import React, { useCallback, useState, useEffect, useMemo } from 'react';

import CallAudioMp3 from '../assets/call_audio.mp3';
import { createContext, useContextSelector } from './context';
import { UserModel } from './api';
import { useNotification } from './notification';
import { useLanguage } from './language';
import { AppMessages } from '../languages';
import ToastInviteContainer from '../components/ToastInviteContainer';

export interface ToastInviteData {
  id: string;
  type?: 'received' | 'sended';
  user: UserModel;
  officeId: string;
  roomId: string;
  meetingId: string;
  onAccept?: () => void;
  onCancel?: () => void;
}
export interface ToastInvites {
  [id: string]: ToastInviteData;
}

interface ToastInviteContextData {
  addInvite(invite: Omit<ToastInviteData, 'id'>): void;
  removeInvite(id: string): void;
}
const ToastInviteContext = createContext({} as ToastInviteContextData);

const audio = new Audio(CallAudioMp3);

export const ToastInviteProvider: React.FC = ({ children }) => {
  const addNotification = useNotification((state) => state.addNotification);
  const isNotificationsEnable = useNotification(
    (state) => state.isNotificationsEnable
  );
  const [invites, setInvites] = useState<ToastInvites>({});
  const [lastReceived, setLastReceived] = useState<{
    invite: ToastInviteData;
    soundPlayed: boolean;
  }>();
  const messages = useLanguage((state) => state.messages);

  const addInvite = useCallback(
    ({
      type,
      user,
      officeId,
      roomId,
      meetingId,
      onAccept,
      onCancel,
    }: ToastInviteData) => {
      const invite = {
        id: user.id,
        type,
        user,
        officeId,
        roomId,
        meetingId,
        onAccept,
        onCancel,
      };

      setInvites((state) => {
        if (invite.type === 'received') {
          let soundPlayed = true;
          audio
            .play()
            .catch(() => {
              soundPlayed = false;
            })
            .finally(() => {
              setLastReceived({ invite, soundPlayed });
            });
        }
        return { ...state, [invite.id]: invite };
      });
    },
    []
  );

  const removeInvite = useCallback((id: string): void => {
    setInvites((state) => {
      const newState = { ...state };
      delete newState[id];
      return newState;
    });
  }, []);

  useEffect(() => {
    if (lastReceived && isNotificationsEnable()) {
      const { firstName, lastName } = lastReceived.invite.user;
      addNotification({
        title: messages[AppMessages.inviteReceived],
        body: `${firstName} ${lastName} ${
          messages[AppMessages.inviteWantsToConnect]
        }.`,
        silent: lastReceived.soundPlayed,
      });
      setLastReceived(undefined);
    }
  }, [lastReceived, isNotificationsEnable, addNotification, messages]);

  const contextValue = useMemo<ToastInviteContextData>(
    () => ({
      addInvite,
      removeInvite,
    }),
    [addInvite, removeInvite]
  );

  const toastInviteContainer = useMemo(
    () => <ToastInviteContainer invites={invites} />,
    [invites]
  );

  return (
    <ToastInviteContext.Provider value={contextValue}>
      {children}
      {toastInviteContainer}
    </ToastInviteContext.Provider>
  );
};

export function useToastInvite<TResult>(
  selector: (state: ToastInviteContextData) => TResult
): TResult {
  return useContextSelector(ToastInviteContext, selector);
}
