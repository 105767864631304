/* eslint-disable import/no-duplicates */
import React, { useCallback, useMemo } from 'react';
import {
  formatDistanceToNowStrict as dateFnsFormatDistanceToNowStrict,
  format as dateFnsFormat,
  differenceInHours as dateFnsDifferenceInHours,
} from 'date-fns';
import { enUS, ptBR, es } from 'date-fns/locale';

import { createContext, useContextSelector } from './context';
import { useLanguage } from './language';

const dateFNSLocales: { [key: string]: any } = {
  'en-US': enUS,
  'es-ES': es,
  'pt-BR': ptBR,
};

interface DateFormatContextData {
  formatDistanceToNowStrict: (date: Date) => string;
  formatDateTime: (date: Date) => string;
  differenceInHours: (dateLeft: Date, dateRight: Date) => number;
}

const DateFormatContext = createContext({} as DateFormatContextData);

export const DateFormatProvider: React.FC = ({ children }) => {
  const languague = useLanguage((state) => state.language);
  const locale = dateFNSLocales[languague];

  const formatDistanceToNowStrict = useCallback(
    (date: Date) => {
      return dateFnsFormatDistanceToNowStrict(date, { locale });
    },
    [locale]
  );

  const formatDateTime = useCallback(
    (date: Date) => {
      return dateFnsFormat(date, `dd MMM yyyy '·' HH:mm`, { locale });
    },
    [locale]
  );

  const differenceInHours = useCallback((dateLeft: Date, dateRight: Date) => {
    return dateFnsDifferenceInHours(dateLeft, dateRight);
  }, []);

  const contextValue = useMemo<DateFormatContextData>(
    () => ({
      formatDistanceToNowStrict,
      formatDateTime,
      differenceInHours,
    }),
    [formatDistanceToNowStrict, formatDateTime, differenceInHours]
  );

  return (
    <DateFormatContext.Provider value={contextValue}>
      {children}
    </DateFormatContext.Provider>
  );
};

export function useDateFormat<TResult>(
  selector: (state: DateFormatContextData) => TResult
): TResult {
  return useContextSelector(DateFormatContext, selector);
}
