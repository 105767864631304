import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { MdContentCopy } from 'react-icons/md';

import { Container, Button } from './styles';
import { AppMessages } from '../../languages';
import { useApi, GuestToken } from '../../hooks/api';
import { useToast } from '../../hooks/toast';
import { useOffice } from '../../hooks/office';
import { ModalContainer } from '../ModalContainer';
import { IconButton } from '../IconButton';
import { CloseBoxIcon, SpinnerIcon } from '../Icons';
import { StyledInput } from '../StyledInput';

interface StoredLinks {
  [groupId: string]: {
    link: string;
    data: GuestToken;
  };
}

interface Props {
  isOpen: boolean;
  onCloseRequest: () => void;
}

export const MeetingLinkSharing: React.FC<Props> = ({
  isOpen,
  onCloseRequest,
}) => {
  const { formatMessage } = useIntl();
  const { getNewGuestToken, getShortUrl } = useApi();
  const addToast = useToast((state) => state.addToast);
  const meetingLinkRef = useRef<HTMLInputElement>(null);
  const userId = useOffice((state) => state.currentUserInfo.id || '');
  const officeId = useOffice((state) => state.currentUserInfo.officeId || '');
  const roomId = useOffice((state) => state.currentUserInfo.roomId || '');
  const groupId = useOffice((state) => state.currentUserInfo.groupId || '');
  const [titleInputValue, setTitleInputValue] = useState('');
  const [storedLinks, setStoredLinks] = useState<StoredLinks>({});
  const [processing, setProcessing] = useState(false);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setTitleInputValue(e.target.value);
    },
    []
  );

  const handleGenerateLink = useCallback(async () => {
    if (!officeId || !roomId || !groupId) return;
    const data: GuestToken = {
      title: titleInputValue,
      creatorId: userId,
      participantIds: [],
      startsAt: new Date(),
      officeId,
      roomId,
      groupId,
    };

    setProcessing(true);
    const token = await getNewGuestToken(data).catch(() => '');

    if (!token) {
      setProcessing(false);
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    const link = `${location.origin}/meeting/${token}`;
    let shortLink = '';

    try {
      shortLink = `https://${await getShortUrl(link)}`;
    } finally {
      setProcessing(false);
      setStoredLinks((state) => ({
        ...state,
        [groupId]: { link: shortLink || link, data },
      }));
    }
  }, [
    userId,
    titleInputValue,
    officeId,
    roomId,
    groupId,
    getNewGuestToken,
    getShortUrl,
  ]);

  const handleCopyLink = useCallback(() => {
    if (!meetingLinkRef.current) return;
    meetingLinkRef.current.select();
    document.execCommand('copy', true);
    addToast({
      title: 'Proseia',
      description: formatMessage({
        id: AppMessages.componentMeetingLinkSharingCopied,
      }),
    });
  }, [addToast, formatMessage]);

  useEffect(() => {
    if (isOpen) return;
    setTitleInputValue('');
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen || !storedLinks[groupId]) return;
    setTitleInputValue(storedLinks[groupId].data.title);
  }, [isOpen, groupId, storedLinks]);

  return (
    <ModalContainer isOpen={isOpen} onClickOutside={onCloseRequest}>
      <Container onClick={(e) => e.stopPropagation()}>
        <div className="header">
          <span>
            {formatMessage({ id: AppMessages.pageOfficeShareMeetingLink })}
          </span>
          <IconButton iconSize={16} onClick={onCloseRequest}>
            <CloseBoxIcon />
          </IconButton>
        </div>
        <div className="content">
          <StyledInput
            className="input"
            required
            autoFocus
            autoComplete="off"
            disabled={processing || !!storedLinks[groupId]}
            label={formatMessage({
              id: AppMessages.componentMeetingLinkSharingTitle,
            })}
            value={titleInputValue}
            onChange={handleInputChange}
            error={!titleInputValue}
          />
          <br />
          {!storedLinks[groupId] && (
            <Button
              disabled={!titleInputValue || processing}
              onClick={handleGenerateLink}
            >
              {formatMessage({
                id: AppMessages.componentMeetingLinkSharingGenerate,
              })}
            </Button>
          )}
          {!processing && storedLinks[groupId] && (
            <>
              <Button className="copy-link" onClick={handleCopyLink}>
                <MdContentCopy />
                {formatMessage({
                  id: AppMessages.componentMeetingLinkSharingCopy,
                })}
              </Button>
              <input
                type="text"
                ref={meetingLinkRef}
                className="meeting-link"
                value={storedLinks[groupId].link}
                onClick={handleCopyLink}
              />
            </>
          )}
          {processing && (
            <div className="spinner-container">
              <SpinnerIcon />
            </div>
          )}
        </div>
      </Container>
    </ModalContainer>
  );
};
