import { LanguageData, AppMessages } from './index';

const messages: LanguageData = {
  [AppMessages.pageSignInWelcome]: 'Sua equipe unida, onde quer que estejam!',
  [AppMessages.pageSignInEmail]: 'E-mail',
  [AppMessages.pageSignInPassword]: 'Senha',
  [AppMessages.pageSignInConfirmPassword]: 'Confirme a Senha',
  [AppMessages.pageSignInFirstName]: 'Nome',
  [AppMessages.pageSignInLastName]: 'Sobrenome',
  [AppMessages.pageSignInRoleDescription]: 'Função',
  [AppMessages.pageSignInPhoneNumber]: 'Telefone',
  [AppMessages.pageSignInCompany]: 'Empresa',
  [AppMessages.pageSignInSubmit]: 'ENTRAR',
  [AppMessages.pageSignInForgotPassword]: 'Esqueci minha senha',
  [AppMessages.pageSignInForgotPasswordConfirm]:
    'Deseja receber um link no e-mail "{email}" para redefinir sua senha?',
  [AppMessages.pageSignInForgotPasswordLinkSended]:
    'Um link foi enviado para o seu e-mail',
  [AppMessages.pageSignInValidationRequired]: 'Obrigatório',
  [AppMessages.pageSignInValidationInvalidEmail]: 'E-mail inválido',
  [AppMessages.pageSignInValidationWeakPassword]:
    'Pelo menos: 1 letra, 1 número, 6 dígitos',
  [AppMessages.pageSignInValidationDoesNotMatch]: 'Não corresponde',
  [AppMessages.pageSignInValidationInvalidPhoneNumber]:
    'Inválido: informe número completo com DDD',
  [AppMessages.pageSignInValidationInvalidCredentials]: 'Credenciais inválidas',
  [AppMessages.pageSignInValidationIncompleteRegistration]:
    'Seu administrador ainda precisa concluir o cadastro do seu usuário para que você possa acessar. Contate-o para solicitar conclusão.',
  [AppMessages.pageSignInKeepConnected]: 'Manter conectado',

  [AppMessages.pageCreateEnvironmentWelcome]:
    'Bem vindo ao Proseia, vamos começar?',
  [AppMessages.pageCreateEnvironmentOrganization]: 'Empresa / Organização',
  [AppMessages.pageCreateEnvironmentEmail]:
    'Coloque o nome da sua empresa e um e-mail válido.',
  [AppMessages.pageCreateEnvironmentSubdomain]:
    'Seu domínio (endereço WEB) no Proseia será:',
  [AppMessages.pageCreateEnvironmentSubdomainPlaceholder]: 'suaempresa',
  [AppMessages.pageCreateEnvironmentSubmit]: 'Próximo',
  [AppMessages.pageCreateEnvironmentValidationInvalidSubdomain]:
    'Pelo menos 3 dígitos, até 20 dígitos',
  [AppMessages.pageCreateEnvironmentValidationSubdomainNotAvailable]:
    'Subdomínio não disponível',

  [AppMessages.pageSigningUpEnvironmentTitle]:
    'Preencha seus dados e escolha uma senha.',
  [AppMessages.pageSigningUpEnvironmentSubmit]: 'Próximo',

  [AppMessages.pageSettingUpEnvironmentTitle]:
    'Aguarde, estamos preparando o seu Proseia.',
  [AppMessages.pageSettingUpEnvironmentSteps]:
    'Criando o seu ambiente; Montando a interface; Preparando as salas; Organizando o chat; Construindo o feed; Quase pronto;',

  [AppMessages.pageStartEnvironmentTitle]: 'Tudo pronto!',
  [AppMessages.pageStartEnvironmentDescription]:
    'Agora é só seguir em frente e começar a usar.',
  [AppMessages.pageStartEnvironmentSubmit]: 'Começar a usar',

  [AppMessages.pageOfficeNotAllowedToRequestConnectionsBetweenOthers]:
    'Não é permitido solicitar conexões entre outros usuários.',
  [AppMessages.pageOfficeNotAllowedToRequestConnectionsWithInactiveMobileUser]:
    'Não é permitido enviar convites de reunião para usuários inativos do mobile.',
  [AppMessages.pageOfficeNotAllowedToMoveOthers]:
    'Não é permitido mover outros usuários.',
  [AppMessages.pageOfficeEnableEditMode]: 'Ativar modo de edição de escritório',
  [AppMessages.pageOfficeDisableEditMode]:
    'Desativar modo de edição de escritório',
  [AppMessages.pageOfficeAddRoom]: 'Adicionar sala',
  [AppMessages.pageOfficeRoomName]: 'Nome da sala',
  [AppMessages.pageOfficeInviteUsers]: 'Convidar usuários',
  [AppMessages.pageOfficeInviteUsersPrompt]:
    'Insira os e-mails separados por vírgulas',
  [AppMessages.pageOfficeStartNewMeeting]: `Iniciar nova reunião`,
  [AppMessages.pageOfficeShareMeetingLink]: `Compartilhar link da reunião`,
  [AppMessages.pageOfficeChangeMood]: 'Alterar humor',
  [AppMessages.pageOfficeTurnOffRoomSoundAndStartMeetingConfirmation]:
    'Deseja desligar o som da sala e iniciar uma nova reunião?',

  [AppMessages.pageMeetingHeaderMessage]:
    'Informe seu nome antes de entrar na reunião',
  [AppMessages.pageMeetingEnterMeeting]: 'Entrar na reunião',

  [AppMessages.componentGoogleSignInButton]: 'Entrar com o Google',

  [AppMessages.componentAvatarOpenChatConversation]: 'Conversar no chat',
  [AppMessages.componentAvatarInviteToMeeting]: 'Convidar para reunião',
  [AppMessages.componentAvatarRequestToJoinTheMeeting]:
    'Pedir para entrar na reunião',
  [AppMessages.componentAvatarJoinTheMeeting]: 'Entrar na reunião',

  [AppMessages.componentMeetingLinkSharingTitle]: 'Título da reunião',
  [AppMessages.componentMeetingLinkSharingGenerate]: 'Gerar link',
  [AppMessages.componentMeetingLinkSharingCopy]: 'Copiar link',
  [AppMessages.componentMeetingLinkSharingCopied]:
    'Link copiado com sucesso para área de transferência!',

  [AppMessages.componentThemeSwitcherToDark]: 'Mudar para tema escuro',
  [AppMessages.componentThemeSwitcherToLight]: 'Mudar para tema claro',

  [AppMessages.componentRoomExpand]: 'Expandir',
  [AppMessages.componentRoomRetract]: 'Retrair',
  [AppMessages.componentRoomDelete]: 'Excluir sala',
  [AppMessages.componentRoomConfirmDeletion]: 'Confirma a exclusão da sala?',
  [AppMessages.componentRoomEnter]: 'Entrar na sala',
  [AppMessages.componentRoomTurnOnSound]: 'Ligar som da sala',
  [AppMessages.componentRoomTurnOffSound]: 'Desligar som da sala',

  [AppMessages.componentProfileDefineYourRole]: 'Defina sua função',
  [AppMessages.componentProfileAt]: 'Está em:',
  [AppMessages.componentProfileSignOut]: 'Sair',
  [AppMessages.componentProfileEditingNotAllowed]:
    'Apenas usuários administradores podem editar as informações do perfil',

  [AppMessages.componentWelcomeTourStartTitle]: `Bem vindo ao Proseia`,
  [AppMessages.componentWelcomeTourStartDescription]: `Vamos começar um tour para aprender como usá-lo!\n(Pressione as setas para navegar por esta apresentação)`,
  [AppMessages.componentWelcomeTourUserInfoTitle]: `Informações do usuário`,
  [AppMessages.componentWelcomeTourUserInfoDescription]: `Aqui estão suas informações pessoais.`,
  [AppMessages.componentWelcomeTourAvatarPictureTitle]: `Imagem do avatar`,
  [AppMessages.componentWelcomeTourAvatarPictureDescription]: `Você pode alterar sua imagem clicando no ícone de edição que aparece quando você passa o mouse sobre o avatar.`,
  [AppMessages.componentWelcomeTourAvatarDraggableTitle]: `O avatar é arrastável`,
  [AppMessages.componentWelcomeTourAvatarDraggableDescription]: `Você também pode arrastá-lo para uma sala para entrar ou arrastá-lo sobre o avatar de outro usuário para solicitar uma conferência onde quer que ele esteja.`,
  [AppMessages.componentWelcomeTourRoleTitle]: `Função`,
  [AppMessages.componentWelcomeTourRoleDescription]: `Sua função é exibida aqui.\nVocê pode clicar para editá-la.`,
  [AppMessages.componentWelcomeTourProfileTitle]: `Perfil`,
  [AppMessages.componentWelcomeTourProfileDescription]: `Ao clicar na área de informações pessoais, o perfil será expandido.`,
  [AppMessages.componentWelcomeTourRemoteTitle]: `Trabalho remoto`,
  [AppMessages.componentWelcomeTourRemoteDescription]: `Habilite ou desabilite esta opção para informar a outros usuários se no momento você está trabalhando presencialmente ou remotamente.`,
  [AppMessages.componentWelcomeTourUserStatusTitle]: `Status do usuário`,
  [AppMessages.componentWelcomeTourUserStatusDescription]: `Informe outros usuários sobre seu status atual, selecionando uma das opções.`,
  [AppMessages.componentWelcomeTourLanguagesTitle]: `Idiomas`,
  [AppMessages.componentWelcomeTourLanguagesDescription]: `Selecione seu idioma preferido entre os disponíveis.`,
  [AppMessages.componentWelcomeTourMoodsTitle]: 'Humores',
  [AppMessages.componentWelcomeTourMoodsDescription]:
    'Informe outros usuários como você está se sentindo',
  [AppMessages.componentWelcomeTourNotificationsTitle]: `Notificações`,
  [AppMessages.componentWelcomeTourNotificationsDescription]: `Dê permissão e ative as notificações para tornar mais fácil ver quando alguém tenta interagir.`,
  [AppMessages.componentWelcomeTourThemeSwitcherTitle]: `Alternador de tema`,
  [AppMessages.componentWelcomeTourThemeSwitcherDescription]: `Alterne entre os temas claro e escuro clicando neste botão.`,
  [AppMessages.componentWelcomeTourInviteOthersTitle]: `Convide outros`,
  [AppMessages.componentWelcomeTourInviteOthersDescription]: `Envie convites para seus colegas de equipe se juntarem a você no Proseia.\nEles receberão o convite em seus e-mails.`,
  [AppMessages.componentWelcomeTourEditModeTitle]: `Edite o escritório virtual`,
  [AppMessages.componentWelcomeTourEditModeDescription]: `Ao clicar neste botão, o modo de edição é habilitado e você pode adicionar, renomear ou excluir salas conforme necessário.`,
  [AppMessages.componentWelcomeTourRoomsTitle]: `Salas`,
  [AppMessages.componentWelcomeTourRoomsDescription]: `Esta é a representação virtual de seu escritório.\nCada sala pode representar uma sala do mundo real em seu escritório, um departamento, um contexto de equipe específico ou qualquer outra separação que faça sentido para sua empresa.\nVocê pode mover-se entre as salas arrastando seu avatar.`,
  [AppMessages.componentWelcomeTourDoneTitle]: `É isso aí!`,
  [AppMessages.componentWelcomeTourDoneDescription]: `Por enquanto, isso é tudo que temos para mostrar a você!`,
  [AppMessages.componentWelcomeTourDone]: `Concluído`,

  [AppMessages.componentStyledMultiSelectSelected]: 'Selecionado(s)',
  [AppMessages.componentStyledMultiSelectAll]: 'Todos',

  [AppMessages.componentUserMoodTitle]: 'Como você está se sentindo?',
  [AppMessages.componentUserMoodComment]:
    'Se quiser, conte-nos o que faz você se sentir assim',
  [AppMessages.componentUserMoodConfirm]: 'Confirmar',

  [AppMessages.componentUserManagementTitle]: 'Gerenciamento de usuários',
  [AppMessages.componentUserManagementInviteNewUsers]:
    'Convidar novos usuários',
  [AppMessages.componentUserManagementUsersCount]: 'Total',
  [AppMessages.componentUserManagementCanAccess]: 'Podem acessar',
  [AppMessages.componentUserManagementRevokeAccess]: 'Bloquear acesso',
  [AppMessages.componentUserManagementGrantAccess]: 'Liberar acesso',
  [AppMessages.componentUserManagementAddAsAdministrator]:
    'Adicionar como administrador',
  [AppMessages.componentUserManagementRemoveFromAdministrators]:
    'Remover dos administradores',
  [AppMessages.componentUserManagementDeleteUser]: 'Excluir usuário',
  [AppMessages.componentUserManagementResendInvitationEmail]:
    'Reenviar e-mail de convite',
  [AppMessages.componentUserManagementAdministrator]: 'Administrador',
  [AppMessages.componentUserManagementAccessRevoked]: 'Acesso bloqueado',
  [AppMessages.componentUserManagementNoLogin]: '(Sem login)',
  [AppMessages.componentUserManagementUseLocation]: 'Usar geolocalização',
  [AppMessages.componentUserManagementUseLocationDescription]:
    'Quando essa opção é habilitada, a localização dos usuários que usam o aplicativo móvel será solicitada.',
  [AppMessages.componentUserManagementMakeLocationRequired]:
    'Tornar a geolocalização obrigatória para este usuário ao usar o aplicativo móvel',
  [AppMessages.componentUserManagementMakeLocationOptional]:
    'Tornar a geolocalização opcional para este usuário ao usar o aplicativo móvel',
  [AppMessages.componentUserManagementAllowProfileEditing]:
    'Permitir edição de perfil',
  [AppMessages.componentUserManagementAllowProfileEditingDescription]:
    'Quando esta opção está desabilitada, os usuários não podem editar suas informações de perfil (nome, função, imagem), apenas os administradores.',
  [AppMessages.componentUserManagementUseExtension]: 'Usar extensão',
  [AppMessages.componentUserManagementUseExtensionDescription]:
    'Quando esta opção é habilitada, o usuário terá que ter a extensão que verifica sua atividade instalada em seu navegador.',
  [AppMessages.componentUserManagementMakeExtensionRequired]:
    'Tornar o uso da extensão obrigatório para este usuário.',
  [AppMessages.componentUserManagementMakeExtensionOptional]:
    'Tornar o uso da extensão opcional para este usuário.',
  [AppMessages.componentUserManagementUnnamed]: 'Nome não definido',
  [AppMessages.componentUserManagementNoRole]: 'Função não definida',

  [AppMessages.userInfoRemote]: 'Remoto',
  [AppMessages.userInfoStatusAvailable]: 'Disponível',
  [AppMessages.userInfoStatusAway]: 'Ausente',
  [AppMessages.userInfoStatusBusy]: 'Ocupado',
  [AppMessages.userInfoStatusInactive]: 'Inativo',

  [AppMessages.inputSearchPlaceholder]: 'Buscar',

  [AppMessages.chatDefaultChannelName]: 'Todo Mundo',
  [AppMessages.chatChannels]: 'Grupos',
  [AppMessages.chatContacts]: 'Contatos',
  [AppMessages.chatTyping]: 'digitando',
  [AppMessages.chatLoading]: 'carregando...',
  [AppMessages.chatNewMessages]: 'Novas mensagens',
  [AppMessages.chatLoadPrevious]: 'Carregar anteriores',
  [AppMessages.chatLoadNew]: 'Mostrar recentes',
  [AppMessages.chatSendMessage]: 'Enviar mensagem',
  [AppMessages.chatExcludeFile]: 'Excluir arquivo',
  [AppMessages.chatNewMessageFrom]: 'Nova mensagem de {name}',
  [AppMessages.chatPublicChannel]: 'Público',
  [AppMessages.chatPrivateChannel]: 'Privado',
  [AppMessages.chatNewChannel]: 'Novo grupo',
  [AppMessages.chatCreateChannel]: 'Criar',
  [AppMessages.chatChannelName]: 'Nome',
  [AppMessages.chatJoinChannel]: 'Entrar',
  [AppMessages.chatLeaveChannel]: 'Sair do grupo',
  [AppMessages.chatLeaveChannelConfirm]: 'Deseja realmente sair deste grupo?',
  [AppMessages.chatRemoveFromChannel]: 'Remover',
  [AppMessages.chatRemoveFromChannelConfirm]:
    'Tem certeza de que deseja remover "{name}" desse grupo?',
  [AppMessages.chatRenameChannel]: 'Renomear',
  [AppMessages.chatRenameChannelNewName]: 'Novo nome:',
  [AppMessages.chatMakeChannelPublic]: 'Tornar público',
  [AppMessages.chatMakeChannelPublicConfirm]:
    'Deseja tornar este grupo público?',
  [AppMessages.chatMakeChannelPrivate]: 'Tornar privado',
  [AppMessages.chatMakeChannelPrivateConfirm]:
    'Deseja tornar este grupo privado?',
  [AppMessages.chatDeleteChannel]: 'Excluir o grupo',
  [AppMessages.chatDeleteChannelConfirm]:
    'Deseja realmente excluir o grupo "{group}"? (Essa ação é irreversível)',
  [AppMessages.chatChannelMembers]: 'Membros',
  [AppMessages.chatChannelMember]: 'Membro',
  [AppMessages.chatAddToChannel]: 'Adicionar',
  [AppMessages.chatInputPlaceholder]: 'Mensagem',
  [AppMessages.chatPostConfirmDeletion]: 'Confirma a exclusão?',
  [AppMessages.chatPostDelete]: 'Excluir',
  [AppMessages.chatPostAddReaction]: 'Adicionar reação',
  [AppMessages.chatPostReplyTo]: 'Responder',
  [AppMessages.chatSendingAudio]: 'Enviando mensagem',

  [AppMessages.officePostsGeneral]: 'Geral',
  [AppMessages.officePostsNothingPosted]: 'Nada foi postado ainda',
  [AppMessages.officePostConfirmDeletion]: 'Confirma a exclusão?',
  [AppMessages.officePostDelete]: 'Excluir',
  [AppMessages.officePostAddReaction]: 'Adicionar reação',
  [AppMessages.officePostPin]: 'Fixar post',
  [AppMessages.officePostUnpin]: 'Desafixar post',
  [AppMessages.officePostPublish]: 'Publicar',
  [AppMessages.officePostInputTitle]: 'Criar post',
  [AppMessages.officePostInputAddToPost]: 'Adicionar ao post',
  [AppMessages.officePostInputPlaceholder]: 'Crie um novo post',
  [AppMessages.officePostCommentInputPlaceholder]: 'Adicione um comentário',
  [AppMessages.officePostCommentsCountTitle]: 'comentário(s)',
  [AppMessages.officePostCommentsShowAllButton]: 'Mostrar todos os comentários',
  [AppMessages.officePostCommentsHideButton]: 'Ocultar comentários',
  [AppMessages.officePostCommentsNewCount]: 'novo(s)',

  [AppMessages.attention]: 'Atenção',
  [AppMessages.notAllowed]: 'Não permitido',
  [AppMessages.noSupport]: 'Sem suporte',
  [AppMessages.permissionDenied]: 'Permissão negada',

  [AppMessages.notificationsNoSupport]: 'Navegador não suporta notificações.',
  [AppMessages.notificationsPermissionRequired]:
    'A permissão de notificações deve ser concedida nas configurações do navegador.',
  [AppMessages.notificationsEnable]: 'Ativar notificações',
  [AppMessages.notificationsDisable]: 'Desativar notificações',

  [AppMessages.userMediaNoSupport]:
    'O navegador não oferece suporte para captura de áudio/vídeo.',
  [AppMessages.userMediaMicrophonePermissionRequired]:
    'A permissão do microfone deve ser concedida nas configurações do navegador.',
  [AppMessages.userMediaMicrophoneEnable]: 'Habilitar microfone',
  [AppMessages.userMediaMicrophoneDisable]: 'Desativar microfone',
  [AppMessages.userMediaCameraPermissionRequired]:
    'A permissão da câmera deve ser concedida nas configurações do navegador.',
  [AppMessages.userMediaCameraEnable]: 'Habilitar câmera',
  [AppMessages.userMediaCameraDisable]: 'Desativar câmera',
  [AppMessages.userMediaScreenSharingEnable]:
    'Iniciar compartilhamento de tela',
  [AppMessages.userMediaScreenSharingDisable]:
    'Parar o compartilhamento de tela',

  [AppMessages.meetingLeave]: 'Sair da reunião',
  [AppMessages.meetingShow]: 'Mostrar reunião',
  [AppMessages.meetingHide]: 'Ocultar reunião',
  [AppMessages.meetingConfirmLeave]: `Você sairá da reunião atual. É isso mesmo?`,

  [AppMessages.inviteSended]: 'Convite enviado',
  [AppMessages.inviteReceived]: 'Convite recebido',
  [AppMessages.inviteRejected]: 'Convite rejeitado',
  [AppMessages.inviteCanceled]: 'Convite cancelado',
  [AppMessages.inviteWantsToConnect]: 'quer se conectar a você',
  [AppMessages.inviteRejectedYourInvitation]: 'rejeitou seu convite',
  [AppMessages.inviteCanceledTheInvitation]: 'cancelou o convite',

  [AppMessages.periodsField]: 'Período',
  [AppMessages.periodsToday]: 'Hoje',
  [AppMessages.periodsYesterday]: 'Ontem',
  [AppMessages.periodsThisWeek]: 'Esta semana',
  [AppMessages.periodsLastWeek]: 'Semana Anterior',
  [AppMessages.periodsThisMonth]: 'Este mês',
  [AppMessages.periodsLastMonth]: 'Mês passado',

  [AppMessages.booleanStringYes]: 'Sim',
  [AppMessages.booleanStringNo]: 'Não',

  [AppMessages.reportsTitle]: 'Relatórios',
  [AppMessages.reportsNoData]: 'Sem dados para o período ou filtro',

  [AppMessages.reportsModelField]: 'Modelo',
  [AppMessages.reportsModelStatusPerUser]: 'Tempo por status/usuário',
  [AppMessages.reportsModelStatusPerRoomUser]: 'Tempo por status/sala/usuário',
  [AppMessages.reportsModelInMeetingPerUser]: 'Tempo de reunião por usuário',
  [AppMessages.reportsModelMeetingsPerDayRoomUser]:
    'Reuniões por dia/sala/usuário',
  [AppMessages.reportsModelAnalyticLogs]: 'Logs analíticos',

  [AppMessages.reportsFieldLogId]: 'ID do registro',
  [AppMessages.reportsFieldUserFullName]: 'Usuário',
  [AppMessages.reportsFieldUserEmail]: 'E-mail',
  [AppMessages.reportsFieldOfficeName]: 'Escritório',
  [AppMessages.reportsFieldRoomName]: 'Sala',
  [AppMessages.reportsFieldIsRemote]: 'Remoto',
  [AppMessages.reportsFieldStatus]: 'Status',
  [AppMessages.reportsFieldStatusMessage]: 'Mensagem de status',
  [AppMessages.reportsFieldInMeeting]: 'Em reunião',
  [AppMessages.reportsFieldMeetingId]: 'ID da reunião',
  [AppMessages.reportsFieldMeetingStart]: 'Início da reunião',
  [AppMessages.reportsFieldMeetingEnd]: 'Fim da reunião',
  [AppMessages.reportsFieldMeetingTotalTimeInSeconds]:
    'Duração da reunião (segundos)',
  [AppMessages.reportsFieldMeetingTotalTimeInMinutes]:
    'Duração da reunião (minutos)',
  [AppMessages.reportsFieldMeetingTotalTimeInHours]:
    'Duração da reunião (horas)',
  [AppMessages.reportsFieldMicrophoneEnabled]: 'Microfone habilitado',
  [AppMessages.reportsFieldCameraEnabled]: 'Câmera habilitada',
  [AppMessages.reportsFieldScreenSharingEnabled]: 'Tela compartilhada',
  [AppMessages.reportsFieldLogCreatedAt]: 'Início do log',
  [AppMessages.reportsFieldLogFinishedAt]: 'Fim do log',
  [AppMessages.reportsFieldLogDate]: 'Data',
  [AppMessages.reportsFieldLogYear]: 'Ano',
  [AppMessages.reportsFieldLogMonth]: 'Mês',
  [AppMessages.reportsFieldLogDay]: 'Dia',
  [AppMessages.reportsFieldTimeInSeconds]: 'Duração do registro (segundos)',
  [AppMessages.reportsFieldTimeInMinutes]: 'Duração do registro (minutos)',
  [AppMessages.reportsFieldTimeInHours]: 'Duração do log (horas)',

  [AppMessages.limitsActiveUsersReached]: 'Limite de usuários ativos atingido',
  [AppMessages.limitsActiveUsers]:
    'Seu plano atual permite apenas {limit} usuários ativos',
  [AppMessages.limitsActivityReportsNotAllowed]:
    'Seu plano atual não permite relatórios',
  [AppMessages.limitsExternalMeetingsNotAllowed]:
    'Seu plano atual não permite links de reunião para usuários externos',
  [AppMessages.limitsCannotJoinMeeting]: 'Não foi possível entrar na reunião',
  [AppMessages.limitsUsersPerMeeting]:
    'Reunião excedeu o número máximo de participantes',
  [AppMessages.limitsAudioHoursReached]:
    'Limite de horas de áudio atingido para seu plano atual',
  [AppMessages.limitsVideoHoursReached]:
    'Limite de horas de vídeo atingido para seu plano atual',
  [AppMessages.limitsScreenSharingHoursReached]:
    'Limite de horas de compartilhamento de tela atingido para seu plano atual',
  [AppMessages.limitsChatFileTooLarge]: 'Arquivo muito grande',
  [AppMessages.limitsChatMaxFileSize]:
    'Seu plano atual permite que você envie arquivos de até {limit} megabytes apenas',

  [AppMessages.chromeExtensionNeededMessage]:
    'Não foi possível detectar a extensão Proseia em seu navegador.\nPara uma melhor experiência, instale a extensão.\n>> Clique aqui para instalar a extensão <<\nApós terminar a instalação, recarregue a página!',

  [AppMessages.smallScreenDetectedMessage]:
    'O Proseia é otimizado para o uso em telas maiores.\nPara uma melhor experiencia, realize o acesso através de um computador!\n\nPara dispositivos móveis disponibilizamos o App Proseia, que permite enviar e receber mensagens no chat.',

  [AppMessages.copyrightText]: '{year} - Todos os direitos reservados',

  [AppMessages.privacyPolicy]: 'Política de privacidade',

  [AppMessages.updateVersion]: 'Nova versão disponível! Clique para atualizar.',

  [AppMessages.stepOf]: 'Passo {current} de {total}',

  [AppMessages.tutorialEnterRoomTitle]: 'Bem vindo ao Proseia',
  [AppMessages.tutorialEnterRoomDescription1]:
    'Arraste seu avatar para entrar em uma sala.',
  [AppMessages.tutorialEnterRoomDescription2]: 'Você também pode usar o ícone ',

  [AppMessages.tutorialAddUsersDescription1]:
    'Clique aqui para adicionar usuários.',

  [AppMessages.tutorialInviteToMeetingTitle]: 'Reunião por audio/vídeo?',
  [AppMessages.tutorialInviteToMeetingDescription1]:
    'Arraste seu avatar para junto de outro usuário.',
  [AppMessages.tutorialInviteToMeetingDescription2]:
    'Usuários em reunião aparecem juntos, e o melhor, você pode adicionar vários!',

  [AppMessages.tutorialContextMenuTitle]: 'Chat ou reunião?',
  [AppMessages.tutorialContextMenuDescription1]:
    'Basta clicar no usuário e selecionar.',

  [AppMessages.tutorialMeetingVisibilityTitle]: 'Criou uma reunião?',
  [AppMessages.tutorialMeetingVisibilityDescription1]:
    'Durante uma reunião você pode continuar usando o Proseia normalmente.',
  [AppMessages.tutorialMeetingVisibilityDescription2]:
    'Enquanto a reunião estiver ativa, o ícone da câmera ficará piscando em vermelho.',

  [AppMessages.tutorialGotIt]: 'Ok, entendi!',

  [AppMessages.incompatibleBrowserTitle]:
    'Infelizmente seu navegador não é compatível!',
  [AppMessages.incompatibleBrowserDescription]:
    'Para acessar o Proseia, utilize um dos navegadores abaixo.',

  [AppMessages.extensionRequiredTitle]:
    'Seu administrador requer a extensão instalada para acessar o Proseia.',
  [AppMessages.extensionRequiredDescription]:
    'Para acessar o Proseia, instale a extensão no link abaixo e recarregue a página.',
  [AppMessages.extensionRequiredButtonText]: 'Instalar',
};

export default messages;
