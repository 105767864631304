import React, { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Channel } from 'mattermost-redux/types/channels';

import { AppMessages } from '../../languages';
import { matchesSearchValue } from '../../utils/strings';
import { useMattermost } from '../../hooks/mattermost';
import { ModalContainer } from '../ModalContainer';
import { IconButton } from '../IconButton';
import { CloseBoxIcon, SpinnerIcon } from '../Icons';
import { InputSearch } from '../InputSearch';

import {
  Container,
  ChannelContainer,
  Button,
  LoadingContainer,
} from './styles';

export const ChatChannelExplorer: React.FC = () => {
  const { formatMessage } = useIntl();
  const mattermostUserId = useMattermost(
    (state) => state.mattermostUser?.id || ''
  );

  const teamChannels = useMattermost((state) => state.teamChannels);
  const channelExplorerIsOpen = useMattermost(
    (state) => state.channelExplorerIsOpen
  );
  const closeChannelExplorer = useMattermost(
    (state) => state.closeChannelExplorer
  );
  const getTeamChannels = useMattermost((state) => state.getTeamChannels);
  const addToChannel = useMattermost((state) => state.addToChannel);
  const openCreateNewChannel = useMattermost(
    (state) => state.openCreateNewChannel
  );
  const getChannelDisplayName = useMattermost(
    (state) => state.getChannelDisplayName
  );

  const [searchValue, setSearchValue] = useState('');
  const [loadingChannels, setLoadingChannels] = useState(false);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [filteredChannels, setFilteredChannels] = useState<Channel[]>([]);

  const handleSearchValueChange = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  useEffect(() => {
    const joinedIds = Object.keys(teamChannels);
    setFilteredChannels(
      channels
        .filter(
          (t) =>
            !joinedIds.includes(t.id) &&
            matchesSearchValue(getChannelDisplayName(t), searchValue)
        )
        .sort((a, b) =>
          getChannelDisplayName(a).localeCompare(getChannelDisplayName(b))
        )
    );
  }, [channels, teamChannels, searchValue, getChannelDisplayName]);

  useEffect(() => {
    if (!channelExplorerIsOpen) {
      setChannels([]);
      return;
    }
    setLoadingChannels(true);
    getTeamChannels()
      .then(setChannels)
      .finally(() => setLoadingChannels(false));
  }, [channelExplorerIsOpen, getTeamChannels]);

  return (
    <ModalContainer
      isOpen={channelExplorerIsOpen}
      onClickOutside={closeChannelExplorer}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <div className="header">
          <span>{formatMessage({ id: AppMessages.chatChannels })}</span>
          <IconButton iconSize={16} onClick={closeChannelExplorer}>
            <CloseBoxIcon />
          </IconButton>
        </div>
        <div className="search-container">
          <InputSearch onChange={handleSearchValueChange} />
          <Button onClick={openCreateNewChannel}>
            {formatMessage({ id: AppMessages.chatNewChannel })}
          </Button>
        </div>
        <div className="channels">
          {loadingChannels && (
            <ChannelContainer>
              <LoadingContainer>
                {formatMessage({ id: AppMessages.chatLoading })}
                <br />
                <SpinnerIcon />
              </LoadingContainer>
            </ChannelContainer>
          )}
          {filteredChannels.map((channel) => (
            <ChannelContainer key={channel.id}>
              <hr />
              <span>{getChannelDisplayName(channel)}</span>
              <Button
                onClick={() => {
                  addToChannel(mattermostUserId, channel.id).then((joined) => {
                    if (!joined) return;
                    closeChannelExplorer();
                  });
                }}
              >
                {formatMessage({ id: AppMessages.chatJoinChannel })}
              </Button>
            </ChannelContainer>
          ))}
        </div>
      </Container>
    </ModalContainer>
  );
};
