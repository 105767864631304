import { shade } from 'polished';
import { styled, css } from '../../themes';
import { StyledSelect } from '../StyledSelect';

interface ContainerProps {
  hasFocus: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  max-width: 100%;
  background: ${(props) => shade(0.1, props.theme.background)};
  color: ${(props) => props.theme.textColor};
  padding: 6px;
  border-radius: 5px;
  margin-top: 10px;

  ${(props) =>
    props.hasFocus &&
    css`
      box-shadow: 0px 0px 2px 1px ${props.theme.textColor};
    `}

  > svg {
    font-size: 24px;
  }

  > svg.clear:hover {
    transform: scale(1.2);
  }

  > input {
    margin-left: 6px;
    background: transparent;
    color: ${(props) => props.theme.textColor};
    border: none;
    width: 100%;
  }
`;

interface CustomStyledSelectProps {
  statuscolor: string;
}

export const CustomStyledSelect = styled(StyledSelect)<CustomStyledSelectProps>`
  &&.MuiInput-root {
    position: absolute;
    width: 34px;

    > svg {
      right: -5px;
      fill: ${(props) => props.statuscolor} !important;
    }
  }
  &&.MuiInput-underline {
    :before {
      border-bottom: none;
      border-bottom-color: ${(props) => props.statuscolor} !important;
    }
    :after {
      border-bottom: none;
      border-bottom-color: ${(props) => props.statuscolor} !important;
    }
  }
`;
