/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useRef, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { styled } from '../themes';
import { AppMessages } from '../languages';
import { createContext, useContextSelector } from './context';
import { useAuth } from './auth';
import { useApi, UserModel } from './api';
import { usePlanLimits } from './plan-limits';
import { useToast } from './toast';
import { ShareIcon } from '../components/Icons';

export const getNewMeetingId = (): string => {
  // eslint-disable-next-line no-restricted-globals
  return `${location.hostname}-${uuid()}`;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const startConference = (
  domain: string,
  id: string,
  user: UserModel,
  container: HTMLDivElement | null,
  allowMicrophone = true,
  allowCamera = true,
  allowScreenSharing = true,
  startMuted = false
) => {
  const resolution = 720;

  const toolbarButtons = [
    // 'camera',
    'chat',
    // 'closedcaptions',
    // 'desktop',
    // 'dock-iframe',
    // 'download',
    // 'embedmeeting',
    'etherpad',
    // 'feedback',
    'filmstrip',
    'fullscreen',
    'hangup',
    // 'help',
    'highlight',
    // 'invite',
    // 'linktosalesforce',
    // 'livestreaming',
    // 'microphone',
    'noisesuppression',
    'participants-pane',
    // 'profile',
    'raisehand',
    // 'recording',
    // 'security',
    'select-background',
    // 'settings',
    'shareaudio',
    'sharedvideo',
    'shortcuts',
    'stats',
    'tileview',
    'toggle-camera',
    // 'undock-iframe',
    'videoquality',
  ];

  if (allowMicrophone) toolbarButtons.push('microphone');

  if (allowCamera) toolbarButtons.push('camera');

  if (allowScreenSharing) toolbarButtons.push('desktop');

  const options = {
    roomName: id,
    width: '100%',
    height: '100%',
    parentNode: container,
    configOverwrite: {
      startWithAudioMuted: startMuted || !allowMicrophone,
      startWithVideoMuted: true,
      enableWelcomePage: false,
      enableClosePage: false,
      prejoinPageEnabled: false,
      enableCalendarIntegration: false,
      resolution,
      constraints: {
        video: {
          aspectRatio: 16 / 9,
          height: {
            ideal: resolution,
            max: 720,
            min: 360,
          },
        },
      },
      toolbarButtons,

      // Enable / disable simulcast support.
      disableSimulcast: true,

      // Enable / disable layer suspension.  If enabled, endpoints whose HD
      // layers are not in use will be suspended (no longer sent) until they
      // are requested again.
      enableLayerSuspension: false,

      // If set to true, prefer to use the H.264 video codec (if supported).
      // Note that it's not recommended to do this because simulcast is not
      // supported when  using H.264. For 1-to-1 calls this setting is enabled
      // by default and can be toggled in the p2p section.
      // This option has been deprecated, use preferredCodec under videoQuality
      // section instead.
      preferH264: true,

      // Provides a way to set the video codec preference on the p2p connection. Acceptable
      // codec values are 'VP8', 'VP9' and 'H264'.
      preferredCodec: 'H264',

      // If set to true, disable H.264 video codec by stripping it out of the
      // SDP.
      disableH264: false,

      // Disables or enables RTX (RFC 4588) (defaults to false).
      disableRtx: true,

      // Disables or enables TCC support in this client (default: enabled).
      enableTcc: true,

      // Specify the settings for video quality optimizations on the client.
      videoQuality: {
        // Provides a way to prevent a video codec from being negotiated on the JVB connection. The codec specified
        // here will be removed from the list of codecs present in the SDP answer generated by the client. If the
        // same codec is specified for both the disabled and preferred option, the disable settings will prevail.
        // Note that 'VP8' cannot be disabled since it's a mandatory codec, the setting will be ignored in this case.
        // disabledCodec: 'H264',

        // Provides a way to set a preferred video codec for the JVB connection. If 'H264' is specified here,
        // simulcast will be automatically disabled since JVB doesn't support H264 simulcast yet. This will only
        // rearrange the the preference order of the codecs in the SDP answer generated by the browser only if the
        // preferred codec specified here is present. Please ensure that the JVB offers the specified codec for this
        // to take effect.
        preferredCodec: 'H264',

        // Provides a way to configure the maximum bitrates that will be enforced on the simulcast streams for
        // video tracks. The keys in the object represent the type of the stream (LD, SD or HD) and the values
        // are the max.bitrates to be set on that particular type of stream. The actual send may vary based on
        // the available bandwidth calculated by the browser, but it will be capped by the values specified here.
        // This is currently not implemented on app based clients on mobile.
        maxBitratesVideo: {
          low: 500000, // 200000,
          standard: 1000000, // 500000,
          high: 1500000,
        },

        // The options can be used to override default thresholds of video thumbnail heights corresponding to
        // the video quality levels used in the application. At the time of this writing the allowed levels are:
        //     'low' - for the low quality level (180p at the time of this writing)
        //     'standard' - for the medium quality level (360p)
        //     'high' - for the high quality level (720p)
        // The keys should be positive numbers which represent the minimal thumbnail height for the quality level.
        //
        // With the default config value below the application will use 'low' quality until the thumbnails are
        // at least 360 pixels tall. If the thumbnail height reaches 720 pixels then the application will switch to
        // the high quality.
        minHeightForQualityLvl: {
          360: 'low',
          480: 'standard',
          720: 'high',
        },

        // Provides a way to resize the desktop track to 720p (if it is greater than 720p) before creating a canvas
        // for the presenter mode (camera picture-in-picture mode with screenshare).
        // resizeDesktopForPresenter: false,
      },

      desktopSharingFrameRate: {
        min: 24,
        max: 30,
      },
      testing: {
        // Enables experimental simulcast support on Firefox.
        enableFirefoxSimulcast: true,
      },
    },
    interfaceConfigOverwrite: {
      APP_NAME: 'Proseia',
      DEFAULT_LOGO_URL: '',
      JITSI_WATERMARK_LINK: '',
      SHOW_BRAND_WATERMARK: false,
      SHOW_JITSI_WATERMARK: false,
      SHOW_WATERMARK_FOR_GUESTS: false,
      TOOLBAR_BUTTONS: toolbarButtons,
    },
    userInfo: {
      email: user.email,
      displayName: `${user.firstName} ${user.lastName}`.trim(),
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { JitsiMeetExternalAPI } = window as any;

  const externalApi = new JitsiMeetExternalAPI(domain, options);

  return externalApi;
};

const ONE_MINUTE = 60000;

const disconnectAloneInConference: { [id: string]: any } = {};

// eslint-disable-next-line prefer-const
let jitsiDomain = '';

const externalApi: { [id: string]: any } = {};

const clearDisconnectAloneInConference = (id: string) => {
  if (!disconnectAloneInConference[id]) return;
  clearTimeout(disconnectAloneInConference[id]);
  disconnectAloneInConference[id] = undefined;
};

const setDisconnectAloneInConference = (
  id: string,
  callback: () => void,
  minutesAlone = 3
) => {
  clearDisconnectAloneInConference(id);
  if (!externalApi[id]) return;
  const participantsInfo: any[] = externalApi[id].getParticipantsInfo();
  const participantsCount = participantsInfo.length;

  if (participantsCount > 1) return;

  disconnectAloneInConference[id] = setTimeout(() => {
    if (!externalApi[id]) return;
    const infos: any[] = externalApi[id].getParticipantsInfo();
    const participants = infos.length;

    if (participants > 1) return;
    callback();
    disconnectAloneInConference[id] = undefined;
  }, minutesAlone * ONE_MINUTE);
};

let audioMuteChangedCallback: (muted: boolean) => void | undefined;
let videoMuteChangedCallback: (muted: boolean) => void | undefined;
let screenSharedChangedCallback: (shared: boolean) => void | undefined;
let joinMeetingCallback: (meetingId: string) => void | undefined;
let leaveMeetingCallback: () => void | undefined;
let shareMeetingLinkCallback: () => void | undefined;

interface JitsiContextData {
  browserHasSupport: () => boolean;
  isMicrophonePermissionGranted: () => Promise<boolean>;
  isMicrophoneBlocked: () => Promise<boolean>;
  isCameraPermissionGranted: () => Promise<boolean>;
  isCameraBlocked: () => Promise<boolean>;
  enterJitsiMeeting: (
    meetingId: string,
    callback: (hasPermission: boolean) => void,
    guestUser?: UserModel,
    guestToken?: string,
    startMuted?: boolean,
    disconnectWhenAlone?: boolean,
    considerNumberOfUsersLimit?: boolean
  ) => Promise<void>;
  leaveJitsiMeeting: (meetingId: string) => void;
  toggleMute: (meetingId: string) => void;
  toggleVideo: (meetingId: string) => void;
  toggleScreenSharing: (meetingId: string) => void;
  isMeetingContainerVisible: () => boolean;
  setMeetingContainerVisibility: (visible: boolean) => void;
  onAudioMuteChanged: (callback: (muted: boolean) => void) => void;
  onVideoMuteChanged: (callback: (muted: boolean) => void) => void;
  onScreenSharedChanged: (callback: (muted: boolean) => void) => void;
  onJoinJitsiMeeting: (callback: (meetingId: string) => void) => void;
  onLeaveJitsiMeeting: (callback: () => void) => void;
  onShareMeetingLink: (callback: () => void) => void;
}

const JitsiContext = createContext({} as JitsiContextData);

interface JitsiMeetingContainerProps {
  hide: boolean;
  fullPage?: boolean;
}

export const JitsiMeetingContainer = styled.div<JitsiMeetingContainerProps>`
  position: fixed;
  top: ${(props) => (props.fullPage ? 0 : 110)}px;
  right: ${(props) => (props.fullPage ? 0 : 10)}px;
  width: calc(100% - ${(props) => (props.fullPage ? 0 : 339)}px);
  height: calc(100vh - ${(props) => (props.fullPage ? 0 : 120)}px);
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  z-index: 1;
  background: ${(props) => props.theme.boxColor};

  :hover {
    > button {
      bottom: 21px;
    }
  }

  > iframe {
    border-radius: 4px;
  }
`;

const ShareMeetingLinkButton = styled.button`
  position: absolute;
  right: 135px;
  bottom: -82px;
  z-index: 10;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: bottom 0.3s ease-in;

  :hover {
    background: rgba(255, 255, 255, 0.15);
  }

  svg {
    fill: white;
    height: 24px;
    width: 24px;
    font-size: 24px;
  }
`;

export const JitsiProvider: React.FC = ({ children }) => {
  const { formatMessage } = useIntl();
  const { getJitsiDomain } = useApi();
  const user = useAuth((state) => state.user);
  const getUsageLimits = usePlanLimits((state) => state.getUsageLimits);
  const addToast = useToast((state) => state.addToast);
  const jitsiMeetingContainerRef = useRef<HTMLDivElement>(null);
  const [hideMeetingContainer, setHideMeetingContainer] = useState(true);
  const [fullPage, setFullPage] = useState(false);

  const browserHasSupport = useCallback(() => {
    try {
      return !!navigator.mediaDevices;
    } catch (e) {
      return false;
    }
  }, []);

  const isMicrophonePermissionGranted = useCallback(async () => {
    if (!browserHasSupport()) {
      return false;
    }

    return navigator.permissions.query({ name: 'microphone' } as any).then(
      ({ state }) => {
        return state === 'granted';
      },
      () => {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then(
            (stream) => {
              stream.getTracks().forEach((track) => track.stop());
              return true;
            },
            () => false
          );
      }
    );
  }, [browserHasSupport]);

  const isMicrophoneBlocked = useCallback(async () => {
    if (!browserHasSupport()) {
      return true;
    }

    return navigator.permissions.query({ name: 'microphone' } as any).then(
      ({ state }) => {
        return state === 'denied';
      },
      () => {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then(
            (stream) => {
              stream.getTracks().forEach((track) => track.stop());
              return false;
            },
            () => true
          );
      }
    );
  }, [browserHasSupport]);

  const isCameraPermissionGranted = useCallback(async () => {
    if (!browserHasSupport()) {
      return false;
    }

    return navigator.permissions.query({ name: 'camera' } as any).then(
      ({ state }) => {
        return state === 'granted';
      },
      () => {
        return navigator.mediaDevices
          .getUserMedia({ audio: false, video: true })
          .then(
            (stream) => {
              stream.getTracks().forEach((track) => track.stop());
              return true;
            },
            () => false
          );
      }
    );
  }, [browserHasSupport]);

  const isCameraBlocked = useCallback(async () => {
    if (!browserHasSupport()) {
      return true;
    }

    return navigator.permissions.query({ name: 'camera' } as any).then(
      ({ state }) => {
        return state === 'denied';
      },
      () => {
        return navigator.mediaDevices
          .getUserMedia({ audio: false, video: true })
          .then(
            (stream) => {
              stream.getTracks().forEach((track) => track.stop());
              return false;
            },
            () => true
          );
      }
    );
  }, [browserHasSupport]);

  const onAudioMuteChanged = useCallback(
    (callback: (muted: boolean) => void) => {
      audioMuteChangedCallback = callback;
    },
    []
  );

  const onVideoMuteChanged = useCallback(
    (callback: (muted: boolean) => void) => {
      videoMuteChangedCallback = callback;
    },
    []
  );

  const onScreenSharedChanged = useCallback(
    (callback: (shared: boolean) => void) => {
      screenSharedChangedCallback = callback;
    },
    []
  );

  const onJoinJitsiMeeting = useCallback(
    (callback: (meetingId: string) => void) => {
      joinMeetingCallback = callback;
    },
    []
  );

  const onLeaveJitsiMeeting = useCallback((callback: () => void) => {
    leaveMeetingCallback = callback;
  }, []);

  const onShareMeetingLink = useCallback((callback: () => void) => {
    shareMeetingLinkCallback = callback;
  }, []);

  const leaveJitsiMeeting = useCallback((meetingId: string) => {
    if (!externalApi[meetingId]) return;
    externalApi[meetingId].executeCommand('hangup');
    setHideMeetingContainer(true);
  }, []);

  const enterJitsiMeeting = useCallback(
    async (
      meetingId: string,
      callback: (hasPermission: boolean) => void,
      guestUser?: UserModel,
      guestToken?: string,
      startMuted?: boolean,
      disconnectWhenAlone = true,
      considerNumberOfUsersLimit = true
    ) => {
      if (externalApi[meetingId]) return;
      const meetingUser = guestUser || user;

      Object.keys(externalApi).forEach((key) => {
        externalApi[key].executeCommand('hangup');
      });

      if (!jitsiDomain) {
        jitsiDomain = await getJitsiDomain(guestToken);

        const jitsiExternalApiScript = document.createElement('script');
        jitsiExternalApiScript.async = false;
        jitsiExternalApiScript.src = `https://${jitsiDomain}/external_api.js`;
        jitsiExternalApiScript.onload = async () => {
          await enterJitsiMeeting(
            meetingId,
            callback,
            guestUser,
            guestToken,
            startMuted,
            disconnectWhenAlone,
            considerNumberOfUsersLimit
          );
        };

        document.body.appendChild(jitsiExternalApiScript);
        return;
      }

      setFullPage(!!guestUser);

      const limits = await getUsageLimits(guestToken);

      const allowMicrophone =
        limits.monthlyAudioHoursLimit > limits.audioHoursUsage;
      const allowCamera =
        limits.monthlyVideoHoursLimit > limits.videoHoursUsage;
      const allowScreenSharing =
        limits.monthlyScreenSharingHoursLimit > limits.screenSharingHoursUsage;

      const newExternalApi = startConference(
        jitsiDomain,
        meetingId,
        meetingUser,
        jitsiMeetingContainerRef.current,
        allowMicrophone,
        allowCamera,
        allowScreenSharing,
        startMuted
      );

      newExternalApi.addEventListener(
        'videoConferenceJoined',
        (e: { roomName: string }) => {
          const participantsInfo: any[] = newExternalApi.getParticipantsInfo();
          const participantsCount = participantsInfo.filter(
            (x) => !x.participantId.includes('-')
          ).length;

          if (
            considerNumberOfUsersLimit &&
            participantsCount > limits.usersPerMeetingLimit
          ) {
            leaveJitsiMeeting(meetingId);
            addToast({
              type: 'info',
              title: formatMessage({ id: AppMessages.limitsCannotJoinMeeting }),
              description: formatMessage({
                id: AppMessages.limitsUsersPerMeeting,
              }),
              duration: 10000,
            });
            return;
          }

          if (startMuted) {
            if (audioMuteChangedCallback) audioMuteChangedCallback(true);
          }

          setTimeout(() => {
            newExternalApi.executeCommand('avatarUrl', meetingUser.picture);
            newExternalApi.executeCommand('toggleTileView');
          }, 1000);

          if (joinMeetingCallback) joinMeetingCallback(e.roomName);

          if (disconnectWhenAlone) {
            setDisconnectAloneInConference(
              meetingId,
              () => {
                leaveJitsiMeeting(meetingId);
              },
              10
            );
          }
        }
      );

      newExternalApi.addEventListener(
        'videoConferenceLeft',
        (e: { roomName: string }) => {
          newExternalApi.dispose();
          delete externalApi[e.roomName];
          if (Object.keys(externalApi).length === 0 && leaveMeetingCallback)
            leaveMeetingCallback();
          setHideMeetingContainer(true);
        }
      );

      newExternalApi.addEventListener(
        'audioMuteStatusChanged',
        (e: { muted: boolean }) => {
          if (audioMuteChangedCallback) audioMuteChangedCallback(e.muted);
        }
      );

      newExternalApi.addEventListener(
        'videoMuteStatusChanged',
        (e: { muted: boolean }) => {
          if (videoMuteChangedCallback) videoMuteChangedCallback(e.muted);
        }
      );

      newExternalApi.addEventListener(
        'screenSharingStatusChanged',
        (e: { on: boolean }) => {
          if (screenSharedChangedCallback) screenSharedChangedCallback(!!e.on);
        }
      );

      newExternalApi.addEventListener('participantJoined', () => {
        clearDisconnectAloneInConference(meetingId);

        setTimeout(() => {
          newExternalApi.executeCommand('avatarUrl', meetingUser.picture);
        }, 1000);
      });

      newExternalApi.addEventListener('participantLeft', () => {
        if (!disconnectWhenAlone) return;
        setDisconnectAloneInConference(meetingId, () => {
          leaveJitsiMeeting(meetingId);
        });
      });

      externalApi[meetingId] = newExternalApi;

      const permissionDenied = await isMicrophoneBlocked();
      callback(!permissionDenied);
    },
    [
      user,
      getJitsiDomain,
      getUsageLimits,
      formatMessage,
      addToast,
      isMicrophoneBlocked,
      leaveJitsiMeeting,
    ]
  );

  const toggleMute = useCallback((meetingId: string) => {
    if (!externalApi[meetingId]) return;
    externalApi[meetingId].executeCommand('toggleAudio');
  }, []);

  const toggleVideo = useCallback((meetingId: string) => {
    if (!externalApi[meetingId]) return;
    externalApi[meetingId].executeCommand('toggleVideo');
  }, []);

  const toggleScreenSharing = useCallback((meetingId: string) => {
    if (!externalApi[meetingId]) return;
    externalApi[meetingId].executeCommand('toggleShareScreen');
  }, []);

  const isMeetingContainerVisible = useCallback(() => {
    return !hideMeetingContainer;
  }, [hideMeetingContainer]);

  const setMeetingContainerVisibility = useCallback((visible: boolean) => {
    setHideMeetingContainer(!visible);
  }, []);

  const handleShareMeetingLink = useCallback(() => {
    shareMeetingLinkCallback();
  }, []);

  const contextValue = useMemo<JitsiContextData>(
    () => ({
      browserHasSupport,
      isMicrophonePermissionGranted,
      isMicrophoneBlocked,
      isCameraPermissionGranted,
      isCameraBlocked,
      enterJitsiMeeting,
      leaveJitsiMeeting,
      toggleMute,
      toggleVideo,
      toggleScreenSharing,
      isMeetingContainerVisible,
      setMeetingContainerVisibility,
      onAudioMuteChanged,
      onVideoMuteChanged,
      onScreenSharedChanged,
      onJoinJitsiMeeting,
      onLeaveJitsiMeeting,
      onShareMeetingLink,
    }),
    [
      browserHasSupport,
      isMicrophonePermissionGranted,
      isMicrophoneBlocked,
      isCameraPermissionGranted,
      isCameraBlocked,
      enterJitsiMeeting,
      leaveJitsiMeeting,
      toggleMute,
      toggleVideo,
      toggleScreenSharing,
      isMeetingContainerVisible,
      setMeetingContainerVisibility,
      onAudioMuteChanged,
      onVideoMuteChanged,
      onScreenSharedChanged,
      onJoinJitsiMeeting,
      onLeaveJitsiMeeting,
      onShareMeetingLink,
    ]
  );

  const shareMeetingLinkButton = useMemo(
    () =>
      !fullPage && (
        <ShareMeetingLinkButton onClick={handleShareMeetingLink}>
          <ShareIcon />
        </ShareMeetingLinkButton>
      ),
    [fullPage, handleShareMeetingLink]
  );

  const jitsiMeetingContainer = useMemo(
    () => (
      <JitsiMeetingContainer
        hide={hideMeetingContainer}
        ref={jitsiMeetingContainerRef}
        fullPage={fullPage}
      >
        {shareMeetingLinkButton}
      </JitsiMeetingContainer>
    ),
    [fullPage, hideMeetingContainer, shareMeetingLinkButton]
  );

  return (
    <JitsiContext.Provider value={contextValue}>
      {jitsiMeetingContainer}
      {children}
    </JitsiContext.Provider>
  );
};

export function useJitsi<TResult>(
  selector: (state: JitsiContextData) => TResult
): TResult {
  return useContextSelector(JitsiContext, selector);
}
