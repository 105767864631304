import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { ProseiaPageContainer } from '../../components/ProseiaPageContainer';

interface RouteParams {
  encodedData?: string;
}

const Start: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const addToast = useToast((state) => state.addToast);
  const firstSignIn = useAuth((state) => state.firstSignIn);
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleRequestError = useCallback(
    (error) => {
      const message = Array.isArray(error.message)
        ? error.message.join('\n')
        : error.message;
      addToast({
        type: 'error',
        title: 'Error',
        description: message,
        duration: 10000,
      });
      history.push('/');
    },
    [addToast, history]
  );

  useEffect(() => {
    const encodedData = match.params.encodedData || '';
    if (!encodedData) return;
    try {
      const dataString = atob(encodedData);
      const data = JSON.parse(dataString);
      setEmail(data.email);
      setPassword(data.password);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setPhoneNumber(data.phoneNumber);
    } catch (error) {
      setEmail('');
      setPassword('');
      setFirstName('');
      setLastName('');
      setPhoneNumber('');
      addToast({
        type: 'error',
        title: 'Error',
        description: 'Invalid token',
        duration: 10000,
      });
    }
  }, [match.params.encodedData, addToast]);

  useEffect(() => {
    if (!email || !password || !firstName || !phoneNumber) return;
    firstSignIn({
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      roleDescription: '',
    }).catch(handleRequestError);
  }, [
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    firstSignIn,
    handleRequestError,
  ]);

  return <ProseiaPageContainer loading />;
};

export default Start;
