import React, { useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Channel } from 'mattermost-redux/types/channels';

import { AppMessages } from '../../languages';
import { useMattermost, TYPING_UPDATE_INTERVAL } from '../../hooks/mattermost';
import { getFormattedUserName } from '../../hooks/office';

interface Props {
  channel: Channel;
}

interface Typings {
  names: string[];
  timeouts: number[];
}

export const ChatTypingInfo: React.FC<Props> = ({ channel }) => {
  const { formatMessage } = useIntl();
  const channelTyping = useMattermost((state) => state.userTyping[channel.id]);
  const getProseiaUser = useMattermost((state) => state.getProseiaUser);
  const [, setLastUpdate] = useState(Date.now());

  const empty = useMemo(() => <div className="typing-info" />, []);

  const typings = Object.keys(channelTyping || {}).reduce<Typings>(
    (previous, userId) => {
      const lastTypingAt = channelTyping[userId];
      const now = Date.now();

      if (!lastTypingAt || now - lastTypingAt > TYPING_UPDATE_INTERVAL)
        return previous;

      const user = getProseiaUser(userId);
      const name = getFormattedUserName(user);
      const timeout = TYPING_UPDATE_INTERVAL - (now - lastTypingAt);

      return {
        names: [...previous.names, name],
        timeouts: [...previous.timeouts, timeout],
      };
    },
    { names: [], timeouts: [] }
  );

  useEffect(() => {
    const interval = Math.min(...typings.timeouts);

    const timeout = setTimeout(() => {
      if (!typings.timeouts.length) return;
      setLastUpdate(Date.now());
    }, interval);

    return () => clearTimeout(timeout);
  }, [typings.timeouts]);

  if (typings.names.length === 0) return empty;

  return (
    <div className="typing-info">
      {formatMessage({ id: AppMessages.chatTyping })}
      :&nbsp;
      {typings.names.join(', ')}
    </div>
  );
};
