import axios from 'axios';

const api = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*', 
  },
});

export interface Model {
  id: string;
  createdAt?: Date;
}

api.interceptors.response.use(undefined, (error) =>
  Promise.reject(error.response?.data || error)
);

export const createCrudService = <T extends Model>(controllerName: string) => {
  return {
    getAll: async () => {
      const response = await api.get<T[]>(controllerName);
      return response.data;
    },
    getById: async (id: string) => {
      if (!id) throw new Error('"id" is required');
      const response = await api.get<T>(`${controllerName}/${id}`);
      return response.data;
    },
    post: async (object: T) => {
      if (!object) throw new Error('"object" is required');
      const { id, createdAt, ...rest } = object;
      const response = await api.post<string>(controllerName, { ...rest });
      return response.data;
    },
    patch: async (id: string, object: Partial<T>) => {
      if (!object) throw new Error('"object" is required');
      const { id: objectId, createdAt, ...rest } = object;
      const response = await api.patch<string>(`${controllerName}/${id}`, rest);
      return response.data;
    },
    delete: async (id: string) => {
      if (!id) throw new Error('"id" is required');
      const response = await api.delete<T>(`${controllerName}/${id}`);
      return response.data;
    },
  };
};

export const createNestedCrudService = <T extends Model>(
  controllerName: string,
  subPath: string
) => {
  return {
    getAll: async (parentId: string) => {
      const response = await api.get<T[]>(
        `${controllerName}/${parentId}/${subPath}`
      );
      return response.data;
    },
    getById: async (parentId: string, id: string) => {
      if (!id) throw new Error('"id" is required');
      const response = await api.get<T>(
        `${controllerName}/${parentId}/${subPath}/${id}`
      );
      return response.data;
    },
    post: async (parentId: string, object: T) => {
      if (!object) throw new Error('"object" is required');
      const { id, createdAt, ...rest } = object;
      const response = await api.post<string>(
        `${controllerName}/${parentId}/${subPath}`,
        { ...rest }
      );
      return response.data;
    },
    patch: async (parentId: string, id: string, object: Partial<T>) => {
      if (!object) throw new Error('"object" is required');
      const { id: objectId, createdAt, ...rest } = object;
      const response = await api.patch<string>(
        `${controllerName}/${parentId}/${subPath}/${id}`,
        rest
      );
      return response.data;
    },
    delete: async (parentId: string, id: string) => {
      if (!id) throw new Error('"id" is required');
      const response = await api.delete<T>(
        `${controllerName}/${parentId}/${subPath}/${id}`
      );
      return response.data;
    },
  };
};

export default api;
