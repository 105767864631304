import { shade } from 'polished';
import { styled } from '../../themes';
import { FONT_COLOR } from '../../components/ProseiaPageContainer/styles';

export const ExtensionRequiredContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 30%;
  }

  > * {
    width: 100%;
    margin: 30px 0;
    @media only screen and (max-height: 600px) {
      margin: 15px 0;
    }
  }

  /* > * + * {
    margin-top: 30px;
  } */
`;

export const ExtensionRequiredLinksContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-around;

  > * {
    margin-right: 8px;
  }
`;

export const ExtensionRequiredLinks = styled.a`
  background: ${FONT_COLOR};
  border-radius: 25px;
  color: #fff;
  padding: 8px;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  font-weight: bold;
  border: none;
  transition: all 0.3s;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 16px;
    width: 30px;
    height: 30px;
    fill: #fff;
  }

  :hover {
    background: ${shade(0.3, FONT_COLOR)};
  }
`;

export const ExtensionRequiredTitleContainer = styled.div`
  color: ${FONT_COLOR};
  font-size: 25px;
  font-weight: bold;
  text-align: center;
`;
