import React, { useState, useCallback, useEffect, useMemo } from 'react';
import io from 'socket.io-client';

import { createContext, useContextSelector } from './context';
import { useAuth } from './auth';
import { UserInfoProps } from './office';
import { UserModel, RoomModel, OfficeModel, AppVersions } from './api';

const socketRoute =
  window.location.port !== '3001' ? '/socket' : 'http://localhost:3000/socket';

interface SocketContextData {
  connect: () => void;
  setOnConnectUserInfo: (userInfo: UserInfoProps) => void;
  emitPartialUserInfo: (partialUserInfo: Partial<UserInfoProps>) => void;
  enableSound: () => void;
  disableSound: () => void;
  enableMic: () => void;
  disableMic: () => void;
  enableVideo: () => void;
  disableVideo: () => void;
  shareScreen: () => void;
  unshareScreen: () => void;
  enterRoom: (officeId: string, roomId: string) => void;
  leaveRoom: () => void;
  enterMeeting: (officeId: string, roomId: string, meetingId: string) => void;
  leaveMeeting: () => void;
  inviteToMeeting: (
    userId: string,
    officeId: string,
    roomId: string,
    meetingId: string
  ) => void;
  acceptMeetingInvite: (
    userId: string,
    officeId: string,
    roomId: string,
    meetingId: string
  ) => void;
  declineMeetingInvite: (
    userId: string,
    officeId: string,
    roomId: string,
    meetingId: string
  ) => void;
  cancelMeetingInvite: (
    userId: string,
    officeId: string,
    roomId: string,
    meetingId: string
  ) => void;
  onUserJoined: (callback: (data: UserInfoProps) => void) => void;
  onUserLeft: (callback: (data: UserModel) => void) => void;
  onRegisteredUsersInfo: (
    callback: (data: { [userId: string]: UserInfoProps }) => void
  ) => void;
  onPartialUserInfo: (
    callback: (data: {
      userId: string;
      partialUserInfo: Partial<UserInfoProps>;
    }) => void
  ) => void;
  onPartialAnonymousUserInfo: (
    callback: (data: {
      userId: string;
      clientId: string;
      partialUserInfo: Partial<UserInfoProps>;
    }) => void
  ) => void;
  onAnonymousUserJoined: (
    callback: (data: { user: UserInfoProps; clientId: string }) => void
  ) => void;
  onAnonymousUserLeft: (
    callback: (data: { user: UserInfoProps; clientId: string }) => void
  ) => void;
  onAnonymousUsersInfo: (
    callback: (data: {
      [userId: string]: {
        [clientId: string]: UserInfoProps;
      };
    }) => void
  ) => void;
  onAppVersions: (callback: (data: AppVersions) => void) => void;
  onRoomCreated: (
    callback: (data: { officeId: string; room: RoomModel }) => void
  ) => void;
  onRoomUpdated: (
    callback: (data: { officeId: string; room: RoomModel }) => void
  ) => void;
  onRoomDeleted: (
    callback: (data: { officeId: string; room: RoomModel }) => void
  ) => void;
  onOfficeCreated: (callback: (data: OfficeModel) => void) => void;
  onOfficeUpdated: (callback: (data: OfficeModel) => void) => void;
  onOfficeDeleted: (callback: (data: OfficeModel) => void) => void;
  onUserCreated: (callback: (data: UserModel) => void) => void;
  onUserUpdated: (callback: (data: UserModel) => void) => void;
  onUserDeleted: (callback: (data: UserModel) => void) => void;
  onUserEnteredMeeting: (
    callback: (data: {
      user: UserModel;
      officeId: string;
      roomId: string;
      meetingId: string;
    }) => void
  ) => void;
  onMeetingInvite: (
    callback: (data: {
      user: UserModel;
      officeId: string;
      roomId: string;
      meetingId: string;
    }) => void
  ) => void;
  onMeetingInviteAccepted: (
    callback: (data: {
      user: UserModel;
      officeId: string;
      roomId: string;
      meetingId: string;
    }) => void
  ) => void;
  onMeetingInviteDeclined: (
    callback: (data: {
      user: UserModel;
      officeId: string;
      roomId: string;
      meetingId: string;
    }) => void
  ) => void;
  onMeetingInviteCanceled: (
    callback: (data: {
      user: UserModel;
      officeId: string;
      roomId: string;
      meetingId: string;
    }) => void
  ) => void;
}

const SocketContext = createContext({} as SocketContextData);

let previousSocket: SocketIOClient.Socket | undefined;

export const SocketProvider: React.FC = ({ children }) => {
  const user = useAuth((state) => state.user);
  const signOut = useAuth((state) => state.signOut);
  const [socket, setSocket] = useState<SocketIOClient.Socket>();

  const emitPartialUserInfo = useCallback(
    (partialUserInfo: Partial<UserInfoProps>) => {
      if (!socket) return;
      socket.emit('partial_user_info', partialUserInfo);
    },
    [socket]
  );

  const enableSound = useCallback(() => {
    if (!socket) return;
    socket.emit('sound_enabled');
  }, [socket]);

  const disableSound = useCallback(() => {
    if (!socket) return;
    socket.emit('sound_disabled');
  }, [socket]);

  const enableMic = useCallback(() => {
    if (!socket) return;
    socket.emit('mic_enabled');
  }, [socket]);

  const disableMic = useCallback(() => {
    if (!socket) return;
    socket.emit('mic_disabled');
  }, [socket]);

  const enableVideo = useCallback(() => {
    if (!socket) return;
    socket.emit('video_enabled');
  }, [socket]);

  const disableVideo = useCallback(() => {
    if (!socket) return;
    socket.emit('video_disabled');
  }, [socket]);

  const shareScreen = useCallback(() => {
    if (!socket) return;
    socket.emit('screen_shared');
  }, [socket]);

  const unshareScreen = useCallback(() => {
    if (!socket) return;
    socket.emit('screen_unshared');
  }, [socket]);

  const enterRoom = useCallback(
    (officeId: string, roomId: string) => {
      if (!socket) return;
      socket.emit('enter_room', { officeId, roomId });
    },
    [socket]
  );

  const leaveRoom = useCallback(() => {
    if (!socket) return;
    socket.emit('leave_room');
  }, [socket]);

  const enterMeeting = useCallback(
    (officeId: string, roomId: string, meetingId: string) => {
      if (!socket) return;
      socket.emit('enter_meeting', { officeId, roomId, meetingId });
    },
    [socket]
  );

  const leaveMeeting = useCallback(() => {
    if (!socket) return;
    socket.emit('leave_meeting');
  }, [socket]);

  const inviteToMeeting = useCallback(
    (userId: string, officeId: string, roomId: string, meetingId: string) => {
      if (!socket) return;
      socket.emit('invite_to_meeting', { userId, officeId, roomId, meetingId });
    },
    [socket]
  );

  const acceptMeetingInvite = useCallback(
    (userId: string, officeId: string, roomId: string, meetingId: string) => {
      if (!socket) return;
      socket.emit('accept_meeting_invite', {
        userId,
        officeId,
        roomId,
        meetingId,
      });
    },
    [socket]
  );

  const declineMeetingInvite = useCallback(
    (userId: string, officeId: string, roomId: string, meetingId: string) => {
      if (!socket) return;
      socket.emit('decline_meeting_invite', {
        userId,
        officeId,
        roomId,
        meetingId,
      });
    },
    [socket]
  );

  const cancelMeetingInvite = useCallback(
    (userId: string, officeId: string, roomId: string, meetingId: string) => {
      if (!socket) return;
      socket.emit('cancel_meeting_invite', {
        userId,
        officeId,
        roomId,
        meetingId,
      });
    },
    [socket]
  );

  const subscribe = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (event: string, callback: Function) => {
      if (!socket) return;
      socket.removeListener(event);
      socket.on(event, callback);
    },
    [socket]
  );

  const onUserJoined = useCallback(
    (callback: (data: UserInfoProps) => void) => {
      subscribe('user_joined', callback);
    },
    [subscribe]
  );

  const onUserLeft = useCallback(
    (callback: (data: UserModel) => void) => {
      subscribe('user_left', callback);
    },
    [subscribe]
  );

  const onRegisteredUsersInfo = useCallback(
    (callback: (data: { [userId: string]: UserInfoProps }) => void) => {
      subscribe('registered_users_info', callback);
    },
    [subscribe]
  );

  const onPartialUserInfo = useCallback(
    (
      callback: (data: {
        userId: string;
        partialUserInfo: Partial<UserInfoProps>;
      }) => void
    ) => {
      subscribe('partial_user_info', callback);
    },
    [subscribe]
  );

  const onPartialAnonymousUserInfo = useCallback(
    (
      callback: (data: {
        userId: string;
        clientId: string;
        partialUserInfo: Partial<UserInfoProps>;
      }) => void
    ) => {
      subscribe('partial_anonymous_user_info', callback);
    },
    [subscribe]
  );

  const onAnonymousUserJoined = useCallback(
    (callback: (data: { user: UserInfoProps; clientId: string }) => void) => {
      subscribe('anonymous_user_joined', callback);
    },
    [subscribe]
  );

  const onAnonymousUserLeft = useCallback(
    (callback: (data: { user: UserInfoProps; clientId: string }) => void) => {
      subscribe('anonymous_user_left', callback);
    },
    [subscribe]
  );

  const onAnonymousUsersInfo = useCallback(
    (
      callback: (data: {
        [userId: string]: { [clientId: string]: UserInfoProps };
      }) => void
    ) => {
      subscribe('anonymous_users_info', callback);
    },
    [subscribe]
  );

  const onAppVersions = useCallback(
    (callback: (data: AppVersions) => void) => {
      subscribe('app_versions', callback);
    },
    [subscribe]
  );

  const onRoomCreated = useCallback(
    (callback: (data: { officeId: string; room: RoomModel }) => void) => {
      subscribe('room_created', callback);
    },
    [subscribe]
  );

  const onRoomUpdated = useCallback(
    (callback: (data: { officeId: string; room: RoomModel }) => void) => {
      subscribe('room_updated', callback);
    },
    [subscribe]
  );

  const onRoomDeleted = useCallback(
    (callback: (data: { officeId: string; room: RoomModel }) => void) => {
      subscribe('room_deleted', callback);
    },
    [subscribe]
  );

  const onOfficeCreated = useCallback(
    (callback: (data: OfficeModel) => void) => {
      subscribe('office_created', callback);
    },
    [subscribe]
  );

  const onOfficeUpdated = useCallback(
    (callback: (data: OfficeModel) => void) => {
      subscribe('office_updated', callback);
    },
    [subscribe]
  );

  const onOfficeDeleted = useCallback(
    (callback: (data: OfficeModel) => void) => {
      subscribe('office_deleted', callback);
    },
    [subscribe]
  );

  const onUserCreated = useCallback(
    (callback: (data: UserModel) => void) => {
      subscribe('user_created', callback);
    },
    [subscribe]
  );

  const onUserUpdated = useCallback(
    (callback: (data: UserModel) => void) => {
      subscribe('user_updated', callback);
    },
    [subscribe]
  );

  const onUserDeleted = useCallback(
    (callback: (data: UserModel) => void) => {
      subscribe('user_deleted', callback);
    },
    [subscribe]
  );

  const onUserEnteredMeeting = useCallback(
    (
      callback: (data: {
        user: UserModel;
        officeId: string;
        roomId: string;
        meetingId: string;
      }) => void
    ) => {
      subscribe('user_entered_meeting', callback);
    },
    [subscribe]
  );

  const onMeetingInvite = useCallback(
    (
      callback: (data: {
        user: UserModel;
        officeId: string;
        roomId: string;
        meetingId: string;
      }) => void
    ) => {
      subscribe('meeting_invite', callback);
    },
    [subscribe]
  );

  const onMeetingInviteAccepted = useCallback(
    (
      callback: (data: {
        user: UserModel;
        officeId: string;
        roomId: string;
        meetingId: string;
      }) => void
    ) => {
      subscribe('meeting_invite_accepted', callback);
    },
    [subscribe]
  );

  const onMeetingInviteDeclined = useCallback(
    (
      callback: (data: {
        user: UserModel;
        officeId: string;
        roomId: string;
        meetingId: string;
      }) => void
    ) => {
      subscribe('meeting_invite_declined', callback);
    },
    [subscribe]
  );

  const onMeetingInviteCanceled = useCallback(
    (
      callback: (data: {
        user: UserModel;
        officeId: string;
        roomId: string;
        meetingId: string;
      }) => void
    ) => {
      subscribe('meeting_invite_canceled', callback);
    },
    [subscribe]
  );

  const setOnConnectUserInfo = useCallback(
    (userInfo: UserInfoProps) => {
      if (!socket) return;
      subscribe('connect', () => {
        socket.emit('register', userInfo);
      });
    },
    [socket, subscribe]
  );

  const connect = useCallback(() => {
    if (!socket) return;

    if (socket.connected) {
      console.log('already connected');
      return;
    }
    subscribe('error', console.error);
    // subscribe('disconnect', () => console.log('socket disconnected!'));

    subscribe('sign_out', signOut);

    socket.open();
  }, [socket, subscribe, signOut]);

  useEffect(() => {
    previousSocket = socket;
  }, [socket]);

  useEffect(() => {
    if (previousSocket?.connected) previousSocket.close();

    setSocket(
      io(socketRoute, {
        autoConnect: false,
        transports: ['websocket'],
        query: {
          'access-token': user.accessToken,
        },
        // transportOptions: {
        //   polling: {
        //     extraHeaders: {
        //       'access-token': user.accessToken,
        //     },
        //   },
        // },
      })
    );
  }, [user.accessToken]);

  const contextValue = useMemo<SocketContextData>(
    () => ({
      connect,
      setOnConnectUserInfo,
      emitPartialUserInfo,
      enableSound,
      disableSound,
      enableMic,
      disableMic,
      enableVideo,
      disableVideo,
      shareScreen,
      unshareScreen,
      enterRoom,
      leaveRoom,
      enterMeeting,
      leaveMeeting,
      inviteToMeeting,
      acceptMeetingInvite,
      declineMeetingInvite,
      cancelMeetingInvite,
      onUserJoined,
      onUserLeft,
      onRegisteredUsersInfo,
      onPartialUserInfo,
      onPartialAnonymousUserInfo,
      onAnonymousUserJoined,
      onAnonymousUserLeft,
      onAnonymousUsersInfo,
      onAppVersions,
      onOfficeCreated,
      onOfficeUpdated,
      onOfficeDeleted,
      onRoomCreated,
      onRoomUpdated,
      onRoomDeleted,
      onUserCreated,
      onUserUpdated,
      onUserDeleted,
      onUserEnteredMeeting,
      onMeetingInvite,
      onMeetingInviteAccepted,
      onMeetingInviteDeclined,
      onMeetingInviteCanceled,
    }),
    [
      connect,
      setOnConnectUserInfo,
      emitPartialUserInfo,
      enableSound,
      disableSound,
      enableMic,
      disableMic,
      enableVideo,
      disableVideo,
      shareScreen,
      unshareScreen,
      enterRoom,
      leaveRoom,
      enterMeeting,
      leaveMeeting,
      inviteToMeeting,
      acceptMeetingInvite,
      declineMeetingInvite,
      cancelMeetingInvite,
      onUserJoined,
      onUserLeft,
      onRegisteredUsersInfo,
      onPartialUserInfo,
      onPartialAnonymousUserInfo,
      onAnonymousUserJoined,
      onAnonymousUserLeft,
      onAnonymousUsersInfo,
      onAppVersions,
      onOfficeCreated,
      onOfficeUpdated,
      onOfficeDeleted,
      onRoomCreated,
      onRoomUpdated,
      onRoomDeleted,
      onUserCreated,
      onUserUpdated,
      onUserDeleted,
      onUserEnteredMeeting,
      onMeetingInvite,
      onMeetingInviteAccepted,
      onMeetingInviteDeclined,
      onMeetingInviteCanceled,
    ]
  );

  return (
    <SocketContext.Provider value={contextValue}>
      {children}
    </SocketContext.Provider>
  );
};

export function useSocket<TResult>(
  selector: (state: SocketContextData) => TResult
): TResult {
  return useContextSelector(SocketContext, selector);
}
