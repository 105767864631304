import React, { useState, useCallback, useMemo } from 'react';
import io from 'socket.io-client';

import { createContext, useContextSelector } from './context';
import { UserInfoProps } from './office';
import { JitsiProvider } from './jitsi';
import { PlanLimitsProvider } from './plan-limits';

interface GuestContextData {
  initialize: (guestToken: string) => void;
  connect: () => void;
  disconnect: () => void;
  setOnConnectUserInfo: (userInfo: UserInfoProps) => void;
  emitPartialUserInfo: (partialUserInfo: Partial<UserInfoProps>) => void;
  enableSound: () => void;
  disableSound: () => void;
  enableMic: () => void;
  disableMic: () => void;
  enableVideo: () => void;
  disableVideo: () => void;
  shareScreen: () => void;
  unshareScreen: () => void;
}

const socketRoute =
  window.location.port !== '3001' ? '/guests' : 'http://localhost:3000/guests';

const GuestContext = createContext({} as GuestContextData);

export const GuesttProvider: React.FC = ({ children }) => {
  const [socket, setSocket] = useState<SocketIOClient.Socket>();

  const emitPartialUserInfo = useCallback(
    (partialUserInfo: Partial<UserInfoProps>) => {
      if (!socket) return;
      socket.emit('partial_user_info', partialUserInfo);
    },
    [socket]
  );

  const enableSound = useCallback(() => {
    if (!socket) return;
    socket.emit('sound_enabled');
  }, [socket]);

  const disableSound = useCallback(() => {
    if (!socket) return;
    socket.emit('sound_disabled');
  }, [socket]);

  const enableMic = useCallback(() => {
    if (!socket) return;
    socket.emit('mic_enabled');
  }, [socket]);

  const disableMic = useCallback(() => {
    if (!socket) return;
    socket.emit('mic_disabled');
  }, [socket]);

  const enableVideo = useCallback(() => {
    if (!socket) return;
    socket.emit('video_enabled');
  }, [socket]);

  const disableVideo = useCallback(() => {
    if (!socket) return;
    socket.emit('video_disabled');
  }, [socket]);

  const shareScreen = useCallback(() => {
    if (!socket) return;
    socket.emit('screen_shared');
  }, [socket]);

  const unshareScreen = useCallback(() => {
    if (!socket) return;
    socket.emit('screen_unshared');
  }, [socket]);

  const subscribe = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (event: string, callback: Function) => {
      if (!socket) return;
      socket.removeListener(event);
      socket.on(event, callback);
    },
    [socket]
  );

  const setOnConnectUserInfo = useCallback(
    (userInfo: UserInfoProps) => {
      subscribe('connect', () => {
        if (!socket) return;
        socket.emit('register', userInfo);
      });
    },
    [socket, subscribe]
  );

  const initialize = useCallback((guestToken: string) => {
    const newSocket = io(socketRoute, {
      autoConnect: false,
      transports: ['websocket'],
      query: {
        'access-token': guestToken,
      },
      // transportOptions: {
      //   polling: {
      //     extraHeaders: {
      //       'access-token': guestToken,
      //     },
      //   },
      // },
    });
    setSocket(newSocket);
  }, []);

  const connect = useCallback(() => {
    if (!socket || socket?.connected) return;
    subscribe('error', console.error);
    socket.open();
  }, [socket, subscribe]);

  const disconnect = useCallback(() => {
    if (!socket) return;
    if (!socket?.connected) return;
    socket.close();
  }, [socket]);

  const contextValue = useMemo<GuestContextData>(
    () => ({
      initialize,
      connect,
      disconnect,
      setOnConnectUserInfo,
      emitPartialUserInfo,
      enableSound,
      disableSound,
      enableMic,
      disableMic,
      enableVideo,
      disableVideo,
      shareScreen,
      unshareScreen,
    }),
    [
      initialize,
      connect,
      disconnect,
      setOnConnectUserInfo,
      emitPartialUserInfo,
      enableSound,
      disableSound,
      enableMic,
      disableMic,
      enableVideo,
      disableVideo,
      shareScreen,
      unshareScreen,
    ]
  );

  return (
    <GuestContext.Provider value={contextValue}>
      <PlanLimitsProvider>
        <JitsiProvider>{children}</JitsiProvider>
      </PlanLimitsProvider>
    </GuestContext.Provider>
  );
};

export function useGuest<TResult>(
  selector: (state: GuestContextData) => TResult
): TResult {
  return useContextSelector(GuestContext, selector);
}
