import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { SpinnerIcon } from '../Icons';

interface Props {
  src: string;
  alt?: string;
  onResize?: (width: number, height: number) => void;
}

export const ChatPostImage: React.FC<Props> = React.memo(
  ({ src, alt = '', onResize }) => {
    const [size, setSize] = useState({ width: 0, height: 0 });
    const [loading, setLoading] = useState(true);
    const resizeEventTimeout = useRef<NodeJS.Timeout>();

    const handleResize = useCallback((width: number, height: number) => {
      if (resizeEventTimeout.current) clearTimeout(resizeEventTimeout.current);
      resizeEventTimeout.current = setTimeout(() => {
        setSize({ width, height });
        resizeEventTimeout.current = undefined;
      }, 50);
    }, []);

    useEffect(() => {
      if (onResize) onResize(size.width, size.height);
    }, [size, onResize]);

    const image = useMemo(
      () => (
        <img
          src={src}
          alt={alt}
          style={{ maxWidth: '100%', maxHeight: '100%', display: 'block' }}
          onLoad={() => setLoading(false)}
        />
      ),
      [alt, src]
    );

    const content = useMemo(
      () => (
        <div style={{ maxWidth: '70%', maxHeight: '100%' }}>
          <div style={{ maxWidth: '100%' }}>
            {loading && <SpinnerIcon />}
            {image}
          </div>
        </div>
      ),
      [loading, image]
    );

    return (
      <ReactResizeDetector handleWidth handleHeight onResize={handleResize}>
        {content}
      </ReactResizeDetector>
    );
  }
);
