/* eslint-disable camelcase */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { MdSearch } from 'react-icons/md';
import { BiChevronsDown } from 'react-icons/bi';
import { Channel } from 'mattermost-redux/types/channels';
import { Post, PostSearchResults } from 'mattermost-redux/types/posts';

import {
  ChatChannelBox,
  LoadingContainer,
  LoadPreviousButton,
  LoadNewButton,
} from './styles';
import { ChatPost } from './ChatPost';
import { ChatTypingInfo } from './ChatTypingInfo';
import { ChatInput } from './ChatInput';

import { AppMessages } from '../../languages';
import { createContext, useContextSelector } from '../../hooks/context';
import { useThemeContext } from '../../hooks/theme';
import { useMattermost, ChatWindowStatus } from '../../hooks/mattermost';
import { getFormattedUserName } from '../../hooks/office';
import {
  ChatPublicChannelIcon,
  ChatPrivateChannelIcon,
  ChatDirectChannelIcon,
  ExpandBoxIcon,
  RetractBoxIcon,
  MinimizeBoxIcon,
  CloseBoxIcon,
  SettingsIcon,
  SpinnerIcon,
} from '../Icons';
import { IconButton } from '../IconButton';
import { InputSearch } from '../InputSearch';
import { Tooltip } from '../Tooltip';

interface ChatChannelContextData {
  inputScrollHeight: number;
  setInputScrollHeight: React.Dispatch<React.SetStateAction<number>>;
  postsToSend: Post[];
  setPostsToSend: React.Dispatch<React.SetStateAction<Post[]>>;
  postsWithError: Post[];
  setPostsWithError: React.Dispatch<React.SetStateAction<Post[]>>;
  replyingToPostId: string;
  setReplyingToPostId: React.Dispatch<React.SetStateAction<string>>;
}

const ChatChannelContext = createContext({} as ChatChannelContextData);

export function useChatChannel<TResult>(
  selector: (state: ChatChannelContextData) => TResult
): TResult {
  return useContextSelector(ChatChannelContext, selector);
}

interface Props {
  channel: Channel;
  boxStatus?: ChatWindowStatus;
}

interface AutoScrollDisabled {
  [channelId: string]: boolean;
}

const autoScrollDisabled: AutoScrollDisabled = {};

export const ChatChannel: React.FC<Props> = ({
  channel,
  boxStatus = 'normal',
}) => {
  const theme = useThemeContext((state) => state.theme);
  const userId = useMattermost((state) => state.mattermostUser?.id || '');

  const postList = useMattermost((state) => state.channelPosts[channel.id]);
  const lastViewedAt = useMattermost(
    (state) => state.channelMyMemberships[channel.id]?.last_viewed_at || 0
  );
  const getChannelDisplayName = useMattermost(
    (state) => state.getChannelDisplayName
  );
  const hasUnreads = useMattermost(
    (state) => state.getChannelUnreadsMessageCount(channel) > 0
  );
  const getProseiaUser = useMattermost((state) => state.getProseiaUser);
  const loadPostsUnread = useMattermost((state) => state.loadPostsUnread);
  const loadPostsBefore = useMattermost((state) => state.loadPostsBefore);
  const loadPostsAfter = useMattermost((state) => state.loadPostsAfter);
  const loadPostsAround = useMattermost((state) => state.loadPostsAround);
  const viewChannel = useMattermost((state) => state.viewChannel);
  const closeChannel = useMattermost((state) => state.closeChannel);
  const changeChannelWindowStatus = useMattermost(
    (state) => state.changeChannelWindowStatus
  );
  const searchInChannel = useMattermost((state) => state.searchInChannel);
  const openChannelOptionsMenu = useMattermost(
    (state) => state.openChannelOptionsMenu
  );

  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [searchBoxOpenned, setSearchBoxOpenned] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchResuls, setSearchResuls] = useState<PostSearchResults>();
  const [searching, setSearching] = useState(false);
  const [scrollToPostId, setScrollToPostId] = useState('');
  const [inputScrollHeight, setInputScrollHeight] = useState(0);
  const [postsToSend, setPostsToSend] = useState<Post[]>([]);
  const [postsWithError, setPostsWithError] = useState<Post[]>([]);
  const [replyingToPostId, setReplyingToPostId] = useState('');

  const channelId = channel.id;
  const otherUserId = channel.name
    .replace(`${userId}__`, '')
    .replace(`__${userId}`, '');

  const postsById: { [postId: string]: Post } = (postList?.posts as any) || {};

  const posts = useMemo(
    () =>
      !postList
        ? []
        : postList.order.reduce<Post[]>((previous, id) => {
            const post = postsById[id];
            if (!post) return previous;
            return [post, ...previous];
          }, []),
    [postList, postsById]
  );

  const postListOrder = postList?.order || [];
  const lastViewedPost = posts.find((post) => post.create_at === lastViewedAt);
  const lastPost = posts[posts.length - 1];
  const lastViewedPostId = lastViewedPost?.id || '';
  const lastPostId = lastPost?.id || '';
  const previousPostId = postList?.prev_post_id || '';
  const nextPostId = postList?.next_post_id || '';

  const startLoading = useCallback(() => {
    setLoading(true);
  }, []);

  const endLoading = useCallback(() => {
    setLoading(false);
  }, []);

  const scrollToPost = useCallback(
    (postId: string) => {
      if (autoScrollDisabled[channelId]) {
        setTimeout(() => {
          autoScrollDisabled[channelId] = false;
        }, 500);
        return;
      }
      setTimeout(() => {
        const element = document.getElementById(postId);
        if (element) {
          element.scrollIntoView({
            behavior: 'auto',
            block: 'start',
            inline: 'start',
          });
        }
      }, 100);
    },
    [channelId]
  );

  const loadNew = useCallback(() => {
    startLoading();
    loadPostsUnread(channelId).finally(endLoading);
  }, [channelId, loadPostsUnread, startLoading, endLoading]);

  useEffect(() => {
    if (!postList) loadNew();
  }, [postList, loadNew]);

  const handleAutoScroll = useCallback(() => {
    setTimeout(() => {
      const container = document.getElementById(channelId);
      if (!container) return;
      const scrollHeight = container.scrollHeight - container.offsetHeight;
      const diff = scrollHeight - container.scrollTop;
      if (diff < container.clientHeight) {
        const element = document.getElementById(`new-divisor-${channelId}`);
        if (!element) container.scrollTop = scrollHeight;
        else
          element.scrollIntoView({
            behavior: 'auto',
            block: 'start',
            inline: 'start',
          });
      }
    }, 100);
  }, [channelId]);

  const handleClose = useCallback(() => {
    closeChannel(channel);
  }, [channel, closeChannel]);

  const handleMinimize = useCallback(() => {
    changeChannelWindowStatus(channel, 'minimized');
  }, [channel, changeChannelWindowStatus]);

  const handleMaximize = useCallback(() => {
    changeChannelWindowStatus(channel, 'maximized');
  }, [channel, changeChannelWindowStatus]);

  const handleNormalize = useCallback(() => {
    changeChannelWindowStatus(channel, 'normal');
  }, [channel, changeChannelWindowStatus]);

  const handleLoadPrevious = useCallback(() => {
    if (!previousPostId) return;

    const container = document.getElementById(channelId);
    const scrollDiff = container
      ? container.scrollHeight - container.scrollTop
      : 0;
    autoScrollDisabled[channelId] = true;
    startLoading();
    loadPostsBefore(channelId)
      .then(() => {
        if (container)
          container.scrollTop = container.scrollHeight - scrollDiff;
      })
      .finally(endLoading);
  }, [previousPostId, channelId, loadPostsBefore, startLoading, endLoading]);

  const handleScroll = useCallback(() => {
    if (loading || !nextPostId) return;

    const container = document.getElementById(channelId);

    if (
      container &&
      container.scrollTop === container.scrollHeight - container.offsetHeight
    ) {
      autoScrollDisabled[channelId] = true;
      startLoading();
      loadPostsAfter(channelId).finally(endLoading);
    }
  }, [
    loading,
    channelId,
    nextPostId,
    loadPostsAfter,
    startLoading,
    endLoading,
  ]);

  const handleSearchValueChange = useCallback(
    (value: string) => {
      setSearchResuls(undefined);
      if (!value) {
        setSearching(false);
        return;
      }
      setSearching(true);
      searchInChannel(channel, value)
        .then(setSearchResuls)
        .finally(() => setSearching(false));
    },
    [channel, searchInChannel]
  );

  const handleInputFocus = useCallback(() => {
    setSearchBoxOpenned(false);
    viewChannel(channel, true);
  }, [channel, viewChannel]);

  useEffect(() => {
    if (!searchBoxOpenned) return;
    if (searchInputRef.current) searchInputRef.current.focus();
    setSearchResuls(undefined);
  }, [searchBoxOpenned]);

  useEffect(() => {
    if (!scrollToPostId) return;
    setTimeout(() => {
      const element = document.getElementById(scrollToPostId);
      if (!element) return;
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
      element.classList.add('got-focus');
      setTimeout(() => {
        element.classList.remove('got-focus');
      }, 10000);
    }, 150);
    setScrollToPostId('');
  }, [scrollToPostId]);

  useEffect(() => {
    if (!lastViewedPostId) return;

    scrollToPost(lastViewedPostId);
  }, [lastViewedPostId, lastPostId, scrollToPost]);

  const contextValue = useMemo<ChatChannelContextData>(
    () => ({
      inputScrollHeight,
      setInputScrollHeight,
      postsToSend,
      setPostsToSend,
      postsWithError,
      setPostsWithError,
      replyingToPostId,
      setReplyingToPostId,
    }),
    [inputScrollHeight, postsToSend, postsWithError, replyingToPostId]
  );

  const headerIcon = useMemo(() => {
    if (channel.type === 'O') return <ChatPublicChannelIcon />;
    if (channel.type === 'P') return <ChatPrivateChannelIcon />;
    return <ChatDirectChannelIcon />;
  }, [channel.type]);

  const channelName = useMemo(() => {
    let displayName = getChannelDisplayName(channel);

    if (channel.type === 'D') {
      const user = getProseiaUser(otherUserId);
      displayName = getFormattedUserName(user);
    }

    return <div className="display-name">{displayName}</div>;
  }, [channel, otherUserId, getProseiaUser, getChannelDisplayName]);

  const searchButton = useMemo(
    () =>
      boxStatus !== 'minimized' && (
        <IconButton
          iconSize={18}
          buttonColor={theme.textColor}
          iconColor={theme.background}
          pressed={searchBoxOpenned}
          onClick={() => setSearchBoxOpenned(!searchBoxOpenned)}
        >
          <MdSearch />
        </IconButton>
      ),
    [boxStatus, searchBoxOpenned, theme.background, theme.textColor]
  );

  const settingsButton = useMemo(
    () =>
      channel.type !== 'D' && (
        <IconButton
          iconSize={16}
          buttonColor={theme.textColor}
          iconColor={theme.background}
          onClick={(e) => {
            openChannelOptionsMenu(channel, e.currentTarget);
          }}
        >
          <SettingsIcon />
        </IconButton>
      ),
    [channel, openChannelOptionsMenu, theme.background, theme.textColor]
  );

  const resizeButtons = useMemo(
    () => (
      <>
        <IconButton
          iconSize={boxStatus === 'minimized' ? 16 : 12}
          buttonColor={theme.textColor}
          iconColor={theme.background}
          onClick={boxStatus === 'minimized' ? handleNormalize : handleMinimize}
        >
          {boxStatus === 'minimized' ? <RetractBoxIcon /> : <MinimizeBoxIcon />}
        </IconButton>
        <IconButton
          iconSize={16}
          buttonColor={theme.textColor}
          iconColor={theme.background}
          onClick={boxStatus === 'maximized' ? handleNormalize : handleMaximize}
        >
          {boxStatus === 'maximized' ? <RetractBoxIcon /> : <ExpandBoxIcon />}
        </IconButton>
      </>
    ),
    [
      boxStatus,
      handleMaximize,
      handleMinimize,
      handleNormalize,
      theme.background,
      theme.textColor,
    ]
  );

  const closeButton = useMemo(
    () => (
      <IconButton
        iconSize={16}
        buttonColor={theme.textColor}
        iconColor={theme.background}
        onClick={handleClose}
      >
        <CloseBoxIcon />
      </IconButton>
    ),
    [handleClose, theme.background, theme.textColor]
  );

  const header = useMemo(
    () => (
      <div className="header">
        {headerIcon}
        {channelName}
        {searchButton}
        {settingsButton}
        {resizeButtons}
        {closeButton}
      </div>
    ),
    [
      headerIcon,
      channelName,
      searchButton,
      settingsButton,
      resizeButtons,
      closeButton,
    ]
  );

  const loadPreviousButton = useMemo(
    () =>
      !!previousPostId && (
        <LoadPreviousButton onClick={handleLoadPrevious} disabled={loading}>
          {formatMessage({ id: AppMessages.chatLoadPrevious })}
        </LoadPreviousButton>
      ),
    [formatMessage, handleLoadPrevious, loading, previousPostId]
  );

  const postListContainer = useMemo(
    () => (
      <div id={channelId} className="postlist" onScroll={handleScroll}>
        {loadPreviousButton}
        {posts.map((post, i) => {
          const previous = posts[i - 1];
          const showNew =
            i < posts.length - 1 &&
            post.user_id !== userId &&
            (!previous || previous.create_at <= lastViewedAt) &&
            post.create_at > lastViewedAt;
          const previousDate = !previous
            ? ''
            : new Date(previous.create_at).toLocaleDateString();
          const postDate = new Date(post.create_at).toLocaleDateString();
          const showDate = previousDate !== postDate;
          const showUser =
            !previous ||
            previous.user_id !== post.user_id ||
            previous.type !== post.type ||
            showDate ||
            showNew;
          const addDivisor = i > 0 && showUser;

          return (
            <ChatPost
              key={post.id}
              post={post}
              showUser={showUser}
              addPostDivisor={addDivisor}
              addNewDivisor={showNew}
              addDateDivisor={showDate}
              onImgResize={handleAutoScroll}
            />
          );
        })}
      </div>
    ),
    [
      channelId,
      handleAutoScroll,
      handleScroll,
      lastViewedAt,
      loadPreviousButton,
      posts,
      userId,
    ]
  );

  const loadNextButton = useMemo(
    () =>
      !!nextPostId && (
        <LoadNewButton onClick={loadNew}>
          <BiChevronsDown />
          <Tooltip
            position="left"
            text={formatMessage({ id: AppMessages.chatLoadNew })}
          />
        </LoadNewButton>
      ),
    [formatMessage, loadNew, nextPostId]
  );

  const loadingContainer = useMemo(
    () =>
      loading && (
        <LoadingContainer>
          {formatMessage({ id: AppMessages.chatLoading })}
          <br />
          <SpinnerIcon />
        </LoadingContainer>
      ),
    [formatMessage, loading]
  );

  const searchBox = useMemo(
    () =>
      searchBoxOpenned &&
      boxStatus !== 'minimized' && (
        <div className="search-box">
          {searchResuls && (
            <div className="postlist search-results">
              {searchResuls.order.map((postId, i) => {
                const hashMap = searchResuls.posts as any;
                const post = hashMap[postId];
                const previousId = searchResuls.order[i - 1];
                const previous = hashMap[previousId];
                const previousDate = !previous
                  ? ''
                  : new Date(previous.create_at).toLocaleDateString();
                const postDate = new Date(post.create_at).toLocaleDateString();
                const showDate = previousDate !== postDate;
                const addDivisor = i > 0;

                return (
                  <ChatPost
                    key={postId}
                    post={post}
                    highlightTerm={searchInputRef.current?.value}
                    showUser
                    addPostDivisor={addDivisor}
                    addDateDivisor={showDate}
                    onJumpClick={() => {
                      setSearchBoxOpenned(false);
                      if (postListOrder.includes(postId)) {
                        setScrollToPostId(postId);
                        return;
                      }
                      const container = document.getElementById(channelId);
                      if (container) container.scrollTop = 0;
                      startLoading();
                      loadPostsAround(channelId, post)
                        .then(() => {
                          setScrollToPostId(postId);
                        })
                        .finally(endLoading);
                    }}
                  />
                );
              })}
            </div>
          )}
          <InputSearch
            ref={searchInputRef}
            onChange={handleSearchValueChange}
            onClear={() => setSearchBoxOpenned(false)}
          />
          {searching && (
            <LoadingContainer>
              <SpinnerIcon />
            </LoadingContainer>
          )}
        </div>
      ),
    [
      boxStatus,
      channelId,
      endLoading,
      handleSearchValueChange,
      loadPostsAround,
      postListOrder,
      searchBoxOpenned,
      searchResuls,
      searching,
      startLoading,
    ]
  );

  const chatTypingInfo = useMemo(() => <ChatTypingInfo channel={channel} />, [
    channel,
  ]);

  const chatInput = useMemo(
    () => (
      <ChatInput
        channel={channel}
        boxStatus={boxStatus}
        onFocus={handleInputFocus}
      />
    ),
    [channel, boxStatus, handleInputFocus]
  );

  const channelBox = useMemo(
    () => (
      <ChatChannelBox
        boxStatus={boxStatus}
        hasUnreadMessages={hasUnreads}
        inputScrollHeight={inputScrollHeight}
      >
        {header}
        {postListContainer}
        {loadNextButton}
        {loadingContainer}
        {searchBox}

        {chatTypingInfo}
        {chatInput}
      </ChatChannelBox>
    ),
    [
      boxStatus,
      chatInput,
      chatTypingInfo,
      hasUnreads,
      header,
      inputScrollHeight,
      loadNextButton,
      loadingContainer,
      postListContainer,
      searchBox,
    ]
  );

  return (
    <ChatChannelContext.Provider value={contextValue}>
      {channelBox}
    </ChatChannelContext.Provider>
  );
};
