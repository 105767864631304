import { useState, useEffect, Dispatch, SetStateAction, useMemo } from 'react';

export const useSessionState = <T>(
  key: string,
  initialState: T
): [T, Dispatch<SetStateAction<T>>] => {
  const storageKey = `@Proseia:${key}`;

  const [state, setState] = useState(() => {
    const storageValue = sessionStorage.getItem(storageKey);

    if (storageValue) return JSON.parse(storageValue) as T;
    return initialState;
  });

  useEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(state));
  }, [storageKey, state]);

  return useMemo(() => [state, setState], [state]);
};
