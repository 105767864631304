import React, { useCallback, useMemo } from 'react';
import { Channel } from 'mattermost-redux/types/channels';

import { useMattermost } from '../../hooks/mattermost';
import {
  ChatPublicChannelIcon,
  ChatPrivateChannelIcon,
  SettingsIcon,
} from '../Icons';

import { ChannelContainer, ChannelOptionsButton } from './styles';

interface Props {
  channel: Channel;
  onClick: () => void;
}

export const ChatListChannel: React.FC<Props> = ({ channel, onClick }) => {
  const unreads = useMattermost((state) =>
    state.getChannelUnreadsMessageCount(channel)
  );
  const mentions = useMattermost((state) =>
    state.getChannelUnreadsMentionCount(channel)
  );
  const displayName = useMattermost((state) =>
    state.getChannelDisplayName(channel)
  );
  const openChannelOptionsMenu = useMattermost(
    (state) => state.openChannelOptionsMenu
  );
  const viewChannel = useMattermost((state) => state.viewChannel);

  const handleClick = useCallback(() => {
    viewChannel(channel, false);
    onClick();
  }, [channel, onClick, viewChannel]);

  const handleOptionsButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      openChannelOptionsMenu(channel, e.currentTarget);
    },
    [channel, openChannelOptionsMenu]
  );

  const channelTypeIcon = useMemo(
    () =>
      channel.type === 'O' ? (
        <ChatPublicChannelIcon />
      ) : (
        <ChatPrivateChannelIcon />
      ),
    [channel.type]
  );

  const channelTitle = useMemo(
    () => (
      <span>
        {channelTypeIcon}
        {displayName}
      </span>
    ),
    [channelTypeIcon, displayName]
  );

  const unreadsContainer = useMemo(
    () =>
      !!unreads && (
        <span className="unreads">
          {!!mentions && `@${mentions}`}
          {!!mentions && unreads > mentions && ` ∙ ${unreads - mentions}`}
          {!mentions && unreads}
        </span>
      ),
    [unreads, mentions]
  );

  const channelOptionsButton = useMemo(
    () => (
      <ChannelOptionsButton
        className="channel-options-button"
        onClick={handleOptionsButtonClick}
      >
        <SettingsIcon />
      </ChannelOptionsButton>
    ),
    [handleOptionsButtonClick]
  );

  return (
    <ChannelContainer className="channel-link" onClick={handleClick}>
      {channelTitle}
      {unreadsContainer}
      {channelOptionsButton}
    </ChannelContainer>
  );
};
