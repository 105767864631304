import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { SpinnerIcon } from '../Icons';

interface Props {
  src: string;
  alt?: string;
  onResize?: (width: number, height: number) => void;
}

export const OfficePostImage: React.FC<Props> = ({
  src,
  alt = '',
  onResize,
}) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [loading, setLoading] = useState(true);

  const resizeEventTimeout = useRef<NodeJS.Timeout>();

  const handleResize = useCallback((width: number, height: number) => {
    if (resizeEventTimeout.current) clearTimeout(resizeEventTimeout.current);
    resizeEventTimeout.current = setTimeout(() => {
      setSize({ width, height });
      resizeEventTimeout.current = undefined;
    }, 50);
  }, []);

  useEffect(() => {
    if (onResize) onResize(size.width, size.height);
  }, [size, onResize]);

  return (
    <ReactResizeDetector handleWidth handleHeight onResize={handleResize}>
      <div
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <div style={{ position: 'relative', maxWidth: '100%' }}>
          <img
            src={src}
            alt={alt}
            style={{ maxWidth: '100%', maxHeight: '100%', display: 'block' }}
            onLoad={() => setLoading(false)}
          />
          {loading && (
            <div>
              <SpinnerIcon />
            </div>
          )}
        </div>
      </div>
    </ReactResizeDetector>
  );
};
