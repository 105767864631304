import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import {
  Container,
  MoodsContainer,
  MoodCommentContainer,
  MoodButton,
  Button,
} from './styles';

import verySadImage from '../../assets/moods/very-sad.svg';
import sadImage from '../../assets/moods/sad.svg';
import neutralImage from '../../assets/moods/neutral.svg';
import happyImage from '../../assets/moods/happy.svg';
import veryHappyImage from '../../assets/moods/very-happy.svg';

import { AppMessages } from '../../languages';
import { useOffice } from '../../hooks/office';
import { useApi, MoodModel } from '../../hooks/api';
import { ModalContainer } from '../ModalContainer';
import { IconButton } from '../IconButton';
import { CloseBoxIcon, GoBackIcon } from '../Icons';
import { StyledInput } from '../StyledInput';
import { AvatarMood } from '../Avatar/styles';

const MOODS: AvatarMood[] = [
  'very sad',
  'sad',
  'neutral',
  'happy',
  'very happy',
];

const EMOJIS = {
  'very sad': verySadImage, // '😢',
  sad: sadImage, // '🙁',
  neutral: neutralImage, // '😐',
  happy: happyImage, // '🙂',
  'very happy': veryHappyImage, // '😀',
};

const COMMENT_CHAR_LIMIT = 300;

interface Props {
  isOpen: boolean;
  onCloseRequest: () => void;
}

const doNothing = () => {
  /* do nothing */
};

export const UserMood: React.FC<Props> = ({ isOpen, onCloseRequest }) => {
  const { formatMessage } = useIntl();
  const { moodsService } = useApi();
  const userId = useOffice((state) => state.currentUserInfo.id);
  const userMood = useOffice((state) => state.currentUserInfo.mood);
  const updateCurrentUserInfo = useOffice(
    (state) => state.updateCurrentUserInfo
  );
  const [selectedMood, setSelectedMood] = useState<AvatarMood>();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleConfirm = useCallback(async () => {
    onCloseRequest();
    if (!inputRef.current?.value) return;

    await moodsService.post({
      user: userId,
      mood: selectedMood,
      comment: inputRef.current.value,
    } as MoodModel);
  }, [userId, selectedMood, moodsService, onCloseRequest]);

  const selectMoodCallback = useCallback(
    (mood: AvatarMood) => {
      return () => {
        setSelectedMood(mood);
        if (mood === userMood) return;
        updateCurrentUserInfo({ mood });
      };
    },
    [userMood, updateCurrentUserInfo]
  );

  useEffect(() => {
    setSelectedMood(undefined);
  }, [isOpen]);

  useEffect(() => {
    if (!selectedMood || !inputRef.current) return;
    inputRef.current.value = '';
    inputRef.current.focus();
  }, [selectedMood]);

  return (
    <ModalContainer isOpen={isOpen} onClickOutside={doNothing}>
      <Container onClick={(e) => e.stopPropagation()}>
        <div className="header">
          <span className={!selectedMood ? 'title' : 'go-back'}>
            {!selectedMood &&
              formatMessage({ id: AppMessages.componentUserMoodTitle })}
            {!!selectedMood && (
              <IconButton
                iconSize={16}
                onClick={() => setSelectedMood(undefined)}
              >
                <GoBackIcon />
              </IconButton>
            )}
            {!!selectedMood && (
              <img
                src={EMOJIS[selectedMood]}
                alt={selectedMood}
                width="20"
                height="20"
                style={{ border: '1px solid black', borderRadius: '50%' }}
              />
            )}
          </span>
          <IconButton iconSize={16} onClick={onCloseRequest}>
            <CloseBoxIcon />
          </IconButton>
        </div>
        <div className="content">
          <MoodsContainer visible={!selectedMood}>
            {MOODS.map((mood) => (
              <MoodButton key={mood} onClick={selectMoodCallback(mood)}>
                <img
                  src={EMOJIS[mood]}
                  alt={mood}
                  width="40"
                  height="40"
                  style={{ border: '1px solid black', borderRadius: '50%' }}
                />
                {/* {EMOJIS[mood]} */}
              </MoodButton>
            ))}
          </MoodsContainer>
          <MoodCommentContainer visible={!!selectedMood}>
            <StyledInput
              className="mood-comment"
              multiline
              rows={4}
              inputRef={inputRef}
              placeholder={formatMessage({
                id: AppMessages.componentUserMoodComment,
              })}
              onChange={(e) => {
                if (e.target.value.length <= COMMENT_CHAR_LIMIT) return;
                e.target.value = e.target.value.substr(0, COMMENT_CHAR_LIMIT);
              }}
            />
            <div className="buttons">
              <Button onClick={handleConfirm}>
                {formatMessage({ id: AppMessages.componentUserMoodConfirm })}
              </Button>
            </div>
          </MoodCommentContainer>
        </div>
      </Container>
    </ModalContainer>
  );
};
