import React, { useCallback, useEffect, useMemo, useState } from 'react';
import jwt from 'jwt-decode';
import { useHistory } from 'react-router-dom';

import { usePersistentState } from './persistentState';
import api from '../services/api';
import { createContext, useContextSelector } from './context';
import { checkNeedToInstallExtension } from '../utils/browser';

interface User {
  id: string;
  accessToken: string;
  refreshToken: string;
  email: string;
  firstName: string;
  lastName: string;
  lastLoginAt: Date;
  picture: string;
  roles?: string[];
  extensionRequired?: boolean;
}

export interface AuthState {
  message: string;
  user: User;
}

export interface SignInCredentials {
  email: string;
  password: string;
}

export interface FirstSignInData extends SignInCredentials {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  roleDescription: string;
}

export interface SignUpData extends SignInCredentials {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  company: string;
  origin?: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signUp(data: SignUpData): Promise<void>;
  firstSignIn: (data: FirstSignInData) => Promise<void>;
  signOut: () => void;
  signOutExtensionRequired: () => void;
  isPasswordStored: (email: string) => Promise<boolean>;
  forgotPassword: (email: string) => Promise<boolean>;
  resetPassword: (id: string, token: string) => Promise<boolean>;
  isTokenValid: () => boolean;
  validateToken: (token: string) => boolean;
  getTokenData: (token: string) => any;
  isAdmin: () => boolean;
  keepConnected: boolean;
  setKeepConnected: React.Dispatch<React.SetStateAction<boolean>>;
  extensionIsRequired: boolean;
}
const AuthContext = createContext({} as AuthContextData);

let tokenExpirationTimeout: any;

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [authInfo, setAuthInfo] = usePersistentState(
    'authInfo',
    {} as AuthState
  );
  const [keepConnected, setKeepConnected] = usePersistentState(
    'keepConnected',
    false
  );

  const [extensionIsRequired, setExtensionIsRequired] = useState<boolean>(
    false
  );

  const accessToken = authInfo.user?.accessToken || '';

  const signIn = useCallback(
    async (credentials: SignInCredentials) => {
      const response = await api.post<AuthState>(`auth/login`, credentials);
      if (response.data.user.extensionRequired) {
        const usesExtension = await api.get<boolean>('uses-extension', {
          headers: {
            Authorization: `Bearer ${response.data.user.accessToken}`,
          },
        });
        if (usesExtension) {
          checkNeedToInstallExtension(setExtensionIsRequired);
        }
      }
      setAuthInfo(response.data);
    },
    [setAuthInfo]
  );

  const signUp = useCallback(
    async (data: SignUpData) => {
      const response = await api.post<AuthState>(`auth/signup`, data);
      setAuthInfo(response.data);
    },
    [setAuthInfo]
  );

  const firstSignIn = useCallback(
    async (data: FirstSignInData) => {
      const response = await api.post<AuthState>(`auth/first-login`, data);
      if (response.data.user.extensionRequired) {
        const usesExtension = await api.get<boolean>('uses-extension', {
          headers: {
            Authorization: `Bearer ${response.data.user.accessToken}`,
          },
        });
        if (usesExtension) {
          checkNeedToInstallExtension(setExtensionIsRequired);
        }
      }
      setAuthInfo(response.data);
    },
    [setAuthInfo]
  );

  const isPasswordStored = useCallback(async (email: string) => {
    const response = await api.get<boolean>(`auth/${email}/is-password-stored`);
    return response.data;
  }, []);

  const forgotPassword = useCallback(async (email: string) => {
    const response = await api.post<boolean>(`auth/forgot-password`, { email });
    return response.data;
  }, []);

  const resetPassword = useCallback(async (id: string, token: string) => {
    const response = await api.post<boolean>(
      `auth/reset-password`,
      { id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  }, []);

  const signOut = useCallback(() => {
    setKeepConnected(false);
    setAuthInfo({} as AuthState);
    window.location.reload();
  }, [setAuthInfo, setKeepConnected]);

  const signOutExtensionRequired = useCallback(() => {
    setKeepConnected(false);
    setAuthInfo({} as AuthState);
    history.push('/');
  }, [setAuthInfo, setKeepConnected, history]);

  const getTokenData = useCallback((token: string) => jwt(token) as any, []);

  const validateToken = useCallback(
    (token: string) => {
      if (!token) return false;
      try {
        const decoded = getTokenData(token);

        if (decoded.nbf && Date.now() <= decoded.nbf * 1000) {
          return false;
        }

        if (!decoded.exp) return false;

        return Date.now() < decoded.exp * 1000;
      } catch (e) {
        return false;
      }
    },
    [getTokenData]
  );

  const isTokenValid = useCallback(() => {
    return validateToken(authInfo.user?.accessToken || '');
  }, [validateToken, authInfo]);

  const isRefreshTokenValid = useCallback(() => {
    return validateToken(authInfo.user?.refreshToken || '');
  }, [validateToken, authInfo]);

  const isAdmin = useCallback(() => {
    if (!authInfo.user?.roles) return false;
    return authInfo.user.roles.includes('admin');
  }, [authInfo]);

  const refreshToken = useCallback(async () => {
    const refreshTokenIsValid = isRefreshTokenValid();

    if (!isTokenValid() && !refreshTokenIsValid)
      throw new Error('Invalid token');

    const token = refreshTokenIsValid
      ? authInfo.user.refreshToken
      : authInfo.user.accessToken;

    const response = await api.get<AuthState>(`auth/refresh-token`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setAuthInfo(response.data);
    console.log('*** token refreshed ***');
  }, [authInfo, setAuthInfo, isTokenValid, isRefreshTokenValid]);

  useEffect(() => {
    const refreshTokenIsValid = isRefreshTokenValid();
    const accessTokenIsValid = isTokenValid();

    if (accessTokenIsValid) {
      if (tokenExpirationTimeout) clearTimeout(tokenExpirationTimeout);

      const decoded = getTokenData(authInfo.user.accessToken);
      let timeout = decoded.exp * 1000 - Date.now() - 60000;

      if (timeout < 1) timeout = 1;

      tokenExpirationTimeout = setTimeout(async () => {
        tokenExpirationTimeout = undefined;
        try {
          await refreshToken();
        } catch (err) {
          signOut();
        }
      }, timeout);
    } else if (refreshTokenIsValid && keepConnected) {
      refreshToken().catch(() => signOut());
    }

    return () => {
      if (tokenExpirationTimeout) clearTimeout(tokenExpirationTimeout);
    };
  }, [
    authInfo,
    keepConnected,
    getTokenData,
    isTokenValid,
    isRefreshTokenValid,
    refreshToken,
    signOut,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!accessToken) return;

      api
        .get<AuthState>(`test-with-authorization`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .catch(() => {
          signOut();
        });
    }, 5000);

    return () => clearTimeout(timeout);
  }, [accessToken, signOut]);

  const contextValue = useMemo<AuthContextData>(
    () => ({
      user: authInfo.user,
      signIn,
      signUp,
      firstSignIn,
      signOut,
      signOutExtensionRequired,
      isPasswordStored,
      forgotPassword,
      resetPassword,
      isTokenValid,
      validateToken,
      getTokenData,
      isAdmin,
      keepConnected,
      setKeepConnected,
      extensionIsRequired,
    }),
    [
      authInfo.user,
      signIn,
      signUp,
      firstSignIn,
      signOut,
      signOutExtensionRequired,
      isPasswordStored,
      forgotPassword,
      resetPassword,
      isTokenValid,
      validateToken,
      getTokenData,
      isAdmin,
      keepConnected,
      setKeepConnected,
      extensionIsRequired,
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export function useAuth<TResult>(
  selector: (state: AuthContextData) => TResult
): TResult {
  return useContextSelector(AuthContext, selector);
}
