import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { MdCheckCircle, MdCancel } from 'react-icons/md';
import {
  Container,
  AvatarContainer,
  InfoContainer,
  ActionsContainer,
} from './styles';
import { ToastInviteData } from '../../../hooks/toastInvite';
import { useModels } from '../../../hooks/model';
import { useThemeContext } from '../../../hooks/theme';
import { AppMessages } from '../../../languages';
import { Avatar } from '../../Avatar';
import { LogoSymbol } from '../../LogoSymbol';

interface ToastInviteProps {
  invite: ToastInviteData;
  style: any;
}

const ToastInvite: React.FC<ToastInviteProps> = ({ invite, style }) => {
  const offices = useModels((state) => state.offices);
  const background = useThemeContext((state) => state.theme.background);
  const textColor = useThemeContext((state) => state.theme.textColor);
  const { formatMessage } = useIntl();

  const { id, type, user, officeId, roomId, onAccept, onCancel } = invite;

  const messageId =
    type === 'received'
      ? AppMessages.inviteWantsToConnect
      : AppMessages.inviteSended;

  const userCurrentOffice = offices.find((t) => t.id === officeId);
  const currentRoom = userCurrentOffice?.rooms?.find((t) => t.id === roomId);
  const currentRoomName = currentRoom?.name || '';

  const handleAccept = useCallback(() => {
    if (onAccept) onAccept();
  }, [onAccept]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <Container style={style}>
      <AvatarContainer className="avatar-container">
        <Avatar
          id={id}
          firstName={user.firstName}
          lastName={user.lastName}
          picture={user.picture}
          email={user.email}
          roleDescription={user.roleDescription}
          size="medium"
        />
      </AvatarContainer>
      <InfoContainer className="info-container">
        <div className="info-name">
          {`${user.firstName} ${user.lastName}`.trim()}
        </div>
        <div className="info-message">{formatMessage({ id: messageId })}</div>
        <div className="location">
          <LogoSymbol primaryColor={textColor} secondaryColor={background} />
          &nbsp;
          {currentRoomName}
        </div>
      </InfoContainer>
      <ActionsContainer className="actions-container">
        {type === 'received' && <MdCheckCircle onClick={handleAccept} />}
        <MdCancel onClick={handleCancel} />
      </ActionsContainer>
    </Container>
  );
};

export default ToastInvite;
