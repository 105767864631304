import { styled, css } from '../../themes';

interface AditionalInfoProps {
  show: boolean;
  top: number;
}

interface IconTextContainerProps {
  hasAction?: boolean;
}

interface ContainerProps {
  activated: boolean;
}

const WIDTH = 289;
const PADDING = 10;

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: auto;
  min-width: ${WIDTH}px;
  max-width: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  > * {
    transition: all 0.2s ease-out;
  }
`;

export const AditionalInfo = styled.div<AditionalInfoProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  z-index: 5;
  padding: ${(props) => props.top}px ${PADDING}px ${PADDING}px ${PADDING}px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  background: ${(props) => props.theme.boxColor};
  box-shadow: ${(props) => props.theme.boxGlow};
  border-radius: 5px;

  ${(props) =>
    !props.show &&
    css`
      box-shadow: none;
      background: transparent;
      opacity: 0;
      height: 0px;
      * {
        display: none !important;
      }
    `}

  .MuiPaper-root {
    background: transparent;
  }
`;

export const UserResumeInfo = styled.div`
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  padding: ${PADDING}px;
`;

export const Infos = styled.div`
  position: relative;
  text-align: center;
  justify-content: center;
  padding-left: 5px;
  min-width: 100px;
`;

export const Name = styled.span`
  position: relative;
  font-size: 22px;
  color: ${(props) => props.theme.textColor};
  display: block;
  padding: 2px;
  border: 1px solid transparent;
`;

export const RoleDescription = styled.span`
  position: relative;
  font-size: 14px;
  color: ${(props) => props.theme.textColor};
  display: block;
  padding: 2px;
  border: 1px solid transparent;
`;

export const UserStatusInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  width: 100%;
  min-width: 100%;

  > * {
    width: 100%;
    min-width: 100%;
  }
`;

export const SeparatorContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 100%;
  height: ${PADDING * 3}px;
`;

export const OutsideArea = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
`;

export const CurrentRoomContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 5px;

  > .logo-symbol {
    margin-right: 6px;
  }
`;

export const IconTextContainer = styled.div<IconTextContainerProps>`
  position: relative;
  display: flex;
  align-items: center;

  > svg {
    font-size: 17px;
    width: 17px;
    height: 17px;
    margin-right: 6px;
    fill: ${(props) => props.theme.textColor};
  }

  ${(props) =>
    props.hasAction &&
    css`
      cursor: pointer;
      transition: transform 0.2s;
      :hover {
        transform: scale(1.1);
      }
    `}
`;

export const PrivacyPolicyLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 10px;
  padding-top: 10px;
`;

export const PrivacyPolicyLink = styled.a`
  color: ${(props) => props.theme.textColor};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export const AppVersionContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 10px;
`;
