export interface Period {
  from: Date;
  to: Date;
}

export enum EPeriod {
  today = 1,
  yesterday,
  thisWeek,
  lastWeek,
  thisMonth,
  lastMonth,
}

const getDayFrom = (from?: Date) => {
  const d = from || new Date();
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
};

const getDayTo = (to?: Date) => {
  const d = to || new Date();
  d.setHours(23);
  d.setMinutes(59);
  d.setSeconds(59);
  d.setMilliseconds(999);
  return d;
};

export const getPeriod = (period: EPeriod): Period | undefined => {
  let from: Date;
  let to: Date;

  switch (period) {
    case EPeriod.today:
      return {
        from: getDayFrom(),
        to: getDayTo(),
      };

    case EPeriod.yesterday:
      from = new Date();
      to = new Date();
      from.setDate(from.getDate() - 1);
      to.setDate(to.getDate() - 1);
      return {
        from: getDayFrom(from),
        to: getDayTo(to),
      };

    case EPeriod.thisWeek:
      from = new Date();
      to = new Date();
      from.setDate(from.getDate() - from.getDay());
      to.setDate(to.getDate() + (6 - to.getDay()));
      return {
        from: getDayFrom(from),
        to: getDayTo(to),
      };

    case EPeriod.lastWeek:
      from = new Date();
      to = new Date();
      from.setDate(from.getDate() - (from.getDay() + 7));
      to.setDate(to.getDate() + (-1 - to.getDay()));
      return {
        from: getDayFrom(from),
        to: getDayTo(to),
      };

    case EPeriod.thisMonth:
      from = new Date();
      to = new Date();
      from.setDate(1);
      to.setDate(1);
      to.setMonth(to.getMonth() + 1);
      to.setDate(to.getDate() - 1);
      return {
        from: getDayFrom(from),
        to: getDayTo(to),
      };

    case EPeriod.lastMonth:
      from = new Date();
      to = new Date();
      from.setDate(1);
      from.setMonth(from.getMonth() - 1);
      to.setDate(1);
      to.setDate(to.getDate() - 1);
      return {
        from: getDayFrom(from),
        to: getDayTo(to),
      };
    default:
      return undefined;
  }
};
