/* eslint-disable import/no-duplicates */
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Post } from 'mattermost-redux/types/posts';
import { Emoji, EmojiData } from 'emoji-mart';
import { useIntl } from 'react-intl';

import {
  CommentContainer,
  CommentHeader,
  CommentAvatar,
  CommentInfo,
  CommentDate,
  CommentDisplayName,
  CommentRole,
  CommentContent,
  CommentMessage,
  CommentActions,
  CommentReactionsContainer,
  ReactionButton,
} from './styles';

import { OfficePostEmojiPicker } from './OfficePostEmojiPicker';
import { useMattermost, formatPostContent } from '../../hooks/mattermost';
import { getFormattedUserName } from '../../hooks/office';
import { useDateFormat } from '../../hooks/dateFormat';
import { AppMessages } from '../../languages';
import { IconButton } from '../IconButton';
import { AddReactionIcon, DeleteIcon } from '../Icons';
import { Tooltip } from '../Tooltip';

interface Props {
  comment: Post;
}

type PostReactions = { emoji: string; users: string[] }[];

export const OfficePostComment: React.FC<Props> = ({ comment }) => {
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [commentReactions, setCommentReactions] = useState<PostReactions>([]);

  const mattermostUserId = useMattermost(
    (state) => state.mattermostUser?.id || ''
  );
  const getProseiaUser = useMattermost((state) => state.getProseiaUser);
  const deletePost = useMattermost((state) => state.deletePost);
  const addReaction = useMattermost((state) => state.addReaction);
  const removeReaction = useMattermost((state) => state.removeReaction);

  const formatDistanceToNowStrict = useDateFormat(
    (state) => state.formatDistanceToNowStrict
  );
  const differenceInHours = useDateFormat((state) => state.differenceInHours);
  const formatDateTime = useDateFormat((state) => state.formatDateTime);

  const user = useMemo(() => getProseiaUser(comment.user_id), [
    getProseiaUser,
    comment.user_id,
  ]);
  const { formatMessage } = useIntl();
  const { firstName, lastName } = user;
  const displayName = useMemo(
    () =>
      comment.type.startsWith('system_')
        ? 'System'
        : getFormattedUserName({ firstName, lastName }),
    [comment.type, firstName, lastName]
  );

  const getImage = useCallback(() => {
    return <img alt={displayName} src={user.picture} width="32" height="32" />;
  }, [user.picture, displayName]);

  const createdAt = useMemo(() => new Date(comment.create_at), [
    comment.create_at,
  ]);
  const now = new Date();
  const commentPast24Hours = differenceInHours(now, createdAt);
  const displayTimeFormatted =
    commentPast24Hours > 24
      ? formatDateTime(createdAt)
      : formatDistanceToNowStrict(createdAt);

  const { reactions } = comment.metadata;
  const reactionsCount = reactions?.length;

  const handleDelete = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm(formatMessage({ id: AppMessages.officePostConfirmDeletion })))
      return;
    deletePost(comment.id);
  }, [comment, formatMessage, deletePost]);

  const handleAddReaction = useCallback(
    (emoji: EmojiData) => {
      if (!emoji?.id) return;
      addReaction(comment.id, emoji.id);
    },
    [comment, addReaction]
  );

  useEffect(() => {
    if (!reactions || !reactionsCount) {
      setCommentReactions([]);
      return;
    }
    setCommentReactions(
      reactions.reduce((result, item) => {
        const previouslyAdded = result.find((t) => t.emoji === item.emoji_name);
        if (previouslyAdded) {
          if (!previouslyAdded.users.includes(item.user_id))
            previouslyAdded.users.push(item.user_id);
          return result;
        }
        return [...result, { emoji: item.emoji_name, users: [item.user_id] }];
      }, [] as PostReactions)
    );
  }, [reactions, reactionsCount]);

  return (
    <CommentContainer>
      <CommentHeader>
        <CommentAvatar>{getImage()}</CommentAvatar>
        <CommentInfo>
          <CommentDisplayName>
            {displayName}
            <CommentRole>
              {user.roleDescription && ` · ${user.roleDescription}`}
            </CommentRole>
          </CommentDisplayName>
          <CommentDate>{displayTimeFormatted}</CommentDate>
        </CommentInfo>
      </CommentHeader>
      <CommentContent>
        <CommentMessage>{formatPostContent(comment.message)}</CommentMessage>

        <CommentActions>
          <IconButton iconSize={18} onClick={() => setEmojiPickerOpen(true)}>
            <AddReactionIcon />
            <Tooltip
              position="top-end"
              text={formatMessage({
                id: AppMessages.officePostAddReaction,
              })}
            />
          </IconButton>
          {mattermostUserId === comment.user_id && (
            <IconButton iconSize={18} onClick={handleDelete}>
              <DeleteIcon />
              <Tooltip
                position="top-end"
                text={formatMessage({ id: AppMessages.officePostDelete })}
              />
            </IconButton>
          )}
        </CommentActions>

        {commentReactions.length > 0 && (
          <CommentReactionsContainer>
            {commentReactions.map((item) => {
              const reacted = item.users.includes(mattermostUserId);
              return (
                <ReactionButton
                  key={item.emoji}
                  reacted={reacted}
                  onClick={() => {
                    if (!reacted) addReaction(comment.id, item.emoji);
                    else removeReaction(comment.id, item.emoji);
                  }}
                >
                  <Emoji emoji={item.emoji} size={18} />
                  &nbsp;
                  {item.users.length}
                  <Tooltip
                    position="top-start"
                    maxWidth={200}
                    text={item.users
                      .map((userId) =>
                        getFormattedUserName(getProseiaUser(userId))
                      )
                      .join(', ')}
                  />
                </ReactionButton>
              );
            })}
          </CommentReactionsContainer>
        )}
      </CommentContent>

      <OfficePostEmojiPicker
        open={emojiPickerOpen}
        channelId={comment.channel_id}
        onClose={() => setEmojiPickerOpen(false)}
        onSelect={handleAddReaction}
        type="comment"
      />
    </CommentContainer>
  );
};
