/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Container } from './styles';

interface Props {
  isOn?: boolean;
  handleToggle: () => void | Promise<void>;
}

export const SwitchButton: React.FC<Props> = ({ isOn, handleToggle }) => {
  const [id] = useState(`react-switch-${uuid()}`);

  return (
    <Container>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={id}
        type="checkbox"
      />

      <label
        style={{ background: isOn ? '#009045' : '' }}
        className="react-switch-label"
        htmlFor={id}
      >
        <span className="react-switch-button" />
      </label>
    </Container>
  );
};
