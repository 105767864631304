import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { createContext, useContextSelector } from './context';
import { useAuth } from './auth';
import { useApi } from './api';
import { useOffice } from './office';

interface TutorialContextData {
  enterRoomTipIsOpen: boolean;
  closeEnterRoomTip: () => Promise<void>;
  addUsersTipIsOpen: boolean;
  closeAddUsersTip: () => Promise<void>;
  inviteToMeetingTipIsOpen: boolean;
  closeInviteToMeetingTip: () => Promise<void>;
  contextMenuTipIsOpen: boolean;
  closeContextMenuTip: () => Promise<void>;
  meetingVisibilityTipIsOpen: boolean;
  closeMeetingVisibilityTip: () => Promise<void>;
}
const TutorialContext = createContext<TutorialContextData>(
  {} as TutorialContextData
);

export const TutorialProvider: React.FC = ({ children }) => {
  const authUserId = useAuth((state) => state.user?.id || '');
  const isAdmin = useAuth((state) => state.isAdmin());
  const { registerUserAction } = useApi();

  const userMoodIsOpen = useOffice((state) => state.userMoodIsOpen);
  const updateUser = useOffice((state) => state.updateUser);
  const userId = useOffice((state) => state.getUser()?.id || '');
  const tutorialAddUsersDoneAt = useOffice(
    (state) => state.getUser()?.tutorialAddUsersDoneAt
  );
  const tutorialContextMenuDoneAt = useOffice(
    (state) => state.getUser()?.tutorialContextMenuDoneAt
  );
  const tutorialEnterRoomDoneAt = useOffice(
    (state) => state.getUser()?.tutorialEnterRoomDoneAt
  );
  const tutorialInviteToMeetingDoneAt = useOffice(
    (state) => state.getUser()?.tutorialInviteToMeetingDoneAt
  );
  const tutorialMeetingVisibilityDoneAt = useOffice(
    (state) => state.getUser()?.tutorialMeetingVisibilityDoneAt
  );
  const hasAnotherUserOnline = useOffice(
    (state) => Object.keys(state.usersInfo).length > 1
  );
  const groupId = useOffice((state) => state.currentUserInfo.groupId);
  const soundEnabled = useOffice((state) => state.currentUserInfo.soundEnabled);

  const [enterRoomTipIsOpen, setEnterRoomTipIsOpen] = useState(false);
  const [addUsersTipIsOpen, setAddUsersTipIsOpen] = useState(false);
  const [inviteToMeetingTipIsOpen, setInviteToMeetingTipIsOpen] = useState(
    false
  );
  const [contextMenuTipIsOpen, setContextMenuTipIsOpen] = useState(false);
  const [meetingVisibilityTipIsOpen, setMeetingVisibilityTipIsOpen] = useState(
    false
  );

  const closeEnterRoomTip = useCallback(async () => {
    setEnterRoomTipIsOpen(false);
    updateUser(authUserId, { tutorialEnterRoomDoneAt: new Date() }).then(() => {
      registerUserAction({
        userId: authUserId,
        action: 'enter room tutorial ok',
      });
    });
  }, [authUserId, updateUser, registerUserAction]);

  const closeAddUsersTip = useCallback(async () => {
    setAddUsersTipIsOpen(false);
    updateUser(authUserId, { tutorialAddUsersDoneAt: new Date() }).then(() => {
      registerUserAction({
        userId: authUserId,
        action: 'add users tutorial ok',
      });
    });
  }, [authUserId, updateUser, registerUserAction]);

  const closeInviteToMeetingTip = useCallback(async () => {
    setInviteToMeetingTipIsOpen(false);
    updateUser(authUserId, { tutorialInviteToMeetingDoneAt: new Date() }).then(
      () => {
        registerUserAction({
          userId: authUserId,
          action: 'invite to meeting tutorial ok',
        });
      }
    );
  }, [authUserId, updateUser, registerUserAction]);

  const closeContextMenuTip = useCallback(async () => {
    setContextMenuTipIsOpen(false);
    updateUser(authUserId, { tutorialContextMenuDoneAt: new Date() }).then(
      () => {
        registerUserAction({
          userId: authUserId,
          action: 'avatar context menu tutorial ok',
        });
      }
    );
  }, [authUserId, updateUser, registerUserAction]);

  const closeMeetingVisibilityTip = useCallback(async () => {
    setMeetingVisibilityTipIsOpen(false);
    updateUser(authUserId, {
      tutorialMeetingVisibilityDoneAt: new Date(),
    }).then(() => {
      registerUserAction({
        userId: authUserId,
        action: 'meeting visibility tutorial ok',
      });
    });
  }, [authUserId, updateUser, registerUserAction]);

  useEffect(() => {
    if (!userId || userId !== authUserId) return;
    setEnterRoomTipIsOpen(!userMoodIsOpen && !tutorialEnterRoomDoneAt);
  }, [authUserId, userMoodIsOpen, userId, tutorialEnterRoomDoneAt]);

  useEffect(() => {
    if (!isAdmin) return;
    if (!userId || userId !== authUserId) return;
    setAddUsersTipIsOpen(
      !userMoodIsOpen && !enterRoomTipIsOpen && !tutorialAddUsersDoneAt
    );
  }, [
    authUserId,
    userMoodIsOpen,
    enterRoomTipIsOpen,
    userId,
    isAdmin,
    tutorialAddUsersDoneAt,
  ]);

  useEffect(() => {
    if (!userId || userId !== authUserId) return;
    setInviteToMeetingTipIsOpen(
      !userMoodIsOpen &&
        !enterRoomTipIsOpen &&
        !addUsersTipIsOpen &&
        hasAnotherUserOnline &&
        !tutorialInviteToMeetingDoneAt
    );
  }, [
    authUserId,
    userMoodIsOpen,
    enterRoomTipIsOpen,
    addUsersTipIsOpen,
    hasAnotherUserOnline,
    userId,
    tutorialInviteToMeetingDoneAt,
  ]);

  useEffect(() => {
    if (!userId || userId !== authUserId) return;
    setContextMenuTipIsOpen(
      !userMoodIsOpen &&
        !enterRoomTipIsOpen &&
        !addUsersTipIsOpen &&
        hasAnotherUserOnline &&
        !inviteToMeetingTipIsOpen &&
        !tutorialContextMenuDoneAt
    );
  }, [
    authUserId,
    userMoodIsOpen,
    enterRoomTipIsOpen,
    addUsersTipIsOpen,
    hasAnotherUserOnline,
    inviteToMeetingTipIsOpen,
    userId,
    tutorialContextMenuDoneAt,
  ]);

  useEffect(() => {
    if (!userId || userId !== authUserId) return;
    setMeetingVisibilityTipIsOpen(
      !userMoodIsOpen &&
        !enterRoomTipIsOpen &&
        !addUsersTipIsOpen &&
        hasAnotherUserOnline &&
        !inviteToMeetingTipIsOpen &&
        !contextMenuTipIsOpen &&
        !!groupId &&
        soundEnabled &&
        !tutorialMeetingVisibilityDoneAt
    );
  }, [
    authUserId,
    groupId,
    soundEnabled,
    userMoodIsOpen,
    enterRoomTipIsOpen,
    addUsersTipIsOpen,
    hasAnotherUserOnline,
    inviteToMeetingTipIsOpen,
    contextMenuTipIsOpen,
    userId,
    tutorialMeetingVisibilityDoneAt,
  ]);

  const contextValue = useMemo<TutorialContextData>(
    () => ({
      enterRoomTipIsOpen,
      closeEnterRoomTip,
      addUsersTipIsOpen,
      closeAddUsersTip,
      inviteToMeetingTipIsOpen,
      closeInviteToMeetingTip,
      contextMenuTipIsOpen,
      closeContextMenuTip,
      meetingVisibilityTipIsOpen,
      closeMeetingVisibilityTip,
    }),
    [
      enterRoomTipIsOpen,
      closeEnterRoomTip,
      addUsersTipIsOpen,
      closeAddUsersTip,
      inviteToMeetingTipIsOpen,
      closeInviteToMeetingTip,
      contextMenuTipIsOpen,
      closeContextMenuTip,
      meetingVisibilityTipIsOpen,
      closeMeetingVisibilityTip,
    ]
  );

  return (
    <TutorialContext.Provider value={contextValue}>
      {children}
    </TutorialContext.Provider>
  );
};

export function useTutorial<TResult>(
  selector: (state: TutorialContextData) => TResult
): TResult {
  return useContextSelector(TutorialContext, selector);
}
