import React from 'react';
import { PostEmbed } from 'mattermost-redux/types/posts';

import { OfficePostLinkPreviewContainer } from './styles';
import { OfficePostImage } from './OfficePostImage';

interface Props {
  embed: PostEmbed;
  onImgResize?: (width: number, height: number) => void;
}

export const OfficePostLinkPreview: React.FC<Props> = ({
  embed,
  onImgResize,
}) => {
  const { type, url, data } = embed;

  const dataTitle = data?.title || '';
  const dataDescription = data?.description || '';
  const dataImages = data?.images || [];

  return (
    <OfficePostLinkPreviewContainer>
      {type === 'opengraph' && (
        <>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <h4>{dataTitle}</h4>
          </a>
          <h5>{dataDescription}</h5>
          {dataImages &&
            dataImages.map((image: any) => (
              <OfficePostImage
                key={image.secure_url || image.url}
                src={image.secure_url || image.url}
                onResize={onImgResize}
              />
            ))}
        </>
      )}
    </OfficePostLinkPreviewContainer>
  );
};
