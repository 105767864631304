/* eslint-disable no-alert */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { AppMessages } from '../../languages';
import { useAuth } from '../../hooks/auth';
import { useGetter } from '../../hooks/getter';
import { OfficeModel, RoomModel, useApi } from '../../hooks/api';
import { useLanguage } from '../../hooks/language';
import { usePlanLimits } from '../../hooks/plan-limits';
import { useMattermost } from '../../hooks/mattermost';
import { useNotification } from '../../hooks/notification';
import { useThemeContext } from '../../hooks/theme';
import { useToast } from '../../hooks/toast';
import { useJitsi, getNewMeetingId } from '../../hooks/jitsi';
import { useOffice } from '../../hooks/office';
import { useModels, mobileRoom } from '../../hooks/model';
import { useReports } from '../../hooks/reports';
import { LogoSymbol } from '../../components/LogoSymbol';
import { Profile } from '../../components/Profile';
import { IconButton } from '../../components/IconButton';
import { Tooltip } from '../../components/Tooltip';
import {
  NotificationsIcon,
  EditOfficeIcon,
  AddIcon,
  ManageAccountsIcon,
  ReportsIcon,
  MoodIcon,
  OfficeIcon,
  MeetingIcon,
  OfficePostsIcon,
  OfficePostsBlankIcon,
  AddUserIcon,
  LocationIcon,
  RefreshIcon,
} from '../../components/Icons';
import { ThemeSwitcher } from '../../components/ThemeSwitcher';
import { RoomContainer } from '../../components/RoomContainer';
import { Room } from '../../components/Room';
import { ChatList } from '../../components/ChatList';
import { ChatChannelContainer } from '../../components/ChatChannelContainer';
import { UserInvitations } from '../../components/UserInvitations';
import { UserManagement } from '../../components/UserManagement';
import { MeetingLinkSharing } from '../../components/MeetingLinkSharing';
import { ReportsContainer } from '../../components/ReportsContainer';
import { UserMood } from '../../components/UserMood';
import {
  AddUsersTip,
  MeetingVisibilityTip,
} from '../../components/TutorialTip';
import { Survey } from '../../components/Survey';
import GeolocationMap from '../../components/GeolocationMap';

import { OfficeRoom } from './OfficeRoom';
import {
  Container,
  HeaderContainer,
  MainContainer,
  LeftAsideContainer,
  RightAsideContainer,
  AppActionsContainer,
  NavBarBorder,
  NavBarLogoButton,
  NavBarButtonContainer,
  NavBarButton,
} from './styles';

interface RoomPosition {
  [roomId: string]: {
    top: number;
    left: number;
    width?: number;
  };
}

interface Dimensions {
  width: number;
  height: number;
}

const Office: React.FC = () => {
  const textColor = useThemeContext((state) => state.theme.textColor);
  const boxColor = useThemeContext((state) => state.theme.boxColor);
  const addToast = useToast((state) => state.addToast);
  const messages = useLanguage((state) => state.messages);
  const isAdmin = useAuth((state) => state.isAdmin);
  const { roomsService, officesService, registerUserAction } = useApi();
  const getUsageLimits = usePlanLimits((state) => state.getUsageLimits);

  const isNotificationsEnable = useNotification(
    (state) => state.isNotificationsEnable
  );
  const enableNotifications = useNotification(
    (state) => state.enableNotifications
  );
  const disableNotifications = useNotification(
    (state) => state.disableNotifications
  );

  const [expanded, setExpanded] = useState('');
  const [hasScroll, setHasScroll] = useState(false);

  const isMeetingContainerVisible = useJitsi((state) =>
    state.isMeetingContainerVisible()
  );
  const setMeetingContainerVisibility = useJitsi(
    (state) => state.setMeetingContainerVisibility
  );
  const onShareMeetingLink = useJitsi((state) => state.onShareMeetingLink);

  const officesLoaded = useModels((state) => state.officesLoaded);
  const offices = useModels((state) => state.offices);
  const users = useModels((state) => state.users);

  const userInfoId = useOffice((state) => state.currentUserInfo.id);
  const officeId = useOffice((state) => state.currentUserInfo.officeId);
  const roomId = useOffice((state) => state.currentUserInfo.roomId);
  const userInfoGroupId = useOffice((state) => state.currentUserInfo.groupId);
  const soundEnabled = useOffice((state) => state.currentUserInfo.soundEnabled);
  const mood = useOffice((state) => state.currentUserInfo.mood);
  const getUsersInfo = useOffice((state) => state.getUsersInfo);
  const getAnonymousUsersInfo = useOffice(
    (state) => state.getAnonymousUsersInfo
  );
  const getAnonymousUserInfo = useOffice((state) => state.getAnonymousUserInfo);
  const officeEditMode = useOffice((state) => state.officeEditMode);
  const favoriteRooms = useOffice((state) => state.favoriteRooms);
  const toggleOfficeEditMode = useOffice((state) => state.toggleOfficeEditMode);
  const getRoomUsers = useOffice((state) => state.getRoomUsers);
  const mobileRoomUsers = useOffice((state) => {
    const officeRooms = state.roomUsers[officeId || 'default'] || {};
    return officeRooms[mobileRoom.id];
  });
  const getMobileRoomUsers = useGetter(mobileRoomUsers);
  const getRoomGroupedUsers = useOffice((state) => state.getRoomGroupedUsers);
  const getCurrentOffice = useOffice((state) => state.getCurrentOffice);
  const enterOfficeRoom = useOffice((state) => state.enterOfficeRoom);
  const inviteToGroupMeeting = useOffice((state) => state.inviteToGroupMeeting);
  const updateCurrentUserInfo = useOffice(
    (state) => state.updateCurrentUserInfo
  );
  const enterGroupMeeting = useOffice((state) => state.enterGroupMeeting);
  const userMoodIsOpen = useOffice((state) => state.userMoodIsOpen);
  const openUserMood = useOffice((state) => state.openUserMood);
  const closeUserMood = useOffice((state) => state.closeUserMood);
  const appVersions = useOffice((state) => state.appVersions);

  const channelsLoaded = useMattermost(
    (state) => Object.keys(state.teamChannels).length > 0
  );
  const unreadMessagesCount = useMattermost((state) => {
    const channelName = state.getOfficeChannelName(officeId || '');
    const officeChannel = state.officeChannels[channelName];
    return officeChannel
      ? state.getChannelUnreadsMessageCount(officeChannel)
      : 0;
  });
  const openOfficeChannel = useMattermost((state) => state.openOfficeChannel);
  const joinOfficeChannel = useMattermost((state) => state.joinOfficeChannel);

  const openReportsPanel = useReports((state) => state.openReportsPanel);

  const [userInvitationsIsOpen, setUserInvitationsIsOpen] = useState(false);
  const [userManagementIsOpen, setUserManagementIsOpen] = useState(false);
  const [meetingLinkSharingIsOpen, setMeetingLinkSharingIsOpen] = useState(
    false
  );
  const [geolocationMapIsOpen, setGeolocationMapIsOpen] = useState(false);
  const [sortedRooms, setSortedRooms] = useState<RoomModel[]>([]);
  const [roomPosition, setRoomPosition] = useState<RoomPosition>({});
  const [roomContainerSize, setRoomContainerSize] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  const [versionOutdated, setVersionOutdated] = useState(false);

  const { id: currentOfficeId, rooms } = getCurrentOffice();

  const handleConnectionRequest = useCallback(
    (idFrom: string, idTo: string) => {
      if (userInfoId !== idFrom && userInfoId !== idTo) {
        addToast({
          type: 'info',
          title: messages[AppMessages.notAllowed],
          description:
            messages[
              AppMessages.pageOfficeNotAllowedToRequestConnectionsBetweenOthers
            ],
        });
        return;
      }

      const usersInfoState = getUsersInfo();
      const anonymousUsersInfoState = getAnonymousUsersInfo();
      const from =
        usersInfoState[idFrom] ||
        getAnonymousUserInfo(anonymousUsersInfoState[idFrom]);
      const to =
        usersInfoState[idTo] ||
        getAnonymousUserInfo(anonymousUsersInfoState[idTo]);

      if (!from || !to) {
        const mobileRoomUsersState = getMobileRoomUsers();
        const otherId = idFrom !== userInfoId ? idFrom : idTo;
        const mobileUser = mobileRoomUsersState.find(
          (item) => item.id === otherId
        );

        if (!mobileUser) return;

        addToast({
          type: 'info',
          title: messages[AppMessages.notAllowed],
          description:
            messages[
              AppMessages
                .pageOfficeNotAllowedToRequestConnectionsWithInactiveMobileUser
            ],
        });
        return;
      }

      if (from.isGuest) return;

      if (to.isGuest) {
        if (!to.officeId || !to.roomId || !to.groupId) return;
        const participants = [
          ...(to.guestParticipantIds || []),
          to.guestCreatorId,
        ];
        if (!participants.includes(from.id)) return;
        enterGroupMeeting(to.officeId, to.roomId, to.groupId, true);
        return;
      }

      if (
        to.groupId &&
        to.groupId === from.groupId &&
        to.roomId === from.roomId &&
        to.officeId === from.officeId
      ) {
        return;
      }

      const groupId = to.groupId || getNewMeetingId();
      const other = idFrom !== userInfoId ? from : to;

      inviteToGroupMeeting(
        other.id,
        to.officeId || '',
        to.roomId || '',
        groupId
      );

      if (to.id !== other.id && !to.groupId && !to.soundEnabled) {
        updateCurrentUserInfo({ groupId });
      }
    },
    [
      userInfoId,
      getUsersInfo,
      messages,
      addToast,
      inviteToGroupMeeting,
      updateCurrentUserInfo,
      enterGroupMeeting,
      getAnonymousUserInfo,
      getAnonymousUsersInfo,
      getMobileRoomUsers,
    ]
  );

  const toggleMeetingVisibility = useCallback(() => {
    setMeetingContainerVisibility(!isMeetingContainerVisible);
  }, [setMeetingContainerVisibility, isMeetingContainerVisible]);

  const toggleNotificationsEnabled = useCallback(() => {
    if (isNotificationsEnable()) {
      disableNotifications();
    } else {
      enableNotifications();
    }
  }, [isNotificationsEnable, enableNotifications, disableNotifications]);

  const handleAddRoom = useCallback(async () => {
    if (!officesLoaded) return;
    const name = prompt(messages[AppMessages.pageOfficeRoomName]);
    if (!name) return;
    let roomOfficeId = currentOfficeId;
    if (roomOfficeId === 'default') roomOfficeId = offices[0]?.id || '';
    if (offices.length === 0)
      roomOfficeId = await officesService.post({ name: 'Main' } as OfficeModel);
    await roomsService.post(roomOfficeId, { name } as RoomModel);
    await registerUserAction({
      userId: userInfoId,
      action: 'room added',
      additionalInfo: `name: ${name}`,
    });
  }, [
    userInfoId,
    officesLoaded,
    messages,
    currentOfficeId,
    offices,
    officesService,
    roomsService,
    registerUserAction,
  ]);

  const handleStartNewMeeting = useCallback(() => {
    if (!officeId || !roomId) return false;
    if (userInfoGroupId && soundEnabled) return true;
    if (soundEnabled) {
      // eslint-disable-next-line no-restricted-globals
      const confirmed = confirm(
        messages[
          AppMessages.pageOfficeTurnOffRoomSoundAndStartMeetingConfirmation
        ]
      );
      if (!confirmed) return false;
      enterOfficeRoom(officeId, roomId);
    }
    const groupId = userInfoGroupId || getNewMeetingId();
    enterGroupMeeting(officeId, roomId, groupId, true);
    return true;
  }, [
    officeId,
    roomId,
    soundEnabled,
    userInfoGroupId,
    messages,
    enterOfficeRoom,
    enterGroupMeeting,
  ]);

  const handleMeetingLinkSharing = useCallback(async () => {
    const limits = await getUsageLimits();
    if (!limits.allowExternalMeetings) {
      addToast({
        type: 'info',
        title: messages[AppMessages.notAllowed],
        description: messages[AppMessages.limitsExternalMeetingsNotAllowed],
        duration: 7000,
      });
      return;
    }
    setMeetingLinkSharingIsOpen(true);
  }, [messages, getUsageLimits, addToast]);

  const handleReports = useCallback(async () => {
    const limits = await getUsageLimits();
    if (!limits.allowActivityReports) {
      addToast({
        type: 'info',
        title: messages[AppMessages.notAllowed],
        description: messages[AppMessages.limitsActivityReportsNotAllowed],
        duration: 7000,
      });
      return;
    }
    openReportsPanel();
  }, [messages, getUsageLimits, addToast, openReportsPanel]);

  const openUserManagement = useCallback(async () => {
    setUserManagementIsOpen(true);
    registerUserAction({
      userId: userInfoId,
      action: 'user management opened',
    });
  }, [userInfoId, registerUserAction]);

  const handleOpenOfficePostsChannel = useCallback(() => {
    if (!officeId) return;
    openOfficeChannel(officeId);
  }, [officeId, openOfficeChannel]);

  const handleViewMeeting = useCallback(() => {
    if (!handleStartNewMeeting()) return;
    if (!isMeetingContainerVisible) toggleMeetingVisibility();
  }, [
    handleStartNewMeeting,
    isMeetingContainerVisible,
    toggleMeetingVisibility,
  ]);

  const handleViewOffice = useCallback(() => {
    if (isMeetingContainerVisible) toggleMeetingVisibility();
  }, [isMeetingContainerVisible, toggleMeetingVisibility]);

  useEffect(() => {
    if (!appVersions) return;

    setVersionOutdated(appVersions.frontend !== process.env.REACT_APP_VERSION);
  }, [appVersions]);

  useEffect(() => {
    window.onscroll = () => {
      setHasScroll((state) => {
        const newValue = window.pageYOffset !== 0;
        return newValue !== state ? newValue : state;
      });
    };

    return () => {
      window.onscroll = null;
    };
  }, []);

  useEffect(() => {
    if (!officeId || officeId === 'default' || !channelsLoaded) return;
    joinOfficeChannel(officeId);
    // joinOfficeChannelComments(officeId);
  }, [officeId, channelsLoaded, joinOfficeChannel]);

  useEffect(() => {
    if (!mood) openUserMood();
  }, [mood, openUserMood]);

  useEffect(() => {
    onShareMeetingLink(handleMeetingLinkSharing);
  }, [onShareMeetingLink, handleMeetingLinkSharing]);

  useEffect(() => {
    const newRooms = [...rooms];

    newRooms.sort((a, b) => {
      // if (a.id === defaultRoom.id) return -1;
      // if (b.id === defaultRoom.id) return 1;

      const officeFavoriteRooms = favoriteRooms[currentOfficeId] || [];
      const aIndex = officeFavoriteRooms.indexOf(a.id);
      const bIndex = officeFavoriteRooms.indexOf(b.id);

      if (aIndex !== -1 && bIndex !== -1 && aIndex < bIndex) return -1;
      if (aIndex !== -1 && bIndex !== -1 && aIndex > bIndex) return 1;
      if (aIndex !== -1) return -1;
      if (bIndex !== -1) return 1;

      const aUsers = getRoomUsers(currentOfficeId, a.id);
      const bUsers = getRoomUsers(currentOfficeId, b.id);
      const aGroupedUsers = getRoomGroupedUsers(currentOfficeId, a.id);
      const bGroupedUsers = getRoomGroupedUsers(currentOfficeId, b.id);

      const aHasUsers = !!aUsers.length || !!Object.keys(aGroupedUsers).length;
      const bHasUsers = !!bUsers.length || !!Object.keys(bGroupedUsers).length;

      if (aHasUsers && !bHasUsers) return -1;
      if (!aHasUsers && bHasUsers) return 1;

      return a.name.localeCompare(b.name);
    });

    setSortedRooms(newRooms);
  }, [
    currentOfficeId,
    rooms,
    favoriteRooms,
    getRoomUsers,
    getRoomGroupedUsers,
  ]);

  useEffect(() => {
    const updatePositions = async () => {
      if (isMeetingContainerVisible) return;

      const positions: RoomPosition = {};

      const elementContainer = document.getElementById('room-container');
      const containerRect = elementContainer?.getBoundingClientRect();
      const containerTop = containerRect?.top || 0;
      const containerLeft = containerRect?.left || 0;

      const setPositionPromises = sortedRooms.map(async (room) => {
        const elementRoom = document.getElementById(room.id);
        const roomRect = elementRoom?.getBoundingClientRect();
        const roomTop = roomRect?.top || 0;
        const roomLeft = roomRect?.left || 0;
        const top = roomTop - containerTop;
        const left = roomLeft - containerLeft;
        const width = roomRect?.width;

        positions[room.id] = { top, left, width };
      });

      await Promise.all(setPositionPromises);

      setRoomPosition((state) => {
        if (JSON.stringify(state) === JSON.stringify(positions)) return state;
        return positions;
      });
    };

    const timer = setTimeout(updatePositions, 100);

    return () => clearTimeout(timer);
  }, [expanded, roomContainerSize, sortedRooms, isMeetingContainerVisible]);

  const hiddenRoomContainer = useMemo(
    () => (
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={(w, h) => setRoomContainerSize({ width: w, height: h })}
      >
        <RoomContainer hidden>
          {!isMeetingContainerVisible &&
            sortedRooms.map((room) => (
              <Room
                key={room.id}
                id={room.id}
                title={room.name}
                isCurrent={officeId === currentOfficeId && roomId === room.id}
                hidden
                expanded={expanded === room.id}
                avatars={getRoomUsers(currentOfficeId, room.id)}
                groupedAvatars={getRoomGroupedUsers(currentOfficeId, room.id)}
              />
            ))}
        </RoomContainer>
      </ReactResizeDetector>
    ),
    [
      currentOfficeId,
      expanded,
      getRoomGroupedUsers,
      getRoomUsers,
      isMeetingContainerVisible,
      officeId,
      roomId,
      sortedRooms,
    ]
  );

  const roomContainer = useMemo(
    () => (
      <RoomContainer>
        {!isMeetingContainerVisible &&
          rooms.map((room) => (
            <OfficeRoom
              key={room.id}
              room={room}
              expanded={expanded === room.id}
              top={roomPosition[room.id]?.top}
              left={roomPosition[room.id]?.left}
              width={roomPosition[room.id]?.width}
              setExpanded={setExpanded}
              handleConnectionRequest={handleConnectionRequest}
            />
          ))}
      </RoomContainer>
    ),
    [
      expanded,
      handleConnectionRequest,
      isMeetingContainerVisible,
      roomPosition,
      rooms,
    ]
  );

  const profile = useMemo(() => <Profile />, []);

  const hasUsersWithLocation = users.some((user) => !!user.lastLocation);

  const updateVersionButton = useMemo(
    () =>
      versionOutdated &&
      !userInfoGroupId &&
      !!appVersions && (
        <IconButton
          iconColor="red"
          onClick={async () => {
            if (caches) {
              const cacheKeys = await caches.keys();
              await Promise.allSettled(
                cacheKeys.map((name) => caches.delete(name))
              );
            }

            window.location.href = `${window.location.origin}?v=${appVersions?.frontend}`;
          }}
        >
          <RefreshIcon />
          <Tooltip
            position="bottom-end"
            text={messages[AppMessages.updateVersion]}
          />
        </IconButton>
      ),
    [versionOutdated, appVersions, messages, userInfoGroupId]
  );

  const geolocationMapButton = useMemo(
    () =>
      hasUsersWithLocation && (
        <IconButton onClick={() => setGeolocationMapIsOpen(true)}>
          <LocationIcon />
        </IconButton>
      ),
    [hasUsersWithLocation]
  );

  const userInvitationsButton = useMemo(
    () => (
      <IconButton
        className="add-users-button"
        onClick={() => {
          setUserInvitationsIsOpen(true);
          registerUserAction({
            userId: userInfoId,
            action: 'add users opened',
          });
        }}
      >
        <AddUserIcon />
        <AddUsersTip />
        <Tooltip
          position="bottom-end"
          text={messages[AppMessages.pageOfficeInviteUsers]}
        />
      </IconButton>
    ),
    [userInfoId, messages, registerUserAction]
  );

  const officeEditModeButtons = useMemo(
    () => (
      <>
        {!officeEditMode && (
          <span
            style={{
              width: 42,
            }}
          />
        )}

        {officeEditMode && (
          <IconButton onClick={handleAddRoom}>
            <AddIcon />
            <Tooltip
              position="bottom-end"
              text={messages[AppMessages.pageOfficeAddRoom]}
            />
          </IconButton>
        )}

        <IconButton
          className="edit-office-button"
          onClick={toggleOfficeEditMode}
          pressed={officeEditMode}
        >
          <EditOfficeIcon />
          <Tooltip
            position="bottom-end"
            text={
              officeEditMode
                ? messages[AppMessages.pageOfficeDisableEditMode]
                : messages[AppMessages.pageOfficeEnableEditMode]
            }
          />
        </IconButton>
      </>
    ),
    [handleAddRoom, messages, officeEditMode, toggleOfficeEditMode]
  );

  const userManagementButton = useMemo(
    () => (
      <IconButton
        className="user-management-button"
        onClick={openUserManagement}
      >
        <ManageAccountsIcon />
        <Tooltip
          position="bottom-end"
          text={messages[AppMessages.componentUserManagementTitle]}
        />
      </IconButton>
    ),
    [messages, openUserManagement]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const reportsButton = useMemo(
    () => (
      <IconButton className="reports-button" onClick={handleReports}>
        <ReportsIcon />
        <Tooltip
          position="bottom-end"
          text={messages[AppMessages.reportsTitle]}
        />
      </IconButton>
    ),
    [handleReports, messages]
  );

  const adminButtons = useMemo(
    () =>
      isAdmin() && (
        <>
          {geolocationMapButton}
          {userInvitationsButton}
          {officeEditModeButtons}
          {userManagementButton}
          {/* {reportsButton} */}
        </>
      ),
    [
      isAdmin,
      geolocationMapButton,
      userInvitationsButton,
      officeEditModeButtons,
      userManagementButton,
      // reportsButton,
    ]
  );

  const moodButton = useMemo(
    () => (
      <IconButton className="mood-button" onClick={openUserMood}>
        <MoodIcon mood={mood} outlined />
        <Tooltip
          position="bottom-end"
          text={messages[AppMessages.pageOfficeChangeMood]}
        />
      </IconButton>
    ),
    [mood, messages, openUserMood]
  );

  const notificationButton = useMemo(
    () => (
      <IconButton
        className="notifications-button"
        onClick={toggleNotificationsEnabled}
      >
        <NotificationsIcon enabled={isNotificationsEnable()} />
        <Tooltip
          position="bottom-end"
          text={
            isNotificationsEnable()
              ? messages[AppMessages.notificationsDisable]
              : messages[AppMessages.notificationsEnable]
          }
        />
      </IconButton>
    ),
    [isNotificationsEnable, messages, toggleNotificationsEnabled]
  );

  const themeButton = useMemo(() => <ThemeSwitcher />, []);

  const appActionsContainer = useMemo(
    () => (
      <AppActionsContainer>
        {updateVersionButton}
        {adminButtons}
        {moodButton}
        {notificationButton}
        {themeButton}
      </AppActionsContainer>
    ),
    [
      adminButtons,
      moodButton,
      notificationButton,
      themeButton,
      updateVersionButton,
    ]
  );

  const headerContainer = useMemo(
    () => (
      <HeaderContainer hasBackground={hasScroll}>
        {profile}
        {appActionsContainer}
      </HeaderContainer>
    ),
    [hasScroll, profile, appActionsContainer]
  );

  const rightAsideContainer = useMemo(
    () => (
      <RightAsideContainer>
        <ChatList />
      </RightAsideContainer>
    ),
    []
  );

  const chatChannelContainer = useMemo(() => <ChatChannelContainer />, []);

  const mainContainer = useMemo(
    () => (
      <MainContainer>
        {hiddenRoomContainer}
        {roomContainer}
        {chatChannelContainer}
      </MainContainer>
    ),
    [hiddenRoomContainer, roomContainer, chatChannelContainer]
  );

  const userMoodContainer = useMemo(
    () => <UserMood isOpen={userMoodIsOpen} onCloseRequest={closeUserMood} />,
    [userMoodIsOpen, closeUserMood]
  );

  const userManagementContainer = useMemo(
    () => (
      <UserManagement
        isOpen={userManagementIsOpen}
        onCloseRequest={() => {
          setUserManagementIsOpen(false);
        }}
        onOpenUserInvitationsRequest={() => {
          setUserInvitationsIsOpen(true);
          registerUserAction({
            userId: userInfoId,
            action: 'user management > add users opened',
          });
        }}
      />
    ),
    [userInfoId, registerUserAction, userManagementIsOpen]
  );

  const userInvitationsContainer = useMemo(
    () => (
      <UserInvitations
        isOpen={userInvitationsIsOpen}
        onCloseRequest={() => {
          setUserInvitationsIsOpen(false);
        }}
      />
    ),
    [userInvitationsIsOpen]
  );

  const geolocationMapContainer = useMemo(
    () => (
      <GeolocationMap
        isOpen={geolocationMapIsOpen}
        onCloseRequest={() => {
          setGeolocationMapIsOpen(false);
        }}
      />
    ),
    [geolocationMapIsOpen]
  );

  const meetingLinkSharingContainer = useMemo(
    () => (
      <MeetingLinkSharing
        isOpen={meetingLinkSharingIsOpen}
        onCloseRequest={() => {
          setMeetingLinkSharingIsOpen(false);
        }}
      />
    ),
    [meetingLinkSharingIsOpen]
  );

  const reportsContainer = useMemo(() => <ReportsContainer />, []);

  const navBarLogoButton = useMemo(
    () => (
      <NavBarLogoButton disabled>
        <LogoSymbol
          sizeInPixels={40}
          primaryColor={textColor}
          secondaryColor={boxColor}
        />
      </NavBarLogoButton>
    ),
    [boxColor, textColor]
  );

  const officeButtonContainer = useMemo(
    () => (
      <NavBarButtonContainer selected={!isMeetingContainerVisible}>
        <NavBarButton onClick={handleViewOffice}>
          <OfficeIcon />
          <MeetingVisibilityTip />
        </NavBarButton>
      </NavBarButtonContainer>
    ),
    [handleViewOffice, isMeetingContainerVisible]
  );

  const meetingButtonContainer = useMemo(
    () => (
      <NavBarButtonContainer selected={isMeetingContainerVisible}>
        <NavBarButton
          highlighted={!!userInfoGroupId && !!soundEnabled}
          blinking={!!userInfoGroupId && !!soundEnabled}
          onClick={handleViewMeeting}
        >
          <MeetingIcon />
          {!isMeetingContainerVisible && (
            <Tooltip
              position="right"
              text={
                !userInfoGroupId || !soundEnabled
                  ? messages[AppMessages.pageOfficeStartNewMeeting]
                  : messages[AppMessages.meetingShow]
              }
            />
          )}
        </NavBarButton>
      </NavBarButtonContainer>
    ),
    [
      userInfoGroupId,
      soundEnabled,
      handleViewMeeting,
      isMeetingContainerVisible,
      messages,
    ]
  );

  const officePostsButtonContainer = useMemo(
    () => (
      <NavBarButtonContainer>
        <NavBarButton
          disabled={!channelsLoaded}
          className={unreadMessagesCount ? 'animated-notification' : undefined}
          onClick={handleOpenOfficePostsChannel}
        >
          {!!unreadMessagesCount && (
            <>
              <OfficePostsBlankIcon />
              <span className="notification-count">
                {Math.min(unreadMessagesCount, 9)}
                {unreadMessagesCount > 9 && '+'}
              </span>
            </>
          )}
          {!unreadMessagesCount && <OfficePostsIcon />}
          <Tooltip position="right" text="Posts" />
        </NavBarButton>
      </NavBarButtonContainer>
    ),
    [channelsLoaded, handleOpenOfficePostsChannel, unreadMessagesCount]
  );

  const navBarBorder = useMemo(() => <NavBarBorder />, []);

  const leftAsideContainer = useMemo(
    () => (
      <LeftAsideContainer>
        {navBarLogoButton}
        {officeButtonContainer}
        {meetingButtonContainer}
        {officePostsButtonContainer}
        {navBarBorder}
      </LeftAsideContainer>
    ),
    [
      navBarLogoButton,
      officeButtonContainer,
      meetingButtonContainer,
      officePostsButtonContainer,
      navBarBorder,
    ]
  );

  const surveyContainer = useMemo(() => <Survey />, []);

  return (
    <Container>
      {mainContainer}
      {rightAsideContainer}
      {headerContainer}
      {userMoodContainer}
      {userManagementContainer}
      {userInvitationsContainer}
      {geolocationMapContainer}
      {meetingLinkSharingContainer}
      {reportsContainer}
      {leftAsideContainer}
      {surveyContainer}
    </Container>
  );
};

export default Office;
