import { Menu, MenuItem } from '@material-ui/core';
import { transparentize } from 'polished';
import { styled } from '../../themes';

export const ContextMenu = styled(Menu)`
  .MuiPaper-root {
    background-color: ${(props) => props.theme.background};
  }

  .MuiDivider-root {
    background-color: ${(props) => transparentize(0.7, props.theme.textColor)};
  }

  .context-menu-header {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.textColor};
    padding: 0 10px 10px;

    > img {
      border-radius: 5px;
      margin-right: 10px;
    }
  }

  .context-menu-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .context-menu-name {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    align-items: center;
    justify-content: center;

    > svg {
      margin-right: 5px;
    }
  }
`;

export const ContextMenuItem = styled(MenuItem)`
  color: ${(props) => props.theme.textColor} !important;
  padding: 10px 16px !important;

  :hover {
    background-color: ${(props) =>
      transparentize(0.9, props.theme.textColor)} !important;
  }

  svg {
    width: 24px;
    fill: ${(props) => props.theme.textColor};
    height: 24px;
    margin-right: 10px;
  }
`;
