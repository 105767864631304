import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import Favicon from 'react-favicon';

import favicon from '../../assets/favicon-32x32.png';
import faviconWithNotification from '../../assets/favicon_with_notification-32x32.png';

import { AppMessages } from '../../languages';
import { matchesSearchValue } from '../../utils/strings';
import { useModels } from '../../hooks/model';
import { useMattermost } from '../../hooks/mattermost';
import { useOffice } from '../../hooks/office';
import { SpinnerIcon } from '../Icons';
import { InputSearch } from '../InputSearch';

import { ChatListChannel } from './ChatListChannel';
import { ChatListContact } from './ChatListContact';
import {
  Container,
  Header,
  ChannelsContainer,
  ContactsContainer,
  LoadingContainer,
} from './styles';

export const ChatList: React.FC = () => {
  const { formatMessage } = useIntl();
  const users = useModels((state) => state.users);
  const mattermostUsers = useMattermost((state) => state.mattermostUsers);
  const directChannels = useMattermost((state) => state.directChannels);
  const teamChannels = useMattermost((state) => state.teamChannels);
  const initialize = useMattermost((state) => state.initialize);
  const teamNotificationsCount = useMattermost((state) =>
    Object.keys(state.teamChannels).reduce((previous, current) => {
      return (
        previous +
        state.getChannelUnreadsMessageCount(state.teamChannels[current])
      );
    }, 0)
  );
  const directNotificationsCount = useMattermost((state) =>
    users.reduce((previous, current) => {
      if (current.accessRevokedAt) return previous;
      const directChannel = state.directChannels[current.email];
      if (!directChannel) return previous;
      return previous + state.getChannelUnreadsMessageCount(directChannel);
    }, 0)
  );
  const openChannelExplorer = useMattermost(
    (state) => state.openChannelExplorer
  );
  const getChannelDisplayName = useMattermost(
    (state) => state.getChannelDisplayName
  );

  const filteredUsers = useOffice((state) => state.filteredUsers);
  const setFilteredUsers = useOffice((state) => state.setFilteredUsers);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const getChannelList = useCallback(() => {
    return Object.keys(teamChannels).map((t) => teamChannels[t]);
  }, [teamChannels]);

  const [filteredChannels, setFilteredChannels] = useState(getChannelList());
  const [searchValue, setSearchValue] = useState('');

  const loadingChannels = !Object.keys(teamChannels).length;
  const loadingContacts =
    loadingChannels || !Object.keys(mattermostUsers).length;

  const hasTeamNotification = teamNotificationsCount > 0;
  const hasDirectNotification = directNotificationsCount > 0;
  const hasNotification = hasTeamNotification || hasDirectNotification;

  if ((navigator as any).setAppBadge) {
    (navigator as any).setAppBadge(
      teamNotificationsCount + directNotificationsCount
    );
  }

  const handleSearch = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const handleAddChannel = useCallback(() => {
    openChannelExplorer();
  }, [openChannelExplorer]);

  const clearSearchValue = useCallback(() => {
    if (searchInputRef.current) searchInputRef.current.value = '';
    setSearchValue('');
  }, []);

  useEffect(() => {
    const filtered = users
      .filter(
        (t) =>
          !t.accessRevokedAt &&
          !!t.lastLoginAt &&
          mattermostUsers[t.email] &&
          matchesSearchValue(`${t.firstName} ${t.lastName}`, searchValue)
      )
      .sort((a, b) => {
        const channelA = directChannels[a.email];
        const channelB = directChannels[b.email];
        const aLastPostAt = !channelA ? 0 : channelA.last_post_at;
        const bLastPostAt = !channelB ? 0 : channelB.last_post_at;
        const result = bLastPostAt - aLastPostAt;
        if (result !== 0) return result;

        return `${a.firstName} ${a.lastName}`
          .trim()
          .localeCompare(`${b.firstName} ${b.lastName}`.trim());
      });

    setFilteredUsers(filtered);
  }, [users, searchValue, mattermostUsers, directChannels, setFilteredUsers]);

  useEffect(() => {
    const filtered = getChannelList()
      .filter((t) => matchesSearchValue(getChannelDisplayName(t), searchValue))
      .sort((a, b) => b.last_post_at - a.last_post_at);

    setFilteredChannels(filtered);
  }, [getChannelList, getChannelDisplayName, searchValue]);

  useEffect(() => {
    const content = document.getElementById('scrollable-content-channels');
    if (!content) return;
    content.scrollTop = 0;
  }, [filteredChannels]);

  useEffect(() => {
    const content = document.getElementById('scrollable-content-contacts');
    if (!content) return;
    content.scrollTop = 0;
  }, [filteredUsers]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const faviconComponent = useMemo(
    () => <Favicon url={hasNotification ? faviconWithNotification : favicon} />,
    [hasNotification]
  );

  const header = useMemo(
    () => (
      <Header>
        Chat
        <InputSearch onChange={handleSearch} ref={searchInputRef} />
      </Header>
    ),
    [handleSearch]
  );

  const channelsContainerTitle = useMemo(
    () => (
      <div className="title">
        {formatMessage({ id: AppMessages.chatChannels })}
        <button type="button" className="add-button" onClick={handleAddChannel}>
          +
        </button>
      </div>
    ),
    [formatMessage, handleAddChannel]
  );

  const channelList = useMemo(
    () =>
      filteredChannels.map((channel) => (
        <ChatListChannel
          key={channel.id}
          channel={channel}
          onClick={clearSearchValue}
        />
      )),
    [clearSearchValue, filteredChannels]
  );

  const loadingChannelsContainer = useMemo(
    () =>
      loadingChannels && (
        <LoadingContainer>
          <SpinnerIcon />
        </LoadingContainer>
      ),
    [loadingChannels]
  );

  const channelsScrollContainer = useMemo(
    () => (
      <div id="scrollable-content-channels" className="scrollable-content">
        {channelList}
        {loadingChannelsContainer}
      </div>
    ),
    [channelList, loadingChannelsContainer]
  );

  const channelsContainer = useMemo(
    () => (
      <ChannelsContainer>
        {channelsContainerTitle}
        {channelsScrollContainer}
      </ChannelsContainer>
    ),
    [channelsContainerTitle, channelsScrollContainer]
  );

  const contactsContainerTitle = useMemo(
    () => (
      <div className="title">
        {formatMessage({ id: AppMessages.chatContacts })}
      </div>
    ),
    [formatMessage]
  );

  const contactList = useMemo(
    () =>
      !loadingContacts &&
      filteredUsers.map((user) => (
        <ChatListContact key={user.id} user={user} onClick={clearSearchValue} />
      )),
    [clearSearchValue, filteredUsers, loadingContacts]
  );

  const loadingContactsContainer = useMemo(
    () =>
      loadingContacts && (
        <LoadingContainer>
          <SpinnerIcon />
        </LoadingContainer>
      ),
    [loadingContacts]
  );

  const contactsScrollContainer = useMemo(
    () => (
      <div id="scrollable-content-contacts" className="scrollable-content">
        {contactList}
        {loadingContactsContainer}
      </div>
    ),
    [contactList, loadingContactsContainer]
  );

  const contactsContainer = useMemo(
    () => (
      <ContactsContainer>
        {contactsContainerTitle}
        {contactsScrollContainer}
      </ContactsContainer>
    ),
    [contactsContainerTitle, contactsScrollContainer]
  );

  return (
    <Container>
      {faviconComponent}
      {header}
      {channelsContainer}
      {contactsContainer}
    </Container>
  );
};
