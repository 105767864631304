import React from 'react';

import { Container } from './styles';

interface Props {
  children?: React.ReactNode;
  spacingInPixels?: number;
  hidden?: boolean;
}

export const RoomContainer: React.FC<Props> = ({
  children,
  spacingInPixels = 10,
  hidden = false,
}: Props) => {
  return (
    <Container
      className="room-container"
      id="room-container"
      spacingInPixels={spacingInPixels}
      hidden={hidden}
    >
      {children}
    </Container>
  );
};
