import { shade } from 'polished';
import { styled } from '../../themes';

const WIDTH = 85;
const HEADER_HEIGHT = 40;

export const Container = styled.div`
  position: relative;
  width: 60%;
  min-width: ${WIDTH}vw;
  max-width: ${WIDTH}vw;
  height: 85vh;
  background: ${(props) => props.theme.boxColor};
  color: ${(props) => props.theme.textColor};
  box-shadow: ${(props) => props.theme.boxGlow};
  border-radius: 5px;

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: ${HEADER_HEIGHT}px;
    max-height: ${HEADER_HEIGHT}px;
    padding: 5px;
    padding-left: 10px;

    > .display-name {
      width: 100%;
    }
  }

  > .content {
    min-height: calc(85vh - ${HEADER_HEIGHT}px);
    max-height: calc(85vh - ${HEADER_HEIGHT}px);
    max-width: 100%;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: scroll;

    > .no-data-label {
      padding: 10px;
    }

    .pvtRenderers,
    .pvtAxisContainer,
    .pvtVals {
      display: none;
    }

    .pvtRowLabel {
      vertical-align: top;
    }

    .pvtFilteredAttribute {
      background: yellow !important;
    }

    .pvtAxisLabel,
    .pvtTotalLabel {
      background: lightgray !important;
    }

    .filters-container {
      display: flex;
      > * {
        margin-right: 10px;
      }
    }
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  background: ${(props) => props.theme.background};
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  opacity: 0.6;

  > svg {
    font-size: 50px;
  }
`;

export const Button = styled.button`
  border: none;
  background: ${(props) => props.color || 'green'};
  padding: 7px 14px;
  color: white;
  white-space: nowrap;
  border-radius: 5px;
  transition: opacity 0.3s;

  :hover {
    background: ${(props) => shade(0.2, props.color || 'green')};
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
    :hover {
      background: ${shade(0.2, 'green')};
    }
  }
`;
