import React, { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CircularProgress } from '@material-ui/core';

import { isBrowserSupported } from '../../utils/browser';
import logoImage from '../../assets/images/logo_01.png';
import logoIncompatibleBrowser from '../../assets/images/sad-proseia-browser.svg';
import peopleImage from '../../assets/images/people_001.png';
import telluriaLogo from '../../assets/images/telluria_logo_small.png';
import { AppMessages } from '../../languages';
import { LanguageSelector } from '../LanguageSelector';
import { ChromeIcon, EdgeIcon } from '../Icons';

import {
  Container,
  Logo,
  FormContainer,
  ConfigContainer,
  PageImage,
  LogoImage,
  MessageContainer,
  CopyrightContainer,
  ProgressMask,
  IncompatibleBrowserContainer,
  IncompatibleBrowserLinksContainer,
  IncompatibleBrowserLinks,
  IncompatibleBrowserTitleContainer,
} from './styles';

interface Props {
  loading?: boolean;
}

export const ProseiaPageContainer: React.FC<Props> = ({
  children,
  loading = false,
}) => {
  const { formatMessage } = useIntl();
  const [browserSupported] = useState(isBrowserSupported);

  const incompatibleBrowserMessage = useMemo(
    () =>
      !browserSupported && (
        <IncompatibleBrowserContainer>
          <IncompatibleBrowserTitleContainer>
            {formatMessage({
              id: AppMessages.incompatibleBrowserTitle,
            })}
          </IncompatibleBrowserTitleContainer>
          <img alt="Incompatible Browser" src={logoIncompatibleBrowser} />
          <MessageContainer align="center">
            {formatMessage({
              id: AppMessages.incompatibleBrowserDescription,
            })}
          </MessageContainer>
          <IncompatibleBrowserLinksContainer>
            <IncompatibleBrowserLinks
              href="https://www.google.com/chrome"
              target="_blank"
            >
              <ChromeIcon />
              Chrome
            </IncompatibleBrowserLinks>
            <IncompatibleBrowserLinks
              href="https://www.microsoft.com/edge"
              target="_blank"
            >
              <EdgeIcon />
              Edge
            </IncompatibleBrowserLinks>
          </IncompatibleBrowserLinksContainer>
        </IncompatibleBrowserContainer>
      ),
    [browserSupported, formatMessage]
  );

  return (
    <>
      <PageImage src={peopleImage} />

      <Container>
        <Logo>
          <LogoImage src={logoImage} />
        </Logo>

        <br />

        {browserSupported && <FormContainer>{children}</FormContainer>}

        {incompatibleBrowserMessage}

        <br />

        <CopyrightContainer>
          {formatMessage(
            { id: AppMessages.copyrightText },
            { year: new Date().getFullYear() }
          )}
          <LogoImage src={telluriaLogo} />
        </CopyrightContainer>
      </Container>

      <ConfigContainer>
        <LanguageSelector />
      </ConfigContainer>

      {loading && (
        <ProgressMask>
          <CircularProgress />
        </ProgressMask>
      )}
    </>
  );
};
