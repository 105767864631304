/* eslint-disable no-plusplus */
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { useThemeContext } from '../../hooks/theme';
import { useOffice } from '../../hooks/office';
import { Avatar } from '../Avatar';
import { AvatarSize, AvatarStatus, AvatarMood } from '../Avatar/styles';

import { Container } from './styles';

export interface AvatarProps {
  id: string;
  picture?: string;
  firstName: string;
  lastName: string;
  email?: string;
  roleDescription?: string;
  status: AvatarStatus;
  statusMessage?: string;
  mood?: AvatarMood;
  isGuest?: boolean;
  guestCreatorId?: string;
  guestParticipantIds?: string[];
  isRemote: boolean;
  micEnabled: boolean;
  videoEnabled: boolean;
  soundEnabled: boolean;
  screenShared?: boolean;
  isMobile?: boolean;
}

interface Props {
  groupId: string;
  list: AvatarProps[];
  avatarSize?: AvatarSize;
  spacingInPixels?: number;
  showAvatarIcons?: boolean;
  showAvatarTooltip?: boolean;
  onConnectionRequest?: (idFrom: string, idTo: string) => void | Promise<void>;
}

export const AvatarGroup: React.FC<Props> = ({
  groupId,
  list = [],
  avatarSize = 'small',
  spacingInPixels = 6,
  showAvatarIcons = true,
  showAvatarTooltip = true,
  onConnectionRequest,
}: Props) => {
  const theme = useThemeContext((state) => state.theme);
  const container = useRef<HTMLDivElement>(null);
  const resizeEventTimeout = useRef<NodeJS.Timeout>();

  const shouldHighlightFiltered = useOffice(
    (state) => state.shouldHighlightFiltered
  );
  const filteredUsers = useOffice((state) => state.filteredUsers);

  const [size, setSize] = useState({ width: 0, height: 0 });

  const handleResize = useCallback((width: number, height: number) => {
    if (resizeEventTimeout.current) clearTimeout(resizeEventTimeout.current);
    resizeEventTimeout.current = setTimeout(() => {
      setSize({ width, height });
      resizeEventTimeout.current = undefined;
    }, 100);
  }, []);

  const updateConnections = useCallback(() => {
    const avatars = container.current?.getElementsByClassName('avatar') || [];

    list.forEach((item, index) => {
      const current = container.current?.children?.item(index);

      if (!current) return;

      current.classList.remove('with-right-connection', 'with-down-connection');

      const rect = current.getBoundingClientRect();
      const { top, left } = rect;

      for (let i = 0; i < avatars.length; i++) {
        const avatar = avatars[i] || {};
        const otherRect = avatar.getBoundingClientRect();

        if (top === otherRect.top && left < otherRect.left)
          current.classList.add('with-right-connection');

        if (left === otherRect.left && top < otherRect.top)
          current.classList.add('with-down-connection');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, size, list, theme]);

  useEffect(() => {
    updateConnections();
  }, [updateConnections]);

  const groupContainer = useMemo(
    () => (
      <Container
        id={groupId}
        ref={container}
        className="avatar-group"
        spacingInPixels={spacingInPixels}
      >
        {list.map((item) => (
          <Avatar
            id={item.id}
            key={item.id}
            firstName={item.firstName}
            lastName={item.lastName}
            email={item.email}
            roleDescription={item.roleDescription}
            size={avatarSize}
            picture={item.picture}
            draggable={!item.isGuest}
            status={item.status}
            statusMessage={item.statusMessage}
            mood={item.mood}
            showIcons={showAvatarIcons}
            showTooltip={showAvatarTooltip}
            isRemote={item.isRemote}
            groupId={groupId}
            soundEnabled={item.soundEnabled}
            micEnabled={item.micEnabled}
            videoEnabled={item.videoEnabled}
            screenShared={item.screenShared}
            isMobile={item.isMobile}
            spacingInPixels={spacingInPixels}
            highlight={
              shouldHighlightFiltered &&
              filteredUsers.some((t) => t.id === item.id)
            }
            hasContexMenu
            onConnectionRequest={onConnectionRequest}
          />
        ))}
      </Container>
    ),
    [
      avatarSize,
      groupId,
      list,
      onConnectionRequest,
      showAvatarIcons,
      showAvatarTooltip,
      spacingInPixels,
      shouldHighlightFiltered,
      filteredUsers,
    ]
  );

  return (
    <ReactResizeDetector handleWidth handleHeight onResize={handleResize}>
      {groupContainer}
    </ReactResizeDetector>
  );
};
