import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { Emoji, EmojiData } from 'emoji-mart';
import { Post } from 'mattermost-redux/types/posts';
import { Channel } from 'mattermost-redux/types/channels';

import { ReactionButton, CustomDivider } from './styles';
import { OfficePostFileLink } from './OfficePostFileLink';
import { OfficePostLinkPreview } from './OfficePostLinkPreview';
import { OfficePostEmojiPicker } from './OfficePostEmojiPicker';
import { AppMessages } from '../../languages';
import { useMattermost, formatPostContent } from '../../hooks/mattermost';
import { getFormattedUserName } from '../../hooks/office';
import { useDateFormat } from '../../hooks/dateFormat';
import { DeleteIcon, AddReactionIcon, PinIcon, CommentsIcon } from '../Icons';
import { IconButton } from '../IconButton';
import { Tooltip } from '../Tooltip';
import { OfficePostComments } from './OfficePostComments';
import { OfficePostCommentInput } from './OfficePostCommentInput';

type PostReactions = { emoji: string; users: string[] }[];

interface Props {
  post: Post;
  channel: Channel;
  comments?: Post[];
  // officeId: string;
  highlightTerm?: string;
  onImgResize?: (width: number, height: number) => void;
  onJumpClick?: () => void;
}

export const OfficePost: React.FC<Props> = ({
  post,
  comments = [],
  channel,
  // officeId,
  highlightTerm,
  onImgResize,
  onJumpClick,
}) => {
  const [showComments, setShowComments] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);
  const postDivRef = useRef<HTMLDivElement>(null);
  const mattermostUserId = useMattermost(
    (state) => state.mattermostUser?.id || ''
  );

  const getProseiaUser = useMattermost((state) => state.getProseiaUser);
  const deletePost = useMattermost((state) => state.deletePost);
  const pinPost = useMattermost((state) => state.pinPost);
  const unpinPost = useMattermost((state) => state.unpinPost);
  const addReaction = useMattermost((state) => state.addReaction);
  const removeReaction = useMattermost((state) => state.removeReaction);
  const lastViewedAt = useMattermost(
    // eslint-disable-next-line camelcase
    (state) => state.channelMyMemberships[channel.id]?.last_viewed_at || 0
  );

  const formatDateTime = useDateFormat((state) => state.formatDateTime);

  const { formatMessage } = useIntl();
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [postReactions, setPostReactions] = useState<PostReactions>([]);

  const user = getProseiaUser(post.user_id);
  const { firstName, lastName } = user;
  const displayName = useMemo(
    () =>
      post.type.startsWith('system_')
        ? 'System'
        : getFormattedUserName({ firstName, lastName }),
    [firstName, lastName, post.type]
  );

  const createdAt = useMemo(() => new Date(post.create_at), [post.create_at]);
  const displayTime = formatDateTime(createdAt);
  // const displayTime = `${createdAt.toLocaleDateString()} - ${format(
  //   createdAt,
  //   'HH:mm'
  // )}`;

  const { embeds, files, reactions } = post.metadata;
  const reactionsCount = reactions?.length;

  const getImage = useCallback(() => {
    return <img alt={displayName} src={user.picture} width="40" height="40" />;
  }, [user, displayName]);

  const handleDelete = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm(formatMessage({ id: AppMessages.officePostConfirmDeletion })))
      return;
    deletePost(post.id);
  }, [post, formatMessage, deletePost]);

  const handlePin = useCallback(() => {
    return post.is_pinned ? unpinPost(post.id) : pinPost(post.id);
  }, [post, pinPost, unpinPost]);

  const handleAddReaction = useCallback(
    (emoji: EmojiData) => {
      if (!emoji?.id) return;
      addReaction(post.id, emoji.id);
    },
    [post, addReaction]
  );

  const unreadComments = useMemo(() => {
    return comments.filter((comment) => comment.create_at > lastViewedAt)
      .length;
  }, [comments, lastViewedAt]);

  useEffect(() => {
    if (!reactions || !reactionsCount) {
      setPostReactions([]);
      return;
    }
    setPostReactions(
      reactions.reduce((result, item) => {
        const previouslyAdded = result.find((t) => t.emoji === item.emoji_name);
        if (previouslyAdded) {
          if (!previouslyAdded.users.includes(item.user_id))
            previouslyAdded.users.push(item.user_id);
          return result;
        }
        return [...result, { emoji: item.emoji_name, users: [item.user_id] }];
      }, [] as PostReactions)
    );
  }, [reactions, reactionsCount]);

  return (
    <>
      <CustomDivider variant="middle" />
      <div id={post.id} className="post" ref={postDivRef}>
        <div className="post-main">
          <div className="post-header">
            <div className="post-image">{getImage()}</div>
            <div className="post-text">
              <div className="post-display-name">
                {displayName}
                {user.roleDescription && (
                  <span className="post-user-role-description">{`· ${user.roleDescription}`}</span>
                )}
                {post.is_pinned && (
                  <IconButton
                    iconSize={18}
                    padding={1}
                    iconColor="green"
                    disabled
                  >
                    <PinIcon />
                  </IconButton>
                )}
              </div>
              <div className="post-time">{displayTime}</div>
            </div>
            {!onJumpClick && (
              <div className="post-actions-container">
                <IconButton
                  iconSize={18}
                  iconColor={post.is_pinned ? 'green' : undefined}
                  onClick={handlePin}
                >
                  <PinIcon />
                  <Tooltip
                    position="top-end"
                    text={
                      post.is_pinned
                        ? formatMessage({ id: AppMessages.officePostUnpin })
                        : formatMessage({ id: AppMessages.officePostPin })
                    }
                  />
                </IconButton>
                {mattermostUserId === post.user_id && (
                  <IconButton iconSize={18} onClick={handleDelete}>
                    <DeleteIcon />
                    <Tooltip
                      position="top-end"
                      text={formatMessage({ id: AppMessages.officePostDelete })}
                    />
                  </IconButton>
                )}
              </div>
            )}
          </div>
          <div className="post-text post-content">
            {formatPostContent(post.message, highlightTerm)}
          </div>
        </div>

        {files &&
          files.map((file) => (
            <OfficePostFileLink
              key={`${post.id}|file|${file.id}`}
              file={file}
              onImgResize={onImgResize}
            />
          ))}

        {embeds &&
          embeds.map((embed) => (
            <OfficePostLinkPreview
              key={`${post.id}|embed|${embed.url}`}
              embed={embed}
              onImgResize={onImgResize}
            />
          ))}

        {!onJumpClick && (
          <div className="interactions-in-post">
            <div className="reactions-container">
              {postReactions.map((item) => {
                const reacted = item.users.includes(mattermostUserId);
                return (
                  <ReactionButton
                    key={item.emoji}
                    reacted={reacted}
                    onClick={() => {
                      if (!reacted) addReaction(post.id, item.emoji);
                      else removeReaction(post.id, item.emoji);
                    }}
                  >
                    <Emoji emoji={item.emoji} size={18} />
                    &nbsp;
                    {item.users.length}
                    <Tooltip
                      position="top-start"
                      maxWidth={200}
                      text={item.users
                        .map((userId) =>
                          getFormattedUserName(getProseiaUser(userId))
                        )
                        .join(', ')}
                    />
                  </ReactionButton>
                );
              })}
              <IconButton
                iconSize={20}
                padding={0}
                className="add-reaction-button"
                onClick={() => setEmojiPickerOpen(true)}
              >
                <AddReactionIcon />
                <Tooltip
                  text={formatMessage({
                    id: AppMessages.officePostAddReaction,
                  })}
                />
              </IconButton>
            </div>

            <div className="comment-count">
              <button
                type="button"
                className="show-comments-button"
                onClick={() => setShowComments(!showComments)}
              >
                <div>
                  <CommentsIcon />
                  {`${comments.length} `}
                  {formatMessage({
                    id: AppMessages.officePostCommentsCountTitle,
                  })}
                </div>
                {unreadComments > 0 && (
                  <span className="new-comments">
                    {`${unreadComments} `}
                    {formatMessage({
                      id: AppMessages.officePostCommentsNewCount,
                    })}
                  </span>
                )}
              </button>
            </div>
          </div>
        )}

        {comments.length > 0 && (
          <OfficePostComments
            postId={post.id}
            comments={comments}
            showComments={showComments}
            setShowComments={setShowComments}
            scrollDown={scrollDown}
            setScrollDown={setScrollDown}
            postDivRef={postDivRef}
          />
        )}

        {!onJumpClick && (
          <OfficePostCommentInput
            channel={channel}
            post={post}
            showComments={showComments}
            setShowComments={setShowComments}
            scrollDown={scrollDown}
            setScrollDown={setScrollDown}
          />
        )}

        {onJumpClick && (
          <button type="button" className="jump" onClick={onJumpClick}>
            <FaArrowAltCircleRight />
          </button>
        )}

        <OfficePostEmojiPicker
          open={emojiPickerOpen}
          channelId={post.channel_id}
          onClose={() => setEmojiPickerOpen(false)}
          onSelect={handleAddReaction}
          type="post"
        />
      </div>
    </>
  );
};
