import React, { useState, useRef, useCallback } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';

interface Props {
  isOpen: boolean;
  image: string;
  onCloseRequest: (croppedImage?: string) => void;
}

export const ProfilePictureEditor: React.FC<Props> = ({
  isOpen,
  image,
  onCloseRequest,
}) => {
  const [zoom, setZoom] = useState(1);
  const editorRef = useRef<AvatarEditor>(null);

  const handleZoomSlider = useCallback(
    (event: React.ChangeEvent<any>, value: number | number[]) => {
      const newZoom = typeof value === 'object' ? value[0] : value;
      setZoom(newZoom);
    },
    []
  );

  const handleSave = useCallback(() => {
    if (editorRef.current) {
      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      onCloseRequest(croppedImg);
    }
  }, [onCloseRequest]);

  const handleCancel = useCallback(() => {
    onCloseRequest();
  }, [onCloseRequest]);

  return (
    <div
      className="cropper-wrapper"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(200,200,200,.8)',
        display: isOpen ? 'flex' : 'none',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 6,
      }}
    >
      <AvatarEditor
        ref={editorRef}
        image={image}
        width={200}
        height={200}
        border={50}
        color={[255, 255, 255, 0.6]} // RGBA
        scale={zoom}
        rotate={0}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            fontSize: 12,
            marginRight: 10,
            paddingBottom: 22,
            fontWeight: 600,
          }}
        >
          Zoom
        </span>
        <Slider
          name="slider"
          min={1}
          max={10}
          step={0.1}
          value={zoom}
          onChange={handleZoomSlider}
          style={{ width: 200 }}
        />
      </div>
      <div>
        <Button variant="contained" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Confirm
        </Button>
      </div>
    </div>
  );
};
