import { styled } from '../../themes';

export const Container = styled.div`
  display: flex;
  /* flex-direction: column; */
  position: relative;
  width: fit-content;
  align-items: center;
  justify-content: space-between;
`;

export const UserStatusElements = styled.div`
  align-self: flex-start;
  display: flex;
  position: relative;
  height: auto;
  width: 74px;
  align-items: center;
  /* margin-bottom: 10px; */
  > svg {
    font-size: 16px;
    width: 16px;
    height: 16px;
    fill: ${(props) => props.theme.textColor};
    + svg {
      margin-left: 13px;
    }
  }
`;

export const UserRemoteLabel = styled.div`
  position: relative;
  display: flex;
  width: 100px;
  height: 20px;
  align-items: center;
`;

export const UserRemoteSwitch = styled.div`
  position: relative;
  width: 40px;
  height: 20px;
  margin-left: 5px;
`;
