import React, { useCallback } from 'react';

import { AppMessages } from '../../languages';
import { RoomModel } from '../../hooks/api';
import { useOffice } from '../../hooks/office';
import { useLanguage } from '../../hooks/language';
import { useToast } from '../../hooks/toast';
import { Room } from '../../components/Room';

interface Props {
  room: RoomModel;
  top?: number;
  left?: number;
  width?: number;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<string>>;
  handleConnectionRequest: (idFrom: string, idTo: string) => void;
}

export const OfficeRoom: React.FC<Props> = ({
  room,
  top,
  left,
  width,
  expanded,
  setExpanded,
  handleConnectionRequest,
}) => {
  const addToast = useToast((state) => state.addToast);
  const messages = useLanguage((state) => state.messages);
  const enterOfficeRoom = useOffice((state) => state.enterOfficeRoom);
  const userInfoId = useOffice((state) => state.currentUserInfo.id);
  const officeId = useOffice((state) => state.currentUserInfo.officeId);
  const roomId = useOffice((state) => state.currentUserInfo.roomId);
  const currentOfficeId = useOffice((state) => state.getCurrentOffice().id);
  const avatars = useOffice((state) =>
    state.getRoomUsers(state.getCurrentOffice().id, room.id)
  );
  const groupedAvatars = useOffice((state) =>
    state.getRoomGroupedUsers(state.getCurrentOffice().id, room.id)
  );

  const isCurrent = officeId === currentOfficeId && roomId === room.id;

  const handleToggleExpanded = useCallback(
    (isExpanded: boolean) => {
      if (isExpanded) setExpanded(room.id);
      else setExpanded('');
    },
    [room.id, setExpanded]
  );

  const handleEnterRoom = useCallback(
    (id: string) => {
      if (userInfoId !== id) {
        addToast({
          type: 'info',
          title: messages[AppMessages.notAllowed],
          description: messages[AppMessages.pageOfficeNotAllowedToMoveOthers],
        });
        return;
      }
      enterOfficeRoom(currentOfficeId, room.id);
    },
    [addToast, currentOfficeId, userInfoId, enterOfficeRoom, messages, room.id]
  );

  const handleLeaveGroup = useCallback(
    (groupId: string, id: string) => {
      handleEnterRoom(id);
    },
    [handleEnterRoom]
  );

  return (
    <Room
      id={room.id}
      title={room.name}
      top={top}
      left={left}
      width={width}
      isCurrent={isCurrent}
      expanded={expanded}
      avatars={avatars}
      groupedAvatars={groupedAvatars}
      onToggleExpanded={handleToggleExpanded}
      onConnectionRequest={handleConnectionRequest}
      onEnterRoom={handleEnterRoom}
      onLeaveGroup={handleLeaveGroup}
    />
  );
};
