export enum AppMessages {
  pageSignInWelcome = 'page.signIn.welcomeMessage',
  pageSignInEmail = 'page.signIn.email',
  pageSignInPassword = 'page.signIn.password',
  pageSignInConfirmPassword = 'page.signIn.confirmPassword',
  pageSignInFirstName = 'page.signIn.firstName',
  pageSignInLastName = 'page.signIn.lastName',
  pageSignInRoleDescription = 'page.signIn.roleDescription',
  pageSignInPhoneNumber = 'page.signIn.phoneNumber',
  pageSignInCompany = 'page.signIn.company',
  pageSignInSubmit = 'page.signIn.submit',
  pageSignInForgotPassword = 'page.signIn.forgotPassword',
  pageSignInForgotPasswordConfirm = 'page.signIn.forgotPassword.confirm',
  pageSignInForgotPasswordLinkSended = 'page.signIn.forgotPassword.linkSended',
  pageSignInValidationRequired = 'page.signIn.validation.required',
  pageSignInValidationInvalidEmail = 'page.signIn.validation.invalidEmail',
  pageSignInValidationWeakPassword = 'page.signIn.validation.weakPassword',
  pageSignInValidationDoesNotMatch = 'page.signIn.validation.doesNotMatch',
  pageSignInValidationInvalidPhoneNumber = 'page.signIn.validation.invalidPhoneNumber',
  pageSignInValidationInvalidCredentials = 'page.signIn.validation.invalidCredentials',
  pageSignInValidationIncompleteRegistration = 'page.signIn.validation.incompleteRegistration',
  pageSignInKeepConnected = 'page.signIn.keepConnected',

  pageCreateEnvironmentWelcome = 'page.createEnvironment.welcomeMessage',
  pageCreateEnvironmentOrganization = 'page.createEnvironment.organization',
  pageCreateEnvironmentEmail = 'page.createEnvironment.email',
  pageCreateEnvironmentSubdomain = 'page.createEnvironment.subdomain',
  pageCreateEnvironmentSubdomainPlaceholder = 'page.createEnvironment.subdomainPlaceholder',
  pageCreateEnvironmentSubmit = 'page.createEnvironment.submit',
  pageCreateEnvironmentValidationInvalidSubdomain = 'page.createEnvironment.validation.invalidSubdomain',
  pageCreateEnvironmentValidationSubdomainNotAvailable = 'page.createEnvironment.validation.subdomainNotAvailable',

  pageSigningUpEnvironmentTitle = 'page.signingUpEnvironment.title',
  pageSigningUpEnvironmentSubmit = 'page.signingUpEnvironment.submit',

  pageSettingUpEnvironmentTitle = 'page.settingUpEnvironment.title',
  pageSettingUpEnvironmentSteps = 'page.settingUpEnvironment.steps',

  pageStartEnvironmentTitle = 'page.startEnvironment.title',
  pageStartEnvironmentDescription = 'page.startEnvironment.description',
  pageStartEnvironmentSubmit = 'page.startEnvironment.submit',

  pageOfficeNotAllowedToRequestConnectionsBetweenOthers = 'page.office.notAllowedToRequestConnectionsBetweenOthers',
  pageOfficeNotAllowedToRequestConnectionsWithInactiveMobileUser = 'page.office.notAllowedToRequestConnectionsWithInactiveMobileUser',
  pageOfficeNotAllowedToMoveOthers = 'page.office.pageOfficeNotAllowedToMoveOthers',
  pageOfficeAddRoom = 'page.office.addRoom',
  pageOfficeRoomName = 'page.office.roomName',
  pageOfficeEnableEditMode = 'page.office.enableEditMode',
  pageOfficeDisableEditMode = 'page.office.disableEditMode',
  pageOfficeInviteUsers = 'page.office.inviteUsers',
  pageOfficeInviteUsersPrompt = 'page.office.inviteUsersPrompt',
  pageOfficeStartNewMeeting = 'page.office.startNewMeeting',
  pageOfficeShareMeetingLink = 'page.office.shareMeetingLink',
  pageOfficeChangeMood = 'page.office.changeMood',
  pageOfficeTurnOffRoomSoundAndStartMeetingConfirmation = 'page.office.turnOffRoomSoundAndStartMeetingConfirmation',

  pageMeetingHeaderMessage = 'page.meeting.headerMessage',
  pageMeetingEnterMeeting = 'page.meeting.enterMeeting',

  componentGoogleSignInButton = 'component.googleSignInButton.text',

  componentAvatarOpenChatConversation = 'component.avatar.openChatConversation',
  componentAvatarInviteToMeeting = 'component.avatar.inviteToMeeting',
  componentAvatarRequestToJoinTheMeeting = 'component.avatar.requestToJoinTheMeeting',
  componentAvatarJoinTheMeeting = 'component.avatar.joinTheMeeting',

  componentMeetingLinkSharingTitle = 'component.meetingLinkSharing.title',
  componentMeetingLinkSharingGenerate = 'component.meetingLinkSharing.generate',
  componentMeetingLinkSharingCopy = 'component.meetingLinkSharing.copy',
  componentMeetingLinkSharingCopied = 'component.meetingLinkSharing.copied',

  componentThemeSwitcherToLight = 'component.themeSwitcher.toLightTooltip',
  componentThemeSwitcherToDark = 'component.themeSwitcher.toDarkTooltip',

  componentRoomExpand = 'component.room.expand',
  componentRoomRetract = 'component.room.retract',
  componentRoomDelete = 'component.room.delete',
  componentRoomConfirmDeletion = 'component.room.confirmDeletion',
  componentRoomEnter = 'component.room.enter',
  componentRoomTurnOnSound = 'component.room.turnOnSound',
  componentRoomTurnOffSound = 'component.room.turnOffSound',

  componentProfileDefineYourRole = 'component.profile.defineYourRole',
  componentProfileAt = 'component.profile.at',
  componentProfileSignOut = 'component.profile.signOut',
  componentProfileEditingNotAllowed = 'component.profile.editingNotAllowed',

  componentWelcomeTourStartTitle = 'component.welcomeTour.startTitle',
  componentWelcomeTourStartDescription = 'component.welcomeTour.startDescription',
  componentWelcomeTourUserInfoTitle = 'component.welcomeTour.userInfoTitle',
  componentWelcomeTourUserInfoDescription = 'component.welcomeTour.userInfoDescription',
  componentWelcomeTourAvatarPictureTitle = 'component.welcomeTour.avatarPictureTitle',
  componentWelcomeTourAvatarPictureDescription = 'component.welcomeTour.avatarPictureDescription',
  componentWelcomeTourAvatarDraggableTitle = 'component.welcomeTour.avatarDraggableTitle',
  componentWelcomeTourAvatarDraggableDescription = 'component.welcomeTour.avatarDraggableDescription',
  componentWelcomeTourRoleTitle = 'component.welcomeTour.roleTitle',
  componentWelcomeTourRoleDescription = 'component.welcomeTour.roleDescription',
  componentWelcomeTourProfileTitle = 'component.welcomeTour.profileTitle',
  componentWelcomeTourProfileDescription = 'component.welcomeTour.profileDescription',
  componentWelcomeTourRemoteTitle = 'component.welcomeTour.remoteTitle',
  componentWelcomeTourRemoteDescription = 'component.welcomeTour.remoteDescription',
  componentWelcomeTourUserStatusTitle = 'component.welcomeTour.userStatusTitle',
  componentWelcomeTourUserStatusDescription = 'component.welcomeTour.userStatusDescription',
  componentWelcomeTourLanguagesTitle = 'component.welcomeTour.languagesTitle',
  componentWelcomeTourLanguagesDescription = 'component.welcomeTour.languagesDescription',
  componentWelcomeTourMoodsTitle = 'component.welcomeTour.moodsTitle',
  componentWelcomeTourMoodsDescription = 'component.welcomeTour.moodsDescription',
  componentWelcomeTourNotificationsTitle = 'component.welcomeTour.notificationsTitle',
  componentWelcomeTourNotificationsDescription = 'component.welcomeTour.notificationsDescription',
  componentWelcomeTourThemeSwitcherTitle = 'component.welcomeTour.themeSwitcherTitle',
  componentWelcomeTourThemeSwitcherDescription = 'component.welcomeTour.themeSwitcherDescription',
  componentWelcomeTourInviteOthersTitle = 'component.welcomeTour.inviteOthersTitle',
  componentWelcomeTourInviteOthersDescription = 'component.welcomeTour.inviteOthersDescription',
  componentWelcomeTourEditModeTitle = 'component.welcomeTour.editModeTitle',
  componentWelcomeTourEditModeDescription = 'component.welcomeTour.editModeDescription',
  componentWelcomeTourRoomsTitle = 'component.welcomeTour.roomsTitle',
  componentWelcomeTourRoomsDescription = 'component.welcomeTour.roomsDescription',
  componentWelcomeTourDoneTitle = 'component.welcomeTour.doneTitle',
  componentWelcomeTourDoneDescription = 'component.welcomeTour.doneDescription',
  componentWelcomeTourDone = 'component.welcomeTour.done',

  componentStyledMultiSelectSelected = 'component.styledMultiSelect.selected',
  componentStyledMultiSelectAll = 'component.styledMultiSelect.all',

  componentUserMoodTitle = 'component.userMood.title',
  componentUserMoodComment = 'component.userMood.comment',
  componentUserMoodConfirm = 'component.userMood.confirm',

  componentUserManagementTitle = 'component.userManagement.title',
  componentUserManagementInviteNewUsers = 'component.userManagement.inviteNewUsers',
  componentUserManagementUsersCount = 'component.userManagement.usersCount',
  componentUserManagementCanAccess = 'component.userManagement.canAccess',
  componentUserManagementRevokeAccess = 'component.userManagement.revokeAccess',
  componentUserManagementGrantAccess = 'component.userManagement.grantAccess',
  componentUserManagementAddAsAdministrator = 'component.userManagement.addAsAdministrator',
  componentUserManagementRemoveFromAdministrators = 'component.userManagement.removeFromAdministrators',
  componentUserManagementDeleteUser = 'component.userManagement.deleteUser',
  componentUserManagementResendInvitationEmail = 'component.userManagement.resendInvitationEmail',
  componentUserManagementAdministrator = 'component.userManagement.administrator',
  componentUserManagementAccessRevoked = 'component.userManagement.accesRevoked',
  componentUserManagementNoLogin = 'component.userManagement.noLogin',
  componentUserManagementUseLocation = 'component.userManagement.useLocation',
  componentUserManagementUseLocationDescription = 'component.userManagement.useLocationDescription',
  componentUserManagementMakeLocationRequired = 'component.userManagement.makeLocationRequired',
  componentUserManagementMakeLocationOptional = 'component.userManagement.makeLocationOptional',
  componentUserManagementAllowProfileEditing = 'component.userManagement.allowProfileEditing',
  componentUserManagementAllowProfileEditingDescription = 'component.userManagement.allowProfileEditingDescription',
  componentUserManagementUseExtension = 'component.userManagement.useExtension',
  componentUserManagementUseExtensionDescription = 'component.userManagement.useExtensionDescription',
  componentUserManagementMakeExtensionRequired = 'component.userManagement.makeExtensionRequired',
  componentUserManagementMakeExtensionOptional = 'component.userManagement.makeExtensionOptional',
  componentUserManagementUnnamed = 'component.userManagement.unnamed',
  componentUserManagementNoRole = 'component.userManagement.noRole',

  userInfoRemote = 'userInfo.remote',
  userInfoStatusAvailable = 'userInfo.status.available',
  userInfoStatusAway = 'userInfo.status.away',
  userInfoStatusBusy = 'userInfo.status.busy',
  userInfoStatusInactive = 'userInfo.status.inactive',

  inputSearchPlaceholder = 'inputSearch.placeholder',

  chatDefaultChannelName = 'chat.defaultChannelName',
  chatChannels = 'chat.channels',
  chatContacts = 'chat.contacts',
  chatTyping = 'chat.typing',
  chatLoading = 'chat.loading',
  chatNewMessages = 'chat.newMessages',
  chatLoadPrevious = 'chat.loadPrevious',
  chatLoadNew = 'chat.loadNew',
  chatSendMessage = 'chat.sendMessage',
  chatExcludeFile = 'chat.excludeFile',
  chatNewMessageFrom = 'chat.newMessageFrom',
  chatPublicChannel = 'chat.publicChannel',
  chatPrivateChannel = 'chat.privateChannel',
  chatNewChannel = 'chat.newChannel',
  chatCreateChannel = 'chat.createChannel',
  chatChannelName = 'chat.channelName',
  chatJoinChannel = 'chat.joinChannel',
  chatLeaveChannel = 'chat.leaveChannel',
  chatLeaveChannelConfirm = 'chat.leaveChannel.confirm',
  chatRemoveFromChannel = 'chat.removeFromChannel',
  chatRemoveFromChannelConfirm = 'chat.removeFromChannel.confirm',
  chatRenameChannel = 'chat.renameChannel',
  chatRenameChannelNewName = 'chat.renameChannel.newName',
  chatMakeChannelPublic = 'chat.makeChannelPublic',
  chatMakeChannelPublicConfirm = 'chat.makeChannelPublic.confirm',
  chatMakeChannelPrivate = 'chat.makeChannelPrivate',
  chatMakeChannelPrivateConfirm = 'chat.makeChannelPrivate.confirm',
  chatDeleteChannel = 'chat.deleteChannel',
  chatDeleteChannelConfirm = 'chat.deleteChannel.confirm',
  chatChannelMembers = 'chat.channelMembers',
  chatChannelMember = 'chat.channelMember',
  chatAddToChannel = 'chat.addToChannel',
  chatInputPlaceholder = 'chat.input.placeholder',
  chatPostConfirmDeletion = 'chat.post.confirmDeletion',
  chatPostDelete = 'chat.post.delete',
  chatPostAddReaction = 'chat.post.addReaction',
  chatPostReplyTo = 'chat.post.replyTo',
  chatSendingAudio = 'chat.sendingAudio',

  officePostsGeneral = 'officePosts.general',
  officePostsNothingPosted = 'officePosts.nothingPosted',
  officePostConfirmDeletion = 'officePost.confirmDeletion',
  officePostDelete = 'officePost.delete',
  officePostAddReaction = 'officePost.addReaction',
  officePostPin = 'officePost.pin',
  officePostUnpin = 'officePost.unpin',
  officePostPublish = 'officePost.publish',
  officePostInputTitle = 'officePost.input.title',
  officePostInputAddToPost = 'officePost.input.addToPost',
  officePostInputPlaceholder = 'officePost.input.placeholder',
  officePostCommentInputPlaceholder = 'officePost.comments.input.placeholder',
  officePostCommentsCountTitle = 'officePost.comments.countTitle',
  officePostCommentsShowAllButton = 'officePost.comments.showAllButton',
  officePostCommentsHideButton = 'officePost.comments.hideButton',
  officePostCommentsNewCount = 'officePost.comments.newCount',

  attention = 'Attention',
  notAllowed = 'notAllowed',
  noSupport = 'noSupport',
  permissionDenied = 'permissionDenied',

  notificationsNoSupport = 'notifications.noSupport',
  notificationsPermissionRequired = 'notifications.permissionRequired',
  notificationsEnable = 'notifications.enable',
  notificationsDisable = 'notifications.disable',

  userMediaNoSupport = 'userMedia.noSupport',
  userMediaMicrophonePermissionRequired = 'userMedia.microphone.permissionRequired',
  userMediaMicrophoneEnable = 'userMedia.microphone.enable',
  userMediaMicrophoneDisable = 'userMedia.microphone.disable',
  userMediaCameraPermissionRequired = 'userMedia.camera.permissionRequired',
  userMediaCameraEnable = 'userMedia.camera.enable',
  userMediaCameraDisable = 'userMedia.camera.disable',
  userMediaScreenSharingEnable = 'userMedia.screenSharing.enable',
  userMediaScreenSharingDisable = 'userMedia.screenSharing.disable',

  meetingLeave = 'meeting.leave',
  meetingShow = 'meeting.show',
  meetingHide = 'meeting.hide',
  meetingConfirmLeave = 'meeting.confirmLeave',

  inviteSended = 'invite.sended',
  inviteReceived = 'invite.received',
  inviteRejected = 'invite.rejected',
  inviteCanceled = 'invite.canceled',
  inviteWantsToConnect = 'invite.wantsToConnect',
  inviteRejectedYourInvitation = 'invite.rejectedYourInvitation',
  inviteCanceledTheInvitation = 'invite.canceledTheInvitation',

  periodsField = 'periods.field',
  periodsToday = 'periods.today',
  periodsYesterday = 'periods.yesterday',
  periodsThisWeek = 'periods.thisWeek',
  periodsLastWeek = 'periods.lastWeek',
  periodsThisMonth = 'periods.thisMonth',
  periodsLastMonth = 'periods.lastMonth',

  booleanStringYes = 'booleanString.yes',
  booleanStringNo = 'booleanString.no',

  reportsTitle = 'reports.title',
  reportsNoData = 'reports.noData',

  reportsModelField = 'reports.model.Field',
  reportsModelStatusPerUser = 'reports.model.StatusPerUser',
  reportsModelStatusPerRoomUser = 'reports.model.StatusPerRoomUser',
  reportsModelInMeetingPerUser = 'reports.model.InMeetingPerUser',
  reportsModelMeetingsPerDayRoomUser = 'reports.model.MeetingsPerDayRoomUser',
  reportsModelAnalyticLogs = 'reports.model.AnalyticLogs',

  reportsFieldLogId = 'reports.field.logId',
  reportsFieldUserFullName = 'reports.field.userFullName',
  reportsFieldUserEmail = 'reports.field.userEmail',
  reportsFieldOfficeName = 'reports.field.officeName',
  reportsFieldRoomName = 'reports.field.roomName',
  reportsFieldIsRemote = 'reports.field.isRemote',
  reportsFieldStatus = 'reports.field.status',
  reportsFieldStatusMessage = 'reports.field.statusMessage',
  reportsFieldInMeeting = 'reports.field.inMeeting',
  reportsFieldMeetingId = 'reports.field.meetingId',
  reportsFieldMeetingStart = 'reports.field.meetingStart',
  reportsFieldMeetingEnd = 'reports.field.meetingEnd',
  reportsFieldMeetingTotalTimeInSeconds = 'reports.field.meetingTotalTimeInSeconds',
  reportsFieldMeetingTotalTimeInMinutes = 'reports.field.meetingTotalTimeInMinutes',
  reportsFieldMeetingTotalTimeInHours = 'reports.field.meetingTotalTimeInHours',
  reportsFieldMicrophoneEnabled = 'reports.field.microphoneEnabled',
  reportsFieldCameraEnabled = 'reports.field.cameraEnabled',
  reportsFieldScreenSharingEnabled = 'reports.field.screenSharingEnabled',
  reportsFieldLogCreatedAt = 'reports.field.logCreatedAt',
  reportsFieldLogFinishedAt = 'reports.field.logFinishedAt',
  reportsFieldLogDate = 'reports.field.logDate',
  reportsFieldLogYear = 'reports.field.logYear',
  reportsFieldLogMonth = 'reports.field.logMonth',
  reportsFieldLogDay = 'reports.field.logDay',
  reportsFieldTimeInSeconds = 'reports.field.timeInSeconds',
  reportsFieldTimeInMinutes = 'reports.field.timeInMinutes',
  reportsFieldTimeInHours = 'reports.field.timeInHours',

  limitsActiveUsersReached = 'limits.activeUsersReached',
  limitsActiveUsers = 'limits.activeUsers',
  limitsActivityReportsNotAllowed = 'limits.activityReportsNotAllowed',
  limitsExternalMeetingsNotAllowed = 'limits.externalMeetingsNotAllowed',
  limitsCannotJoinMeeting = 'limits.cannotJoinMeeting',
  limitsUsersPerMeeting = 'limits.usersPerMeeting',
  limitsAudioHoursReached = 'limits.audioHoursReached',
  limitsVideoHoursReached = 'limits.videoHoursReached',
  limitsScreenSharingHoursReached = 'limits.screenSharingHoursReached',
  limitsChatFileTooLarge = 'limits.chatFileTooLarge',
  limitsChatMaxFileSize = 'limits.chatMaxFileSize',

  chromeExtensionNeededMessage = 'chromeExtensionNeededMessage',

  smallScreenDetectedMessage = 'smallScreenDetectedMessage',

  copyrightText = 'copyrightText',

  privacyPolicy = 'privacyPolicy',

  updateVersion = 'updateVersion',

  stepOf = 'stepOf',

  tutorialEnterRoomTitle = 'tutorial.enterRoom.title',
  tutorialEnterRoomDescription1 = 'tutorial.enterRoom.description1',
  tutorialEnterRoomDescription2 = 'tutorial.enterRoom.description2',

  tutorialAddUsersDescription1 = 'tutorial.addUsers.description1',

  tutorialInviteToMeetingTitle = 'tutorial.inviteToMeeting.title',
  tutorialInviteToMeetingDescription1 = 'tutorial.inviteToMeeting.description1',
  tutorialInviteToMeetingDescription2 = 'tutorial.inviteToMeeting.description2',

  tutorialContextMenuTitle = 'tutorial.contextMenu.title',
  tutorialContextMenuDescription1 = 'tutorial.contextMenu.description1',

  tutorialMeetingVisibilityTitle = 'tutorial.meetingVisibility.title',
  tutorialMeetingVisibilityDescription1 = 'tutorial.meetingVisibility.description1',
  tutorialMeetingVisibilityDescription2 = 'tutorial.meetingVisibility.description2',

  tutorialGotIt = 'tutorial.gotIt',

  incompatibleBrowserTitle = 'incompatibleBrowser.title',
  incompatibleBrowserDescription = 'incompatibleBrowser.description',

  extensionRequiredTitle = 'extensionRequired.title',
  extensionRequiredDescription = 'extensionRequired.description',
  extensionRequiredButtonText = 'extensionRequired.buttonText',
}

export interface LanguageData extends Record<string, string> {
  [AppMessages.pageSignInWelcome]: string;
  [AppMessages.pageSignInEmail]: string;
  [AppMessages.pageSignInPassword]: string;
  [AppMessages.pageSignInConfirmPassword]: string;
  [AppMessages.pageSignInFirstName]: string;
  [AppMessages.pageSignInLastName]: string;
  [AppMessages.pageSignInRoleDescription]: string;
  [AppMessages.pageSignInPhoneNumber]: string;
  [AppMessages.pageSignInCompany]: string;
  [AppMessages.pageSignInSubmit]: string;
  [AppMessages.pageSignInForgotPassword]: string;
  [AppMessages.pageSignInForgotPasswordConfirm]: string;
  [AppMessages.pageSignInForgotPasswordLinkSended]: string;
  [AppMessages.pageSignInValidationRequired]: string;
  [AppMessages.pageSignInValidationInvalidEmail]: string;
  [AppMessages.pageSignInValidationWeakPassword]: string;
  [AppMessages.pageSignInValidationDoesNotMatch]: string;
  [AppMessages.pageSignInValidationInvalidPhoneNumber]: string;
  [AppMessages.pageSignInValidationInvalidCredentials]: string;
  [AppMessages.pageSignInValidationIncompleteRegistration]: string;
  [AppMessages.pageSignInKeepConnected]: string;

  [AppMessages.pageCreateEnvironmentWelcome]: string;
  [AppMessages.pageCreateEnvironmentOrganization]: string;
  [AppMessages.pageCreateEnvironmentEmail]: string;
  [AppMessages.pageCreateEnvironmentSubdomain]: string;
  [AppMessages.pageCreateEnvironmentSubdomainPlaceholder]: string;
  [AppMessages.pageCreateEnvironmentSubmit]: string;
  [AppMessages.pageCreateEnvironmentValidationInvalidSubdomain]: string;
  [AppMessages.pageCreateEnvironmentValidationSubdomainNotAvailable]: string;

  [AppMessages.pageSigningUpEnvironmentTitle]: string;
  [AppMessages.pageSigningUpEnvironmentSubmit]: string;

  [AppMessages.pageSettingUpEnvironmentTitle]: string;
  [AppMessages.pageSettingUpEnvironmentSteps]: string;

  [AppMessages.pageStartEnvironmentTitle]: string;
  [AppMessages.pageStartEnvironmentDescription]: string;
  [AppMessages.pageStartEnvironmentSubmit]: string;

  [AppMessages.pageOfficeNotAllowedToRequestConnectionsBetweenOthers]: string;
  [AppMessages.pageOfficeNotAllowedToRequestConnectionsWithInactiveMobileUser]: string;
  [AppMessages.pageOfficeNotAllowedToMoveOthers]: string;
  [AppMessages.pageOfficeEnableEditMode]: string;
  [AppMessages.pageOfficeDisableEditMode]: string;
  [AppMessages.pageOfficeAddRoom]: string;
  [AppMessages.pageOfficeRoomName]: string;
  [AppMessages.pageOfficeInviteUsers]: string;
  [AppMessages.pageOfficeInviteUsersPrompt]: string;
  [AppMessages.pageOfficeStartNewMeeting]: string;
  [AppMessages.pageOfficeShareMeetingLink]: string;
  [AppMessages.pageOfficeChangeMood]: string;
  [AppMessages.pageOfficeTurnOffRoomSoundAndStartMeetingConfirmation]: string;

  [AppMessages.pageMeetingHeaderMessage]: string;
  [AppMessages.pageMeetingEnterMeeting]: string;

  [AppMessages.componentGoogleSignInButton]: string;

  [AppMessages.componentAvatarOpenChatConversation]: string;
  [AppMessages.componentAvatarInviteToMeeting]: string;
  [AppMessages.componentAvatarRequestToJoinTheMeeting]: string;
  [AppMessages.componentAvatarJoinTheMeeting]: string;

  [AppMessages.componentMeetingLinkSharingTitle]: string;
  [AppMessages.componentMeetingLinkSharingGenerate]: string;
  [AppMessages.componentMeetingLinkSharingCopy]: string;
  [AppMessages.componentMeetingLinkSharingCopied]: string;

  [AppMessages.componentThemeSwitcherToDark]: string;
  [AppMessages.componentThemeSwitcherToLight]: string;

  [AppMessages.componentRoomExpand]: string;
  [AppMessages.componentRoomRetract]: string;
  [AppMessages.componentRoomDelete]: string;
  [AppMessages.componentRoomConfirmDeletion]: string;
  [AppMessages.componentRoomEnter]: string;
  [AppMessages.componentRoomTurnOnSound]: string;
  [AppMessages.componentRoomTurnOffSound]: string;

  [AppMessages.componentProfileDefineYourRole]: string;
  [AppMessages.componentProfileAt]: string;
  [AppMessages.componentProfileSignOut]: string;
  [AppMessages.componentProfileEditingNotAllowed]: string;

  [AppMessages.componentWelcomeTourStartTitle]: string;
  [AppMessages.componentWelcomeTourStartDescription]: string;
  [AppMessages.componentWelcomeTourUserInfoTitle]: string;
  [AppMessages.componentWelcomeTourUserInfoDescription]: string;
  [AppMessages.componentWelcomeTourAvatarPictureTitle]: string;
  [AppMessages.componentWelcomeTourAvatarPictureDescription]: string;
  [AppMessages.componentWelcomeTourAvatarDraggableTitle]: string;
  [AppMessages.componentWelcomeTourAvatarDraggableDescription]: string;
  [AppMessages.componentWelcomeTourRoleTitle]: string;
  [AppMessages.componentWelcomeTourRoleDescription]: string;
  [AppMessages.componentWelcomeTourProfileTitle]: string;
  [AppMessages.componentWelcomeTourProfileDescription]: string;
  [AppMessages.componentWelcomeTourRemoteTitle]: string;
  [AppMessages.componentWelcomeTourRemoteDescription]: string;
  [AppMessages.componentWelcomeTourUserStatusTitle]: string;
  [AppMessages.componentWelcomeTourUserStatusDescription]: string;
  [AppMessages.componentWelcomeTourLanguagesTitle]: string;
  [AppMessages.componentWelcomeTourLanguagesDescription]: string;
  [AppMessages.componentWelcomeTourMoodsTitle]: string;
  [AppMessages.componentWelcomeTourMoodsDescription]: string;
  [AppMessages.componentWelcomeTourNotificationsTitle]: string;
  [AppMessages.componentWelcomeTourNotificationsDescription]: string;
  [AppMessages.componentWelcomeTourThemeSwitcherTitle]: string;
  [AppMessages.componentWelcomeTourThemeSwitcherDescription]: string;
  [AppMessages.componentWelcomeTourInviteOthersTitle]: string;
  [AppMessages.componentWelcomeTourInviteOthersDescription]: string;
  [AppMessages.componentWelcomeTourEditModeTitle]: string;
  [AppMessages.componentWelcomeTourEditModeDescription]: string;
  [AppMessages.componentWelcomeTourRoomsTitle]: string;
  [AppMessages.componentWelcomeTourRoomsDescription]: string;
  [AppMessages.componentWelcomeTourDoneTitle]: string;
  [AppMessages.componentWelcomeTourDoneDescription]: string;
  [AppMessages.componentWelcomeTourDone]: string;

  [AppMessages.componentStyledMultiSelectSelected]: string;
  [AppMessages.componentStyledMultiSelectAll]: string;

  [AppMessages.componentUserMoodTitle]: string;
  [AppMessages.componentUserMoodComment]: string;
  [AppMessages.componentUserMoodConfirm]: string;

  [AppMessages.componentUserManagementTitle]: string;
  [AppMessages.componentUserManagementInviteNewUsers]: string;
  [AppMessages.componentUserManagementUsersCount]: string;
  [AppMessages.componentUserManagementCanAccess]: string;
  [AppMessages.componentUserManagementRevokeAccess]: string;
  [AppMessages.componentUserManagementGrantAccess]: string;
  [AppMessages.componentUserManagementAddAsAdministrator]: string;
  [AppMessages.componentUserManagementRemoveFromAdministrators]: string;
  [AppMessages.componentUserManagementDeleteUser]: string;
  [AppMessages.componentUserManagementResendInvitationEmail]: string;
  [AppMessages.componentUserManagementAdministrator]: string;
  [AppMessages.componentUserManagementAccessRevoked]: string;
  [AppMessages.componentUserManagementNoLogin]: string;
  [AppMessages.componentUserManagementUseLocation]: string;
  [AppMessages.componentUserManagementUseLocationDescription]: string;
  [AppMessages.componentUserManagementMakeLocationRequired]: string;
  [AppMessages.componentUserManagementMakeLocationOptional]: string;
  [AppMessages.componentUserManagementAllowProfileEditing]: string;
  [AppMessages.componentUserManagementAllowProfileEditingDescription]: string;
  [AppMessages.componentUserManagementUseExtension]: string;
  [AppMessages.componentUserManagementUseExtensionDescription]: string;
  [AppMessages.componentUserManagementMakeExtensionRequired]: string;
  [AppMessages.componentUserManagementMakeExtensionOptional]: string;
  [AppMessages.componentUserManagementUnnamed]: string;
  [AppMessages.componentUserManagementNoRole]: string;

  [AppMessages.userInfoRemote]: string;
  [AppMessages.userInfoStatusAvailable]: string;
  [AppMessages.userInfoStatusAway]: string;
  [AppMessages.userInfoStatusBusy]: string;
  [AppMessages.userInfoStatusInactive]: string;

  [AppMessages.inputSearchPlaceholder]: string;

  [AppMessages.chatDefaultChannelName]: string;
  [AppMessages.chatChannels]: string;
  [AppMessages.chatContacts]: string;
  [AppMessages.chatTyping]: string;
  [AppMessages.chatLoading]: string;
  [AppMessages.chatNewMessages]: string;
  [AppMessages.chatLoadPrevious]: string;
  [AppMessages.chatLoadNew]: string;
  [AppMessages.chatSendMessage]: string;
  [AppMessages.chatExcludeFile]: string;
  [AppMessages.chatNewMessageFrom]: string;
  [AppMessages.chatPublicChannel]: string;
  [AppMessages.chatPrivateChannel]: string;
  [AppMessages.chatNewChannel]: string;
  [AppMessages.chatCreateChannel]: string;
  [AppMessages.chatChannelName]: string;
  [AppMessages.chatJoinChannel]: string;
  [AppMessages.chatLeaveChannel]: string;
  [AppMessages.chatLeaveChannelConfirm]: string;
  [AppMessages.chatRemoveFromChannel]: string;
  [AppMessages.chatRemoveFromChannelConfirm]: string;
  [AppMessages.chatRenameChannel]: string;
  [AppMessages.chatRenameChannelNewName]: string;
  [AppMessages.chatMakeChannelPublic]: string;
  [AppMessages.chatMakeChannelPublicConfirm]: string;
  [AppMessages.chatMakeChannelPrivate]: string;
  [AppMessages.chatMakeChannelPrivateConfirm]: string;
  [AppMessages.chatDeleteChannel]: string;
  [AppMessages.chatDeleteChannelConfirm]: string;
  [AppMessages.chatChannelMembers]: string;
  [AppMessages.chatChannelMember]: string;
  [AppMessages.chatAddToChannel]: string;
  [AppMessages.chatInputPlaceholder]: string;
  [AppMessages.chatPostConfirmDeletion]: string;
  [AppMessages.chatPostDelete]: string;
  [AppMessages.chatPostAddReaction]: string;
  [AppMessages.chatPostReplyTo]: string;
  [AppMessages.chatSendingAudio]: string;

  [AppMessages.officePostsGeneral]: string;
  [AppMessages.officePostsNothingPosted]: string;
  [AppMessages.officePostConfirmDeletion]: string;
  [AppMessages.officePostDelete]: string;
  [AppMessages.officePostAddReaction]: string;
  [AppMessages.officePostPin]: string;
  [AppMessages.officePostUnpin]: string;
  [AppMessages.officePostPublish]: string;
  [AppMessages.officePostInputTitle]: string;
  [AppMessages.officePostInputAddToPost]: string;
  [AppMessages.officePostInputPlaceholder]: string;
  [AppMessages.officePostCommentInputPlaceholder]: string;
  [AppMessages.officePostCommentsCountTitle]: string;
  [AppMessages.officePostCommentsShowAllButton]: string;
  [AppMessages.officePostCommentsHideButton]: string;
  [AppMessages.officePostCommentsNewCount]: string;

  [AppMessages.attention]: string;
  [AppMessages.notAllowed]: string;
  [AppMessages.noSupport]: string;
  [AppMessages.permissionDenied]: string;

  [AppMessages.notificationsNoSupport]: string;
  [AppMessages.notificationsPermissionRequired]: string;
  [AppMessages.notificationsEnable]: string;
  [AppMessages.notificationsDisable]: string;

  [AppMessages.userMediaNoSupport]: string;
  [AppMessages.userMediaMicrophonePermissionRequired]: string;
  [AppMessages.userMediaMicrophoneEnable]: string;
  [AppMessages.userMediaMicrophoneDisable]: string;
  [AppMessages.userMediaCameraPermissionRequired]: string;
  [AppMessages.userMediaCameraEnable]: string;
  [AppMessages.userMediaCameraDisable]: string;
  [AppMessages.userMediaScreenSharingEnable]: string;
  [AppMessages.userMediaScreenSharingDisable]: string;

  [AppMessages.meetingLeave]: string;
  [AppMessages.meetingShow]: string;
  [AppMessages.meetingHide]: string;
  [AppMessages.meetingConfirmLeave]: string;

  [AppMessages.inviteSended]: string;
  [AppMessages.inviteReceived]: string;
  [AppMessages.inviteRejected]: string;
  [AppMessages.inviteCanceled]: string;
  [AppMessages.inviteWantsToConnect]: string;
  [AppMessages.inviteRejectedYourInvitation]: string;
  [AppMessages.inviteCanceledTheInvitation]: string;

  [AppMessages.periodsField]: string;
  [AppMessages.periodsToday]: string;
  [AppMessages.periodsYesterday]: string;
  [AppMessages.periodsThisWeek]: string;
  [AppMessages.periodsLastWeek]: string;
  [AppMessages.periodsThisMonth]: string;
  [AppMessages.periodsLastMonth]: string;

  [AppMessages.booleanStringYes]: string;
  [AppMessages.booleanStringNo]: string;

  [AppMessages.reportsTitle]: string;
  [AppMessages.reportsNoData]: string;

  [AppMessages.reportsModelField]: string;
  [AppMessages.reportsModelStatusPerUser]: string;
  [AppMessages.reportsModelStatusPerRoomUser]: string;
  [AppMessages.reportsModelInMeetingPerUser]: string;
  [AppMessages.reportsModelMeetingsPerDayRoomUser]: string;
  [AppMessages.reportsModelAnalyticLogs]: string;

  [AppMessages.reportsFieldLogId]: string;
  [AppMessages.reportsFieldUserFullName]: string;
  [AppMessages.reportsFieldUserEmail]: string;
  [AppMessages.reportsFieldOfficeName]: string;
  [AppMessages.reportsFieldRoomName]: string;
  [AppMessages.reportsFieldIsRemote]: string;
  [AppMessages.reportsFieldStatus]: string;
  [AppMessages.reportsFieldStatusMessage]: string;
  [AppMessages.reportsFieldInMeeting]: string;
  [AppMessages.reportsFieldMeetingId]: string;
  [AppMessages.reportsFieldMeetingStart]: string;
  [AppMessages.reportsFieldMeetingEnd]: string;
  [AppMessages.reportsFieldMeetingTotalTimeInSeconds]: string;
  [AppMessages.reportsFieldMeetingTotalTimeInMinutes]: string;
  [AppMessages.reportsFieldMeetingTotalTimeInHours]: string;
  [AppMessages.reportsFieldMicrophoneEnabled]: string;
  [AppMessages.reportsFieldCameraEnabled]: string;
  [AppMessages.reportsFieldScreenSharingEnabled]: string;
  [AppMessages.reportsFieldLogCreatedAt]: string;
  [AppMessages.reportsFieldLogFinishedAt]: string;
  [AppMessages.reportsFieldLogDate]: string;
  [AppMessages.reportsFieldLogYear]: string;
  [AppMessages.reportsFieldLogMonth]: string;
  [AppMessages.reportsFieldLogDay]: string;
  [AppMessages.reportsFieldTimeInSeconds]: string;
  [AppMessages.reportsFieldTimeInMinutes]: string;
  [AppMessages.reportsFieldTimeInHours]: string;

  [AppMessages.limitsActiveUsersReached]: string;
  [AppMessages.limitsActiveUsers]: string;
  [AppMessages.limitsActivityReportsNotAllowed]: string;
  [AppMessages.limitsExternalMeetingsNotAllowed]: string;
  [AppMessages.limitsCannotJoinMeeting]: string;
  [AppMessages.limitsUsersPerMeeting]: string;
  [AppMessages.limitsAudioHoursReached]: string;
  [AppMessages.limitsVideoHoursReached]: string;
  [AppMessages.limitsScreenSharingHoursReached]: string;
  [AppMessages.limitsChatFileTooLarge]: string;
  [AppMessages.limitsChatMaxFileSize]: string;

  [AppMessages.chromeExtensionNeededMessage]: string;

  [AppMessages.smallScreenDetectedMessage]: string;

  [AppMessages.copyrightText]: string;

  [AppMessages.privacyPolicy]: string;

  [AppMessages.updateVersion]: string;

  [AppMessages.stepOf]: string;

  [AppMessages.tutorialEnterRoomTitle]: string;
  [AppMessages.tutorialEnterRoomDescription1]: string;
  [AppMessages.tutorialEnterRoomDescription2]: string;

  [AppMessages.tutorialAddUsersDescription1]: string;

  [AppMessages.tutorialInviteToMeetingTitle]: string;
  [AppMessages.tutorialInviteToMeetingDescription1]: string;
  [AppMessages.tutorialInviteToMeetingDescription2]: string;

  [AppMessages.tutorialContextMenuTitle]: string;
  [AppMessages.tutorialContextMenuDescription1]: string;

  [AppMessages.tutorialMeetingVisibilityTitle]: string;
  [AppMessages.tutorialMeetingVisibilityDescription1]: string;
  [AppMessages.tutorialMeetingVisibilityDescription2]: string;

  [AppMessages.tutorialGotIt]: string;

  [AppMessages.incompatibleBrowserTitle]: string;
  [AppMessages.incompatibleBrowserDescription]: string;

  [AppMessages.extensionRequiredTitle]: string;
  [AppMessages.extensionRequiredDescription]: string;
  [AppMessages.extensionRequiredButtonText]: string;
}
