import React, { useEffect, useMemo /* useRef */ } from 'react';
import { Post } from 'mattermost-redux/types/posts';

import { CommentsContainer, CommentsMain } from './styles';

import { OfficePostComment } from './OfficePostComment';

interface Props {
  postId: string;
  comments: Post[];
  showComments: boolean;
  setShowComments: React.Dispatch<React.SetStateAction<boolean>>;
  scrollDown: boolean;
  setScrollDown: React.Dispatch<React.SetStateAction<boolean>>;
  postDivRef: React.RefObject<HTMLDivElement>;
}

export const OfficePostComments: React.FC<Props> = ({
  postId,
  comments,
  showComments,
  setShowComments,
  scrollDown,
  setScrollDown,
  postDivRef,
}) => {
  // const scrollRef = useRef<HTMLDivElement>(null);

  const commentsSorted = useMemo(() => {
    return !comments
      ? []
      : [...comments].sort((a, b) => a.create_at - b.create_at);
  }, [comments]);

  useEffect(() => {
    if (!showComments) return;

    const commentsMainElement = document.getElementById(
      `comments-main-${postId}`
    );

    if (!commentsMainElement) return;
    if (!postDivRef.current) return;

    commentsMainElement.scrollTop =
      commentsMainElement.scrollHeight - commentsMainElement.offsetHeight;

    postDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [showComments, postId, postDivRef]);

  useEffect(() => {
    if (!scrollDown) return;

    const commentsMainElement = document.getElementById(
      `comments-main-${postId}`
    );
    if (!commentsMainElement) return;
    if (!postDivRef.current) return;

    commentsMainElement.scrollTop =
      commentsMainElement.scrollHeight - commentsMainElement.offsetHeight;

    postDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });

    setScrollDown(false);
  }, [scrollDown, postId, setScrollDown, postDivRef]);

  return (
    <CommentsContainer>
      <CommentsMain id={`comments-main-${postId}`}>
        {showComments &&
          commentsSorted.map((comment) => (
            <OfficePostComment key={comment.id} comment={comment} />
          ))}
      </CommentsMain>
    </CommentsContainer>
  );
};
