import { shade } from 'polished';
import { keyframes } from 'styled-components';
import { styled, css } from '../../themes';

interface ContainerProps {
  isCurrent: boolean;
  statusColor?: string;
  expanded: boolean;
  top: number;
  left: number;
  width?: number;
  hidden?: boolean;
}

interface ContentProps {
  spacingInPixels: number;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  background: ${(props) => props.theme.boxColor};
  border: 2px solid transparent;
  color: ${(props) => props.theme.textColor};
  min-width: 249px;
  min-height: 107px;
  display: inline-flex;
  flex-direction: column;
  flex: auto;
  box-shadow: ${(props) => props.theme.boxGlow};
  border-radius: 5px;
  transition: all 0.2s ease-out, top 0.5s, left 0.5s, width 0.5s, height 0.5s;

  :hover {
    background: ${(props) => shade(0.1, props.theme.boxColor)};
    z-index: 1;
  }

  ${(props) =>
    props.isCurrent &&
    css`
      ::before {
        content: '';
        position: absolute;
        left: -2px;
        top: -2px;
        width: 100%;
        height: 100%;
        background: none;
        pointer-events: none;
        border: 2px solid ${props.statusColor};
        border-radius: 5px;
      }
    `}

  ${(props) =>
    props.expanded &&
    css`
      width: 100%;
    `}

  ${(props) =>
    !props.hidden &&
    css`
      top: ${props.top}px;
      left: ${props.left}px;
    `}

  ${(props) =>
    !props.hidden &&
    !!props.width &&
    css`
      width: ${props.width}px;
    `}

  &.drop-zone {
    background: ${(props) => shade(0.1, props.theme.boxColor)};
    box-shadow: ${(props) => props.theme.focusGlow};
  }
`;

export const TitleBar = styled.div`
  position: relative;
  padding: 6px 6px 0 6px;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  > .room-name {
    padding: 2px;
    border: 1px solid transparent;
  }
`;

const ANIMATION_STOP_DISPLACEMENT = '0deg';
const ANIMATION_MOVE_DISPLACEMENT = '20deg';

const animatedNotificationKeyframes = keyframes`
  0% { transform: rotate(${ANIMATION_STOP_DISPLACEMENT}); }
  1% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  2% { transform: rotate(-${ANIMATION_MOVE_DISPLACEMENT}); }
  3% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  4% { transform: rotate(-${ANIMATION_MOVE_DISPLACEMENT}); }
  5% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  6% { transform: rotate(-${ANIMATION_MOVE_DISPLACEMENT}); }
  7% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  8% { transform: rotate(${ANIMATION_STOP_DISPLACEMENT}); }

  10% { transform: rotate(${ANIMATION_STOP_DISPLACEMENT}); }
  11% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  12% { transform: rotate(-${ANIMATION_MOVE_DISPLACEMENT}); }
  13% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  14% { transform: rotate(-${ANIMATION_MOVE_DISPLACEMENT}); }
  15% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  16% { transform: rotate(-${ANIMATION_MOVE_DISPLACEMENT}); }
  17% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  18% { transform: rotate(${ANIMATION_STOP_DISPLACEMENT}); }

  100% { transform: translateY(${ANIMATION_STOP_DISPLACEMENT}); }
`;

export const ActionContainer = styled.div`
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin-left: 3px;
  }

  > .animated-notification {
    animation: ${animatedNotificationKeyframes} 10s ease-out infinite;

    > .notification-count {
      position: absolute;
      color: white;
      font-size: 12px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Content = styled.div<ContentProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  min-height: 62px;
  /* padding-bottom: ${(props) => props.spacingInPixels}px; */
  /* padding-right: 50px; */

  > .avatar {
    margin: ${(props) => props.spacingInPixels}px;
  }
`;

export const Footer = styled.div`
  position: relative;
  padding: 0 6px 6px 6px;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`;
