import { keyframes } from 'styled-components';
import { styled } from '../../themes';

interface ContainerProps {
  spacingInPixels: number;
  hidden?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: ${(props) => (props.hidden ? 'absolute' : 'relative')};
  display: flex;
  background: transparent;
  flex-wrap: wrap;
  width: calc(
    100% - ${(props) => (props.hidden ? props.spacingInPixels : 0)}px
  );
  opacity: ${(props) => (props.hidden ? 0 : 1)};

  animation: ${keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `} 0.2s linear;

  > .room {
    margin: ${(props) => (props.hidden ? props.spacingInPixels : 0)}px;
    position: ${(props) => (props.hidden ? 'relative' : 'absolute')};
  }
`;
