import { shade, lighten, opacify, saturate, transparentize } from 'polished';
import { keyframes } from 'styled-components';
import { styled, css, ProseiaTheme } from '../../themes';

import { ChatWindowStatus } from '../../hooks/mattermost';
import { HEADER_HEIGHT as APP_HEADER_HEIGHT } from '../../pages/Office/styles';

export const CHAT_CHANNEL_NORMAL_WIDTH = 330;
export const CHAT_CHANNEL_NORMAL_HEIGHT = 480;
export const SPACING = 10;
const FILE_LIST_HEIGHT = 70;
const HEADER_HEIGHT = 35;
const INPUT_HEIGHT = 61;
const TYPING_INFO_HEIGHT = 12;
const FIXED_HEIGHT = HEADER_HEIGHT + TYPING_INFO_HEIGHT;

interface ContainerProps {
  maximized: boolean;
}

interface ChatChannelBoxProps {
  boxStatus: ChatWindowStatus;
  hasUnreadMessages: boolean;
  inputScrollHeight?: number;
}

const getMaxInputHeight = () => INPUT_HEIGHT * 3;

const getInputHeight = (scrollHeight?: number) =>
  Math.max(Math.min(scrollHeight || 0, getMaxInputHeight()), INPUT_HEIGHT);

const getContainerHeight = (maximized: boolean) => {
  if (maximized) return `calc(100vh - ${APP_HEADER_HEIGHT + SPACING}px)`;
  return `${CHAT_CHANNEL_NORMAL_HEIGHT}px`;
};

const getBoxWidth = (boxStatus: ChatWindowStatus) => {
  if (boxStatus === 'maximized') return `calc(100% - ${SPACING}px)`;
  return `${CHAT_CHANNEL_NORMAL_WIDTH}px`;
};

const getBoxHeight = (boxStatus: ChatWindowStatus) => {
  if (boxStatus === 'maximized')
    return `calc(100vh - ${APP_HEADER_HEIGHT + SPACING * 2}px)`;
  if (boxStatus === 'minimized') return `${HEADER_HEIGHT}px`;
  return `${CHAT_CHANNEL_NORMAL_HEIGHT}px`;
};

export const Container = styled.div<ContainerProps>`
  position: fixed;
  right: 0;
  bottom: 0px;
  background: transparent;
  pointer-events: none;
  min-width: inherit;
  width: inherit;
  max-width: inherit;
  min-height: ${(props) => getContainerHeight(props.maximized)};
  max-height: ${(props) => getContainerHeight(props.maximized)};
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  * {
    font-size: 14px;
  }
`;

const getUnreadAnimation = (theme: ProseiaTheme) => css`
  animation: ${keyframes`
    0% { background: ${theme.textColor}; opacity: 1; }
    50% { background: ${shade(0.3, theme.textColor)}; pacity: 0.7; }
    100% { background: ${theme.textColor}; opacity: 1; }
  `} 1.5s ease-out infinite;
`;

export const ChatChannelBox = styled.div<ChatChannelBoxProps>`
  position: relative;
  margin: ${SPACING}px 0;
  pointer-events: all;
  bottom: 0px;
  background: ${(props) => props.theme.boxColor};
  box-shadow: ${(props) => props.theme.boxGlow};
  min-width: ${(props) => getBoxWidth(props.boxStatus)};
  max-width: ${(props) => getBoxWidth(props.boxStatus)};
  min-height: ${(props) => getBoxHeight(props.boxStatus)};
  max-height: 100vh;
  height: ${(props) => getBoxHeight(props.boxStatus)};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  :hover {
    z-index: 1;
  }

  & + & {
    margin-left: ${SPACING}px;
  }

  ${(props) =>
    props.boxStatus === 'minimized' &&
    css`
      > * {
        display: none !important;
      }

      > *.header {
        display: flex !important;
      }
    `}

  > .header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: ${HEADER_HEIGHT}px;
    max-height: ${HEADER_HEIGHT}px;
    padding: 0px 5px;
    background: ${(props) => props.theme.textColor};
    color: ${(props) => props.theme.background};
    font-weight: bold;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    ${(props) => props.hasUnreadMessages && getUnreadAnimation(props.theme)}

    > svg {
      font-size: 24px;
    }

    > .display-name {
      max-height: inherit;
      overflow-y: auto;
      margin-left: 5px;
      width: 100%;
    }
  }

  .postlist {
    position: relative;
    max-width: 100%;
    min-height: calc(
      100% -
        ${(props) => FIXED_HEIGHT + getInputHeight(props.inputScrollHeight)}px
    );
    max-height: calc(
      100% -
        ${(props) => FIXED_HEIGHT + getInputHeight(props.inputScrollHeight)}px
    );
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding: 0px 5px;

    > .post-divisor {
      min-height: 10px;
      max-height: 10px;
    }

    > .post-date-divisor {
      color: ${(props) => props.theme.textColor};
      width: 95%;
      text-align: center;
      /* border-bottom: 1px solid ${(props) => props.theme.textColor}; */
      line-height: 0.1em;
      margin: 10px 5px 20px;
      opacity: 0.6;

      > span {
        font-size: 12px;
        background: ${(props) => props.theme.boxColor};
        padding: 0px 10px;
      }
    }

    > .post-new-divisor {
      color: red;
      width: 95%;
      text-align: center;
      /* border-bottom: 1px solid red; */
      line-height: 0.1em;
      margin: 10px 5px 20px;
      opacity: 0.6;

      > span {
        font-size: 12px;
        background: ${(props) => props.theme.boxColor};
        padding: 0px 10px;
      }
    }
  }

  .search-box {
    position: absolute;
    background: ${(props) => props.theme.boxColor};
    color: ${(props) => props.theme.textColor};
    top: ${HEADER_HEIGHT}px;
    left: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: calc(
      100% -
        ${(props) => FIXED_HEIGHT + getInputHeight(props.inputScrollHeight)}px
    );
    max-height: calc(
      100% -
        ${(props) => FIXED_HEIGHT + getInputHeight(props.inputScrollHeight)}px
    );
    height: calc(
      100% -
        ${(props) => FIXED_HEIGHT + getInputHeight(props.inputScrollHeight)}px
    );
    padding: 2px;

    > .postlist.search-results {
      position: absolute;
      min-width: 100%;
      max-width: 100%;
      top: 40px;
      left: 0px;
      max-height: calc(100% - 40px);
      overflow-y: auto;
      overflow-x: hidden;

      .highlight {
        background: yellow;
        color: black;
      }
    }
  }

  .typing-info {
    font-size: 10px;
    padding: 0px 5px;
    min-height: ${TYPING_INFO_HEIGHT}px;
    max-height: ${TYPING_INFO_HEIGHT}px;
    pointer-events: all;
    opacity: 0.5;
  }

  .post.self {
    align-self: flex-end;
    border-right: 3px solid #CC207D;
    border-left: none;
  }

  .post {
    position: relative;
    max-width: 90%;
    min-width: 90%;
    align-self: flex-start;
    border-left: 3px solid #7E00C0;
    padding: 5px;
    box-shadow: 0 0 0 transparent;
    transition: box-shadow 0.5s;

    background: ${(props) => shade(0.1, props.theme.boxColor)};
    border-radius: 5px;

    > .post {
      max-width: 100%;
      border-left: 3px solid ${(props) => props.theme.mentionColor};
      background: ${(props) => lighten(0.1, props.theme.boxColor)};
      margin-bottom: 5px;

      :hover {
        background: ${(props) => lighten(0.1, props.theme.boxColor)};
      }

      .post-time {
        opacity: 0.5 !important;
        font-size: 10px !important;
        max-width: none !important;
      }

      * {
        font-size: 12px;
      }
    }

    + .post {
      margin-top: 2px;
    }

    :hover {
      background: ${(props) => shade(0.2, props.theme.boxColor)};

      .post-time {
        opacity: 0.5 !important;
      }

      .post-actions-container {
        opacity: 1 !important;
      }

      > button.jump {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    > button.jump {
      position: absolute;
      display: none;
      right: 0px;
      top: 0px;
      margin: 2px 5px;
      border: none;
      background: none;
      color: ${(props) => props.theme.textColor};
      opacity: 0.5;
      transform: scale(0.9);

      :hover {
        opacity: 0.8;
        transform: scale(1);
      }

      > svg {
        font-size: 20px;
      }
    }

    > .post-main {
      position: relative;
      display: flex;

      .post-time {
        padding: 2px 0px;
        font-size: 12px;
        font-weight: bold;
        opacity: 0.2;
        min-width: 32px;
        max-width: 32px;
      }

      > .post-image {
        min-width: 32px;
        max-width: 32px;
        > img {
          border-radius: 50%;
        }
      }

      > .post-text {
        padding-left: 5px;
        width: 100%;
        max-width: calc(100% - 32px);

        > .post-content {
          max-width: 100%;
          word-wrap: break-word;

          > .emoji-mart-emoji {
            bottom: -5px;
            margin-top: -5px;
          }
        }

        > .post-display-name {
          display: flex;
          width: 100%;
          max-width: 100%;
          font-weight: bold;
          /* justify-content: space-between; */

          > .post-time {
            margin-left: 10px;
          }
        }
      }

      .post-actions-container {
        position: absolute;
        display: flex;
        top: 0px;
        right: 0px;
        border-radius: 5px;
        opacity: 0;

        :hover {
          background: ${(props) => shade(0.2, props.theme.boxColor)};
        }

        > * {
          opacity: 0.2;

          :hover {
            opacity: 1;
          }
        }
      }
    }

    a {
      color: ${(props) => props.theme.linkColor};
      font-weight: bold;
      text-decoration: none;

      :hover {
        color: ${(props) => shade(0.2, props.theme.linkColor)};
      }
    }

    a.mention {
      color: ${(props) => props.theme.mentionColor};

      :hover {
        color: ${(props) => shade(0.2, props.theme.mentionColor)};
      }
    }

    &.got-focus {
      box-shadow: 0 0 3px red;
      background: ${(props) => shade(0.1, props.theme.boxColor)};

      .post-time {
        opacity: 0.5 !important;
      }
    }

    > .reactions-container {
      position: relative;
      margin-top: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      max-width: 100%;

      > .add-reaction-button {
        opacity: 0.5;

        :hover {
          opacity: 1;
        }
      }
    }
  }

  .chat-input__suggestions {
    background: ${(props) => props.theme.boxColor} !important;
    max-height: calc(90vh - ${INPUT_HEIGHT}px);
    overflow-y: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 8px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #8885;
      border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #888d;
    }
  }

  .chat-input__suggestions__item--focused {
    div {
      background-color: ${({ theme }) => shade(0.2, theme.boxColor)} !important;
    }
  }
`;

interface ReactionButtonProps {
  reacted: boolean;
}

export const ReactionButton = styled.button<ReactionButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  max-height: 22px;
  border: none;
  font-size: 12px;
  border-radius: 10px;
  color: ${(props) => props.theme.textColor};
  margin-right: 5px;

  ${(props) =>
    props.reacted
      ? css`
          background: ${shade(0.3, props.theme.boxColor)};
          box-shadow: 0px 0px 2px ${props.theme.textColor};
        `
      : css`
          background: ${lighten(0.05, props.theme.boxColor)};
        `}

  :hover {
    background: ${(props) => shade(0.1, props.theme.boxColor)};
    box-shadow: 0px 0px 2px ${(props) => props.theme.textColor};
  }

  .emoji-mart-emoji {
    font-size: 0 !important;
  }
`;

interface ChatInputContainerProps {
  scrollHeight?: number;
  boxStatus: ChatWindowStatus;
}

export const ChatInputContainer = styled.div<ChatInputContainerProps>`
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 100%;
  min-height: ${INPUT_HEIGHT}px;
  height: ${(props) => props.scrollHeight}px;
  max-height: ${getMaxInputHeight()}px;
  background: ${(props) => shade(0.1, props.theme.background)};
  color: ${(props) => props.theme.textColor};
  border: 1px solid ${(props) => props.theme.boxColor};
  border-top: none;

  > button {
    margin-bottom: 14px;
  }

  .focus-line {
    position: absolute;
    padding: 0 !important;
    pointer-events: none;
    top: 0;
    left: 0;
    border-top: 1px solid ${(props) => props.theme.textColor};
    width: 0px;
    transition: width 0.2s;
  }

  .focus-line.has-focus {
    width: 100%;
  }

  > .chat-input {
    padding: 7px 5px 3px 10px;
    min-height: ${INPUT_HEIGHT}px;
    height: ${(props) => props.scrollHeight}px;
    max-height: ${getMaxInputHeight()}px;
    align-items: center;
    font-size: 14px;
    background: transparent;
    color: ${(props) => props.theme.textColor};
    border: none;
    width: 100%;
    overflow: auto;

    textarea {
      resize: none;
      padding: 7px 5px 3px 10px;
      min-height: ${INPUT_HEIGHT}px;
      height: ${(props) => props.scrollHeight}px;
      align-items: center;
      font-size: 14px;
      background: transparent;
      color: ${(props) => props.theme.textColor};
      border: none;
      width: 100%;
    }

    .chat-input-mention {
      background-color: ${(props) => saturate(1, props.theme.boxColor)};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.textColor};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.textColor};
    }
  }

  > .file-list {
    position: absolute;
    max-width: 100%;
    max-height: ${FILE_LIST_HEIGHT}px;
    top: -${FILE_LIST_HEIGHT}px;
    display: flex;

    > div {
      position: relative;
      background: ${(props) => props.theme.background};
      border: 1px solid ${(props) => props.theme.textColor};

      img {
        max-height: ${FILE_LIST_HEIGHT}px !important;
      }

      .delete {
        position: absolute;
        top: 0px;
        right: 0%;
      }
    }
  }

  > .replying-to {
    position: absolute;
    min-width: 100%;
    max-width: 100%;
    bottom: 100%;
    padding: 5px;
    background: ${(props) => shade(0.1, props.theme.background)};
    max-height: calc(
      ${(props) => getBoxHeight(props.boxStatus)} -
        ${(props) => FIXED_HEIGHT + getInputHeight(props.scrollHeight)}px
    );
    overflow-y: auto;

    > * {
      padding: 5px;
    }

    .post {
      max-width: 100%;
      border-left: 3px solid ${(props) => props.theme.mentionColor};
      background: ${(props) => lighten(0.1, props.theme.boxColor)};

      :hover {
        background: ${(props) => lighten(0.1, props.theme.boxColor)};
      }

      .post-time {
        opacity: 0.5 !important;
        font-size: 10px !important;
        max-width: none !important;
      }

      * {
        font-size: 12px;
      }
    }

    > svg {
      position: absolute;
      transform: scale(1);
      margin-left: 0px;
      width: 22px;
      height: 22px;
      font-size: 22px;
    }

    .cancel-reply {
      position: absolute;
      transform: scale(1);
      top: 1px;
      right: 0;
    }
  }
`;

export const ChatInputAudioTime = styled.span`
  font-size: 30px;
  padding: 10px;
`;

export const ChatAudio = styled.audio`
  height: 35px;
  max-width: 100%;
`;

export const ChatPostLinkPreviewContainer = styled.div`
  padding-left: 5px;
  border-left: 3px solid ${(props) => opacify(-0.5, props.theme.textColor)};
  word-wrap: break-word;
`;

export const ChatEmojiPickerContainer = styled.div`
  position: absolute;
  right: -5px;
  bottom: ${INPUT_HEIGHT}px;

  > .outside-area {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    opacity: 0;
  }
`;

export const SendingAudioFeedbackContainer = styled.div`
  background: ${(props) => transparentize(0.5, props.theme.background)};
  position: absolute;
  padding: 0 20px;
  max-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > span {
    margin-right: 10px;
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  background: ${(props) => props.theme.background};
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  opacity: 0.6;

  > svg {
    font-size: 50px;
  }
`;

export const LoadPreviousButton = styled.button`
  margin-top: 5px;
  margin-left: 15%;
  min-width: 70%;
  max-width: 70%;
  border: none;
  border-radius: 10px;
  padding: 5px;
  background: none;
  color: ${(props) => props.theme.textColor};
  font-weight: bold;
  opacity: 0.5;

  :hover {
    opacity: 0.7;
  }
`;

export const LoadNewButton = styled.button`
  position: absolute;
  margin-top: -15px;
  margin-left: calc(100% - 32px);
  min-width: 22px;
  max-width: 22px;
  min-height: 22px;
  max-height: 22px;
  border: none;
  border-radius: 50%;
  background: ${(props) => props.theme.textColor};
  color: ${(props) => props.theme.background};
  opacity: 0.5;

  :hover {
    opacity: 0.7;
  }

  > svg {
    font-size: 22px;
  }
`;

export const ChatMentionSuggestion = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: ${(props) => props.theme.boxColor};
  color: ${(props) => props.theme.textColor};
  width: 100%;
  border: 1px solid ${(props) => opacify(-0.8, props.theme.textColor)};

  :hover {
    background-color: ${(props) => shade(0.2, props.theme.boxColor)};
  }

  > img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  > span {
    margin-left: 10px;
  }
`;
