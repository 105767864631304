import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';

import { useTutorial } from '../../hooks/tutorial';
import { EnterRoomTipContent } from './contents/EnterRoom';
import { AddUsersTipContent } from './contents/AddUsers';
import { InviteToMeetingTipContent } from './contents/InviteToMeeting';
import { ContextMenuTipContent } from './contents/ContextMenu';
import { MeetingVisibilityTipContent } from './contents/MeetingVisibility';

export type TooltipPosition =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface Props {
  content: React.ReactChild | React.ReactFragment | React.ReactPortal;
  open: boolean;
  position?: TooltipPosition;
}

const StyledTooltip = withStyles({
  tooltip: {
    fontFamily: 'Inter, Arial, Helvetica, sans-serif',
    fontSize: '16px',
    color: 'black',
    backgroundColor: '#e9eef1',
    maxWidth: '500px',
    boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.4)',
  },
  arrow: {
    '&::before': {
      backgroundColor: '#e9eef1',
      boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.4)',
    },
  },
})(Tooltip);

export const TutorialTip: React.FC<Props> = ({ content, open, position }) => {
  return (
    <StyledTooltip
      title={content}
      open={open}
      arrow
      placement={position}
      interactive
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'none',
        }}
      />
    </StyledTooltip>
  );
};

export const EnterRoomTip: React.FC = () => {
  const enterRoomTipIsOpen = useTutorial((state) => state.enterRoomTipIsOpen);

  return (
    <TutorialTip
      open={enterRoomTipIsOpen}
      content={<EnterRoomTipContent />}
      position="right"
    />
  );
};

export const AddUsersTip: React.FC = () => {
  const addUsersTipIsOpen = useTutorial((state) => state.addUsersTipIsOpen);

  return (
    <TutorialTip
      open={addUsersTipIsOpen}
      content={<AddUsersTipContent />}
      position="bottom"
    />
  );
};

export const InviteToMeetingTip: React.FC = () => {
  const inviteToMeetingTipIsOpen = useTutorial(
    (state) => state.inviteToMeetingTipIsOpen
  );

  return (
    <TutorialTip
      open={inviteToMeetingTipIsOpen}
      content={<InviteToMeetingTipContent />}
      position="right"
    />
  );
};

export const ContextMenuTip: React.FC = () => {
  const contextMenuTipIsOpen = useTutorial(
    (state) => state.contextMenuTipIsOpen
  );

  return (
    <TutorialTip
      open={contextMenuTipIsOpen}
      content={<ContextMenuTipContent />}
      position="right"
    />
  );
};

export const MeetingVisibilityTip: React.FC = () => {
  const meetingVisibilityTipIsOpen = useTutorial(
    (state) => state.meetingVisibilityTipIsOpen
  );

  return (
    <TutorialTip
      open={meetingVisibilityTipIsOpen}
      content={<MeetingVisibilityTipContent />}
      position="right"
    />
  );
};
