import { shade } from 'polished';
import { styled } from '../../themes';

const WIDTH = 524;

export const Container = styled.div`
  position: relative;
  width: 60%;
  min-width: ${WIDTH}px;
  max-width: ${WIDTH}px;
  /* height: 50%; */
  background: ${(props) => props.theme.boxColor};
  color: ${(props) => props.theme.textColor};
  box-shadow: ${(props) => props.theme.boxGlow};
  border-radius: 5px;

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    padding-left: 10px;
  }

  > .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: calc(100% - 38px);
    padding: 20px;

    > .input {
      width: 100%;
    }

    > .meeting-link {
      margin-top: 10px;
      padding: 10px;
      width: 100%;
      border-radius: 5px;
    }

    > .spinner-container {
      position: absolute;
      background: #00000055;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      border-radius: 5px;

      > svg {
        font-size: 50px;
      }
    }
  }
`;

export const Button = styled.button`
  border: none;
  background: ${(props) => props.color || 'green'};
  padding: 14px 28px;
  color: white;
  white-space: nowrap;
  border-radius: 5px;
  transition: opacity 0.3s;
  align-self: center;

  :hover {
    background: ${shade(0.2, 'green')};
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
    :hover {
      background: ${shade(0.2, 'green')};
    }
  }
`;
