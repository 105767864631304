export function matchesSearchValue(text: string, searchValue: string): boolean {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .includes(
      searchValue
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    );
}

interface SplitedName {
  firstName: string;
  lastName: string;
}

export function splitName(fullName: string): SplitedName {
  const names = fullName
    .trim()
    .split(' ')
    .map((name) => name.trim())
    .filter((name) => !!name);

  const firstName = names.splice(0, 1).join(' ');
  const lastName = names.join(' ');

  return { firstName, lastName };
}
