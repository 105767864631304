import React from 'react';

import { AppMessages } from '../../languages';
import { useLanguage } from '../../hooks/language';
import { useThemeContext } from '../../hooks/theme';
import { IconButton } from '../IconButton';
import { LightModeIcon, DarkModeIcon } from '../Icons';
import { Tooltip } from '../Tooltip';

interface Props {
  iconSize?: number;
  padding?: number;
}

export const ThemeSwitcher: React.FC<Props> = ({
  iconSize = 30,
  padding = 6,
}) => {
  const theme = useThemeContext((state) => state.theme);
  const toggleTheme = useThemeContext((state) => state.toggleTheme);
  const messages = useLanguage((state) => state.messages);

  const toLightTooltip = messages[AppMessages.componentThemeSwitcherToLight];
  const toDarkTooltip = messages[AppMessages.componentThemeSwitcherToDark];

  return (
    <IconButton
      className="theme-switcher"
      iconSize={iconSize}
      padding={padding}
      onClick={toggleTheme}
    >
      {theme.title === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
      <Tooltip
        position="bottom-end"
        text={theme.title === 'dark' ? toLightTooltip : toDarkTooltip}
      />
    </IconButton>
  );
};
