import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { EMAIL_PATTERN, SUBDOMAIN_PATTERN } from '../../utils/constants';
import { AppMessages } from '../../languages';
import { useQueryParams } from '../../hooks/queryParams';
import { useLoading } from '../../hooks/loading';
import { useApi } from '../../hooks/api';
import { useToast } from '../../hooks/toast';
import { ProseiaPageContainer } from '../../components/ProseiaPageContainer';
import {
  Button,
  StyledInput,
  TitleContainer,
  MessageContainer,
} from '../../components/ProseiaPageContainer/styles';

// eslint-disable-next-line import/extensions
import { addHotjarScript } from './hotjar';

import { StepMessageContainer } from './styles';

interface FormErrors {
  [key: string]: string;
}

const CreateEnvironment: React.FC = () => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const addToast = useToast((state) => state.addToast);
  const { isSubdomainAvailable, createEnvironment } = useApi();
  const { formatMessage } = useIntl();

  const [submitting, startSubmitting, stopSubmitting] = useLoading();
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const emailRef = useRef<HTMLInputElement>();
  const subdomainRef = useRef<HTMLInputElement>();

  const getEmail = useCallback(
    () => (emailRef.current?.value?.trim() || '').toLowerCase(),
    []
  );

  const getSubdomain = useCallback(
    () =>
      (subdomainRef.current?.value?.trim() || '')
        .normalize('NFD')
        .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
        .toLowerCase(),
    []
  );

  const organizationLabel = formatMessage({
    id: AppMessages.pageCreateEnvironmentOrganization,
  });
  const emailLabel = formatMessage({ id: AppMessages.pageSignInEmail });
  const subdomainLabel = formatMessage({
    id: AppMessages.pageCreateEnvironmentSubdomain,
  });
  const subdomainPlaceholder = formatMessage({
    id: AppMessages.pageCreateEnvironmentSubdomainPlaceholder,
  });
  const submitLabel = formatMessage({
    id: AppMessages.pageCreateEnvironmentSubmit,
  });

  const requiredMessage = formatMessage({
    id: AppMessages.pageSignInValidationRequired,
  });
  const invalidEmailMessage = formatMessage({
    id: AppMessages.pageSignInValidationInvalidEmail,
  });
  const invalidSubdomainMessage = formatMessage({
    id: AppMessages.pageCreateEnvironmentValidationInvalidSubdomain,
  });
  const subdomainNotAvailableMessage = formatMessage({
    id: AppMessages.pageCreateEnvironmentValidationSubdomainNotAvailable,
  });
  const stepMessage = formatMessage(
    { id: AppMessages.stepOf },
    { current: 1, total: 3 }
  );

  const isEmailValid = useCallback(() => {
    const isValid = !!getEmail().match(EMAIL_PATTERN);

    if (!isValid) {
      setFormErrors((state) => ({ ...state, email: invalidEmailMessage }));
    }

    return isValid;
  }, [getEmail, invalidEmailMessage]);

  const isSubdomainValid = useCallback(() => {
    const isValid = !!getSubdomain().match(SUBDOMAIN_PATTERN);

    if (!isValid) {
      setFormErrors((state) => ({
        ...state,
        subdomain: invalidSubdomainMessage,
      }));
    }

    return isValid;
  }, [getSubdomain, invalidSubdomainMessage]);

  const checkSubdomainAvailability = useCallback(() => {
    return isSubdomainAvailable(getSubdomain()).then(
      (available) => {
        if (!available) {
          setFormErrors((state) => ({
            ...state,
            subdomain: subdomainNotAvailableMessage,
          }));
        }
        return available;
      },
      () => false
    );
  }, [isSubdomainAvailable, getSubdomain, subdomainNotAvailableMessage]);

  const canSubmit = useCallback(async () => {
    if (submitting) return false;
    if (!getEmail() || !isEmailValid()) return false;
    if (!getSubdomain() || !isSubdomainValid()) return false;
    if (!(await checkSubdomainAvailability())) return false;

    return true;
  }, [
    submitting,
    getEmail,
    getSubdomain,
    isEmailValid,
    isSubdomainValid,
    checkSubdomainAvailability,
  ]);

  const handleEmailBlur = useCallback(() => {
    if (!getEmail()) {
      setFormErrors((state) => ({ ...state, email: requiredMessage }));
      return;
    }

    isEmailValid();
  }, [getEmail, isEmailValid, requiredMessage]);

  const handleEmailChange = useCallback(() => {
    setFormErrors((state) => ({ ...state, email: '', credentials: '' }));
  }, []);

  const handleSubdomainBlur = useCallback(() => {
    if (!getSubdomain()) {
      setFormErrors((state) => ({ ...state, subdomain: requiredMessage }));
      return;
    }

    isSubdomainValid();
  }, [getSubdomain, isSubdomainValid, requiredMessage]);

  const handleSubdomainChange = useCallback(() => {
    setFormErrors((state) => ({ ...state, subdomain: '' }));
  }, []);

  const handleRequestError = useCallback(
    (error) => {
      const message = Array.isArray(error.message)
        ? error.message.join('\n')
        : error.message;
      addToast({ type: 'error', title: 'Error', description: message });
      stopSubmitting();
    },
    [addToast, stopSubmitting]
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      startSubmitting();

      canSubmit()
        .then((allowed) => {
          if (!allowed) {
            stopSubmitting();
            return;
          }

          const payload = {
            email: getEmail(),
            subdomain: getSubdomain(),
            createLead: true,
            origin: queryParams.get('origin') || undefined,
          };

          const token = btoa(JSON.stringify(payload));

          if (window.location.hostname === 'localhost') {
            setTimeout(() => {
              stopSubmitting();
              history.push(`/signing-up/${token}`);
            }, 2500);
            return;
          }

          createEnvironment(payload).then(() => {
            history.push(`/signing-up/${token}`);
          }, handleRequestError);
        })
        .catch(stopSubmitting);
    },
    [
      queryParams,
      history,
      canSubmit,
      startSubmitting,
      stopSubmitting,
      getEmail,
      getSubdomain,
      createEnvironment,
      handleRequestError,
    ]
  );

  useEffect(() => {
    addHotjarScript();
  }, []);

  return (
    <ProseiaPageContainer loading={submitting}>
      <form onSubmit={handleSubmit}>
        <TitleContainer>
          {formatMessage({
            id: AppMessages.pageCreateEnvironmentWelcome,
          })}
        </TitleContainer>

        <br />

        <MessageContainer>
          {formatMessage({
            id: AppMessages.pageCreateEnvironmentEmail,
          })}
        </MessageContainer>

        <br />

        <StyledInput
          required
          name="subdomain"
          autoFocus
          inputRef={subdomainRef}
          label={organizationLabel}
          onChange={handleSubdomainChange}
          onBlur={handleSubdomainBlur}
          disabled={submitting}
          error={!!formErrors.subdomain}
          helperText={formErrors.subdomain}
          autoComplete="off"
        />

        <StyledInput
          required
          name="email"
          inputRef={emailRef}
          // autoFocus
          label={emailLabel}
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          disabled={submitting}
          error={!!formErrors.email || !!formErrors.credentials}
          helperText={formErrors.email || formErrors.credentials}
        />

        <br />

        <MessageContainer align="center">{subdomainLabel}</MessageContainer>

        <MessageContainer bold align="center">
          {`${getSubdomain() || subdomainPlaceholder}.proseia.app`}
        </MessageContainer>

        <Button type="submit" disabled={submitting}>
          {submitLabel}
        </Button>

        <br />

        <StepMessageContainer align="center">
          {stepMessage}
        </StepMessageContainer>

        <br />
      </form>
    </ProseiaPageContainer>
  );
};

export default CreateEnvironment;
