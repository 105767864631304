import React from 'react';
import { useIntl } from 'react-intl';

import ContextMenuGIF from '../../../assets/tutorials/context-menu.gif';
import { useTutorial } from '../../../hooks/tutorial';
import { AppMessages } from '../../../languages';

import {
  ContentContainer,
  ContentTitle,
  ContentLine,
  ContentImage,
  ContentButton,
} from '../styles';

export const ContextMenuTipContent: React.FC = () => {
  const closeContextMenuTip = useTutorial((state) => state.closeContextMenuTip);
  const { formatMessage } = useIntl();

  return (
    <ContentContainer onClick={(e) => e.stopPropagation()}>
      <ContentTitle>
        {formatMessage({ id: AppMessages.tutorialContextMenuTitle })}
      </ContentTitle>

      <ContentLine>
        {formatMessage({ id: AppMessages.tutorialContextMenuDescription1 })}
      </ContentLine>

      <ContentImage
        src={ContextMenuGIF}
        width="300"
        height="240"
        draggable={false}
      />

      <ContentLine />

      <ContentButton onClick={closeContextMenuTip}>
        {formatMessage({ id: AppMessages.tutorialGotIt })}
      </ContentButton>
    </ContentContainer>
  );
};
