import { styled } from '../../themes';

export const Container = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 30px;
  z-index: 2;
  pointer-events: none;
`;
