import { keyframes } from 'styled-components';
import { styled } from '../../themes';

const FONT_COLOR = '#344d9a';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 45%;
  min-width: 450px;
  min-height: 90vh;
  left: 0;
  bottom: 0;
  margin: 5vh 5vw;
  padding: 15px;
  border-radius: 5px;
  background: #ffffffdd;
  /* overflow-y: auto; */

  @media (max-width: 500px) {
    width: 100%;
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
  }

  @media print {
    width: 94%;
    min-width: 94%;
    min-height: 0;
    left: auto;
    bottom: auto;
    margin: 3%;
    padding: 20px;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    /* flex: auto; */
  }

  animation: ${keyframes`
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);;
    }
  `} 0.5s linear;
`;

export const Content = styled.div`
  width: 100%;

  * {
    color: ${FONT_COLOR};
  }
`;

export const Logo = styled.div`
  width: 100%;
  justify-content: left !important;
  align-items: flex-start !important;
`;

export const Title = styled.h1`
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Subtitle = styled.h2`
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Paragraph = styled.p`
  font-size: 18px;
  text-align: justify;
  margin-bottom: 5px;
`;

export const List = styled.ul`
  margin-left: 20px;
`;

export const ListItem = styled.li`
  margin-top: 5px;
`;

export const ConfigContainer = styled.div`
  position: fixed;
  top: 25px;
  right: 25px;
  display: flex;
  justify-content: space-between;
`;

export const PageImage = styled.img`
  position: fixed;
  right: -26vh;
  top: -12vh;
  height: 112vh;
`;

export const LogoImage = styled.img`
  /* width: 100%; */
`;

export const CopyrightContainer = styled.div`
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  padding: 0 20px;
  align-items: center !important;
  justify-content: center !important;
  color: ${FONT_COLOR};
  width: fit-content !important;
  /* font-size: 14px; */

  > img {
    margin-left: 10px;
    height: 25px;
  }
`;
