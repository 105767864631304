import { shade } from 'polished';
import { styled } from '../../themes';

const WIDTH = 249;

export const Container = styled.div`
  position: relative;
  width: 60%;
  min-width: ${WIDTH}px;
  max-width: ${WIDTH}px;
  background: ${(props) => props.theme.boxColor};
  color: ${(props) => props.theme.textColor};
  box-shadow: ${(props) => props.theme.boxGlow};
  border-radius: 5px;

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    padding-left: 10px;
  }

  > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    > * {
      margin: 5px;
    }

    > input {
      background: ${(props) => shade(0.1, props.theme.background)};
      color: ${(props) => props.theme.textColor};
      border: none;
      width: 100%;
      padding: 10px;
      border-radius: 5px;
    }
  }
`;

export const ChannelTypeOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;

  :hover {
    background: ${(props) => shade(0.1, props.theme.boxColor)};
  }

  > label {
    margin-left: 5px;
  }
`;

export const Button = styled.button`
  border: none;
  background: green;
  color: ${(props) => props.theme.textColor};
  padding: 7px 7px;
  font-weight: bold;
  width: 100%;
  border-radius: 5px;

  :hover {
    background: ${shade(0.2, 'green')};
  }

  &[disabled] {
    background: ${shade(0.2, 'green')};
    color: #888;
    cursor: default;
  }
`;
