import React, { useState, useCallback, useMemo } from 'react';

import { createContext, useContextSelector } from './context';
import { UsageLimits, useApi } from './api';

interface PlanLimitsContextData {
  usageLimits: UsageLimits;
  loadUsageLimits: (guestToken?: string) => Promise<void>;
  getUsageLimits: (guestToken?: string) => Promise<UsageLimits>;
}

const PlanLimitsContext = createContext({} as PlanLimitsContextData);

export const PlanLimitsProvider: React.FC = ({ children }) => {
  const { getCurrentMonthUsageLimits } = useApi();
  const [usageLimits, setUsageLimits] = useState({} as UsageLimits);

  const loadUsageLimits = useCallback(
    async (guestToken?: string) => {
      const result = await getCurrentMonthUsageLimits(guestToken);
      setUsageLimits(result);
    },
    [getCurrentMonthUsageLimits]
  );

  const getUsageLimits = useCallback(
    async (guestToken?: string) => {
      const result = await getCurrentMonthUsageLimits(guestToken);
      setUsageLimits(result);
      return result;
    },
    [getCurrentMonthUsageLimits]
  );

  const contextValue = useMemo<PlanLimitsContextData>(
    () => ({
      usageLimits,
      loadUsageLimits,
      getUsageLimits,
    }),
    [usageLimits, loadUsageLimits, getUsageLimits]
  );

  return (
    <PlanLimitsContext.Provider value={contextValue}>
      {children}
    </PlanLimitsContext.Provider>
  );
};

export function usePlanLimits<TResult>(
  selector: (state: PlanLimitsContextData) => TResult
): TResult {
  return useContextSelector(PlanLimitsContext, selector);
}
