import React, { useCallback } from 'react';
import { useLanguage, supportedLanguages } from '../../hooks/language';
import { StyledMenuItem } from '../StyledMenuItem/styles';
import { StyledSelect } from '../StyledSelect';

import enUsFlag from '../../assets/flags/en-US.png';
import esEsFlag from '../../assets/flags/es-ES.png';
import ptBrFlag from '../../assets/flags/pt-BR.png';

const flags: { [key: string]: string } = {
  'en-US': enUsFlag,
  'es-ES': esEsFlag,
  'pt-BR': ptBrFlag,
};

export const LanguageSelector: React.FC = () => {
  const language = useLanguage((state) => state.language);
  const setLanguage = useLanguage((state) => state.setLanguage);

  const handleChangeLanguage = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const newLanguage = event.target.value as string;
      setLanguage(newLanguage);
    },
    [setLanguage]
  );

  return (
    <StyledSelect
      labelId="language-selector-label"
      id="language-selector"
      value={language}
      onChange={handleChangeLanguage}
      style={{ minWidth: '50px' }}
      disableUnderline
    >
      {supportedLanguages.map((lang) => (
        <StyledMenuItem
          key={lang}
          disableGutters
          value={lang}
          style={{ padding: '5px' }}
        >
          <img src={flags[lang]} alt={lang} style={{ width: '24px' }} />
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};
