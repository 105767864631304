import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

import { ThemeProvider, dark, light, ProseiaTheme } from '../themes';
import { createContext, useContextSelector } from './context';
import { usePersistentState } from './persistentState';

interface ThemeContextData {
  theme: ProseiaTheme;
  toggleTheme: () => void;
}

const getMuiTheme = (theme: ProseiaTheme) => ({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontFamily: 'Inter, Arial, Helvetica, sans-serif',
        fontSize: '16px',
        color: theme.background,
        backgroundColor: theme.textColor,
      },
      arrow: {
        color: theme.textColor,
      },
    },
  },
});

const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

const FilledThemeProvider: React.FC = ({ children }) => {
  const [themeTitle, setThemeTitle] = usePersistentState('theme', light.title);
  const [theme, setTheme] = useState(light);
  const [muiTheme, setMuiTheme] = useState(createMuiTheme());

  const toggleTheme = useCallback(() => {
    setThemeTitle((state) => (state === dark.title ? light.title : dark.title));
  }, [setThemeTitle]);

  useEffect(() => {
    setTheme((state) => {
      if (themeTitle === state.title) return state;
      if (themeTitle === dark.title) return dark;
      return light;
    });
  }, [themeTitle]);

  useEffect(() => {
    const newMuiTheme = createMuiTheme(getMuiTheme(theme));
    setMuiTheme(newMuiTheme);
  }, [theme]);

  const contextValue = useMemo<ThemeContextData>(
    () => ({
      theme,
      toggleTheme,
    }),
    [theme, toggleTheme]
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export function useThemeContext<TResult>(
  selector: (state: ThemeContextData) => TResult
): TResult {
  return useContextSelector(ThemeContext, selector);
}

export default FilledThemeProvider;
