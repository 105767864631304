import React, { useState, useEffect, useCallback } from 'react';
import { FileInfo } from 'mattermost-redux/types/files';

import { ChatPostImage } from './ChatPostImage';
import { useMattermost } from '../../hooks/mattermost';
import { SpinnerIcon } from '../Icons';

import { ChatPostLinkPreviewContainer, ChatAudio } from './styles';

interface Props {
  file: FileInfo;
  onImgResize?: (width: number, height: number) => void;
}

export const ChatPostFileLink: React.FC<Props> = ({ file, onImgResize }) => {
  const [fileUrl, setFileUrl] = useState('');
  const [filePreviewUrl, setFilePreviewUrl] = useState('');
  const [preparingLink, setPreparingLink] = useState(false);
  const getFile = useMattermost((state) => state.getFile);
  const getFileUrl = useMattermost((state) => state.getFileUrl);
  const getFilePreviewUrl = useMattermost((state) => state.getFilePreviewUrl);

  const isGif = file.mime_type === 'image/gif';
  const isAudio = file.mime_type.startsWith('audio');

  const handleClick = useCallback(() => {
    if (fileUrl || preparingLink) return;
    setPreparingLink(true);
    getFile(getFileUrl(file.id))
      .then((url) => {
        setFileUrl(url);
      })
      .finally(() => {
        setPreparingLink(false);
        setTimeout(() => {
          const element = document.getElementById(`link-${file.id}`);
          if (element) element.click();
        }, 75);
      });
  }, [fileUrl, preparingLink, file.id, getFile, getFileUrl]);

  useEffect(() => {
    if (!isGif) return;
    getFile(getFileUrl(file.id)).then((url) => {
      setFileUrl(url);
    });
  }, [isGif, file, getFileUrl, getFile]);

  useEffect(() => {
    if (!file.has_preview_image) return;
    getFile(getFilePreviewUrl(file.id)).then((url) => {
      setFilePreviewUrl(url);
    });
  }, [file, getFilePreviewUrl, getFile]);

  useEffect(() => {
    if (!isAudio) return;
    handleClick();
  }, [isAudio, handleClick]);

  return isAudio ? (
    <ChatPostLinkPreviewContainer>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <ChatAudio controls>
        {!!fileUrl && <source src={fileUrl} type={file.mime_type} />}
      </ChatAudio>
      {preparingLink && <SpinnerIcon />}
    </ChatPostLinkPreviewContainer>
  ) : (
    <ChatPostLinkPreviewContainer>
      <a
        id={`link-${file.id}`}
        href={fileUrl || undefined}
        download={!isGif && !file.has_preview_image && file.name}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        {!file.has_preview_image && !isGif && <h4>{file.name}</h4>}
        {file.has_preview_image && !isGif && (
          <ChatPostImage src={filePreviewUrl} onResize={onImgResize} />
        )}
        {isGif && <ChatPostImage src={fileUrl} onResize={onImgResize} />}
        {preparingLink && <SpinnerIcon />}
      </a>
    </ChatPostLinkPreviewContainer>
  );
};
