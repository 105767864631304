import { shade } from 'polished';
import { styled } from '../../themes';

const WIDTH = 524;

export const Container = styled.div`
  position: relative;
  width: 60%;
  min-width: ${WIDTH}px;
  max-width: ${WIDTH}px;
  height: 70%;
  background: ${(props) => props.theme.boxColor};
  color: ${(props) => props.theme.textColor};
  box-shadow: ${(props) => props.theme.boxGlow};
  border-radius: 5px;

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    padding-left: 10px;
  }

  > .search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    > button {
      margin-left: 10px;
    }
  }

  > .channels {
    min-height: calc(100% - 94px);
    max-height: calc(100% - 94px);
    overflow-x: none;
    overflow-y: auto;
  }
`;

export const ChannelContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  > button {
    opacity: 0;
  }

  :hover {
    background: ${(props) => shade(0.1, props.theme.boxColor)};

    > button {
      opacity: 1;
    }
  }

  > hr {
    position: absolute;
    top: 0px;
    width: calc(100% - 20px);
    background-color: #000;
    opacity: 0.2;
    height: 1px;
    border: none;
  }
`;

export const Button = styled.button`
  border: none;
  background: ${(props) => props.color || 'green'};
  padding: 7px 14px;
  color: white;
  white-space: nowrap;
  border-radius: 5px;
  transition: opacity 0.3s;

  :hover {
    background: ${shade(0.2, 'green')};
  }
`;

export const LoadingContainer = styled.div`
  > svg {
    font-size: 50px;
  }
`;
