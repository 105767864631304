import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { IoMdCloseCircle } from 'react-icons/io';
import { AiTwotoneLeftCircle } from 'react-icons/ai';

import { Container, CustomStyledSelect } from './styles';
import { AppMessages } from '../../languages';
import { useOffice } from '../../hooks/office';
import { AvatarStatus } from '../Avatar/styles';
import { StyledMenuItem } from '../StyledMenuItem/styles';

const KEY_CODE_ENTER = 13;
const KEY_CODE_ESC = 27;

const statusColors = {
  available: '#009045',
  away: '#FDD835',
  busy: '#C62828',
  inactive: '#adadad',
};
const selectableStatus: AvatarStatus[] = ['available', 'away', 'busy'];

interface Props {
  onChange?: (searchValue: string) => void | Promise<void>;
}

const statusMessages = {
  available: AppMessages.userInfoStatusAvailable,
  away: AppMessages.userInfoStatusAway,
  busy: AppMessages.userInfoStatusBusy,
  inactive: AppMessages.userInfoStatusInactive,
};

export const UserStatusEditor: React.FC<Props> = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { formatMessage } = useIntl();
  const status = useOffice((state) => state.currentUserInfo.status);
  const statusMessage = useOffice(
    (state) => state.currentUserInfo.statusMessage
  );
  const updateCurrentUserInfo = useOffice(
    (state) => state.updateCurrentUserInfo
  );

  const [hasValue, setHasValue] = useState(!!statusMessage);
  const [hasFocus, setHasFocus] = useState(false);

  const placeholder = formatMessage({
    id: statusMessages[status],
  });

  const currentStatus = `${status}${statusMessage || ''}*`;

  const handleInputChange = useCallback(() => {
    if (!inputRef.current) return;
    const inputValue = inputRef.current.value;
    if (hasValue !== !!inputValue) setHasValue(!!inputValue);
  }, [hasValue]);

  const handleEditAvatarStatusMessage = useCallback(() => {
    const message = formatMessage({ id: statusMessages[status] });
    const result = inputRef.current?.value;
    const newStatusMessage = !result || result === message ? '' : result;
    updateCurrentUserInfo({ statusMessage: newStatusMessage });
  }, [status, formatMessage, updateCurrentUserInfo]);

  const handleClear = useCallback(() => {
    if (!inputRef.current) return;

    inputRef.current.value = '';
    handleInputChange();
    handleEditAvatarStatusMessage();
  }, [handleInputChange, handleEditAvatarStatusMessage]);

  const handleChangeAvatarStatus = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const newStatus = event.target.value as AvatarStatus;
      updateCurrentUserInfo({ status: newStatus, statusMessage: '' });

      setTimeout(() => {
        if (inputRef.current) inputRef.current.focus();
      }, 50);
    },
    [updateCurrentUserInfo]
  );

  const handleInputFocus = useCallback(() => {
    setHasFocus(true);
    if (inputRef.current) inputRef.current.select();
  }, []);

  const handleInputBlur = useCallback(() => {
    setHasFocus(false);
    if (inputRef.current) inputRef.current.value = statusMessage || '';
  }, [statusMessage]);

  const handleTyping = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!inputRef.current) return;
      if (e.keyCode === KEY_CODE_ENTER) {
        handleEditAvatarStatusMessage();
        inputRef.current.blur();
      } else if (e.keyCode === KEY_CODE_ESC) {
        inputRef.current.blur();
      }
    },
    [handleEditAvatarStatusMessage]
  );

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.value = statusMessage || '';
  }, [statusMessage]);

  const statusIcon = useMemo(
    () => (
      <AiTwotoneLeftCircle
        style={{
          color: statusColors[status || 'available'],
          marginRight: 10,
        }}
      />
    ),
    [status]
  );

  const statusOptions = useMemo(
    () =>
      selectableStatus.map((item) => (
        <StyledMenuItem key={item} disableGutters value={item}>
          <AiTwotoneLeftCircle
            style={{
              color: statusColors[item],
              marginRight: 10,
            }}
          />
          {formatMessage({ id: statusMessages[item] })}
        </StyledMenuItem>
      )),
    [formatMessage]
  );

  const currentOption = useMemo(
    () => <StyledMenuItem disableGutters value={currentStatus} hidden />,
    [currentStatus]
  );

  const statusSelector = useMemo(
    () => (
      <CustomStyledSelect
        hidden
        statuscolor={statusColors[status || 'available']}
        value={currentStatus}
        onChange={handleChangeAvatarStatus}
      >
        {statusOptions}
        {currentOption}
      </CustomStyledSelect>
    ),
    [
      currentOption,
      currentStatus,
      handleChangeAvatarStatus,
      status,
      statusOptions,
    ]
  );

  const messageInput = useMemo(
    () => (
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onKeyDown={handleTyping}
      />
    ),
    [
      handleInputBlur,
      handleInputChange,
      handleInputFocus,
      handleTyping,
      placeholder,
    ]
  );

  const clearIcon = useMemo(
    () =>
      hasValue && <IoMdCloseCircle className="clear" onClick={handleClear} />,
    [handleClear, hasValue]
  );

  return (
    <Container hasFocus={hasFocus} className="user-status-editor">
      {statusIcon}
      {statusSelector}
      {messageInput}
      {clearIcon}
    </Container>
  );
};
