import React, { useRef, useCallback, useEffect, useMemo } from 'react';

import { AppMessages } from '../../languages';
import { useLanguage } from '../../hooks/language';
import { useAuth } from '../../hooks/auth';
import { useApi } from '../../hooks/api';
import { useJitsi } from '../../hooks/jitsi';
import { useMattermost } from '../../hooks/mattermost';
import { useOffice, getRoomMeetingId } from '../../hooks/office';
import { defaultRoom, mobileRoom } from '../../hooks/model';
import {
  ExpandBoxIcon,
  RetractBoxIcon,
  DeleteIcon,
  OfficePostsIcon,
  OfficePostsBlankIcon,
  FavoriteIcon,
  EnterIcon,
  SoundIcon,
  MicIcon,
} from '../Icons';
import { AvatarGroup, AvatarProps } from '../AvatarGroup';
import { Avatar } from '../Avatar';
import { statusColors } from '../Avatar/styles';
import { IconButton } from '../IconButton';
import { Tooltip } from '../Tooltip';
import { EditableLabel } from '../EditableLabel';
import { InviteToMeetingTip, ContextMenuTip } from '../TutorialTip';

import {
  Container,
  TitleBar,
  ActionContainer,
  Content,
  Footer,
} from './styles';

interface Props {
  id: string;
  title: string;
  isCurrent?: boolean;
  expanded?: boolean;
  avatars?: AvatarProps[];
  groupedAvatars?: { [groupId: string]: AvatarProps[] };
  top?: number;
  left?: number;
  width?: number;
  hidden?: boolean;
  onToggleExpanded?: (expanded: boolean) => void | Promise<void>;
  onConnectionRequest?: (idFrom: string, idTo: string) => void | Promise<void>;
  onEnterRoom?: (id: string) => void | Promise<void>;
  onLeaveGroup?: (groupId: string, id: string) => void | Promise<void>;
}

export const Room: React.FC<Props> = ({
  id,
  title,
  isCurrent = false,
  expanded = false,
  avatars = [],
  groupedAvatars = {},
  top = 0,
  left = 0,
  width = 0,
  hidden = false,
  onToggleExpanded,
  onConnectionRequest,
  onEnterRoom,
  onLeaveGroup,
}: Props) => {
  const container = useRef<HTMLDivElement>(null);
  const messages = useLanguage((state) => state.messages);
  const userId = useAuth((state) => state.user?.id || '');
  const isAdmin = useAuth((state) => state.isAdmin());
  const { roomsService, addFavoriteRoom, removeFavoriteRoom } = useApi();

  const officeId = useOffice((state) => state.getCurrentOffice().id);
  const officeEditMode = useOffice((state) => state.officeEditMode);
  const userInfoId = useOffice((state) => state.currentUserInfo.id);
  const userInfoOfficeId = useOffice((state) => state.currentUserInfo.officeId);
  const userInfoRoomId = useOffice((state) => state.currentUserInfo.roomId);
  const userInfoGroupId = useOffice((state) => state.currentUserInfo.groupId);
  const userInfoSoundEnabled = useOffice(
    (state) => state.currentUserInfo.soundEnabled
  );
  const userInfoMicEnabled = useOffice(
    (state) => state.currentUserInfo.micEnabled
  );
  const userInfoStatus = useOffice((state) => state.currentUserInfo.status);
  const firstOtherUserId = useOffice((state) =>
    Object.keys(state.usersInfo)
      .sort()
      .find((key) => key !== state.currentUserInfo.id)
  );
  const favoriteRooms = useOffice((state) => state.favoriteRooms);
  const setFavoriteRooms = useOffice((state) => state.setFavoriteRooms);
  const enterGroupMeeting = useOffice((state) => state.enterGroupMeeting);
  const enterOfficeRoom = useOffice((state) => state.enterOfficeRoom);
  const shouldHighlightFiltered = useOffice(
    (state) => state.shouldHighlightFiltered
  );
  const filteredUsers = useOffice((state) => state.filteredUsers);

  const channelsLoaded = useMattermost(
    (state) => Object.keys(state.teamChannels).length > 0
  );
  const openOfficeChannel = useMattermost((state) => state.openOfficeChannel);
  const joinOfficeChannel = useMattermost((state) => state.joinOfficeChannel);
  const unreadMessagesCount = useMattermost((state) => {
    const channelName = state.getOfficeChannelName(officeId, id);
    const officeChannel = state.officeChannels[channelName];
    return officeChannel
      ? state.getChannelUnreadsMessageCount(officeChannel)
      : 0;
  });

  const toggleMute = useJitsi((state) => state.toggleMute);

  const expandMessage = messages[AppMessages.componentRoomExpand];
  const retractMessage = messages[AppMessages.componentRoomRetract];
  const deleteMessage = messages[AppMessages.componentRoomDelete];
  const confirmDeleteMessage =
    messages[AppMessages.componentRoomConfirmDeletion];
  const enterMessage = messages[AppMessages.componentRoomEnter];
  const isDefaultRoom = id === defaultRoom.id || id === mobileRoom.id;
  const canEdit = isAdmin && officeEditMode && !isDefaultRoom;

  const soundEnabled = isCurrent && !userInfoGroupId && userInfoSoundEnabled;
  const micEnabled = soundEnabled && userInfoMicEnabled;
  const roomSoundDisabled = userInfoSoundEnabled && !soundEnabled;

  const micMessage = micEnabled
    ? messages[AppMessages.userMediaMicrophoneDisable]
    : messages[AppMessages.userMediaMicrophoneEnable];

  const soundMessage = soundEnabled
    ? messages[AppMessages.componentRoomTurnOffSound]
    : messages[AppMessages.componentRoomTurnOnSound];

  const handleOpenOfficePostsChannel = useCallback(() => {
    openOfficeChannel(officeId, id);
  }, [id, officeId, openOfficeChannel]);

  const handleToggleExpanded = useCallback(() => {
    if (onToggleExpanded) onToggleExpanded(!expanded);
  }, [onToggleExpanded, expanded]);

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (container.current) container.current.classList.add('drop-zone');
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (container.current) container.current.classList.remove('drop-zone');
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (container.current) container.current.classList.add('drop-zone');
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (container.current) container.current.classList.remove('drop-zone');

      const idDropped = e.dataTransfer.getData('text/plain');

      const groupIds = Object.keys(groupedAvatars);
      const groupId = groupIds.reduce(
        (r, k) =>
          r || groupedAvatars[k].find((t) => t.id === idDropped)?.id || '',
        ''
      );

      const inRoom = groupId || !!avatars.find((t) => t.id === idDropped);

      if (!inRoom && onEnterRoom) onEnterRoom(idDropped);
      if (groupId && onLeaveGroup) onLeaveGroup(groupId, idDropped);
    },
    [avatars, groupedAvatars, onEnterRoom, onLeaveGroup]
  );

  const handleRenameRoom = useCallback(
    (newName: string | undefined) => {
      if (!newName) return;
      roomsService.patch(officeId, id, { name: newName });
    },
    [roomsService, officeId, id]
  );

  const handleDeleteRoom = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(confirmDeleteMessage);
    if (confirmed) roomsService.delete(officeId, id);
  }, [confirmDeleteMessage, roomsService, officeId, id]);

  const isFavoriteRoom = useCallback(() => {
    const officeFavoriteRooms = favoriteRooms[officeId] || [];
    return officeFavoriteRooms.includes(id);
  }, [favoriteRooms, officeId, id]);

  const toggleFavorite = useCallback(async () => {
    const promise = isFavoriteRoom()
      ? removeFavoriteRoom(userInfoId, officeId, id)
      : addFavoriteRoom(userInfoId, officeId, id);
    const result = await promise;
    setFavoriteRooms(result);
  }, [
    addFavoriteRoom,
    removeFavoriteRoom,
    setFavoriteRooms,
    isFavoriteRoom,
    userInfoId,
    officeId,
    id,
  ]);

  const handleToggleMute = useCallback(async () => {
    if (!userInfoOfficeId || !userInfoRoomId) return;

    if (soundEnabled) {
      toggleMute(getRoomMeetingId(userInfoRoomId));
      return;
    }

    enterGroupMeeting(
      userInfoOfficeId,
      userInfoRoomId,
      '',
      false,
      false,
      false,
      false
    );
  }, [
    userInfoOfficeId,
    userInfoRoomId,
    soundEnabled,
    toggleMute,
    enterGroupMeeting,
  ]);

  const handleToggleSound = useCallback(async () => {
    if (!userInfoOfficeId || !userInfoRoomId) return;
    if (soundEnabled) enterOfficeRoom(userInfoOfficeId, userInfoRoomId);
    else
      enterGroupMeeting(
        userInfoOfficeId,
        userInfoRoomId,
        '',
        false,
        true,
        false,
        false
      );
  }, [
    userInfoOfficeId,
    userInfoRoomId,
    soundEnabled,
    enterOfficeRoom,
    enterGroupMeeting,
  ]);

  const spacing = 6;

  useEffect(() => {
    if (hidden || !officeId || !channelsLoaded || isDefaultRoom) return;
    joinOfficeChannel(officeId, id);
  }, [hidden, officeId, id, channelsLoaded, isDefaultRoom, joinOfficeChannel]);

  const roomName = useMemo(
    () =>
      canEdit ? (
        <EditableLabel
          textAlign="left"
          value={title}
          placeholder={title}
          onChange={handleRenameRoom}
        />
      ) : (
        <span className="room-name">{title}</span>
      ),
    [canEdit, handleRenameRoom, title]
  );

  const officePostButton = useMemo(
    () => (
      <>
        {!isDefaultRoom && !unreadMessagesCount && (
          <IconButton
            iconSize={22}
            padding={0}
            disabled={!channelsLoaded}
            onClick={handleOpenOfficePostsChannel}
          >
            <OfficePostsIcon />
            <Tooltip position="top" text="Posts" />
          </IconButton>
        )}

        {!isDefaultRoom && !!unreadMessagesCount && (
          <IconButton
            iconSize={22}
            padding={0}
            iconColor="red"
            disabled={!channelsLoaded}
            onClick={handleOpenOfficePostsChannel}
            className="animated-notification"
          >
            <OfficePostsBlankIcon />
            <span className="notification-count">
              {Math.min(unreadMessagesCount, 9)}
              {unreadMessagesCount > 9 && '+'}
            </span>
            <Tooltip position="top" text="Posts" />
          </IconButton>
        )}
      </>
    ),
    [
      channelsLoaded,
      handleOpenOfficePostsChannel,
      isDefaultRoom,
      unreadMessagesCount,
    ]
  );

  const headerActionsContainer = useMemo(
    () => (
      <ActionContainer className="room-actions-container">
        {officePostButton}
      </ActionContainer>
    ),
    [officePostButton]
  );

  const header = useMemo(
    () => (
      <TitleBar className="room-title-bar">
        {roomName}
        {headerActionsContainer}
      </TitleBar>
    ),
    [roomName, headerActionsContainer]
  );

  const groupList = useMemo(
    () =>
      Object.keys(groupedAvatars).map((groupId) => (
        <AvatarGroup
          key={groupId}
          groupId={groupId}
          list={groupedAvatars[groupId]}
          spacingInPixels={spacing}
          avatarSize={expanded ? 'large' : 'small'}
          onConnectionRequest={onConnectionRequest}
        />
      )),
    [expanded, groupedAvatars, onConnectionRequest]
  );

  const avatarList = useMemo(
    () =>
      avatars.map((item) => (
        <Avatar
          id={item.id}
          key={item.id}
          firstName={item.firstName}
          lastName={item.lastName}
          email={item.email}
          roleDescription={item.roleDescription}
          size={expanded ? 'large' : 'small'}
          picture={item.picture}
          status={item.status}
          statusMessage={item.statusMessage}
          mood={item.mood}
          draggable={!item.isGuest}
          showIcons
          showTooltip
          isRemote={item.isRemote}
          soundEnabled={item.soundEnabled}
          micEnabled={item.micEnabled}
          videoEnabled={item.videoEnabled}
          screenShared={item.screenShared}
          isMobile={item.isMobile}
          spacingInPixels={spacing}
          highlight={
            shouldHighlightFiltered &&
            filteredUsers.some((t) => t.id === item.id)
          }
          hasContexMenu
          onConnectionRequest={onConnectionRequest}
        >
          {userInfoId === item.id && <InviteToMeetingTip />}
          {firstOtherUserId === item.id && <ContextMenuTip />}
        </Avatar>
      )),
    [
      avatars,
      userInfoId,
      expanded,
      firstOtherUserId,
      shouldHighlightFiltered,
      filteredUsers,
      onConnectionRequest,
    ]
  );

  const content = useMemo(
    () => (
      <Content
        className="room-content"
        spacingInPixels={spacing}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {groupList}
        {avatarList}
      </Content>
    ),
    [
      avatarList,
      groupList,
      handleDragEnter,
      handleDragLeave,
      handleDragOver,
      handleDrop,
    ]
  );

  const micButton = useMemo(
    () =>
      isCurrent && (
        <IconButton
          iconSize={18}
          padding={0}
          disabled={roomSoundDisabled}
          onClick={handleToggleMute}
        >
          <MicIcon enabled={micEnabled} />
          {!roomSoundDisabled && <Tooltip position="top" text={micMessage} />}
        </IconButton>
      ),
    [handleToggleMute, isCurrent, micEnabled, micMessage, roomSoundDisabled]
  );

  const soundButton = useMemo(
    () =>
      isCurrent && (
        <IconButton
          iconSize={18}
          padding={0}
          disabled={roomSoundDisabled}
          onClick={handleToggleSound}
        >
          <SoundIcon enabled={soundEnabled} />
          {!roomSoundDisabled && <Tooltip position="top" text={soundMessage} />}
        </IconButton>
      ),
    [
      handleToggleSound,
      isCurrent,
      roomSoundDisabled,
      soundEnabled,
      soundMessage,
    ]
  );

  const footerLeftActionsContainer = useMemo(
    () => (
      <ActionContainer className="room-actions-container">
        {micButton}
        {soundButton}
      </ActionContainer>
    ),
    [micButton, soundButton]
  );

  const deleteButton = useMemo(
    () =>
      canEdit && (
        <IconButton iconSize={18} padding={0} onClick={handleDeleteRoom}>
          <DeleteIcon />
          <Tooltip position="top" text={deleteMessage} />
        </IconButton>
      ),
    [canEdit, deleteMessage, handleDeleteRoom]
  );

  const enterButton = useMemo(
    () => (
      <IconButton
        iconSize={18}
        padding={0}
        disabled={isCurrent}
        onClick={() => {
          if (!onEnterRoom || !userId) return;
          onEnterRoom(userId);
        }}
      >
        <EnterIcon />
        <Tooltip position="top" text={enterMessage} />
      </IconButton>
    ),
    [enterMessage, isCurrent, onEnterRoom, userId]
  );

  const favoriteButton = useMemo(
    () => (
      <IconButton iconSize={18} padding={0} onClick={toggleFavorite}>
        <FavoriteIcon checked={isFavoriteRoom()} />
      </IconButton>
    ),
    [isFavoriteRoom, toggleFavorite]
  );

  const resizeButton = useMemo(
    () => (
      <IconButton iconSize={17} padding={0} onClick={handleToggleExpanded}>
        {!expanded ? <ExpandBoxIcon /> : <RetractBoxIcon />}
        <Tooltip
          position="top"
          text={!expanded ? expandMessage : retractMessage}
        />
      </IconButton>
    ),
    [expandMessage, expanded, handleToggleExpanded, retractMessage]
  );

  const footerRightActionsContainer = useMemo(
    () => (
      <ActionContainer className="room-actions-container">
        {deleteButton}
        {enterButton}
        {favoriteButton}
        {resizeButton}
      </ActionContainer>
    ),
    [deleteButton, enterButton, favoriteButton, resizeButton]
  );

  const footer = useMemo(
    () => (
      <Footer>
        {footerLeftActionsContainer}
        {footerRightActionsContainer}
      </Footer>
    ),
    [footerLeftActionsContainer, footerRightActionsContainer]
  );

  return (
    <Container
      ref={container}
      id={id}
      className="room"
      isCurrent={isCurrent}
      statusColor={statusColors[userInfoStatus]}
      expanded={expanded}
      top={top}
      left={left}
      width={width}
      hidden={hidden}
    >
      {header}
      {content}
      {footer}
    </Container>
  );
};
