import { TextField } from '@material-ui/core';
import { styled } from '../../themes';

export const Container = styled(TextField)`
  margin: 7px 0px !important;

  .MuiInputBase-root {
    color: ${(props) => props.theme.textColor};
    font-family: inherit;
  }

  .MuiFormLabel-root {
    color: ${(props) => props.theme.textColor};
    opacity: 0.6;

    &.Mui-focused {
      color: ${(props) => props.theme.textColor};
      opacity: 1;
    }
  }

  .MuiInput-underline {
    :hover:not(.Mui-disabled):before {
      border-bottom-color: ${(props) => props.theme.textColor};
      opacity: 0.5;
    }

    :before {
      border-bottom-color: ${(props) => props.theme.textColor};
      opacity: 0.3;
    }
    :after {
      border-bottom-color: ${(props) => props.theme.textColor};
    }
  }
`;
