/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-plusplus */
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { v4 as uuid } from 'uuid';
import { Fade, Divider } from '@material-ui/core';

import defaultUserImage from '../../assets/default-user.png';
import { AppMessages } from '../../languages';
import { useLanguage } from '../../hooks/language';
import { useMattermost } from '../../hooks/mattermost';
import { useOffice } from '../../hooks/office';
import {
  MicIcon,
  VideoIcon,
  ScreenShareIcon,
  RemoteIcon,
  SoundIcon,
  EditPictureIcon,
  MoodIcon,
  ChatIcon,
  MeetingIcon,
  SmartphoneIcon,
} from '../Icons';
import { Tooltip } from '../Tooltip';

import {
  AvatarSize,
  AvatarStatus,
  AvatarMood,
  Container,
  AvatarConnection,
  Picture,
  RemoteIconContainer,
  MediaIconContainer,
  MicIconContainer,
  MoodIconContainer,
  EditButton,
  ContextMenu,
  ContextMenuItem,
} from './styles';

interface Props {
  id?: string;
  picture?: string;
  firstName: string;
  lastName: string;
  email?: string;
  roleDescription?: string;
  status?: AvatarStatus;
  statusMessage?: string;
  mood?: AvatarMood;
  size?: AvatarSize;
  showIcons?: boolean;
  showTooltip?: boolean;
  isRemote?: boolean;
  groupId?: string;
  micEnabled?: boolean;
  videoEnabled?: boolean;
  screenShared?: boolean;
  soundEnabled?: boolean;
  spacingInPixels?: number;
  draggable?: boolean;
  hasEditButton?: boolean;
  hasContexMenu?: boolean;
  hideBorder?: boolean;
  isMobile?: boolean;
  highlight?: boolean;
  onEditPicture?: (newImageData: string) => void;
  canEditPicture?: () => Promise<boolean>;
  onConnectionRequest?: (idFrom: string, idTo: string) => void | Promise<void>;
}

export const Avatar: React.FC<Props> = ({
  id = uuid(),
  picture = defaultUserImage,
  firstName,
  lastName,
  email,
  roleDescription,
  size = 'small',
  status = 'available',
  statusMessage,
  mood,
  showIcons = false,
  showTooltip = false,
  isRemote = false,
  groupId,
  soundEnabled = false,
  micEnabled = false,
  videoEnabled = false,
  screenShared = false,
  spacingInPixels = 6,
  draggable = false,
  hasEditButton = false,
  hasContexMenu = false,
  hideBorder = false,
  isMobile = false,
  highlight = false,
  onEditPicture,
  canEditPicture,
  onConnectionRequest,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const messages = useLanguage((state) => state.messages);
  const openDirectChannel = useMattermost((state) => state.openDirectChannel);
  const userInfoId = useOffice((state) => state.currentUserInfo?.id || '');
  const userInfoGroupId = useOffice(
    (state) => state.currentUserInfo?.groupId || ''
  );
  const [mouseOver, setMouseOver] = useState(false);
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLDivElement>();

  const isCurrentUser = userInfoId === id;

  const statusMessages = {
    available: messages[AppMessages.userInfoStatusAvailable],
    away: messages[AppMessages.userInfoStatusAway],
    busy: messages[AppMessages.userInfoStatusBusy],
    inactive: messages[AppMessages.userInfoStatusInactive],
  };

  const displayStatus = statusMessage || statusMessages[status];

  const fullName = `${firstName?.trim() || ''} ${lastName?.trim() || ''}`;
  const displayName = fullName.trim();
  const tooltipText = `${displayName}\n(${displayStatus})`;

  const alreadyAtTheSameMeeting = !!groupId && groupId === userInfoGroupId;

  const hasInviteToMeetingOption =
    !isCurrentUser &&
    !alreadyAtTheSameMeeting &&
    draggable &&
    (!groupId || !!userInfoGroupId);

  const hasRequestToJoinTheMeetingOption =
    !isCurrentUser && draggable && !!groupId;

  const hasJoinTheMeetingOption = !isCurrentUser && !draggable && !!groupId;

  const handleMouseEnter = useCallback(() => {
    if (!hasEditButton) return;
    setMouseOver(true);
  }, [hasEditButton]);

  const handleMouseLeave = useCallback(() => {
    if (!hasEditButton) return;
    setMouseOver(false);
  }, [hasEditButton]);

  const handleSelectFile = useCallback(() => {
    if (!inputFileRef.current) return;
    inputFileRef.current.click();
  }, []);

  const handleEditButtonClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      if (canEditPicture && !(await canEditPicture())) return;

      handleSelectFile();
    },
    [handleSelectFile, canEditPicture]
  );

  const handlePictureChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      window.URL = window.URL || window.webkitURL;
      if (
        !event.target ||
        !event.target.files ||
        event.target.files.length === 0
      )
        return;

      const imageData = window.URL.createObjectURL(event.target.files[0]);
      if (inputFileRef.current) inputFileRef.current.value = '';
      if (onEditPicture) onEditPicture(imageData);
    },
    [onEditPicture]
  );

  const handleDragStart = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.stopPropagation();

      // eslint-disable-next-line no-unused-expressions
      containerRef.current?.classList?.add('dragging');

      const dragContainer =
        document.getElementById('drag-container') || ({} as HTMLElement);
      const element = (containerRef.current?.cloneNode(true) ||
        {}) as HTMLElement;

      dragContainer.appendChild(element);

      const connections = dragContainer.getElementsByClassName(
        'avatar-connection'
      );

      for (let i = 0; i < connections.length; i++) {
        connections[i].classList.add('hide-on-drag');
      }

      const devicePixelRatio = window.devicePixelRatio || 1;

      e.dataTransfer.setDragImage(
        element,
        (element.clientWidth / 2) * devicePixelRatio,
        (element.clientHeight / 2) * devicePixelRatio
      );

      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.setData('text/plain', id);
    },
    [id]
  );

  const handleDragEnd = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    // eslint-disable-next-line no-unused-expressions
    containerRef.current?.classList?.remove('dragging');

    const dragContainer =
      document.getElementById('drag-container') || ({} as HTMLElement);

    for (let i = dragContainer.childNodes.length - 1; i >= 0; i--) {
      dragContainer.removeChild(dragContainer.childNodes.item(i));
    }
  }, []);

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (containerRef.current) containerRef.current.classList.add('drop-zone');
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (containerRef.current)
      containerRef.current.classList.remove('drop-zone');
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (containerRef.current)
        containerRef.current.classList.remove('drop-zone');
      const idDropped = e.dataTransfer.getData('text/plain');
      if (idDropped !== id && onConnectionRequest)
        onConnectionRequest(idDropped, id);
    },
    [id, onConnectionRequest]
  );

  const handleOpenContextMenu = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (isCurrentUser || !hasContexMenu) return;
      e.preventDefault();
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
    },
    [isCurrentUser, hasContexMenu]
  );

  const handleCloseContextMenu = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  const handleOpenChat = useCallback(() => {
    if (!email) return;
    openDirectChannel(email);
    handleCloseContextMenu();
  }, [email, openDirectChannel, handleCloseContextMenu]);

  const handleInviteToMeeting = useCallback(() => {
    if (!onConnectionRequest) return;
    onConnectionRequest(id, userInfoId);
    handleCloseContextMenu();
  }, [onConnectionRequest, handleCloseContextMenu, userInfoId, id]);

  const handleRequestToJoinTheMeeting = useCallback(() => {
    if (!onConnectionRequest) return;
    onConnectionRequest(userInfoId, id);
    handleCloseContextMenu();
  }, [onConnectionRequest, handleCloseContextMenu, userInfoId, id]);

  useEffect(() => {
    if (!containerRef.current) return;

    /*if (!highlight) {
      containerRef.current.classList.remove('highlight');
      return;
    }

    containerRef.current.classList.add('highlight');*/
  }, [highlight]);

  useEffect(() => {
    let dragContainer = document.getElementById('drag-container');
    if (!dragContainer) {
      dragContainer = document.createElement('div');
      dragContainer.id = 'drag-container';
      document.getElementsByTagName('body')[0].appendChild(dragContainer);
      dragContainer.style.position = 'absolute';
      dragContainer.style.display = 'flex';
      dragContainer.style.top = '-100vh';
      dragContainer.style.padding = '50px';
    }

    return () => {
      if (dragContainer) {
        for (let i = dragContainer.childNodes.length - 1; i >= 0; i--) {
          dragContainer.removeChild(dragContainer.childNodes.item(i));
        }
      }
    };
  }, []);

  const rightConnection = useMemo(
    () => (
      <AvatarConnection
        className="avatar-connection direction-right"
        size={size}
        spacingInPixels={spacingInPixels}
      >
        <svg>
          <circle className="self" />
          <circle className="other" />
          <path />
        </svg>
      </AvatarConnection>
    ),
    [size, spacingInPixels]
  );

  const bottomConnection = useMemo(
    () => (
      <AvatarConnection
        className="avatar-connection direction-down"
        size={size}
        spacingInPixels={spacingInPixels}
      >
        <svg className="direction-down">
          <circle className="self" />
          <circle className="other" />
          <path />
        </svg>
      </AvatarConnection>
    ),
    [size, spacingInPixels]
  );

  const userPicture = useMemo(
    () => (
      <Picture
        src={picture}
        alt={displayName}
        size={size}
        status={status}
        hideBorder={hideBorder}
      />
    ),
    [picture, displayName, size, status, hideBorder]
  );

  const remoteIconContainer = useMemo(
    () =>
      showIcons &&
      isRemote && (
        <RemoteIconContainer size={size} status={status}>
          <RemoteIcon />
        </RemoteIconContainer>
      ),
    [showIcons, isRemote, size, status]
  );

  const moodIconContainer = useMemo(
    () =>
      showIcons &&
      mood && (
        <MoodIconContainer size={size} status={status}>
          <MoodIcon mood={mood} outlined />
        </MoodIconContainer>
      ),
    [showIcons, mood, size, status]
  );

  const mediaIconContainer = useMemo(
    () =>
      showIcons && (
        <MediaIconContainer size={size} status={status}>
          {!isMobile && (
            <>
              {screenShared && <ScreenShareIcon enabled />}
              {!screenShared && videoEnabled && <VideoIcon enabled />}
              {!screenShared && !videoEnabled && (
                <SoundIcon enabled={soundEnabled} />
              )}
            </>
          )}
          {isMobile && <SmartphoneIcon />}
        </MediaIconContainer>
      ),
    [
      showIcons,
      size,
      status,
      screenShared,
      videoEnabled,
      soundEnabled,
      isMobile,
    ]
  );

  const micIconContainer = useMemo(
    () =>
      showIcons &&
      soundEnabled && (
        <MicIconContainer size={size} status={status}>
          <MicIcon enabled={micEnabled} />
        </MicIconContainer>
      ),
    [showIcons, soundEnabled, size, status, micEnabled]
  );

  const toolTip = useMemo(() => showTooltip && <Tooltip text={tooltipText} />, [
    showTooltip,
    tooltipText,
  ]);

  const dragMask = useMemo(
    () => (
      <div
        className="drag-mask"
        draggable={draggable}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleOpenContextMenu}
      >
        {toolTip}
      </div>
    ),
    [
      draggable,
      handleDragStart,
      handleDragEnd,
      handleDragEnter,
      handleDragLeave,
      handleDragOver,
      handleDrop,
      handleMouseEnter,
      handleMouseLeave,
      handleOpenContextMenu,
      toolTip,
    ]
  );

  const editButton = useMemo(
    () =>
      hasEditButton &&
      mouseOver && (
        <EditButton
          className="edit-button"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleEditButtonClick}
        >
          <EditPictureIcon />
        </EditButton>
      ),
    [
      hasEditButton,
      mouseOver,
      handleMouseEnter,
      handleMouseLeave,
      handleEditButtonClick,
    ]
  );

  const inputFile = useMemo(
    () => (
      <input
        ref={inputFileRef}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onClick={(e) => e.stopPropagation()}
        onChange={handlePictureChange}
      />
    ),
    [handlePictureChange]
  );

  const contextMenuHeader = useMemo(
    () => (
      <div className="context-menu-header">
        <img src={picture} alt={displayName} width="50" height="50" />
        <div className="context-menu-user-info">
          <span className="context-menu-name">{displayName}</span>
          {!!roleDescription && (
            <span className="context-menu-role-description">
              {roleDescription}
            </span>
          )}
          <span className="context-menu-email">{email}</span>
        </div>
      </div>
    ),
    [displayName, picture, roleDescription, email]
  );

  const contextMenuChat = useMemo(
    () =>
      !!email && [
        <Divider key="chatDivisor" />,
        <ContextMenuItem key="chat" onClick={handleOpenChat}>
          <ChatIcon />
          {messages[AppMessages.componentAvatarOpenChatConversation]}
        </ContextMenuItem>,
      ],
    [email, handleOpenChat, messages]
  );

  const contextMenuInviteToMeeting = useMemo(
    () =>
      hasInviteToMeetingOption && [
        <Divider key="inviteToMeetingDivisor" />,
        <ContextMenuItem
          key="inviteToMeeting"
          onClick={handleInviteToMeeting}
          disabled={alreadyAtTheSameMeeting}
        >
          <MeetingIcon />
          {messages[AppMessages.componentAvatarInviteToMeeting]}
        </ContextMenuItem>,
      ],
    [
      alreadyAtTheSameMeeting,
      handleInviteToMeeting,
      hasInviteToMeetingOption,
      messages,
    ]
  );

  const contextMenuRequestToJoinTheMeeting = useMemo(
    () =>
      hasRequestToJoinTheMeetingOption && [
        <Divider key="requestToJoinTheMeetingDivisor" />,
        <ContextMenuItem
          key="requestToJoinTheMeeting"
          onClick={handleRequestToJoinTheMeeting}
          disabled={alreadyAtTheSameMeeting}
        >
          <MeetingIcon />
          {messages[AppMessages.componentAvatarRequestToJoinTheMeeting]}
        </ContextMenuItem>,
      ],
    [
      alreadyAtTheSameMeeting,
      handleRequestToJoinTheMeeting,
      hasRequestToJoinTheMeetingOption,
      messages,
    ]
  );

  const contextMenuJoinTheMeeting = useMemo(
    () =>
      hasJoinTheMeetingOption && [
        <Divider key="joinTheMeetingDivisor" />,
        <ContextMenuItem
          key="joinTheMeeting"
          onClick={handleRequestToJoinTheMeeting}
          disabled={alreadyAtTheSameMeeting}
        >
          <MeetingIcon />
          {messages[AppMessages.componentAvatarJoinTheMeeting]}
        </ContextMenuItem>,
      ],
    [
      alreadyAtTheSameMeeting,
      handleRequestToJoinTheMeeting,
      hasJoinTheMeetingOption,
      messages,
    ]
  );

  const contextMenu = useMemo(
    () =>
      Boolean(anchorEl) && (
        <ContextMenu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseContextMenu}
          TransitionComponent={Fade}
        >
          {contextMenuHeader}
          {contextMenuChat}
          {contextMenuInviteToMeeting}
          {contextMenuRequestToJoinTheMeeting}
          {contextMenuJoinTheMeeting}
        </ContextMenu>
      ),
    [
      anchorEl,
      contextMenuChat,
      contextMenuHeader,
      contextMenuInviteToMeeting,
      contextMenuJoinTheMeeting,
      contextMenuRequestToJoinTheMeeting,
      handleCloseContextMenu,
    ]
  );

  return (
    <Container id={id} ref={containerRef} className="avatar" size={size}>
      {rightConnection}
      {bottomConnection}
      {userPicture}
      {remoteIconContainer}
      {moodIconContainer}
      {mediaIconContainer}
      {micIconContainer}
      {dragMask}
      {editButton}
      {inputFile}
      {contextMenu}
      {children}
    </Container>
  );
};
