import React, { useCallback, useMemo, useState } from 'react';

import { createContext, useContextSelector } from './context';
import { ActivityLog, ActivityLogsQuery, useApi } from './api';
import { useModels, defaultRoom } from './model';

type BooleanString = 'yes' | 'no';

type CustomActivityLog = ActivityLog & {
  logId: string;
  userFullName: string;
  userEmail?: string;
  officeName: string;
  roomName: string;
  isRemote: BooleanString;
  statusMessage: string;
  inMeeting: BooleanString;
  meetingId?: string;
  meetingStart?: string;
  meetingEnd?: string;
  meetingTotalTimeInSeconds?: number;
  meetingTotalTimeInMinutes?: number;
  meetingTotalTimeInHours?: number;
  microphoneEnabled: BooleanString;
  cameraEnabled: BooleanString;
  screenSharingEnabled: BooleanString;
  logCreatedAt: string;
  logFinishedAt: string;
  logDate: string;
  logYear: number;
  logMonth: number;
  logDay: number;
  timeInSeconds: number;
  timeInMinutes: number;
  timeInHours: number;
};

interface ReportsContextData {
  reportsPanelIsOpen: boolean;
  activityLogs: CustomActivityLog[];
  openReportsPanel: () => void;
  closeReportsPanel: () => void;
  loadActivityLogs: (query: Partial<ActivityLogsQuery>) => Promise<void>;
}

interface Duration {
  createdAt: Date;
  finishedAt: Date;
  time: number;
}

function toBooleanString<T>(value: T): BooleanString {
  return value ? 'yes' : 'no';
}

function getDuration(log: ActivityLog, timeLimit?: number): Duration {
  const createdAt = new Date(log.createdAt);
  const time = log.time || (timeLimit || Date.now()) - createdAt.getTime();
  const finishedAt = new Date(log.createdAt);
  finishedAt.setTime(finishedAt.getTime() + time);
  return { createdAt, finishedAt, time };
}

const ReportsContext = createContext({} as ReportsContextData);

export const ReportsProvider: React.FC = ({ children }) => {
  const { searchActivityLogs } = useApi();
  const users = useModels((state) => state.users);
  const offices = useModels((state) => state.offices);

  const [reportsPanelIsOpen, setReportsPanelIsOpen] = useState(false);
  const [activityLogs, setActivityLogs] = useState<CustomActivityLog[]>([]);

  const openReportsPanel = useCallback(() => {
    setReportsPanelIsOpen(true);
  }, []);

  const closeReportsPanel = useCallback(() => {
    setReportsPanelIsOpen(false);
  }, []);

  const loadActivityLogs = useCallback(
    async (query: Partial<ActivityLogsQuery>) => {
      const result = await searchActivityLogs(query);
      const currentTime = Date.now();

      const meetingsDuration = result.reduce((res, log) => {
        if (!log.group) return res;
        const { createdAt, finishedAt, time } = getDuration(log, currentTime);
        res[log.group] = res[log.group] || { createdAt, finishedAt, time };
        if (createdAt < res[log.group].createdAt)
          res[log.group].createdAt = createdAt;
        if (finishedAt > res[log.group].finishedAt)
          res[log.group].finishedAt = finishedAt;
        res[log.group].time =
          res[log.group].finishedAt.getTime() -
          res[log.group].createdAt.getTime();
        return res;
      }, {} as { [id: string]: Duration });

      const custom: CustomActivityLog[] = result.map((log) => {
        const { createdAt, finishedAt, time } = getDuration(log, currentTime);
        const logId = log.id;
        const user = users.find((t) => t.id === log.user);
        const office = offices.find((t) => t.id === log.office);
        const room = office?.rooms?.find((t) => t.id === log.room);
        const isRemote = toBooleanString(log.remote);
        const statusMessage = log.message || '';
        const inMeeting = toBooleanString(log.group);
        const meetingId = log.group;
        const microphoneEnabled = toBooleanString(log.mic);
        const cameraEnabled = toBooleanString(log.video);
        const screenSharingEnabled = toBooleanString(log.screenShared);
        const meetingDuration = meetingsDuration[meetingId || ''];
        const meetingStart = meetingDuration?.createdAt?.toLocaleString();
        const meetingEnd = meetingDuration?.finishedAt?.toLocaleString();
        const meetingTime = meetingDuration?.time;
        const meetingTotalTimeInSeconds = meetingTime
          ? parseFloat((meetingTime / 1000).toFixed(2))
          : undefined;
        const meetingTotalTimeInMinutes = meetingTime
          ? parseFloat((meetingTime / (60 * 1000)).toFixed(2))
          : undefined;
        const meetingTotalTimeInHours = meetingTime
          ? parseFloat((meetingTime / (60 * 60 * 1000)).toFixed(2))
          : undefined;

        return {
          ...log,
          logId,
          userFullName: `${user?.firstName || ''} ${
            user?.lastName || ''
          }`.trim(),
          userEmail: user?.email,
          officeName: office?.name || 'default',
          roomName: room?.name || defaultRoom.id,
          isRemote,
          statusMessage,
          inMeeting,
          meetingId,
          meetingStart,
          meetingEnd,
          meetingTotalTimeInSeconds,
          meetingTotalTimeInMinutes,
          meetingTotalTimeInHours,
          microphoneEnabled,
          cameraEnabled,
          screenSharingEnabled,
          createdAt,
          logCreatedAt: createdAt.toLocaleString(),
          logFinishedAt: finishedAt.toLocaleString(),
          logDate: createdAt.toLocaleDateString(),
          logYear: createdAt.getFullYear(),
          logMonth: createdAt.getMonth() + 1,
          logDay: createdAt.getDate(),
          timeInSeconds: time / 1000,
          timeInMinutes: time / (60 * 1000),
          timeInHours: time / (60 * 60 * 1000),
        };
      });

      setActivityLogs(custom);
    },
    [searchActivityLogs, users, offices]
  );

  const contextValue = useMemo<ReportsContextData>(
    () => ({
      reportsPanelIsOpen,
      activityLogs,
      openReportsPanel,
      closeReportsPanel,
      loadActivityLogs,
    }),
    [
      reportsPanelIsOpen,
      activityLogs,
      openReportsPanel,
      closeReportsPanel,
      loadActivityLogs,
    ]
  );

  return (
    <ReportsContext.Provider value={contextValue}>
      {children}
    </ReportsContext.Provider>
  );
};

export function useReports<TResult>(
  selector: (state: ReportsContextData) => TResult
): TResult {
  return useContextSelector(ReportsContext, selector);
}
