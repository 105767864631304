import React from 'react';

import { useTransition } from 'react-spring';

import ToastInvite from './ToastInvite';
import { ToastInvites } from '../../hooks/toastInvite';
import { Container } from './styles';

interface ToastInviteContainerPropos {
  invites: ToastInvites;
}

const ToastInviteContainer: React.FC<ToastInviteContainerPropos> = ({
  invites,
}) => {
  const inviteList = Object.keys(invites).map((id) => invites[id]);
  const messagesWithTransitions = useTransition(
    inviteList,
    (message) => message.id,
    {
      from: { right: '-120%', opacity: 0 },
      enter: { right: '0%', opacity: 1 },
      leave: { right: '-120%', opacity: 0 },
    }
  );
  return (
    <Container hidden={inviteList.length === 0}>
      {messagesWithTransitions.map(({ item, key, props }) => (
        <ToastInvite key={key} style={props} invite={item} />
      ))}
    </Container>
  );
};

export default ToastInviteContainer;
