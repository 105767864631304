/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { useIntl } from 'react-intl';

import logoImage from '../../assets/images/logo_01.png';
import peopleImage from '../../assets/images/people_001.png';
import telluriaLogo from '../../assets/images/telluria_logo_small.png';
import { AppMessages } from '../../languages';

import {
  Container,
  Logo,
  PageImage,
  LogoImage,
  Content,
  Title,
  Subtitle,
  Paragraph,
  List,
  ListItem,
  CopyrightContainer,
} from './styles';

const PrivacyPolicy: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageImage src={peopleImage} />

      <Container>
        <Logo>
          <LogoImage src={logoImage} />
        </Logo>

        <br />

        <Content>
          <br />

          <Title>Política de Privacidade</Title>

          <br />

          <Paragraph>
            Aqui no Proseia proteger a sua privacidade é muito importante para
            nós. Esta política de privacidade foi projetada para ajudá-lo a
            entender quais informações coletamos sobre você, incluindo dados
            pessoais no sentido do Regulamento Geral de Proteção de Dados
            (&ldquo;GDPR&rdquo;) e outras informações que o identificam
            diretamente ou fazem você identificável (&ldquo;informações
            pessoais&rdquo;) e como usamos essas informações.
          </Paragraph>

          <Paragraph>
            Esta Política de Privacidade se aplica a todos os visitantes e
            usuários de produtos e serviços do Proseia (coletivamente, os
            &ldquo;Serviços&rdquo;).
          </Paragraph>

          <Paragraph>
            Por favor, leia esta política de privacidade com cuidado. Ao acessar
            ou usar qualquer parte dos Serviços, você reconhece que foi
            informado de nossas práticas com relação às suas informações
            pessoais e outros dados. Se você não concordar com esta Política de
            Privacidade, interrompa imediatamente o uso dos Serviços.
          </Paragraph>

          <br />

          <Subtitle id="about-our-infrastructure">
            Sobre nossa infraestrutura
          </Subtitle>

          <Paragraph>
            Para fornecer os Serviços a você, o Proseia utiliza de diversos
            servidores hospedados em plataformas fornecedoras de infraestrutura
            em nuvem como: AWS(Amazon), GCP(Google), Azure(Microsoft),
            Heroku(Sales Force), UPCloud, etc.
          </Paragraph>

          <Paragraph>
            Servidores estes que contam com uma política de acesso restrita e
            limitada aos responsáveis pela manutenção da infraestrutura da
            plataforma.
          </Paragraph>

          <br />

          <Subtitle id="messages-sent-by-chat">
            Mensagens enviadas pelo chat
          </Subtitle>

          <Paragraph>
            Para que o histórico de mensagens individual fique disponível
            independente do dispositivo/terminal pelo qual o usuário acessa o
            Proseia, todas as mensagens são armazenadas em nossos servidores
            citados previamente na seção de infraestrutura.
          </Paragraph>

          <Paragraph>
            Asseguramos que nenhum usuário pode visualizar, editar, excluir ou
            alterar mensagens de outros usuários (ou de grupos privados dos
            quais ele não participa). Pelo menos não sem conhecer a senha de
            acesso do outro ou sem ter acesso a um terminal/dispositivo onde
            outro usuário tenha deixado uma sessão do Proseia aberta.
          </Paragraph>

          <Paragraph>
            Não monitoramos nem processamos o conteúdo das mensagens, assim como
            não as compartilhamos com terceiros.
          </Paragraph>

          <br />

          <Subtitle id="user-activity-logs">
            Logs de atividade de usuários
          </Subtitle>

          <Paragraph>
            Armazenamos os logs das interações públicas (que são visíveis para
            todos os outros usuários logados em um ambiente) que cada usuário
            realiza na plataforma, dentre elas: mudança de sala, alteração de
            status, alteração da descrição do status, entrada e saída de
            reuniões, ativação/desativação de microfone/câmera/compartilhamento
            de tela, mudança de descrição da função, alteração do humor, login e
            logoff. Esses logs podem ser armazenados pelo tempo que acharmos
            necessário, e só serão utilizados para obtermos métricas de
            utilização da plataforma.
          </Paragraph>

          <br />

          <Subtitle id="meetings">Reuniões de áudio/vídeo</Subtitle>

          <Paragraph>
            Durante uma reunião processamos informações de rede e uso, incluindo
            endereços IP dos participantes da reunião.
          </Paragraph>

          <Paragraph>
            Armazenamos informações referentes a quais usuários participaram de
            reuniões, e por quanto tempo (para fins de métricas de utilização já
            citados anteriormente), mas não monitoramos nem armazenamos o
            conteúdo das reuniões, seja áudio, vídeo ou texto. Sendo assim, o
            conteúdo das reuniões só é acessível aos participantes das mesmas e
            apenas durante sua realização.
          </Paragraph>

          <br />

          <Subtitle id="privacy-of-user-access-passwords">
            Privacidade das senhas de acesso dos usuários
          </Subtitle>

          <Paragraph>
            As senhas de acesso dos nossos usuários são armazenadas utilizando
            criptografia não reversível, seguindo os padrões da indústria
            amplamente aceitos para este tipo de tratativa. Como não há maneira
            de recuperar a senha dos usuários, caso um usuário esqueça sua
            senha, ele deverá utilizar a opção “Esqueci minha senha” (existente
            na página de login) para receber um e-mail com um link para cadastro
            de uma nova senha (processo necessário para garantir que quem está
            tentando trocar a senha é o dono do e-mail).
          </Paragraph>

          <br />

          <Subtitle id="information-shared-with-third-parties">
            Informações compartilhadas com terceiros e para quais finalidades
          </Subtitle>

          <Paragraph>
            Não vendemos, trocamos ou de outra forma transferimos as informações
            que coletamos de você para terceiros não afiliados. No entanto,
            podemos compartilhar as informações que coletamos de você com
            terceiros de confiança que nos auxiliam na operação de nossos
            Serviços, conduzindo nossos negócios ou atendendo você, desde que
            essas partes concordem em manter suas informações confidenciais e
            seguras. Esses terceiros confiáveis ​​incluem:
          </Paragraph>

          <Paragraph>
            <List>
              <ListItem>
                Prestadores de serviços, subcontratados, parceiros,
                fornecedores, consultores e outros que nos ajudem em qualquer um
                dos propósitos desta Política de Privacidade, incluindo a
                realização de serviços em nosso nome, como identificação e
                veiculação de anúncios direcionados, envio de e-mail ou medição
                do tráfego do site. Esses provedores de serviço não têm
                permissão para usar as informações coletadas em nosso nome,
                exceto para nos ajudar a conduzir e melhorar nossos negócios.
              </ListItem>

              <ListItem>
                Nossas afiliadas, empresas controladoras, subsidiárias e outras
                empresas relacionadas, todas para os fins desta Política de
                Privacidade;
              </ListItem>

              <ListItem>
                Terceiros para responder ou cumprir, a nosso exclusivo critério,
                uma ordem judicial, intimação, aplicação da lei ou outra
                solicitação do governo (com ou sem aviso prévio, a nosso
                critério) de acordo com a lei aplicável;
              </ListItem>

              <ListItem>
                Compradores, sucessores ou outros em conexão com uma fusão,
                alienação, reestruturação, reorganização, dissolução ou outra
                venda ou transferência de parte ou de todos os nossos negócios
                ou ativos. Avisaremos antes que suas informações pessoais sejam
                transferidas e se tornem sujeitas a uma política de privacidade
                diferente;
              </ListItem>

              <ListItem>
                Terceiros, a nosso critério, para: (i) cumprir qualquer lei ou
                regulamento aplicável, (ii) fazer cumprir esta Política de
                Privacidade, incluindo a investigação de possíveis violações,
                (iii) investigar e nos defender contra quaisquer reclamações ou
                alegações de terceiros, ou (iv) proteger contra danos aos
                direitos, propriedade ou segurança nossos, os sites, os
                serviços, outros usuários dos serviços ou terceiros (incluindo
                perda financeira, ou em conexão com a prevenção de fraude ou
                atividade ilegal, e/ou para fazer cumprir nossos outros acordos
                com você); e
              </ListItem>

              <ListItem>Outros terceiros com o seu consentimento.</ListItem>
            </List>
          </Paragraph>

          <br />

          <Subtitle id="how-we-protect-your-personal-information">
            Como protegemos suas informações pessoais
          </Subtitle>

          <Paragraph>
            Implementamos uma variedade de medidas de segurança para manter a
            segurança de suas informações pessoais enviadas contra perda, uso
            indevido e acesso ou divulgação não autorizados. Essas etapas levam
            em consideração a sensibilidade das informações que coletamos,
            processamos e armazenamos, bem como o estado atual da tecnologia.
            Todas as informações que consideramos potencialmente confidenciais
            são transmitidas por meio de canais criptografados e seguimos os
            padrões da indústria amplamente aceitos para proteger os dados
            coletados por nós, tanto durante a transmissão quanto após o
            recebimento. Dada a natureza das comunicações e da tecnologia de
            processamento de informações, não podemos garantir que as
            informações durante a transmissão pela Internet ou enquanto
            armazenadas em nossos sistemas ou de outra forma sob nossos cuidados
            estarão absolutamente protegidas da intrusão de terceiros.
          </Paragraph>

          <br />

          <Subtitle id="third-party-links-and-websites">
            Links e sites de terceiros
          </Subtitle>

          <Paragraph>
            Esta Política de Privacidade não se aplica a quaisquer sites,
            serviços ou aplicativos de terceiros, mesmo que sejam acessíveis por
            meio de nossos Sites e/ou Serviços. Esta Política de Privacidade se
            aplica apenas aos nossos Sites e Serviços, portanto, quando você
            acessa um link para outros sites, deve ler essas políticas de
            privacidade separadas e independentes. Não temos responsabilidade ou
            obrigação pelo conteúdo e atividades desses sites vinculados. No
            entanto, procuramos proteger a integridade de nossos sites e
            serviços e agradecemos qualquer feedback sobre os sites e serviços.
          </Paragraph>

          <br />
        </Content>

        <br />

        <CopyrightContainer>
          {formatMessage(
            { id: AppMessages.copyrightText },
            { year: new Date().getFullYear() }
          )}
          <LogoImage src={telluriaLogo} />
        </CopyrightContainer>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
