import { ProseiaTheme } from '.';

const theme: ProseiaTheme = {
  title: 'dark',
  background: '#191D23',
  textColor: '#e0e0e0',
  linkColor: 'orangered',
  mentionColor: 'orangered',
  iconColor: '#e0e0e0',
  boxColor: '#343940',
  boxGlow: '0px 0px 3px 1px rgba(0, 0, 0, 0.4)',
  formComponentGlow: '0px 0px 3px 1px rgba(255, 255, 255, 0.4)',
  avatarGlow: '0px 0px 5px 3px rgba(255, 255, 255, 0.5)',
  focusGlow: '0px 0px 10px 6px rgba(255, 255, 255, 0.5)',
};

export default theme;
