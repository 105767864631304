import React from 'react';
import { keyframes } from 'styled-components';

import {
  MdMic,
  MdMicOff,
  MdVolumeOff,
  MdPhone,
  MdHome,
  MdNotifications,
  MdNotificationsOff,
  MdVideocam,
  MdVideocamOff,
  MdClose,
  MdSend,
  MdCancel,
  MdAttachFile,
  MdGroup,
  MdAddCircle,
  MdShare,
  MdVideoCall,
  MdPrint,
  MdKeyboardBackspace,
  MdReply,
  MdSmartphone,
} from 'react-icons/md';
import { TiArrowMaximise, TiArrowMinimise, TiPin } from 'react-icons/ti';
import {
  FaRegWindowMinimize,
  FaLock,
  FaHashtag,
  FaLaugh,
  FaSmile,
  FaMeh,
  FaFrown,
  FaSadTear,
  FaQuestionCircle,
  FaRegLaugh,
  FaRegSmile,
  FaRegMeh,
  FaRegFrown,
  FaRegSadTear,
  FaRegQuestionCircle,
  FaEdge,
  FaChrome,
  FaArrowDown,
  FaArrowUp,
  FaComments,
} from 'react-icons/fa';
import { FiMonitor } from 'react-icons/fi';
import { BsFillChatFill } from 'react-icons/bs';
import { GrEmoji } from 'react-icons/gr';
import { BiCustomize, BiLineChart } from 'react-icons/bi';
import { ImSpinner9, ImCamera } from 'react-icons/im';
import { HiUserAdd } from 'react-icons/hi';

// import { ReactComponent as ProseiaLoadingSvg } from '../../assets/icons/proseia_loading.svg';
import { ReactComponent as AdminPanelSettingsSvg } from '../../assets/icons/admin_panel_settings_black_48dp.svg';
import { ReactComponent as AccountCircleSvg } from '../../assets/icons/account_circle_black_48dp.svg';
import { ReactComponent as NoAccountsSvg } from '../../assets/icons/no_accounts_black_48dp.svg';
import { ReactComponent as GridViewSvg } from '../../assets/icons/grid_view_black_48dp.svg';
import { ReactComponent as ChatSvg } from '../../assets/icons/chat_black_48dp.svg';
import { ReactComponent as DeleteSvg } from '../../assets/icons/delete_black_48dp.svg';
import { ReactComponent as DeleteForeverSvg } from '../../assets/icons/delete_forever_black_48dp.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/edit_black_48dp.svg';
import { ReactComponent as MeetingSvg } from '../../assets/icons/video_camera_front_black_48dp.svg';
import { ReactComponent as FeedSvg } from '../../assets/icons/feed_black_48dp.svg';
import { ReactComponent as FeedBlankSvg } from '../../assets/icons/feed_blank_black_48dp.svg';
import { ReactComponent as HeadsetSvg } from '../../assets/icons/headphones_black_48dp.svg';
import { ReactComponent as HeadsetOffSvg } from '../../assets/icons/headphones_off_black_48dp.svg';
import { ReactComponent as StarSvg } from '../../assets/icons/star_black_48dp.svg';
import { ReactComponent as StarBorderSvg } from '../../assets/icons/star_border_black_48dp.svg';
import { ReactComponent as LocationSvg } from '../../assets/icons/location_black_48dp.svg';
import { ReactComponent as LocationMobileSvg } from '../../assets/icons/location_mobile_black_48dp.svg';
import { ReactComponent as LogoutSvg } from '../../assets/icons/logout_black_48dp.svg';
import { ReactComponent as LoginSvg } from '../../assets/icons/login_black_48dp.svg';
import { ReactComponent as LockSvg } from '../../assets/icons/lock_black_48dp.svg';
import { ReactComponent as LockOpenSvg } from '../../assets/icons/lock_open_black_48dp.svg';
import { ReactComponent as MailSvg } from '../../assets/icons/mail_black_48dp.svg';
import { ReactComponent as ManageAccountsSvg } from '../../assets/icons/manage_accounts_black_48dp.svg';
import { ReactComponent as PersonSvg } from '../../assets/icons/person_black_48dp.svg';
import { ReactComponent as SettingsSvg } from '../../assets/icons/settings_black_48dp.svg';
import { ReactComponent as OpenInFullSvg } from '../../assets/icons/open_in_full_black_48dp.svg';
import { ReactComponent as CloseFullscreenSvg } from '../../assets/icons/close_fullscreen_black_48dp.svg';
import { ReactComponent as LightModeSvg } from '../../assets/icons/light_mode_black_48dp.svg';
import { ReactComponent as DarkModeSvg } from '../../assets/icons/dark_mode_black_48dp.svg';
import { ReactComponent as RefreshSvg } from '../../assets/icons/refresh_black_48dp.svg';
import { ReactComponent as ExtensionSvg } from '../../assets/icons/extension_black_48dp.svg';
import { ReactComponent as ExtensionOffSvg } from '../../assets/icons/extension_off_black_48dp.svg';

import { AvatarMood } from '../Avatar/styles';
import { styled } from '../../themes';

interface IconStatus {
  enabled: boolean;
}

export const MicIcon: React.FC<IconStatus> = ({ enabled }) =>
  enabled ? <MdMic /> : <MdMicOff />;

export const SoundIcon: React.FC<IconStatus> = ({ enabled }) =>
  enabled ? <HeadsetSvg /> : <HeadsetOffSvg />;
// enabled ? <MdHeadset /> : <MdVolumeOff />;

export const VideoIcon: React.FC<IconStatus> = ({ enabled }) =>
  enabled ? <MdVideocam /> : <MdVideocamOff />;

export const ScreenShareIcon: React.FC<IconStatus> = ({ enabled }) =>
  enabled ? <FiMonitor /> : <FiMonitor />;

export const HangUpIcon: React.FC<IconStatus> = ({ enabled }) =>
  enabled ? (
    <MdPhone color="red" style={{ transform: 'rotate(135deg)', fill: 'red' }} />
  ) : (
    <MdVolumeOff />
  );

export const RemoteIcon: React.FC = () => <MdHome />;

export const ExitIcon: React.FC = () => <LogoutSvg />;
export const EnterIcon: React.FC = () => <LoginSvg />;

export const EmailIcon: React.FC = () => <MailSvg />;

export const NotificationsIcon: React.FC<IconStatus> = ({ enabled }) =>
  enabled ? <MdNotifications /> : <MdNotificationsOff />;

interface MeetingVisibilityIconProps {
  visible: boolean;
}

export const MeetingVisibilityIcon: React.FC<MeetingVisibilityIconProps> = ({
  visible,
}) => (visible ? <TiArrowMinimise /> : <TiArrowMaximise />);

export const ExpandBoxIcon: React.FC = () => <OpenInFullSvg />;
export const RetractBoxIcon: React.FC = () => <CloseFullscreenSvg />;
export const MinimizeBoxIcon: React.FC = () => <FaRegWindowMinimize />;
export const CloseBoxIcon: React.FC = () => <MdClose />;

export const ChatPublicChannelIcon: React.FC = () => <FaHashtag />;
export const ChatPrivateChannelIcon: React.FC = () => <FaLock />;
export const ChatDirectChannelIcon: React.FC = () => <BsFillChatFill />;

export const SendMessageIcon: React.FC = () => <MdSend />;
export const AttachFileIcon: React.FC = () => <MdAttachFile />;
export const CancelIcon: React.FC = () => <MdCancel />;

export const GroupIcon: React.FC = () => <MdGroup />;

export const EmojiIcon: React.FC = () => <GrEmoji />;
export const AddReactionIcon: React.FC = () => <GrEmoji />;
export const PinIcon: React.FC = () => <TiPin />;
export const ReplyIcon: React.FC = () => <MdReply />;

export const EditOfficeIcon: React.FC = () => <BiCustomize />;

export const AddIcon: React.FC = () => <MdAddCircle />;
export const DeleteIcon: React.FC = () => <DeleteSvg />;
export const DeleteForeverIcon: React.FC = () => <DeleteForeverSvg />;

const spinnerKeyframes = keyframes`
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(0.7) rotate(540deg);
  }
  100% {
    transform: scale(1) rotate(1080deg);
  }
`;

const SpinnerIconContainer = styled(ImSpinner9)`
  animation: ${spinnerKeyframes} 1.5s ease-out infinite;
`;

export const SpinnerIcon: React.FC = () => <SpinnerIconContainer />;

// export const SpinnerIcon = styled(ProseiaLoadingSvg)`
//   * {
//     stroke: ${(props) => props.theme.textColor};
//   }
// `;

interface UserIconProps {
  revoked?: boolean;
}

interface UserAdminIconProps {
  admin?: boolean;
}

export const ManageAccountsIcon: React.FC = () => <ManageAccountsSvg />;
export const AddUserIcon: React.FC = () => <HiUserAdd />;
export const UserIcon: React.FC<UserIconProps> = ({ revoked }) =>
  revoked ? <NoAccountsSvg /> : <AccountCircleSvg />;
export const UserAdminIcon: React.FC<UserAdminIconProps> = ({ admin }) =>
  admin ? <ManageAccountsIcon /> : <PersonSvg />;
export const EditPictureIcon: React.FC = () => <ImCamera />;

// outras opções: <BiNews />, <MdRssFeed />, <CgFeed />
export const OfficePostsIcon: React.FC = () => <FeedSvg />;
export const OfficePostsBlankIcon: React.FC = () => <FeedBlankSvg />;

export const ShareIcon: React.FC = () => <MdShare />;
export const StartNewMeetingIcon: React.FC = () => <MdVideoCall />;

export const ReportsIcon: React.FC = () => <BiLineChart />;
export const PrintIcon: React.FC = () => <MdPrint />;

interface MoodIconProps {
  mood?: AvatarMood;
  outlined?: boolean;
}

export const MoodIcon: React.FC<MoodIconProps> = ({ mood, outlined }) => {
  switch (mood) {
    case 'very happy':
      return outlined ? <FaRegLaugh /> : <FaLaugh />;
    case 'happy':
      return outlined ? <FaRegSmile /> : <FaSmile />;
    case 'neutral':
      return outlined ? <FaRegMeh /> : <FaMeh />;
    case 'sad':
      return outlined ? <FaRegFrown /> : <FaFrown />;
    case 'very sad':
      return outlined ? <FaRegSadTear /> : <FaSadTear />;
    default:
      return outlined ? <FaRegQuestionCircle /> : <FaQuestionCircle />;
  }
};

export const GoBackIcon: React.FC = () => <MdKeyboardBackspace />;

export const OfficeIcon: React.FC = () => <GridViewSvg />;
export const ChatIcon: React.FC = () => <ChatSvg />;
export const MeetingIcon: React.FC = () => <MeetingSvg />;

interface FavoriteIconProps {
  checked?: boolean;
}

export const FavoriteIcon: React.FC<FavoriteIconProps> = ({ checked }) =>
  checked ? <StarSvg /> : <StarBorderSvg />;

export const SettingsIcon: React.FC = () => <SettingsSvg />;
export const EditIcon: React.FC = () => <EditSvg />;
export const LockIcon: React.FC = () => <LockSvg />;
export const LockOpenIcon: React.FC = () => <LockOpenSvg />;
export const AdminIcon: React.FC = () => <AdminPanelSettingsSvg />;

export const LocationIcon: React.FC = () => <LocationSvg />;
export const LocationMobileIcon: React.FC = () => <LocationMobileSvg />;

export const LightModeIcon: React.FC = () => <LightModeSvg />;
export const DarkModeIcon: React.FC = () => <DarkModeSvg />;

export const RefreshIcon: React.FC = () => <RefreshSvg />;

export const ChromeIcon: React.FC = () => <FaChrome />;
export const EdgeIcon: React.FC = () => <FaEdge />;

export const ExtensionIcon: React.FC = () => <ExtensionSvg />;
export const ExtensionOffIcon: React.FC = () => <ExtensionOffSvg />;

export const SmartphoneIcon: React.FC = () => <MdSmartphone />;

export const ArrowDownIcon: React.FC = () => <FaArrowDown />;
export const ArrowUpIcon: React.FC = () => <FaArrowUp />;
export const CommentsIcon: React.FC = () => <FaComments />;
