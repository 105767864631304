import React, { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import ToastContainer from '../components/ToastContainer';
import { createContext, useContextSelector } from './context';

export interface ToastMessage {
  id: string;
  type?: 'success' | 'error' | 'info';
  title: string;
  description?: string;
  duration?: number;
}
interface ToastContextData {
  addToast(message: Omit<ToastMessage, 'id'>): void;
  removeToast(id: string): void;
}
const ToastContext = createContext<ToastContextData>({} as ToastContextData);

export const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback(
    ({ type, title, description, duration }: ToastMessage) => {
      const id = uuid();
      const toast = { id, type, title, description, duration };

      setMessages((state) => [...state, toast]);
    },
    []
  );

  const removeToast = useCallback((id: string): void => {
    setMessages((state) => state.filter((mensagem) => mensagem.id !== id));
  }, []);

  const contextValue = useMemo<ToastContextData>(
    () => ({
      addToast,
      removeToast,
    }),
    [addToast, removeToast]
  );

  const toastContainer = useMemo(() => <ToastContainer messages={messages} />, [
    messages,
  ]);

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      {toastContainer}
    </ToastContext.Provider>
  );
};

export function useToast<TResult>(
  selector: (state: ToastContextData) => TResult
): TResult {
  return useContextSelector(ToastContext, selector);
}
