import { useMemo } from 'react';
import { Channel } from 'mattermost-redux/types/channels';
import api, {
  Model,
  createCrudService,
  createNestedCrudService,
} from '../services/api';
import { useAuth } from './auth';

export interface RoomModel extends Model {
  name: string;
}

export interface OfficeModel extends Model {
  name: string;
  rooms: RoomModel[];
}

export interface UserLocation {
  accuracy: number;
  latitude: number;
  longitude: number;
  timestamp: Date;
}

export interface UserModel extends Model {
  email: string;
  firstName: string;
  lastName: string;
  picture: string;
  roles?: string[];
  roleDescription?: string;
  lastLoginAt: Date;
  lastMobileAccessAt?: Date;
  lastMobileLogoutAt?: Date;
  welcomeTourDoneAt?: Date;
  tutorialEnterRoomDoneAt?: Date;
  tutorialAddUsersDoneAt?: Date;
  tutorialInviteToMeetingDoneAt?: Date;
  tutorialContextMenuDoneAt?: Date;
  tutorialMeetingVisibilityDoneAt?: Date;
  lastLocation?: UserLocation;
  locationRequired?: boolean;
  extensionRequired?: boolean;
  accessRevokedAt?: Date;
}

interface MattermostAccessData {
  domain: string;
  teamId: string;
  accessToken: string;
}

export interface GuestToken {
  title: string;
  startsAt: Date;
  creatorId: string;
  participantIds: string[];
  officeId: string;
  roomId: string;
  groupId: string;
}

export interface ActivityLogsQuery {
  user: string[];
  status: string[];
  message: string[];
  remote: boolean;
  office: string[];
  room: string[];
  hasGroup: boolean;
  group: string[];
  mic: boolean;
  video: boolean;
  screenShared: boolean;
  createdAtFrom: Date;
  createdAtTo: Date;
}

export interface ActivityLog {
  id: string;
  user: string;
  status: string;
  message?: string;
  remote?: boolean;
  office: string;
  room: string;
  group?: string;
  mic?: boolean;
  video?: boolean;
  screenShared?: boolean;
  createdAt: Date;
  time?: number;
}

export interface UsageLimits {
  planName: string;

  usersPerMeetingLimit: number;
  allowExternalMeetings: boolean;
  allowActivityReports: boolean;
  chatMaxFileSize: number;

  activeUsersLimit: number;
  activeUsersUsage: number;

  monthlyAudioHoursLimit: number;
  audioHoursUsage: number;

  monthlyVideoHoursLimit: number;
  videoHoursUsage: number;

  monthlyScreenSharingHoursLimit: number;
  screenSharingHoursUsage: number;
}

export interface MoodModel extends Model {
  user: string;
  mood: string;
  comment: string;
}

export enum SurveyPlatform {
  zoho = 'zoho',
}

export interface Survey extends Model {
  platform: SurveyPlatform;
  externalId: string;
  tenants: string[];
  emails: string[];
  startsAt: Date;
  endsAt: Date;
}

export interface AppVersions {
  backend: string;
  frontend: string;
}

const officesService = createCrudService<OfficeModel>('offices');
const roomsService = createNestedCrudService<RoomModel>('offices', 'rooms');
const usersService = createCrudService<UserModel>('users');
const moodsService = createCrudService<MoodModel>('moods');

const getJitsiDomain = async (guestToken?: string): Promise<string> => {
  const url = guestToken ? 'guests/jitsi-domain' : 'jitsi-domain';
  const config = guestToken
    ? {
        headers: {
          ...api.defaults.headers,
          Authorization: `Bearer ${guestToken}`,
        },
      }
    : undefined;
  const response = await api.get<string>(url, config);
  return response.data;
};

const getMattermostAccessData = async (): Promise<MattermostAccessData> => {
  const response = await api.get<MattermostAccessData>(
    'mattermost/user-context'
  );
  return response.data;
};

const updateMattermostChannelPrivacy = async (
  channelId: string,
  privacy: string
): Promise<Channel> => {
  const response = await api.post<Channel>(
    'mattermost/update-channel-privacy',
    { channelId, privacy }
  );
  return response.data;
};

const deleteMattermostChannel = async (channelId: string): Promise<void> => {
  await api.delete<Channel>(`mattermost/delete-channel/${channelId}`);
};

const inviteUser = async (email: string): Promise<boolean> => {
  const response = await api.post<boolean>('users/invite', { email });
  return response.data;
};

const getGuestTokenData = async (token: string): Promise<GuestToken> => {
  const response = await api.get<GuestToken>(`guests/token/${token}`);
  return response.data;
};

const getNewGuestToken = async (data: GuestToken): Promise<string> => {
  const response = await api.post<string>('guests/new-token', data);
  return response.data;
};

const getShortUrl = async (url: string): Promise<string> => {
  const response = await api.post<string>('guests/shorten-url', { url });
  return response.data;
};

const searchActivityLogs = async (
  query: Partial<ActivityLogsQuery>
): Promise<ActivityLog[]> => {
  const response = await api.post<ActivityLog[]>('activity-logs/search', query);
  return response.data;
};

const getCurrentMonthUsageLimits = async (
  guestToken?: string
): Promise<UsageLimits> => {
  const url = guestToken ? 'guests/usage-limits' : 'usage-limits/current';
  const config = guestToken
    ? {
        headers: {
          ...api.defaults.headers,
          Authorization: `Bearer ${guestToken}`,
        },
      }
    : undefined;
  const response = await api.get<UsageLimits>(url, config);
  return response.data;
};

const uploadPublicFile = async (
  data: string | File | Blob,
  fileName?: string
): Promise<string> => {
  const formData = new FormData();
  formData.append(
    'file',
    data,
    fileName || (data as any).name || `${Date.now()}`
  );
  const response = await api.post<string>('files/upload-public-file', formData);
  return response.data;
};

interface PushNotificationChannelHasNewMessage {
  teamId: string;
  channelId: string;
  senderId: string;
  senderName: string;
  picture: string;
  channelName?: string;
  recipientIds: string[];
}

const sendPushNotificationChannelHasNewMessage = async (
  payload: PushNotificationChannelHasNewMessage
) => {
  const url = 'push-notifications/send-channel-has-new-message';
  const response = await api.post(url, payload);
  return response.data;
};

const isSubdomainAvailable = async (subdomain: string) => {
  const url = `environment-generation/${subdomain}/is-available`; 
  try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });

      if (!response.ok) {
        console.error('Erro na requisição:', response.status, response.statusText);
        return true; 
      }

      const data = await response.json();
      return Boolean(data); 
    } catch (error) {
      console.error('Erro de rede ou outro erro:', error);
      return false;
  }
};

const isSubdomainIssued = async (subdomain: string) => {
  const url = `environment-generation/${subdomain}/is-issued`;
  const response = await api.get<boolean>(url);
  return response.data;
};

interface CreateEnvironmentRequest {
  email: string;
  subdomain: string;
  createLead?: boolean;
  origin?: string;
}

const createEnvironment = async (data: CreateEnvironmentRequest) => {
  const response = await api.post('environment-generation', data);
  return response.data;
};

const getFavoriteRooms = async (userId: string) => {
  const response = await api.get<Record<string, string[]>>(
    `users/${userId}/favorite-rooms`
  );
  return response.data;
};

const addFavoriteRoom = async (
  userId: string,
  officeId: string,
  roomId: string
) => {
  const response = await api.post<Record<string, string[]>>(
    `users/${userId}/favorite-rooms/add`,
    { officeId, roomId }
  );
  return response.data;
};

const removeFavoriteRoom = async (
  userId: string,
  officeId: string,
  roomId: string
) => {
  const response = await api.post<Record<string, string[]>>(
    `users/${userId}/favorite-rooms/remove`,
    { officeId, roomId }
  );
  return response.data;
};

const revokeUserAccess = async (userId: string) => {
  const response = await api.patch<string>(`users/${userId}/revoke-access`);
  return response.data;
};

const grantUserAccess = async (userId: string) => {
  const response = await api.patch<string>(`users/${userId}/grant-access`);
  return response.data;
};

const revokeAdminPrivileges = async (userId: string) => {
  const response = await api.patch<string>(`users/${userId}/roles`, {
    roles: [''],
  });
  return response.data;
};

const grantAdminPrivileges = async (userId: string) => {
  const response = await api.patch<string>(`users/${userId}/roles`, {
    roles: ['admin'],
  });
  return response.data;
};

const getGoogleApiJsApiKey = async () => {
  const response = await api.get<string>(`google-maps-js-api-key`);
  return response.data;
};

const getDataUriFromUrl = async (url: string) => {
  const key = `@Proseia:external-url(${url})`;
  const stored = sessionStorage.getItem(`@Proseia:external-url(${url})`);

  if (stored) return stored;

  const blob = await fetch(`from-url/${btoa(url)}`, {
    headers: api.defaults.headers,
  }).then((r) => r.blob());

  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result as string;
      sessionStorage.setItem(key, dataUrl);
      resolve(dataUrl);
    };
    reader.readAsDataURL(blob);
  });
};

interface RegisterUserActionRequest {
  userId: string;
  action: string;
  additionalInfo?: string;
}

const registerUserAction = async ({
  userId,
  action,
  additionalInfo,
}: RegisterUserActionRequest) => {
  const response = await api.post<boolean>(`users/${userId}/register-action`, {
    action,
    additionalInfo,
  });
  return response.data;
};

const getUsesLocationValue = async (): Promise<boolean> => {
  const response = await api.get<boolean>('uses-location');
  return response.data;
};

const setUsesLocationValue = async (usesLocation: boolean): Promise<void> => {
  await api.patch<string>('uses-location', { usesLocation });
};

const getAllowProfileEditingValue = async (): Promise<boolean> => {
  const response = await api.get<boolean>('allow-profile-editing');
  return response.data;
};

const setAllowProfileEditingValue = async (allow: boolean): Promise<void> => {
  await api.patch<string>('allow-profile-editing', { allow });
};

const getUsesExtensionValue = async (): Promise<boolean> => {
  const response = await api.get<boolean>('uses-extension');
  return response.data;
};

const setUsesExtensionValue = async (usesExtension: boolean): Promise<void> => {
  await api.patch<string>('uses-extension', { usesExtension });
};

const setUserLocationRequired = async (
  user: UserModel,
  locationRequired: boolean
): Promise<void> => {
  await api.patch<string>(`users/${user.id}/location-required`, {
    locationRequired,
  });
};

const setUserExtensionRequired = async (
  user: UserModel,
  extensionRequired: boolean
): Promise<void> => {
  await api.patch<string>(`users/${user.id}/extension-required`, {
    extensionRequired,
  });
};

const getCurrentSurvey = async (): Promise<Survey> => {
  const response = await api.get<Survey>('surveys/current');
  return response.data;
};

export const useApi = () => {
  const accessToken = useAuth((state) => state?.user?.accessToken);

  if (accessToken) {
    api.defaults.headers = {
      ...api.defaults.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return useMemo(
    () => ({
      officesService,
      roomsService,
      usersService,
      moodsService,
      getJitsiDomain,
      getMattermostAccessData,
      updateMattermostChannelPrivacy,
      deleteMattermostChannel,
      inviteUser,
      getGuestTokenData,
      getNewGuestToken,
      getShortUrl,
      uploadPublicFile,
      searchActivityLogs,
      getCurrentMonthUsageLimits,
      sendPushNotificationChannelHasNewMessage,
      isSubdomainAvailable,
      isSubdomainIssued,
      createEnvironment,
      getFavoriteRooms,
      addFavoriteRoom,
      removeFavoriteRoom,
      revokeUserAccess,
      grantUserAccess,
      revokeAdminPrivileges,
      grantAdminPrivileges,
      registerUserAction,
      getGoogleApiJsApiKey,
      getDataUriFromUrl,
      getUsesLocationValue,
      setUsesLocationValue,
      setUserLocationRequired,
      getCurrentSurvey,
      getAllowProfileEditingValue,
      setAllowProfileEditingValue,
      getUsesExtensionValue,
      setUsesExtensionValue,
      setUserExtensionRequired,
    }),
    []
  );
};
