import { styled } from '../../themes';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    height: 66%;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color 0.2s;

    .react-switch-button {
      content: '';
      position: relative;
      top: 0px;
      left: 0px;
      width: 50%;
      height: 150%;
      border-radius: 50%;
      transition: 0.2s;
      background: #fff;
      box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    }
  }

  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: 100%;
    transform: translateX(-100%);
  }

  /* .react-switch-label:active .react-switch-button {
    width: 60px;
  } */
`;
