import React, {
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
} from 'react';
import { MdSearch } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';

import { Container } from './styles';
import { AppMessages } from '../../languages';
import { useLanguage } from '../../hooks/language';

const KEY_CODE_ENTER = 13;
const KEY_CODE_ESC = 27;

interface Props {
  onChange: (searchValue: string) => void | Promise<void>;
  onClear?: () => void | Promise<void>;
}

let handleSearchReference: any;

const InputSearchRef: React.ForwardRefRenderFunction<
  HTMLInputElement,
  Props
> = ({ onChange, onClear }, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const messages = useLanguage((state) => state.messages);
  const [hasValue, setHasValue] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  const placeholder = messages[AppMessages.inputSearchPlaceholder];

  useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  const handleChange = useCallback(
    ({ interval }) => {
      if (!inputRef?.current) return;

      const searchValue = inputRef?.current.value;

      if (handleSearchReference) clearTimeout(handleSearchReference);

      handleSearchReference = setTimeout(() => {
        onChange(searchValue);
        handleSearchReference = undefined;
      }, interval || 500);

      if (hasValue !== !!searchValue) setHasValue(!!searchValue);
    },
    [onChange, hasValue]
  );

  const handleClearSearch = useCallback(() => {
    if (!inputRef.current) return;

    inputRef.current.value = '';
    handleChange({ interval: 1 });
    if (onClear) onClear();
  }, [handleChange, onClear]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === KEY_CODE_ESC) {
        handleClearSearch();
      } else if (e.keyCode === KEY_CODE_ENTER) {
        handleChange({ interval: 1 });
      }
    },
    [handleClearSearch, handleChange]
  );

  return (
    <Container hasFocus={hasFocus}>
      <MdSearch />
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
      />
      {hasValue && (
        <IoMdCloseCircle className="clear" onClick={handleClearSearch} />
      )}
    </Container>
  );
};

export const InputSearch = React.forwardRef(InputSearchRef);
