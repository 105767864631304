import { shade } from 'polished';
import { styled } from '../../themes';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
`;

export const ContentTitle = styled.h2`
  max-width: 300px;
`;

export const ContentLine = styled.p`
  display: flex;
  padding: 8px 0;
  align-items: center;
  max-width: 300px;

  > svg {
    fill: black;
    width: 22px;
    height: 22px;
  }
`;

export const ContentImage = styled.img`
  max-width: 100%;
`;

export const ContentButton = styled.button`
  border: none;
  background-color: gray;
  color: white;
  font-size: 18px;
  padding: 10px 50px;
  border-radius: 25px;
  margin-top: 5px;
  max-width: fit-content;
  align-self: center;

  :hover {
    background-color: ${shade(0.3, 'gray')};
  }
`;
