import { Menu, MenuItem } from '@material-ui/core';
import { shade, transparentize } from 'polished';
import { styled } from '../../themes';

const WIDTH = 524;

export const Container = styled.div`
  position: relative;
  width: 60%;
  min-width: ${WIDTH}px;
  max-width: ${WIDTH}px;
  height: 85%;
  background: ${(props) => props.theme.boxColor};
  color: ${(props) => props.theme.textColor};
  box-shadow: ${(props) => props.theme.boxGlow};
  border-radius: 5px;

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    padding-left: 10px;
  }

  > .search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    > button {
      margin-left: 10px;
    }
  }

  > .users-count {
    /* display: flex; */
    line-height: 25px;
    padding: 2px 12px 30px;

    /* span + span {
      margin-left: 10px;
    } */
  }

  > .users {
    min-height: calc(100% - 228px);
    max-height: calc(100% - 228px);
    overflow-x: none;
    overflow-y: auto;
  }

  > .invite-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }
`;

export const UserContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  > button {
    opacity: 0;
  }

  :hover {
    background: ${(props) => shade(0.1, props.theme.boxColor)};

    > button {
      opacity: 1;
    }
  }

  > hr {
    position: absolute;
    top: 0px;
    width: calc(100% - 20px);
    background-color: #000;
    opacity: 0.2;
    height: 1px;
    border: none;
  }

  > span {
    display: flex;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;

      .name > * {
        padding: 0;
      }

      .role > * {
        padding: 0;
      }

      .email {
        font-size: 14px;
        opacity: 0.8;
      }

      .no-login {
        font-size: 14px;
      }
    }
  }

  .avatar {
    margin-right: 10px;
  }
`;

export const Button = styled.button`
  /* position: absolute;
  right: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: ${(props) => props.color || 'green'};
  padding: 7px 14px;
  color: white;
  white-space: nowrap;
  border-radius: 5px;
  transition: opacity 0.3s;

  :hover {
    background: ${(props) => shade(0.2, props.color || 'green')};
  }

  svg {
    font-size: 20px;
    width: 20px;
    height: 20px;
    fill: white;
    margin-right: 5px;
  }
`;

export const Label = styled.span`
  position: relative;
  /* border: 1px solid ${(props) => props.theme.textColor}; */
  color: ${(props) => props.theme.textColor};
  font-size: 14px;
  /* padding: 5px; */
  /* border-radius: 5px; */
  opacity: 0.7;

  /* & + & {
    margin-left: 10px;
  } */

  &.no-login {
    border-color: darkorange;
    color: darkorange;
  }

  &.admin {
    border-color: #00a86b;
    color: #00a86b;
  }

  &.access-revoked {
    border-color: red;
    color: red;
  }

  > svg {
    position: absolute;
    top: calc(50% - 15px);
    left: -60px;
    fill: ${(props) => props.theme.textColor};
    width: 30px;
    height: 30px;
  }
`;

export const LoadingContainer = styled.div`
  > svg {
    font-size: 50px;
  }
`;

export const ContextMenu = styled(Menu)`
  .MuiPaper-root {
    background-color: ${(props) => props.theme.background};
  }

  .MuiDivider-root {
    background-color: ${(props) => transparentize(0.7, props.theme.textColor)};
  }

  .context-menu-header {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.textColor};
    padding: 0 10px 10px;

    > img {
      border-radius: 5px;
      margin-right: 10px;
    }
  }

  .context-menu-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .context-menu-name {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    align-items: center;
    justify-content: center;

    > svg {
      margin-right: 5px;
    }
  }
`;

export const ContextMenuItem = styled(MenuItem)`
  color: ${(props) => props.theme.textColor} !important;
  padding: 10px 16px !important;

  :hover {
    background-color: ${(props) =>
      transparentize(0.9, props.theme.textColor)} !important;
  }

  svg {
    width: 24px;
    fill: ${(props) => props.theme.textColor};
    height: 24px;
    margin-right: 10px;
  }
`;

export const ConfigItemContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 25px;
`;

export const ConfigSwitch = styled.div`
  right: 0px;
  width: 40px;
  height: 20px;
  margin-left: 5px;
`;

export const ConfigContainer = styled.div`
  position: absolute;
  width: 50%;
  right: 15px;
  top: 86px;

  > * {
    position: relative;
  }
`;
