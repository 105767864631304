import { keyframes } from 'styled-components';
import { shade } from 'polished';
import { styled } from '../../themes';

const HEADER_HEIGHT = 80;
const TITLE_HEIGHT = 22;

const ANIMATION_STOP_DISPLACEMENT = '0px';
const ANIMATION_MOVE_DISPLACEMENT = '2px';

const animatedNotificationKeyframes = keyframes`
  0% { transform: translateY(${ANIMATION_STOP_DISPLACEMENT}); }
  1% { transform: translateY(${ANIMATION_MOVE_DISPLACEMENT}); }
  2% { transform: translateY(-${ANIMATION_MOVE_DISPLACEMENT}); }
  3% { transform: translateY(${ANIMATION_MOVE_DISPLACEMENT}); }
  4% { transform: translateY(-${ANIMATION_MOVE_DISPLACEMENT}); }
  5% { transform: translateY(${ANIMATION_MOVE_DISPLACEMENT}); }
  6% { transform: translateY(-${ANIMATION_MOVE_DISPLACEMENT}); }
  7% { transform: translateY(${ANIMATION_MOVE_DISPLACEMENT}); }
  8% { transform: translateY(${ANIMATION_STOP_DISPLACEMENT}); }

  10% { transform: translateY(${ANIMATION_STOP_DISPLACEMENT}); }
  11% { transform: translateY(${ANIMATION_MOVE_DISPLACEMENT}); }
  12% { transform: translateY(-${ANIMATION_MOVE_DISPLACEMENT}); }
  13% { transform: translateY(${ANIMATION_MOVE_DISPLACEMENT}); }
  14% { transform: translateY(-${ANIMATION_MOVE_DISPLACEMENT}); }
  15% { transform: translateY(${ANIMATION_MOVE_DISPLACEMENT}); }
  16% { transform: translateY(-${ANIMATION_MOVE_DISPLACEMENT}); }
  17% { transform: translateY(${ANIMATION_MOVE_DISPLACEMENT}); }
  18% { transform: translateY(${ANIMATION_STOP_DISPLACEMENT}); }

  100% { transform: translateY(${ANIMATION_STOP_DISPLACEMENT}); }
`;

export const Container = styled.div`
  position: relative;
  background: ${(props) => props.theme.boxColor};
  box-shadow: ${(props) => props.theme.boxGlow};
  color: ${(props) => props.theme.textColor};
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 5px;

  .unreads {
    position: absolute;
    background: red;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    padding: 0 5px;
    /* margin-left: 10px; */
    align-self: center;
    right: 20px;
    animation: ${animatedNotificationKeyframes} 10s ease-out infinite;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 5px 10px;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;

    > .title {
      position: relative;
      display: flex;
      justify-content: space-between;
      height: ${TITLE_HEIGHT}px;
      font-size: 14px;
      overflow-x: hidden;

      > .add-button {
        background: transparent;
        color: ${(props) => props.theme.textColor};
        border: none;
        font-size: 18px;
        margin-right: 5px;

        :hover {
          font-weight: bold;
        }
      }
    }

    > .scrollable-content {
      position: relative;
      height: calc(100% - ${TITLE_HEIGHT}px);
      max-height: calc(100% - ${TITLE_HEIGHT}px);
      font-size: 14px;
      overflow-x: hidden;
    }
  }
`;

export const Header = styled.div`
  height: ${HEADER_HEIGHT}px;
`;

export const ChannelsContainer = styled.div`
  height: calc(35% - ${HEADER_HEIGHT / 2}px);
  max-height: calc(35% - ${HEADER_HEIGHT / 2}px);
`;

export const ChannelContainer = styled.div`
  display: flex;
  cursor: pointer;
  padding: 2px;
  padding-right: 20px;

  :hover {
    font-weight: bold;
    background: ${(props) => shade(0.2, props.theme.boxColor)};

    .channel-options-button {
      opacity: 1;
    }
  }

  .channel-options-button {
    opacity: 0;
  }

  > span > svg {
    font-size: 12px;
    margin-right: 5px;
  }
`;

export const ContactsContainer = styled.div`
  height: calc(65% - ${HEADER_HEIGHT / 2}px);
  max-height: calc(65% - ${HEADER_HEIGHT / 2}px);
`;

export const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  cursor: pointer;

  :hover {
    background: ${(props) => shade(0.2, props.theme.boxColor)};
  }

  > .avatar {
    transform: scale(0.8);
  }

  .location {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 170px;

    > .logo-symbol {
      margin-right: 5px;
    }
  }

  .user-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 170px;
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    font-size: 50px;
  }
`;

const CHANNEL_OPTIONS_BUTTON_SIZE = '16px';

export const ChannelOptionsButton = styled.button`
  position: absolute;
  right: 2px;
  border: none;
  background: transparent;
  padding: 0;
  width: ${CHANNEL_OPTIONS_BUTTON_SIZE};
  height: ${CHANNEL_OPTIONS_BUTTON_SIZE};
  align-self: center;
  justify-self: flex-end;

  :hover {
    > svg {
      opacity: 1;
    }
  }

  > svg {
    fill: ${(props) => props.theme.textColor};
    width: ${CHANNEL_OPTIONS_BUTTON_SIZE};
    height: ${CHANNEL_OPTIONS_BUTTON_SIZE};
    font-size: ${CHANNEL_OPTIONS_BUTTON_SIZE};
    opacity: 0.5;
  }
`;
