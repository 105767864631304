import { styled } from '../../themes';

export type TextAlign = 'left' | 'center' | 'right';

interface StyleProps {
  textAlign?: TextAlign;
  fontSizePx?: number;
}

export const Container = styled.div<StyleProps>`
  display: flex;
  text-align: ${(props) => props.textAlign || 'left'};
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 100%;
`;

export const StyledLabel = styled.span<StyleProps>`
  width: 100%;
  max-width: 100%;
  display: inline-block;
  padding: 2px;
  font-size: ${(props) => props.fontSizePx || 14}px;
  border: 1px solid transparent;
  border-radius: 5px;
  color: ${(props) => props.theme.textColor};
  /* cursor: text; */

  &:hover {
    /* box-shadow: ${(props) => props.theme.formComponentGlow}; */

    > button {
      opacity: 1;
    }
  }

  > button {
    position: absolute;
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-left: 5px;
    opacity: 0;
  }
`;

export const Input = styled.input<StyleProps>`
  width: 100%;
  max-width: 100%;
  display: inline-block;
  padding: 2px;
  font-size: ${(props) => props.fontSizePx || 14}px;
  border: 1px solid transparent;
  border-radius: 5px;
  background: transparent;
  color: ${(props) => props.theme.textColor};
  transition: all 0.2s ease-out;
  box-shadow: ${(props) => props.theme.formComponentGlow};
  text-align: ${(props) => props.textAlign || 'left'};
`;
