import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import { AppMessages } from '../../languages';
import { GuestToken, useApi } from '../../hooks/api';
import { useAuth } from '../../hooks/auth';
import { useGuest } from '../../hooks/guest';
import { useJitsi } from '../../hooks/jitsi';
import { ProseiaPageContainer } from '../../components/ProseiaPageContainer';
import {
  StyledInput,
  Button,
  MessageContainer,
} from '../../components/ProseiaPageContainer/styles';

interface FormErrors {
  [key: string]: string;
}

interface RouteParams {
  token?: string;
}

const Meeting: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const { getGuestTokenData } = useApi();
  const validateToken = useAuth((state) => state.validateToken);
  const getTokenData = useAuth((state) => state.getTokenData);
  const { formatMessage } = useIntl();

  const initialize = useGuest((state) => state.initialize);
  const setOnConnectUserInfo = useGuest((state) => state.setOnConnectUserInfo);
  const connect = useGuest((state) => state.connect);
  const disconnect = useGuest((state) => state.disconnect);
  const enableMic = useGuest((state) => state.enableMic);
  const disableMic = useGuest((state) => state.disableMic);
  const disableSound = useGuest((state) => state.disableSound);
  const enableVideo = useGuest((state) => state.enableVideo);
  const disableVideo = useGuest((state) => state.disableVideo);
  const shareScreen = useGuest((state) => state.shareScreen);
  const unshareScreen = useGuest((state) => state.unshareScreen);

  const enterJitsiMeeting = useJitsi((state) => state.enterJitsiMeeting);
  const isMeetingContainerVisible = useJitsi(
    (state) => state.isMeetingContainerVisible
  );
  const setMeetingContainerVisibility = useJitsi(
    (state) => state.setMeetingContainerVisibility
  );
  const onAudioMuteChanged = useJitsi((state) => state.onAudioMuteChanged);
  const onVideoMuteChanged = useJitsi((state) => state.onVideoMuteChanged);
  const onScreenSharedChanged = useJitsi(
    (state) => state.onScreenSharedChanged
  );
  const onJoinJitsiMeeting = useJitsi((state) => state.onJoinJitsiMeeting);
  const onLeaveJitsiMeeting = useJitsi((state) => state.onLeaveJitsiMeeting);

  const [userId] = useState(uuid());
  const [entering, setEntering] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [tokenData, setTokenData] = useState<GuestToken>();
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const requiredMessage = formatMessage({
    id: AppMessages.pageSignInValidationRequired,
  });

  const firstNameRef = useRef<HTMLInputElement>();
  const lastNameRef = useRef<HTMLInputElement>();

  const firstNameLabel = formatMessage({ id: AppMessages.pageSignInFirstName });
  const lastNameLabel = formatMessage({ id: AppMessages.pageSignInLastName });
  const headerLabel = formatMessage({
    id: AppMessages.pageMeetingHeaderMessage,
  });
  const submitLabel = formatMessage({
    id: AppMessages.pageMeetingEnterMeeting,
  });

  const getFirstName = useCallback(() => firstNameRef.current?.value || '', []);
  const getLastName = useCallback(() => lastNameRef.current?.value || '', []);

  const canSubmit = useCallback(() => {
    if (!tokenData || !getFirstName() || isMeetingContainerVisible())
      return false;

    return true;
  }, [tokenData, getFirstName, isMeetingContainerVisible]);

  const handleFirstNameBlur = useCallback(() => {
    if (!getFirstName()) {
      setFormErrors((state) => ({ ...state, firstName: requiredMessage }));
    }
  }, [getFirstName, requiredMessage]);

  const handleFirstNameChange = useCallback(() => {
    setFormErrors((state) => ({ ...state, firstName: '' }));
  }, []);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!match.params.token) return;
      if (!tokenData) return;
      if (!canSubmit()) return;

      setEntering(true);

      enterJitsiMeeting(
        tokenData.groupId,
        (hasPermission) => {
          if (!hasPermission) {
            setEntering(false);
            return;
          }
          setMeetingContainerVisibility(true);
        },
        {
          id: userId,
          firstName: getFirstName(),
          lastName: getLastName(),
          email: '',
          picture: '',
          lastLoginAt: new Date(),
        },
        match.params.token
      );
    },
    [
      canSubmit,
      enterJitsiMeeting,
      setMeetingContainerVisibility,
      getFirstName,
      getLastName,
      match.params.token,
      tokenData,
      userId,
    ]
  );

  useEffect(() => {
    if (!match.params.token) return;
    if (!validateToken(match.params.token)) return;
    setTokenData(getTokenData(match.params.token));
    initialize(match.params.token);
  }, [match.params.token, validateToken, getTokenData, initialize]);

  useEffect(() => {
    onAudioMuteChanged((muted) => {
      if (muted) disableMic();
      else enableMic();
    });
  }, [onAudioMuteChanged, disableMic, enableMic]);

  useEffect(() => {
    onVideoMuteChanged((muted) => {
      if (muted) disableVideo();
      else enableVideo();
    });
  }, [onVideoMuteChanged, disableVideo, enableVideo]);

  useEffect(() => {
    onScreenSharedChanged((shared) => {
      if (shared) shareScreen();
      else unshareScreen();
    });
  }, [onScreenSharedChanged, shareScreen, unshareScreen]);

  useEffect(() => {
    if (!tokenData) return;
    onJoinJitsiMeeting((meetingId) => {
      setEntering(false);
      setOnConnectUserInfo({
        id: userId,
        firstName: getFirstName(),
        lastName: getLastName(),
        lastUpdate: Date.now(),
        status: 'available',
        statusMessage: tokenData.title,
        isGuest: true,
        guestCreatorId: tokenData.creatorId,
        guestParticipantIds: tokenData.participantIds,
        isRemote: true,
        officeId: tokenData.officeId,
        roomId: tokenData.roomId,
        groupId: meetingId,
        micEnabled: true,
        videoEnabled: false,
        soundEnabled: true,
      });
      connect();
    });
  }, [
    tokenData,
    userId,
    onJoinJitsiMeeting,
    getFirstName,
    getLastName,
    setOnConnectUserInfo,
    connect,
  ]);

  useEffect(() => {
    disableSound();
    onLeaveJitsiMeeting(disconnect);
    setMeetingContainerVisibility(false);
  }, [
    disableSound,
    onLeaveJitsiMeeting,
    disconnect,
    setMeetingContainerVisibility,
  ]);

  useEffect(() => {
    if (!match.params.token) {
      setInvalidToken(true);
      return;
    }
    getGuestTokenData(match.params.token).then(
      () => {
        setInvalidToken(false);
      },
      () => {
        setInvalidToken(true);
      }
    );
  }, [match.params.token, getGuestTokenData]);

  const formContainer = useMemo(
    () =>
      tokenData &&
      !invalidToken && (
        <>
          <MessageContainer align="center">{headerLabel}</MessageContainer>
          <br />
          <br />
          <br />
          <form onSubmit={handleSubmit} autoComplete="off">
            <StyledInput
              required
              autoComplete="off"
              autoFocus
              name="firstName"
              inputRef={firstNameRef}
              label={firstNameLabel}
              onChange={handleFirstNameChange}
              onBlur={handleFirstNameBlur}
              error={!!formErrors.firstName}
              helperText={formErrors.firstName}
            />
            <StyledInput
              autoComplete="off"
              name="lastName"
              inputRef={lastNameRef}
              label={lastNameLabel}
            />
            <Button type="submit" disabled={entering}>
              {submitLabel}
            </Button>
          </form>
        </>
      ),
    [
      entering,
      firstNameLabel,
      formErrors.firstName,
      handleFirstNameBlur,
      handleFirstNameChange,
      handleSubmit,
      headerLabel,
      invalidToken,
      lastNameLabel,
      submitLabel,
      tokenData,
    ]
  );

  return (
    <ProseiaPageContainer loading={entering}>
      {formContainer}

      {invalidToken && (
        <MessageContainer align="center">Invalid token</MessageContainer>
      )}
    </ProseiaPageContainer>
  );
};

export default Meeting;
