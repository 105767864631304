import React from 'react';

import { Container } from './styles';

interface Props {
  isOpen: boolean;
  onClickOutside: () => void;
}

export const ModalContainer: React.FC<Props> = ({
  children,
  isOpen,
  onClickOutside,
}) => {
  return isOpen ? (
    <Container onClick={onClickOutside}>{children}</Container>
  ) : (
    <></>
  );
};
