/* eslint-disable no-restricted-globals */
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Fade, Divider } from '@material-ui/core';
import { Channel } from 'mattermost-redux/types/channels';

import { AppMessages } from '../../languages';
import { useAuth } from '../../hooks/auth';
import { DEFAULT_CHANNEL_NAME, useMattermost } from '../../hooks/mattermost';
import {
  ChatPublicChannelIcon,
  ChatPrivateChannelIcon,
  EditIcon,
  GroupIcon,
  ExitIcon,
  DeleteIcon,
  LockIcon,
  LockOpenIcon,
} from '../Icons';

import { ContextMenu, ContextMenuItem } from './styles';

interface Props {
  channel?: Channel;
  anchorEl?: EventTarget & HTMLButtonElement;
  onClose?: () => void;
}

export const ChatChannelOptionsMenu: React.FC<Props> = ({
  anchorEl,
  channel,
  onClose,
}) => {
  const isAdmin = useAuth((state) => state.isAdmin);
  const { formatMessage } = useIntl();
  const mattermostUserId = useMattermost(
    (state) => state.mattermostUser?.id || ''
  );

  const renameChannel = useMattermost((state) => state.renameChannel);
  const updateChannelPrivacy = useMattermost(
    (state) => state.updateChannelPrivacy
  );
  const deleteChannel = useMattermost((state) => state.deleteChannel);
  const openChannelMembers = useMattermost((state) => state.openChannelMembers);
  const getChannelDisplayName = useMattermost(
    (state) => state.getChannelDisplayName
  );
  const removeFromChannel = useMattermost((state) => state.removeFromChannel);

  // eslint-disable-next-line camelcase
  const displayName = channel ? getChannelDisplayName(channel) : '';
  const channelId = channel?.id;
  const isDefaultChannel = channel?.name === DEFAULT_CHANNEL_NAME;
  const channelType = channel?.type || '';

  // eslint-disable-next-line camelcase
  const isOwner = mattermostUserId === channel?.creator_id;
  const canEdit = isOwner || isAdmin();

  const handleClose = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (onClose) onClose();
    },
    [onClose]
  );

  const handleMembers = useCallback(
    (e: any) => {
      handleClose(e);
      if (!channelId) return;
      openChannelMembers(channelId);
    },
    [channelId, openChannelMembers, handleClose]
  );

  const handleRename = useCallback(
    (e: any) => {
      e.stopPropagation();
      // eslint-disable-next-line no-alert
      const result = prompt(
        formatMessage({ id: AppMessages.chatRenameChannelNewName }),
        displayName
      );
      if (channelId && result && result !== displayName) {
        renameChannel(channelId, result);
      }
      handleClose(e);
    },
    [channelId, displayName, renameChannel, handleClose, formatMessage]
  );

  const handleTurnPrivate = useCallback(
    (e: any) => {
      handleClose(e);
      if (!channelId) return;

      // eslint-disable-next-line no-alert
      const result = confirm(
        formatMessage({ id: AppMessages.chatMakeChannelPrivateConfirm })
      );
      if (!result) return;
      updateChannelPrivacy(channelId, 'P');
    },
    [channelId, handleClose, formatMessage, updateChannelPrivacy]
  );

  const handleTurnPublic = useCallback(
    (e: any) => {
      handleClose(e);
      if (!channelId) return;

      // eslint-disable-next-line no-alert
      const result = confirm(
        formatMessage({ id: AppMessages.chatMakeChannelPublicConfirm })
      );
      if (!result) return;
      updateChannelPrivacy(channelId, 'O');
    },
    [channelId, handleClose, formatMessage, updateChannelPrivacy]
  );

  const handleLeave = useCallback(
    (e: any) => {
      handleClose(e);
      if (!channelId || !mattermostUserId) return;

      // eslint-disable-next-line no-alert
      const result = confirm(
        formatMessage({ id: AppMessages.chatLeaveChannelConfirm })
      );
      if (!result) return;
      removeFromChannel(mattermostUserId, channelId);
    },
    [channelId, mattermostUserId, handleClose, formatMessage, removeFromChannel]
  );

  const handleDelete = useCallback(
    (e: any) => {
      handleClose(e);
      if (!channelId) return;

      // eslint-disable-next-line no-alert
      const result = confirm(
        formatMessage(
          { id: AppMessages.chatDeleteChannelConfirm },
          { group: displayName }
        )
      );
      if (!result) return;
      deleteChannel(channelId);
    },
    [channelId, displayName, handleClose, formatMessage, deleteChannel]
  );

  const channelTypeIcon = useMemo(
    () =>
      channelType === 'O' ? (
        <ChatPublicChannelIcon />
      ) : (
        <ChatPrivateChannelIcon />
      ),
    [channelType]
  );

  const header = useMemo(
    () => (
      <div className="context-menu-header">
        <div className="context-menu-info">
          <span className="context-menu-name">
            {channelTypeIcon}
            {displayName}
          </span>
        </div>
      </div>
    ),
    [channelTypeIcon, displayName]
  );

  const divider = useMemo(() => <Divider />, []);

  const membersOption = useMemo(
    () => (
      <ContextMenuItem onClick={handleMembers}>
        <GroupIcon />
        {formatMessage({ id: AppMessages.chatChannelMembers })}
      </ContextMenuItem>
    ),
    [formatMessage, handleMembers]
  );

  const renameOption = useMemo(
    () => (
      <ContextMenuItem
        onClick={handleRename}
        disabled={isDefaultChannel || !canEdit}
      >
        <EditIcon />
        {formatMessage({ id: AppMessages.chatRenameChannel })}
      </ContextMenuItem>
    ),
    [canEdit, formatMessage, handleRename, isDefaultChannel]
  );

  const turnPrivateOption = useMemo(
    () =>
      channelType === 'O' && (
        <ContextMenuItem
          onClick={handleTurnPrivate}
          disabled={isDefaultChannel || !canEdit}
        >
          <LockIcon />
          {formatMessage({ id: AppMessages.chatMakeChannelPrivate })}
        </ContextMenuItem>
      ),
    [canEdit, channelType, formatMessage, handleTurnPrivate, isDefaultChannel]
  );

  const turnPublicOption = useMemo(
    () =>
      channelType === 'P' && (
        <ContextMenuItem
          onClick={handleTurnPublic}
          disabled={isDefaultChannel || !canEdit}
        >
          <LockOpenIcon />
          {formatMessage({ id: AppMessages.chatMakeChannelPublic })}
        </ContextMenuItem>
      ),
    [canEdit, channelType, formatMessage, handleTurnPublic, isDefaultChannel]
  );

  const leaveOption = useMemo(
    () => (
      <ContextMenuItem onClick={handleLeave} disabled={isDefaultChannel}>
        <ExitIcon />
        {formatMessage({ id: AppMessages.chatLeaveChannel })}
      </ContextMenuItem>
    ),
    [formatMessage, handleLeave, isDefaultChannel]
  );

  const deleteOption = useMemo(
    () => (
      <ContextMenuItem
        onClick={handleDelete}
        disabled={isDefaultChannel || !canEdit}
      >
        <DeleteIcon />
        {formatMessage({ id: AppMessages.chatDeleteChannel })}
      </ContextMenuItem>
    ),
    [canEdit, formatMessage, handleDelete, isDefaultChannel]
  );

  return (
    <ContextMenu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      {header}
      {divider}
      {membersOption}
      {divider}
      {renameOption}
      {turnPrivateOption}
      {turnPublicOption}
      {divider}
      {leaveOption}
      {divider}
      {deleteOption}
    </ContextMenu>
  );
};
