import React from 'react';

import ThemeProvider from './theme';
import { LanguageProvider } from './language';
import { DateFormatProvider } from './dateFormat';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { NotificationProvider } from './notification';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <LanguageProvider>
      <DateFormatProvider>
        <ThemeProvider>
          <ToastProvider>
            <NotificationProvider>{children}</NotificationProvider>
          </ToastProvider>
        </ThemeProvider>
      </DateFormatProvider>
    </LanguageProvider>
  </AuthProvider>
);

export default AppProvider;
