import React, { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Container, Button } from './styles';
import { EMAIL_LIST_PATTERN } from '../../utils/constants';
import { AppMessages } from '../../languages';
import { useAuth } from '../../hooks/auth';
import { useApi } from '../../hooks/api';
import { usePlanLimits } from '../../hooks/plan-limits';
import { useToast } from '../../hooks/toast';
import { ModalContainer } from '../ModalContainer';
import { IconButton } from '../IconButton';
import { CloseBoxIcon, SpinnerIcon } from '../Icons';
import { StyledInput } from '../StyledInput';

interface Props {
  isOpen: boolean;
  onCloseRequest: () => void;
}

export const UserInvitations: React.FC<Props> = ({
  isOpen,
  onCloseRequest,
}) => {
  const { formatMessage } = useIntl();
  const authUser = useAuth((state) => state.user);
  const { inviteUser, registerUserAction } = useApi();
  const getUsageLimits = usePlanLimits((state) => state.getUsageLimits);
  const addToast = useToast((state) => state.addToast);
  const [inputValue, setInputValue] = useState('');
  const [sending, setSending] = useState(false);

  const isValidInput = !!inputValue.match(EMAIL_LIST_PATTERN);

  const handleInviteUsers = useCallback(async () => {
    setSending(true);

    const emails = inputValue
      .replaceAll('\n', '')
      .split(',')
      .map((email) => email.trim().toLocaleLowerCase())
      .filter((email) => !!email);

    const usageLimits = await getUsageLimits();

    const usersNewCount = emails.length + usageLimits.activeUsersUsage;
    const limitReached = usersNewCount > usageLimits.activeUsersLimit;

    if (limitReached) {
      addToast({
        type: 'info',
        title: formatMessage({ id: AppMessages.limitsActiveUsersReached }),
        description: formatMessage(
          {
            id: AppMessages.limitsActiveUsers,
          },
          {
            limit: usageLimits.activeUsersLimit,
          }
        ),
        duration: 7000,
      });
      setSending(false);
      return;
    }

    registerUserAction({
      userId: authUser.id,
      action: 'user(s) invited',
      additionalInfo: inputValue,
    });

    const promises = emails.map((email) => {
      return inviteUser(email).then(
        (sended) => {
          return { email, sended };
        },
        (error) => {
          addToast({
            type: 'error',
            title: 'Error',
            description: error.message,
          });
          return { email, sended: false };
        }
      );
    });

    const result = await Promise.all(promises);
    const notSended = result.filter((t) => !t.sended).map((t) => t.email);

    setInputValue(notSended.join(', '));
    setSending(false);

    if (notSended.length === 0) {
      addToast({
        type: 'success',
        title: 'Success',
        description: '=)',
      });
      onCloseRequest();
    }
  }, [
    inputValue,
    authUser.id,
    formatMessage,
    getUsageLimits,
    inviteUser,
    addToast,
    onCloseRequest,
    registerUserAction,
  ]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setInputValue(e.target.value);
    },
    []
  );

  useEffect(() => {
    setInputValue('');
  }, [isOpen]);

  return (
    <ModalContainer isOpen={isOpen} onClickOutside={onCloseRequest}>
      <Container onClick={(e) => e.stopPropagation()}>
        <div className="header">
          <span>
            {formatMessage({ id: AppMessages.pageOfficeInviteUsersPrompt })}
          </span>
          <IconButton iconSize={16} onClick={onCloseRequest}>
            <CloseBoxIcon />
          </IconButton>
        </div>
        <div className="content">
          <StyledInput
            className="input"
            autoFocus
            multiline
            rows="3"
            rowsMax="3"
            placeholder="user1@gmail.com, user2@gmail.com"
            value={inputValue}
            onChange={handleInputChange}
            error={!!inputValue && !isValidInput}
          />
          <br />
          <Button disabled={!isValidInput} onClick={handleInviteUsers}>
            {formatMessage({ id: AppMessages.pageOfficeInviteUsers })}
          </Button>
          {sending && (
            <div className="spinner-container">
              <SpinnerIcon />
            </div>
          )}
        </div>
      </Container>
    </ModalContainer>
  );
};
