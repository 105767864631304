import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { Emoji, emojiIndex } from 'emoji-mart';
import { Client4 } from 'mattermost-redux/client';
import WebSocketClient from 'mattermost-redux/client/websocket_client';
import { UserProfile } from 'mattermost-redux/types/users';
import { Team } from 'mattermost-redux/types/teams';
import {
  Channel,
  ChannelUnread,
  ChannelMembership,
} from 'mattermost-redux/types/channels';
import {
  PostList,
  Post,
  PostSearchResults,
} from 'mattermost-redux/types/posts';
import { Reaction } from 'mattermost-redux/types/reactions';
import { FileUploadResponse } from 'mattermost-redux/types/files';

import favicon from '../assets/favicon.ico';
import ChannelMessageWav from '../assets/channel_message.wav';
import DirectMessageWav from '../assets/direct_message.wav';
import { AppMessages } from '../languages';
import { useGetter } from './getter';
import { useApi, UserModel } from './api';
import { createContext, useContextSelector } from './context';
import { useModels } from './model';
import { useNotification } from './notification';
import { ChatChannelExplorer } from '../components/ChatChannelExplorer';
import { ChatNewChannel } from '../components/ChatNewChannel';
import { ChatChannelMembers } from '../components/ChatChannelMembers';
import { ChatChannelOptionsMenu } from '../components/ChatChannelOptionsMenu';
import { OfficePosts } from '../components/OfficePosts';

export const TYPING_UPDATE_INTERVAL = 5000;
export const DEFAULT_CHANNEL_NAME = 'town-square';
const OFFICE_CHANNEL_PREFIX = 'office-posts-';
const OFFICE_CHANNEL_COMMENTS_SUFFIX = 'comments';

interface MattermostUsers {
  [key: string]: UserProfile;
}

interface UserTyping {
  [channelId: string]: { [userId: string]: number };
}

interface Channels {
  [channelId: string]: Channel;
}

interface ChannelUnreads {
  [channelId: string]: ChannelUnread;
}

interface ChannelMyMemberships {
  [channelId: string]: ChannelMembership;
}

interface ChannelMemberships {
  [channelId: string]: ChannelMembership[];
}

interface ChannelPosts {
  [channelId: string]: PostList;
}

interface RepliedPosts {
  [postId: string]: Post | null;
}

interface DirectChannels {
  [key: string]: Channel;
}

interface FileUrls {
  [mattermostFileUrl: string]: string;
}

const fileUrls: FileUrls = {};

export type ChatWindowStatus = 'normal' | 'minimized' | 'maximized';
export type TeamChannelType = 'O' | 'P';

interface OpenedChannel {
  channel: Channel;
  priority: number;
  windowStatus: ChatWindowStatus;
  hasFocus: boolean;
}

interface MattermostContextData {
  mattermostUser?: UserProfile;
  mattermostUsers: MattermostUsers;
  userTyping: UserTyping;
  team?: Team;
  teamChannels: Channels;
  directChannels: DirectChannels;
  groupChannels: Channels;
  officeChannels: Channels;
  selectedChannel?: Channel;
  channelPosts: ChannelPosts;
  repliedPosts: RepliedPosts;
  channelMyMemberships: ChannelMyMemberships;
  channelMemberships: ChannelMemberships;
  openedChannels: OpenedChannel[];
  channelExplorerIsOpen: boolean;
  openChannelExplorer: () => void;
  closeChannelExplorer: () => void;
  createNewChannelIsOpen: boolean;
  openCreateNewChannel: () => void;
  closeCreateNewChannel: () => void;
  channelMembersId: string;
  channelMembersIsOpen: boolean;
  openChannelMembers: (channelId: string) => void;
  closeChannelMembers: () => void;
  openChannelOptionsMenu: (
    channel: Channel,
    anchorEl: EventTarget & HTMLButtonElement
  ) => void;
  getChannelDisplayName: (channel: Channel) => string;
  notifyNewMessages: boolean;
  setNotifyNewMessages: React.Dispatch<React.SetStateAction<boolean>>;
  initialize: () => void;
  getToken: () => string;
  getProseiaUser: (mattermostUserId: string) => UserModel;
  getTeamChannels: () => Promise<Channel[]>;
  getOfficeChannels: () => Promise<Channel[]>;
  officeChannelId: string;
  officeChannelIsOpen: boolean;
  joinOfficeChannel: (
    officeId: string,
    roomId?: string | undefined
  ) => Promise<Channel | undefined>;
  joinOfficeChannelComments: (officeId: string) => Promise<Channel | undefined>;
  addComment: (
    channel: Channel,
    postId: string,
    message: string,
    fileIds?: string[]
  ) => Promise<Post | undefined>;
  openOfficeChannel: (
    officeId: string,
    roomId?: string | undefined
  ) => Promise<void>;
  closeOfficeChannel: () => void;
  getChannelMembers: (channelId: string) => Promise<ChannelMembership[]>;
  getChannelUnreadsMessageCount: (channel: Channel) => number;
  getChannelUnreadsMentionCount: (channel: Channel) => number;
  viewChannel: (channel: Channel, clearUnreads: boolean) => void;
  deselectChannel: (channel: Channel) => void;
  closeChannel: (channel: Channel) => void;
  changeChannelWindowStatus: (
    channel: Channel,
    value: ChatWindowStatus
  ) => void;
  searchInChannel: (
    channel: Channel,
    text: string
  ) => Promise<PostSearchResults>;
  getPinnedPosts: (channelId: string) => Promise<PostList>;
  addToChannel: (userId: string, channelId: string) => Promise<boolean>;
  removeFromChannel: (userId: string, channelId: string) => Promise<boolean>;
  loadPostsUnread: (channelId: string) => Promise<void>;
  loadPostsBefore: (channelId: string) => Promise<void>;
  loadPostsAfter: (channelId: string) => Promise<void>;
  loadPostsAround: (channelId: string, post: Post) => Promise<void>;
  uploadFileToChannel: (
    channelId: string,
    fileName: string,
    data: string | File | Blob
  ) => Promise<FileUploadResponse>;
  sendMessage: (
    channel: Channel,
    message: string,
    fileIds?: string[],
    rootPostId?: string
  ) => Promise<Post>;
  sendTyping: (channel: Channel) => void;
  deletePost: (postId: string) => Promise<boolean>;
  pinPost: (postId: string) => Promise<boolean>;
  unpinPost: (postId: string) => Promise<boolean>;
  addReaction: (postId: string, emojiId: string) => Promise<boolean>;
  removeReaction: (postId: string, emojiId: string) => Promise<boolean>;
  getFileUrl: (fileId: string) => string;
  getFilePreviewUrl: (fileId: string) => string;
  getFilePublicLink: (fileId: string) => Promise<string>;
  getFile: (fileUrl: string) => Promise<any>;
  createDirectChannel: (userId: string) => Promise<Channel>;
  openDirectChannel: (email: string) => void;
  createTeamChannel: (
    displayName: string,
    type: TeamChannelType
  ) => Promise<Channel>;
  getOfficeChannelName: (officeId: string, roomId?: string) => string;
  createOfficeChannel: (officeId: string, roomId?: string) => Promise<Channel>;
  renameChannel: (channelId: string, newName: string) => Promise<Channel>;
  updateChannelPrivacy: (
    channelId: string,
    privacy: 'O' | 'P'
  ) => Promise<Channel>;
  deleteChannel: (channelId: string) => Promise<void>;
}

const highlightTerm = (phrase: string, term?: string): string | JSX.Element => {
  if (!term) return phrase;

  const normalizedTerm = term
    .replace(/[-/\\^$*+?!.()|[\]{}]/g, '\\$&')
    .replace(/[aáàãäâå]/gi, '[aáàãäâå]')
    .replace(/[eéèëê]/gi, '[eéèëê]')
    .replace(/[iíìïî]/gi, '[iíìïî]')
    .replace(/[oóòõöô]/gi, '[oóòõöô]')
    .replace(/[uúùüû]/gi, '[uúùüû]')
    .replace(/[cç]/gi, '[cç]');

  const highlightTermPattern = new RegExp(
    `(${normalizedTerm
      .split(' ')
      .filter((t) => !!t)
      .join('|')})`,
    'gi'
  );

  return phrase.split(highlightTermPattern).reduce((termResult, item) => {
    const isTerm = item.match(highlightTermPattern);
    return (
      <>
        {termResult}
        {isTerm ? <span className="highlight">{item}</span> : item}
      </>
    );
  }, <></>);
};

const EMOJI_PATTERN = /(:[^\s:]+(?:::skin-tone-[0-6])?:)/g;
const URL_PATTERN = /(https?:\/\/[^\s]+)/g;
const MENTION_PATTERN = /(@[-\w.]+\{\{.*?\}\})/g;

const isEmoji = (text: string) => {
  if (!text.match(EMOJI_PATTERN)) return false;
  const emojiText = text.split(':')[1];
  const emoji = emojiIndex.emojis[emojiText];
  return !!emoji;
};

const formatEmoji = (phrase: string, termToHighlight?: string) =>
  phrase.split(EMOJI_PATTERN).reduce((subPhraseResult, subPhrase) => {
    const emoji = isEmoji(subPhrase) ? (
      <Emoji key={subPhrase} emoji={subPhrase} size={24} />
    ) : undefined;
    return (
      <>
        {subPhraseResult}
        {emoji || highlightTerm(subPhrase, termToHighlight)}
      </>
    );
  }, <></>);

const formatMention = (phrase: string, termToHighlight?: string) =>
  phrase.split(MENTION_PATTERN).reduce((subPhraseResult, subPhrase) => {
    const isMention = subPhrase.match(MENTION_PATTERN);
    const [userName, display] = subPhrase
      .substr(1)
      .replace('}}', '')
      .split('{{');

    return (
      <>
        {subPhraseResult}
        {isMention ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            className="mention"
            data-toggle="tooltip"
            title={`@${userName} (${display})`}
          >
            {formatEmoji(display ?? userName, termToHighlight)}
          </a>
        ) : (
          formatEmoji(subPhrase, termToHighlight)
        )}
      </>
    );
  }, <></>);

const formatPostContentLine = (
  line: string,
  termToHighlight?: string
): JSX.Element => {
  return line.split(URL_PATTERN).reduce((phraseResult, phrase) => {
    const isLink = phrase.match(URL_PATTERN);
    return (
      <>
        {phraseResult}
        {isLink ? (
          <a href={phrase} target="_blank" rel="noopener noreferrer">
            {highlightTerm(phrase, termToHighlight)}
          </a>
        ) : (
          formatMention(phrase, termToHighlight)
        )}
      </>
    );
  }, <></>);
};

export const formatPostContent = (
  content: string,
  termToHighlight?: string
): string | JSX.Element => {
  return content.split('\n').reduce<JSX.Element | string>(
    (lineAccumulator, line) => (
      <>
        {lineAccumulator && (
          <>
            {lineAccumulator}
            <br />
          </>
        )}
        {formatPostContentLine(line, termToHighlight)}
      </>
    ),
    ''
  );
};

const MattermostContext = createContext({} as MattermostContextData);

const subscribedEvents: { [event: string]: (data: any) => void } = {};

const channelMessageAudio = new Audio(ChannelMessageWav);
const directMessageAudio = new Audio(DirectMessageWav);
directMessageAudio.volume = 0.5;

export const MattermostProvider: React.FC = ({ children }) => {
  const {
    getMattermostAccessData,
    updateMattermostChannelPrivacy,
    deleteMattermostChannel,
  } = useApi();
  const users = useModels((state) => state.users);
  const { formatMessage } = useIntl();
  const addNotification = useNotification((state) => state.addNotification);
  const [mattermostUser, setMattermostUser] = useState<UserProfile>();
  const getMattermostUser = useGetter(mattermostUser);
  const [mattermostUsers, setMattermostUsers] = useState<MattermostUsers>({});
  const getMattermostUsers = useGetter(mattermostUsers);
  const [userTyping, setUserTyping] = useState<UserTyping>({});
  const [team, setTeam] = useState<Team>();
  const [allChannels, setAllChannels] = useState<Channels>({});
  const getAllChannels = useGetter(allChannels);
  const [teamChannels, setTeamChannels] = useState<Channels>({});
  const [directChannels, setDirectChannels] = useState<DirectChannels>({});
  const getDirectChannels = useGetter(directChannels);
  const [groupChannels, setGroupChannels] = useState<Channels>({});
  const [officeChannels, setOfficeChannels] = useState<Channels>({});
  const getOfficeChannelsState = useGetter(officeChannels);
  const [selectedChannel, setSelectedChannel] = useState<Channel>();
  const getSelectedChannel = useGetter(selectedChannel);
  const [channelUnreads, setChannelUnreads] = useState<ChannelUnreads>({});
  const [channelPosts, setChannelPosts] = useState<ChannelPosts>({});
  const [repliedPosts, setRepliedPosts] = useState<RepliedPosts>({});
  const getRepliedPosts = useGetter(repliedPosts);
  const [channelMyMemberships, setChannelMyMemberships] = useState<
    ChannelMyMemberships
  >({});
  const [channelMemberships, setChannelMemberships] = useState<
    ChannelMemberships
  >({});
  const getChannelMemberships = useGetter(channelMemberships);
  const [openedChannels, setOpenedChannels] = useState<OpenedChannel[]>([]);
  const [channelExplorerIsOpen, setChannelExplorerIsOpen] = useState(false);
  const [createNewChannelIsOpen, setCreateNewChannelIsOpen] = useState(false);
  const [channelMembersIsOpen, setChannelMembersIsOpen] = useState(false);
  const [channelMembersId, setChannelMembersId] = useState('');
  const [officeChannelIsOpen, setOfficeChannelIsOpen] = useState(false);
  const [officeChannelId, setOfficeChannelId] = useState('');
  const [channelOptionsAnchorEl, setChannelOptionsAnchorEl] = useState<
    EventTarget & HTMLButtonElement
  >();
  const [currentChannelOptions, setCurrentChannelOptions] = useState<Channel>();

  const [notifyNewMessages, setNotifyNewMessages] = useState(true);

  const openChannelExplorer = useCallback(() => {
    setChannelExplorerIsOpen(true);
  }, []);

  const closeChannelExplorer = useCallback(() => {
    setChannelExplorerIsOpen(false);
  }, []);

  const openCreateNewChannel = useCallback(() => {
    setCreateNewChannelIsOpen(true);
  }, []);

  const closeCreateNewChannel = useCallback(() => {
    setCreateNewChannelIsOpen(false);
  }, []);

  const openChannelMembers = useCallback((channelId: string) => {
    setChannelMembersId(channelId);
    setChannelMembersIsOpen(true);
  }, []);

  const closeChannelMembers = useCallback(() => {
    setChannelMembersIsOpen(false);
  }, []);

  const openChannelOptionsMenu = useCallback(
    (channel: Channel, anchorEl: EventTarget & HTMLButtonElement) => {
      setCurrentChannelOptions(channel);
      setChannelOptionsAnchorEl(anchorEl);
    },
    []
  );

  const subscribeEvent = useCallback(
    (event: string, callback: (payload: any) => void) => {
      subscribedEvents[event] = callback;
    },
    []
  );

  const handleEvent = useCallback((payload: any) => {
    const callback = subscribedEvents[payload.event];

    if (!callback) {
      console.log(payload);
      return;
    }

    callback(payload);
  }, []);

  const getProseiaUser = useCallback(
    (mattermostUserId: string) => {
      const mmUser = mattermostUsers[mattermostUserId];
      if (!mmUser)
        return {
          id: 'unknown',
          firstName: 'Unknown',
          lastName: '',
          email: 'unknown',
          picture: favicon,
        } as UserModel;
      const user = users.find((t) => t.email === mmUser.email);
      if (!user)
        return {
          id: mmUser.id,
          firstName: mmUser.first_name,
          lastName: mmUser.last_name,
          email: mmUser.email,
          picture: favicon,
        } as UserModel;
      return user;
    },
    [mattermostUsers, users]
  );

  const getToken = useCallback(() => Client4.getToken(), []);

  const getChannelDisplayName = useCallback(
    (channel: Channel) => {
      if (channel.name === DEFAULT_CHANNEL_NAME)
        return formatMessage({ id: AppMessages.chatDefaultChannelName });
      return channel.display_name || channel.name;
    },
    [formatMessage]
  );

  const loadChannels = useCallback(async (teamId: string) => {
    const myChannels = await Client4.getMyChannels(teamId);
    const channels = myChannels.reduce((p, c) => ({ ...p, [c.id]: c }), {});
    setAllChannels((state) => ({ ...state, ...channels }));
  }, []);

  const loadUsers = useCallback(async (teamId: string) => {
    const mmUsers = await Client4.searchUsers('@', {
      team_id: teamId,
      limit: 999,
    });
    setMattermostUsers(
      mmUsers.reduce(
        (p, c) => ({
          ...p,
          [c.id]: c,
          [c.email]: c,
          [c.username]: c,
          [`@${c.username}`]: c,
        }),
        {}
      )
    );
  }, []);

  const initialize = useCallback(async () => {
    const { domain, accessToken, teamId } = await getMattermostAccessData();

    Client4.setUrl(`https://${domain}`);
    Client4.setToken(accessToken);
    Client4.setIncludeCookies(false);

    WebSocketClient.setConnectingCallback(() =>
      console.log('chat connecting...')
    );
    WebSocketClient.setFirstConnectCallback(() =>
      console.log('chat connected')
    );
    WebSocketClient.setReconnectCallback(async () => {
      console.log('chat reconnected');

      setOpenedChannels([]);
      setChannelPosts({});
      setRepliedPosts({});
      setChannelUnreads({});
      setChannelMyMemberships({});
      setChannelMemberships({});

      await loadUsers(teamId);
      await loadChannels(teamId);
    });
    WebSocketClient.setErrorCallback((data: any) => console.error(data));
    WebSocketClient.setCloseCallback(() => console.log('closed'));
    WebSocketClient.setEventCallback(handleEvent);

    await WebSocketClient.initialize(accessToken, {
      connectionUrl: `wss://${domain}/api/v4/websocket`,
    });

    const currentUser = await Client4.getMe();
    const currentTeam = await Client4.getTeam(teamId);

    setMattermostUser(currentUser);
    await loadUsers(teamId);
    await loadChannels(teamId);
    setTeam(currentTeam);
  }, [getMattermostAccessData, handleEvent, loadChannels, loadUsers]);

  const isOfficeChannel = useCallback((channel: Channel) => {
    return channel.name.startsWith(OFFICE_CHANNEL_PREFIX);
  }, []);

  useEffect(() => {
    if (!mattermostUser) return;
    const myChannels = Object.keys(allChannels).map((key) => allChannels[key]);

    const publics = myChannels.reduce((p, c) => {
      if (c.type !== 'O' || isOfficeChannel(c)) return p;
      return { ...p, [c.id]: c };
    }, {});

    const privates = myChannels.reduce((p, c) => {
      if (c.type !== 'P') return p;
      return { ...p, [c.id]: c };
    }, {});

    const groups = myChannels.reduce((p, c) => {
      if (c.type !== 'G') return p;
      return { ...p, [c.id]: c };
    }, {});

    const offices = myChannels.reduce((p, c) => {
      if (c.type !== 'O' || !isOfficeChannel(c)) return p;
      return { ...p, [c.id]: c, [c.name]: c };
    }, {});

    const directs = myChannels.reduce((p, c) => {
      if (c.type !== 'D') return p;
      const userId = c.name
        .replace(`${mattermostUser.id}__`, '')
        .replace(`__${mattermostUser.id}`, '');
      const user = mattermostUsers[userId];
      if (!user) return p;
      return { ...p, [user.id]: c, [user.email]: c };
    }, {});

    setTeamChannels({ ...publics, ...privates });
    setGroupChannels({ ...groups });
    setOfficeChannels({ ...offices });
    setDirectChannels({ ...directs });

    if (myChannels.length === 0) return;

    setOpenedChannels((state) => {
      if (state.length === 0) return state;
      const newState = [...state];
      newState.forEach((t) => {
        const i = newState.indexOf(t);
        if (!allChannels[t.channel.id]) {
          newState.splice(i, 1);
          return;
        }
        newState[i].channel = allChannels[t.channel.id];
      });
      return newState;
    });
  }, [allChannels, mattermostUser, mattermostUsers, isOfficeChannel]);

  const sendMessage = useCallback(
    (
      channel: Channel,
      message: string,
      fileIds: string[] = [],
      replyTo?: string
    ) => {
      const post = {
        channel_id: channel.id,
        message,
        file_ids: fileIds,
      } as Post;
      if (replyTo) post.props = { replyTo };
      return Client4.createPost(post);
    },
    []
  );

  const sendTyping = useCallback((channel: Channel) => {
    const parentId: any = undefined;
    WebSocketClient.userTyping(channel.id, parentId);
  }, []);

  const uploadFileToChannel = useCallback(
    (channelId: string, fileName: string, data: string | File | Blob) => {
      Client4.trackEvent('api', 'api_files_upload');

      const formData = new FormData();
      formData.append('files', data, fileName);
      formData.append('channel_id', channelId);

      const request = {
        method: 'post',
        body: formData,
      };

      return Client4.doFetch<FileUploadResponse>(
        `${Client4.getFilesRoute()}?channel_id=${channelId}&filename=${fileName}`,
        request
      );
    },
    []
  );

  const deletePost = useCallback(async (postId: string) => {
    await Client4.deletePost(postId);
    return true;
  }, []);

  const pinPost = useCallback(async (postId: string) => {
    await Client4.pinPost(postId);
    return true;
  }, []);

  const unpinPost = useCallback(async (postId: string) => {
    await Client4.unpinPost(postId);
    return true;
  }, []);

  const addReaction = useCallback(
    async (postId: string, emojiId: string) => {
      if (!mattermostUser) return false;
      await Client4.addReaction(mattermostUser.id, postId, emojiId);
      return true;
    },
    [mattermostUser]
  );

  const removeReaction = useCallback(
    async (postId: string, emojiId: string) => {
      if (!mattermostUser) return false;
      await Client4.removeReaction(mattermostUser.id, postId, emojiId);
      return true;
    },
    [mattermostUser]
  );

  const getFile = useCallback(async (fileUrl: string) => {
    if (fileUrls[fileUrl]) return fileUrls[fileUrl];
    const res = await fetch(fileUrl, Client4.getOptions({ method: 'get' }));
    const internalUrl = URL.createObjectURL(await res.blob());
    fileUrls[fileUrl] = internalUrl;
    return internalUrl;
  }, []);

  const getFileUrl = useCallback((fileId: string) => {
    if (!Client4.getUrl()) return '';
    return Client4.getFileUrl(fileId, 0);
  }, []);

  const getFilePreviewUrl = useCallback((fileId: string) => {
    if (!Client4.getUrl()) return '';
    return Client4.getFilePreviewUrl(fileId, 0);
  }, []);

  const getFilePublicLink = useCallback(async (fileId: string) => {
    if (!Client4.getUrl()) return '';
    const result = await Client4.getFilePublicLink(fileId);
    return result.link;
  }, []);

  const clearChannelUnreads = useCallback(
    (channelId: string, prevChannelId?: string | undefined) => {
      Client4.getMyChannelMember(channelId).then((channelMembership) => {
        setChannelMyMemberships((state) => ({
          ...state,
          [channelId]: channelMembership,
        }));
        Client4.viewMyChannel(channelId, prevChannelId);
      });
    },
    []
  );

  const viewChannel = useCallback(
    (channel: Channel, clearUnreads: boolean) => {
      if (!Client4.getUrl()) return;

      const selectedChannelState = getSelectedChannel();

      const prevChannelId = selectedChannelState?.id;

      if (clearUnreads) {
        clearChannelUnreads(channel.id, prevChannelId);
      }

      if (isOfficeChannel(channel)) {
        return;
      }

      const windowStatus = 'normal';

      setOpenedChannels((state) => {
        const newState = [...state];
        const opened = newState.find((t) => t.channel.id === channel.id);

        newState.forEach((item, index) => {
          if (item.channel.id !== channel.id) {
            newState[index].hasFocus = false;
            if (item.windowStatus === 'maximized')
              newState[index].windowStatus = 'normal';
          }
        });

        if (!opened) {
          const newOpened: OpenedChannel = {
            channel,
            priority: Date.now(),
            windowStatus,
            hasFocus: true,
          };
          return [...newState, newOpened];
        }

        if (opened.windowStatus === 'minimized')
          opened.windowStatus = windowStatus;
        opened.priority = Date.now();
        opened.hasFocus = true;
        return newState;
      });

      setSelectedChannel(channel);
    },
    [getSelectedChannel, clearChannelUnreads, isOfficeChannel]
  );

  const deselectChannel = useCallback((channel: Channel) => {
    setSelectedChannel((state) => {
      if (!state || state.id !== channel.id) return state;
      return undefined;
    });
  }, []);

  const closeChannel = useCallback((channel: Channel) => {
    setOpenedChannels((state) => {
      const newState = [...state];
      const toRemove = newState.find((t) => t.channel.id === channel.id);
      if (!toRemove) return state;
      newState.splice(newState.indexOf(toRemove), 1);
      return newState;
    });
  }, []);

  const changeChannelWindowStatus = useCallback(
    (channel: Channel, value: ChatWindowStatus) => {
      setOpenedChannels((state) => {
        const newState = [...state];
        const toChange = newState.find((t) => t.channel.id === channel.id);
        if (!toChange) return state;
        toChange.windowStatus = value;
        toChange.priority = Date.now();
        return newState;
      });
    },
    []
  );

  const searchInChannel = useCallback(
    (channel: Channel, text: string) => {
      const teamId = team ? team.id : '';
      return Client4.searchPosts(teamId, `${text} in:${channel.name}`, false);
    },
    [team]
  );

  const getPinnedPosts = useCallback(
    (channelId: string) => Client4.getPinnedPosts(channelId),
    []
  );

  const addToChannel = useCallback(
    async (userId: string, channelId: string) => {
      const response = await Client4.addToChannel(userId, channelId);
      return !!response;
    },
    []
  );

  const removeFromChannel = useCallback(
    async (userId: string, channelId: string) => {
      const response = await Client4.removeFromChannel(userId, channelId);
      return !!response;
    },
    []
  );

  const createDirectChannel = useCallback(
    async (userId: string) => {
      const mattermostUserState = getMattermostUser();
      const myId = mattermostUserState?.id ?? '';
      return Client4.createDirectChannel([myId, userId]);
    },
    [getMattermostUser]
  );

  const openDirectChannel = useCallback(
    (email: string) => {
      const mattermostUsersState = getMattermostUsers();
      if (!mattermostUsersState[email]) return;

      const directChannelsState = getDirectChannels();
      const directChannel = directChannelsState[email];

      if (directChannel) {
        viewChannel(directChannel, false);
        return;
      }

      createDirectChannel(mattermostUsersState[email].id).then((channel) => {
        viewChannel(channel, false);
      });
    },
    [viewChannel, createDirectChannel, getDirectChannels, getMattermostUsers]
  );

  const createTeamChannel = useCallback(
    (displayName: string, type: TeamChannelType) => {
      const teamId = team ? team.id : '';
      return Client4.createChannel({
        team_id: teamId,
        name: uuid(),
        display_name: displayName,
        purpose: '',
        header: '',
        type,
      } as Channel);
    },
    [team]
  );

  const getOfficeChannelName = useCallback(
    (officeId: string, roomId?: string) => {
      return `${OFFICE_CHANNEL_PREFIX}${officeId}-${roomId || 'general'}`;
    },
    []
  );

  const createOfficeChannel = useCallback(
    (officeId: string, roomId?: string) => {
      const teamId = team ? team.id : '';
      const name = getOfficeChannelName(officeId, roomId);
      return Client4.createChannel({
        team_id: teamId,
        name,
        display_name: name,
        purpose: '',
        header: '',
        type: 'O',
      } as Channel);
    },
    [team, getOfficeChannelName]
  );

  const addChannelPost = useCallback((channelId: string, post: Post) => {
    setChannelPosts((state) => {
      const postList = state[channelId];
      if (!postList || !!postList.next_post_id) return state;
      const order = [post.id, ...postList.order];
      const posts = { ...postList.posts, [post.id]: post };
      return { ...state, [channelId]: { ...postList, order, posts } };
    });
  }, []);

  const loadPostsUnread = useCallback(
    async (channelId: string) => {
      if (!Client4.getUrl()) return;
      const userId = mattermostUser ? mattermostUser.id : '';
      const postList = await Client4.getPostsUnread(channelId, userId);
      setChannelPosts((state) => ({ ...state, [channelId]: postList }));
    },
    [mattermostUser]
  );

  const loadPostsBefore = useCallback(
    async (channelId: string) => {
      if (!Client4.getUrl()) return;
      const postList = channelPosts[channelId];
      if (!postList || !postList.prev_post_id) return;
      const oldestLoadedPostId = postList.order[postList.order.length - 1];
      const postsBefore = await Client4.getPostsBefore(
        channelId,
        oldestLoadedPostId,
        0,
        20
      );
      setChannelPosts((state) => ({
        ...state,
        [channelId]: {
          ...postList,
          prev_post_id: postsBefore.prev_post_id,
          posts: { ...postList.posts, ...postsBefore.posts },
          order: [...postList.order, ...postsBefore.order],
        },
      }));
    },
    [channelPosts]
  );

  const loadPostsAfter = useCallback(
    async (channelId: string) => {
      if (!Client4.getUrl()) return;
      const postList = channelPosts[channelId];
      if (!postList || !postList.next_post_id) return;
      const latestLoadedPostId = postList.order[0];
      const postsAfter = await Client4.getPostsAfter(
        channelId,
        latestLoadedPostId,
        0,
        20
      );
      setChannelPosts((state) => ({
        ...state,
        [channelId]: {
          ...postList,
          next_post_id: postsAfter.next_post_id,
          posts: { ...postList.posts, ...postsAfter.posts },
          order: [...postsAfter.order, ...postList.order],
        },
      }));
    },
    [channelPosts]
  );

  const loadPostsAround = useCallback(async (channelId: string, post: Post) => {
    if (!Client4.getUrl()) return;
    const postsBefore = await Client4.getPostsBefore(channelId, post.id, 0, 5);
    const postsAfter = await Client4.getPostsAfter(channelId, post.id, 0, 20);
    setChannelPosts((state) => ({
      ...state,
      [channelId]: {
        prev_post_id: postsBefore.prev_post_id,
        next_post_id: postsAfter.next_post_id,
        posts: { [post.id]: post, ...postsBefore.posts, ...postsAfter.posts },
        order: [...postsAfter.order, post.id, ...postsBefore.order],
      },
    }));
  }, []);

  const getTeamChannels = useCallback(async () => {
    if (!team) return [];
    const publicChannels = await Client4.getChannels(team.id, 0, 2000);
    return publicChannels.filter((channel) => !isOfficeChannel(channel));
  }, [team, isOfficeChannel]);

  const getOfficeChannels = useCallback(async () => {
    if (!team) return [];
    const publicChannels = await Client4.getChannels(team.id, 0, 2000);
    return publicChannels.filter((channel) => isOfficeChannel(channel));
  }, [team, isOfficeChannel]);

  const joinOfficeChannel = useCallback(
    async (officeId: string, roomId?: string): Promise<Channel | undefined> => {
      const mattermostUserState = getMattermostUser();
      const allChannelsState = getAllChannels();
      if (!mattermostUserState || !Object.keys(allChannelsState).length)
        return undefined;

      const channelName = getOfficeChannelName(officeId, roomId);

      const officeChannelsState = getOfficeChannelsState();

      if (Object.keys(officeChannelsState).includes(channelName))
        return officeChannelsState[channelName];

      const officeChannelList = await getOfficeChannels();
      let channel = officeChannelList.find((t) => t.name === channelName);

      if (channel) {
        const added = await addToChannel(mattermostUserState.id, channel.id);
        if (!added) return undefined;
      } else {
        channel = await createOfficeChannel(officeId, roomId);
      }

      return channel;
    },
    [
      getOfficeChannels,
      getOfficeChannelName,
      addToChannel,
      createOfficeChannel,
      getAllChannels,
      getMattermostUser,
      getOfficeChannelsState,
    ]
  );

  const joinOfficeChannelComments = useCallback(
    (officeId: string): Promise<Channel | undefined> => {
      return joinOfficeChannel(officeId, OFFICE_CHANNEL_COMMENTS_SUFFIX);
    },
    [joinOfficeChannel]
  );

  const addComment = useCallback(
    async (
      channel: Channel,
      postId: string,
      message: string,
      fileIds: string[] = []
    ) => {
      if (!channel) return undefined;
      const post = {
        channel_id: channel.id,
        root_id: postId,
        message,
        file_ids: fileIds,
      } as Post;
      return Client4.createPost(post);
    },
    []
  );

  // const addComment = useCallback(
  //   async (
  //     officeId: string,
  //     postId: string,
  //     message: string,
  //     fileIds: string[] = []
  //   ) => {
  //     const channel = await joinOfficeChannelComments(officeId);
  //     if (!channel) return undefined;
  //     const post = {
  //       channel_id: channel.id,
  //       root_id: postId,
  //       message,
  //       file_ids: fileIds,
  //     } as Post;
  //     return Client4.createPost(post);
  //   },
  //   [joinOfficeChannelComments]
  // );

  const openOfficeChannel = useCallback(
    async (officeId: string, roomId?: string) => {
      const channel = await joinOfficeChannel(officeId, roomId);
      if (!channel) return;
      viewChannel(channel, false);
      setOfficeChannelId(channel.id);
      setOfficeChannelIsOpen(true);
    },
    [joinOfficeChannel, viewChannel]
  );

  const closeOfficeChannel = useCallback(() => {
    setOfficeChannelIsOpen(false);
  }, []);

  const getChannelMembers = useCallback(async (channelId: string) => {
    if (!Client4.getUrl()) return [];
    const members = await Client4.getChannelMembers(channelId, 0, 2000);
    return members;
  }, []);

  const getChannelUnreads = useCallback(async (channelId: string) => {
    if (!Client4.getUrl()) return {} as ChannelUnread;
    return Client4.doFetch<ChannelUnread>(
      `${Client4.getUserRoute('me')}/channels/${channelId}/unread`,
      { method: 'get' }
    );
  }, []);

  const getChannelUnreadsMessageCount = useCallback(
    (channel: Channel) => {
      const channelUnread = channelUnreads[channel.id];
      return !channelUnread ? 0 : channelUnread.msg_count;
    },
    [channelUnreads]
  );

  const getChannelUnreadsMentionCount = useCallback(
    (channel: Channel) => {
      const channelUnread = channelUnreads[channel.id];
      return !channelUnread ? 0 : channelUnread.mention_count;
    },
    [channelUnreads]
  );

  const updateChannelUnreads = useCallback(async () => {
    if (!team) return;
    const channelIds = Object.keys(allChannels);
    const unreadChannelIds = Object.keys(channelUnreads);
    const notVerified = channelIds.filter((t) => !unreadChannelIds.includes(t));

    if (notVerified.length === 0) return;

    const channelsMemberships = await Client4.getMyChannelMembers(team.id);

    const defaultUnreads = channelsMemberships.reduce((result, item) => {
      const unread: ChannelUnread = {
        channel_id: item.channel_id,
        user_id: item.user_id,
        last_viewed_at: item.last_viewed_at,
        mention_count: 0,
        msg_count: 0,
        team_id: team.id,
        deltaMsgs: 0,
      };
      return { ...result, [item.channel_id]: unread };
    }, {});

    const withNewMessages = channelsMemberships.reduce((result, item) => {
      const channel = allChannels[item.channel_id];
      if (!channel) return result;
      if (unreadChannelIds.includes(item.channel_id)) return result;
      if (channel.last_post_at <= item.last_viewed_at) return result;
      return [...result, item.channel_id];
    }, [] as string[]);

    if (withNewMessages.length === 0) {
      setChannelUnreads((state) => ({ ...defaultUnreads, ...state }));
      return;
    }

    const newUnreads: ChannelUnreads = (
      await Promise.all(
        withNewMessages.map((channelId) =>
          getChannelUnreads(channelId).then(
            (unreads) => ({ channelId, unreads }),
            () => ({ channelId, unreads: undefined })
          )
        )
      )
    ).reduce(
      (result, item) => ({ ...result, [item.channelId]: item.unreads }),
      {}
    );

    setChannelUnreads((state) => ({
      ...defaultUnreads,
      ...state,
      ...newUnreads,
    }));
  }, [team, allChannels, channelUnreads, getChannelUnreads]);

  const renameChannel = useCallback((channelId: string, newName: string) => {
    return Client4.patchChannel(channelId, { display_name: newName });
  }, []);

  const updateChannelPrivacy = useCallback(
    (channelId: string, privacy: 'O' | 'P') => {
      return updateMattermostChannelPrivacy(channelId, privacy);
    },
    [updateMattermostChannelPrivacy]
  );

  const deleteChannel = useCallback(
    (channelId: string) => {
      return deleteMattermostChannel(channelId);
    },
    [deleteMattermostChannel]
  );

  useEffect(() => {
    updateChannelUnreads();
  }, [updateChannelUnreads]);

  // useEffect(() => {
  //   const notLoaded = Object.keys(channelUnreads).filter(
  //     (channelId) =>
  //       !!channelUnreads[channelId].msg_count && !channelPosts[channelId]
  //   );
  //   notLoaded.forEach(loadPostsUnread);
  // }, [channelUnreads, channelPosts, loadPostsUnread]);

  const updateOpenedChannels = useCallback((channels: Channel[]) => {
    if (channels.length === 0) return;
    const ids = channels.map((t) => t.id);
    setOpenedChannels((state) => {
      if (state.length === 0) return state;
      const newState = [...state];
      const openedList = newState.filter((t) => ids.includes(t.channel.id));
      if (openedList.length === 0) return state;
      openedList.forEach((t, i) => {
        const channel = channels.find((c) => c.id === t.channel.id);
        if (!channel) return;
        openedList[i].channel = channel;
      });
      return newState;
    });
  }, []);

  useEffect(() => {
    const channels = Object.keys(allChannels).map((t) => allChannels[t]);
    updateOpenedChannels(channels);
  }, [allChannels, updateOpenedChannels]);

  const updateChannelMemberships = useCallback(
    (channelId: string) => {
      getChannelMembers(channelId).then((memberships) => {
        setChannelMemberships((state) => ({
          ...state,
          [channelId]: memberships,
        }));
      });
    },
    [getChannelMembers]
  );

  useEffect(() => {
    if (!selectedChannel?.id) return;

    if (channelMemberships[selectedChannel.id]) return;

    updateChannelMemberships(selectedChannel.id);
  }, [channelMemberships, selectedChannel, updateChannelMemberships]);

  useEffect(() => {
    const postsToAdd: RepliedPosts = {};

    const notLoaded = Object.keys(channelPosts).reduce<string[]>(
      (result, channelId) => {
        const postList = channelPosts[channelId];

        const channelRepliedPosts = postList.order.reduce<string[]>(
          (postsResult, postId) => {
            const post = (postList.posts as any)[postId] as Post;

            if (!post?.props?.replyTo) return postsResult;

            const replied = (postList.posts as any)[post.props.replyTo] as Post;
            const repliedFromState = getRepliedPosts()[post.props.replyTo];

            if (repliedFromState !== undefined) return postsResult;

            if (replied) {
              postsToAdd[post.props.replyTo] = replied;
              return postsResult;
            }

            return [...postsResult, post.props.replyTo];
          },
          []
        );

        if (!channelRepliedPosts.length) return result;

        return [...result, ...channelRepliedPosts];
      },
      []
    );

    if (!Object.keys(postsToAdd).length && !notLoaded.length) return;

    const promises = notLoaded.map((postId) =>
      Client4.getPost(postId).then(
        (post) => ({ postId, post }),
        () => ({ postId, post: null })
      )
    );

    Promise.all(promises).then((posts) => {
      posts.forEach((item) => {
        postsToAdd[item.postId] = item.post;
      });

      setRepliedPosts((state) => ({ ...state, ...postsToAdd }));
    });
  }, [channelPosts, getRepliedPosts]);

  // useEffect(() => {
  //   subscribeEvent('new_user', (payload) => {
  //     const newUserId = payload.data.user_id;
  //     if (!newUserId) return;

  //     Client4.getUser(newUserId).then((newUser) => {
  //       setMattermostUsers((state) => ({
  //         ...state,
  //         [newUser.id]: newUser,
  //         [newUser.email]: newUser,
  //         [newUser.username]: newUser,
  //         [`@${newUser.username}`]: newUser,
  //       }));
  //     });
  //   });
  // }, [subscribeEvent]);

  useEffect(() => {
    subscribeEvent('user_added', (payload) => {
      const userId = payload.data.user_id;
      const channelId = payload.broadcast.channel_id;

      if (!channelId || !userId) return;

      if (getChannelMemberships()[channelId])
        updateChannelMemberships(channelId);

      if (userId !== getMattermostUser()?.id) return;

      Client4.getChannel(channelId).then((channel) => {
        if (channel.team_id && channel.team_id !== team?.id) return;
        setAllChannels((state) => ({ ...state, [channel.id]: channel }));
        Client4.viewMyChannel(channel.id);
      });
    });
  }, [
    subscribeEvent,
    getChannelMemberships,
    getMattermostUser,
    team,
    updateChannelMemberships,
  ]);

  useEffect(() => {
    subscribeEvent('user_removed', (payload) => {
      const userId = payload.broadcast.user_id;
      const channelId = payload.data.channel_id;

      if (!channelId || !userId) return;

      setChannelMemberships((state) => {
        if (!state[channelId]) return state;
        const newState = { ...state };
        if (userId === getMattermostUser()?.id) {
          delete newState[channelId];
          return newState;
        }
        return {
          ...newState,
          [channelId]: newState[channelId].filter(
            (item) => item.user_id !== userId
          ),
        };
      });

      if (userId !== getMattermostUser()?.id) return;

      setAllChannels((state) => {
        const newState = { ...state };
        delete newState[channelId];
        return newState;
      });

      setOpenedChannels((state) => {
        const newState = [...state];
        const toRemove = newState.find((t) => t.channel.id === channelId);
        if (!toRemove) return state;
        newState.splice(newState.indexOf(toRemove), 1);
        return newState;
      });
    });
  }, [subscribeEvent, getMattermostUser]);

  useEffect(() => {
    subscribeEvent('direct_added', (payload) => {
      const channelId = payload.broadcast.channel_id;

      if (!channelId) return;

      Client4.getChannel(channelId).then((channel) => {
        setAllChannels((state) => ({ ...state, [channel.id]: channel }));
      });
    });
  }, [subscribeEvent]);

  useEffect(() => {
    subscribeEvent('channel_created', (payload) => {
      const channelId = payload.data.channel_id;

      if (!channelId) return;

      Client4.getChannel(channelId).then((channel) => {
        if (channel.team_id && channel.team_id !== team?.id) return;
        setAllChannels((state) => ({ ...state, [channel.id]: channel }));
      });
    });
  }, [subscribeEvent, team]);

  useEffect(() => {
    subscribeEvent('typing', (payload) => {
      const userId = payload.data.user_id;
      const channelId = payload.broadcast.channel_id;

      if (!userId || !channelId) return;

      setUserTyping((state) => ({
        ...state,
        [channelId]: { ...state[channelId], [userId]: Date.now() },
      }));
    });
  }, [subscribeEvent]);

  useEffect(() => {
    subscribeEvent('channel_viewed', (payload) => {
      const channelId = payload.data.channel_id;

      if (!channelId || !getAllChannels()[channelId]) return;

      getChannelUnreads(channelId).then((unreads) => {
        setChannelUnreads((state) => ({ ...state, [channelId]: unreads }));
      });
    });
  }, [getAllChannels, subscribeEvent, getChannelUnreads]);

  useEffect(() => {
    subscribeEvent('channel_updated', (payload) => {
      const channelId = payload.broadcast.channel_id;
      const channel: Channel = JSON.parse(payload.data.channel);

      if (!channelId || !channel) return;
      if (channel.team_id && channel.team_id !== team?.id) return;

      setAllChannels((state) => ({ ...state, [channel.id]: channel }));
    });
  }, [subscribeEvent, team]);

  useEffect(() => {
    subscribeEvent('posted', (payload) => {
      const post: Post = JSON.parse(payload.data.post);
      const channelId = payload.broadcast.channel_id;

      if (!channelId || !post) return;

      const channel = getAllChannels()[channelId];
      if (!channel) return;

      if (channel.team_id && channel.team_id !== team?.id) return;

      setUserTyping((state) => ({
        ...state,
        [channelId]: {
          ...state[channelId],
          [post.user_id]: Date.now() - TYPING_UPDATE_INTERVAL,
        },
      }));

      addChannelPost(channelId, post);

      const selectedChannelId = getSelectedChannel()?.id;

      if (channelId === selectedChannelId) clearChannelUnreads(channelId);

      setOpenedChannels((state) => {
        const newState = [...state];
        const opened = newState.find((t) => t.channel.id === channelId);
        if (!opened) return state;
        opened.priority = Date.now();
        return newState;
      });

      getChannelUnreads(channelId)
        .then((unreads) => {
          setChannelUnreads((state) => ({ ...state, [channelId]: unreads }));
          setAllChannels((state) => ({
            ...state,
            [channelId]: { ...state[channelId], last_post_at: post.create_at },
          }));
        })
        .catch(console.error);

      if (post.user_id === getMattermostUser()?.id) return;

      if (!Object.keys(getMattermostUsers()).includes(post.user_id)) {
        Client4.getUser(post.user_id).then((newUser) => {
          setMattermostUsers((state) => ({
            ...state,
            [newUser.id]: newUser,
            [newUser.email]: newUser,
            [newUser.username]: newUser,
            [`@${newUser.username}`]: newUser,
          }));
        });
      }

      if (isOfficeChannel(channel) || !notifyNewMessages) return;

      const user = getProseiaUser(post.user_id);
      if (user.id === 'unknown' || user.id === post.user_id) return;
      const audio =
        channel.type === 'D' ? directMessageAudio : channelMessageAudio;
      const shouldNotify =
        channel.type === 'D' &&
        (document.hidden || channelId !== selectedChannelId);
      let soundPlayed = true;
      audio
        .play()
        .catch(() => {
          soundPlayed = false;
        })
        .finally(() => {
          if (shouldNotify) {
            addNotification({
              title: formatMessage(
                { id: AppMessages.chatNewMessageFrom },
                { name: `${user.firstName} ${user.lastName}`.trim() }
              ),
              body: post.message,
              silent: soundPlayed,
            });
          }
        });
    });
  }, [
    getAllChannels,
    getMattermostUser,
    getMattermostUsers,
    notifyNewMessages,
    getSelectedChannel,
    team,
    addChannelPost,
    addNotification,
    clearChannelUnreads,
    formatMessage,
    getChannelUnreads,
    getProseiaUser,
    isOfficeChannel,
    subscribeEvent,
  ]);

  useEffect(() => {
    subscribeEvent('post_deleted', (payload) => {
      const post = JSON.parse(payload.data.post) as Post;
      const channelId = payload.broadcast.channel_id as string;

      if (!post || !channelId) return;

      setChannelPosts((state) => {
        if (!state[channelId]) return state;
        if (!Object.keys(state[channelId].posts).includes(post.id))
          return state;
        const newState = { ...state };
        const newPosts = { ...newState[channelId].posts } as any;
        delete newPosts[post.id];
        newState[channelId].posts = newPosts;
        return newState;
      });
    });
  }, [subscribeEvent]);

  useEffect(() => {
    subscribeEvent('post_edited', (payload) => {
      const post = JSON.parse(payload.data.post) as Post;
      const channelId = payload.broadcast.channel_id as string;

      if (!post || !channelId) return;

      setChannelPosts((state) => {
        if (!state[channelId]) return state;
        if (!Object.keys(state[channelId].posts).includes(post.id))
          return state;
        const newState = { ...state };
        const newPosts = { ...newState[channelId].posts } as any;
        newPosts[post.id] = post;
        newState[channelId].posts = newPosts;
        return newState;
      });
    });
  }, [subscribeEvent]);

  useEffect(() => {
    subscribeEvent('reaction_added', (payload) => {
      const reaction = JSON.parse(payload.data.reaction) as Reaction;
      const channelId = payload.broadcast.channel_id as string;

      if (!reaction || !channelId) return;

      setChannelPosts((state) => {
        if (!state[channelId]) return state;
        if (!Object.keys(state[channelId].posts).includes(reaction.post_id))
          return state;
        const newState = { ...state };
        const posts = newState[channelId].posts as any;
        const post = posts[reaction.post_id] as Post;
        if (!post.metadata.reactions) post.metadata.reactions = [];
        post.metadata.reactions.push(reaction);
        return newState;
      });
    });
  }, [subscribeEvent]);

  useEffect(() => {
    subscribeEvent('reaction_removed', (payload) => {
      const reaction = JSON.parse(payload.data.reaction) as Reaction;
      const channelId = payload.broadcast.channel_id as string;

      if (!reaction || !channelId) return;

      setChannelPosts((state) => {
        if (!state[channelId]) return state;
        if (!Object.keys(state[channelId].posts).includes(reaction.post_id))
          return state;
        const newState = { ...state };
        const posts = newState[channelId].posts as any;
        const post = posts[reaction.post_id] as Post;
        post.metadata.reactions = post.metadata.reactions.filter(
          (t) =>
            !(
              t.user_id === reaction.user_id &&
              t.post_id === reaction.post_id &&
              t.emoji_name === reaction.emoji_name
            )
        );
        return newState;
      });
    });
  }, [subscribeEvent]);

  useEffect(() => {
    subscribeEvent('channel_deleted', (payload) => {
      const channelId = payload.data.channel_id;
      const teamId = payload.broadcast.team_id;

      if (!channelId) return;
      if (teamId && teamId !== team?.id) return;

      setAllChannels((state) => {
        const newState = { ...state };
        delete newState[channelId];
        return newState;
      });
    });
  }, [team, subscribeEvent]);

  const contextValue = useMemo<MattermostContextData>(
    () => ({
      mattermostUser,
      mattermostUsers,
      userTyping,
      team,
      teamChannels,
      directChannels,
      groupChannels,
      officeChannels,
      selectedChannel,
      channelPosts,
      repliedPosts,
      channelMyMemberships,
      channelMemberships,
      openedChannels,
      channelExplorerIsOpen,
      createNewChannelIsOpen,
      channelMembersIsOpen,
      channelMembersId,
      officeChannelIsOpen,
      officeChannelId,
      openChannelExplorer,
      closeChannelExplorer,
      openCreateNewChannel,
      closeCreateNewChannel,
      openChannelMembers,
      closeChannelMembers,
      openChannelOptionsMenu,
      getChannelDisplayName,
      notifyNewMessages,
      setNotifyNewMessages,
      initialize,
      getToken,
      getProseiaUser,
      getTeamChannels,
      getOfficeChannels,
      joinOfficeChannel,
      joinOfficeChannelComments,
      addComment,
      openOfficeChannel,
      closeOfficeChannel,
      getChannelMembers,
      getChannelUnreadsMessageCount,
      getChannelUnreadsMentionCount,
      viewChannel,
      deselectChannel,
      closeChannel,
      changeChannelWindowStatus,
      searchInChannel,
      getPinnedPosts,
      addToChannel,
      removeFromChannel,
      loadPostsUnread,
      loadPostsBefore,
      loadPostsAfter,
      loadPostsAround,
      uploadFileToChannel,
      sendMessage,
      sendTyping,
      deletePost,
      pinPost,
      unpinPost,
      addReaction,
      removeReaction,
      getFileUrl,
      getFilePreviewUrl,
      getFilePublicLink,
      getFile,
      createDirectChannel,
      openDirectChannel,
      createTeamChannel,
      getOfficeChannelName,
      createOfficeChannel,
      renameChannel,
      updateChannelPrivacy,
      deleteChannel,
    }),
    [
      mattermostUser,
      mattermostUsers,
      userTyping,
      team,
      teamChannels,
      directChannels,
      groupChannels,
      officeChannels,
      selectedChannel,
      channelPosts,
      repliedPosts,
      channelMyMemberships,
      channelMemberships,
      openedChannels,
      channelExplorerIsOpen,
      createNewChannelIsOpen,
      channelMembersIsOpen,
      channelMembersId,
      officeChannelIsOpen,
      officeChannelId,
      openChannelExplorer,
      closeChannelExplorer,
      openCreateNewChannel,
      closeCreateNewChannel,
      openChannelMembers,
      closeChannelMembers,
      openChannelOptionsMenu,
      getChannelDisplayName,
      notifyNewMessages,
      setNotifyNewMessages,
      initialize,
      getToken,
      getProseiaUser,
      getTeamChannels,
      getOfficeChannels,
      joinOfficeChannel,
      joinOfficeChannelComments,
      addComment,
      openOfficeChannel,
      closeOfficeChannel,
      getChannelMembers,
      getChannelUnreadsMessageCount,
      getChannelUnreadsMentionCount,
      viewChannel,
      deselectChannel,
      closeChannel,
      changeChannelWindowStatus,
      searchInChannel,
      getPinnedPosts,
      addToChannel,
      removeFromChannel,
      loadPostsUnread,
      loadPostsBefore,
      loadPostsAfter,
      loadPostsAround,
      uploadFileToChannel,
      sendMessage,
      sendTyping,
      deletePost,
      pinPost,
      unpinPost,
      addReaction,
      removeReaction,
      getFileUrl,
      getFilePreviewUrl,
      getFilePublicLink,
      getFile,
      createDirectChannel,
      openDirectChannel,
      createTeamChannel,
      getOfficeChannelName,
      createOfficeChannel,
      renameChannel,
      updateChannelPrivacy,
      deleteChannel,
    ]
  );

  const channelExplorer = useMemo(() => <ChatChannelExplorer />, []);

  const newChannel = useMemo(() => <ChatNewChannel />, []);

  const channelMembers = useMemo(() => <ChatChannelMembers />, []);

  const channelOptionsMenu = useMemo(
    () => (
      <ChatChannelOptionsMenu
        anchorEl={channelOptionsAnchorEl}
        channel={currentChannelOptions}
        onClose={() => setChannelOptionsAnchorEl(undefined)}
      />
    ),
    [channelOptionsAnchorEl, currentChannelOptions]
  );

  const officePosts = useMemo(() => <OfficePosts />, []);

  return (
    <MattermostContext.Provider value={contextValue}>
      {children}
      {channelExplorer}
      {newChannel}
      {channelMembers}
      {channelOptionsMenu}
      {officePosts}
    </MattermostContext.Provider>
  );
};

export function useMattermost<TResult>(
  selector: (state: MattermostContextData) => TResult
): TResult {
  return useContextSelector(MattermostContext, selector);
}
