export enum EBrowserType {
  CHROME = 'chrome',
  FIREFOX = 'firefox',
  SAFARI = 'safari',
  OPERA = 'opera',
  EDGE = 'edge',
  NOT_IDENTIFIED = 'not identified',
}

export function getBrowserName(): EBrowserType {
  const { userAgent } = navigator;
  let browserName: EBrowserType;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = EBrowserType.CHROME;
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = EBrowserType.FIREFOX;
  } else if (userAgent.match(/safari/i)) {
    browserName = EBrowserType.SAFARI;
  } else if (userAgent.match(/opr\//i)) {
    browserName = EBrowserType.OPERA;
  } else if (userAgent.match(/edg/i)) {
    browserName = EBrowserType.EDGE;
  } else {
    browserName = EBrowserType.NOT_IDENTIFIED;
  }

  return browserName;
}

export function isBrowserSupported(): boolean {
  const browser = getBrowserName();
  //const compatibleBrowsers = [EBrowserType.CHROME];

  //return compatibleBrowsers.includes(browser);
  return true;
}

export const CHROME_EXTENSION_ID = 'doncholeihpbilkchgpkobngcjooopgo';
export const CHROME_EXTENSION_VERSION = '1.0.2';
export const CHROME_EXTENSION_LINK = `https://chrome.google.com/webstore/detail/proseia/${CHROME_EXTENSION_ID}`;

export function checkNeedToInstallExtension(
  state: React.Dispatch<React.SetStateAction<boolean>>
): void {
  const { chrome } = window as any;

  if (!chrome?.runtime?.sendMessage) return;

  chrome.runtime.sendMessage(
    CHROME_EXTENSION_ID,
    { key: 'version' },
    (version: any) => {
      if (version !== CHROME_EXTENSION_VERSION) state(true);
    }
  );
}
