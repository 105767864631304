import { Menu, MenuItem } from '@material-ui/core';
import { transparentize } from 'polished';
import { keyframes } from 'styled-components';
import { styled, css } from '../../themes';

export type AvatarSize = 'small' | 'medium' | 'large';
export type AvatarStatus = 'available' | 'away' | 'busy' | 'inactive';
export type AvatarMood =
  | 'very sad'
  | 'sad'
  | 'neutral'
  | 'happy'
  | 'very happy';

interface ContainerProps {
  size: AvatarSize;
}

interface AvatarConnectionProps {
  size: AvatarSize;
  spacingInPixels: number;
}

interface PictureProps {
  size: AvatarSize;
  status: AvatarStatus;
  hideBorder?: boolean;
}

interface IconContainerProps {
  size: AvatarSize;
  status: AvatarStatus;
}

const sizes = {
  small: {
    pictureSize: '50px',
    borderWidth: '3px',
    iconContainerSize: '15px',
    iconSize: '13px',
  },
  medium: {
    pictureSize: '75px',
    borderWidth: '5px',
    iconContainerSize: '22.5px',
    iconSize: '18.5px',
  },
  large: {
    pictureSize: '100px',
    borderWidth: '6px',
    iconContainerSize: '30px',
    iconSize: '26px',
  },
};

export const statusColors = {
  available: 'green',
  away: '#e6c200',
  busy: '#b80002',
  inactive: '#adadad',
};

const iconColors = {
  available: '#e0e0e0',
  away: '#404040',
  busy: '#e0e0e0',
  inactive: '#404040',
};

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: right;
  width: ${(props) => sizes[props.size].pictureSize};
  height: ${(props) => sizes[props.size].pictureSize};
  min-width: ${(props) => sizes[props.size].pictureSize};
  min-height: ${(props) => sizes[props.size].pictureSize};
  border-radius: 50%;
  transition: all 0.4s ease-out;
  z-index: 0;
  cursor: pointer;

  * {
    transition: all 0.4s ease-out;
  }

  :hover {
    > img {
      box-shadow: ${(props) => props.theme.avatarGlow};
    }
  }

  &.highlight {
    > img {
      animation: ${keyframes`
        0% { box-shadow: none; }
        50% { box-shadow: 0px 0px 5px 3px orangered; }
        100% { box-shadow: none; }
      `} 0.85s ease-out infinite;
    }
  }

  &.dragging {
    .tooltip {
      display: none;
    }
    .edit-button {
      display: none;
    }
  }

  > .drag-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: transparent;
    z-index: 1;
  }

  > .hide-on-drag {
    display: none !important;
  }

  &.drop-zone > img {
    width: 110%;
    left: -5%;
    box-shadow: ${(props) => props.theme.focusGlow};
  }

  .avatar-connection {
    pointer-events: none;
  }

  &.with-right-connection > .avatar-connection.direction-right {
    display: inline-flex !important;
    &.hide-on-drag {
      display: none !important;
    }
  }

  &.with-down-connection > .avatar-connection.direction-down {
    display: inline-flex !important;
    &.hide-on-drag {
      display: none !important;
    }
  }
`;

export const AvatarConnection = styled.div<AvatarConnectionProps>`
  ${(props) => {
    const size = sizes[props.size];
    const border = parseInt(size.borderWidth.replace('px', ''), 10);
    const width = parseInt(size.pictureSize.replace('px', ''), 10);
    const radius = (width + border * 2) / 2;
    const spaceBetween = props.spacingInPixels * 2 - border * 2;

    const totalH = radius * 4 + spaceBetween + 2;
    const totalV = (radius + 1) * 2;
    const middleH = totalH / 2;
    const middleV = totalV / 2;

    const lb = `${middleH - radius * 0.5 - spaceBetween} ${middleV * 1.85}`;
    const mb = `${middleH} ${radius * 1.4 + 1}`;
    const rb = `${middleH + radius * 0.5 + spaceBetween} ${middleV * 1.85}`;
    const rt = `${middleH + radius * 0.5 + spaceBetween} ${middleV * 0.15}`;
    const mt = `${middleH} ${radius * 0.6 + 1}`;
    const lt = `${middleH - radius * 0.5 - spaceBetween} ${middleV * 0.15}`;

    return css`
      position: absolute;
      display: none;
      align-items: center;
      z-index: 0;
      top: -${border + 1}px;
      left: 0px;

      > svg {
        position: relative;
        stroke: none;
        fill: gray;
        width: ${totalH}px;
        height: ${totalV}px;
        left: ${-border - 1}px;
        z-index: 0;

        circle {
          cy: ${radius + 1}px;
          r: ${radius}px;
          z-index: 0;
        }

        circle.self {
          cx: ${radius + 1}px;
        }
        circle.other {
          cx: ${radius * 3 + spaceBetween + 1}px;
        }

        path {
          d: path('M ${lb} Q${mb} ${rb} L ${rt} Q ${mt} ${lt} Z');
          z-index: 0;
        }
      }

      > svg.direction-down {
        transform: rotate(90deg);
        top: ${radius + props.spacingInPixels - border}px;
        left: ${-radius - (spaceBetween + border * 2) / 2 - 1}px;
      }
    `;
  }};
`;

const getPictureBorder = (props: PictureProps) => {
  const { borderWidth } = sizes[props.size];
  const borderColor = statusColors[props.status];

  return `${borderWidth} solid ${borderColor}`;
};

export const Picture = styled.img<PictureProps>`
  position: relative;
  width: 100%;
  min-height: ${(props) => sizes[props.size].pictureSize};
  max-height: ${(props) => sizes[props.size].pictureSize};
  ${(props) =>
    !props.hideBorder &&
    css`
      border: ${getPictureBorder(props)};
    `}
  border-radius: 50%;
  z-index: 1;
`;

const IconContainer = styled.div<IconContainerProps>`
  position: absolute;
  width: ${(props) => sizes[props.size].iconContainerSize};
  height: ${(props) => sizes[props.size].iconContainerSize};
  background: ${(props) => statusColors[props.status]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => iconColors[props.status]};
  z-index: 1;

  > svg {
    width: ${(props) => sizes[props.size].iconSize};
    height: ${(props) => sizes[props.size].iconSize};
    fill: ${(props) => iconColors[props.status]};
  }
`;

export const MicIconContainer = styled(IconContainer)`
  left: 36%;
  top: 78%;
`;

export const MediaIconContainer = styled(IconContainer)`
  left: 66%;
  top: 66%;
`;

export const RemoteIconContainer = styled(IconContainer)`
  left: 78%;
  top: 36%;
`;

export const MoodIconContainer = styled(IconContainer)`
  left: 66%;
  top: 6%;
`;

export const EditButton = styled.button`
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 3px 5px;
  background: #00000066;
  color: white;
  border: none;
  border-radius: 50%;
  opacity: 0.5;
  z-index: 1;

  > svg {
    font-size: 20px;
    width: 20px;
    height: 20px;
    fill: white;
  }

  :hover {
    opacity: 1;
  }
`;

export const ContextMenu = styled(Menu)`
  .MuiPaper-root {
    background-color: ${(props) => props.theme.background};
  }

  .MuiDivider-root {
    background-color: ${(props) => transparentize(0.7, props.theme.textColor)};
  }

  .context-menu-header {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.textColor};
    padding: 0 10px 10px;

    > img {
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .context-menu-user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .context-menu-name {
    font-size: 16px;
    font-weight: bold;
  }

  .context-menu-role-description {
    font-size: 14px;
  }

  .context-menu-email {
    font-size: 12px;
  }
`;

function bgColorHover(color: string) {
  return `${transparentize(0.9, color)} !important`;
}

export const ContextMenuItem = styled(MenuItem)`
  color: ${(props) => props.theme.textColor} !important;
  padding: 10px 16px !important;

  :hover {
    background-color: ${(props) => bgColorHover(props.theme.textColor)};
  }

  svg {
    width: 24px;
    fill: ${(props) => props.theme.textColor};
    height: 24px;
    margin-right: 10px;
  }
`;
