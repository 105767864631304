import { useState, useCallback, useEffect, useMemo } from 'react';

export const useLoading = (): [boolean, () => void, () => void] => {
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(0);

  const startLoading = useCallback(() => {
    setLoading((state) => state + 1);
  }, []);

  const stopLoading = useCallback(() => {
    setLoading((state) => Math.min(state - 1, 0));
  }, []);

  useEffect(() => {
    setIsLoading(!!loading);
  }, [loading]);

  return useMemo(() => [isLoading, startLoading, stopLoading], [
    isLoading,
    startLoading,
    stopLoading,
  ]);
};
