import React from 'react';
import { SelectProps, makeStyles } from '@material-ui/core';
import { useThemeContext } from '../../hooks/theme';
import lightTheme from '../../themes/theme.light';
import darkTheme from '../../themes/theme.dark';

import { Container } from './styles';

const useLightStyles = makeStyles({
  paper: {
    background: lightTheme.background,
    color: lightTheme.textColor,
    paddingLeft: '10px',
    paddingRight: '10px',
    marginTop: '41px',
  },
});

const useDarkStyles = makeStyles({
  paper: {
    background: darkTheme.background,
    color: darkTheme.textColor,
    paddingLeft: '10px',
    paddingRight: '10px',
    marginTop: '41px',
  },
});

export const StyledSelect: React.FC<SelectProps> = ({ children, ...rest }) => {
  const themeTitle = useThemeContext((state) => state.theme.title);

  const useStyles =
    themeTitle === darkTheme.title ? useDarkStyles : useLightStyles;
  const classes = useStyles();

  return (
    <Container
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      MenuProps={{ classes: { paper: classes.paper } }}
    >
      {children}
    </Container>
  );
};
