import React, { useState, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { MdCheck } from 'react-icons/md';
import { CircularProgress } from '@material-ui/core';

import { AppMessages } from '../../languages';
import { useApi } from '../../hooks/api';
import { useToast } from '../../hooks/toast';
import { ProseiaPageContainer } from '../../components/ProseiaPageContainer';
import { TitleContainer } from '../../components/ProseiaPageContainer/styles';

import { SettingUpItemContainer, StepMessageContainer } from './styles';

interface RouteParams {
  encodedData?: string;
}

let verifySubdomainIssuedInterval: any;
let progressInterval: any;

const SettingUp: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const addToast = useToast((state) => state.addToast);
  const { isSubdomainIssued } = useApi();
  const { formatMessage } = useIntl();

  const [email, setEmail] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [issued, setIssued] = useState(false);
  const [progress, setProgress] = useState(0);

  const stepMessage = formatMessage(
    { id: AppMessages.stepOf },
    { current: 3, total: 3 }
  );

  const items = formatMessage({ id: AppMessages.pageSettingUpEnvironmentSteps })
    .split(';')
    .map((item) => item.trim())
    .filter((item) => !!item);

  const handleRequestError = useCallback(
    (error) => {
      const message = Array.isArray(error.message)
        ? error.message.join('\n')
        : error.message;
      addToast({
        type: 'error',
        title: 'Error',
        description: message,
        duration: 10000,
      });
      setIssued(false);
    },
    [addToast]
  );

  useEffect(() => {
    const encodedData = match.params.encodedData || '';

    if (!encodedData) return;

    try {
      const dataString = atob(encodedData);
      const data = JSON.parse(dataString);
      setEmail(data.email);
      setSubdomain(data.subdomain);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setPhoneNumber(data.phoneNumber);
      setPassword(data.password);
    } catch (error) {
      setEmail('');
      setSubdomain('');
      setFirstName('');
      setLastName('');
      setPhoneNumber('');
      setPassword('');
      addToast({
        type: 'error',
        title: 'Error',
        description: 'Invalid token',
        duration: 10000,
      });
    }
  }, [match.params.encodedData, addToast]);

  useEffect(() => {
    clearInterval(verifySubdomainIssuedInterval);
    verifySubdomainIssuedInterval = undefined;

    if (!email || !subdomain) {
      return () => {
        // DO NOTHING
      };
    }

    verifySubdomainIssuedInterval = setInterval(() => {
      isSubdomainIssued(subdomain).then(
        (isIssued) => {
          if (isIssued) {
            clearInterval(verifySubdomainIssuedInterval);
            verifySubdomainIssuedInterval = undefined;
          }
          setIssued(isIssued);
        },
        (error) => {
          clearInterval(verifySubdomainIssuedInterval);
          verifySubdomainIssuedInterval = undefined;
          handleRequestError(error);
          if (window.location.hostname === 'localhost') {
            setIssued(true);
          }
        }
      );
    }, 5000);

    return () => {
      clearInterval(verifySubdomainIssuedInterval);
      verifySubdomainIssuedInterval = undefined;
    };
  }, [email, subdomain, isSubdomainIssued, handleRequestError]);

  useEffect(() => {
    const total = issued ? 15000 : 2.5 * 60 * 1000;
    const startAt = new Date().getTime();

    progressInterval = setInterval(() => {
      const now = new Date().getTime();
      const value = ((now - startAt) / total) * 100;
      if (value >= 100) {
        clearInterval(progressInterval);
      }
      setProgress((state) => Math.min(Math.max(value, state), 100));
    }, 1000);

    return () => clearInterval(progressInterval);
  }, [issued]);

  useEffect(() => {
    if (!issued || progress < 100) return;

    const payload = {
      email,
      firstName,
      lastName,
      phoneNumber,
      password,
    };

    const token = btoa(JSON.stringify(payload));
    const url =
      window.location.hostname === 'localhost'
        ? `http://localhost:${window.location.port}/start/${token}`
        : `https://${subdomain}.proseia.app/start/${token}`;

    setTimeout(() => {
      window.location.href = url;
    }, 5000);
  }, [
    issued,
    progress,
    email,
    subdomain,
    firstName,
    lastName,
    phoneNumber,
    password,
  ]);

  return (
    <ProseiaPageContainer>
      <form>
        <TitleContainer>
          {formatMessage({
            id: AppMessages.pageSettingUpEnvironmentTitle,
          })}
        </TitleContainer>

        <br />

        {items.map((item, index) => {
          const range = (1 / items.length) * 100;
          const itemStartPercentage = index * range;
          const itemEndPercentage = itemStartPercentage + range;
          const active = itemStartPercentage <= progress;
          const concluded =
            itemEndPercentage < progress && index !== items.length - 1;

          return (
            <SettingUpItemContainer active={active} key={item}>
              {item}
              {active && concluded && <MdCheck />}
              {active && !concluded && <CircularProgress />}
            </SettingUpItemContainer>
          );
        })}

        <br />

        <br />

        <br />

        <StepMessageContainer align="center">
          {stepMessage}
        </StepMessageContainer>
      </form>
    </ProseiaPageContainer>
  );
};

export default SettingUp;
