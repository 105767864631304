import { createGlobalStyle } from 'styled-components';
import 'emoji-mart/css/emoji-mart.css';

const scrollbarThumbColor = '#888';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Inter', Arial, Helvetica, sans-serif;

    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 8px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${scrollbarThumbColor}5;
      border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${scrollbarThumbColor}d;
    }
  }

  *:hover > .tooltip {
    visibility: visible;
    opacity: 0.9;
  }

  body {
    overflow-x: hidden;
    /* seems like "overlay" is deprecated, but still works on chrome and edge */
    overflow-y: overlay;
    -webkit-font-smoothing: antialiased;
    background: white;
  }

  body, input, textarea, button {
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  a, button {
    cursor: pointer;
  }

  .tour-mask {
    opacity: 0.5;

    .reactour__mask--disable-interaction {
      outline: 2px solid red;
      z-index: 10;
    }
  }

  @media print {
    @page {
      margin: 1cm;
    }

    .pvtRenderers,
    .pvtAxisContainer,
    .pvtVals {
      display: none;
    }
  }

  #zsitc_container {
    width: 400px;
  }
`;
