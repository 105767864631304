import { transparentize } from 'polished';
import { keyframes } from 'styled-components';
import { styled, css } from '../../themes';

interface HeaderContainerProps {
  hasBackground: boolean;
}

export const HEADER_HEIGHT = 100;
const RIGHT_ASIDE_WIDTH = 269;
const LEFT_ASIDE_WIDTH = 60;
const NAVBAR_BUTTON_SIZE = 40;
const SHOW_CONTAINER_BORDERS = false;

const containerborder = css`
  ${SHOW_CONTAINER_BORDERS && 'border: solid 1px white;'}
`;

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  color: ${(props) => props.theme.textColor};

  ::before {
    content: '<div />';
    position: fixed;
    width: 100%;
    min-height: 100vh;
    background: ${(props) => props.theme.background};
  }
`;

export const HeaderContainer = styled.header<HeaderContainerProps>`
  ${containerborder}
  position: fixed;
  top: 0;
  left: ${LEFT_ASIDE_WIDTH}px;
  width: 100vw;
  max-width: calc(100% - ${LEFT_ASIDE_WIDTH}px);
  height: ${HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  transition: background 0.3s;
  z-index: 2;

  ${(props) =>
    props.hasBackground &&
    css`
      background: ${props.theme.background};
    `}
`;

export const AppActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  max-width: 100%;
`;

export const MeetingActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid ${(props) => props.theme.textColor}; */
  border-radius: 5px;
`;

export const MainContainer = styled.main`
  ${containerborder}
  position: absolute;
  top: ${HEADER_HEIGHT}px;
  left: calc(${LEFT_ASIDE_WIDTH}px + ${RIGHT_ASIDE_WIDTH}px);
  width: calc(100vw - ${RIGHT_ASIDE_WIDTH + LEFT_ASIDE_WIDTH}px);
  max-width: calc(100% - ${RIGHT_ASIDE_WIDTH + LEFT_ASIDE_WIDTH}px);

  @media (max-width: 600px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const LeftAsideContainer = styled.aside`
  ${containerborder}
  position: fixed;
  top: 0;
  left: 0;
  width: ${LEFT_ASIDE_WIDTH}px;
  height: 100vh;
  padding: 10px;
  background: ${(props) => props.theme.boxColor};
  box-shadow: ${(props) => props.theme.boxGlow};
  z-index: 2;
`;

export const NavBarBorder = styled.div`
  position: absolute;
  background-image: linear-gradient(#FF5029, #7E00C0);
  top: 0;
  left: 100%;
  width: 2px;
  height: 100vh;
`;

export const RightAsideContainer = styled.aside`
  ${containerborder}
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: ${LEFT_ASIDE_WIDTH}px;
  width: ${RIGHT_ASIDE_WIDTH}px;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  padding: 10px;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Logo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const NavBarLogoButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  border: none;
  background: none;
  width: ${NAVBAR_BUTTON_SIZE}px;
  height: ${NAVBAR_BUTTON_SIZE}px;
  padding: 0;
  opacity: 0.7;
  border-radius: 50%;

  :hover {
    opacity: 1;
  }

  &[disabled] {
    cursor: default;

    :hover {
      opacity: 0.7;
    }
  }
`;

interface NavBarButtonContainerProps {
  selected?: boolean;
}

const ANIMATION_STOP_DISPLACEMENT = '0deg';
const ANIMATION_MOVE_DISPLACEMENT = '20deg';

const animatedNotificationKeyframes = keyframes`
  0% { transform: rotate(${ANIMATION_STOP_DISPLACEMENT}); }
  1% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  2% { transform: rotate(-${ANIMATION_MOVE_DISPLACEMENT}); }
  3% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  4% { transform: rotate(-${ANIMATION_MOVE_DISPLACEMENT}); }
  5% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  6% { transform: rotate(-${ANIMATION_MOVE_DISPLACEMENT}); }
  7% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  8% { transform: rotate(${ANIMATION_STOP_DISPLACEMENT}); }

  10% { transform: rotate(${ANIMATION_STOP_DISPLACEMENT}); }
  11% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  12% { transform: rotate(-${ANIMATION_MOVE_DISPLACEMENT}); }
  13% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  14% { transform: rotate(-${ANIMATION_MOVE_DISPLACEMENT}); }
  15% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  16% { transform: rotate(-${ANIMATION_MOVE_DISPLACEMENT}); }
  17% { transform: rotate(${ANIMATION_MOVE_DISPLACEMENT}); }
  18% { transform: rotate(${ANIMATION_STOP_DISPLACEMENT}); }

  100% { transform: translateY(${ANIMATION_STOP_DISPLACEMENT}); }
`;

export const NavBarButtonContainer = styled.div<NavBarButtonContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: ${(props) =>
    !props.selected
      ? 'transparent'
      : transparentize(0.8, props.theme.textColor)};
  width: ${NAVBAR_BUTTON_SIZE}px;
  height: ${NAVBAR_BUTTON_SIZE}px;
  padding: 0;
  border-radius: 50%;

  & + & {
    margin-top: 10px;
  }

  > .animated-notification {
    animation: ${animatedNotificationKeyframes} 10s ease-out infinite;

    > .notification-count {
      position: absolute;
      color: white;
      font-size: 12px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

interface NavBarButtonProps {
  highlighted?: boolean;
  blinking?: boolean;
}

export const NavBarButton = styled.button<NavBarButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  width: 24px;
  height: 24px;
  padding: 0;
  opacity: ${(props) => (props.highlighted ? 1 : 0.7)};

  :hover {
    opacity: 1;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: ${(props) => (props.highlighted ? 'red' : props.theme.textColor)};

    ${(props) =>
      props.blinking &&
      props.highlighted &&
      css`
        animation: ${keyframes`
          0% {
            fill: ${props.theme.textColor};
            opacity: 0.7;
          }
          50% {
            fill: red;
            opacity: 1;
          }
          100% {
            fill: ${props.theme.textColor};
            opacity: 0.7;
          }
        `} 2s ease-out infinite;
      `}
  }

  &.animated-notification {
    opacity: 1;
    svg {
      fill: red;
    }
  }
`;

export const NavBarIconBadge = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  font-size: 10px;
  padding: 2px;
  min-width: 16px;
  border-radius: 50%;
  background: red;
  color: white;
  pointer-events: none;
`;
