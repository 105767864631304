import React, { useState, useRef, useEffect, useCallback } from 'react';

import { IconButton } from '../IconButton';
import { EditIcon } from '../Icons';

import { Container, Input, StyledLabel, TextAlign } from './styles';

const KEY_CODE_ENTER = 13;
const KEY_CODE_ESC = 27;

interface Props {
  value?: string;
  placeholder?: string;
  textAlign?: TextAlign;
  className?: string;
  fontSizePx?: number;
  onChange?: (newValue?: string) => void;
  canEdit?: () => Promise<boolean>;
}

export const EditableLabel: React.FC<Props> = ({
  value,
  placeholder,
  textAlign = 'center',
  className,
  fontSizePx = 14,
  onChange,
  canEdit,
}) => {
  const [stateValue, setStateValue] = useState(value);
  const [showEditableInput, setShowEditableInput] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStateValue(event.target.value);
    },
    []
  );

  useEffect(() => {
    setStateValue(value);
  }, [value]);

  const changeToEditableMode = useCallback(
    async (
      // event: React.MouseEvent<HTMLDivElement, MouseEvent>
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.stopPropagation();

      if (canEdit && !(await canEdit())) return;

      setShowEditableInput(true);
    },
    [canEdit]
  );

  const notPropagateEvent = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      event.stopPropagation();
    },
    []
  );

  const changeToViewOnlyMode = useCallback(() => {
    setStateValue(value);
    setShowEditableInput(false);
  }, [value]);

  const handleTyping = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === KEY_CODE_ENTER) {
        if (onChange) {
          onChange(inputRef.current?.value);
        }
        setShowEditableInput(false);
      } else if (e.keyCode === KEY_CODE_ESC) {
        setStateValue(value);
        setShowEditableInput(false);
      }
    },
    [onChange, value]
  );

  useEffect(() => {
    if (!inputRef.current || !showEditableInput) return;
    inputRef.current.select();
  }, [showEditableInput]);

  return (
    <Container
      className={className}
      // onClick={changeToEditableMode}
      textAlign={textAlign}
    >
      {showEditableInput && (
        <Input
          ref={inputRef}
          textAlign={textAlign}
          fontSizePx={fontSizePx}
          onClick={notPropagateEvent}
          onKeyDown={handleTyping}
          placeholder={placeholder}
          value={stateValue}
          onChange={handleInputChange}
          onBlur={changeToViewOnlyMode}
        />
      )}
      {!showEditableInput && (
        <StyledLabel fontSizePx={fontSizePx}>
          {stateValue || placeholder}
          <IconButton
            iconSize={fontSizePx * 1.2}
            onClick={changeToEditableMode}
          >
            <EditIcon />
          </IconButton>
        </StyledLabel>
      )}
    </Container>
  );
};
