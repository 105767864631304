import { styled } from '../../themes';

interface ContainerProps {
  spacingInPixels: number;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;

  > .avatar {
    margin: ${(props) => props.spacingInPixels}px;
  }
`;
