import React, { useState, useRef, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { LogoSymbol } from '../LogoSymbol';
import { splitName } from '../../utils/strings';
import { AppMessages } from '../../languages';
import { useThemeContext } from '../../hooks/theme';
import { useToast } from '../../hooks/toast';
import { useOffice } from '../../hooks/office';
import { useAuth } from '../../hooks/auth';
import { useApi } from '../../hooks/api';
import { getNewMeetingId } from '../../hooks/jitsi';
import { Separator } from '../Separator';
import { UserStatus } from '../UserStatus';
import { UserStatusEditor } from '../UserStatusEditor';
import { Avatar } from '../Avatar';
import { EditableLabel } from '../EditableLabel';
import { EmailIcon, ExitIcon } from '../Icons';
import { ProfilePictureEditor } from '../ProfilePictureEditor';
import { LanguageSelector } from '../LanguageSelector';
import { EnterRoomTip } from '../TutorialTip';

import {
  Container,
  Infos,
  Name,
  RoleDescription,
  UserResumeInfo,
  AditionalInfo,
  UserStatusInfo,
  SeparatorContainer,
  OutsideArea,
  CurrentRoomContainer,
  IconTextContainer,
  PrivacyPolicyLinkContainer,
  PrivacyPolicyLink,
  AppVersionContainer,
} from './styles';

export const Profile: React.FC = () => {
  const textColor = useThemeContext((state) => state.theme.textColor);
  const background = useThemeContext((state) => state.theme.background);
  const { formatMessage } = useIntl();
  const userAditionalInfo = useRef<HTMLDivElement>(null);
  const { uploadPublicFile, getAllowProfileEditingValue } = useApi();

  const [showAditionalInfo, setShowAditionalInfo] = useState(false);
  const [editingPicture, setEditingPicture] = useState(false);
  const [editingPictureData, setEditingPictureData] = useState('');

  const addToast = useToast((state) => state.addToast);

  const getCurrentRoom = useOffice((state) => state.getCurrentRoom);
  const getUser = useOffice((state) => state.getUser);
  const getUsersInfo = useOffice((state) => state.getUsersInfo);
  const picture = useOffice((state) => state.currentUserInfo?.picture);
  const status = useOffice((state) => state.currentUserInfo?.status);
  const statusMessage = useOffice(
    (state) => state.currentUserInfo?.statusMessage || ''
  );
  const userInfoOfficeId = useOffice(
    (state) => state.currentUserInfo?.officeId || ''
  );
  const userInfoRoomId = useOffice(
    (state) => state.currentUserInfo?.roomId || ''
  );
  const userInfoGroupId = useOffice(
    (state) => state.currentUserInfo?.groupId || ''
  );
  const updateCurrentUserInfo = useOffice(
    (state) => state.updateCurrentUserInfo
  );
  const inviteToGroupMeeting = useOffice((state) => state.inviteToGroupMeeting);
  const updateUser = useOffice((state) => state.updateUser);

  const currentRoom = getCurrentRoom();
  const user = getUser();

  const signOut = useAuth((state) => state.signOut);
  const isAdmin = useAuth((state) => state.isAdmin());

  const userId = user?.id || '';
  const userFirstName = user?.firstName || '';
  const userLastName = user?.lastName || '';
  const email = user?.email;
  const roleDescription = user?.roleDescription;

  const displayName =
    `${userFirstName?.trim()} ${userLastName.trim()}`.trim() ||
    formatMessage({ id: AppMessages.componentUserManagementUnnamed });

  const updateUserName = useCallback(
    (newName: string | undefined) => {
      if (!userId || !newName?.trim()) return;

      const splittedName = splitName(newName);

      updateUser(userId, splittedName).then(() => {
        updateCurrentUserInfo(splittedName);
      });
    },
    [userId, updateUser, updateCurrentUserInfo]
  );

  const updateUserRole = useCallback(
    (newRoleDescription: string | undefined) => {
      if (!userId) return;
      updateUser(userId, { roleDescription: newRoleDescription }).then(() => {
        updateCurrentUserInfo({ roleDescription: newRoleDescription });
      });
    },
    [userId, updateUser, updateCurrentUserInfo]
  );

  const expandDiv = useCallback(() => {
    setShowAditionalInfo((showAditionalInfoState) => !showAditionalInfoState);
  }, []);

  const collapse = useCallback(() => {
    if (showAditionalInfo) setShowAditionalInfo(false);
  }, [showAditionalInfo]);

  const handleConnectionRequest = useCallback(
    (idFrom: string, idTo: string) => {
      if (idFrom === idTo) return;
      const usersInfoState = getUsersInfo();
      const groupFrom = usersInfoState[idFrom].groupId;
      const groupId = userInfoGroupId || getNewMeetingId();
      if (groupFrom === groupId) return;
      if (!userInfoOfficeId || !userInfoRoomId) return;
      inviteToGroupMeeting(idFrom, userInfoOfficeId, userInfoRoomId, groupId);
    },
    [
      userInfoGroupId,
      userInfoOfficeId,
      userInfoRoomId,
      getUsersInfo,
      inviteToGroupMeeting,
    ]
  );

  const canEditProfile = useCallback(async () => {
    if (isAdmin) return true;

    const allow = await getAllowProfileEditingValue();

    if (!allow)
      addToast({
        type: 'error',
        title: formatMessage({ id: AppMessages.notAllowed }),
        description: formatMessage({
          id: AppMessages.componentProfileEditingNotAllowed,
        }),
        duration: 5000,
      });

    return allow;
  }, [isAdmin, getAllowProfileEditingValue, addToast, formatMessage]);

  const handleEditPicture = useCallback(async (newImageData: string) => {
    setEditingPictureData(newImageData);
    setEditingPicture(true);
  }, []);

  const handleNewPicture = useCallback(
    async (croppedImage?: string) => {
      setEditingPicture(false);
      setEditingPictureData('');
      if (!croppedImage) return;
      updateCurrentUserInfo({ picture: croppedImage });
      const blob = await fetch(croppedImage).then((t) => t.blob());
      uploadPublicFile(blob)
        .then((url) => {
          updateCurrentUserInfo({ picture: url });
          return updateUser(userId, { picture: url });
        })
        .catch(console.error);
    },
    [updateCurrentUserInfo, uploadPublicFile, userId, updateUser]
  );

  const outsideArea = useMemo(
    () => showAditionalInfo && <OutsideArea onClick={collapse} />,
    [collapse, showAditionalInfo]
  );

  const avatar = useMemo(
    () => (
      <Avatar
        id={userId}
        picture={picture}
        firstName={userFirstName}
        lastName={userLastName}
        email={email}
        roleDescription={roleDescription}
        draggable
        hasEditButton={!showAditionalInfo}
        size="medium"
        status={status}
        statusMessage={statusMessage}
        onConnectionRequest={handleConnectionRequest}
        onEditPicture={handleEditPicture}
        canEditPicture={canEditProfile}
      >
        <EnterRoomTip />
      </Avatar>
    ),
    [
      email,
      handleConnectionRequest,
      handleEditPicture,
      canEditProfile,
      picture,
      roleDescription,
      showAditionalInfo,
      status,
      statusMessage,
      userFirstName,
      userId,
      userLastName,
    ]
  );

  const nameContainer = useMemo(
    () => (
      <>
        {showAditionalInfo && <Name className="user-name">{displayName}</Name>}

        {!showAditionalInfo && (
          <EditableLabel
            className="user-name"
            fontSizePx={22}
            value={displayName}
            placeholder={displayName}
            onChange={updateUserName}
            canEdit={canEditProfile}
          />
        )}
      </>
    ),
    [displayName, showAditionalInfo, updateUserName, canEditProfile]
  );

  const roleDescriptionContainer = useMemo(
    () => (
      <>
        {showAditionalInfo && (
          <RoleDescription className="user-role-description">
            {roleDescription}
          </RoleDescription>
        )}

        {!showAditionalInfo && (
          <EditableLabel
            className="user-role-description"
            placeholder={formatMessage({
              id: AppMessages.componentProfileDefineYourRole,
            })}
            value={roleDescription}
            onChange={updateUserRole}
            canEdit={canEditProfile}
          />
        )}
      </>
    ),
    [
      formatMessage,
      showAditionalInfo,
      updateUserRole,
      roleDescription,
      canEditProfile,
    ]
  );

  const resumeInfoTextContent = useMemo(
    () => (
      <Infos>
        {nameContainer}
        {roleDescriptionContainer}
      </Infos>
    ),
    [nameContainer, roleDescriptionContainer]
  );

  const resumeInfo = useMemo(
    () => (
      <UserResumeInfo
        ref={userAditionalInfo}
        className="user-resume-info"
        onClick={expandDiv}
      >
        {avatar}
        {resumeInfoTextContent}
      </UserResumeInfo>
    ),
    [avatar, expandDiv, resumeInfoTextContent]
  );

  const statusInfo = useMemo(
    () => (
      <UserStatusInfo>
        <UserStatus />
        <UserStatusEditor />
      </UserStatusInfo>
    ),
    []
  );

  const separator = useMemo(
    () => (
      <SeparatorContainer>
        <Separator />
      </SeparatorContainer>
    ),
    []
  );

  const logoSymbol = useMemo(
    () => <LogoSymbol primaryColor={textColor} secondaryColor={background} />,
    [background, textColor]
  );

  const emailContainer = useMemo(
    () => (
      <IconTextContainer className="profile-email">
        <EmailIcon />
        {email}
      </IconTextContainer>
    ),
    [email]
  );

  const currentRoomContainer = useMemo(
    () => (
      <CurrentRoomContainer className="profile-current-room">
        {logoSymbol}
        {currentRoom.name}
      </CurrentRoomContainer>
    ),
    [currentRoom.name, logoSymbol]
  );

  const signoutContainer = useMemo(
    () => (
      <IconTextContainer className="signout-button" hasAction onClick={signOut}>
        <ExitIcon />
        {formatMessage({ id: AppMessages.componentProfileSignOut })}
      </IconTextContainer>
    ),
    [formatMessage, signOut]
  );

  const languageSelector = useMemo(() => <LanguageSelector />, []);

  const bottomContainer = useMemo(
    () => (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        {signoutContainer}
        {languageSelector}
      </div>
    ),
    [languageSelector, signoutContainer]
  );

  const privacyPolicyLink = useMemo(
    () => (
      <PrivacyPolicyLinkContainer>
        <PrivacyPolicyLink
          href={`${window.location.origin}/privacy-policy`}
          target="_blank"
        >
          {formatMessage({ id: AppMessages.privacyPolicy })}
        </PrivacyPolicyLink>
      </PrivacyPolicyLinkContainer>
    ),
    [formatMessage]
  );

  const appVersionContainer = useMemo(
    () => (
      <AppVersionContainer>
        {`v${process.env.REACT_APP_VERSION}`}
      </AppVersionContainer>
    ),
    []
  );

  const aditionalInfo = useMemo(
    () => (
      <AditionalInfo
        className="aditional-info"
        top={userAditionalInfo.current?.clientHeight || 0}
        show={showAditionalInfo}
      >
        {statusInfo}
        {separator}
        {formatMessage({ id: AppMessages.componentProfileAt })}
        {currentRoomContainer}
        {separator}
        {emailContainer}
        {separator}
        {bottomContainer}
        {privacyPolicyLink}
        {appVersionContainer}
      </AditionalInfo>
    ),
    [
      bottomContainer,
      currentRoomContainer,
      emailContainer,
      formatMessage,
      privacyPolicyLink,
      separator,
      showAditionalInfo,
      statusInfo,
      appVersionContainer,
    ]
  );

  const pictureEditor = useMemo(
    () => (
      <ProfilePictureEditor
        isOpen={editingPicture}
        image={editingPictureData}
        onCloseRequest={handleNewPicture}
      />
    ),
    [editingPicture, editingPictureData, handleNewPicture]
  );

  return (
    <Container className="profile" activated={showAditionalInfo}>
      {outsideArea}
      {resumeInfo}
      {aditionalInfo}
      {pictureEditor}
    </Container>
  );
};
