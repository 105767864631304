import React from 'react';
import { useIntl } from 'react-intl';
import { GoAlert } from 'react-icons/go';

import appStoreBadgeUS from '../../assets/images/App_Store/US/Download_on_App_Store/Black_lockup/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import appStoreBadgeES from '../../assets/images/App_Store/ES/Download_on_App_Store/Black_lockup/SVG/Download_on_the_App_Store_Badge_ES_RGB_blk_100217.svg';
import appStoreBadgePTBR from '../../assets/images/App_Store/PTBR/Download_on_App_Store/Black_lockup/SVG/Download_on_the_App_Store_Badge_PTBR_RGB_blk_092917.svg';
import playStoreBadgeUS from '../../assets/images/Play_Store/google-play-badge-en_us.png';
import playStoreBadgeES from '../../assets/images/Play_Store/google-play-badge-es_es.png';
import playStoreBadgePTBR from '../../assets/images/Play_Store/google-play-badge-pt_br.png';
import { AppMessages } from '../../languages';
import { useLanguage } from '../../hooks/language';
import { ModalContainer } from '../ModalContainer';
import { IconButton } from '../IconButton';
import { CloseBoxIcon } from '../Icons';

import { Container, StoreBadge } from './styles';

interface Props {
  isOpen: boolean;
  onCloseRequest: () => void;
}

const appStoreBadges: { [key: string]: string } = {
  'en-US': appStoreBadgeUS,
  'es-ES': appStoreBadgeES,
  'pt-BR': appStoreBadgePTBR,
};

const playStoreBadges: { [key: string]: string } = {
  'en-US': playStoreBadgeUS,
  'es-ES': playStoreBadgeES,
  'pt-BR': playStoreBadgePTBR,
};

export const SmallScreenDetectedDialog: React.FC<Props> = ({
  isOpen,
  onCloseRequest,
}) => {
  const { formatMessage } = useIntl();
  const language = useLanguage((state) => state.language);

  const message = formatMessage({
    id: AppMessages.smallScreenDetectedMessage,
  })
    .split('\n')
    .reduce<JSX.Element | string>(
      (lineAccumulator, line) => (
        <>
          {lineAccumulator && (
            <>
              {lineAccumulator}
              <br />
            </>
          )}
          {line}
        </>
      ),
      ''
    );

  const appStoreBadge = appStoreBadges[language];
  const playStoreBadge = playStoreBadges[language];

  const isIOS = /iPhone|iPad/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);

  return (
    <ModalContainer
      isOpen={isOpen}
      onClickOutside={() => {
        // do nothing
      }}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <div className="content">
          <div className="icon-container">
            <GoAlert />
          </div>
          <div className="text-container">
            {message}

            <br />

            {isIOS && (
              <a href="https://apps.apple.com/br/app/proseia/id1561194246">
                <StoreBadge src={appStoreBadge} />
              </a>
            )}

            {isAndroid && (
              <a href="https://play.google.com/store/apps/details?id=com.proseia">
                <StoreBadge src={playStoreBadge} />
              </a>
            )}
          </div>
        </div>
        <div className="close-button">
          <IconButton iconSize={16} onClick={onCloseRequest}>
            <CloseBoxIcon />
          </IconButton>
        </div>
      </Container>
    </ModalContainer>
  );
};
