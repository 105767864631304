/* eslint-disable no-alert */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { AppMessages } from '../languages';
import { useGetter } from './getter';
import { useApi, OfficeModel, RoomModel, UserModel, AppVersions } from './api';
import { createContext, useContextSelector } from './context';
import { useAuth } from './auth';
import { useLanguage } from './language';
import { useToast } from './toast';
import { SocketProvider, useSocket } from './socket';
import { ToastInviteProvider, useToastInvite } from './toastInvite';
import { TutorialProvider } from './tutorial';
import { MattermostProvider, useMattermost } from './mattermost';
import { JitsiProvider, useJitsi } from './jitsi';
import { ReportsProvider } from './reports';
import { PlanLimitsProvider } from './plan-limits';
import { usePersistentState } from './persistentState';
import {
  defaultRoom,
  mobileRoom,
  ModelsProvider,
  useModels,
  officeDefaultRooms,
} from './model';
import { AvatarProps } from '../components/AvatarGroup';
import { ExtensionNeededDialog } from '../components/ExtensionNeededDialog';
import { SmallScreenDetectedDialog } from '../components/SmallScreenDetectedDialog';
import {
  checkNeedToInstallExtension,
  CHROME_EXTENSION_LINK,
  CHROME_EXTENSION_ID,
} from '../utils/browser';

// const CHROME_EXTENSION_ID = 'doncholeihpbilkchgpkobngcjooopgo';
// const CHROME_EXTENSION_VERSION = '1.0.2';
// const CHROME_EXTENSION_LINK = `https://chrome.google.com/webstore/detail/proseia/${CHROME_EXTENSION_ID}`;
const ONE_MINUTE = 60;
const IDLE_INTERVAL_IN_SECONDS = ONE_MINUTE * 5;
const DEFAULT_IDLE_STATE = 'active';

let previousStatus: Pick<AvatarProps, 'status' | 'statusMessage'> = {
  status: 'available',
  statusMessage: '',
};

export interface UserInfoProps extends AvatarProps {
  officeId?: string;
  roomId?: string;
  groupId?: string;
  lastUpdate: number;
  previousOfficeId?: string;
  previousRoomId?: string;
}

interface UsersInfo {
  [userId: string]: UserInfoProps;
}

interface AnonymousUsersInfo {
  [userId: string]: {
    [clientId: string]: UserInfoProps;
  };
}

interface AnonymousUserInfoSockets {
  [clientId: string]: UserInfoProps;
}

interface RoomUsers {
  [officeId: string]: {
    [roomId: string]: UserInfoProps[];
  };
}

interface RoomGroupedUsers {
  [officeId: string]: {
    [roomId: string]: {
      [groupId: string]: UserInfoProps[];
    };
  };
}

interface OfficeContextData {
  appVersions: AppVersions | undefined;
  usersInfo: UsersInfo;
  setUsersInfo: React.Dispatch<React.SetStateAction<UsersInfo>>;
  getUsersInfo: () => UsersInfo;
  anonymousUsersInfo: AnonymousUsersInfo;
  setAnonymousUsersInfo: React.Dispatch<
    React.SetStateAction<AnonymousUsersInfo>
  >;
  getAnonymousUsersInfo: () => AnonymousUsersInfo;
  currentUserInfo: UserInfoProps;
  updateCurrentUserInfo: (partialUserInfo: Partial<UserInfoProps>) => void;
  favoriteRooms: Record<string, string[]>;
  setFavoriteRooms: React.Dispatch<
    React.SetStateAction<Record<string, string[]>>
  >;
  filteredUsers: UserModel[];
  setFilteredUsers: React.Dispatch<React.SetStateAction<UserModel[]>>;
  shouldHighlightFiltered: boolean;
  setShouldHighlightFiltered: React.Dispatch<React.SetStateAction<boolean>>;
  userMoodIsOpen: boolean;
  openUserMood: () => Promise<void>;
  closeUserMood: () => Promise<void>;
  officeEditMode: boolean;
  toggleOfficeEditMode: () => void;
  roomUsers: RoomUsers;
  roomGroupedUsers: RoomGroupedUsers;
  getRoomUsers: (officeId: string, roomId: string) => UserInfoProps[];
  getRoomGroupedUsers: (
    officeId: string,
    roomId: string
  ) => { [groupId: string]: UserInfoProps[] };
  getCurrentOffice: () => OfficeModel;
  getCurrentRoom: () => RoomModel;
  getUser: () => UserModel;
  enterOfficeRoom: (officeId: string, roomId: string) => void;
  enterGroupMeeting: (
    officeId: string,
    roomId: string,
    meetingId: string,
    meetingContainerStartVisible?: boolean,
    startMuted?: boolean,
    disconnectWhenAlone?: boolean,
    considerNumberOfUsersLimit?: boolean
  ) => void;
  inviteToGroupMeeting: (
    userId: string,
    officeId: string,
    roomId: string,
    meetingId: string
  ) => void;
  acceptGroupMeetingInvite: (
    userId: string,
    officeId: string,
    roomId: string,
    meetingId: string
  ) => void;
  declineGroupMeetingInvite: (
    userId: string,
    officeId: string,
    roomId: string,
    meetingId: string
  ) => void;
  cancelGroupMeetingInvite: (
    userId: string,
    officeId: string,
    roomId: string,
    meetingId: string
  ) => void;
  updateUser: (userId: string, props: Partial<UserModel>) => Promise<string>;
  getAnonymousUserInfo: (
    anonymousUserInfoSockets: AnonymousUserInfoSockets
  ) => UserInfoProps | undefined;
}

export const getFormattedUserName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}): string => {
  let result = `${(firstName || '').trim()} ${(lastName || '').trim()}`.trim();
  if (result.length <= 25) return result;
  result = result
    .split(' ')
    .map((name, i, array) => {
      if (i === 0 || i === array.length - 1) return name;
      if (!name.trim()) return '';
      return `${name.trim()[0]}.`;
    })
    .join(' ');
  if (result.length <= 25) return result;
  return `${result.substr(0, 22).trim()}...`;
};

export const getRoomMeetingId = (roomId: string): string => {
  // eslint-disable-next-line no-restricted-globals
  return `room-${roomId}-${location.hostname}`;
};

const OfficeContext = createContext({} as OfficeContextData);

let enteringMeeting = false;

const OfficeProvider: React.FC = ({ children }) => {
  const authUser = useAuth((state) => state.user);
  const signOutExtensionRequired = useAuth(
    (state) => state.signOutExtensionRequired
  );
  const extensionIsRequired = useAuth((state) => state.extensionIsRequired);
  const signOut = useAuth((state) => state.signOut);
  const { usersService, getFavoriteRooms, getUsesExtensionValue } = useApi();
  const addToast = useToast((state) => state.addToast);
  const messages = useLanguage((state) => state.messages);
  const addInvite = useToastInvite((state) => state.addInvite);
  const removeInvite = useToastInvite((state) => state.removeInvite);

  const offices = useModels((state) => state.offices);
  const users = useModels((state) => state.users);
  const officesLoaded = useModels((state) => state.officesLoaded);
  const usersLoaded = useModels((state) => state.usersLoaded);
  const addUserToState = useModels((state) => state.addUserToState);
  const updateUserState = useModels((state) => state.updateUserState);
  const deleteUserFromState = useModels((state) => state.deleteUserFromState);
  const addRoomToState = useModels((state) => state.addRoomToState);
  const updateRoomState = useModels((state) => state.updateRoomState);
  const deleteRoomFromState = useModels((state) => state.deleteRoomFromState);
  const addOfficeToState = useModels((state) => state.addOfficeToState);
  const updateOfficeState = useModels((state) => state.updateOfficeState);
  const deleteOfficeFromState = useModels(
    (state) => state.deleteOfficeFromState
  );

  const setNotifyNewMessages = useMattermost(
    (state) => state.setNotifyNewMessages
  );
  const [usersInfo, setUsersInfo] = useState<UsersInfo>({});
  const getUsersInfo = useGetter(usersInfo);
  const [anonymousUsersInfo, setAnonymousUsersInfo] = useState<
    AnonymousUsersInfo
  >({});
  const getAnonymousUsersInfo = useGetter(anonymousUsersInfo);
  const [idleState, setIdleState] = useState(DEFAULT_IDLE_STATE);

  const [currentUserInfo, setCurrentUserInfo] = usePersistentState(
    'currentUserInfo',
    {
      status: 'available',
    } as UserInfoProps
  );

  const [officeEditMode, setOfficeEditMode] = useState(false);

  const [favoriteRooms, setFavoriteRooms] = useState<Record<string, string[]>>(
    {}
  );

  const connect = useSocket((state) => state.connect);
  const setOnConnectUserInfo = useSocket((state) => state.setOnConnectUserInfo);
  const onUserJoined = useSocket((state) => state.onUserJoined);
  const onUserLeft = useSocket((state) => state.onUserLeft);
  const onRegisteredUsersInfo = useSocket(
    (state) => state.onRegisteredUsersInfo
  );
  const onAnonymousUserJoined = useSocket(
    (state) => state.onAnonymousUserJoined
  );
  const onAnonymousUserLeft = useSocket((state) => state.onAnonymousUserLeft);
  const onAnonymousUsersInfo = useSocket((state) => state.onAnonymousUsersInfo);
  const onAppVersions = useSocket((state) => state.onAppVersions);
  const onPartialUserInfo = useSocket((state) => state.onPartialUserInfo);
  const onPartialAnonymousUserInfo = useSocket(
    (state) => state.onPartialAnonymousUserInfo
  );
  const onUserEnteredMeeting = useSocket((state) => state.onUserEnteredMeeting);
  const onMeetingInvite = useSocket((state) => state.onMeetingInvite);
  const onMeetingInviteAccepted = useSocket(
    (state) => state.onMeetingInviteAccepted
  );
  const onMeetingInviteDeclined = useSocket(
    (state) => state.onMeetingInviteDeclined
  );
  const onMeetingInviteCanceled = useSocket(
    (state) => state.onMeetingInviteCanceled
  );
  const onUserCreated = useSocket((state) => state.onUserCreated);
  const onUserUpdated = useSocket((state) => state.onUserUpdated);
  const onUserDeleted = useSocket((state) => state.onUserDeleted);
  const onOfficeCreated = useSocket((state) => state.onOfficeCreated);
  const onOfficeUpdated = useSocket((state) => state.onOfficeUpdated);
  const onOfficeDeleted = useSocket((state) => state.onOfficeDeleted);
  const onRoomCreated = useSocket((state) => state.onRoomCreated);
  const onRoomUpdated = useSocket((state) => state.onRoomUpdated);
  const onRoomDeleted = useSocket((state) => state.onRoomDeleted);
  const enterRoom = useSocket((state) => state.enterRoom);
  const inviteToMeeting = useSocket((state) => state.inviteToMeeting);
  const enterMeeting = useSocket((state) => state.enterMeeting);
  const acceptMeetingInvite = useSocket((state) => state.acceptMeetingInvite);
  const declineMeetingInvite = useSocket((state) => state.declineMeetingInvite);
  const cancelMeetingInvite = useSocket((state) => state.cancelMeetingInvite);
  const emitPartialUserInfo = useSocket((state) => state.emitPartialUserInfo);
  const enableMic = useSocket((state) => state.enableMic);
  const enableSound = useSocket((state) => state.enableSound);
  const disableMic = useSocket((state) => state.disableMic);
  const enableVideo = useSocket((state) => state.enableVideo);
  const disableVideo = useSocket((state) => state.disableVideo);
  const shareScreen = useSocket((state) => state.shareScreen);
  const unshareScreen = useSocket((state) => state.unshareScreen);
  const leaveMeeting = useSocket((state) => state.leaveMeeting);

  const browserHasSupport = useJitsi((state) => state.browserHasSupport);
  const isMicrophoneBlocked = useJitsi((state) => state.isMicrophoneBlocked);
  const isCameraBlocked = useJitsi((state) => state.isCameraBlocked);
  const enterJitsiMeeting = useJitsi((state) => state.enterJitsiMeeting);
  const leaveJitsiMeeting = useJitsi((state) => state.leaveJitsiMeeting);
  const onJoinJitsiMeeting = useJitsi((state) => state.onJoinJitsiMeeting);
  const onLeaveJitsiMeeting = useJitsi((state) => state.onLeaveJitsiMeeting);
  const onAudioMuteChanged = useJitsi((state) => state.onAudioMuteChanged);
  const onVideoMuteChanged = useJitsi((state) => state.onVideoMuteChanged);
  const onScreenSharedChanged = useJitsi(
    (state) => state.onScreenSharedChanged
  );
  const setMeetingContainerVisibility = useJitsi(
    (state) => state.setMeetingContainerVisibility
  );

  const [micPermissionDenied, setMicPermissionDenied] = useState(false);
  const [
    extensionNeededDialogIsOpen,
    setExtensionNeededDialogIsOpen,
  ] = useState(false);
  const [
    smallScreenDetectedDialogIsOpen,
    setSmallScreenDetectedDialogIsOpen,
  ] = useState(false);

  const [userMoodIsOpen, setUserMoodIsOpen] = useState(false);
  const [roomUsers, setRoomUsers] = useState<RoomUsers>({});
  const [roomGroupedUsers, setRoomGroupedUsers] = useState<RoomGroupedUsers>(
    {}
  );

  const [appVersions, setAppVersions] = useState<AppVersions>();

  const [usesExtension, setUsesExtension] = useState<boolean>();

  const [filteredUsers, setFilteredUsers] = useState([...users]);
  const [shouldHighlightFiltered, setShouldHighlightFiltered] = useState(false);

  const { groupId: currentGroupId, soundEnabled } = currentUserInfo;

  const openUserMood = useCallback(async () => {
    setUserMoodIsOpen(true);
  }, []);

  const closeUserMood = useCallback(async () => {
    setUserMoodIsOpen(false);
  }, []);

  const getAnonymousUserInfo = useCallback(
    (anonymousUserInfoSockets: AnonymousUserInfoSockets) => {
      const socketKeys = Object.keys(anonymousUserInfoSockets || {});
      if (!socketKeys.length) {
        return undefined;
      }

      const userInfo = anonymousUserInfoSockets[socketKeys[0]];

      return userInfo;
    },
    []
  );

  useEffect(() => {
    const activeUsers = users.filter(
      (t) => !t.accessRevokedAt && !!t.lastLoginAt
    );

    const percentage = filteredUsers.length / activeUsers.length;
    const lessThanAFifth = percentage <= 0.2;

    setShouldHighlightFiltered(lessThanAFifth);
  }, [filteredUsers.length, users]);

  useEffect(() => {
    if (!browserHasSupport()) {
      addToast({
        type: 'error',
        title: messages[AppMessages.noSupport],
        description: messages[AppMessages.userMediaNoSupport],
      });
    }
  }, [browserHasSupport, addToast, messages]);

  useEffect(() => {
    isMicrophoneBlocked().then((isDenied) => {
      if (isDenied) {
        addToast({
          type: 'error',
          title: messages[AppMessages.permissionDenied],
          description:
            messages[AppMessages.userMediaMicrophonePermissionRequired],
        });
      }
      setMicPermissionDenied(isDenied);
    });
  }, [isMicrophoneBlocked, addToast, messages]);

  useEffect(() => {
    isCameraBlocked().then((isDenied) => {
      if (isDenied) {
        addToast({
          type: 'error',
          title: messages[AppMessages.permissionDenied],
          description: messages[AppMessages.userMediaCameraPermissionRequired],
        });
      }
      setMicPermissionDenied(isDenied);
    });
  }, [isCameraBlocked, addToast, messages]);

  const toggleOfficeEditMode = useCallback(() => {
    setOfficeEditMode((enabled) => !enabled);
  }, []);

  useEffect(() => {
    setRoomUsers((oldState) => {
      let newState = Object.keys(usersInfo).reduce<RoomUsers>((res, userId) => {
        const user = usersInfo[userId];
        if (user.groupId || !user.officeId || !user.roomId) return res;
        const previousOffice = res[user.officeId] || {};
        const previousRoom = previousOffice[user.roomId] || [];
        return {
          ...res,
          [user.officeId]: {
            ...previousOffice,
            [user.roomId]: [...previousRoom, user].sort((a, b) => {
              return a.lastUpdate - b.lastUpdate;
            }),
          },
        };
      }, {});

      newState = Object.keys(anonymousUsersInfo).reduce<RoomUsers>(
        (res, userId) => {
          const user = getAnonymousUserInfo(anonymousUsersInfo[userId]);

          if (!user || !user.officeId || !user.roomId) return res;

          user.isMobile = true;

          if (user.groupId) {
            Object.keys(res).forEach((officeKey) => {
              const officeGroup = res[officeKey];
              Object.keys(officeGroup).forEach((roomKey) => {
                const roomGroup = officeGroup[roomKey];
                roomGroup.forEach((userItem, index) => {
                  if (userItem.id === userId) {
                    roomGroup.slice(index, 1);
                  }
                });
              });
            });
            return res;
          }

          if (usersInfo[userId]) return res;

          const previousOffice = res[user.officeId] || {};
          const previousRoom = previousOffice[user.roomId] || [];
          return {
            ...res,
            [user.officeId]: {
              ...previousOffice,
              [user.roomId]: [...previousRoom, user].sort((a, b) => {
                return a.lastUpdate - b.lastUpdate;
              }),
            },
          };
        },
        newState
      );

      newState = users.reduce<RoomUsers>((res, user) => {
        if (!user.lastMobileAccessAt || user.accessRevokedAt) return res;

        const lastMobileAccessAt = new Date(user.lastMobileAccessAt);
        const lastMobileLogoutAt = new Date(
          user.lastMobileLogoutAt || '2022-01-01'
        );

        if (lastMobileAccessAt.getTime() < lastMobileLogoutAt.getTime())
          return res;

        const offlineLimitDate = new Date();
        offlineLimitDate.setHours(offlineLimitDate.getHours() - 72);
        if (lastMobileAccessAt < offlineLimitDate) return res;

        const anonymousUser = getAnonymousUserInfo(anonymousUsersInfo[user.id]);
        if (usersInfo[user.id] || anonymousUser) return res;

        const currentOfficeId = currentUserInfo.officeId || 'default';
        const previousOffice = res[currentOfficeId] || {};
        const previousRoom = previousOffice[mobileRoom.id] || [];

        return {
          ...res,
          [currentOfficeId]: {
            ...previousOffice,
            [mobileRoom.id]: [
              ...previousRoom,
              ({
                ...user,
                officeId: currentOfficeId,
                roomId: mobileRoom,
                status: 'inactive',
                lastUpdate: lastMobileAccessAt,
                isMobile: true,
              } as any) as UserInfoProps,
            ].sort((a, b) => {
              return a.lastUpdate - b.lastUpdate;
            }),
          },
        };
      }, newState);

      if (JSON.stringify(oldState) === JSON.stringify(newState))
        return oldState;

      const updatedState = { ...oldState };
      const oldStateOffices = Object.keys(oldState);
      const newStateOffices = Object.keys(newState);

      oldStateOffices.forEach((officeKey) => {
        if (!newStateOffices.includes(officeKey)) {
          delete updatedState[officeKey];
          return;
        }

        const oldStateRooms = Object.keys(oldState[officeKey]);
        const newStateRooms = Object.keys(newState[officeKey]);

        oldStateRooms.forEach((roomKey) => {
          if (!newStateRooms.includes(roomKey)) {
            updatedState[officeKey] = { ...updatedState[officeKey] };
            delete updatedState[officeKey][roomKey];
            return;
          }

          const oldValue = JSON.stringify(oldState[officeKey][roomKey]);
          const newValue = JSON.stringify(newState[officeKey][roomKey]);
          if (oldValue === newValue) return;

          updatedState[officeKey] = {
            ...updatedState[officeKey],
            [roomKey]: newState[officeKey][roomKey],
          };
        });
      });

      newStateOffices.forEach((officeKey) => {
        if (!oldStateOffices.includes(officeKey)) {
          updatedState[officeKey] = newState[officeKey];
          return;
        }

        const oldStateRooms = Object.keys(oldState[officeKey]);
        const newStateRooms = Object.keys(newState[officeKey]);

        newStateRooms.forEach((roomKey) => {
          if (oldStateRooms.includes(roomKey)) return;

          updatedState[officeKey] = {
            ...updatedState[officeKey],
            [roomKey]: newState[officeKey][roomKey],
          };
        });
      });

      return updatedState;
    });
  }, [
    usersInfo,
    anonymousUsersInfo,
    getAnonymousUserInfo,
    users,
    currentUserInfo.officeId,
  ]);

  useEffect(() => {
    setRoomGroupedUsers((oldState) => {
      let newState = Object.keys(usersInfo).reduce<RoomGroupedUsers>(
        (res, userId) => {
          const user = usersInfo[userId];
          if (!user.groupId || !user.officeId || !user.roomId) return res;
          const previousOffice = res[user.officeId] || {};
          const previousRoom = previousOffice[user.roomId] || {};
          const previousGroup = previousRoom[user.groupId] || [];
          return {
            ...res,
            [user.officeId]: {
              ...previousOffice,
              [user.roomId]: {
                ...previousRoom,
                [user.groupId]: [...previousGroup, user].sort((a, b) => {
                  return a.lastUpdate - b.lastUpdate;
                }),
              },
            },
          };
        },
        {}
      );

      newState = Object.keys(anonymousUsersInfo).reduce<RoomGroupedUsers>(
        (res, userId) => {
          const user = getAnonymousUserInfo(anonymousUsersInfo[userId]);

          if (!user || !user.groupId || !user.officeId || !user.roomId)
            return res;

          user.isMobile = true;

          if (usersInfo[userId]?.groupId) return res;

          const previousOffice = res[user.officeId] || {};
          const previousRoom = previousOffice[user.roomId] || {};
          const previousGroup = previousRoom[user.groupId] || [];

          return {
            ...res,
            [user.officeId]: {
              ...previousOffice,
              [user.roomId]: {
                ...previousRoom,
                [user.groupId]: [...previousGroup, user].sort((a, b) => {
                  return a.lastUpdate - b.lastUpdate;
                }),
              },
            },
          };
        },
        newState
      );

      if (JSON.stringify(oldState) === JSON.stringify(newState))
        return oldState;

      const updatedState = { ...oldState };
      const oldStateOffices = Object.keys(oldState);
      const newStateOffices = Object.keys(newState);

      oldStateOffices.forEach((officeKey) => {
        if (!newStateOffices.includes(officeKey)) {
          delete updatedState[officeKey];
          return;
        }

        const oldStateRooms = Object.keys(oldState[officeKey]);
        const newStateRooms = Object.keys(newState[officeKey]);

        oldStateRooms.forEach((roomKey) => {
          if (!newStateRooms.includes(roomKey)) {
            updatedState[officeKey] = { ...updatedState[officeKey] };
            delete updatedState[officeKey][roomKey];
            return;
          }

          const oldStateGroups = Object.keys(oldState[officeKey][roomKey]);
          const newStateGroups = Object.keys(newState[officeKey][roomKey]);

          oldStateGroups.forEach((groupKey) => {
            if (!newStateGroups.includes(groupKey)) {
              updatedState[officeKey] = {
                ...updatedState[officeKey],
                [roomKey]: {
                  ...updatedState[officeKey][roomKey],
                },
              };
              delete updatedState[officeKey][roomKey][groupKey];
              return;
            }

            const oldValue = JSON.stringify(
              oldState[officeKey][roomKey][groupKey]
            );
            const newValue = JSON.stringify(
              newState[officeKey][roomKey][groupKey]
            );
            if (oldValue === newValue) return;

            updatedState[officeKey] = {
              ...updatedState[officeKey],
              [roomKey]: {
                ...updatedState[officeKey][roomKey],
                [groupKey]: newState[officeKey][roomKey][groupKey],
              },
            };
          });
        });
      });

      newStateOffices.forEach((officeKey) => {
        if (!oldStateOffices.includes(officeKey)) {
          updatedState[officeKey] = newState[officeKey];
          return;
        }

        const oldStateRooms = Object.keys(oldState[officeKey]);
        const newStateRooms = Object.keys(newState[officeKey]);

        newStateRooms.forEach((roomKey) => {
          if (!oldStateRooms.includes(roomKey)) {
            updatedState[officeKey] = {
              ...updatedState[officeKey],
              [roomKey]: newState[officeKey][roomKey],
            };
            return;
          }

          const oldStateGroups = Object.keys(oldState[officeKey][roomKey]);
          const newStateGroups = Object.keys(newState[officeKey][roomKey]);

          newStateGroups.forEach((groupKey) => {
            if (oldStateGroups.includes(groupKey)) return;

            updatedState[officeKey] = {
              ...updatedState[officeKey],
              [roomKey]: {
                ...updatedState[officeKey][roomKey],
                [groupKey]: newState[officeKey][roomKey][groupKey],
              },
            };
          });
        });
      });

      return updatedState;
    });
  }, [usersInfo, anonymousUsersInfo, getAnonymousUserInfo]);

  const getRoomUsers = useCallback(
    (officeId: string, roomId: string) => {
      return (roomUsers[officeId] || {})[roomId] || [];
    },
    [roomUsers]
  );

  const getRoomGroupedUsers = useCallback(
    (officeId: string, roomId: string) => {
      return (roomGroupedUsers[officeId] || {})[roomId] || {};
    },
    [roomGroupedUsers]
  );

  const getCurrentOffice = useCallback(() => {
    const placeholder: OfficeModel = {
      id: 'default',
      name: 'Office',
      rooms: [...officeDefaultRooms],
    };
    const office = offices.find((t) => t.id === currentUserInfo.officeId);
    return office || offices[0] || placeholder;
  }, [offices, currentUserInfo.officeId]);

  const getCurrentRoom = useCallback(() => {
    const office = getCurrentOffice();
    const room = office.rooms.find((t) => t.id === currentUserInfo.roomId);
    return room || office.rooms[0] || defaultRoom;
  }, [currentUserInfo.roomId, getCurrentOffice]);

  const getUser = useCallback(() => {
    const user = users.find((t) => t.id === authUser.id);
    return user || ({} as UserModel);
  }, [users, authUser]);

  const updateCurrentUserInfo = useCallback(
    (partialUserInfo: Partial<UserInfoProps>) => {
      emitPartialUserInfo(partialUserInfo);
      setCurrentUserInfo((state) => ({ ...state, ...partialUserInfo }));
    },
    [setCurrentUserInfo, emitPartialUserInfo]
  );

  const enterOfficeRoom = useCallback(
    (officeId: string, roomId: string) => {
      if (currentGroupId) {
        // eslint-disable-next-line no-restricted-globals
        const confirmed = confirm(messages[AppMessages.meetingConfirmLeave]);
        if (!confirmed) return;
        leaveJitsiMeeting(currentGroupId);
      }

      setCurrentUserInfo((state) => {
        if (!currentGroupId && soundEnabled) {
          leaveJitsiMeeting(getRoomMeetingId(state.roomId || ''));
        }

        return {
          ...state,
          officeId,
          roomId,
          previousOfficeId: undefined,
          previousRoomId: undefined,
          groupId: '',
          soundEnabled: false,
          micEnabled: false,
          videoEnabled: false,
          screenShared: false,
          lastUpdate: Date.now(),
        };
      });

      enterRoom(officeId, roomId);
    },
    [
      enterRoom,
      currentGroupId,
      leaveJitsiMeeting,
      messages,
      setCurrentUserInfo,
      soundEnabled,
    ]
  );

  const enterGroupMeeting = useCallback(
    (
      officeId: string,
      roomId: string,
      meetingId: string,
      meetingContainerStartVisible?: boolean,
      startMuted?: boolean,
      disconnectWhenAlone = true,
      considerNumberOfUsersLimit = true
    ) => {
      if (micPermissionDenied) {
        addToast({
          type: 'error',
          title: messages[AppMessages.permissionDenied],
          description:
            messages[AppMessages.userMediaMicrophonePermissionRequired],
        });
        return;
      }

      if (enteringMeeting) return;
      enteringMeeting = true;

      enterJitsiMeeting(
        meetingId || getRoomMeetingId(roomId),
        (hasPermission) => {
          if (hasPermission) {
            if (meetingId) enterMeeting(officeId, roomId, meetingId);

            setCurrentUserInfo((state) => ({
              ...state,
              officeId,
              roomId,
              groupId: meetingId,
              lastUpdate: Date.now(),
              previousOfficeId: state.officeId,
              previousRoomId: state.roomId,
            }));

            if (meetingContainerStartVisible)
              setMeetingContainerVisibility(true);
          }
        },
        undefined,
        undefined,
        startMuted,
        disconnectWhenAlone,
        considerNumberOfUsersLimit
      ).finally(() => {
        enteringMeeting = false;
      });
    },
    [
      micPermissionDenied,
      messages,
      addToast,
      enterJitsiMeeting,
      enterMeeting,
      setCurrentUserInfo,
      setMeetingContainerVisibility,
    ]
  );

  const acceptGroupMeetingInvite = useCallback(
    (userId: string, officeId: string, roomId: string, meetingId: string) => {
      acceptMeetingInvite(userId, officeId, roomId, meetingId);
      enterGroupMeeting(officeId, roomId, meetingId, true);
      removeInvite(userId);
    },
    [acceptMeetingInvite, enterGroupMeeting, removeInvite]
  );

  const declineGroupMeetingInvite = useCallback(
    (userId: string, officeId: string, roomId: string, meetingId: string) => {
      declineMeetingInvite(userId, officeId, roomId, meetingId);
      removeInvite(userId);
    },
    [declineMeetingInvite, removeInvite]
  );

  const cancelGroupMeetingInvite = useCallback(
    (userId: string, officeId: string, roomId: string, meetingId: string) => {
      cancelMeetingInvite(userId, officeId, roomId, meetingId);
      removeInvite(userId);
    },
    [cancelMeetingInvite, removeInvite]
  );

  const inviteToGroupMeeting = useCallback(
    (userId: string, officeId: string, roomId: string, meetingId: string) => {
      inviteToMeeting(userId, officeId, roomId, meetingId);
      const user = users.find((t) => t.id === userId) || ({} as UserModel);
      addInvite({
        type: 'sended',
        user,
        officeId,
        roomId,
        meetingId,
        onCancel: () => {
          cancelGroupMeetingInvite(userId, officeId, roomId, meetingId);
        },
      });
    },
    [users, inviteToMeeting, cancelGroupMeetingInvite, addInvite]
  );

  useEffect(() => {
    if (!officesLoaded) return;

    const office = getCurrentOffice();
    const room = getCurrentRoom();

    if (
      office.id !== currentUserInfo.officeId ||
      room.id !== currentUserInfo.roomId
    ) {
      enterOfficeRoom(office.id, room.id);
    }
  }, [
    currentUserInfo.officeId,
    currentUserInfo.roomId,
    officesLoaded,
    getCurrentOffice,
    getCurrentRoom,
    enterOfficeRoom,
  ]);

  useEffect(() => {
    const { id } = currentUserInfo;
    const userInfo = usersInfo[id];
    if (JSON.stringify(userInfo) !== JSON.stringify(currentUserInfo)) {
      setUsersInfo((state) => ({ ...state, [id]: currentUserInfo }));
    }
  }, [currentUserInfo, usersInfo]);

  useEffect(() => {
    setCurrentUserInfo((state) => {
      const userChanged = authUser.id !== state.id;
      const millisecondsSinceLogin =
        Date.now() - new Date(authUser.lastLoginAt).getTime();
      const justLoggedIn = millisecondsSinceLogin < 5000;

      if (userChanged || justLoggedIn) {
        const authState = {
          ...authUser,
          accessToken: undefined,
          refreshToken: undefined,
          lastLoginAt: undefined,
          roles: undefined,
        };

        return { ...state, ...authState, mood: undefined };
      }

      return state;
    });
  }, [authUser, setCurrentUserInfo]);

  useEffect(() => {
    onUserJoined((data) => {
      const { id } = data;
      setUsersInfo((state) => ({ ...state, [id]: { ...state[id], ...data } }));
    });
  }, [onUserJoined]);

  useEffect(() => {
    onUserLeft((data) => {
      setUsersInfo((state) => {
        const newState = { ...state };
        delete newState[data.id];
        return newState;
      });
    });
  }, [onUserLeft]);

  useEffect(() => {
    onRegisteredUsersInfo((data) => {
      setUsersInfo(data);
    });
  }, [onRegisteredUsersInfo]);

  useEffect(() => {
    onPartialUserInfo((data) => {
      const id = data.userId;
      if (id !== authUser.id) {
        setUsersInfo((state) => ({
          ...state,
          [id]: { ...state[id], ...data.partialUserInfo },
        }));
        return;
      }
      setCurrentUserInfo((state) => ({ ...state, ...data.partialUserInfo }));
    });
  }, [authUser.id, onPartialUserInfo, setCurrentUserInfo]);

  useEffect(() => {
    onPartialAnonymousUserInfo((data) => {
      const id = data.userId;
      const { clientId } = data;
      setAnonymousUsersInfo((state) => {
        const group = state[id] || {};
        const userInfo = group[clientId] || {};

        return {
          ...state,
          [id]: {
            ...group,
            [clientId]: { ...userInfo, ...data.partialUserInfo },
          },
        };
      });
    });
  }, [onPartialAnonymousUserInfo]);

  useEffect(() => {
    onAnonymousUserJoined((data) => {
      const { id } = data.user;
      setAnonymousUsersInfo((state) => ({
        ...state,
        [id]: { ...state[id], [data.clientId]: data.user },
      }));
    });
  }, [onAnonymousUserJoined]);

  useEffect(() => {
    onAnonymousUserLeft((data) => {
      setAnonymousUsersInfo((state) => {
        const newState = { ...state };
        const group = newState[data.user.id] || {};
        delete group[data.clientId];
        return newState;
      });
    });
  }, [onAnonymousUserLeft]);

  useEffect(() => {
    onAnonymousUsersInfo((data) => {
      setAnonymousUsersInfo(data);
    });
  }, [onAnonymousUsersInfo]);

  useEffect(() => {
    onAppVersions(setAppVersions);
  }, [onAppVersions]);

  useEffect(() => {
    onUserEnteredMeeting((data) => {
      const { user, officeId, roomId, meetingId } = data;
      setUsersInfo((state) => ({
        ...state,
        [user.id]: {
          ...state[user.id],
          officeId,
          roomId,
          groupId: meetingId,
          lastUpdate: Date.now(),
        },
      }));
    });
  }, [onUserEnteredMeeting]);

  useEffect(() => {
    onMeetingInvite((data) => {
      const { user, officeId, roomId, meetingId } = data;
      const userId = user.id;

      addInvite({
        type: 'received',
        user,
        officeId,
        roomId,
        meetingId,
        onAccept: () =>
          acceptGroupMeetingInvite(userId, officeId, roomId, meetingId),
        onCancel: () =>
          declineGroupMeetingInvite(userId, officeId, roomId, meetingId),
      });
    });
  }, [
    onMeetingInvite,
    acceptGroupMeetingInvite,
    declineGroupMeetingInvite,
    addInvite,
  ]);

  useEffect(() => {
    onMeetingInviteAccepted((data) => {
      enterGroupMeeting(data.officeId, data.roomId, data.meetingId, true);
      removeInvite(data.user.id);
    });
  }, [onMeetingInviteAccepted, enterGroupMeeting, removeInvite]);

  useEffect(() => {
    onMeetingInviteDeclined((data) => {
      const message = messages[AppMessages.inviteRejectedYourInvitation];
      removeInvite(data.user.id);
      addToast({
        type: 'info',
        title: messages[AppMessages.inviteRejected],
        description: `${data.user.firstName} ${message}`,
      });
    });
  }, [onMeetingInviteDeclined, removeInvite, addToast, messages]);

  useEffect(() => {
    onMeetingInviteCanceled((data) => {
      const message = messages[AppMessages.inviteCanceledTheInvitation];
      removeInvite(data.user.id);
      addToast({
        type: 'info',
        title: messages[AppMessages.inviteCanceled],
        description: `${data.user.firstName} ${message}`,
      });
    });
  }, [onMeetingInviteCanceled, removeInvite, addToast, messages]);

  useEffect(() => {
    onJoinJitsiMeeting((meetingId) => {
      const isRoomMeeting = meetingId.startsWith('room-');
      enableSound();
      setCurrentUserInfo((state) => ({
        ...state,
        groupId: isRoomMeeting ? '' : meetingId,
        soundEnabled: true,
      }));
    });
  }, [onJoinJitsiMeeting, setCurrentUserInfo, enableSound]);

  useEffect(() => {
    onLeaveJitsiMeeting(() => {
      leaveMeeting();
      setCurrentUserInfo((state) => {
        if (
          state.previousOfficeId !== state.officeId ||
          state.previousRoomId !== state.roomId
        ) {
          emitPartialUserInfo({
            officeId: state.previousOfficeId || state.officeId,
            roomId: state.previousRoomId || state.roomId,
          });
        }

        return {
          ...state,
          groupId: '',
          soundEnabled: false,
          micEnabled: false,
          videoEnabled: false,
          screenShared: false,
          officeId: state.previousOfficeId || state.officeId,
          roomId: state.previousRoomId || state.roomId,
          previousOfficeId: undefined,
          previousRoomId: undefined,
        };
      });
    });
  }, [
    onLeaveJitsiMeeting,
    setCurrentUserInfo,
    leaveMeeting,
    emitPartialUserInfo,
  ]);

  useEffect(() => {
    onAudioMuteChanged((muted) => {
      const action = muted ? disableMic : enableMic;
      action();
      setCurrentUserInfo((state) => ({
        ...state,
        soundEnabled: true,
        micEnabled: !muted,
      }));
    });
  }, [onAudioMuteChanged, setCurrentUserInfo, disableMic, enableMic]);

  useEffect(() => {
    onVideoMuteChanged((muted) => {
      const action = muted ? disableVideo : enableVideo;
      action();
      setCurrentUserInfo((state) => ({
        ...state,
        soundEnabled: true,
        videoEnabled: !muted,
      }));
    });
  }, [onVideoMuteChanged, setCurrentUserInfo, disableVideo, enableVideo]);

  useEffect(() => {
    onScreenSharedChanged((shared) => {
      const action = shared ? shareScreen : unshareScreen;
      action();
      setCurrentUserInfo((state) => ({
        ...state,
        screenShared: shared,
      }));
    });
  }, [onScreenSharedChanged, setCurrentUserInfo, shareScreen, unshareScreen]);

  useEffect(() => {
    onUserCreated(addUserToState);
  }, [onUserCreated, addUserToState]);

  useEffect(() => {
    onUserUpdated((userData) => {
      updateUserState(userData);
      if (userData.id === authUser.id) {
        updateCurrentUserInfo(userData);
      }
    });
  }, [authUser.id, onUserUpdated, updateUserState, updateCurrentUserInfo]);

  useEffect(() => {
    onUserDeleted(deleteUserFromState);
  }, [onUserDeleted, deleteUserFromState]);

  useEffect(() => {
    onRoomCreated(({ officeId, room }) => {
      addRoomToState(officeId, room);
    });
  }, [onRoomCreated, addRoomToState]);

  useEffect(() => {
    onRoomUpdated(({ officeId, room }) => {
      updateRoomState(officeId, room);
    });
  }, [onRoomUpdated, updateRoomState]);

  useEffect(() => {
    onRoomDeleted(({ officeId, room }) => {
      deleteRoomFromState(officeId, room);
    });
  }, [onRoomDeleted, deleteRoomFromState]);

  useEffect(() => {
    onOfficeCreated(addOfficeToState);
  }, [onOfficeCreated, addOfficeToState]);

  useEffect(() => {
    onOfficeUpdated(updateOfficeState);
  }, [onOfficeUpdated, updateOfficeState]);

  useEffect(() => {
    onOfficeDeleted(deleteOfficeFromState);
  }, [onOfficeDeleted, deleteOfficeFromState]);

  useEffect(() => {
    setCurrentUserInfo((state) => ({
      ...state,
      groupId: '',
      soundEnabled: false,
      micEnabled: false,
      videoEnabled: false,
      screenShared: false,
    }));
  }, [setCurrentUserInfo]);

  useEffect(() => {
    setOnConnectUserInfo(currentUserInfo);
  }, [currentUserInfo, setOnConnectUserInfo]);

  useEffect(() => {
    if (!officesLoaded || !usersLoaded) return;
    connect();
  }, [connect, officesLoaded, usersLoaded]);

  const updateUser = useCallback(
    (userId: string, props: Partial<UserModel>) => {
      return usersService.patch(userId, props);
    },
    [usersService]
  );

  useEffect(() => {
    getUsesExtensionValue().then(setUsesExtension);
  }, [getUsesExtensionValue]);

  useEffect(() => {
    // const { chrome } = window as any;

    // if (!chrome?.runtime?.sendMessage) return;

    // if (usesExtension) {
    //   chrome.runtime.sendMessage(
    //     CHROME_EXTENSION_ID,
    //     { key: 'version' },
    //     (version: any) => {
    //       if (version !== CHROME_EXTENSION_VERSION)
    //         setExtensionNeededDialogIsOpen(true);
    //     }
    //   );
    // }

    if (usesExtension) {
      checkNeedToInstallExtension(setExtensionNeededDialogIsOpen);
    }
  }, [messages, usesExtension]);

  useEffect(() => {
    if (window.innerHeight > 480 && window.innerWidth > 600) return;

    setSmallScreenDetectedDialogIsOpen(true);
  }, []);

  useEffect(() => {
    const { chrome } = window as any;

    if (!chrome?.runtime?.sendMessage)
      return () =>
        console.log('window does not contains chrome.runtime.sendMessage');

    let previousIdleState = DEFAULT_IDLE_STATE;

    const timeoutRef = setInterval(() => {
      chrome.runtime.sendMessage(
        CHROME_EXTENSION_ID,
        { key: 'idle', interval: IDLE_INTERVAL_IN_SECONDS },
        (state: string) => {
          if (!state) clearInterval(timeoutRef);
          if (!state || previousIdleState === state) return;
          previousIdleState = state;
          setIdleState(state);
        }
      );
    }, 1000);

    return () => clearInterval(timeoutRef);
  }, []);

  useEffect(() => {
    const { chrome } = window as any;

    if (!chrome?.runtime?.sendMessage)
      return () =>
        console.log('window does not contains chrome.runtime.sendMessage');

    let previousIdleState = DEFAULT_IDLE_STATE;
    let timeoutRef: NodeJS.Timeout;

    // eslint-disable-next-line no-unused-expressions
    (window as any).electron?.ipcRenderer
      ?.getExtensionId()
      ?.then((extensionId: string) => {
        timeoutRef = setInterval(() => {
          chrome.runtime.sendMessage(
            extensionId,
            { key: 'idle', interval: IDLE_INTERVAL_IN_SECONDS },
            (state: string) => {
              if (!state) clearInterval(timeoutRef);
              if (!state || previousIdleState === state) return;
              previousIdleState = state;
              setIdleState(state);
            }
          );
        }, 1000);
        return true;
      }, console.error);

    return () => clearInterval(timeoutRef);
  }, []);

  useEffect(() => {
    if (!idleState) return;

    if (idleState === 'active' && currentUserInfo.status !== 'inactive') return;

    if (idleState !== 'active' && currentUserInfo.status === 'inactive') return;

    if (idleState === 'active' && currentUserInfo.status === 'inactive') {
      updateCurrentUserInfo(previousStatus);
      return;
    }

    previousStatus = {
      status: currentUserInfo.status,
      statusMessage: currentUserInfo.statusMessage,
    };
    updateCurrentUserInfo({ status: 'inactive', statusMessage: '' });
  }, [
    currentUserInfo.status,
    currentUserInfo.statusMessage,
    idleState,
    updateCurrentUserInfo,
  ]);

  useEffect(() => {
    if (!authUser?.id) return;
    getFavoriteRooms(authUser.id).then(setFavoriteRooms);
  }, [authUser, getFavoriteRooms]);

  useEffect(() => {
    setNotifyNewMessages(currentUserInfo.status !== 'busy');
  }, [currentUserInfo.status, setNotifyNewMessages]);

  useEffect(() => {
    if (extensionIsRequired) {
      signOutExtensionRequired();
    }
  }, [extensionIsRequired, signOutExtensionRequired]);

  useEffect(() => {
    const { accessRevokedAt } = getUser();
    if (!accessRevokedAt) return;

    signOut();
  }, [getUser, signOut]);

  const contextValue = useMemo<OfficeContextData>(
    () => ({
      appVersions,
      usersInfo,
      updateUser,
      setUsersInfo,
      getUsersInfo,
      anonymousUsersInfo,
      setAnonymousUsersInfo,
      getAnonymousUsersInfo,
      currentUserInfo,
      updateCurrentUserInfo,
      favoriteRooms,
      setFavoriteRooms,
      filteredUsers,
      setFilteredUsers,
      shouldHighlightFiltered,
      setShouldHighlightFiltered,
      userMoodIsOpen,
      openUserMood,
      closeUserMood,
      officeEditMode,
      toggleOfficeEditMode,
      roomUsers,
      roomGroupedUsers,
      getRoomUsers,
      getRoomGroupedUsers,
      getCurrentOffice,
      getCurrentRoom,
      getUser,
      enterOfficeRoom,
      enterGroupMeeting,
      inviteToGroupMeeting,
      acceptGroupMeetingInvite,
      declineGroupMeetingInvite,
      cancelGroupMeetingInvite,
      getAnonymousUserInfo,
    }),
    [
      appVersions,
      usersInfo,
      getUsersInfo,
      updateUser,
      anonymousUsersInfo,
      getAnonymousUsersInfo,
      currentUserInfo,
      updateCurrentUserInfo,
      favoriteRooms,
      filteredUsers,
      shouldHighlightFiltered,
      userMoodIsOpen,
      openUserMood,
      closeUserMood,
      officeEditMode,
      toggleOfficeEditMode,
      roomUsers,
      roomGroupedUsers,
      getRoomUsers,
      getRoomGroupedUsers,
      getCurrentOffice,
      getCurrentRoom,
      getUser,
      enterOfficeRoom,
      enterGroupMeeting,
      inviteToGroupMeeting,
      acceptGroupMeetingInvite,
      declineGroupMeetingInvite,
      cancelGroupMeetingInvite,
      getAnonymousUserInfo,
    ]
  );

  const extensionNeededDialog = useMemo(
    () => (
      <ExtensionNeededDialog
        isOpen={extensionNeededDialogIsOpen}
        extensionLink={CHROME_EXTENSION_LINK}
        onCloseRequest={() => setExtensionNeededDialogIsOpen(false)}
      />
    ),
    [extensionNeededDialogIsOpen]
  );

  const smallScreenDetectedDialog = useMemo(
    () => (
      <SmallScreenDetectedDialog
        isOpen={smallScreenDetectedDialogIsOpen}
        onCloseRequest={() => setSmallScreenDetectedDialogIsOpen(false)}
      />
    ),
    [smallScreenDetectedDialogIsOpen]
  );

  return (
    <OfficeContext.Provider value={contextValue}>
      {children}
      {extensionNeededDialog}
      {smallScreenDetectedDialog}
    </OfficeContext.Provider>
  );
};

export function useOffice<TResult>(
  selector: (state: OfficeContextData) => TResult
): TResult {
  return useContextSelector(OfficeContext, selector);
}

const OfficeProviderWithSocket: React.FC = ({ children }) => {
  return (
    <ModelsProvider>
      <PlanLimitsProvider>
        <ReportsProvider>
          <SocketProvider>
            <ToastInviteProvider>
              <MattermostProvider>
                <JitsiProvider>
                  <OfficeProvider>
                    <TutorialProvider>{children}</TutorialProvider>
                  </OfficeProvider>
                </JitsiProvider>
              </MattermostProvider>
            </ToastInviteProvider>
          </SocketProvider>
        </ReportsProvider>
      </PlanLimitsProvider>
    </ModelsProvider>
  );
};
export default OfficeProviderWithSocket;
