import React from 'react';
import { useIntl } from 'react-intl';

import EnterRoomGIF from '../../../assets/tutorials/enter-room.gif';
import { useTutorial } from '../../../hooks/tutorial';
import { AppMessages } from '../../../languages';
import { EnterIcon } from '../../Icons';

import {
  ContentContainer,
  ContentTitle,
  ContentLine,
  ContentImage,
  ContentButton,
} from '../styles';

export const EnterRoomTipContent: React.FC = () => {
  const closeEnterRoomTip = useTutorial((state) => state.closeEnterRoomTip);
  const { formatMessage } = useIntl();

  return (
    <ContentContainer onClick={(e) => e.stopPropagation()}>
      <ContentTitle>
        {formatMessage({ id: AppMessages.tutorialEnterRoomTitle })}
      </ContentTitle>

      <ContentLine>
        {formatMessage({ id: AppMessages.tutorialEnterRoomDescription1 })}
      </ContentLine>

      <ContentImage
        src={EnterRoomGIF}
        width="300"
        height="240"
        draggable={false}
      />

      <ContentLine>
        {formatMessage({ id: AppMessages.tutorialEnterRoomDescription2 })}
        <EnterIcon />
        {` .`}
      </ContentLine>

      <ContentButton onClick={closeEnterRoomTip}>
        {formatMessage({ id: AppMessages.tutorialGotIt })}
      </ContentButton>
    </ContentContainer>
  );
};
