import React, { useMemo } from 'react';
import { Emoji } from 'emoji-mart';

import { useMattermost } from '../../hooks/mattermost';
import { getFormattedUserName } from '../../hooks/office';
import { Tooltip } from '../Tooltip';

import { ReactionButton } from './styles';

interface Props {
  postId: string;
  item: {
    emoji: string;
    users: string[];
  };
}

export const ChatPostReaction: React.FC<Props> = ({ postId, item }) => {
  const mattermostUserId = useMattermost(
    (state) => state.mattermostUser?.id || ''
  );
  const getProseiaUser = useMattermost((state) => state.getProseiaUser);
  const addReaction = useMattermost((state) => state.addReaction);
  const removeReaction = useMattermost((state) => state.removeReaction);

  const reacted = item.users.includes(mattermostUserId);

  const emoji = useMemo(() => <Emoji emoji={item.emoji} size={18} />, [
    item.emoji,
  ]);

  const toolTip = useMemo(
    () => (
      <Tooltip
        position="top-start"
        maxWidth={200}
        text={item.users
          .map((userId) => getFormattedUserName(getProseiaUser(userId)))
          .join(', ')}
      />
    ),
    [getProseiaUser, item.users]
  );

  return (
    <ReactionButton
      reacted={reacted}
      onClick={() => {
        if (!reacted) addReaction(postId, item.emoji);
        else removeReaction(postId, item.emoji);
      }}
    >
      {emoji}
      &nbsp;
      {item.users.length}
      {toolTip}
    </ReactionButton>
  );
};
