import { AppMessages } from '../../languages';

export interface PivotTableProps {
  aggregatorName: string;
  colOrder?: string;
  cols: string[];
  rendererName: string;
  rowOrder?: string;
  rows: string[];
  vals: string[];
  valueFilter?: any;
}

interface DefaultpivotTableModels {
  [name: string]: PivotTableProps;
}

export const defaultPivotTableModels: DefaultpivotTableModels = {
  [AppMessages.reportsModelStatusPerUser]: {
    aggregatorName: 'Sum',
    rendererName: 'Table',
    cols: ['status'],
    rows: ['userFullName'],
    vals: ['timeInHours'],
  },
  [AppMessages.reportsModelStatusPerRoomUser]: {
    aggregatorName: 'Sum',
    rendererName: 'Table',
    cols: ['status'],
    rows: ['userFullName', 'roomName'],
    vals: ['timeInHours'],
  },
  [AppMessages.reportsModelInMeetingPerUser]: {
    aggregatorName: 'Sum',
    rendererName: 'Table',
    cols: [],
    rows: ['userFullName'],
    vals: ['timeInHours'],
    valueFilter: {
      inMeeting: {
        no: true,
      },
    },
  },
  [AppMessages.reportsModelMeetingsPerDayRoomUser]: {
    aggregatorName: 'Sum',
    rendererName: 'Table',
    cols: [],
    rows: [
      'logDate',
      'roomName',
      'meetingId',
      'meetingStart',
      'meetingEnd',
      'meetingTotalTimeInHours',
      'userFullName',
    ],
    vals: ['timeInHours'],
    valueFilter: {
      inMeeting: {
        no: true,
      },
    },
  },
  [AppMessages.reportsModelAnalyticLogs]: {
    aggregatorName: 'Sum',
    rendererName: 'Table',
    cols: [],
    rows: [
      'userFullName',
      'logCreatedAt',
      'roomName',
      'isRemote',
      'inMeeting',
      'microphoneEnabled',
      'cameraEnabled',
      'screenSharingEnabled',
      'status',
      'statusMessage',
    ],
    vals: ['timeInHours'],
  },
};
