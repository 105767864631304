import React from 'react';
import { Container } from './styles';

interface Props {
  sizeInPixels?: number;
  primaryColor?: string;
  secondaryColor?: string;
}

export const LogoSymbol: React.FC<Props> = ({
  sizeInPixels = 0,
  primaryColor = '#211915',
  secondaryColor = '#fff',
}: Props) => {
  return (
    <Container className="logo-symbol" sizeInPixels={sizeInPixels}>
      <div className="scale-container">
        <svg>
          <g transform="translate(-5647.684 -281.482)">
            <path
              className="circle"
              d="M5664.665,289.974a8.49,8.49,0,1,1-8.491-8.492A8.492,8.492,0,0,1,5664.665,289.974Z"
              fill={primaryColor}
            />
            <path
              className="shape"
              d="M5658.658,285.63a2.888,2.888,0,0,0-.646.233l-.031.016a3.062,3.062,0,0,0-.875.63,3.208,3.208,0,0,0-.662,1.35,2.827,2.827,0,0,1-2.171,2.08,2.879,2.879,0,0,1-1.167-.016,1.636,1.636,0,0,0-.169-.036l-.012,0h0a2.263,2.263,0,1,0,1.732,3.027h0c0-.011.008-.02.012-.032l.007-.021a2.848,2.848,0,0,1,2.112-1.9,2.322,2.322,0,0,1,1.112-.029,5.293,5.293,0,0,0,1.536.327,3.062,3.062,0,0,0,1.033-.307l.031-.014a2.924,2.924,0,0,0,.58-.369,2.838,2.838,0,0,0-2.422-4.933Z"
              fill={secondaryColor}
            />
          </g>
        </svg>
      </div>
    </Container>
  );
};
