import styledDefault, {
  ThemedStyledInterface,
  ThemedCssFunction,
  BaseThemeProviderComponent,
  ThemeProvider as ThemeProviderDefault,
  css as cssDefault,
} from 'styled-components';

import darkTheme from './theme.dark';
import lightTheme from './theme.light';

export interface ProseiaTheme {
  title: string;
  background: string;
  textColor: string;
  linkColor: string;
  mentionColor: string;
  iconColor: string;
  boxColor: string;
  formComponentGlow: string;
  boxGlow: string;
  focusGlow: string;
  avatarGlow: string;
}

export const dark = darkTheme;
export const light = lightTheme;

export const styled = styledDefault as ThemedStyledInterface<ProseiaTheme>;
export const css = cssDefault as ThemedCssFunction<ProseiaTheme>;
export const ThemeProvider = ThemeProviderDefault as BaseThemeProviderComponent<
  ProseiaTheme
>;
