import React, { useState, useCallback, useRef } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { useMattermost } from '../../hooks/mattermost';
import { Container, CHAT_CHANNEL_NORMAL_WIDTH, SPACING } from './styles';
import { ChatChannel } from './ChatChannel';

export const ChatChannelContainer: React.FC = () => {
  const openedChannels = useMattermost((state) => state.openedChannels);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const resizeEventTimeout = useRef<NodeJS.Timeout>();

  const isMaximized =
    openedChannels.filter((t) => t.windowStatus === 'maximized').length > 0;

  const getChannelList = useCallback(() => {
    const list = [...openedChannels];
    const maximized = list.find((t) => t.windowStatus === 'maximized');
    if (maximized) return [maximized];
    const visibleAmount = Math.floor(
      size.width / (SPACING + CHAT_CHANNEL_NORMAL_WIDTH)
    );
    if (visibleAmount >= list.length) return list;
    for (let i = list.length; i > visibleAmount; i--) {
      const minPriority = Math.min(...list.map((t) => t.priority));
      const lowPriority = list.filter((t) => t.priority === minPriority);
      const hidden = lowPriority[lowPriority.length - 1];
      list.splice(list.indexOf(hidden), 1);
    }
    return list;
  }, [openedChannels, size]);

  const handleResize = useCallback((width: number, height: number) => {
    if (resizeEventTimeout.current) clearTimeout(resizeEventTimeout.current);
    resizeEventTimeout.current = setTimeout(() => {
      setSize({ width, height });
      resizeEventTimeout.current = undefined;
    }, 100);
  }, []);

  return (
    <ReactResizeDetector handleWidth handleHeight onResize={handleResize}>
      <Container className="chat-channel-container" maximized={isMaximized}>
        {getChannelList()?.map((item) => (
          <ChatChannel
            key={item.channel.id}
            channel={item.channel}
            boxStatus={item.windowStatus}
          />
        ))}
      </Container>
    </ReactResizeDetector>
  );
};
