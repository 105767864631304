import React, { useCallback, useMemo } from 'react';
import { useOffice } from '../../hooks/office';
import {
  Container,
  UserStatusElements,
  UserRemoteSwitch,
  UserRemoteLabel,
} from './styles';
import { AppMessages } from '../../languages';
import { useLanguage } from '../../hooks/language';
import { MicIcon, SoundIcon, RemoteIcon } from '../Icons';
import { SwitchButton } from '../SwitchButton';

export const UserStatus: React.FC = () => {
  const soundEnabled = useOffice((state) => state.currentUserInfo.soundEnabled);
  const micEnabled = useOffice((state) => state.currentUserInfo.micEnabled);
  const isRemote = useOffice((state) => state.currentUserInfo.isRemote);
  const updateCurrentUserInfo = useOffice(
    (state) => state.updateCurrentUserInfo
  );
  const messages = useLanguage((state) => state.messages);

  const toggleRemote = useCallback(() => {
    updateCurrentUserInfo({ isRemote: !isRemote });
  }, [isRemote, updateCurrentUserInfo]);

  const micIcon = useMemo(() => <MicIcon enabled={micEnabled} />, [micEnabled]);

  const soundIcon = useMemo(() => <SoundIcon enabled={soundEnabled} />, [
    soundEnabled,
  ]);

  const remoteIcon = useMemo(() => isRemote && <RemoteIcon />, [isRemote]);

  const userStatusElements = useMemo(
    () => (
      <UserStatusElements>
        {micIcon}
        {soundIcon}
        {remoteIcon}
      </UserStatusElements>
    ),
    [micIcon, remoteIcon, soundIcon]
  );

  const remoteText = useMemo(
    () => <span>{messages[AppMessages.userInfoRemote]}</span>,
    [messages]
  );

  const remoteSwitch = useMemo(
    () => (
      <UserRemoteSwitch>
        <SwitchButton isOn={isRemote} handleToggle={toggleRemote} />
      </UserRemoteSwitch>
    ),
    [isRemote, toggleRemote]
  );

  const remoteContainer = useMemo(
    () => (
      <UserRemoteLabel className="is-remote-switcher">
        {remoteText}
        {remoteSwitch}
      </UserRemoteLabel>
    ),
    [remoteSwitch, remoteText]
  );

  return (
    <Container>
      {userStatusElements}
      {remoteContainer}
    </Container>
  );
};
