import { LanguageData, AppMessages } from './index';

const messages: LanguageData = {
  [AppMessages.pageSignInWelcome]: '¡Tu equipo junto, estés donde estés!',
  [AppMessages.pageSignInEmail]: 'Email',
  [AppMessages.pageSignInPassword]: 'Contraseña',
  [AppMessages.pageSignInConfirmPassword]: 'Confirmar',
  [AppMessages.pageSignInFirstName]: 'Nombre de pila',
  [AppMessages.pageSignInLastName]: 'Apellido',
  [AppMessages.pageSignInRoleDescription]: 'Rol',
  [AppMessages.pageSignInPhoneNumber]: 'Teléfono',
  [AppMessages.pageSignInCompany]: 'Empresa',
  [AppMessages.pageSignInSubmit]: 'INICIAR SESIÓN',
  [AppMessages.pageSignInForgotPassword]: 'Olvidé mi contraseña',
  [AppMessages.pageSignInForgotPasswordConfirm]:
    '¿Quieres recibir un enlace en el correo electrónico "{email}" para restablecer tu contraseña?',
  [AppMessages.pageSignInForgotPasswordLinkSended]:
    'Se ha enviado un enlace a su correo electrónico.',
  [AppMessages.pageSignInValidationRequired]: 'Necesario',
  [AppMessages.pageSignInValidationInvalidEmail]: 'Email inválido',
  [AppMessages.pageSignInValidationWeakPassword]:
    'Mínimo: 1 letra, 1 número, 6 dígitos',
  [AppMessages.pageSignInValidationDoesNotMatch]: 'No coincide',
  [AppMessages.pageSignInValidationInvalidPhoneNumber]:
    'Numero de telefono invalido',
  [AppMessages.pageSignInValidationInvalidCredentials]:
    'Credenciales no válidas',
  [AppMessages.pageSignInValidationIncompleteRegistration]:
    'Su administrador aún debe completar su registro de usuario antes de que pueda iniciar sesión. Comuníquese con él/ella para solicitar que se complete.',
  [AppMessages.pageSignInKeepConnected]: 'Mantener conectado',

  [AppMessages.pageCreateEnvironmentWelcome]:
    'Bienvenido a Proseia, ¿empezamos?',
  [AppMessages.pageCreateEnvironmentOrganization]: 'Empresa / Organización',
  [AppMessages.pageCreateEnvironmentEmail]:
    'Ingrese el nombre de su empresa y un email válido.',
  [AppMessages.pageCreateEnvironmentSubdomain]:
    'Su dominio (dirección WEB) en Proseia será:',
  [AppMessages.pageCreateEnvironmentSubdomainPlaceholder]: 'suempresa',
  [AppMessages.pageCreateEnvironmentSubmit]: 'Próximo',
  [AppMessages.pageCreateEnvironmentValidationInvalidSubdomain]:
    'Al menos 3 dígitos, hasta 20 dígitos',
  [AppMessages.pageCreateEnvironmentValidationSubdomainNotAvailable]:
    'Subdominio no disponible',

  [AppMessages.pageSigningUpEnvironmentTitle]:
    'Complete sus datos y elija una contraseña.',
  [AppMessages.pageSigningUpEnvironmentSubmit]: 'Próximo',

  [AppMessages.pageSettingUpEnvironmentTitle]:
    'Espera, estamos preparando tu Proseia.',
  [AppMessages.pageSettingUpEnvironmentSteps]:
    'Creando tu entorno; Montaje de la interfaz; Preparando las habitaciones; Organizar el chat; Construyendo el feed; Casi listo;',

  [AppMessages.pageStartEnvironmentTitle]: '¡Listo!',
  [AppMessages.pageStartEnvironmentDescription]:
    'Ahora solo sigue adelante y comienza a usarlo.',
  [AppMessages.pageStartEnvironmentSubmit]: 'Empezar',

  [AppMessages.pageOfficeNotAllowedToRequestConnectionsBetweenOthers]:
    'No está permitido solicitar conexiones entre otros usuarios.',
  [AppMessages.pageOfficeNotAllowedToRequestConnectionsWithInactiveMobileUser]:
    'No está permitido enviar invitaciones a reuniones a usuarios móviles inactivos.',
  [AppMessages.pageOfficeNotAllowedToMoveOthers]:
    'No está permitido mover a otros usuarios.',
  [AppMessages.pageOfficeEnableEditMode]:
    'Habilitar el modo de edición de oficina',
  [AppMessages.pageOfficeDisableEditMode]:
    'Desactivar el modo de edición de oficina',
  [AppMessages.pageOfficeAddRoom]: 'Agregar sala',
  [AppMessages.pageOfficeRoomName]: 'Nombre de la sala',
  [AppMessages.pageOfficeInviteUsers]: 'Invitar usuarios',
  [AppMessages.pageOfficeInviteUsersPrompt]:
    'Ingrese correos electrónicos separados por comas',
  [AppMessages.pageOfficeStartNewMeeting]: `Iniciar nueva reunión`,
  [AppMessages.pageOfficeShareMeetingLink]: `Compartir enlace de reunión`,
  [AppMessages.pageOfficeChangeMood]: 'Cambiar de humor',
  [AppMessages.pageOfficeTurnOffRoomSoundAndStartMeetingConfirmation]:
    '¿Quiere silenciar la sala e iniciar una nueva reunión?',

  [AppMessages.pageMeetingHeaderMessage]:
    'Ingrese su nombre antes de ingresar a la reunión',
  [AppMessages.pageMeetingEnterMeeting]: 'Entrar a la reunión',

  [AppMessages.componentGoogleSignInButton]: 'Inicia sesión con Google',

  [AppMessages.componentAvatarOpenChatConversation]:
    'Abrir conversación de chat',
  [AppMessages.componentAvatarInviteToMeeting]: 'Invitar a la reunión',
  [AppMessages.componentAvatarRequestToJoinTheMeeting]:
    'Solicitando unirse a la reunión',
  [AppMessages.componentAvatarJoinTheMeeting]: 'Entrar en la reunión',

  [AppMessages.componentMeetingLinkSharingTitle]: 'Título de la reunión',
  [AppMessages.componentMeetingLinkSharingGenerate]: 'Generar enlace',
  [AppMessages.componentMeetingLinkSharingCopy]: 'Copiar enlace',
  [AppMessages.componentMeetingLinkSharingCopied]:
    'El enlace se copió correctamente en el portapapeles.',

  [AppMessages.componentThemeSwitcherToDark]: 'Cambiar a tema oscuro',
  [AppMessages.componentThemeSwitcherToLight]: 'Cambiar a tema claro',

  [AppMessages.componentRoomExpand]: 'Expandir',
  [AppMessages.componentRoomRetract]: 'Retraer',
  [AppMessages.componentRoomDelete]: 'Eliminar sala',
  [AppMessages.componentRoomConfirmDeletion]:
    '¿Confirmar la eliminación de la sala?',
  [AppMessages.componentRoomEnter]: 'Entra en la sala',
  [AppMessages.componentRoomTurnOnSound]: 'Enciende el sonido de la sala',
  [AppMessages.componentRoomTurnOffSound]: 'Apaga el sonido de la sala',

  [AppMessages.componentProfileDefineYourRole]: 'Define tu rol',
  [AppMessages.componentProfileAt]: 'Está en:',
  [AppMessages.componentProfileSignOut]: 'Desconectar',
  [AppMessages.componentProfileEditingNotAllowed]:
    'Solo los usuarios administradores pueden editar la información del perfil',

  [AppMessages.componentWelcomeTourStartTitle]: `Bienvenidos a Proseia`,
  [AppMessages.componentWelcomeTourStartDescription]: `¡Comencemos un recorrido para aprender a usarlo!\n(Presione las flechas para navegar por esta presentación)`,
  [AppMessages.componentWelcomeTourUserInfoTitle]: `Informacion del usuario`,
  [AppMessages.componentWelcomeTourUserInfoDescription]: `Aquí está su información personal.`,
  [AppMessages.componentWelcomeTourAvatarPictureTitle]: `Imagen de avatar`,
  [AppMessages.componentWelcomeTourAvatarPictureDescription]: `Puede cambiar su imagen haciendo clic en el icono de edición que aparece cuando pasa el cursor sobre el avatar.`,
  [AppMessages.componentWelcomeTourAvatarDraggableTitle]: `El avatar se puede arrastrar`,
  [AppMessages.componentWelcomeTourAvatarDraggableDescription]: `También puede arrastrarlo a una sala para ingresar o arrastrarlo sobre el avatar de otro usuario para solicitar una conferencia donde sea que esté.`,
  [AppMessages.componentWelcomeTourRoleTitle]: `Ocupación`,
  [AppMessages.componentWelcomeTourRoleDescription]: `Su rol se muestra aquí.\nPuede hacer clic para editarlo.`,
  [AppMessages.componentWelcomeTourProfileTitle]: `Perfil`,
  [AppMessages.componentWelcomeTourProfileDescription]: `Al hacer clic en el área de información personal, el perfil se expandirá.`,
  [AppMessages.componentWelcomeTourRemoteTitle]: `Trabajo remoto`,
  [AppMessages.componentWelcomeTourRemoteDescription]: `Habilite o deshabilite esta opción para informar a otros usuarios si actualmente está trabajando en persona o de forma remota.`,
  [AppMessages.componentWelcomeTourUserStatusTitle]: `Estatus de usuario`,
  [AppMessages.componentWelcomeTourUserStatusDescription]: `Informe a otros usuarios de su estado actual seleccionando una de las opciones.`,
  [AppMessages.componentWelcomeTourLanguagesTitle]: `Idiomas`,
  [AppMessages.componentWelcomeTourLanguagesDescription]: `Seleccione su idioma preferido entre los disponibles.`,
  [AppMessages.componentWelcomeTourMoodsTitle]: 'Estados de ánimo',
  [AppMessages.componentWelcomeTourMoodsDescription]:
    'Informe a otros usuarios cómo te sientes',
  [AppMessages.componentWelcomeTourNotificationsTitle]: `Notificaciones`,
  [AppMessages.componentWelcomeTourNotificationsDescription]: `Otorgue permiso y habilite las notificaciones para que sea más fácil ver cuando alguien intenta interactuar.`,
  [AppMessages.componentWelcomeTourThemeSwitcherTitle]: `Selector de temas`,
  [AppMessages.componentWelcomeTourThemeSwitcherDescription]: `Cambie entre temas claros y oscuros haciendo clic en este botón.`,
  [AppMessages.componentWelcomeTourInviteOthersTitle]: `Invita a otros`,
  [AppMessages.componentWelcomeTourInviteOthersDescription]: `Envíe invitaciones a sus compañeros de equipo para que se unan a usted en Proseia.\nRecibirán la invitación en sus correos electrónicos.`,
  [AppMessages.componentWelcomeTourEditModeTitle]: `Edita la oficina virtual`,
  [AppMessages.componentWelcomeTourEditModeDescription]: `Al hacer clic en este botón, se habilita el modo de edición y puede agregar, cambiar el nombre o eliminar salas según sea necesario.`,
  [AppMessages.componentWelcomeTourRoomsTitle]: `Salas`,
  [AppMessages.componentWelcomeTourRoomsDescription]: `Esta es la representación virtual de su oficina.\nCada sala puede representar una sala del mundo real en su oficina, un departamento, un contexto de equipo específico o cualquier otra separación que tenga sentido para su empresa.\nPuede moverse entre salas arrastrando tu avatar.`,
  [AppMessages.componentWelcomeTourDoneTitle]: `¡Es eso!`,
  [AppMessages.componentWelcomeTourDoneDescription]: `¡Por ahora, eso es todo lo que tenemos para mostrarle!`,
  [AppMessages.componentWelcomeTourDone]: `Listo`,

  [AppMessages.componentStyledMultiSelectSelected]: 'Seleccionados',
  [AppMessages.componentStyledMultiSelectAll]: 'Todos',

  [AppMessages.componentUserMoodTitle]: '¿Como te sientes?',
  [AppMessages.componentUserMoodComment]:
    'Si quieres, cuéntanos que te hace sentir así',
  [AppMessages.componentUserMoodConfirm]: 'Confirmar',

  [AppMessages.componentUserManagementTitle]: 'Gestión de usuarios',
  [AppMessages.componentUserManagementInviteNewUsers]:
    'Invita a nuevos usuarios',
  [AppMessages.componentUserManagementUsersCount]: 'Total',
  [AppMessages.componentUserManagementCanAccess]: 'Puede acceder',
  [AppMessages.componentUserManagementRevokeAccess]: 'Bloquear el acceso',
  [AppMessages.componentUserManagementGrantAccess]: 'Liberar acceso',
  [AppMessages.componentUserManagementAddAsAdministrator]:
    'Agregar como administrador',
  [AppMessages.componentUserManagementRemoveFromAdministrators]:
    'Eliminar de los administradores',
  [AppMessages.componentUserManagementDeleteUser]: 'Borrar usuario',
  [AppMessages.componentUserManagementResendInvitationEmail]:
    'Reenviar correo electrónico de invitación',
  [AppMessages.componentUserManagementAdministrator]: 'Administrador',
  [AppMessages.componentUserManagementAccessRevoked]: 'Acceso bloqueado',
  [AppMessages.componentUserManagementNoLogin]: '(Sin inicio de sesión)',
  [AppMessages.componentUserManagementUseLocation]: 'Usar geolocalización',
  [AppMessages.componentUserManagementUseLocationDescription]:
    'Cuando esta opción está habilitada, se solicitará la ubicación de los usuarios que utilizan la aplicación móvil.',
  [AppMessages.componentUserManagementMakeLocationRequired]:
    'Hacer que la geolocalización sea obligatoria para que este usuario use la aplicación móvil',
  [AppMessages.componentUserManagementMakeLocationOptional]:
    'acer que la geolocalización sea opcional para este usuario cuando use la aplicación móvil',
  [AppMessages.componentUserManagementAllowProfileEditing]:
    'Permitir la edición de perfil',
  [AppMessages.componentUserManagementAllowProfileEditingDescription]:
    'Cuando esta opción está desactivada, los usuarios que no son administradores no pueden editar la información de su perfil (nombre, rol, imagen).',
  [AppMessages.componentUserManagementUseExtension]: 'Usar extensión',
  [AppMessages.componentUserManagementUseExtensionDescription]:
    'Cuando esta opción está habilitada, el usuario debe tener instalada en su navegador la extensión que verifica su actividad..',
  [AppMessages.componentUserManagementMakeExtensionRequired]:
    'Hacer que el uso de la extensión sea obligatorio para este usuario.',
  [AppMessages.componentUserManagementMakeExtensionOptional]:
    'Hacer que el uso de la extensión sea opcional para este usuario.',
  [AppMessages.componentUserManagementUnnamed]: 'Nombre indefinido',
  [AppMessages.componentUserManagementNoRole]: 'Rol indefinido',

  [AppMessages.userInfoRemote]: 'Remoto',
  [AppMessages.userInfoStatusAvailable]: 'Disponible',
  [AppMessages.userInfoStatusAway]: 'Ausente',
  [AppMessages.userInfoStatusBusy]: 'Ocupado',
  [AppMessages.userInfoStatusInactive]: 'Inactivo',

  [AppMessages.inputSearchPlaceholder]: 'Buscar',

  [AppMessages.chatDefaultChannelName]: 'Todos',
  [AppMessages.chatChannels]: 'Canales',
  [AppMessages.chatContacts]: 'Contactos',
  [AppMessages.chatTyping]: 'escribiendo',
  [AppMessages.chatLoading]: 'cargando...',
  [AppMessages.chatNewMessages]: 'Nuevos mensajes',
  [AppMessages.chatLoadPrevious]: 'Cargar anterior',
  [AppMessages.chatLoadNew]: 'Mostrar recientes',
  [AppMessages.chatSendMessage]: 'Enviar mensaje',
  [AppMessages.chatExcludeFile]: 'Excluir archivo',
  [AppMessages.chatNewMessageFrom]: 'Nuevo mensaje de {name}',
  [AppMessages.chatPublicChannel]: 'Público',
  [AppMessages.chatPrivateChannel]: 'Privado',
  [AppMessages.chatNewChannel]: 'Nuevo canal',
  [AppMessages.chatCreateChannel]: 'Crear',
  [AppMessages.chatChannelName]: 'Nombre',
  [AppMessages.chatJoinChannel]: 'Unirse',
  [AppMessages.chatLeaveChannel]: 'Dejar el grupo',
  [AppMessages.chatLeaveChannelConfirm]: '¿De verdad quieres dejar este grupo?',
  [AppMessages.chatRemoveFromChannel]: 'Eliminar',
  [AppMessages.chatRemoveFromChannelConfirm]:
    '¿Realmente quieres eliminar "{name}" de ese grupo?',
  [AppMessages.chatRenameChannel]: 'Rebautizar',
  [AppMessages.chatRenameChannelNewName]: 'Nuevo nombre:',
  [AppMessages.chatMakeChannelPublic]: 'Hazlo público',
  [AppMessages.chatMakeChannelPublicConfirm]:
    '¿Quieres hacer público este grupo?',
  [AppMessages.chatMakeChannelPrivate]: 'Hazlo privado',
  [AppMessages.chatMakeChannelPrivateConfirm]:
    '¿Quieres que este grupo sea privado?',
  [AppMessages.chatDeleteChannel]: 'Eliminar el grupo',
  [AppMessages.chatDeleteChannelConfirm]:
    '¿Realmente desea eliminar el grupo "{group}"? (Esta acción es irreversible)',
  [AppMessages.chatChannelMembers]: 'Miembros',
  [AppMessages.chatChannelMember]: 'Miembro',
  [AppMessages.chatAddToChannel]: 'Agregar',
  [AppMessages.chatInputPlaceholder]: 'Mensaje',
  [AppMessages.chatPostConfirmDeletion]: '¿Confirmar la eliminación?',
  [AppMessages.chatPostDelete]: 'Eliminar',
  [AppMessages.chatPostAddReaction]: 'Agregar reacción',
  [AppMessages.chatPostReplyTo]: 'Respuesta',
  [AppMessages.chatSendingAudio]: 'Enviando mensaje',

  [AppMessages.officePostsGeneral]: 'General',
  [AppMessages.officePostsNothingPosted]: 'Aún no se ha publicado nada',
  [AppMessages.officePostConfirmDeletion]: '¿Confirmar la eliminación?',
  [AppMessages.officePostDelete]: 'Eliminar',
  [AppMessages.officePostAddReaction]: 'Agregar reacción',
  [AppMessages.officePostPin]: 'Fijar la publicación',
  [AppMessages.officePostUnpin]: 'Dejar de fijar la publicación',
  [AppMessages.officePostPublish]: 'Publicar',
  [AppMessages.officePostInputTitle]: 'Crear publicación',
  [AppMessages.officePostInputAddToPost]: 'Agregar a la publicación',
  [AppMessages.officePostInputPlaceholder]: 'Crear una nueva publicación',
  [AppMessages.officePostCommentInputPlaceholder]: 'Añadir un comentario',
  [AppMessages.officePostCommentsCountTitle]: 'comentario(s)',
  [AppMessages.officePostCommentsShowAllButton]:
    'Mostrar todos los comentarios',
  [AppMessages.officePostCommentsHideButton]: 'Ocultar comentarios',
  [AppMessages.officePostCommentsNewCount]: 'nuevo(s)',

  [AppMessages.attention]: 'Atención',
  [AppMessages.notAllowed]: 'No permitido',
  [AppMessages.noSupport]: 'Sin soporte',
  [AppMessages.permissionDenied]: 'Permiso denegado',

  [AppMessages.notificationsNoSupport]:
    'El navegador no admite notificaciones.',
  [AppMessages.notificationsPermissionRequired]:
    'El permiso de notificaciones debe otorgarse en la configuración del navegador.',
  [AppMessages.notificationsEnable]: 'Permitir notificaciones',
  [AppMessages.notificationsDisable]: 'Desactivar notificaciones',

  [AppMessages.userMediaNoSupport]:
    'El navegador no admite la captura de audio/video.',
  [AppMessages.userMediaMicrophonePermissionRequired]:
    'El permiso de micrófono debe otorgarse en la configuración del navegador.',
  [AppMessages.userMediaMicrophoneEnable]: 'Habilitar micrófono',
  [AppMessages.userMediaMicrophoneDisable]: 'Desactivar micrófono',
  [AppMessages.userMediaCameraPermissionRequired]:
    'El permiso de la cámara debe otorgarse en la configuración del navegador.',
  [AppMessages.userMediaCameraEnable]: 'Habilitar cámara',
  [AppMessages.userMediaCameraDisable]: 'Desactivar cámara',
  [AppMessages.userMediaScreenSharingEnable]: 'Empezar a compartir pantalla',
  [AppMessages.userMediaScreenSharingDisable]: 'Dejar de compartir pantalla',

  [AppMessages.meetingLeave]: 'Salir de la reunión',
  [AppMessages.meetingShow]: 'Mostrar reunión',
  [AppMessages.meetingHide]: 'Ocultar reunión',
  [AppMessages.meetingConfirmLeave]: `Dejará la reunión actual. ¿Es eso mismo?`,

  [AppMessages.inviteSended]: 'Invitación enviada',
  [AppMessages.inviteReceived]: 'Invitación recibida',
  [AppMessages.inviteRejected]: 'Invitación rechazada',
  [AppMessages.inviteCanceled]: 'Invitación cancelada',
  [AppMessages.inviteWantsToConnect]: 'quiere conectarse contigo',
  [AppMessages.inviteRejectedYourInvitation]: 'rechazó tu invitación',
  [AppMessages.inviteCanceledTheInvitation]: 'canceló la invitación',

  [AppMessages.periodsField]: 'Período',
  [AppMessages.periodsToday]: 'Hoy',
  [AppMessages.periodsYesterday]: 'Ayer',
  [AppMessages.periodsThisWeek]: 'Esta semana',
  [AppMessages.periodsLastWeek]: 'La semana pasada',
  [AppMessages.periodsThisMonth]: 'Este mes',
  [AppMessages.periodsLastMonth]: 'El mes pasado',

  [AppMessages.booleanStringYes]: 'Si',
  [AppMessages.booleanStringNo]: 'No',

  [AppMessages.reportsTitle]: 'Informes',
  [AppMessages.reportsNoData]: 'Sin datos para el período o filtro',

  [AppMessages.reportsModelField]: 'Modelo',
  [AppMessages.reportsModelStatusPerUser]: 'Tiempo por estado/usuario',
  [AppMessages.reportsModelStatusPerRoomUser]: 'Tiempo por estado/sala/usuario',
  [AppMessages.reportsModelInMeetingPerUser]: 'Tiempo de reunión por usuario',
  [AppMessages.reportsModelMeetingsPerDayRoomUser]:
    'Reuniones por día/sala/usuario',
  [AppMessages.reportsModelAnalyticLogs]: 'Registros analíticos',

  [AppMessages.reportsFieldLogId]: 'ID de registro',
  [AppMessages.reportsFieldUserFullName]: 'Usuario',
  [AppMessages.reportsFieldUserEmail]: 'Email',
  [AppMessages.reportsFieldOfficeName]: 'Oficina',
  [AppMessages.reportsFieldRoomName]: 'Sala',
  [AppMessages.reportsFieldIsRemote]: 'Remoto',
  [AppMessages.reportsFieldStatus]: 'Estado',
  [AppMessages.reportsFieldStatusMessage]: 'Mensaje de estado',
  [AppMessages.reportsFieldInMeeting]: 'En reunión',
  [AppMessages.reportsFieldMeetingId]: 'ID de reunión',
  [AppMessages.reportsFieldMeetingStart]: 'Inicio de la reunión',
  [AppMessages.reportsFieldMeetingEnd]: 'Fin de la reunión',
  [AppMessages.reportsFieldMeetingTotalTimeInSeconds]:
    'Duración de la reunión (segundos)',
  [AppMessages.reportsFieldMeetingTotalTimeInMinutes]:
    'Duración de la reunión (minutos)',
  [AppMessages.reportsFieldMeetingTotalTimeInHours]:
    'Duración de la reunión (horas)',
  [AppMessages.reportsFieldMicrophoneEnabled]: 'Micrófono habilitado',
  [AppMessages.reportsFieldCameraEnabled]: 'Cámara habilitada',
  [AppMessages.reportsFieldScreenSharingEnabled]: 'Pantalla compartida',
  [AppMessages.reportsFieldLogCreatedAt]: 'Inicio de registro',
  [AppMessages.reportsFieldLogFinishedAt]: 'Final del registro',
  [AppMessages.reportsFieldLogDate]: 'Fecha',
  [AppMessages.reportsFieldLogYear]: 'Año',
  [AppMessages.reportsFieldLogMonth]: 'Mes',
  [AppMessages.reportsFieldLogDay]: 'Día',
  [AppMessages.reportsFieldTimeInSeconds]: 'Duración del registro (segundos)',
  [AppMessages.reportsFieldTimeInMinutes]: 'Duración del registro (minutos)',
  [AppMessages.reportsFieldTimeInHours]: 'Duración del registro (horas)',

  [AppMessages.limitsActiveUsersReached]:
    'Se alcanzó el límite de usuarios activos',
  [AppMessages.limitsActiveUsers]:
    'Su plan actual permite solo {limit} usuarios activos',
  [AppMessages.limitsActivityReportsNotAllowed]:
    'Su plan actual no permite informes',
  [AppMessages.limitsExternalMeetingsNotAllowed]:
    'Su plan actual no permite enlaces a reuniones para usuarios externos',
  [AppMessages.limitsCannotJoinMeeting]: 'No se pudo unir a la reunión',
  [AppMessages.limitsUsersPerMeeting]:
    'La reunión superó el número máximo de participantes',
  [AppMessages.limitsAudioHoursReached]:
    'Se alcanzó el límite de horas de audio para su plan actual',
  [AppMessages.limitsVideoHoursReached]:
    'Se alcanzó el límite de horas de video para su plan actual',
  [AppMessages.limitsScreenSharingHoursReached]:
    'Se alcanzó el límite de horas de uso compartido de pantalla para su plan actual',
  [AppMessages.limitsChatFileTooLarge]: 'Archivo demasiado grande',
  [AppMessages.limitsChatMaxFileSize]:
    'Su plan actual le permite cargar archivos de hasta {limit} megabytes solamente',

  [AppMessages.chromeExtensionNeededMessage]:
    'No pudimos detectar la extensión Proseia para su navegador.\nPara una mejor experiencia, instale la extensión.\n>> Haga clic aquí para instalar la extensión <<\n¡Después de finalizar la instalación, vuelva a cargar la página!',

  [AppMessages.smallScreenDetectedMessage]:
    'Proseia está optimizado para su uso en pantallas más grandes.\n¡Para una mejor experiencia, acceda a través de una computadora!\n\nPara dispositivos móviles ofrecemos la App Proseia, que le permite enviar y recibir mensajes en el chat.',

  [AppMessages.copyrightText]: '{year} - Todos los derechos reservados',

  [AppMessages.privacyPolicy]: 'Política de privacidad',

  [AppMessages.updateVersion]:
    '¡Nueva versión disponible! Haga clic para actualizar.',

  [AppMessages.stepOf]: 'Paso {current} de {total}',

  [AppMessages.tutorialEnterRoomTitle]: 'Bienvenidos a Proseia',
  [AppMessages.tutorialEnterRoomDescription1]:
    'Arrastra tu avatar para entrar a una sala.',
  [AppMessages.tutorialEnterRoomDescription2]:
    'También puede utilizar el icono ',

  [AppMessages.tutorialAddUsersDescription1]:
    'Haga clic aquí para agregar usuarios.',

  [AppMessages.tutorialInviteToMeetingTitle]: '¿Reunión de audio/video?',
  [AppMessages.tutorialInviteToMeetingDescription1]:
    'Arrastra tu avatar a otro usuario.',
  [AppMessages.tutorialInviteToMeetingDescription2]:
    'Los usuarios de una reunión aparecen juntos y, lo mejor de todo, ¡puede agregar varios!',

  [AppMessages.tutorialContextMenuTitle]: '¿Charlar o reunirse?',
  [AppMessages.tutorialContextMenuDescription1]:
    'Simplemente haga clic en el usuario y seleccione.',

  [AppMessages.tutorialMeetingVisibilityTitle]: '¿Creó una reunión?',
  [AppMessages.tutorialMeetingVisibilityDescription1]:
    'Durante una reunión, puede seguir usando Proseia con normalidad.',
  [AppMessages.tutorialMeetingVisibilityDescription2]:
    'Mientras la reunión está activa, el icono de la cámara parpadeará en rojo.',

  [AppMessages.tutorialGotIt]: '¡OK entendí!',

  [AppMessages.incompatibleBrowserTitle]:
    'Desafortunadamente, su navegador no es compatible!',
  [AppMessages.incompatibleBrowserDescription]:
    'Para acceder a Proseia, utilice uno de los navegadores siguientes.',

  [AppMessages.extensionRequiredTitle]:
    'Su administrador necesita la extensión instalada para acceder a Proseia.',
  [AppMessages.extensionRequiredDescription]:
    'Para acceder a Proseia, instale la extensión desde el enlace de abajo y vuelva a cargar la página.',
  [AppMessages.extensionRequiredButtonText]: 'Instalar',
};

export default messages;
