import { shade } from 'polished';
import { styled } from '../../themes';

const WIDTH = 400;
const MOODS_CONTAINER_HEIGHT = 53;
const MOOD_COMMENT_CONTAINER_HEIGHT = 160;

export const Container = styled.div`
  position: relative;
  width: 60%;
  min-width: ${WIDTH}px;
  max-width: ${WIDTH}px;
  /* height: 50%; */
  background: ${(props) => props.theme.boxColor};
  color: ${(props) => props.theme.textColor};
  box-shadow: ${(props) => props.theme.boxGlow};
  border-radius: 5px;

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    padding-left: 10px;

    > .title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    > .go-back {
      display: flex;
      align-items: center;
    }
  }

  > .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: calc(100% - 38px);
    padding: 20px;
    overflow-x: hidden;
    width: 100%;

    > .input {
      width: 100%;
    }

    > .spinner-container {
      position: absolute;
      background: #00000055;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      border-radius: 5px;

      > svg {
        font-size: 50px;
      }
    }
  }
`;

interface MoodsContainerProps {
  visible?: boolean;
}

export const MoodsContainer = styled.div<MoodsContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transform: translateX(${(props) => (props.visible ? 0 : -100)}%);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: transform 0.5s, opacity 0.5s;

  > * + * {
    margin-left: 15px;
  }
`;

export const MoodCommentContainer = styled.div<MoodsContainerProps>`
  position: relative;
  width: 100%;
  margin-top: -${MOODS_CONTAINER_HEIGHT}px;
  transform: translateX(${(props) => (props.visible ? 0 : 100)}%);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  height: ${(props) =>
    props.visible ? MOOD_COMMENT_CONTAINER_HEIGHT : MOODS_CONTAINER_HEIGHT}px;
  transition: transform 0.5s, opacity 0.5s, height 0.5s;
  overflow: hidden;

  > .mood-comment {
    width: 100%;
  }

  > .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 10px;

    > * + * {
      margin-left: 15px;
    }
  }
`;

export const Button = styled.button`
  border: none;
  background: ${(props) => props.color || 'green'};
  padding: 14px 28px;
  color: white;
  white-space: nowrap;
  min-width: 200px;
  border-radius: 5px;
  transition: opacity 0.3s;
  align-self: center;

  :hover {
    background: ${(props) => shade(0.2, props.color || 'green')};
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
    :hover {
      background: ${(props) => shade(0.2, props.color || 'green')};
    }
  }
`;

export const MoodButton = styled.button`
  border: none;
  background: transparent;
  border-radius: 5px;
  transform: scale(1);
  font-size: 30pt;
  opacity: 0.8;
  transition: transform 0.3s, opacity 0.3s;

  :hover {
    transform: scale(1.2);
    opacity: 1;
  }
`;
