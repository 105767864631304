import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { MentionsInput, Mention, SuggestionDataItem } from 'react-mentions';
import { CircularProgress } from '@material-ui/core';
import { getEmojiDataFromNative, EmojiData } from 'emoji-mart';
import emojisData from 'emoji-mart/data/apple.json';
import emojiRegex from 'emoji-regex';
import { v4 as uuid } from 'uuid';
import { Channel } from 'mattermost-redux/types/channels';
import { Post } from 'mattermost-redux/types/posts';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import { MdCheckCircle } from 'react-icons/md';

import { useChatChannel } from './ChatChannel';
import { ChatPostImage } from './ChatPostImage';
import { ChatEmojiPicker } from './ChatEmojiPicker';

import defaultUserImage from '../../assets/default-user.png';
import PasteImage from '../../utils/PasteImage.js';
import {
  convertToAAC,
  processInWebWorker,
} from '../../utils/convert-to-aac.js';
import { AppMessages } from '../../languages';
import { useApi, UserModel } from '../../hooks/api';
import {
  useMattermost,
  TYPING_UPDATE_INTERVAL,
  ChatWindowStatus,
} from '../../hooks/mattermost';
import { useOffice, getFormattedUserName } from '../../hooks/office';
import { usePlanLimits } from '../../hooks/plan-limits';
import { useToast } from '../../hooks/toast';
import { useSessionState } from '../../hooks/sessionState';
import {
  SendMessageIcon,
  AttachFileIcon,
  CancelIcon,
  EmojiIcon,
  SpinnerIcon,
  MicIcon,
} from '../Icons';
import { IconButton } from '../IconButton';

import { ChatPost } from './ChatPost';

import {
  ChatInputContainer,
  ChatInputAudioTime,
  ChatMentionSuggestion,
  SendingAudioFeedbackContainer,
} from './styles';

const KEY_CODE_ENTER = 13;

const NATIVE_EMOJI_PATTERN = emojiRegex();

interface Props {
  channel: Channel;
  boxStatus: ChatWindowStatus;
  onFocus?: () => void | Promise<void>;
}

interface FileList {
  [fileName: string]: {
    id: string;
    loading: boolean;
    src: string;
    hasPreviewImage: boolean;
    mimeType: string;
  };
}

interface MentionSuggestionDataItem extends SuggestionDataItem {
  user?: UserModel;
}

const previousTypingAt: { [channelId: string]: number } = {};

const stream: { [channelId: string]: MediaStream } = {};
const recorder: { [channelId: string]: RecordRTC } = {};
const recordingInterval: { [channelId: string]: any } = {};

export const ChatInput: React.FC<Props> = ({ channel, boxStatus, onFocus }) => {
  const inputScrollHeight = useChatChannel((state) => state.inputScrollHeight);
  const setInputScrollHeight = useChatChannel(
    (state) => state.setInputScrollHeight
  );
  const setPostsToSend = useChatChannel((state) => state.setPostsToSend);
  const setPostsWithError = useChatChannel((state) => state.setPostsWithError);
  const replyingToPostId = useChatChannel((state) => state.replyingToPostId);
  const setReplyingToPostId = useChatChannel(
    (state) => state.setReplyingToPostId
  );

  const addToast = useToast((state) => state.addToast);
  const getUsersInfo = useOffice((state) => state.getUsersInfo);
  const getAnonymousUsersInfo = useOffice(
    (state) => state.getAnonymousUsersInfo
  );
  const usageLimits = usePlanLimits((state) => state.usageLimits);
  const getUsageLimits = usePlanLimits((state) => state.getUsageLimits);
  const { sendPushNotificationChannelHasNewMessage } = useApi();
  const mattermostUserId = useMattermost(
    (state) => state.mattermostUser?.id || ''
  );

  const team = useMattermost((state) => state.team);
  const isSelectedChannel = useMattermost(
    (state) => state.selectedChannel?.id === channel.id
  );
  const sendTyping = useMattermost((state) => state.sendTyping);
  const uploadFileToChannel = useMattermost(
    (state) => state.uploadFileToChannel
  );
  const sendMessage = useMattermost((state) => state.sendMessage);
  const getFileUrl = useMattermost((state) => state.getFileUrl);
  const getFilePreviewUrl = useMattermost((state) => state.getFilePreviewUrl);
  const getFile = useMattermost((state) => state.getFile);
  const deselectChannel = useMattermost((state) => state.deselectChannel);
  const memberships = useMattermost(
    (state) => state.channelMemberships[channel.id] || []
  );
  const getProseiaUser = useMattermost((state) => state.getProseiaUser);
  const channelDisplayName = useMattermost((state) =>
    state.getChannelDisplayName(channel)
  );
  const replyToPost = useMattermost((state) => {
    const posts = state.channelPosts[channel.id];
    const postsById: { [postId: string]: Post } = (posts?.posts as any) || {};
    return postsById[replyingToPostId];
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const focusLineRef = useRef<HTMLSpanElement>(null);
  const { formatMessage } = useIntl();
  const [pasteImage, setPasteImage] = useState<PasteImage | null>(null);
  const [value, setValue] = useSessionState(`chat.message:${channel.id}`, '');
  const [files, setFiles] = useState<FileList>({});
  const [sending, setSending] = useState(false);
  const [sendingAudio, setSendingAudio] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [recording, setRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState('00:00');

  const recordingAllowed = useMemo(
    () => !value && !sending && !Object.keys(files).length,
    [files, sending, value]
  );

  const uploadFile = useCallback(
    async (fileName: string, data: string | File | Blob) => {
      const { size, length } = data as any;

      const dataSizeInBytes = size || length || 0;
      const ONE_MEGABYTE = 1024 * 1024;
      const dataSizeInMegabytes = dataSizeInBytes / ONE_MEGABYTE;

      let { chatMaxFileSize } = usageLimits;
      if (!chatMaxFileSize) {
        const limits = await getUsageLimits();
        chatMaxFileSize = limits.chatMaxFileSize;
      }

      if (dataSizeInMegabytes > chatMaxFileSize) {
        addToast({
          type: 'info',
          title: formatMessage({ id: AppMessages.limitsChatFileTooLarge }),
          description: formatMessage(
            { id: AppMessages.limitsChatMaxFileSize },
            { limit: chatMaxFileSize }
          ),
          duration: 7000,
        });
        return;
      }

      setFiles((state) => ({
        ...state,
        [fileName]: {
          loading: true,
          id: uuid(),
          src: '',
          hasPreviewImage: false,
          mimeType: '',
        },
      }));

      setUploading(true);

      uploadFileToChannel(channel.id, fileName, data).then(
        (response) => {
          const [fileInfo] = response.file_infos;
          const isGif = fileInfo.mime_type === 'image/gif';

          const setFile = (src: string) => {
            setFiles((state) => ({
              ...state,
              [fileName]: {
                loading: false,
                id: fileInfo.id,
                src,
                hasPreviewImage: fileInfo.has_preview_image,
                mimeType: fileInfo.mime_type,
              },
            }));
            setUploading(false);
          };

          if (!fileInfo.has_preview_image && !isGif) {
            setFile('');
            return;
          }

          if (fileInfo.has_preview_image) {
            getFile(getFilePreviewUrl(fileInfo.id)).then(setFile);
            return;
          }

          getFile(getFileUrl(fileInfo.id)).then(setFile);
        },
        (error) => {
          addToast({
            type: 'error',
            title: 'ERROR',
            description: error.message || error.error || error,
          });
          setFiles((state) => {
            const newState = { ...state };
            delete newState[fileName];
            return newState;
          });
          setUploading(false);
        }
      );
    },
    [
      channel.id,
      usageLimits,
      getUsageLimits,
      addToast,
      formatMessage,
      setFiles,
      uploadFileToChannel,
      getFilePreviewUrl,
      getFileUrl,
      getFile,
    ]
  );

  const handlePastImage = useCallback(
    (data: string | File | Blob) => {
      const fileName = `pasted_at_${new Date().toISOString()}.png`;
      uploadFile(fileName, data);
    },
    [uploadFile]
  );

  useEffect(() => {
    if (pasteImage) return;
    const ref = new PasteImage(inputRef.current);
    ref.on('paste-image-data', (data: any) => {
      handlePastImage(data);
    });
    setPasteImage(ref);
  }, [pasteImage, handlePastImage]);

  const handleDeleteFile = useCallback(
    (fileName: string) => {
      setFiles((state) => {
        const newState = { ...state };
        delete newState[fileName];
        return newState;
      });
    },
    [setFiles]
  );

  const scrollDown = useCallback(() => {
    const container = document.getElementById(channel.id);
    if (!container) return;
    container.scrollTop = container.scrollHeight;
  }, [channel.id]);

  const addValue = useCallback(
    (valueToAdd: string) => {
      if (!inputRef.current) return;
      const field = inputRef.current;
      let startPos = field.selectionStart;
      let endPos = field.selectionEnd;

      setValue((state) => {
        let offset = 0;

        if (state.length > field.value.length) {
          if (startPos !== endPos) {
            startPos = Math.max(startPos, endPos);
            endPos = startPos;
          }

          offset = state.length - field.value.length;

          const previousText = state.substring(0, startPos + offset);
          const closestPreviousMentionStart = previousText.lastIndexOf('@');
          const closestPreviousMentionEnd = previousText.lastIndexOf('}}');

          if (closestPreviousMentionStart > closestPreviousMentionEnd) {
            offset += state.substr(startPos + offset).indexOf('}}') + 2;
          }
        }

        return `${state.substring(
          0,
          startPos + offset
        )}${valueToAdd}${state.substring(endPos + offset, state.length)}`;
      });

      setTimeout(() => {
        if (!inputRef.current) return;
        inputRef.current.selectionStart = startPos + valueToAdd.length;
        inputRef.current.selectionEnd = startPos + valueToAdd.length;
        inputRef.current.focus();
      }, 15);
    },
    [setValue]
  );

  const sendPushNotification = useCallback(async () => {
    if (!team || !mattermostUserId) return;

    const recipientIds = memberships.reduce<string[]>((res, member) => {
      if (mattermostUserId === member.user_id) return res;

      const user = getProseiaUser(member.user_id);
      const usersInfoState = getUsersInfo();
      const userInfo = usersInfoState[user.id];
      const anonymousUsersInfoState = getAnonymousUsersInfo();
      const anonymousUserInfo = anonymousUsersInfoState[user.id];

      if (
        userInfo &&
        (userInfo.status === 'available' || userInfo.status === 'busy')
      ) {
        return res;
      }

      if (anonymousUserInfo && Object.keys(anonymousUserInfo).length) {
        return res;
      }

      return [...res, member.user_id];
    }, []);

    if (!recipientIds.length) return;

    const sender = getProseiaUser(mattermostUserId);

    await sendPushNotificationChannelHasNewMessage({
      channelId: channel.id,
      teamId: team.id,
      senderId: mattermostUserId,
      channelName: channel.type === 'D' ? undefined : channelDisplayName,
      picture: sender.picture || '',
      recipientIds,
      senderName: getFormattedUserName(sender),
    });
  }, [
    channel.id,
    channel.type,
    channelDisplayName,
    team,
    mattermostUserId,
    memberships,
    getUsersInfo,
    getAnonymousUsersInfo,
    getProseiaUser,
    sendPushNotificationChannelHasNewMessage,
  ]);

  const handleSend = useCallback(() => {
    if (sending) return;

    const fileNames = Object.keys(files).filter(
      (fileName) => !files[fileName].loading
    );
    const fileIds = fileNames.map((fileName) => files[fileName].id);

    if (fileIds.length === 0 && !value) return;

    scrollDown();

    setSending(true);
    const stringValue = value.replace(NATIVE_EMOJI_PATTERN, (match) => {
      return getEmojiDataFromNative(match, 'apple', emojisData as any).colons;
    });

    const post = {
      id: uuid(),
      channel_id: channel.id,
      message: stringValue,
      file_ids: fileIds,
      props: {},
      create_at: Date.now(),
    } as Post;

    if (replyingToPostId) post.props.replyTo = replyingToPostId;

    setPostsToSend((state) => [...state, post]);

    sendMessage(channel, stringValue, fileIds, replyingToPostId)
      .then(
        () => {
          sendPushNotification();
        },
        (error) => {
          setPostsWithError((state) => [...state, post]);

          addToast({
            type: 'error',
            title: 'ERROR',
            description: error.message || error.error || error,
          });

          setValue((state) => {
            if (state) return state;
            setFiles(files);
            return value;
          });
        }
      )
      .finally(() => {
        setPostsToSend((state) =>
          state.reduce<Post[]>((res, item) => {
            if (item.id === post.id) return res;
            return [...res, item];
          }, [])
        );
      });

    setReplyingToPostId('');
    setValue('');
    if (fileIds.length > 0) {
      setFiles((state) => {
        const newState = { ...state };
        fileNames.forEach((fileName) => {
          delete newState[fileName];
        });
        return newState;
      });
    }

    setSending(false);
  }, [
    sending,
    value,
    files,
    replyingToPostId,
    channel,
    addToast,
    setValue,
    setFiles,
    scrollDown,
    sendMessage,
    sendPushNotification,
    setPostsToSend,
    setPostsWithError,
    setReplyingToPostId,
  ]);

  const handleSelectFile = useCallback(() => {
    if (!inputFileRef.current) return;
    inputFileRef.current.click();
  }, []);

  const handleAttachFile = useCallback(async () => {
    if (!inputFileRef.current) return;

    const fileList = inputFileRef.current.files;

    if (fileList && fileList.length > 0) {
      const uploadPromises = Array.from(fileList).map((file) => {
        if (!file) return Promise.resolve();
        return uploadFile(file.name, file);
      });
      await Promise.allSettled(uploadPromises);
      inputFileRef.current.value = '';
    }
  }, [uploadFile]);

  const handleTyping = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (sending) {
        e.preventDefault();
        return;
      }

      const currentTime = Date.now();
      const previousAt = previousTypingAt[channel.id];

      if (!previousAt || currentTime - previousAt >= TYPING_UPDATE_INTERVAL) {
        sendTyping(channel);
        previousTypingAt[channel.id] = currentTime;
      }
    },
    [sending, channel, sendTyping]
  );

  const handleKeyDown = useCallback(
    (
      e:
        | React.KeyboardEvent<HTMLTextAreaElement>
        | React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (sending) {
        e.preventDefault();
        return;
      }

      const enterPressed = e.keyCode === KEY_CODE_ENTER;

      if (enterPressed && !e.ctrlKey && !e.shiftKey && !e.altKey) {
        e.preventDefault();
        handleSend();
      } else if (enterPressed && !e.shiftKey) {
        addValue('\n');
      }
    },
    [sending, handleSend, addValue]
  );

  const handleInputFocus = useCallback(async () => {
    if (focusLineRef.current) focusLineRef.current.classList.add('has-focus');
    if (onFocus) onFocus();
  }, [onFocus]);

  const handleInputBlur = useCallback(() => {
    if (focusLineRef.current)
      focusLineRef.current.classList.remove('has-focus');
    deselectChannel(channel);
  }, [channel, deselectChannel]);

  const handleAddEmoji = useCallback(
    (emoji: EmojiData) => {
      const { native } = emoji as any;
      addValue(native || emoji.colons);
    },
    [addValue]
  );

  const handleStartRecording = useCallback(async () => {
    processInWebWorker();
    stream[channel.id] = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });
    setRecording(true);
    recorder[channel.id] = new RecordRTC(stream[channel.id], {
      // type: 'audio',
      // mimeType: 'audio/wav',
      bufferSize: 16384,
      recorderType: StereoAudioRecorder,
    });
    recorder[channel.id].startRecording();
  }, [channel.id]);

  const handleCancelRecording = useCallback(() => {
    setRecording(false);
    if (!recorder[channel.id]) return;

    recorder[channel.id].stopRecording(() => {
      stream[channel.id].getTracks().forEach((track) => {
        track.stop();
        stream[channel.id].removeTrack(track);
      });
    });
  }, [channel.id]);

  const handleEndRecording = useCallback(() => {
    setRecording(false);
    if (!recorder[channel.id]) return;

    setSendingAudio(true);
    setSending(true);
    recorder[channel.id].stopRecording(() => {
      stream[channel.id].getTracks().forEach((track) => {
        track.stop();
        stream[channel.id].removeTrack(track);
      });

      const fileName = `audio_${new Date().toISOString()}.ogg`;

      //convertToAAC(recorder[channel.id].getBlob(), (blob: Blob) => {        
      //});
      uploadFileToChannel(channel.id, fileName, recorder[channel.id].getBlob()).then(
        (response) => {
          const [fileInfo] = response.file_infos;

          const post = {
            id: uuid(),
            channel_id: channel.id,
            message: '',
            file_ids: [fileInfo.id],
            props: {},
            create_at: Date.now(),
          } as Post;

          if (replyingToPostId) post.props.replyTo = replyingToPostId;

          setPostsToSend((state) => [...state, post]);

          sendMessage(channel, '', [fileInfo.id], replyingToPostId)
            .then(
              () => {
                sendPushNotification();
              },
              (error) => {
                setPostsWithError((state) => [...state, post]);

                addToast({
                  type: 'error',
                  title: 'ERROR',
                  description: error.message || error.error || error,
                });
              }
            )
            .finally(() => {
              setPostsToSend((state) =>
                state.reduce<Post[]>((res, item) => {
                  if (item.id === post.id) return res;
                  return [...res, item];
                }, [])
              );
              setSending(false);
              setSendingAudio(false);
            });
          setReplyingToPostId('');
        },
        (error) => {
          setSending(false);
          setSendingAudio(false);
          addToast({
            type: 'error',
            title: 'ERROR',
            description: error.message || error.error || error,
          });
        }
      );
    });
  }, [
    channel,
    replyingToPostId,
    uploadFileToChannel,
    sendMessage,
    sendPushNotification,
    addToast,
    setPostsToSend,
    setPostsWithError,
    setReplyingToPostId,
  ]);

  useEffect(() => {
    if (!recording) {
      setRecordingTime('00:00');

      return () => {
        clearInterval(recordingInterval[channel.id]);
      };
    }

    const startedAt = new Date();

    recordingInterval[channel.id] = setInterval(() => {
      const now = new Date();
      const diff = now.getTime() - startedAt.getTime();
      const secondsDiff = Math.floor(diff / 1000);
      const seconds = (secondsDiff % 60).toString().padStart(2, '0');
      const minutes = Math.floor(secondsDiff / 60)
        .toString()
        .padStart(2, '0');
      setRecordingTime(`${minutes}:${seconds}`);
    }, 1000);

    return () => {
      clearInterval(recordingInterval[channel.id]);
    };
  }, [channel.id, recording]);

  useEffect(() => {
    return () => {
      handleCancelRecording();
    };
  }, [handleCancelRecording]);

  useEffect(() => {
    if (sending || uploading || !isSelectedChannel || !inputRef.current) return;
    inputRef.current.focus();
  }, [sending, uploading, isSelectedChannel]);

  useEffect(() => {
    if (sending || uploading || !replyingToPostId || !inputRef.current) return;
    inputRef.current.focus();
  }, [sending, uploading, replyingToPostId]);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.style.height = '0px';
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    setInputScrollHeight(inputRef.current.scrollHeight);
  }, [value, setInputScrollHeight]);

  const loadingFiles = useMemo(
    () =>
      Object.keys(files)
        .map((fileName) => {
          return { ...files[fileName], fileName };
        })
        .filter((t) => t.loading),
    [files]
  );

  const loadedFiles = useMemo(
    () =>
      Object.keys(files)
        .map((fileName) => {
          return { ...files[fileName], fileName };
        })
        .filter((t) => !t.loading),
    [files]
  );

  const inputFile = useMemo(
    () => (
      <input
        ref={inputFileRef}
        type="file"
        multiple
        style={{ display: 'none' }}
        onChange={handleAttachFile}
      />
    ),
    [handleAttachFile]
  );

  const mentionSuggestionData = useMemo(
    () =>
      memberships.reduce<MentionSuggestionDataItem[]>((result, item) => {
        if (item.user_id === mattermostUserId) return result;
        const user = getProseiaUser(item.user_id);
        if (user.id === 'unknown' || user.id === item.user_id) return result;
        if (user.accessRevokedAt) return result;
        if (!user.lastLoginAt) return result;
        const id = user.email.replace('@', '.');
        const display = getFormattedUserName(user);
        return [...result, { id, display, user }];
      }, []),
    [getProseiaUser, mattermostUserId, memberships]
  );

  const renderMentionSuggestion = useCallback(
    (suggestion: MentionSuggestionDataItem) => (
      <ChatMentionSuggestion>
        <img
          src={suggestion.user?.picture || defaultUserImage}
          alt={suggestion.display}
        />
        <span>{suggestion.display}</span>
      </ChatMentionSuggestion>
    ),
    []
  );

  const mentionTrigger = useMemo(
    () => (
      <Mention
        className="chat-input-mention"
        trigger="@"
        appendSpaceOnAdd
        markup="@__id__{{__display__}}"
        displayTransform={(id, display) => `@${display}`}
        data={mentionSuggestionData}
        renderSuggestion={renderMentionSuggestion}
      />
    ),
    [mentionSuggestionData, renderMentionSuggestion]
  );

  const inputTextarea = useMemo(
    () => (
      <MentionsInput
        inputRef={inputRef}
        className="chat-input"
        id={`channel-input-${channel.id}`}
        placeholder={formatMessage({ id: AppMessages.chatInputPlaceholder })}
        suggestionsPortalHost={containerRef.current ?? undefined}
        allowSuggestionsAboveCursor
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={handleTyping}
        onKeyDown={handleKeyDown}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        disabled={recording || sendingAudio}
      >
        {mentionTrigger}
      </MentionsInput>
    ),
    [
      channel.id,
      formatMessage,
      handleInputBlur,
      handleInputFocus,
      handleKeyDown,
      handleTyping,
      recording,
      sendingAudio,
      setValue,
      value,
      mentionTrigger,
    ]
  );

  const emojiButton = useMemo(
    () =>
      !sendingAudio &&
      !recording && (
        <IconButton iconSize={20} onClick={() => setEmojiPickerOpen(true)}>
          <EmojiIcon />
        </IconButton>
      ),
    [recording, sendingAudio]
  );

  const attachFileButton = useMemo(
    () =>
      !sendingAudio &&
      !recording && (
        <IconButton iconSize={20} onClick={handleSelectFile}>
          <AttachFileIcon />
        </IconButton>
      ),
    [recording, sendingAudio, handleSelectFile]
  );

  const sendMessageButton = useMemo(
    () =>
      !sendingAudio &&
      !recordingAllowed && (
        <IconButton iconSize={20} onClick={handleSend}>
          <SendMessageIcon />
        </IconButton>
      ),
    [handleSend, recordingAllowed, sendingAudio]
  );

  const sendingAudioFeedback = useMemo(
    () =>
      sendingAudio && (
        <SendingAudioFeedbackContainer>
          <span>{formatMessage({ id: AppMessages.chatSendingAudio })}</span>
          <CircularProgress style={{ color: 'orangered' }} />
        </SendingAudioFeedbackContainer>
      ),
    [sendingAudio, formatMessage]
  );

  const startRecordingButton = useMemo(
    () =>
      recordingAllowed &&
      !sendingAudio &&
      !recording && (
        <IconButton iconSize={20} onClick={handleStartRecording}>
          <MicIcon enabled />
        </IconButton>
      ),
    [handleStartRecording, recording, sendingAudio, recordingAllowed]
  );

  const cancelRecordingButton = useMemo(
    () =>
      recordingAllowed &&
      recording && (
        <IconButton
          iconSize={30}
          iconColor="red"
          onClick={handleCancelRecording}
        >
          <CancelIcon />
        </IconButton>
      ),
    [handleCancelRecording, recording, recordingAllowed]
  );

  const sendRecordingButton = useMemo(
    () =>
      recordingAllowed &&
      recording && (
        <IconButton iconSize={30} onClick={handleEndRecording}>
          <MdCheckCircle />
        </IconButton>
      ),
    [handleEndRecording, recording, recordingAllowed]
  );

  const focusLine = useMemo(
    () => <span ref={focusLineRef} className="focus-line" />,
    []
  );

  const replyingToContainer = useMemo(
    () =>
      replyToPost && (
        <div className="replying-to">
          {focusLine}
          <ChatPost post={replyToPost} isReplied />
          <IconButton
            iconSize={14}
            className="cancel-reply"
            color="transparent"
            onClick={() => setReplyingToPostId('')}
          >
            <CancelIcon />
          </IconButton>
        </div>
      ),
    [focusLine, replyToPost, setReplyingToPostId]
  );

  const fileList = useMemo(
    () => (
      <div className="file-list">
        {loadedFiles.map((t) => {
          const isGif = t.mimeType === 'image/gif';
          return (
            <div key={`loaded-${t.id}`}>
              {!t.hasPreviewImage && !isGif && t.fileName}
              {(t.hasPreviewImage || isGif) && <ChatPostImage src={t.src} />}
              <div className="delete">
                <IconButton
                  iconSize={14}
                  onClick={() => handleDeleteFile(t.fileName)}
                >
                  <CancelIcon />
                </IconButton>
              </div>
            </div>
          );
        })}
        {loadingFiles.map((t) => {
          return (
            <div key={`loading-${t.id}`}>
              loading file...
              <SpinnerIcon />
            </div>
          );
        })}
      </div>
    ),
    [handleDeleteFile, loadedFiles, loadingFiles]
  );

  const emojiPicker = useMemo(
    () => (
      <ChatEmojiPicker
        open={emojiPickerOpen}
        onClose={() => setEmojiPickerOpen(false)}
        onSelect={handleAddEmoji}
      />
    ),
    [emojiPickerOpen, handleAddEmoji]
  );

  return (
    <ChatInputContainer
      scrollHeight={inputScrollHeight}
      ref={containerRef}
      boxStatus={boxStatus}
    >
      {inputFile}
      {inputTextarea}

      {emojiButton}
      {attachFileButton}
      {sendMessageButton}

      {sendingAudioFeedback}

      {startRecordingButton}
      {cancelRecordingButton}
      {recordingAllowed && recording && (
        <ChatInputAudioTime>{recordingTime}</ChatInputAudioTime>
      )}
      {sendRecordingButton}

      {replyingToContainer}
      {fileList}

      {!replyToPost && focusLine}

      {emojiPicker}
    </ChatInputContainer>
  );
};
