import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Favicon from 'react-favicon';
import favicon from './assets/favicon-32x32.png';
import Routes from './routes';
import AppProvider from './hooks';
import GlobalStyle from './styles/global';

const App: React.FC = () => (
  <BrowserRouter>
    <Favicon url={favicon} />
    <AppProvider>
      <Routes />
    </AppProvider>
    <GlobalStyle />
  </BrowserRouter>
);

export default App;
