import React, { useCallback, useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import { LanguageData } from '../languages';
import englishUS from '../languages/en-US';
import spanishES from '../languages/es-ES';
import portugueseBR from '../languages/pt-BR';
import { createContext, useContextSelector } from './context';
import { usePersistentState } from './persistentState';

const languages: { [key: string]: LanguageData } = {
  'en-US': englishUS,
  'es-ES': spanishES,
  'pt-BR': portugueseBR,
};

export const supportedLanguages = Object.keys(languages);

interface LanguageContextData {
  language: string;
  messages: LanguageData;
  setLanguage: (value: string) => void;
}

let defaultLanguage = navigator.language;

if (!supportedLanguages.includes(defaultLanguage)) {
  switch (defaultLanguage.split('-')[0]) {
    case 'en':
      defaultLanguage = 'en-US';
      break;
    case 'es':
      defaultLanguage = 'es-ES';
      break;
    case 'pt':
      defaultLanguage = 'pt-BR';
      break;
    default:
      defaultLanguage = 'en-US';
      break;
  }
}

const LanguageContext = createContext({} as LanguageContextData);

export const LanguageProvider: React.FC = ({ children }) => {
  const [lang, setLang] = usePersistentState('language', defaultLanguage);
  const messages = languages[lang];
  const locale = lang.split('-')[0];

  const setLanguage = useCallback(
    (value: string) => {
      setLang(value);
    },
    [setLang]
  );

  const contextValue = useMemo<LanguageContextData>(
    () => ({
      language: lang,
      messages,
      setLanguage,
    }),
    [lang, messages, setLanguage]
  );

  return (
    <LanguageContext.Provider value={contextValue}>
      <IntlProvider messages={messages} locale={locale}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export function useLanguage<TResult>(
  selector: (state: LanguageContextData) => TResult
): TResult {
  return useContextSelector(LanguageContext, selector);
}
