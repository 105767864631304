import React, { useCallback, useMemo } from 'react';

import { UserModel } from '../../hooks/api';
import { useMattermost } from '../../hooks/mattermost';
import { useOffice, getFormattedUserName } from '../../hooks/office';
import { useModels } from '../../hooks/model';
import { useThemeContext } from '../../hooks/theme';
import { Avatar } from '../Avatar';
import { LogoSymbol } from '../LogoSymbol';

import { ContactContainer } from './styles';

interface Props {
  user: UserModel;
  onClick: () => void;
}

export const ChatListContact: React.FC<Props> = ({ user, onClick }) => {
  const background = useThemeContext((state) => state.theme.background);
  const textColor = useThemeContext((state) => state.theme.textColor);
  const offices = useModels((state) => state.offices);
  const userInfoOfficeId = useOffice(
    (state) => state.usersInfo[user.id]?.officeId || ''
  );
  const userInfoRoomId = useOffice(
    (state) => state.usersInfo[user.id]?.roomId || ''
  );
  const userInfoStatus = useOffice(
    (state) => state.usersInfo[user.id]?.status || 'inactive'
  );
  const userInfoStatusMessage = useOffice(
    (state) => state.usersInfo[user.id]?.statusMessage || ''
  );
  const draggable = useOffice((state) => !!state.usersInfo[user.id]);
  const channel = useMattermost((state) => state.directChannels[user.email]);
  const unreads = useMattermost((state) =>
    state.getChannelUnreadsMessageCount(channel || {})
  );
  const openDirectChannel = useMattermost((state) => state.openDirectChannel);

  const handleClick = useCallback(() => {
    openDirectChannel(user.email);
    onClick();
  }, [onClick, openDirectChannel, user.email]);

  const avatar = useMemo(
    () => (
      <Avatar
        id={user.id}
        firstName={user.firstName}
        lastName={user.lastName}
        picture={user.picture}
        email={user.email}
        roleDescription={user.roleDescription}
        status={userInfoStatus}
        statusMessage={userInfoStatusMessage}
        draggable={draggable}
      />
    ),
    [
      user.email,
      user.firstName,
      user.id,
      user.lastName,
      user.picture,
      user.roleDescription,
      draggable,
      userInfoStatus,
      userInfoStatusMessage,
    ]
  );

  const userName = useMemo(
    () => (
      <div className="user-name">
        {getFormattedUserName({
          firstName: user.firstName,
          lastName: user.lastName,
        })}
      </div>
    ),
    [user.firstName, user.lastName]
  );

  const roomLocationIcon = useMemo(
    () => <LogoSymbol primaryColor={textColor} secondaryColor={background} />,
    [textColor, background]
  );

  const roomName = useMemo(() => {
    if (!userInfoOfficeId || !userInfoRoomId) return '';
    const office = offices.find((t) => t.id === userInfoOfficeId);
    const room = office?.rooms.find((t) => t.id === userInfoRoomId);
    let result = room?.name || '';
    if (result.length > 18) result = `${result.substr(0, 16).trim()}...`;
    return result;
  }, [offices, userInfoOfficeId, userInfoRoomId]);

  const roomLocationContainer = useMemo(
    () =>
      draggable && (
        <div className="location">
          {roomLocationIcon}
          {roomName}
        </div>
      ),
    [roomLocationIcon, roomName, draggable]
  );

  const textContent = useMemo(
    () => (
      <div>
        {userName}
        {roomLocationContainer}
      </div>
    ),
    [roomLocationContainer, userName]
  );

  const unreadsContainer = useMemo(
    () => !!unreads && <div className="unreads">{unreads}</div>,
    [unreads]
  );

  return (
    <ContactContainer onClick={handleClick}>
      {avatar}
      {textContent}
      {unreadsContainer}
    </ContactContainer>
  );
};
