import { Tooltip } from '@material-ui/core';
import { styled, css } from '../../themes';

import {
  TooltipPosition,
  TooltipTextAlign,
  TooltipArrowPosition,
} from './index';

interface ContainerProps {
  position: TooltipPosition;
  minWidth: number;
  maxWidth: number;
  textAlign: TooltipTextAlign;
  arrowPosition: TooltipArrowPosition;
}

const SPACING = 5;

const arrowPos = {
  start: '15%',
  center: '50%',
  end: '85%',
};

export const StyledTooltip = styled(Tooltip)``;

export const Container = styled.span<ContainerProps>`
  visibility: hidden;
  min-width: ${(props) => props.minWidth}px;
  max-width: ${(props) => props.maxWidth}px;
  width: max-content;
  background: ${(props) => props.theme.textColor};
  color: ${(props) => props.theme.background};
  text-align: ${(props) => props.textAlign};
  padding: ${SPACING}px;
  position: absolute;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s;

  ${(props) => {
    const background = props.theme.textColor;

    switch (props.position) {
      case 'bottom':
        return css`
          top: calc(100% + ${SPACING * 2}px);
          justify-self: flex-start;

          ::before {
            content: '';
            position: absolute;
            top: -${SPACING * 2}px;
            left: ${arrowPos[props.arrowPosition]};
            margin-left: -${SPACING}px;
            border-width: ${SPACING}px;
            border-style: solid;
            border-color: transparent transparent ${background} transparent;
          }
        `;
      case 'bottom-start':
        return css`
          top: calc(100% + ${SPACING * 2}px);
          left: 0px;

          ::before {
            content: '';
            position: absolute;
            top: -${SPACING * 2}px;
            left: ${arrowPos[props.arrowPosition]};
            margin-left: -${SPACING}px;
            border-width: ${SPACING}px;
            border-style: solid;
            border-color: transparent transparent ${background} transparent;
          }
        `;
      case 'bottom-end':
        return css`
          top: calc(100% + ${SPACING * 2}px);
          right: 0px;

          ::before {
            content: '';
            position: absolute;
            top: -${SPACING * 2}px;
            left: ${arrowPos[props.arrowPosition]};
            margin-left: -${SPACING}px;
            border-width: ${SPACING}px;
            border-style: solid;
            border-color: transparent transparent ${background} transparent;
          }
        `;
      case 'left':
        return css`
          right: calc(100% + ${SPACING * 2}px);
          align-self: center;

          ::after {
            content: '';
            position: absolute;
            top: ${arrowPos[props.arrowPosition]};
            left: 100%;
            margin-top: -${SPACING}px;
            border-width: ${SPACING}px;
            border-style: solid;
            border-color: transparent transparent transparent ${background};
          }
        `;
      case 'right':
        return css`
          left: calc(100% + ${SPACING * 2}px);
          align-self: center;

          ::before {
            content: '';
            position: absolute;
            top: ${arrowPos[props.arrowPosition]};
            left: -${SPACING * 2}px;
            margin-top: -${SPACING}px;
            border-width: ${SPACING}px;
            border-style: solid;
            border-color: transparent ${background} transparent transparent;
          }
        `;
      case 'top-start':
        return css`
          bottom: calc(100% + ${SPACING * 2}px);
          left: 0px;

          ::after {
            content: '';
            position: absolute;
            top: 100%;
            left: ${arrowPos[props.arrowPosition]};
            margin-left: -${SPACING}px;
            border-width: ${SPACING}px;
            border-style: solid;
            border-color: ${background} transparent transparent transparent;
          }
        `;
      case 'top-end':
        return css`
          bottom: calc(100% + ${SPACING * 2}px);
          right: 0px;

          ::after {
            content: '';
            position: absolute;
            top: 100%;
            left: ${arrowPos[props.arrowPosition]};
            margin-left: -${SPACING}px;
            border-width: ${SPACING}px;
            border-style: solid;
            border-color: ${background} transparent transparent transparent;
          }
        `;
      default:
        return css`
          bottom: calc(100% + ${SPACING * 2}px);
          justify-self: center;

          ::after {
            content: '';
            position: absolute;
            top: 100%;
            left: ${arrowPos[props.arrowPosition]};
            margin-left: -${SPACING}px;
            border-width: ${SPACING}px;
            border-style: solid;
            border-color: ${background} transparent transparent transparent;
          }
        `;
    }
  }}
`;
