import { MenuItem } from '@material-ui/core';
import { styled } from '../../themes';

export const StyledMenuItem = styled(MenuItem)`
  && {
    font-family: 'Inter', Arial, Helvetica, sans-serif;

    .MuiListItem-gutters {
      padding-left: 5px;
    }
  }
`;
