import { TextField, LinearProgress } from '@material-ui/core';
import { keyframes } from 'styled-components';
import { shade } from 'polished';
import { styled, css } from '../../themes';

export const FONT_COLOR = '#344d9a';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 40%;
  min-width: 450px;
  min-height: 90vh;
  left: 0;
  bottom: 0;
  margin: 5vh 5vw;
  padding: 15px;
  border-radius: 5px;
  background: #ffffffdd;
  /* overflow-y: auto; */

  @media (max-width: 500px) {
    width: 100%;
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    /* flex: auto; */
  }

  animation: ${keyframes`
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);;
    }
  `} 0.5s linear;
`;

export const Logo = styled.div`
  width: 100%;
  justify-content: left !important;
  align-items: flex-start !important;
`;

export const TitleContainer = styled.div`
  color: ${FONT_COLOR};
  font-size: 30px;
  font-weight: bold;
  /* text-align: center; */
`;

interface MessageContainerProps {
  align?: 'center' | 'left' | 'right';
  bold?: boolean;
}

export const MessageContainer = styled.div<MessageContainerProps>`
  color: ${FONT_COLOR};
  font-size: 20px;

  ${(props) =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}

  & + & {
    margin-top: 8px;
  }
`;

export const FormContainer = styled.div`
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    > * {
      width: 100%;
    }

    > .inline-fields {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      > * {
        width: 100%;
      }

      > div + div {
        min-width: 45%;
        margin-left: 5% !important;
      }
    }
  }
`;

export const Button = styled.button`
  position: relative;
  margin-top: 30px;
  border: none;
  background: ${FONT_COLOR};
  padding: 7px 40px;
  color: white;
  font-size: 18px;
  white-space: nowrap;
  border-radius: 25px;
  transition: all 0.3s;
  width: fit-content !important;
  min-width: 250px !important;

  @media (max-width: 500px) {
    width: 90% !important;
  }

  svg {
    position: absolute;
    fill: white;
    margin: 3px 8px;
  }

  :hover {
    background: ${shade(0.3, FONT_COLOR)};
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
    :hover {
      background: ${FONT_COLOR};
    }
  }
`;

export const LinkButton = styled.button`
  border: none;
  background: transparent;
  color: ${(props) => props.theme.textColor};
  transition: all 0.3s;
  max-width: fit-content;
  margin-top: 20px;
  font-size: 14px;

  :hover {
    font-weight: bold;
  }
`;

export const ConfigContainer = styled.div`
  position: fixed;
  top: 25px;
  right: 25px;
  display: flex;
  justify-content: space-between;
`;

export const PageImage = styled.img`
  position: fixed;
  right: -26vh;
  top: -12vh;
  height: 112vh;
`;

export const LogoImage = styled.img`
  /* width: 100%; */
`;

export const CopyrightContainer = styled.div`
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  padding: 0 20px;
  align-items: center !important;
  justify-content: center !important;
  color: ${FONT_COLOR};
  width: fit-content !important;
  /* font-size: 14px; */

  > img {
    margin-left: 10px;
    height: 25px;
  }
`;

const STYLED_INPUT_FONT_SIZE = '22px';

export const StyledInput = styled(TextField)`
  margin: 0px !important;
  /* margin-left: 50px !important; */
  width: 50% !important;
  max-width: 280px !important;
  height: 77px;

  @media (max-width: 500px) {
    width: 90% !important;
    max-width: 90% !important;
  }

  .MuiInputBase-root {
    color: ${FONT_COLOR};
    font-family: inherit;
    font-size: ${STYLED_INPUT_FONT_SIZE};

    .MuiInputAdornment-root {
      * {
        color: ${FONT_COLOR};
        font-family: inherit;
        font-size: ${STYLED_INPUT_FONT_SIZE};
      }
    }
  }

  .MuiFormLabel-root {
    color: ${FONT_COLOR};
    opacity: 0.6;
    font-size: ${STYLED_INPUT_FONT_SIZE};

    &.Mui-focused {
      color: ${FONT_COLOR};
      opacity: 1;
    }
  }

  .MuiInput-underline {
    :hover:not(.Mui-disabled):before {
      border-bottom-color: ${FONT_COLOR};
      opacity: 0.5;
    }

    :before {
      border-bottom-color: ${FONT_COLOR};
      opacity: 0.3;
    }
    :after {
      border-bottom-color: ${FONT_COLOR};
    }
  }
`;

export const ProgressBar = styled(LinearProgress)`
  width: calc(100% - 200px) !important;
  min-width: 300px !important;

  @media (max-width: 500px) {
    width: 100% !important;
    min-width: 100% !important;
  }
`;

export const ButtonProgressIndicator = styled(LinearProgress)`
  position: absolute !important;
  width: 100% !important;
  min-width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
  border-radius: 25px;
  opacity: 0.5;
`;

export const ProgressMask = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background: #ffffff88;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IncompatibleBrowserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 30%;
  }

  > * {
    width: 100%;
    margin: 30px 0;
    @media only screen and (max-height: 600px) {
      margin: 15px 0;
    }
  }

  /* > * + * {
    margin-top: 30px;
  } */
`;

export const IncompatibleBrowserLinksContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-around;

  > * {
    margin-right: 8px;
  }
`;

export const IncompatibleBrowserLinks = styled.a`
  background: ${FONT_COLOR};
  border-radius: 25px;
  color: #fff;
  padding: 8px;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  font-weight: bold;
  border: none;
  transition: all 0.3s;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 16px;
    width: 30px;
    height: 30px;
  }

  :hover {
    background: ${shade(0.3, FONT_COLOR)};
  }
`;

export const IncompatibleBrowserTitleContainer = styled.div`
  color: ${FONT_COLOR};
  font-size: 25px;
  font-weight: bold;
  text-align: center;
`;
