import { shade } from 'polished';
import { styled } from '../../themes';

const MIN_WIDTH = 400;
const USER_LIST_WIDTH = 250;

export const Container = styled.div`
  position: relative;
  width: 60%;
  min-width: ${MIN_WIDTH}px;
  max-width: 95%;
  width: 80%;
  height: 80vh;
  background: ${(props) => props.theme.boxColor};
  color: ${(props) => props.theme.textColor};
  box-shadow: ${(props) => props.theme.boxGlow};
  border-radius: 5px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  padding-left: 10px;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: calc(80vh - 38px);
  padding: 0px 20px 20px;

  > .map {
    height: calc(80vh - 58px);
    width: calc(100% - ${USER_LIST_WIDTH}px);
  }

  > .user-list {
    display: flex;
    flex-direction: column;
    min-width: ${USER_LIST_WIDTH}px;
    max-width: ${USER_LIST_WIDTH}px;
    height: calc(80vh - 58px);
    padding-left: 10px;

    > .scroll-list {
      margin-top: 10px;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
  }
`;

export const UserContainer = styled.button`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  cursor: pointer;
  background: transparent;
  border: none;
  color: ${(props) => props.theme.textColor};

  :hover {
    background: ${(props) => shade(0.2, props.theme.boxColor)};
  }

  > .avatar {
    transform: scale(0.8);
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-flex-start;
`;

export const UserName = styled.span`
  text-align: left;
`;

export const LocationTimestamp = styled.span`
  font-size: 10px;
  text-align: left;
`;
