import React, { useState, useEffect } from 'react';

import { useApi, SurveyPlatform } from '../../hooks/api';
import { useOffice } from '../../hooks/office';

import { openZohoSurvey } from './ZohoSurveyScript';

const ONE_DAY = 1000 * 60 * 60 * 24;

export const Survey: React.FC = () => {
  const { getCurrentSurvey } = useApi();
  const email = useOffice((state) => state.currentUserInfo?.email || '');
  const getUser = useOffice((state) => state.getUser);
  const [loaded, setLoaded] = useState(false);

  const {
    tutorialEnterRoomDoneAt,
    tutorialContextMenuDoneAt,
    tutorialInviteToMeetingDoneAt,
  } = getUser();

  const allTutorialsDone =
    !!tutorialEnterRoomDoneAt &&
    !!tutorialContextMenuDoneAt &&
    !!tutorialInviteToMeetingDoneAt;

  const lastTutorialAt = Math.max(
    ...[
      tutorialEnterRoomDoneAt,
      tutorialContextMenuDoneAt,
      tutorialInviteToMeetingDoneAt,
    ].map((date) => new Date(date || 0).getTime())
  );

  const timeSinceLastTutorial = Date.now() - lastTutorialAt;
  const isNew = timeSinceLastTutorial < ONE_DAY;

  useEffect(() => {
    if (loaded || isNew || !allTutorialsDone || !email) return;

    setLoaded(true);
    getCurrentSurvey().then((survey) => {
      if (!survey || survey.platform !== SurveyPlatform.zoho) return;
      openZohoSurvey(survey.externalId, email);
    }, console.error);
  }, [email, loaded, allTutorialsDone, isNew, getCurrentSurvey]);

  return <></>;
};
