/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import { Picker, EmojiData } from 'emoji-mart';

import { useThemeContext } from '../../hooks/theme';
import { ChatEmojiPickerContainer } from './styles';

type ThemeTitle = 'light' | 'dark';

interface Props {
  open: boolean;
  onClose: () => void;
  onSelect?: (emoji: EmojiData) => void;
}

export const ChatEmojiPicker: React.FC<Props> = ({
  open,
  onClose,
  onSelect,
}) => {
  const themeTitle = useThemeContext((state) => state.theme.title);

  const handleClose = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const handleSelect = useCallback(
    (emoji: EmojiData) => {
      if (onSelect) onSelect(emoji);
      handleClose();
    },
    [onSelect, handleClose]
  );

  return (
    <ChatEmojiPickerContainer>
      {open && (
        <>
          <div className="outside-area" onClick={handleClose} />
          <Picker
            autoFocus
            theme={themeTitle as ThemeTitle}
            sheetSize={64}
            onSelect={handleSelect}
            emoji=""
            title=""
          />
        </>
      )}
    </ChatEmojiPickerContainer>
  );
};
