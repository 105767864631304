/* eslint-disable no-restricted-globals */
import React, { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ChannelMembership } from 'mattermost-redux/types/channels';

import { AppMessages } from '../../languages';
import { matchesSearchValue } from '../../utils/strings';
import { UserModel } from '../../hooks/api';
import { useAuth } from '../../hooks/auth';
import { useMattermost, DEFAULT_CHANNEL_NAME } from '../../hooks/mattermost';
import { useModels } from '../../hooks/model';
import { ModalContainer } from '../ModalContainer';
import { IconButton } from '../IconButton';
import { CloseBoxIcon, SpinnerIcon, AdminIcon } from '../Icons';
import { InputSearch } from '../InputSearch';

import {
  Container,
  MemberContainer,
  Button,
  Label,
  LoadingContainer,
} from './styles';

export const ChatChannelMembers: React.FC = () => {
  const isAdmin = useAuth((state) => state.isAdmin);
  const { formatMessage } = useIntl();
  const users = useModels((state) => state.users);
  const mattermostUserId = useMattermost(
    (state) => state.mattermostUser?.id || ''
  );
  const mattermostUsers = useMattermost((state) => state.mattermostUsers);

  const teamChannels = useMattermost((state) => state.teamChannels);
  const channelMembersIsOpen = useMattermost(
    (state) => state.channelMembersIsOpen
  );
  const channelMembersId = useMattermost((state) => state.channelMembersId);
  const closeChannelMembers = useMattermost(
    (state) => state.closeChannelMembers
  );
  const getChannelMembers = useMattermost((state) => state.getChannelMembers);
  const addToChannel = useMattermost((state) => state.addToChannel);
  const removeFromChannel = useMattermost((state) => state.removeFromChannel);
  const closeChannel = useMattermost((state) => state.closeChannel);
  const getChannelDisplayName = useMattermost(
    (state) => state.getChannelDisplayName
  );

  const [filteredUsers, setFilteredUsers] = useState([...users]);
  const [members, setMembers] = useState<UserModel[]>([]);
  const [notMembers, setNotMembers] = useState<UserModel[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [memberships, setMemberships] = useState<ChannelMembership[]>([]);

  const channel = teamChannels[channelMembersId];
  const channelDisplayName = channel ? getChannelDisplayName(channel) : '';
  const isDefaultChannel = channel?.name === DEFAULT_CHANNEL_NAME;
  const isGroupCreator = channel && channel.creator_id === mattermostUserId;
  const canRemoveUsers = isAdmin() || isGroupCreator;

  const handleSearchValueChange = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  useEffect(() => {
    const filtered = users
      .filter(
        (t) =>
          !!t.lastLoginAt &&
          mattermostUsers[t.email] &&
          matchesSearchValue(`${t.firstName} ${t.lastName}`, searchValue)
      )
      .sort((a, b) => {
        return `${a.firstName} ${a.lastName}`
          .trim()
          .localeCompare(`${b.firstName} ${b.lastName}`.trim());
      });

    setFilteredUsers(filtered);
  }, [users, searchValue, mattermostUsers]);

  useEffect(() => {
    if (!channelMembersIsOpen) {
      setMemberships([]);
      return;
    }
    setLoading(true);
    getChannelMembers(channelMembersId)
      .then(setMemberships)
      .finally(() => setLoading(false));
  }, [channelMembersIsOpen, channelMembersId, getChannelMembers]);

  useEffect(() => {
    if (!channelMembersIsOpen) {
      setMembers([]);
      setNotMembers([]);
      return;
    }
    const emails = memberships.map((t) => mattermostUsers[t.user_id]?.email);
    const channelUsers = filteredUsers.filter((t) => emails.includes(t.email));
    const otherUsers = filteredUsers.filter(
      (t) => !t.accessRevokedAt && !emails.includes(t.email)
    );
    setMembers(channelUsers);
    setNotMembers(otherUsers);
  }, [channelMembersIsOpen, memberships, mattermostUsers, filteredUsers]);

  useEffect(() => {
    if (!channelMembersIsOpen) setSearchValue('');
  }, [channelMembersIsOpen]);

  return (
    <ModalContainer
      isOpen={channelMembersIsOpen}
      onClickOutside={closeChannelMembers}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <div className="header">
          <span>
            {channelDisplayName}
            {' | '}
            {formatMessage({ id: AppMessages.chatChannelMembers })}
          </span>
          <IconButton iconSize={16} onClick={closeChannelMembers}>
            <CloseBoxIcon />
          </IconButton>
        </div>

        <div className="search-container">
          <InputSearch onChange={handleSearchValueChange} />
        </div>

        <div className="members">
          {loading && (
            <MemberContainer>
              <LoadingContainer>
                {formatMessage({ id: AppMessages.chatLoading })}
                <br />
                <SpinnerIcon />
              </LoadingContainer>
            </MemberContainer>
          )}

          {!loading &&
            members.map((user) => (
              <MemberContainer key={user.id}>
                <hr />

                <span>
                  <img
                    src={user.picture}
                    alt={`${user.firstName} ${user.lastName}`.trim()}
                  />
                  {`${user.firstName} ${user.lastName}`.trim()}
                </span>

                <Label>
                  {!isDefaultChannel &&
                    (user.roles?.includes('admin') ||
                      mattermostUsers[user.email].id ===
                        channel.creator_id) && <AdminIcon />}
                  {formatMessage({ id: AppMessages.chatChannelMember })}
                </Label>

                {!isDefaultChannel &&
                  mattermostUserId === mattermostUsers[user.email]?.id && (
                    <Button
                      color="#b80002"
                      onClick={() => {
                        // eslint-disable-next-line no-alert
                        const confirmed = confirm(
                          formatMessage({
                            id: AppMessages.chatLeaveChannelConfirm,
                          })
                        );
                        if (!confirmed) return;
                        closeChannel(channel);
                        closeChannelMembers();
                        removeFromChannel(mattermostUserId, channelMembersId);
                      }}
                    >
                      {formatMessage({ id: AppMessages.chatLeaveChannel })}
                    </Button>
                  )}

                {!isDefaultChannel &&
                  mattermostUserId !== mattermostUsers[user.email]?.id &&
                  canRemoveUsers && (
                    <Button
                      color="#b80002"
                      onClick={() => {
                        // eslint-disable-next-line no-alert
                        const confirmed = confirm(
                          formatMessage(
                            {
                              id: AppMessages.chatRemoveFromChannelConfirm,
                            },
                            {
                              name: `${user.firstName} ${user.lastName}`.trim(),
                            }
                          )
                        );
                        if (!confirmed) return;
                        removeFromChannel(
                          mattermostUsers[user.email].id,
                          channelMembersId
                        ).then((removed) => {
                          if (!removed) return;
                          getChannelMembers(channelMembersId).then(
                            setMemberships
                          );
                        });
                      }}
                    >
                      {formatMessage({ id: AppMessages.chatRemoveFromChannel })}
                    </Button>
                  )}
              </MemberContainer>
            ))}

          {!loading &&
            notMembers.map((user) => (
              <MemberContainer key={user.id}>
                <hr />
                <span>
                  <img
                    src={user.picture}
                    alt={`${user.firstName} ${user.lastName}`.trim()}
                  />
                  {`${user.firstName} ${user.lastName}`.trim()}
                </span>
                <Button
                  onClick={() => {
                    addToChannel(
                      mattermostUsers[user.email].id,
                      channelMembersId
                    ).then((joined) => {
                      if (!joined) return;
                      getChannelMembers(channelMembersId).then(setMemberships);
                    });
                  }}
                >
                  {formatMessage({ id: AppMessages.chatAddToChannel })}
                </Button>
              </MemberContainer>
            ))}
        </div>
      </Container>
    </ModalContainer>
  );
};
