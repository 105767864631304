/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { getEmojiDataFromNative, EmojiData } from 'emoji-mart';
import emojisData from 'emoji-mart/data/apple.json';
import emojiRegex from 'emoji-regex';
import { v4 as uuid } from 'uuid';
import { Channel } from 'mattermost-redux/types/channels';

import { CustomStyledInput, OutsideArea, Button } from './styles';
import { OfficePostEmojiPicker } from './OfficePostEmojiPicker';
import { OfficePostImage } from './OfficePostImage';
import { AppMessages } from '../../languages';
import { useMattermost } from '../../hooks/mattermost';
import { getFormattedUserName } from '../../hooks/office';
import PasteImage from '../../utils/PasteImage.js';
import { AttachFileIcon, CancelIcon, EmojiIcon, SpinnerIcon } from '../Icons';
import { IconButton } from '../IconButton';
import { StyledInput } from '../StyledInput';

const NATIVE_EMOJI_PATTERN = emojiRegex();

interface FileList {
  [fileName: string]: {
    id: string;
    loading: boolean;
    src: string;
    hasPreviewImage: boolean;
    mimeType: string;
  };
}

interface Props {
  channel: Channel;
}

export const OfficePostInput: React.FC<Props> = ({ channel }) => {
  const mattermostUserId = useMattermost(
    (state) => state.mattermostUser?.id || ''
  );

  const getProseiaUser = useMattermost((state) => state.getProseiaUser);
  const uploadFileToChannel = useMattermost(
    (state) => state.uploadFileToChannel
  );
  const getFile = useMattermost((state) => state.getFile);
  const getFileUrl = useMattermost((state) => state.getFileUrl);
  const getFilePreviewUrl = useMattermost((state) => state.getFilePreviewUrl);
  const sendMessage = useMattermost((state) => state.sendMessage);

  const { formatMessage } = useIntl();
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>();
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [pasteImage, setPasteImage] = useState<PasteImage | null>(null);
  const [value, setValue] = useState('');
  const [files, setFiles] = useState<FileList>({});
  const [sending, setSending] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

  const channelId = channel?.id || '';
  const user = getProseiaUser(mattermostUserId);
  const { firstName, lastName } = user;
  const displayName = useMemo(
    () => getFormattedUserName({ firstName, lastName }),
    [firstName, lastName]
  );
  const placeholder = formatMessage({
    id: AppMessages.officePostInputPlaceholder,
  });

  const loadingFiles = Object.keys(files)
    .map((fileName) => {
      return { ...files[fileName], fileName };
    })
    .filter((t) => t.loading);

  const loadedFiles = Object.keys(files)
    .map((fileName) => {
      return { ...files[fileName], fileName };
    })
    .filter((t) => !t.loading);

  const getImage = useCallback(() => {
    return <img alt={displayName} src={user.picture} width="40" height="40" />;
  }, [user, displayName]);

  const uploadFile = useCallback(
    (fileName: string, data: string | File | Blob) => {
      setFiles((state) => ({
        ...state,
        [fileName]: {
          loading: true,
          id: uuid(),
          src: '',
          hasPreviewImage: false,
          mimeType: '',
        },
      }));

      setUploading(true);

      uploadFileToChannel(channelId, fileName, data).then((res) => {
        const [fileInfo] = res.file_infos;
        const isGif = fileInfo.mime_type === 'image/gif';

        const setFile = (src: string) => {
          setFiles((state) => ({
            ...state,
            [fileName]: {
              loading: false,
              id: fileInfo.id,
              src,
              hasPreviewImage: fileInfo.has_preview_image,
              mimeType: fileInfo.mime_type,
            },
          }));
          setUploading(false);
        };

        if (!fileInfo.has_preview_image && !isGif) {
          setFile('');
          return;
        }

        if (fileInfo.has_preview_image) {
          getFile(getFilePreviewUrl(fileInfo.id)).then(setFile);
          return;
        }

        getFile(getFileUrl(fileInfo.id)).then(setFile);
      }, console.error);
    },
    [channelId, uploadFileToChannel, getFilePreviewUrl, getFileUrl, getFile]
  );

  const handlePastImage = useCallback(
    (data: string | File | Blob) => {
      const fileName = `pasted_at_${new Date().toISOString()}.png`;
      uploadFile(fileName, data);
    },
    [uploadFile]
  );

  useEffect(() => {
    if (pasteImage) return;
    const ref = new PasteImage(inputRef.current);
    ref.on('paste-image-data', (data: any) => {
      handlePastImage(data);
    });
    setPasteImage(ref);
  }, [pasteImage, handlePastImage]);

  const handleDeleteFile = useCallback((fileName: string) => {
    setFiles((state) => {
      const newState = { ...state };
      delete newState[fileName];
      return newState;
    });
  }, []);

  const addValue = useCallback((valueToAdd: string) => {
    if (!inputRef.current) return;
    const field = inputRef.current;
    const startPos = field.selectionStart;
    const endPos = field.selectionEnd;

    setValue(
      (state) =>
        `${state.substring(0, startPos)}${valueToAdd}${state.substring(
          endPos,
          state.length
        )}`
    );

    setTimeout(() => {
      if (!inputRef.current) return;
      inputRef.current.selectionStart = startPos + valueToAdd.length;
      inputRef.current.selectionEnd = startPos + valueToAdd.length;
      inputRef.current.focus();
    }, 15);
  }, []);

  const reset = useCallback(() => {
    setValue('');
    setFiles({});
    setSending(false);
    setUploading(false);
  }, []);

  const handleSend = useCallback(() => {
    if (sending || uploading) return;

    const fileNames = Object.keys(files).filter(
      (fileName) => !files[fileName].loading
    );
    const fileIds = fileNames.map((fileName) => files[fileName].id);

    if (fileIds.length === 0 && !value) return;

    setSending(true);
    const stringValue = value.replace(NATIVE_EMOJI_PATTERN, (match) => {
      return getEmojiDataFromNative(match, 'apple', emojisData as any).colons;
    });

    sendMessage(channel, stringValue, fileIds)
      .then(() => {
        setEditing(false);
      }, console.error)
      .finally(() => {
        setSending(false);
      });
  }, [
    sending,
    uploading,
    value,
    files,
    channel,
    setSending,
    setEditing,
    sendMessage,
  ]);

  const handleSelectFile = useCallback(() => {
    if (!inputFileRef.current) return;
    inputFileRef.current.click();
  }, []);

  const handleAttachFile = useCallback(() => {
    if (!inputFileRef.current) return;

    const fileList = inputFileRef.current.files;

    if (fileList && fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        if (file) uploadFile(file.name, file);
      }
    }
  }, [uploadFile]);

  const handleAddEmoji = useCallback(
    (emoji: EmojiData) => {
      const { native } = emoji as any;
      addValue(native || emoji.colons);
    },
    [addValue]
  );

  const handleClose = useCallback(() => {
    setEditing(false);
  }, []);

  useEffect(() => {
    if (!channelId) return;
    setEditing(false);
    reset();
  }, [channelId, reset]);

  useEffect(() => {
    if (editing) return;
    reset();
  }, [editing, reset]);

  useEffect(() => {
    if (!editing || !inputRef.current) return;
    inputRef.current.focus();
  }, [editing]);

  return (
    <>
      {editing && (
        <OutsideArea
          onClick={handleClose}
          onWheel={(e) => {
            const container = document.getElementById(channelId);
            if (!container) return;
            container.scrollTo({ top: container.scrollTop + e.deltaY });
          }}
        />
      )}
      <div id="office-post-input" className="post" style={{ zIndex: 1 }}>
        <div className="post-main">
          {editing && (
            <div className="title-bar">
              <h3>{formatMessage({ id: AppMessages.officePostInputTitle })}</h3>
              <IconButton className="close-button" onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            </div>
          )}
          <div className="post-header">
            <div className="post-image">{getImage()}</div>
            {editing && (
              <>
                <div className="post-text">
                  <div className="post-display-name">{displayName}</div>
                  <div className="post-time">{user.roleDescription}</div>
                </div>
              </>
            )}
            {!editing && (
              <StyledInput
                placeholder={placeholder}
                style={{
                  width: '100%',
                }}
                onClick={() => setEditing(true)}
              />
            )}
          </div>

          <div className="input-content">
            <input
              ref={inputFileRef}
              type="file"
              multiple
              style={{ display: 'none' }}
              onChange={handleAttachFile}
            />
            <CustomStyledInput
              inputRef={inputRef}
              placeholder={placeholder}
              multiline
              rows={!loadedFiles.length ? 5 : 2}
              rowsMax={99}
              style={{
                width: '100%',
                display: editing ? undefined : 'none',
              }}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              disabled={sending}
            />

            {loadingFiles.map((t) => {
              return (
                <div key={`loading-${t.id}`}>
                  <SpinnerIcon />
                </div>
              );
            })}
            <div className="file-list">
              {loadedFiles.map((t) => {
                const isGif = t.mimeType === 'image/gif';
                return (
                  <div
                    key={`loaded-${t.id}`}
                    id={`loaded-${t.id}`}
                    className="loaded-file"
                  >
                    {!t.hasPreviewImage && !isGif && t.fileName}
                    {(t.hasPreviewImage || isGif) && (
                      <OfficePostImage
                        src={t.src}
                        onResize={() => {
                          const element = document.getElementById(
                            `loaded-${t.id}`
                          );
                          if (!element) return;
                          element.scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                          });
                        }}
                      />
                    )}
                    <div className="delete">
                      <IconButton
                        iconSize={14}
                        padding={2}
                        buttonColor="red"
                        iconColor="white"
                        pressed
                        onClick={() => handleDeleteFile(t.fileName)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {editing && (
            <>
              <div className="input-actions add-to-post">
                <div className="add-to-post-label">
                  {formatMessage({
                    id: AppMessages.officePostInputAddToPost,
                  })}
                </div>
                <IconButton iconSize={20} onClick={handleSelectFile}>
                  <AttachFileIcon />
                </IconButton>
                <IconButton
                  iconSize={20}
                  onClick={() => setEmojiPickerOpen(true)}
                >
                  <EmojiIcon />
                </IconButton>
              </div>
              <div className="input-actions">
                <Button
                  className="publish"
                  disabled={
                    !(value || loadedFiles.length) || sending || uploading
                  }
                  onClick={handleSend}
                >
                  {sending ? (
                    <SpinnerIcon />
                  ) : (
                    formatMessage({ id: AppMessages.officePostPublish })
                  )}
                </Button>
              </div>
            </>
          )}
        </div>
        <OfficePostEmojiPicker
          open={emojiPickerOpen}
          channelId={channelId}
          onClose={() => setEmojiPickerOpen(false)}
          onSelect={handleAddEmoji}
          type="post-creation"
        />
      </div>
    </>
  );
};
