import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import { useReactToPrint } from 'react-to-print';

import 'react-pivottable/pivottable.css';

import { Container, LoadingContainer } from './styles';
import {
  PivotTableProps,
  defaultPivotTableModels,
} from './default-pivot-table-models';
import { EPeriod, getPeriod } from '../../utils/periods';
import { useReports } from '../../hooks/reports';
import { useLanguage } from '../../hooks/language';
import { AppMessages } from '../../languages';
import { AvatarStatus } from '../Avatar/styles';
import { ModalContainer } from '../ModalContainer';
import { IconButton } from '../IconButton';
import { CloseBoxIcon, SpinnerIcon, PrintIcon } from '../Icons';
import { StyledMultiSelect, MultiSelectOption } from '../StyledMultiSelect';
import { StyledSelect } from '../StyledSelect';
import { StyledMenuItem } from '../StyledMenuItem/styles';

const statusMessages = {
  available: AppMessages.userInfoStatusAvailable,
  away: AppMessages.userInfoStatusAway,
  busy: AppMessages.userInfoStatusBusy,
  inactive: AppMessages.userInfoStatusInactive,
};

export const ReportsContainer: React.FC = () => {
  const { formatMessage } = useIntl();
  const messages = useLanguage((state) => state.messages);

  const activityLogs = useReports((state) => state.activityLogs);
  const reportsPanelIsOpen = useReports((state) => state.reportsPanelIsOpen);
  const closeReportsPanel = useReports((state) => state.closeReportsPanel);
  const loadActivityLogs = useReports((state) => state.loadActivityLogs);

  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState(EPeriod.thisWeek);
  const [data, setData] = useState<any[]>([]);

  const [periodUsers, setPeriodUsers] = useState<MultiSelectOption[]>([]);
  const [periodDates, setPeriodDates] = useState<MultiSelectOption[]>([]);
  const [periodStatus, setPeriodStatus] = useState<MultiSelectOption[]>([]);
  const [periodRooms, setPeriodRooms] = useState<MultiSelectOption[]>([]);
  const [periodInMeeting, setPeriodInMeeting] = useState<MultiSelectOption[]>(
    []
  );
  const [filteredUsers, setFilteredUsers] = useState<MultiSelectOption[]>([]);
  const [filteredDates, setFilteredDates] = useState<MultiSelectOption[]>([]);
  const [filteredStatus, setFilteredStatus] = useState<MultiSelectOption[]>([]);
  const [filteredRooms, setFilteredRooms] = useState<MultiSelectOption[]>([]);
  const [filteredInMeeting, setFilteredInMeeting] = useState<
    MultiSelectOption[]
  >([]);

  const [hiddenAttributes] = useState([
    'id',
    'user',
    'remote',
    'message',
    'time',
    'group',
    'mic',
    'video',
    'screenShared',
    'office',
    'officeName',
    'room',
    'createdAt',
  ]);

  const [hiddenFromDragDrop] = useState([
    'timeInMilliseconds',
    'timeInSeconds',
    'timeInMinutes',
    'timeInHours',
  ]);

  const [pivotTableModel, setPivotTableModel] = useState(
    AppMessages.reportsModelStatusPerUser as string
  );
  const [pivotTableProps, setPivotTableProps] = useState<PivotTableProps>();

  const printContainerRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    documentTitle: formatMessage({ id: pivotTableModel }),
    content: () => printContainerRef.current,
  });

  const handlePivotTableChange = useCallback(
    ({
      aggregatorName,
      colOrder,
      cols,
      rendererName,
      rowOrder,
      rows,
      vals,
      valueFilter,
    }: PivotTableProps) => {
      setPivotTableProps({
        aggregatorName,
        colOrder,
        cols,
        rendererName,
        rowOrder,
        rows,
        vals,
        valueFilter,
      });
    },
    []
  );

  const handleModelChanged = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const name = event.target.value as string;
      setPivotTableModel(name);
    },
    []
  );

  const handlePeriodChanged = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setPeriod(event.target.value as EPeriod);
    },
    []
  );

  const formatReportField = useCallback(
    (fieldName: string) => {
      const key = `reports.field.${fieldName}`;
      if (!messages[key]) return fieldName;
      return formatMessage({ id: key });
    },
    [messages, formatMessage]
  );

  const formatBooleanString = useCallback(
    (value: string) => {
      const key = `booleanString.${value}`;
      if (!messages[key]) return value;
      return formatMessage({ id: key });
    },
    [messages, formatMessage]
  );

  const formatAvatarStatus = useCallback(
    (value: string) => {
      const key = statusMessages[value as AvatarStatus];
      if (!messages[key]) return value;
      return formatMessage({ id: key });
    },
    [messages, formatMessage]
  );

  const translateFields = useCallback(
    (object: any): any => {
      if (!object) return object;
      return Object.keys(object).reduce((res, key) => {
        const translatedKey = formatReportField(key);
        let value = object[key];
        const statusKeys = Object.keys(statusMessages);

        if (key === 'status' && statusKeys.includes(value)) {
          value = formatAvatarStatus(value);
        } else if (value === 'yes' || value === 'no') {
          value = formatBooleanString(value);
        } else if (key === 'status') {
          Object.keys(object[key] || {}).forEach((subKey) => {
            if (!statusKeys.includes(subKey)) return;
            const subValue = value[subKey];
            const tSubKey = formatAvatarStatus(subKey);
            value = {
              ...value,
              [tSubKey]: subValue,
              [subKey]: undefined,
            };
          });
        } else if (value?.yes !== undefined || value?.no !== undefined) {
          Object.keys(object[key] || {}).forEach((subKey) => {
            if (subKey !== 'yes' && subKey !== 'no') return;
            const subValue = value[subKey];
            const tSubKey = formatBooleanString(subKey);
            value = {
              ...value,
              [tSubKey]: subValue,
              [subKey]: undefined,
            };
          });
        }

        return { ...res, [translatedKey]: value };
      }, {});
    },
    [formatReportField, formatAvatarStatus, formatBooleanString]
  );

  useEffect(() => {
    if (!pivotTableModel) return;
    const model = defaultPivotTableModels[pivotTableModel];

    setPivotTableProps({
      ...model,
      rows: model.rows?.map(formatReportField),
      cols: model.cols?.map(formatReportField),
      vals: model.vals?.map(formatReportField),
      valueFilter: translateFields(model.valueFilter),
    });
  }, [pivotTableModel, formatReportField, translateFields]);

  useEffect(() => {
    if (!reportsPanelIsOpen || !period) return;
    const filterPeriod = getPeriod(period);
    setLoading(true);
    loadActivityLogs({
      status: ['available', 'busy', 'away', 'inactive'],
      createdAtFrom: filterPeriod?.from,
      createdAtTo: filterPeriod?.to,
    }).finally(() => setLoading(false));
  }, [reportsPanelIsOpen, period, loadActivityLogs]);

  useEffect(() => {
    const translatedData = activityLogs
      .filter((log) => {
        if (!filteredUsers.some((item) => item.value === log.userFullName))
          return false;
        if (!filteredDates.some((item) => item.value === log.logDate))
          return false;
        if (!filteredStatus.some((item) => item.value === log.status))
          return false;
        if (!filteredRooms.some((item) => item.value === log.roomName))
          return false;
        if (!filteredInMeeting.some((item) => item.value === log.inMeeting))
          return false;
        return true;
      })
      .map(translateFields);
    setData(translatedData);
  }, [
    activityLogs,
    filteredUsers,
    filteredDates,
    filteredStatus,
    filteredRooms,
    filteredInMeeting,
    translateFields,
  ]);

  useEffect(() => {
    const periodOptions = activityLogs.reduce(
      (res, item) => {
        return {
          ...res,
          periodUsers: { ...res.periodUsers, [item.userFullName]: true },
          periodDates: { ...res.periodDates, [item.logDate]: true },
          periodStatus: { ...res.periodStatus, [item.status]: true },
          periodRooms: { ...res.periodRooms, [item.roomName]: true },
          periodInMeeting: { ...res.periodInMeeting, [item.inMeeting]: true },
        };
      },
      {
        periodUsers: {},
        periodDates: {},
        periodStatus: {},
        periodRooms: {},
        periodInMeeting: {},
      }
    );
    setPeriodUsers(
      Object.keys(periodOptions.periodUsers).map((key) => ({
        label: key,
        value: key,
      }))
    );
    setPeriodDates(
      Object.keys(periodOptions.periodDates).map((key) => ({
        label: key,
        value: key,
      }))
    );
    setPeriodStatus(
      Object.keys(periodOptions.periodStatus).map((key) => ({
        label: formatAvatarStatus(key),
        value: key,
      }))
    );
    setPeriodRooms(
      Object.keys(periodOptions.periodRooms).map((key) => ({
        label: key,
        value: key,
      }))
    );
    setPeriodInMeeting(
      Object.keys(periodOptions.periodInMeeting).map((key) => ({
        label: formatBooleanString(key),
        value: key,
      }))
    );
  }, [activityLogs, formatAvatarStatus, formatBooleanString]);

  return (
    <ModalContainer
      isOpen={reportsPanelIsOpen}
      onClickOutside={closeReportsPanel}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <div className="header">
          <span className="display-name">
            {formatMessage({ id: AppMessages.reportsTitle })}
          </span>
          <IconButton iconSize={16} onClick={closeReportsPanel}>
            <CloseBoxIcon />
          </IconButton>
        </div>
        <div className="content">
          {`${formatMessage({ id: AppMessages.periodsField })}:`}
          <StyledSelect
            labelId="report-period-selector-label"
            id="report-period-selector"
            value={period}
            onChange={handlePeriodChanged}
            style={{ minWidth: '300px', paddingLeft: '10px' }}
          >
            {Object.keys(EPeriod).reduce<any[]>((result, eKey) => {
              const item = EPeriod[eKey as any];
              if (typeof item !== 'number') return result;

              return [
                ...result,
                <StyledMenuItem
                  key={item}
                  disableGutters
                  value={item as EPeriod}
                  style={{ padding: '5px' }}
                >
                  {formatMessage({ id: `periods.${EPeriod[item]}` }) ||
                    'Select'}
                </StyledMenuItem>,
              ];
            }, [])}
          </StyledSelect>
          <br />
          <br />
          {`${formatMessage({ id: AppMessages.reportsModelField })}:`}
          <StyledSelect
            labelId="report-model-selector-label"
            id="report-model-selector"
            value={pivotTableModel}
            onChange={handleModelChanged}
            style={{ minWidth: '300px', paddingLeft: '10px' }}
          >
            {Object.keys(defaultPivotTableModels).map((name) => (
              <StyledMenuItem
                key={name}
                disableGutters
                value={name}
                style={{ padding: '5px' }}
              >
                {formatMessage({ id: name })}
              </StyledMenuItem>
            ))}
          </StyledSelect>
          <br />
          <br />
          <div className="filters-container">
            <StyledMultiSelect
              placeholder={formatReportField('userFullName')}
              options={periodUsers}
              selectedOptions={filteredUsers}
              setSelectedOptions={setFilteredUsers}
            />
            <StyledMultiSelect
              placeholder={formatReportField('logDate')}
              options={periodDates}
              selectedOptions={filteredDates}
              setSelectedOptions={setFilteredDates}
            />
            <StyledMultiSelect
              placeholder={formatReportField('status')}
              options={periodStatus}
              selectedOptions={filteredStatus}
              setSelectedOptions={setFilteredStatus}
            />
            <StyledMultiSelect
              placeholder={formatReportField('roomName')}
              options={periodRooms}
              selectedOptions={filteredRooms}
              setSelectedOptions={setFilteredRooms}
            />
            <StyledMultiSelect
              placeholder={formatReportField('inMeeting')}
              options={periodInMeeting}
              selectedOptions={filteredInMeeting}
              setSelectedOptions={setFilteredInMeeting}
            />
            <IconButton onClick={handlePrint}>
              <PrintIcon />
            </IconButton>
          </div>
          <br />
          {loading && (
            <LoadingContainer>
              <SpinnerIcon />
            </LoadingContainer>
          )}
          {!data.length && !loading && (
            <div className="no-data-label">
              {formatMessage({ id: AppMessages.reportsNoData })}
            </div>
          )}
          {!!data.length && !loading && (
            <div ref={printContainerRef} style={{ width: '100%' }}>
              <PivotTableUI
                data={data}
                unusedOrientationCutoff={Infinity}
                hiddenAttributes={hiddenAttributes}
                hiddenFromDragDrop={hiddenFromDragDrop}
                onChange={handlePivotTableChange}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...pivotTableProps}
              />
            </div>
          )}
        </div>
      </Container>
    </ModalContainer>
  );
};
