/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

import OfficeProvider from '../hooks/office';
import { GuesttProvider } from '../hooks/guest';
import { useAuth } from '../hooks/auth';

interface RouteProps<T = any> extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType<T>;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}: RouteProps) => {
  const isTokenValid = useAuth((state) => state.isTokenValid());

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location, ...rest }) => {
        if (location.pathname.startsWith('/meeting/')) {
          return (
            <GuesttProvider>
              <Component {...rest} location={location} />
            </GuesttProvider>
          );
        }

        if (location.pathname.startsWith('/privacy-policy')) {
          return <Component {...rest} location={location} />;
        }

        if (isPrivate && isTokenValid) {
          return (
            <OfficeProvider>
              <Component {...rest} location={location} />
            </OfficeProvider>
          );
        }

        return isPrivate === isTokenValid ? (
          <Component {...rest} location={location} />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/office',
              state: { location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
