import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  iconSize?: number;
  iconColor?: string;
  buttonColor?: string;
  padding?: number;
  disabled?: boolean;
  pressed?: boolean;
  pressedOutlined?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const IconButton: React.FC<Props> = ({
  children,
  iconSize = 30,
  iconColor,
  buttonColor,
  padding = 6,
  disabled = false,
  pressed = false,
  pressedOutlined = false,
  className,
  onClick,
  ...rest
}) => {
  return (
    <Container
      className={className}
      iconSize={iconSize}
      iconColor={iconColor}
      buttonColor={buttonColor}
      padding={padding}
      disabled={disabled}
      pressed={pressed}
      pressedOutlined={pressedOutlined}
      onClick={onClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </Container>
  );
};
