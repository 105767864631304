import { styled } from '../../themes';
import {
  MessageContainer,
  FONT_COLOR,
} from '../../components/ProseiaPageContainer/styles';

export const StepMessageContainer = styled(MessageContainer)`
  font-size: 14px;
`;

interface SettingUpItemContainerProps {
  active: boolean;
}

export const SettingUpItemContainer = styled.div<SettingUpItemContainerProps>`
  display: flex;
  align-items: center;
  justify-content: left;
  color: ${FONT_COLOR};
  font-size: 20px;
  opacity: ${(props) => (props.active ? 1 : 0.3)};

  & + & {
    margin-top: 15px;
  }

  > svg {
    margin-left: 10px;
    fill: ${FONT_COLOR};
    width: 24px;
    height: 24px;
  }

  .MuiCircularProgress-root {
    margin-left: 10px;
    width: 20px !important;
    height: 20px !important;
    > svg {
      fill: ${FONT_COLOR} !important;
    }
  }
`;
