/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { MdSearch } from 'react-icons/md';
import { FaSadTear } from 'react-icons/fa';
import { BiChevronsUp } from 'react-icons/bi';
import {
  Post,
  PostSearchResults,
  PostList,
} from 'mattermost-redux/types/posts';

import {
  Container,
  LoadingContainer,
  LoadMoreButton,
  LoadNewButton,
} from './styles';
import { OfficePost } from './OfficePost';
import { OfficePostInput } from './OfficePostInput';
import { AppMessages } from '../../languages';
import { useMattermost } from '../../hooks/mattermost';
import { useModels } from '../../hooks/model';
import { CloseBoxIcon, SpinnerIcon, PinIcon } from '../Icons';
import { ModalContainer } from '../ModalContainer';
import { IconButton } from '../IconButton';
import { Tooltip } from '../Tooltip';
import { InputSearch } from '../InputSearch';

export const OfficePosts: React.FC = () => {
  const { formatMessage } = useIntl();
  const offices = useModels((state) => state.offices);

  const officeChannels = useMattermost((state) => state.officeChannels);
  const officeChannelIsOpen = useMattermost(
    (state) => state.officeChannelIsOpen
  );
  const officeChannelId = useMattermost((state) => state.officeChannelId);
  const channelPosts = useMattermost((state) => state.channelPosts);
  const closeOfficeChannel = useMattermost((state) => state.closeOfficeChannel);
  const loadPostsUnread = useMattermost((state) => state.loadPostsUnread);
  const loadPostsBefore = useMattermost((state) => state.loadPostsBefore);
  const loadPostsAfter = useMattermost((state) => state.loadPostsAfter);
  const viewChannel = useMattermost((state) => state.viewChannel);
  const searchInChannel = useMattermost((state) => state.searchInChannel);
  const loadPostsAround = useMattermost((state) => state.loadPostsAround);
  const getPinnedPosts = useMattermost((state) => state.getPinnedPosts);

  const [loading, setLoading] = useState(false);
  const [pinnedBoxOpenned, setPinnedBoxOpenned] = useState(false);
  const [pinnedPostList, setPinnedPostList] = useState<PostList>();
  const [loadingPinned, setLoadingPinned] = useState(false);
  const [searchBoxOpenned, setSearchBoxOpenned] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchResuls, setSearchResuls] = useState<PostSearchResults>();
  const [searching, setSearching] = useState(false);
  const [scrollToPostId, setScrollToPostId] = useState('');

  const channel = officeChannels[officeChannelId];
  const channelId = channel ? channel.id : '';
  const postList = channelPosts[channelId];
  // eslint-disable-next-line camelcase
  const previousPostId = postList?.prev_post_id || '';
  const officeId = channel?.name?.split('-')[2];
  const roomId = channel?.name?.split('-')[3];
  const office = offices.find((t) => t.id === officeId);
  const room = office?.rooms?.find((t) => t.id === roomId);
  const channelDisplayName =
    room?.name || formatMessage({ id: AppMessages.officePostsGeneral });

  const postsById: { [postId: string]: Post } = (postList?.posts as any) || {};

  const posts = !postList
    ? []
    : postList.order.reduce<Post[]>((previous, id) => {
        const post = postsById[id];
        if (!post || post.type.startsWith('system_') || post.root_id)
          return previous;
        return [...previous, post] as Post[];
      }, []);

  const comments = !postList
    ? []
    : postList.order.reduce<Post[]>((previous, id) => {
        const post = postsById[id];
        if (!post || post.type.startsWith('system_') || !post.root_id)
          return previous;
        return [...previous, post] as Post[];
      }, []);

  const startLoading = useCallback(() => {
    setLoading(true);
  }, []);

  const endLoading = useCallback(() => {
    setLoading(false);
  }, []);

  const loadNew = useCallback(() => {
    if (!channelId) return;
    startLoading();
    loadPostsUnread(channelId).finally(() => {
      endLoading();
      const container = document.getElementById(channelId);
      if (container) container.scrollTop = 0;
    });
  }, [channelId, loadPostsUnread, startLoading, endLoading]);

  const handleLoadPrevious = useCallback(() => {
    if (!postList || !postList.prev_post_id) return;

    startLoading();
    loadPostsBefore(channelId).finally(endLoading);
  }, [postList, channelId, loadPostsBefore, startLoading, endLoading]);

  const handleLoadNext = useCallback(() => {
    if (!postList || !postList.next_post_id) return;

    const container = document.getElementById(channelId);
    const scrollDiff = container
      ? container.scrollHeight - container.scrollTop
      : 0;

    startLoading();
    loadPostsAfter(channelId)
      .then(() => {
        if (container)
          container.scrollTop = container.scrollHeight - scrollDiff;
      })
      .finally(endLoading);
  }, [postList, channelId, loadPostsAfter, startLoading, endLoading]);

  const handleScroll = useCallback(() => {
    if (loading || !officeChannelIsOpen) return;

    const container = document.getElementById(channelId);

    if (container && container.scrollTop === 0) {
      handleLoadNext();
    }
  }, [loading, officeChannelIsOpen, channelId, handleLoadNext]);

  const handleSearchValueChange = useCallback(
    (value: string) => {
      setSearchResuls(undefined);
      if (!value) {
        setSearching(false);
        return;
      }
      setSearching(true);
      searchInChannel(channel, value)
        .then(setSearchResuls)
        .finally(() => setSearching(false));
    },
    [channel, searchInChannel]
  );

  useEffect(() => {
    if (!searchBoxOpenned) return;
    if (searchInputRef.current) searchInputRef.current.focus();
    setSearchResuls(undefined);
  }, [searchBoxOpenned]);

  useEffect(() => {
    if (!pinnedBoxOpenned) return;
    setLoadingPinned(true);
    setPinnedPostList(undefined);
    getPinnedPosts(channelId)
      .then(setPinnedPostList)
      .finally(() => setLoadingPinned(false));
  }, [pinnedBoxOpenned, channelId, getPinnedPosts]);

  useEffect(() => {
    if (!scrollToPostId) return;
    setTimeout(() => {
      const element = document.getElementById(scrollToPostId);
      if (!element) return;
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
      element.classList.add('got-focus');
      setTimeout(() => {
        element.classList.remove('got-focus');
      }, 10000);
    }, 75);
    setScrollToPostId('');
  }, [scrollToPostId]);

  useEffect(() => {
    if (!postList) loadNew();
  }, [postList, loadNew]);

  useEffect(() => {
    if (previousPostId && posts.length === 0) handleLoadPrevious();
  }, [handleLoadPrevious, posts.length, previousPostId]);

  useEffect(() => {
    if (!channelId || !officeChannelIsOpen) return;
    viewChannel(channel, true);
  }, [channelId, channel, officeChannelIsOpen, viewChannel]);

  useEffect(() => {
    if (officeChannelIsOpen) return;
    setPinnedBoxOpenned(false);
    setSearchBoxOpenned(false);
  }, [officeChannelIsOpen]);

  return (
    <ModalContainer
      isOpen={officeChannelIsOpen}
      onClickOutside={closeOfficeChannel}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <div className="header">
          <span className="display-name">
            {`Posts | ${channelDisplayName}`}
          </span>
          {!pinnedBoxOpenned && (
            <IconButton
              iconSize={18}
              pressed={searchBoxOpenned}
              onClick={() => setSearchBoxOpenned(!searchBoxOpenned)}
            >
              <MdSearch />
            </IconButton>
          )}
          {!searchBoxOpenned && (
            <IconButton
              iconSize={18}
              pressed={pinnedBoxOpenned}
              onClick={() => setPinnedBoxOpenned(!pinnedBoxOpenned)}
            >
              <PinIcon />
            </IconButton>
          )}
          <IconButton iconSize={16} onClick={closeOfficeChannel}>
            <CloseBoxIcon />
          </IconButton>
        </div>
        <div id={channelId} className="postlist" onScroll={handleScroll}>
          <OfficePostInput channel={channel} />
          {posts.map((post) => {
            const commentsByPost = comments.filter(
              (comment) => comment.root_id === post.id
            );

            return (
              <OfficePost
                key={post.id}
                post={post}
                channel={channel}
                comments={commentsByPost}
              />
            );
          })}
          {postList && !posts.length && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h3>
                {formatMessage({ id: AppMessages.officePostsNothingPosted })}
              </h3>
              <FaSadTear size={22} />
            </div>
          )}
          {postList && postList.prev_post_id && (
            <LoadMoreButton onClick={handleLoadPrevious} disabled={loading}>
              {formatMessage({ id: AppMessages.chatLoadPrevious })}
            </LoadMoreButton>
          )}
        </div>
        {postList && postList.next_post_id && (
          <LoadNewButton onClick={loadNew}>
            <BiChevronsUp />
            <Tooltip
              position="left"
              text={formatMessage({ id: AppMessages.chatLoadNew })}
            />
          </LoadNewButton>
        )}
        {loading && (
          <LoadingContainer>
            {formatMessage({ id: AppMessages.chatLoading })}
            <br />
            <SpinnerIcon />
          </LoadingContainer>
        )}
        {pinnedBoxOpenned && (
          <div className="search-box">
            {pinnedPostList && (
              <div className="postlist pinned-posts">
                {pinnedPostList.order.map((postId) => {
                  const pinnedPosts = pinnedPostList.posts as any;
                  const post = pinnedPosts[postId];
                  return (
                    <OfficePost
                      key={postId}
                      post={post}
                      channel={channel}
                      onJumpClick={() => {
                        setPinnedBoxOpenned(false);
                        if (postList.order.includes(postId)) {
                          setScrollToPostId(postId);
                          return;
                        }
                        const container = document.getElementById(channelId);
                        if (container) container.scrollTop = 0;
                        startLoading();
                        loadPostsAround(channelId, post)
                          .then(() => {
                            setScrollToPostId(postId);
                          })
                          .finally(endLoading);
                      }}
                    />
                  );
                })}
              </div>
            )}
            {loadingPinned && (
              <LoadingContainer>
                <SpinnerIcon />
              </LoadingContainer>
            )}
          </div>
        )}
        {searchBoxOpenned && (
          <div className="search-box">
            {searchResuls && (
              <div className="postlist search-results">
                {searchResuls.order.map((postId) => {
                  const searchResultPosts = searchResuls.posts as any;
                  const post = searchResultPosts[postId];
                  if (post.root_id) return undefined;
                  return (
                    <OfficePost
                      key={postId}
                      post={post}
                      channel={channel}
                      highlightTerm={searchInputRef.current?.value}
                      onJumpClick={() => {
                        setSearchBoxOpenned(false);
                        if (postList.order.includes(postId)) {
                          setScrollToPostId(postId);
                          return;
                        }
                        const container = document.getElementById(channelId);
                        if (container) container.scrollTop = 0;
                        startLoading();
                        loadPostsAround(channelId, post)
                          .then(() => {
                            setScrollToPostId(postId);
                          })
                          .finally(endLoading);
                      }}
                    />
                  );
                })}
              </div>
            )}
            <InputSearch
              ref={searchInputRef}
              onChange={handleSearchValueChange}
              onClear={() => setSearchBoxOpenned(false)}
            />
            {searching && (
              <LoadingContainer>
                <SpinnerIcon />
              </LoadingContainer>
            )}
          </div>
        )}
      </Container>
    </ModalContainer>
  );
};
