import React from 'react';
import { useIntl } from 'react-intl';

import { useTutorial } from '../../../hooks/tutorial';
import { AppMessages } from '../../../languages';

import { ContentContainer, ContentLine, ContentButton } from '../styles';

export const AddUsersTipContent: React.FC = () => {
  const closeAddUsersTip = useTutorial((state) => state.closeAddUsersTip);
  const { formatMessage } = useIntl();

  return (
    <ContentContainer onClick={(e) => e.stopPropagation()}>
      <ContentLine>
        {formatMessage({ id: AppMessages.tutorialAddUsersDescription1 })}
      </ContentLine>

      <ContentButton onClick={closeAddUsersTip}>
        {formatMessage({ id: AppMessages.tutorialGotIt })}
      </ContentButton>
    </ContentContainer>
  );
};
