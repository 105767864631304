import { animated } from 'react-spring';
import { keyframes } from 'styled-components';
import { styled, css } from '../../../themes';

export const Container = styled(animated.div)`
  width: 360px;
  height: 95px;
  pointer-events: all;
  position: relative;
  background: ${(props) => props.theme.boxColor};
  box-shadow: ${(props) => props.theme.avatarGlow};
  color: ${(props) => props.theme.textColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;

  & + div {
    margin-top: 8px;
  }

  ${(props) => css`
    animation: ${keyframes`
      0% {
        box-shadow: ${props.theme.avatarGlow};
      }
      50% {
        box-shadow: ${props.theme.focusGlow};
      }
      100% {
        box-shadow: ${props.theme.avatarGlow};
      }
    `} 1.5s ease-out infinite;
  `}
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  height: 100%;

  > .location {
    display: flex;
    align-items: center;
  }

  > .info-message {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  > svg {
    font-size: 40px;
    cursor: pointer;
    transition: all 0.2s ease-out;

    :hover {
      transform: scale(1.2);
    }

    + svg {
      margin-top: 5px;
      font-size: 30px;
    }
  }
`;
