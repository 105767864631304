import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import CreateEnvironment from '../pages/CreateEnvironment';
import SigningUpEnvironment from '../pages/CreateEnvironment/SigningUp';
import SettingUpEnvironment from '../pages/CreateEnvironment/SettingUp';
import StartEnvironment from '../pages/CreateEnvironment/Start';
import SignIn from '../pages/SignIn';
import Office from '../pages/Office';
import Meeting from '../pages/Meeting';
import PrivacyPolicy from '../pages/PrivacyPolicy';

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={SignIn} />
    <Route exact path="/signin/:token" component={SignIn} />
    <Route exact path="/invite/:encodedEmail" component={SignIn} />
    <Route exact path="/try-it" component={CreateEnvironment} />
    <Route
      exact
      path="/signing-up/:encodedData"
      component={SigningUpEnvironment}
    />
    <Route
      exact
      path="/setting-up/:encodedData"
      component={SettingUpEnvironment}
    />
    <Route exact path="/start/:encodedData" component={StartEnvironment} />
    <Route isPrivate exact path="/office" component={Office} />
    <Route exact path="/meeting/:token" component={Meeting} />
    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
  </Switch>
);

export default Routes;
