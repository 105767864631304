/* eslint-disable no-restricted-globals */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Fade, Divider } from '@material-ui/core';

import { AppMessages } from '../../languages';
import { matchesSearchValue, splitName } from '../../utils/strings';
import { useGetter } from '../../hooks/getter';
import { useApi, UserModel } from '../../hooks/api';
import { useAuth } from '../../hooks/auth';
import { useOffice } from '../../hooks/office';
import { useModels } from '../../hooks/model';
import { usePlanLimits } from '../../hooks/plan-limits';
import { useToast } from '../../hooks/toast';
import { ModalContainer } from '../ModalContainer';
import { IconButton } from '../IconButton';
import {
  CloseBoxIcon,
  SpinnerIcon,
  SettingsIcon,
  AddUserIcon,
  EmailIcon,
  UserIcon,
  UserAdminIcon,
  DeleteIcon,
  LocationMobileIcon,
  ExtensionIcon,
  ExtensionOffIcon,
} from '../Icons';
import { InputSearch } from '../InputSearch';
import { SwitchButton } from '../SwitchButton';
import { Tooltip } from '../Tooltip';
import { EditableLabel } from '../EditableLabel';
import { Avatar } from '../Avatar';
import { ProfilePictureEditor } from '../ProfilePictureEditor';

import {
  Container,
  UserContainer,
  Button,
  Label,
  LoadingContainer,
  ContextMenu,
  ContextMenuItem,
  ConfigItemContainer,
  ConfigSwitch,
  ConfigContainer,
} from './styles';

interface Props {
  isOpen: boolean;
  onCloseRequest: () => void;
  onOpenUserInvitationsRequest: () => void;
}

export const UserManagement: React.FC<Props> = ({
  isOpen,
  onCloseRequest,
  onOpenUserInvitationsRequest,
}) => {
  const authUser = useAuth((state) => state.user);
  const {
    inviteUser,
    revokeUserAccess,
    grantUserAccess,
    revokeAdminPrivileges,
    grantAdminPrivileges,
    getUsesLocationValue,
    setUsesLocationValue,
    setUserLocationRequired,
    usersService,
    getAllowProfileEditingValue,
    setAllowProfileEditingValue,
    getUsesExtensionValue,
    setUsesExtensionValue,
    setUserExtensionRequired,
    uploadPublicFile,
  } = useApi();
  const getUsageLimits = usePlanLimits((state) => state.getUsageLimits);
  const addToast = useToast((state) => state.addToast);
  const { formatMessage } = useIntl();
  const users = useModels((state) => state.users);
  const usersLoaded = useModels((state) => state.usersLoaded);
  const updateUser = useOffice((state) => state.updateUser);
  const [filteredUsers, setFilteredUsers] = useState([...users]);
  const getFilteredUsers = useGetter(filteredUsers);
  const [order, setOrder] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(!usersLoaded);
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement>();
  const [contextUser, setContextUser] = useState<UserModel>(authUser);
  const [usesLocation, setUsesLocation] = useState(false);
  const [usesLocationLoaded, setUsesLocationLoaded] = useState(false);
  const [allowProfileEditing, setAllowProfileEditing] = useState(false);
  const [allowProfileEditingLoaded, setAllowProfileEditingLoaded] = useState(
    false
  );
  const [usesExtension, setUsesExtension] = useState(false);
  const [usesExtensionLoaded, setUsesExtensionLoaded] = useState(false);
  const [editingUserId, setEditingUserId] = useState('');
  const [editingPicture, setEditingPicture] = useState(false);
  const getEditingPictureUserId = useGetter(editingUserId);
  const [editingPictureData, setEditingPictureData] = useState('');

  const canAccess = filteredUsers.filter((user) => !user.accessRevokedAt);

  const handleSearchValueChange = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const getUserName = useCallback((user: UserModel) => {
    return `${(user.firstName || '').trim()} ${(
      user.lastName || ''
    ).trim()}`.trim();
  }, []);

  const isAdminUser = useCallback((user: UserModel) => {
    return !!user.roles?.includes('admin');
  }, []);

  const handleCloseContextMenu = useCallback((e: any) => {
    e.stopPropagation();
    setAnchorEl(undefined);
  }, []);

  const handleToggleUsesLocation = useCallback(() => {
    setUsesLocation(!usesLocation);
    setUsesLocationValue(!usesLocation).catch(() =>
      setUsesLocation(usesLocation)
    );
  }, [usesLocation, setUsesLocationValue]);

  const handleToggleAllowProfileEditing = useCallback(() => {
    setAllowProfileEditing(!allowProfileEditing);
    setAllowProfileEditingValue(!allowProfileEditing).catch(() =>
      setAllowProfileEditing(allowProfileEditing)
    );
  }, [allowProfileEditing, setAllowProfileEditingValue]);

  const handleToggleUsesExtension = useCallback(() => {
    setUsesExtension(!usesExtension);
    setUsesExtensionValue(!usesExtension).catch(() =>
      setUsesExtension(usesExtension)
    );
  }, [usesExtension, setUsesExtensionValue]);

  const handleEditPicture = useCallback((newImageData: string) => {
    setEditingPictureData(newImageData);
    setEditingPicture(true);
  }, []);

  const handleNewPicture = useCallback(
    async (croppedImage?: string) => {
      setEditingPicture(false);
      setEditingPictureData('');
      if (!croppedImage) return;
      const userId = getEditingPictureUserId();
      const blob = await fetch(croppedImage).then((t) => t.blob());
      uploadPublicFile(blob)
        .then((url) => {
          return updateUser(userId, { picture: url });
        })
        .catch(console.error);
    },
    [uploadPublicFile, updateUser, getEditingPictureUserId]
  );

  const updateUserName = useCallback(
    (userId: string, newName: string | undefined) => {
      if (!userId || !newName?.trim()) return;

      const splittedName = splitName(newName);

      updateUser(userId, splittedName);
    },
    [updateUser]
  );

  const updateUserRole = useCallback(
    (userId: string, newRoleDescription: string | undefined) => {
      if (!userId) return;
      updateUser(userId, { roleDescription: newRoleDescription });
    },
    [updateUser]
  );

  useEffect(() => {
    const filtered = users
      .filter(
        (t) =>
          matchesSearchValue(`${getUserName(t)} ${t.email}`, searchValue) &&
          !t.email.toLowerCase().includes('@proseia.app') &&
          !(
            t.email.toLowerCase().includes('@telluria.com.br') &&
            window.location.hostname !== 'telluria.proseia.app'
          )
      )
      .sort((a, b) => {
        return order.indexOf(a.id) - order.indexOf(b.id);
      });

    setFilteredUsers(filtered);
  }, [users, order, searchValue, getUserName]);

  useEffect(() => {
    if (!isOpen || !usersLoaded) return;

    const sorted = [...getFilteredUsers()];

    sorted.sort((a, b) => {
      const hasLoginA = !!a.lastLoginAt;
      const hasLoginB = !!b.lastLoginAt;

      const nameA = `${hasLoginA}${getUserName(a) || '.'}${a.email}`.trim();
      const nameB = `${hasLoginB}${getUserName(b) || '.'}${b.email}`.trim();

      return nameA.localeCompare(nameB);
    });

    setOrder(sorted.map((user) => user.id));
  }, [isOpen, usersLoaded, getUserName, getFilteredUsers]);

  useEffect(() => {
    if (!isOpen) setSearchValue('');
  }, [isOpen]);

  useEffect(() => {
    setLoading(!usersLoaded);
  }, [usersLoaded]);

  useEffect(() => {
    if (!isOpen) return;

    setUsesLocationLoaded(false);
    getUsesLocationValue()
      .then(setUsesLocation)
      .finally(() => setUsesLocationLoaded(true));
  }, [isOpen, getUsesLocationValue]);

  useEffect(() => {
    if (!isOpen) return;

    setAllowProfileEditingLoaded(false);
    getAllowProfileEditingValue()
      .then(setAllowProfileEditing)
      .finally(() => setAllowProfileEditingLoaded(true));
  }, [isOpen, getAllowProfileEditingValue]);

  useEffect(() => {
    if (!isOpen) return;

    setUsesExtensionLoaded(false);
    getUsesExtensionValue()
      .then(setUsesExtension)
      .finally(() => setUsesExtensionLoaded(true));
  }, [isOpen, getUsesExtensionValue]);

  const header = useMemo(
    () => (
      <div className="header">
        <span>
          {formatMessage({ id: AppMessages.componentUserManagementTitle })}
        </span>
        <IconButton iconSize={16} onClick={onCloseRequest}>
          <CloseBoxIcon />
        </IconButton>
      </div>
    ),
    [formatMessage, onCloseRequest]
  );

  const searchContainer = useMemo(
    () => (
      <div className="search-container">
        <InputSearch onChange={handleSearchValueChange} />
      </div>
    ),
    [handleSearchValueChange]
  );

  const usersCount = useMemo(
    () => (
      <div className="users-count">
        <div>
          {`${formatMessage({
            id: AppMessages.componentUserManagementUsersCount,
          })}: ${filteredUsers.length}`}
        </div>

        <div>
          {`${formatMessage({
            id: AppMessages.componentUserManagementCanAccess,
          })}: ${canAccess.length}`}
        </div>
      </div>
    ),
    [canAccess.length, filteredUsers.length, formatMessage]
  );

  const locationConfigContainer = useMemo(
    () =>
      usesLocationLoaded && (
        <ConfigItemContainer>
          {formatMessage({
            id: AppMessages.componentUserManagementUseLocation,
          })}

          <Tooltip
            position="right"
            text={formatMessage({
              id: AppMessages.componentUserManagementUseLocationDescription,
            })}
          />

          <ConfigSwitch>
            <SwitchButton
              isOn={usesLocation}
              handleToggle={handleToggleUsesLocation}
            />
          </ConfigSwitch>
        </ConfigItemContainer>
      ),
    [formatMessage, handleToggleUsesLocation, usesLocation, usesLocationLoaded]
  );

  const profileEditingConfigContainer = useMemo(
    () =>
      allowProfileEditingLoaded && (
        <ConfigItemContainer>
          {formatMessage({
            id: AppMessages.componentUserManagementAllowProfileEditing,
          })}

          <Tooltip
            position="right"
            text={formatMessage({
              id:
                AppMessages.componentUserManagementAllowProfileEditingDescription,
            })}
          />

          <ConfigSwitch>
            <SwitchButton
              isOn={allowProfileEditing}
              handleToggle={handleToggleAllowProfileEditing}
            />
          </ConfigSwitch>
        </ConfigItemContainer>
      ),
    [
      formatMessage,
      handleToggleAllowProfileEditing,
      allowProfileEditing,
      allowProfileEditingLoaded,
    ]
  );

  const extensionConfigContainer = useMemo(
    () =>
      usesExtensionLoaded && (
        <ConfigItemContainer>
          {formatMessage({
            id: AppMessages.componentUserManagementUseExtension,
          })}

          <Tooltip
            position="right"
            text={formatMessage({
              id: AppMessages.componentUserManagementUseExtensionDescription,
            })}
          />

          <ConfigSwitch>
            <SwitchButton
              isOn={usesExtension}
              handleToggle={handleToggleUsesExtension}
            />
          </ConfigSwitch>
        </ConfigItemContainer>
      ),
    [
      formatMessage,
      handleToggleUsesExtension,
      usesExtension,
      usesExtensionLoaded,
    ]
  );

  const configContainer = useMemo(
    () => (
      <ConfigContainer>
        {locationConfigContainer}
        {profileEditingConfigContainer}
        {extensionConfigContainer}
      </ConfigContainer>
    ),
    [
      locationConfigContainer,
      profileEditingConfigContainer,
      extensionConfigContainer,
    ]
  );

  const userList = useMemo(
    () => (
      <div className="users">
        {loading && (
          <UserContainer>
            <LoadingContainer>
              <SpinnerIcon />
            </LoadingContainer>
          </UserContainer>
        )}

        {!loading &&
          filteredUsers.map((user, index) => (
            <UserContainer key={user.id}>
              {!!index && <hr />}

              <span>
                <Avatar
                  size="medium"
                  firstName={user.firstName}
                  lastName={user.lastName}
                  picture={user.picture}
                  hideBorder
                  hasEditButton
                  onEditPicture={(newImageData: string) => {
                    setEditingUserId(user.id);
                    handleEditPicture(newImageData);
                  }}
                />

                <div>
                  <EditableLabel
                    className="name"
                    value={getUserName(user)}
                    placeholder={formatMessage({
                      id: AppMessages.componentUserManagementUnnamed,
                    })}
                    textAlign="left"
                    fontSizePx={16}
                    onChange={(newValue?: string) => {
                      updateUserName(user.id, newValue);
                    }}
                  />

                  <EditableLabel
                    className="role"
                    value={user.roleDescription}
                    placeholder={formatMessage({
                      id: AppMessages.componentUserManagementNoRole,
                    })}
                    textAlign="left"
                    fontSizePx={14}
                    onChange={(newValue?: string) => {
                      updateUserRole(user.id, newValue);
                    }}
                  />

                  <span className="email">{user.email}</span>

                  {!user.lastLoginAt && (
                    <Label className="no-login">
                      {formatMessage({
                        id: AppMessages.componentUserManagementNoLogin,
                      })}
                    </Label>
                  )}

                  {isAdminUser(user) && (
                    <Label className="admin">
                      {formatMessage({
                        id: AppMessages.componentUserManagementAdministrator,
                      })}
                    </Label>
                  )}

                  {!!user.accessRevokedAt && (
                    <Label className="access-revoked">
                      {formatMessage({
                        id: AppMessages.componentUserManagementAccessRevoked,
                      })}
                    </Label>
                  )}
                </div>
              </span>

              <span>
                {usesLocationLoaded && usesLocation && (
                  <IconButton
                    style={{ opacity: user.locationRequired ? 1 : 0.3 }}
                    onClick={() => {
                      setUserLocationRequired(user, !user.locationRequired);
                    }}
                  >
                    <LocationMobileIcon />
                    <Tooltip
                      text={formatMessage({
                        id: user.locationRequired
                          ? AppMessages.componentUserManagementMakeLocationOptional
                          : AppMessages.componentUserManagementMakeLocationRequired,
                      })}
                    />
                  </IconButton>
                )}

                {usesExtensionLoaded && usesExtension && (
                  <IconButton
                    style={{ opacity: user.extensionRequired ? 1 : 0.3 }}
                    onClick={() => {
                      setUserExtensionRequired(user, !user.extensionRequired);
                    }}
                  >
                    {user.extensionRequired ? (
                      <ExtensionIcon />
                    ) : (
                      <ExtensionOffIcon />
                    )}
                    <Tooltip
                      text={formatMessage({
                        id: user.extensionRequired
                          ? AppMessages.componentUserManagementMakeExtensionOptional
                          : AppMessages.componentUserManagementMakeExtensionRequired,
                      })}
                    />
                  </IconButton>
                )}

                <IconButton
                  onClick={(e) => {
                    setContextUser(user);
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </span>
            </UserContainer>
          ))}
      </div>
    ),
    [
      filteredUsers,
      formatMessage,
      getUserName,
      isAdminUser,
      handleEditPicture,
      loading,
      setUserLocationRequired,
      usesLocation,
      usesLocationLoaded,
      setUserExtensionRequired,
      usesExtension,
      usesExtensionLoaded,
      updateUserName,
      updateUserRole,
    ]
  );

  const inviteButtonContainer = useMemo(
    () => (
      <div className="invite-button-container">
        <Button onClick={onOpenUserInvitationsRequest}>
          <AddUserIcon />
          {formatMessage({
            id: AppMessages.componentUserManagementInviteNewUsers,
          })}
        </Button>
      </div>
    ),
    [formatMessage, onOpenUserInvitationsRequest]
  );

  const userContextMenu = useMemo(
    () => (
      <ContextMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseContextMenu}
        TransitionComponent={Fade}
      >
        <div className="context-menu-header">
          <div className="context-menu-info">
            <span className="context-menu-name">
              {getUserName(contextUser) || contextUser?.email}
            </span>
          </div>
        </div>

        <Divider />

        {!contextUser.lastLoginAt && (
          <ContextMenuItem
            disabled={contextUser.id === authUser.id}
            onClick={(e) => {
              inviteUser(contextUser.email).then(
                (sended) => {
                  addToast({
                    type: 'success',
                    title: 'Success',
                    description: '=)',
                  });
                  return { email: contextUser.email, sended };
                },
                (error) => {
                  addToast({
                    type: 'error',
                    title: 'Error',
                    description: error.message,
                  });
                  return { email: contextUser.email, sended: false };
                }
              );
              handleCloseContextMenu(e);
            }}
          >
            <EmailIcon />
            {formatMessage({
              id: AppMessages.componentUserManagementResendInvitationEmail,
            })}
          </ContextMenuItem>
        )}

        {isAdminUser(contextUser) && (
          <ContextMenuItem
            disabled={contextUser.id === authUser.id}
            onClick={(e) => {
              revokeAdminPrivileges(contextUser.id);
              handleCloseContextMenu(e);
            }}
          >
            <UserAdminIcon admin={!isAdminUser(contextUser)} />
            {formatMessage({
              id: AppMessages.componentUserManagementRemoveFromAdministrators,
            })}
          </ContextMenuItem>
        )}

        {!isAdminUser(contextUser) && (
          <ContextMenuItem
            disabled={contextUser.id === authUser.id}
            onClick={(e) => {
              grantAdminPrivileges(contextUser.id);
              handleCloseContextMenu(e);
            }}
          >
            <UserAdminIcon admin={!isAdminUser(contextUser)} />
            {formatMessage({
              id: AppMessages.componentUserManagementAddAsAdministrator,
            })}
          </ContextMenuItem>
        )}

        {!!contextUser.lastLoginAt && !contextUser.accessRevokedAt && (
          <ContextMenuItem
            disabled={contextUser.id === authUser.id}
            onClick={(e) => {
              revokeUserAccess(contextUser.id);
              handleCloseContextMenu(e);
            }}
          >
            <UserIcon revoked={!contextUser.accessRevokedAt} />
            {formatMessage({
              id: AppMessages.componentUserManagementRevokeAccess,
            })}
          </ContextMenuItem>
        )}

        {!!contextUser.lastLoginAt && !!contextUser.accessRevokedAt && (
          <ContextMenuItem
            disabled={contextUser.id === authUser.id}
            onClick={async (e) => {
              const usageLimits = await getUsageLimits();

              if (canAccess.length >= usageLimits.activeUsersLimit) {
                addToast({
                  type: 'info',
                  title: formatMessage({
                    id: AppMessages.limitsActiveUsersReached,
                  }),
                  description: formatMessage(
                    {
                      id: AppMessages.limitsActiveUsers,
                    },
                    {
                      limit: usageLimits.activeUsersLimit,
                    }
                  ),
                  duration: 7000,
                });
                return;
              }
              grantUserAccess(contextUser.id);
              handleCloseContextMenu(e);
            }}
          >
            <UserIcon revoked={!contextUser.accessRevokedAt} />
            {formatMessage({
              id: AppMessages.componentUserManagementGrantAccess,
            })}
          </ContextMenuItem>
        )}

        {!contextUser.lastLoginAt && <Divider />}

        {!contextUser.lastLoginAt && (
          <ContextMenuItem
            disabled={contextUser.id === authUser.id}
            onClick={(e) => {
              usersService.delete(contextUser.id);
              handleCloseContextMenu(e);
            }}
          >
            <DeleteIcon />
            {formatMessage({
              id: AppMessages.componentUserManagementDeleteUser,
            })}
          </ContextMenuItem>
        )}
      </ContextMenu>
    ),
    [
      addToast,
      anchorEl,
      authUser.id,
      canAccess.length,
      contextUser,
      formatMessage,
      getUsageLimits,
      getUserName,
      grantAdminPrivileges,
      grantUserAccess,
      handleCloseContextMenu,
      inviteUser,
      isAdminUser,
      revokeAdminPrivileges,
      revokeUserAccess,
      usersService,
    ]
  );

  const pictureEditor = useMemo(
    () => (
      <ProfilePictureEditor
        isOpen={editingPicture}
        image={editingPictureData}
        onCloseRequest={handleNewPicture}
      />
    ),
    [editingPicture, editingPictureData, handleNewPicture]
  );

  return (
    <ModalContainer isOpen={isOpen} onClickOutside={onCloseRequest}>
      <Container onClick={(e) => e.stopPropagation()}>
        {header}
        {searchContainer}
        {usersCount}
        {configContainer}
        {userList}
        {inviteButtonContainer}
        {userContextMenu}
        {pictureEditor}
      </Container>
    </ModalContainer>
  );
};
