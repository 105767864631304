import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { createContext, useContextSelector } from './context';
import { useApi, OfficeModel, RoomModel, UserModel } from './api';

interface ModelsContextData {
  offices: OfficeModel[];
  users: UserModel[];
  officesLoaded: boolean;
  usersLoaded: boolean;
  loadOffices: () => Promise<void>;
  loadUsers: () => Promise<void>;
  addOfficeToState: (newOffice: OfficeModel) => void;
  updateOfficeState: (office: OfficeModel) => void;
  deleteOfficeFromState: (office: OfficeModel) => void;
  addRoomToState: (officeId: string, newRoom: RoomModel) => void;
  updateRoomState: (officeId: string, room: RoomModel) => void;
  deleteRoomFromState: (officeId: string, room: RoomModel) => void;
  addUserToState: (newUser: UserModel) => void;
  updateUserState: (user: UserModel) => void;
  deleteUserFromState: (user: UserModel) => void;
}

export const defaultRoom: RoomModel = {
  id: 'default',
  name: 'Lobby',
};

export const mobileRoom: RoomModel = {
  id: 'mobile',
  name: 'Mobile',
};

export const officeDefaultRooms = [defaultRoom, mobileRoom];

const ModelsContext = createContext({} as ModelsContextData);

export const ModelsProvider: React.FC = ({ children }) => {
  const { officesService, usersService } = useApi();
  const [offices, setOffices] = useState<OfficeModel[]>([]);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [officesLoaded, setOfficesLoaded] = useState(false);
  const [usersLoaded, setUsersLoaded] = useState(false);

  const loadOffices = useCallback(async () => {
    const officeList = await officesService.getAll();
    officeList.forEach((office) => {
      // eslint-disable-next-line no-param-reassign
      office.rooms = [...officeDefaultRooms, ...office.rooms];
    });
    setOffices(officeList);
    setOfficesLoaded(true);
  }, [officesService]);

  const loadUsers = useCallback(async () => {
    setUsers(await usersService.getAll());
    setUsersLoaded(true);
  }, [usersService]);

  const addOfficeToState = useCallback((newOffice: OfficeModel) => {
    setOffices((state) => {
      const newState = [...state];
      const item = newState.find((t) => t.id === newOffice.id);
      if (item) return state;
      const office = { ...newOffice };
      office.rooms = [...officeDefaultRooms, ...office.rooms];
      return [...newState, office];
    });
  }, []);

  const updateOfficeState = useCallback((office: OfficeModel) => {
    setOffices((state) => {
      const newState = [...state];
      const item = newState.find((t) => t.id === office.id);
      if (!item) return state;
      const index = newState.indexOf(item);
      newState[index] = { ...item, ...office };
      return newState;
    });
  }, []);

  const deleteOfficeFromState = useCallback((office: OfficeModel) => {
    setOffices((state) => state.filter((t) => t.id !== office.id));
  }, []);

  const addRoomToState = useCallback((officeId: string, newRoom: RoomModel) => {
    setOffices((state) => {
      const newState = [...state];
      const office = newState.find((t) => t.id === officeId);
      if (!office) return state;
      const item = office.rooms.find((t) => t.id === newRoom.id);
      if (item) return state;
      office.rooms = [...office.rooms, newRoom];
      const officeIndex = newState.indexOf(office);
      newState[officeIndex] = { ...office };
      return newState;
    });
  }, []);

  const updateRoomState = useCallback((officeId: string, room: RoomModel) => {
    setOffices((state) => {
      const newState = [...state];
      const office = newState.find((t) => t.id === officeId);
      if (!office) return state;
      const item = office.rooms.find((t) => t.id === room.id);
      if (!item) return state;
      office.rooms = [...office.rooms];
      const index = office.rooms.indexOf(item);
      office.rooms[index] = { ...item, ...room };
      const officeIndex = newState.indexOf(office);
      newState[officeIndex] = { ...office };
      return newState;
    });
  }, []);

  const deleteRoomFromState = useCallback(
    (officeId: string, room: RoomModel) => {
      setOffices((state) => {
        const newState = [...state];
        const office = newState.find((t) => t.id === officeId);
        if (!office) return state;
        office.rooms = office.rooms.filter((t) => t.id !== room.id);
        const officeIndex = newState.indexOf(office);
        newState[officeIndex] = { ...office };
        return newState;
      });
    },
    []
  );

  const addUserToState = useCallback((newUser: UserModel) => {
    setUsers((state) => {
      const newState = [...state];
      const item = newState.find((t) => t.id === newUser.id);
      if (item) return state;
      return [...newState, newUser];
    });
  }, []);

  const updateUserState = useCallback((user: UserModel) => {
    setUsers((state) => {
      const newState = [...state];
      const item = newState.find((t) => t.id === user.id);
      if (!item) return state;
      const index = newState.indexOf(item);
      newState[index] = { ...item, ...user };
      return newState;
    });
  }, []);

  const deleteUserFromState = useCallback((user: UserModel) => {
    setUsers((state) => state.filter((t) => t.id !== user.id));
  }, []);

  useEffect(() => {
    loadOffices();
  }, [loadOffices]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const contextValue = useMemo<ModelsContextData>(
    () => ({
      offices,
      users,
      officesLoaded,
      usersLoaded,
      loadOffices,
      loadUsers,
      addOfficeToState,
      updateOfficeState,
      deleteOfficeFromState,
      addRoomToState,
      updateRoomState,
      deleteRoomFromState,
      addUserToState,
      updateUserState,
      deleteUserFromState,
    }),
    [
      offices,
      users,
      officesLoaded,
      usersLoaded,
      loadOffices,
      loadUsers,
      addOfficeToState,
      updateOfficeState,
      deleteOfficeFromState,
      addRoomToState,
      updateRoomState,
      deleteRoomFromState,
      addUserToState,
      updateUserState,
      deleteUserFromState,
    ]
  );

  return (
    <ModelsContext.Provider value={contextValue}>
      {children}
    </ModelsContext.Provider>
  );
};

export function useModels<TResult>(
  selector: (state: ModelsContextData) => TResult
): TResult {
  return useContextSelector(ModelsContext, selector);
}
