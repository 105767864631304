import { Divider } from '@material-ui/core';
import { shade, lighten, opacify } from 'polished';
import { styled, css } from '../../themes';
import { StyledInput } from '../StyledInput';

const WIDTH = 524;
const HEADER_HEIGHT = 40;
const FIXED_HEIGHT = HEADER_HEIGHT + 10;
const IMAGE_SIZE = 40;

export const Container = styled.div`
  position: relative;
  width: 60%;
  min-width: ${WIDTH}px;
  max-width: ${WIDTH}px;
  height: 85vh;
  background: ${(props) => props.theme.boxColor};
  color: ${(props) => props.theme.textColor};
  box-shadow: ${(props) => props.theme.boxGlow};
  border-radius: 5px;

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: ${HEADER_HEIGHT}px;
    max-height: ${HEADER_HEIGHT}px;
    padding: 5px;
    padding-left: 10px;

    > .display-name {
      width: 100%;
    }
  }

  .postlist {
    position: relative;
    max-width: 100%;
    min-height: calc(100% - ${FIXED_HEIGHT}px);
    max-height: calc(100% - ${FIXED_HEIGHT}px);
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding: 0px 5px;
  }

  .search-box {
    position: absolute;
    background: ${(props) => props.theme.boxColor};
    color: ${(props) => props.theme.textColor};
    top: ${HEADER_HEIGHT}px;
    left: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: calc(100% - ${FIXED_HEIGHT}px);
    max-height: calc(100% - ${FIXED_HEIGHT}px);
    height: calc(100% - ${FIXED_HEIGHT}px);
    padding: 2px;
    z-index: 1;

    > .postlist.search-results {
      position: absolute;
      min-width: 100%;
      max-width: 100%;
      top: 40px;
      left: 0px;
      max-height: calc(100% - 40px);
      overflow-y: auto;
      overflow-x: hidden;

      .highlight {
        background: yellow;
        color: black;
      }
    }

    > .postlist.pinned-posts {
      position: absolute;
      min-width: 100%;
      max-width: 100%;
      top: 0px;
      left: 0px;
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .post {
    position: relative;
    max-width: 100%;
    margin: 10px;
    padding: 10px;
    transition: box-shadow 0.5s;
    background: ${(props) => props.theme.boxColor};
    border-left: 3px solid ${({ theme }) => theme.mentionColor};

    :hover {
      > button.jump {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    > button.jump {
      position: absolute;
      display: none;
      right: 0px;
      top: 0px;
      margin: 2px 5px;
      border: none;
      background: none;
      color: ${(props) => props.theme.textColor};
      opacity: 0.5;
      transform: scale(0.9);

      :hover {
        opacity: 0.8;
        transform: scale(1);
      }

      > svg {
        font-size: 20px;
      }
    }

    > .post-main {
      > .title-bar {
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100%;

        ::after {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          border-bottom: 1px solid ${(props) => props.theme.textColor};
          opacity: 0.3;
        }

        > h3 {
          font-weight: bold;
        }

        > .close-button {
          position: absolute;
          top: -10px;
          right: -10px;
        }
      }

      > .post-header {
        display: flex;
        width: 100%;
        margin-bottom: 5px;

        > .post-image {
          min-width: ${IMAGE_SIZE}px;
          max-width: ${IMAGE_SIZE}px;
          margin-right: 10px;
          > img {
            border-radius: 50%;
          }
        }

        > .post-actions-container {
          position: absolute;
          display: flex;
          top: 0px;
          right: 0px;
          border-radius: 5px;

          :hover {
            background: ${(props) => shade(0.2, props.theme.boxColor)};
          }

          > * {
            opacity: 0.4;

            :hover {
              opacity: 1;
            }
          }
        }
      }

      .post-time {
        padding: 2px 0px;
        font-size: 12px;
        font-weight: bold;
        opacity: 0.5;
      }

      .post-text {
        width: 100%;
        max-width: calc(100% - ${IMAGE_SIZE}px);
        margin-bottom: 16px;

        &.post-content {
          max-width: 100%;
          padding-bottom: 5px;
          word-wrap: break-word;

          > .emoji-mart-emoji {
            bottom: -5px;
            margin-top: -5px;
          }
        }

        > .post-display-name {
          display: flex;
          width: 100%;
          max-width: 100%;
          font-weight: bold;

          > .post-user-role-description {
            opacity: 0.5;
            margin-left: 5px;
          }

          > .post-time {
            margin-left: 10px;
          }
        }
      }
    }

    a {
      color: ${(props) => props.theme.linkColor};
      font-weight: bold;
      text-decoration: none;

      :hover {
        color: ${(props) => shade(0.2, props.theme.linkColor)};
      }
    }

    a.mention {
      color: ${(props) => props.theme.mentionColor};

      :hover {
        color: ${(props) => shade(0.2, props.theme.mentionColor)};
      }
    }

    &.got-focus {
      box-shadow: 0 0 3px red;
      background: ${(props) => shade(0.1, props.theme.boxColor)};

      .post-time {
        opacity: 0.8 !important;
      }
    }

    .input-content {
      position: relative;
      width: calc(100% + 8px);
      max-height: calc(70vh - 200px);
      overflow-y: scroll;
    }

    .input-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &.add-to-post {
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 0 2px ${(props) => props.theme.textColor};
        margin-top: 10px;
        margin-bottom: 15px;
      }

      > * + * {
        margin-left: 10px;
      }

      > .add-to-post-label {
        width: 100%;
        cursor: default;
      }

      > .publish {
        width: 100%;
      }
    }

    .file-list {
      .loaded-file {
        position: relative;
        margin-bottom: 10px;

        :hover {
          background: #b8000233;

          .delete {
            opacity: 1 !important;
          }
        }

        .delete {
          position: absolute;
          opacity: 0;
          top: 0px;
          right: 0px;
        }
      }
    }

    > .interactions-in-post {
      display: flex;

      .reactions-container {
        position: relative;
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        max-width: 100%;
        > .add-reaction-button {
          opacity: 0.5;

          :hover {
            opacity: 1;
          }
        }
      }

      .comment-count {
        align-items: flex-start;
        min-width: 30%;
      }

      .show-comments-button {
        font-size: 14px;
        border: none;
        background: none;
        color: ${({ theme }) => theme.textColor};

        :hover {
          text-decoration: underline;
        }

        div {
          display: flex;

          svg {
            margin-right: 6px;
          }
        }
      }

      .new-comments {
        color: #ef5350;
        font-weight: bold;
      }
    }
  }
`;

interface ReactionButtonProps {
  reacted: boolean;
}

export const ReactionButton = styled.button<ReactionButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  max-height: 22px;
  border: none;
  font-size: 12px;
  border-radius: 10px;
  color: ${(props) => props.theme.textColor};
  margin-right: 5px;

  ${(props) =>
    props.reacted
      ? css`
          background: ${shade(0.3, props.theme.boxColor)};
          box-shadow: 0px 0px 2px ${props.theme.textColor};
        `
      : css`
          background: ${lighten(0.05, props.theme.boxColor)};
        `}

  :hover {
    background: ${(props) => shade(0.1, props.theme.boxColor)};
    box-shadow: 0px 0px 2px ${(props) => props.theme.textColor};
  }

  .emoji-mart-emoji {
    font-size: 0 !important;
  }
`;

export const CustomDivider = styled(Divider)`
  margin-top: 15px !important;
  margin-bottom: 15px !important;

  height: 2px !important;
  background-color: ${({ theme }) => lighten(0.1, theme.boxColor)} !important;
`;

export const CustomStyledInput = styled(StyledInput)`
  .MuiInput-underline {
    :before {
      border-bottom: none !important;
    }
    :after {
      border-bottom: none !important;
    }
  }
`;

export const OutsideArea = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background: black;
  opacity: 0.6;
  z-index: 1;
`;

export const OfficePostLinkPreviewContainer = styled.div`
  padding-left: 5px;
  border-left: 3px solid ${(props) => opacify(-0.5, props.theme.textColor)};
  word-wrap: break-word;
`;

type TypeEmojiPicker = 'comment-input' | 'post' | 'comment' | 'post-creation';

interface EmojiProps {
  type: TypeEmojiPicker;
}

export const OfficePostEmojiPickerContainer = styled.div<EmojiProps>`
  position: absolute;
  z-index: 2;

  ${({ type }) => {
    switch (type) {
      case 'post':
        return css`
          right: 0px;
          bottom: 85px;
        `;
      case 'comment-input':
        return css`
          right: 0px;
          bottom: 56px;
        `;
      case 'comment':
        return css`
          right: 0px;
          bottom: 100px;
        `;
      case 'post-creation':
        return css`
          right: 0px;
          top: -10px;
        `;
      default:
        return undefined;
    }
  }}

  > .outside-area {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    opacity: 0;
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  background: ${(props) => props.theme.background};
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  opacity: 0.6;

  > svg {
    font-size: 50px;
  }
`;

export const Button = styled.button`
  border: none;
  background: ${(props) => props.color || 'green'};
  padding: 7px 14px;
  color: white;
  white-space: nowrap;
  border-radius: 5px;
  transition: opacity 0.3s;

  :hover {
    background: ${(props) => shade(0.2, props.color || 'green')};
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
    :hover {
      background: ${shade(0.2, 'green')};
    }
  }
`;

export const LoadMoreButton = styled.button`
  margin-top: 5px;
  margin-left: 15%;
  min-width: 70%;
  max-width: 70%;
  border: none;
  border-radius: 10px;
  padding: 5px;
  background: none;
  color: ${(props) => props.theme.textColor};
  font-weight: bold;
  opacity: 0.5;

  :hover {
    opacity: 0.7;
  }
`;

export const LoadNewButton = styled.button`
  position: absolute;
  margin-top: -20px;
  margin-left: calc(100% - 32px);
  min-width: 22px;
  max-width: 22px;
  min-height: 22px;
  max-height: 22px;
  border: none;
  border-radius: 50%;
  background: ${(props) => props.theme.textColor};
  color: ${(props) => props.theme.background};
  opacity: 0.5;

  :hover {
    opacity: 0.7;
  }

  > svg {
    font-size: 22px;
  }
`;

// OfficePostComments Styles
export const CommentsContainer = styled.div`
  margin-top: 8px;
`;

export const CommentsHideButton = styled.button`
  margin: 0 8px;
  border: none;
  background: none;
  color: ${({ theme }) => theme.textColor};
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-right: 8px;
  }
`;

export const CommentsMain = styled.div`
  max-height: 50vh;
  padding: 8px;
  overflow-y: auto;
`;

export const CommentsCount = styled.div`
  position: absolute;
  right: 24px;
  font-size: 12px;

  svg {
    margin-right: 6px;
  }
`;

export const CommentsShowAllButton = styled.button`
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.textColor};
`;

export const CommentsShowAllButtonIcon = styled.div`
  margin-right: 8px;
`;

export const CommentsShowAllButtonDesc = styled.div``;

// OfficePostComment style
export const CommentContainer = styled.div`
  padding: 10px;
  margin-bottom: 8px;
  border-left: 3px solid #CC207D;
`;

export const CommentHeader = styled.div`
  display: flex;
`;

export const CommentAvatar = styled.div`
  margin-right: 8px;

  img {
    border-radius: 50%;
  }
`;

export const CommentContent = styled.div`
  width: 100%;
`;

export const CommentInfo = styled.div`
  margin-bottom: 8px;
`;

export const CommentDisplayName = styled.div`
  font-weight: bold;
`;

export const CommentRole = styled.span`
  font-size: 14px;
  font-weight: normal;
  margin-left: 4px;
  opacity: 0.5;
`;

export const CommentDate = styled.div`
  font-size: 12px;
  opacity: 0.5;
`;

export const CommentMessage = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
`;

export const CommentActions = styled.div`
  display: flex;
  right: 32px;
  border-radius: 5px;
  margin-top: 8px;
  opacity: 0.5;
`;

export const CommentReactionsContainer = styled.div`
  position: relative;
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 100%;
`;

// OfficePostCommentInput
export const CommentInputContainer = styled.div`
  margin-top: 8px;
  display: flex;
`;

export const CommentInputAvatar = styled.div`
  margin-right: 8px;
  img {
    border-radius: 50%;
  }
`;

export const CommentInputContent = styled.div`
  display: flex;
  width: 100%;
  background: ${(props) => lighten(0.1, props.theme.boxColor)};
  border-radius: 25px;
  padding: 0 16px;
  align-items: flex-end;
`;

export const CommentInput = styled(StyledInput)`
  width: 100%;

  .MuiInput-underline {
    :before {
      border-bottom: none !important;
    }
    :after {
      border-bottom: none !important;
    }
  }
`;

export const CommentInputButtons = styled.div`
  margin-bottom: 6px;
  display: flex;
`;
