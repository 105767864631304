import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { EmojiData } from 'emoji-mart';
import { Post } from 'mattermost-redux/types/posts';

import { AppMessages } from '../../languages';
import { useMattermost, formatPostContent } from '../../hooks/mattermost';
import { getFormattedUserName } from '../../hooks/office';
import { DeleteIcon, AddReactionIcon, ReplyIcon } from '../Icons';
import { IconButton } from '../IconButton';
import { Tooltip } from '../Tooltip';

import { useChatChannel } from './ChatChannel';
import { ChatPostFileLink } from './ChatPostFileLink';
import { ChatEmojiPicker } from './ChatEmojiPicker';
import { ChatPostLinkPreview } from './ChatPostLinkPreview';
import { ChatPostReaction } from './ChatPostReaction';

type PostReactions = { emoji: string; users: string[] }[];

interface Props {
  post: Post;
  showUser?: boolean;
  addPostDivisor?: boolean;
  addNewDivisor?: boolean;
  addDateDivisor?: boolean;
  highlightTerm?: string;
  isReplied?: boolean;
  onImgResize?: (width: number, height: number) => void;
  onJumpClick?: () => void;
}

export const ChatPost: React.FC<Props> = ({
  post,
  showUser = true,
  addPostDivisor,
  addNewDivisor,
  addDateDivisor,
  highlightTerm,
  isReplied,
  onImgResize,
  onJumpClick,
}) => {
  const mattermostUserId = useMattermost(
    (state) => state.mattermostUser?.id || ''
  );
  const getProseiaUser = useMattermost((state) => state.getProseiaUser);
  const deletePost = useMattermost((state) => state.deletePost);
  const addReaction = useMattermost((state) => state.addReaction);
  const repliedPost = useMattermost((state) => {
    if (!post.props.replyTo) return undefined;
    return state.repliedPosts[post.props.replyTo];
  });

  const setReplyingToPostId = useChatChannel(
    (state) => state.setReplyingToPostId
  );

  const { formatMessage } = useIntl();

  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [emojiPickerPos, setEmojiPickerPos] = useState({
    bottom: 0,
    right: 0,
  });
  const [postReactions, setPostReactions] = useState<PostReactions>([]);

  const user = useMemo(() => getProseiaUser(post.user_id), [
    getProseiaUser,
    post.user_id,
  ]);

  const isSelfPost = mattermostUserId === post.user_id && !isReplied;

  const { firstName, lastName } = user;
  const displayName = useMemo(
    () =>
      post.type.startsWith('system_')
        ? 'System'
        : getFormattedUserName({ firstName, lastName }),
    [post.type, firstName, lastName]
  );
  const createdAt = useMemo(() => new Date(post.create_at), [post.create_at]);
  const hours = createdAt.getHours();
  const hoursStr = `${hours < 10 ? '0' : ''}${hours}`;
  const minutes = createdAt.getMinutes();
  const minutesStr = `${minutes < 10 ? '0' : ''}${minutes}`;
  const displayTime = `${hoursStr}:${minutesStr}`;
  const displayDate = createdAt.toLocaleDateString();

  const { embeds, files, reactions } = post.metadata;
  const reactionsCount = reactions?.length;

  const getImage = useCallback(() => {
    return <img alt={displayName} src={user.picture} width="32" height="32" />;
  }, [user.picture, displayName]);

  const handleDelete = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm(formatMessage({ id: AppMessages.chatPostConfirmDeletion })))
      return;
    deletePost(post.id);
  }, [post, formatMessage, deletePost]);

  const handleAddReaction = useCallback(
    (emoji: EmojiData) => {
      if (!emoji?.id) return;
      addReaction(post.id, emoji.id);
    },
    [post, addReaction]
  );

  useEffect(() => {
    if (!emojiPickerOpen) return;
    const container = document.getElementById(post.id);
    if (!container) return;
    const rect = container.getBoundingClientRect();
    const right = window.innerWidth - (rect.right + 15);
    setEmojiPickerPos({ bottom: 10, right });
  }, [post, emojiPickerOpen]);

  useEffect(() => {
    if (!reactions || !reactionsCount) {
      setPostReactions([]);
      return;
    }
    setPostReactions(
      reactions.reduce((result, item) => {
        const previouslyAdded = result.find((t) => t.emoji === item.emoji_name);
        if (previouslyAdded) {
          if (!previouslyAdded.users.includes(item.user_id))
            previouslyAdded.users.push(item.user_id);
          return result;
        }
        return [...result, { emoji: item.emoji_name, users: [item.user_id] }];
      }, [] as PostReactions)
    );
  }, [reactions, reactionsCount]);

  const postDivisor = useMemo(
    () => addPostDivisor && <div className="post-divisor" />,
    [addPostDivisor]
  );

  const newDivisor = useMemo(
    () =>
      addNewDivisor && (
        <h5 id={`new-divisor-${post.channel_id}`} className="post-new-divisor">
          <span>{formatMessage({ id: AppMessages.chatNewMessages })}</span>
        </h5>
      ),
    [addNewDivisor, formatMessage, post.channel_id]
  );

  const dateDivisor = useMemo(
    () =>
      addDateDivisor && (
        <h5 className="post-date-divisor">
          <span>{new Date(post.create_at).toLocaleDateString()}</span>
        </h5>
      ),
    [addDateDivisor, post.create_at]
  );

  const userImage = useMemo(
    () =>
      !isReplied && showUser && <div className="post-image">{getImage()}</div>,
    [isReplied, showUser, getImage]
  );

  const postTime = useMemo(
    () => (
      <div className="post-time">
        {displayTime}
        {isReplied && ` - ${displayDate}`}
      </div>
    ),
    [isReplied, displayDate, displayTime]
  );

  const userName = useMemo(
    () =>
      showUser && (
        <div className="post-display-name">
          {displayName}
          {postTime}
        </div>
      ),
    [displayName, postTime, showUser]
  );

  const postMessage = useMemo(
    () => (
      <div className="post-content">
        {formatPostContent(post.message, highlightTerm)}
      </div>
    ),
    [highlightTerm, post.message]
  );

  const postTextContent = useMemo(
    () => (
      <div className="post-text">
        {userName}
        {postMessage}
      </div>
    ),
    [postMessage, userName]
  );

  const replyButton = useMemo(
    () => (
      <IconButton
        iconSize={18}
        padding={2}
        onClick={() => setReplyingToPostId(post.id)}
      >
        <ReplyIcon />
        <Tooltip
          position="top-end"
          text={formatMessage({
            id: AppMessages.chatPostReplyTo,
          })}
        />
      </IconButton>
    ),
    [formatMessage, post.id, setReplyingToPostId]
  );

  const addReactionButton = useMemo(
    () => (
      <IconButton
        iconSize={18}
        padding={2}
        onClick={() => setEmojiPickerOpen(true)}
      >
        <AddReactionIcon />
        <Tooltip
          position="top-end"
          text={formatMessage({
            id: AppMessages.chatPostAddReaction,
          })}
        />
      </IconButton>
    ),
    [formatMessage]
  );

  const deletePostButton = useMemo(
    () =>
      mattermostUserId === post.user_id && (
        <IconButton iconSize={18} padding={2} onClick={handleDelete}>
          <DeleteIcon />
          <Tooltip
            position="top-end"
            text={formatMessage({ id: AppMessages.chatPostDelete })}
          />
        </IconButton>
      ),
    [formatMessage, handleDelete, mattermostUserId, post.user_id]
  );

  const postActionsContainer = useMemo(
    () =>
      !isReplied &&
      !onJumpClick && (
        <div className="post-actions-container">
          {replyButton}
          {addReactionButton}
          {deletePostButton}
        </div>
      ),
    [addReactionButton, deletePostButton, replyButton, isReplied, onJumpClick]
  );

  const postMainContent = useMemo(
    () => (
      <div className="post-main">
        {userImage}
        {!showUser && postTime}
        {postTextContent}
        {postActionsContainer}
      </div>
    ),
    [postActionsContainer, postTextContent, postTime, showUser, userImage]
  );

  const postFiles = useMemo(
    () =>
      files &&
      files.map((file) => (
        <ChatPostFileLink
          key={`${post.id}|file|${file.id}`}
          file={file}
          onImgResize={onImgResize}
        />
      )),
    [files, onImgResize, post.id]
  );

  const postEmbeds = useMemo(
    () =>
      embeds &&
      embeds.map((embed) => (
        <ChatPostLinkPreview
          key={`${post.id}|embed|${embed.url}`}
          embed={embed}
          onImgResize={onImgResize}
        />
      )),
    [embeds, onImgResize, post.id]
  );

  const postReactionsContainer = useMemo(
    () =>
      !isReplied &&
      !onJumpClick &&
      postReactions.length > 0 && (
        <div className="reactions-container">
          {postReactions.map((item) => (
            <ChatPostReaction key={item.emoji} item={item} postId={post.id} />
          ))}
          <IconButton
            iconSize={20}
            padding={0}
            className="add-reaction-button"
            onClick={() => setEmojiPickerOpen(true)}
          >
            <AddReactionIcon />
            <Tooltip
              text={formatMessage({ id: AppMessages.chatPostAddReaction })}
            />
          </IconButton>
        </div>
      ),
    [formatMessage, onJumpClick, isReplied, post.id, postReactions]
  );

  const jumpToPostButton = useMemo(
    () =>
      onJumpClick && (
        <button type="button" className="jump" onClick={onJumpClick}>
          <FaArrowAltCircleRight />
        </button>
      ),
    [onJumpClick]
  );

  const emojiPicker = useMemo(
    () => (
      <div
        style={{
          position: 'fixed',
          zIndex: 10,
          bottom: `${emojiPickerPos.bottom}px`,
          right: `${emojiPickerPos.right}px`,
        }}
      >
        <ChatEmojiPicker
          open={emojiPickerOpen}
          onClose={() => setEmojiPickerOpen(false)}
          onSelect={handleAddReaction}
        />
      </div>
    ),
    [
      emojiPickerOpen,
      emojiPickerPos.bottom,
      emojiPickerPos.right,
      handleAddReaction,
    ]
  );

  const replied = useMemo(
    () =>
      !isReplied && !!repliedPost && <ChatPost post={repliedPost} isReplied />,
    [isReplied, repliedPost]
  );

  const postContainerClassList = useMemo(() => {
    if (!isSelfPost) return 'post';
    return 'post self';
  }, [isSelfPost]);

  const postContainer = useMemo(
    () => (
      <div id={post.id} className={postContainerClassList}>
        {replied}
        {postMainContent}
        {postFiles}
        {postEmbeds}
        {postReactionsContainer}
        {jumpToPostButton}
        {emojiPicker}
      </div>
    ),
    [
      post.id,
      replied,
      postMainContent,
      postFiles,
      postEmbeds,
      postReactionsContainer,
      jumpToPostButton,
      emojiPicker,
      postContainerClassList,
    ]
  );

  return (
    <>
      {postDivisor}
      {newDivisor}
      {dateDivisor}
      {postContainer}
    </>
  );
};
