import { shade } from 'polished';
import { styled } from '../../themes';

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  background: ${(props) => props.theme.boxColor};
  color: ${(props) => props.theme.textColor};
  box-shadow: ${(props) => props.theme.boxGlow};
  border-radius: 5px;

  > .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  > .content {
    word-wrap: break-word;
    max-width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .icon-container {
      max-width: 40px;
      font-size: 40px;
    }

    > .text-container {
      max-width: calc(100% - 60px);
      > a {
        color: ${(props) => props.theme.linkColor};
        max-width: 100%;
        font-weight: bold;
        -webkit-text-decoration: none;
        text-decoration: none;

        :hover {
          color: ${(props) => shade(0.2, props.theme.linkColor)};
        }
      }
    }
  }
`;

export const StoreBadge = styled.img`
  width: 80%;
`;
