const w = window as any;
const zsBaseUrl = 'https://survey.zohopublic.com';
const zsInterceptKey = 'zs_intercept';

const zsIntercept = (...args: any[]) => {
  (w[zsInterceptKey].p = w[zsInterceptKey].p || []).push(args);
};

export const openZohoSurvey = (surveyId: string, email?: string): void => {
  const route = `/api/v1/public/livesurveys/${surveyId}/popup/script`;

  w[zsInterceptKey] = w[zsInterceptKey] || zsIntercept;

  // eslint-disable-next-line no-restricted-globals
  const tenant = location.hostname.split('.')[0];

  let query = `tenant=${tenant}`;
  if (email) query += `&email=${email}`;

  setTimeout(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `${zsBaseUrl}${route}`;

    document.body.appendChild(script);

    zsIntercept(zsBaseUrl, `${surveyId}?${query}`, {
      minHeight: 450,
      heightType: 1,
      maxHeight: 700,
      hideEndPage: true,
    });
  }, 5000);
};
