/// <reference types="googlemaps" />

import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { CircularProgress } from '@material-ui/core';

import { matchesSearchValue } from '../../utils/strings';
import { useApi, UserModel } from '../../hooks/api';
import { useOffice, getFormattedUserName } from '../../hooks/office';
import { useModels } from '../../hooks/model';
import { AvatarStatus, statusColors } from '../Avatar/styles';
import { Avatar } from '../Avatar';
import { InputSearch } from '../InputSearch';
import { IconButton } from '../IconButton';
import { CloseBoxIcon } from '../Icons';
import { ModalContainer } from '../ModalContainer';

import {
  Container,
  Header,
  Content,
  UserContainer,
  UserInfo,
  UserName,
  LocationTimestamp,
} from './styles';

interface ModalProps {
  isOpen: boolean;
  onCloseRequest: () => void;
}

interface MapProps {
  isOpen: boolean;
  apiKey: string;
  trackedUsers: UserModel[];
  mapRef: google.maps.Map<Element> | undefined;
  setMapRef: React.Dispatch<
    React.SetStateAction<google.maps.Map<Element> | undefined>
  >;
}

interface UserMarkerProps {
  user: UserModel;
  status?: AvatarStatus;
  map?: google.maps.Map<Element>;
}

const containerStyle = {
  width: '100%',
  height: 'calc(80vh - 58px)',
};

const center = {
  lat: -20.9138945,
  lng: -46.9919819,
};

const getSvgDataString = (pictureDataUri: string, color: string) =>
  `data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='35.625' height='50.893' viewBox='0 0 35.625 50.893'%3E%3Cdefs%3E%3Cstyle%3E .avatar-mask %7B fill: url(%23pattern); %7D %3C/style%3E%3Cpattern id='pattern' preserveAspectRatio='xMidYMid slice' width='100%25' height='100%25' viewBox='0 0 1920 1080'%3E%3Cimage width='1920' height='1080' xlink:href='${pictureDataUri}'/%3E%3C/pattern%3E%3C/defs%3E%3Cg transform='translate(-943 -515)'%3E%3Cpath d='M-22.405,0A17.8,17.8,0,0,0-40.218,17.813c0,13.359,17.813,33.08,17.813,33.08S-4.593,31.172-4.593,17.813A17.8,17.8,0,0,0-22.405,0Z' fill='${color.replace(
    '#',
    '%23'
  )}' transform='translate(983.218 515)'/%3E%3Ccircle class='avatar-mask' cx='13.698' cy='13.698' r='13.698' transform='translate(947.115 518.953)'/%3E%3C/g%3E%3C/svg%3E%0A`;

const getInfoWindowContent = (user: UserModel) => {
  const timestamp = new Date(user.lastLocation?.timestamp || '').getTime();
  const millisecondsElapsed = Date.now() - timestamp;
  const timestampColor = millisecondsElapsed <= 60000 * 60 ? '#008000' : 'red';

  return ReactDOMServer.renderToString(
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        style={{ width: '70px', borderRadius: '50%' }}
        src={user.picture}
        alt={getFormattedUserName(user)}
      />

      <span style={{ color: 'black', fontWeight: 'bold', fontSize: '16px' }}>
        {getFormattedUserName(user)}
      </span>

      {!!user.roleDescription && (
        <span style={{ color: 'black' }}>{user.roleDescription}</span>
      )}

      <span style={{ color: 'black', fontWeight: 'bold' }}>{user.email}</span>

      <br />

      {user.lastLocation && (
        <>
          <span style={{ color: timestampColor, fontWeight: 'bold' }}>
            {new Date(user.lastLocation.timestamp).toLocaleString()}
          </span>

          <span style={{ color: 'black' }}>
            <b>Lat: </b>
            {user.lastLocation.latitude}
          </span>

          <span style={{ color: 'black' }}>
            <b>Lng: </b>
            {user.lastLocation.longitude}
          </span>
        </>
      )}
    </div>
  );
};

const fitBounds = (
  mapRef: google.maps.Map<Element> | undefined,
  users: UserModel[]
) => {
  if (!mapRef) return false;

  const bounds = new google.maps.LatLngBounds();

  users.forEach((user) => {
    bounds.extend({
      lat: user.lastLocation?.latitude || 0,
      lng: user.lastLocation?.longitude || 0,
    });
  });

  // Don't zoom in too far on only one marker
  if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
    const offset = 0.0005;
    const extendPoint1 = new google.maps.LatLng(
      bounds.getNorthEast().lat() + offset,
      bounds.getNorthEast().lng() + offset
    );
    const extendPoint2 = new google.maps.LatLng(
      bounds.getNorthEast().lat() - offset,
      bounds.getNorthEast().lng() - offset
    );
    bounds.extend(extendPoint1);
    bounds.extend(extendPoint2);
  }

  mapRef.fitBounds(bounds);

  return true;
};

const UserMarker: React.FC<UserMarkerProps> = ({
  user,
  status = 'inactive',
  map,
}) => {
  const { getDataUriFromUrl } = useApi();
  const [icon, setIcon] = useState<string>();
  const [infoWindow] = useState(new google.maps.InfoWindow());
  const [anchorPoint] = useState(new google.maps.Point(0, -40));

  const lat = user.lastLocation?.latitude || 0;
  const lng = user.lastLocation?.longitude || 0;
  const [position, setPosition] = useState({ lat, lng });

  const handleOpenInfoWIndow = useCallback(() => {
    const anchor = new google.maps.MVCObject();
    anchor.setValues({ position, anchorPoint });
    infoWindow.open(map, anchor);
  }, [map, infoWindow, position, anchorPoint]);

  useEffect(() => {
    if (!user.picture) return;

    getDataUriFromUrl(user.picture).then((result) =>
      setIcon(
        getSvgDataString(
          result.replace(':application/octet-stream;', ':image/png;'),
          statusColors[status]
        )
      )
    );
  }, [user.picture, status, getDataUriFromUrl]);

  useEffect(() => {
    setPosition({ lat, lng });
  }, [lat, lng]);

  useEffect(() => {
    infoWindow.setContent(getInfoWindowContent(user));
  }, [user, infoWindow]);

  useEffect(() => {
    return () => {
      infoWindow.close();
    };
  }, [infoWindow]);

  return (
    <Marker position={position} icon={icon} onClick={handleOpenInfoWIndow} />
  );
};

const GeolocationMap: React.FC<MapProps> = ({
  isOpen,
  apiKey,
  trackedUsers,
  children,
  mapRef,
  setMapRef,
}) => {
  const usersInfo = useOffice((state) => state.usersInfo);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
  });
  const [autoFitBounds, setAutoFitBounds] = useState(true);

  const onLoad = useCallback(
    (map: google.maps.Map<Element>) => {
      setMapRef(map);
    },
    [setMapRef]
  );

  useEffect(() => {
    if (!autoFitBounds) return;
    setAutoFitBounds(!fitBounds(mapRef, trackedUsers));
  }, [autoFitBounds, mapRef, trackedUsers]);

  useEffect(() => {
    setAutoFitBounds(isOpen);
  }, [isOpen]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      onLoad={onLoad}
    >
      {children}
      {trackedUsers.map((user) => (
        <UserMarker
          key={`marker-${user.id}`}
          user={user}
          status={usersInfo[user.id]?.status}
          map={mapRef}
        />
      ))}
    </GoogleMap>
  ) : (
    <CircularProgress />
  );
};

const GeolocationMapContainer: React.FC<ModalProps> = ({
  isOpen,
  onCloseRequest,
  children,
}) => {
  const { getGoogleApiJsApiKey } = useApi();
  const users = useModels((state) => state.users);
  const usersInfo = useOffice((state) => state.usersInfo);
  const [apiKey, setApiKey] = useState('');
  const [trackedUsers, setTrackedUsers] = useState<UserModel[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<UserModel[]>([]);
  const [mapRef, setMapRef] = useState<google.maps.Map<Element>>();

  const trackedUsersStr = JSON.stringify(
    users.reduce((res, item) => {
      if (!item.lastLoginAt) return res;
      if (item.accessRevokedAt) return res;
      if (!item.lastLocation) return res;

      return [
        ...res,
        {
          id: item.id,
          email: item.email,
          picture: item.picture,
          firstName: item.firstName,
          lastName: item.lastName,
          roleDescription: item.roleDescription,
          lastLocation: item.lastLocation,
        } as UserModel,
      ];
    }, [] as UserModel[])
  );

  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);

      const filtered = trackedUsers.filter((t) =>
        matchesSearchValue(`${t.firstName} ${t.lastName}`, value)
      );

      fitBounds(mapRef, filtered);
    },
    [trackedUsers, mapRef]
  );

  const clearSearchValue = useCallback(() => {
    if (searchInputRef.current) searchInputRef.current.value = '';
    setSearchValue('');
  }, []);

  useEffect(() => {
    const filtered = trackedUsers
      .filter((t) =>
        matchesSearchValue(`${t.firstName} ${t.lastName}`, searchValue)
      )
      .sort((a, b) => {
        return `${b.lastLocation?.timestamp} ${a.firstName} ${a.lastName}`
          .trim()
          .localeCompare(
            `${a.lastLocation?.timestamp} ${b.firstName} ${b.lastName}`.trim()
          );
      });

    setFilteredUsers(filtered);
  }, [trackedUsers, searchValue]);

  useEffect(() => {
    if (!isOpen) return;
    getGoogleApiJsApiKey().then(setApiKey);
  }, [isOpen, getGoogleApiJsApiKey]);

  useEffect(() => {
    if (isOpen) return;
    clearSearchValue();
  }, [isOpen, clearSearchValue]);

  useEffect(() => {
    setTrackedUsers(JSON.parse(trackedUsersStr));
  }, [trackedUsersStr]);

  return !apiKey ? (
    <CircularProgress />
  ) : (
    <ModalContainer isOpen={isOpen} onClickOutside={onCloseRequest}>
      <Container onClick={(e) => e.stopPropagation()}>
        <Header>
          <span />
          <IconButton iconSize={16} onClick={onCloseRequest}>
            <CloseBoxIcon />
          </IconButton>
        </Header>

        <Content>
          <div className="map">
            {!apiKey || !trackedUsers.length ? (
              <CircularProgress />
            ) : (
              <GeolocationMap
                isOpen={isOpen}
                apiKey={apiKey}
                trackedUsers={filteredUsers}
                mapRef={mapRef}
                setMapRef={setMapRef}
              >
                {children}
              </GeolocationMap>
            )}
          </div>

          <div className="user-list">
            <InputSearch onChange={handleSearch} ref={searchInputRef} />

            <div className="scroll-list">
              {filteredUsers.map((user) => (
                <UserContainer onClick={() => fitBounds(mapRef, [user])}>
                  <Avatar
                    id={user.id}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    picture={user.picture}
                    email={user.email}
                    roleDescription={user.roleDescription}
                    status={usersInfo[user.id]?.status || 'inactive'}
                    statusMessage={usersInfo[user.id]?.statusMessage}
                  />

                  <UserInfo>
                    <UserName>{getFormattedUserName(user)}</UserName>
                    <LocationTimestamp>
                      {new Date(
                        user.lastLocation?.timestamp || Date.now()
                      ).toLocaleString()}
                    </LocationTimestamp>
                  </UserInfo>
                </UserContainer>
              ))}
            </div>
          </div>
        </Content>
      </Container>
    </ModalContainer>
  );
};

export default React.memo(GeolocationMapContainer);
