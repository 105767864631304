import { styled, css } from '../../themes';

interface Props {
  sizeInPixels: number;
}

const DEFAULT_SIZE = 17;

export const Container = styled.div<Props>`
  ${(props) => {
    const scale = !props.sizeInPixels ? 1 : props.sizeInPixels / DEFAULT_SIZE;
    const scaledSize = DEFAULT_SIZE * scale;

    return css`
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: ${scaledSize}px;
      min-height: ${scaledSize}px;
      max-width: ${scaledSize}px;
      max-height: ${scaledSize}px;

      > .scale-container {
        position: relative;
        top: 0;
        left: 0;
        min-width: ${DEFAULT_SIZE}px;
        min-height: ${DEFAULT_SIZE}px;
        max-width: ${DEFAULT_SIZE}px;
        max-height: ${DEFAULT_SIZE}px;
        transform: scale(${scale});

        > svg {
          position: relative;
          width: ${DEFAULT_SIZE}px;
          height: ${DEFAULT_SIZE}px;
        }
      }
    `;
  }}
`;
