import React, { useMemo, useRef, useState, useCallback } from 'react';
import { getEmojiDataFromNative, EmojiData } from 'emoji-mart';
import emojisData from 'emoji-mart/data/apple.json';
import emojiRegex from 'emoji-regex';
import { useIntl } from 'react-intl';
import { Channel } from 'mattermost-redux/types/channels';
import { Post } from 'mattermost-redux/types/posts';

import {
  CommentInputContainer,
  CommentInputAvatar,
  CommentInputContent,
  CommentInput,
  CommentInputButtons,
} from './styles';
import { useMattermost } from '../../hooks/mattermost';
import { AppMessages } from '../../languages';
import { getFormattedUserName } from '../../hooks/office';
import { IconButton } from '../IconButton';
import { EmojiIcon, SendMessageIcon } from '../Icons';
import { OfficePostEmojiPicker } from './OfficePostEmojiPicker';

const NATIVE_EMOJI_PATTERN = emojiRegex();

interface Props {
  channel: Channel;
  post: Post;
  showComments: boolean;
  setShowComments: React.Dispatch<React.SetStateAction<boolean>>;
  scrollDown: boolean;
  setScrollDown: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OfficePostCommentInput: React.FC<Props> = ({
  channel,
  post,
  showComments,
  setShowComments,
  scrollDown,
  setScrollDown,
}) => {
  const inputRef = useRef<HTMLTextAreaElement>();
  const [value, setValue] = useState('');
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
  const [sending, setSending] = useState(false);

  const mattermostUserId = useMattermost(
    (state) => state.mattermostUser?.id || ''
  );

  const { formatMessage } = useIntl();
  const getProseiaUser = useMattermost((state) => state.getProseiaUser);
  const addComment = useMattermost((state) => state.addComment);

  const channelId = channel?.id || '';
  const user = getProseiaUser(mattermostUserId);
  const { firstName, lastName } = user;
  const displayName = useMemo(
    () => getFormattedUserName({ firstName, lastName }),
    [firstName, lastName]
  );

  const placeholder = formatMessage({
    id: AppMessages.officePostCommentInputPlaceholder,
  });

  const getImage = useCallback(() => {
    return <img alt={displayName} src={user.picture} width="32" height="32" />;
  }, [user.picture, displayName]);

  const addValue = useCallback((valueToAdd: string) => {
    if (!inputRef.current) return;
    const field = inputRef.current;
    const startPos = field.selectionStart;
    const endPos = field.selectionEnd;

    setValue(
      (state) =>
        `${state.substring(0, startPos)}${valueToAdd}${state.substring(
          endPos,
          state.length
        )}`
    );

    setTimeout(() => {
      if (!inputRef.current) return;
      inputRef.current.selectionStart = startPos + valueToAdd.length;
      inputRef.current.selectionEnd = startPos + valueToAdd.length;
      inputRef.current.focus();
    }, 15);
  }, []);

  const handleAddEmoji = useCallback(
    (emoji: EmojiData) => {
      const { native } = emoji as any;
      addValue(native || emoji.colons);
    },
    [addValue]
  );

  const handleSend = useCallback(() => {
    if (sending) return;

    if (!value) return;

    setSending(true);
    const stringValue = value.replace(NATIVE_EMOJI_PATTERN, (match) => {
      return getEmojiDataFromNative(match, 'apple', emojisData as any).colons;
    });

    addComment(channel, post.id, stringValue, [])
      .then(() => {
        // setEditing(false);
      }, console.error)
      .finally(() => {
        setSending(false);
        setValue('');
        if (!showComments) {
          setShowComments(true);
        } else if (!scrollDown) setScrollDown(true);
      });
  }, [
    sending,
    value,
    channel,
    setSending,
    addComment,
    post.id,
    showComments,
    setShowComments,
    scrollDown,
    setScrollDown,
  ]);

  return (
    <CommentInputContainer>
      <CommentInputAvatar>{getImage()}</CommentInputAvatar>
      <CommentInputContent>
        <CommentInput
          inputRef={inputRef}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          value={value}
          rowsMax={10}
          multiline
          disabled={sending}
        />

        <CommentInputButtons>
          <IconButton iconSize={20} onClick={() => setEmojiPickerOpen(true)}>
            <EmojiIcon />
          </IconButton>

          <IconButton
            iconSize={20}
            onClick={handleSend}
            disabled={!value || sending}
          >
            <SendMessageIcon />
          </IconButton>
        </CommentInputButtons>
      </CommentInputContent>

      <OfficePostEmojiPicker
        open={emojiPickerOpen}
        channelId={channelId}
        onClose={() => setEmojiPickerOpen(false)}
        onSelect={handleAddEmoji}
        type="comment-input"
      />
    </CommentInputContainer>
  );
};
