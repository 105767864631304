import React from 'react';
import { useIntl } from 'react-intl';

import InviteToMeetingGIF from '../../../assets/tutorials/invite-to-meeting.gif';
import MeetingPNG from '../../../assets/tutorials/meeting.png';
import { useTutorial } from '../../../hooks/tutorial';
import { AppMessages } from '../../../languages';

import {
  ContentContainer,
  ContentTitle,
  ContentLine,
  ContentImage,
  ContentButton,
} from '../styles';

export const InviteToMeetingTipContent: React.FC = () => {
  const closeInviteToMeetingTip = useTutorial(
    (state) => state.closeInviteToMeetingTip
  );
  const { formatMessage } = useIntl();

  return (
    <ContentContainer onClick={(e) => e.stopPropagation()}>
      <ContentTitle>
        {formatMessage({ id: AppMessages.tutorialInviteToMeetingTitle })}
      </ContentTitle>

      <ContentLine>
        {formatMessage({
          id: AppMessages.tutorialInviteToMeetingDescription1,
        })}
      </ContentLine>

      <ContentImage
        src={InviteToMeetingGIF}
        width="300"
        height="240"
        draggable={false}
      />

      <ContentLine>
        {formatMessage({
          id: AppMessages.tutorialInviteToMeetingDescription2,
        })}
      </ContentLine>

      <ContentImage src={MeetingPNG} width="300" draggable={false} />

      <ContentButton onClick={closeInviteToMeetingTip}>
        {formatMessage({ id: AppMessages.tutorialGotIt })}
      </ContentButton>
    </ContentContainer>
  );
};
