import { shade } from 'polished';
import { styled, css } from '../../themes';

interface ContainerProps {
  iconSize: number;
  iconColor?: string;
  buttonColor?: string;
  padding: number;
  disabled: boolean;
  pressed: boolean;
  pressedOutlined: boolean;
}

export const Container = styled.button<ContainerProps>`
  position: relative;
  background: ${(props) =>
    props.pressed && !props.pressedOutlined
      ? props.iconColor || props.theme.textColor
      : 'transparent'};
  border: ${(props) =>
    props.pressedOutlined
      ? `solid 2px ${
          props.pressed
            ? props.iconColor || props.theme.textColor
            : 'transparent'
        }`
      : 'none'};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.padding}px;
  color: ${(props) =>
    props.pressed && !props.pressedOutlined
      ? props.buttonColor || props.theme.background
      : props.iconColor || props.theme.textColor};
  transition: all 0.2s ease-out;
  border-radius: 5px;

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `}

  ${(props) =>
    !props.disabled &&
    css`
      :hover {
        background: ${props.pressed && !props.pressedOutlined
          ? shade(0.1, props.iconColor || props.theme.textColor)
          : shade(0.1, props.buttonColor || props.theme.boxColor)};

        > svg {
          transform: scale(1.2);
        }

        > span {
          transform: scale(1.2);
        }
      }
    `}

  > svg {
    width: ${(props) => props.iconSize}px;
    height: ${(props) => props.iconSize}px;
    font-size: ${(props) => props.iconSize}px;
    fill: ${(props) =>
      props.pressed && !props.pressedOutlined
        ? props.buttonColor || props.theme.background
        : props.iconColor || props.theme.textColor};
  }
`;
