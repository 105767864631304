import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Container, ChannelTypeOption, Button } from './styles';

import { useMattermost, TeamChannelType } from '../../hooks/mattermost';
import { AppMessages } from '../../languages';
import { ModalContainer } from '../ModalContainer';
import { IconButton } from '../IconButton';
import {
  CloseBoxIcon,
  ChatPublicChannelIcon,
  ChatPrivateChannelIcon,
} from '../Icons';

export const ChatNewChannel: React.FC = () => {
  const { formatMessage } = useIntl();
  const [name, setName] = useState('');
  const [type, setType] = useState<TeamChannelType>('O');

  const createNewChannelIsOpen = useMattermost(
    (state) => state.createNewChannelIsOpen
  );
  const closeCreateNewChannel = useMattermost(
    (state) => state.closeCreateNewChannel
  );
  const closeChannelExplorer = useMattermost(
    (state) => state.closeChannelExplorer
  );
  const createTeamChannel = useMattermost((state) => state.createTeamChannel);
  const viewChannel = useMattermost((state) => state.viewChannel);

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) setType(event.target.value as TeamChannelType);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await createTeamChannel(name, type).then((channel) => {
      viewChannel(channel, false);
    });
    closeCreateNewChannel();
    closeChannelExplorer();
  };

  useEffect(() => {
    setName('');
    setType('O');
  }, [createNewChannelIsOpen]);

  return (
    <ModalContainer
      isOpen={createNewChannelIsOpen}
      onClickOutside={closeCreateNewChannel}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <div className="header">
          <span>{formatMessage({ id: AppMessages.chatNewChannel })}</span>
          <IconButton iconSize={16} onClick={closeCreateNewChannel}>
            <CloseBoxIcon />
          </IconButton>
        </div>
        <form className="content" onSubmit={handleSubmit}>
          <input
            type="text"
            name="channel-name"
            placeholder={formatMessage({ id: AppMessages.chatChannelName })}
            autoComplete="off"
            value={name}
            onChange={handleChangeName}
          />
          <div className="channel-type">
            <ChannelTypeOption onClick={() => setType('O')}>
              <input
                name="channel-type"
                type="radio"
                value="O"
                checked={type === 'O'}
                onChange={handleTypeChange}
              />
              <label htmlFor="channel-type">
                <ChatPublicChannelIcon />
                &nbsp;
                {formatMessage({ id: AppMessages.chatPublicChannel })}
              </label>
            </ChannelTypeOption>

            <ChannelTypeOption onClick={() => setType('P')}>
              <input
                name="channel-type"
                type="radio"
                value="P"
                checked={type === 'P'}
                onChange={handleTypeChange}
              />
              <label htmlFor="channel-type">
                <ChatPrivateChannelIcon />
                &nbsp;
                {formatMessage({ id: AppMessages.chatPrivateChannel })}
              </label>
            </ChannelTypeOption>
          </div>

          <Button type="submit" disabled={name.length < 2}>
            {formatMessage({ id: AppMessages.chatCreateChannel })}
          </Button>
        </form>
      </Container>
    </ModalContainer>
  );
};
