import React, { useState, useCallback, useEffect, useRef } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { InputAdornment, IconButton } from '@material-ui/core';

import { AppMessages } from '../../languages';
import { PASSWORD_PATTERN, PHONE_NUMBER_PATTERN } from '../../utils/constants';
import { useToast } from '../../hooks/toast';
import { ProseiaPageContainer } from '../../components/ProseiaPageContainer';
import {
  TitleContainer,
  StyledInput,
  Button,
} from '../../components/ProseiaPageContainer/styles';

import { StepMessageContainer } from './styles';

interface RouteParams {
  encodedData?: string;
}

interface FormErrors {
  [key: string]: string;
}

const SigningUp: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const history = useHistory();
  const addToast = useToast((state) => state.addToast);
  const { formatMessage } = useIntl();

  const [email, setEmail] = useState('');
  const [subdomain, setSubdomain] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const passwordRef = useRef<HTMLInputElement>();
  const firstNameRef = useRef<HTMLInputElement>();
  const lastNameRef = useRef<HTMLInputElement>();
  const phoneNumberRef = useRef<HTMLInputElement>();

  const getPassword = useCallback(() => passwordRef.current?.value || '', []);
  const getFirstName = useCallback(
    () => firstNameRef.current?.value?.trim() || '',
    []
  );
  const getLastName = useCallback(
    () => lastNameRef.current?.value?.trim() || '',
    []
  );
  const getPhoneNumber = useCallback(
    () => phoneNumberRef.current?.value?.trim() || '',
    []
  );

  const passwordLabel = formatMessage({ id: AppMessages.pageSignInPassword });
  const firstNameLabel = formatMessage({ id: AppMessages.pageSignInFirstName });
  const lastNameLabel = formatMessage({ id: AppMessages.pageSignInLastName });
  const phoneNumberLabel = formatMessage({
    id: AppMessages.pageSignInPhoneNumber,
  });
  const submitLabel = formatMessage({
    id: AppMessages.pageSigningUpEnvironmentSubmit,
  });

  const requiredMessage = formatMessage({
    id: AppMessages.pageSignInValidationRequired,
  });
  const weakPasswordMessage = formatMessage({
    id: AppMessages.pageSignInValidationWeakPassword,
  });
  const invalidPhoneNumberMessage = formatMessage({
    id: AppMessages.pageSignInValidationInvalidPhoneNumber,
  });

  const stepMessage = formatMessage(
    { id: AppMessages.stepOf },
    { current: 2, total: 3 }
  );

  const isPasswordValid = useCallback(() => {
    const isValid = !!getPassword().match(PASSWORD_PATTERN);

    if (!isValid) {
      setFormErrors((state) => ({ ...state, password: weakPasswordMessage }));
    }

    return isValid;
  }, [getPassword, weakPasswordMessage]);

  const isPhoneNumberValid = useCallback(() => {
    const isValid = !!getPhoneNumber().match(PHONE_NUMBER_PATTERN);

    if (!isValid) {
      setFormErrors((state) => ({
        ...state,
        phoneNumber: invalidPhoneNumberMessage,
      }));
    }

    return isValid;
  }, [getPhoneNumber, invalidPhoneNumberMessage]);

  const canSubmit = useCallback(() => {
    if (!email.trim() || !getFirstName()) return false;
    if (!getPhoneNumber() || !isPhoneNumberValid()) return false;
    if (!getPassword() || !isPasswordValid()) return false;
    return true;
  }, [
    email,
    getPassword,
    isPasswordValid,
    getFirstName,
    getPhoneNumber,
    isPhoneNumberValid,
  ]);

  const handlePasswordBlur = useCallback(() => {
    if (!getPassword()) {
      setFormErrors((state) => ({ ...state, password: requiredMessage }));
      return;
    }

    isPasswordValid();
  }, [getPassword, isPasswordValid, requiredMessage]);

  const handlePasswordChange = useCallback(() => {
    setFormErrors((state) => ({ ...state, password: '', credentials: '' }));
  }, []);

  const handleFirstNameBlur = useCallback(() => {
    if (!getFirstName()) {
      setFormErrors((state) => ({ ...state, firstName: requiredMessage }));
    }
  }, [getFirstName, requiredMessage]);

  const handleFirstNameChange = useCallback(() => {
    setFormErrors((state) => ({ ...state, firstName: '' }));
  }, []);

  const handlePhoneNumberBlur = useCallback(() => {
    if (!getPhoneNumber()) {
      setFormErrors((state) => ({ ...state, phoneNumber: requiredMessage }));
      return;
    }

    isPhoneNumberValid();
  }, [getPhoneNumber, isPhoneNumberValid, requiredMessage]);

  const handlePhoneNumberChange = useCallback(() => {
    setFormErrors((state) => ({ ...state, phoneNumber: '' }));
  }, []);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((state) => !state);
  }, []);

  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    []
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!canSubmit()) return;

      const payload = {
        subdomain,
        email,
        firstName: getFirstName(),
        lastName: getLastName(),
        phoneNumber: getPhoneNumber(),
        password: getPassword(),
      };

      const token = btoa(JSON.stringify(payload));

      history.push(`/setting-up/${token}`);
    },
    [
      email,
      subdomain,
      history,
      canSubmit,
      getPassword,
      getFirstName,
      getLastName,
      getPhoneNumber,
    ]
  );

  useEffect(() => {
    const encodedData = match.params.encodedData || '';
    if (!encodedData) return;
    try {
      const dataString = atob(encodedData);
      const data = JSON.parse(dataString);
      setEmail(data.email);
      setSubdomain(data.subdomain);
    } catch (error) {
      setEmail('');
      setSubdomain('');
      addToast({
        type: 'error',
        title: 'Error',
        description: 'Invalid token',
        duration: 10000,
      });
    }
  }, [match.params.encodedData, addToast]);

  return (
    <ProseiaPageContainer>
      <form onSubmit={handleSubmit}>
        <TitleContainer>
          {formatMessage({
            id: AppMessages.pageSigningUpEnvironmentTitle,
          })}
        </TitleContainer>

        <br />

        <br />

        <br />

        <div className="inline-fields">
          <StyledInput
            required
            name="firstName"
            autoFocus
            inputRef={firstNameRef}
            label={firstNameLabel}
            onChange={handleFirstNameChange}
            onBlur={handleFirstNameBlur}
            error={!!formErrors.firstName}
            helperText={formErrors.firstName}
          />

          <StyledInput
            name="lastName"
            inputRef={lastNameRef}
            label={lastNameLabel}
          />
        </div>

        <div className="inline-fields">
          <StyledInput
            required
            name="phoneNumber"
            inputRef={phoneNumberRef}
            label={phoneNumberLabel}
            onChange={handlePhoneNumberChange}
            onBlur={handlePhoneNumberBlur}
            error={!!formErrors.phoneNumber}
            helperText={formErrors.phoneNumber}
          />

          <StyledInput
            required
            name="password"
            autoComplete="new-password"
            inputRef={passwordRef}
            label={passwordLabel}
            type={showPassword ? 'text' : 'password'}
            onChange={handlePasswordChange}
            onBlur={handlePasswordBlur}
            error={!!formErrors.password || !!formErrors.credentials}
            helperText={formErrors.password || formErrors.credentials}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <br />

        <br />

        <Button type="submit">{submitLabel}</Button>

        <br />

        <StepMessageContainer align="center">
          {stepMessage}
        </StepMessageContainer>
      </form>
    </ProseiaPageContainer>
  );
};

export default SigningUp;
