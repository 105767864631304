import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { EMAIL_PATTERN, PASSWORD_PATTERN } from '../../utils/constants';
import { AppMessages } from '../../languages';
import { useQueryParams } from '../../hooks/queryParams';
import { useApi } from '../../hooks/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
// import { GoogleSignInButton } from '../../components/GoogleSignInButton';
import { SwitchButton } from '../../components/SwitchButton';
import { ProseiaPageContainer } from '../../components/ProseiaPageContainer';
import {
  StyledInput,
  Button,
  LinkButton,
  MessageContainer,
} from '../../components/ProseiaPageContainer/styles';
import { ExtensionIcon } from '../../components/Icons';
import logoExtensionRequired from '../../assets/images/sad-proseia-extension.svg';
import { CHROME_EXTENSION_LINK } from '../../utils/browser';

import {
  ExtensionRequiredContainer,
  ExtensionRequiredLinks,
  ExtensionRequiredLinksContainer,
  ExtensionRequiredTitleContainer,
} from './styles';

interface FormErrors {
  [key: string]: string;
}

interface RouteParams {
  token?: string;
  encodedEmail?: string;
}

const SignIn: React.FC<RouteComponentProps<RouteParams>> = ({ match }) => {
  const queryParams = useQueryParams();
  const addToast = useToast((state) => state.addToast);

  const { getAllowProfileEditingValue } = useApi();

  const isPasswordStored = useAuth((state) => state.isPasswordStored);
  const signIn = useAuth((state) => state.signIn);
  const signUp = useAuth((state) => state.signUp);
  const firstSignIn = useAuth((state) => state.firstSignIn);
  const forgotPassword = useAuth((state) => state.forgotPassword);
  const resetPassword = useAuth((state) => state.resetPassword);
  const validateToken = useAuth((state) => state.validateToken);
  const getTokenData = useAuth((state) => state.getTokenData);
  const keepConnected = useAuth((state) => state.keepConnected);
  const setKeepConnected = useAuth((state) => state.setKeepConnected);
  const extensionIsRequired = useAuth((state) => state.extensionIsRequired);

  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [submitCallback, setSubmitCallback] = useState<() => Promise<void>>();
  const [isSignup, setIsSignup] = useState(false);
  const [userRegistered, setUserRegistered] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [profileEditingAllowed, setProfileEditingAllowed] = useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const emailRef = useRef<HTMLInputElement>();
  const passwordRef = useRef<HTMLInputElement>();
  const confirmPasswordRef = useRef<HTMLInputElement>();
  const firstNameRef = useRef<HTMLInputElement>();
  const lastNameRef = useRef<HTMLInputElement>();
  const roleDescriptionRef = useRef<HTMLInputElement>();
  const phoneNumberRef = useRef<HTMLInputElement>();
  const companyRef = useRef<HTMLInputElement>();

  const getEmail = useCallback(() => emailRef.current?.value?.trim() || '', []);
  const getPassword = useCallback(() => passwordRef.current?.value || '', []);
  const getConfirmPassword = useCallback(
    () => confirmPasswordRef.current?.value || '',
    []
  );
  const getFirstName = useCallback(
    () => firstNameRef.current?.value?.trim() || '',
    []
  );
  const getLastName = useCallback(
    () => lastNameRef.current?.value?.trim() || '',
    []
  );
  const getRoleDescription = useCallback(
    () => roleDescriptionRef.current?.value?.trim() || '',
    []
  );
  const getPhoneNumber = useCallback(
    () => phoneNumberRef.current?.value?.trim() || '',
    []
  );
  const getCompany = useCallback(
    () => companyRef.current?.value?.trim() || '',
    []
  );

  // const signInWithGoogle = useCallback(() => {
  //   window.location.href = `/auth/google/login`;
  // }, []);

  const emailLabel = formatMessage({ id: AppMessages.pageSignInEmail });
  const passwordLabel = formatMessage({ id: AppMessages.pageSignInPassword });
  const confirmPasswordLabel = formatMessage({
    id: AppMessages.pageSignInConfirmPassword,
  });
  const firstNameLabel = formatMessage({ id: AppMessages.pageSignInFirstName });
  const lastNameLabel = formatMessage({ id: AppMessages.pageSignInLastName });
  const roleDescriptionLabel = formatMessage({
    id: AppMessages.pageSignInRoleDescription,
  });
  const phoneNumberLabel = formatMessage({
    id: AppMessages.pageSignInPhoneNumber,
  });
  const companyLabel = formatMessage({
    id: AppMessages.pageSignInCompany,
  });
  const submitLabel = formatMessage({ id: AppMessages.pageSignInSubmit });
  const forgotPasswordLabel = formatMessage({
    id: AppMessages.pageSignInForgotPassword,
  });

  const requiredMessage = formatMessage({
    id: AppMessages.pageSignInValidationRequired,
  });
  const invalidEmailMessage = formatMessage({
    id: AppMessages.pageSignInValidationInvalidEmail,
  });
  const weakPasswordMessage = formatMessage({
    id: AppMessages.pageSignInValidationWeakPassword,
  });
  const doesNotMatchMessage = formatMessage({
    id: AppMessages.pageSignInValidationDoesNotMatch,
  });
  const invalidCredentialsMessage = formatMessage({
    id: AppMessages.pageSignInValidationInvalidCredentials,
  });
  const attentionMessage = formatMessage({
    id: AppMessages.attention,
  });
  const incompleteRegistrationMessage = formatMessage({
    id: AppMessages.pageSignInValidationIncompleteRegistration,
  });

  const isEmailValid = useCallback(() => !!getEmail().match(EMAIL_PATTERN), [
    getEmail,
  ]);
  const isPasswordValid = useCallback(
    () =>
      !!getPassword().match(PASSWORD_PATTERN) || (!isFirstLogin && !isSignup),
    [getPassword, isFirstLogin, isSignup]
  );

  const canSubmit = useCallback(() => {
    if (!getEmail() || !getPassword()) return false;

    if (!isEmailValid() || !isPasswordValid()) return false;

    if (!isFirstLogin && !isSignup) return true;

    if (!getConfirmPassword() || getPassword() !== getConfirmPassword())
      return false;

    if (isFirstLogin && !profileEditingAllowed) return true;

    return (
      getFirstName() && !(isSignup && (!getCompany() || !getPhoneNumber()))
    );
  }, [
    isSignup,
    isFirstLogin,
    profileEditingAllowed,
    getEmail,
    getPassword,
    getConfirmPassword,
    getFirstName,
    getCompany,
    getPhoneNumber,
    isEmailValid,
    isPasswordValid,
  ]);

  const handleEmailBlur = useCallback(async () => {
    if (!getEmail()) {
      setFormErrors((state) => ({ ...state, email: requiredMessage }));
      setUserRegistered(false);
      setIsFirstLogin(false);
      return;
    }

    if (!isEmailValid()) {
      setFormErrors((state) => ({ ...state, email: invalidEmailMessage }));
      return;
    }

    setCheckingEmail(true);

    const passwordAlreadyStored = await isPasswordStored(getEmail()).catch(
      () => undefined
    );

    if (!passwordAlreadyStored) {
      const canEditProfile = await getAllowProfileEditingValue().catch(
        () => true
      );
      setProfileEditingAllowed(canEditProfile);
    }

    setUserRegistered(passwordAlreadyStored !== undefined);
    setIsFirstLogin(passwordAlreadyStored === false);
    setCheckingEmail(false);
  }, [
    getEmail,
    isEmailValid,
    isPasswordStored,
    getAllowProfileEditingValue,
    requiredMessage,
    invalidEmailMessage,
  ]);

  const handleEmailChange = useCallback(() => {
    setFormErrors((state) => ({ ...state, email: '', credentials: '' }));
  }, []);

  const handlePasswordBlur = useCallback(() => {
    if (!getPassword()) {
      setFormErrors((state) => ({ ...state, password: requiredMessage }));
      return;
    }
    if (!isPasswordValid()) {
      setFormErrors((state) => ({ ...state, password: weakPasswordMessage }));
    }
  }, [getPassword, isPasswordValid, requiredMessage, weakPasswordMessage]);

  const handlePasswordChange = useCallback(() => {
    setFormErrors((state) => ({ ...state, password: '', credentials: '' }));
  }, []);

  const handleConfirmPasswordBlur = useCallback(() => {
    if (!getConfirmPassword())
      setFormErrors((state) => ({
        ...state,
        confirmPassword: requiredMessage,
      }));
  }, [getConfirmPassword, requiredMessage]);

  const handleConfirmPasswordChange = useCallback(() => {
    const confirmPassword = getConfirmPassword();

    if (!!confirmPassword && confirmPassword !== getPassword()) {
      setFormErrors((state) => ({
        ...state,
        confirmPassword: doesNotMatchMessage,
      }));
      return;
    }

    setFormErrors((state) => ({ ...state, confirmPassword: '' }));
  }, [getPassword, getConfirmPassword, doesNotMatchMessage]);

  const handleFirstNameBlur = useCallback(() => {
    if (!getFirstName()) {
      setFormErrors((state) => ({ ...state, firstName: requiredMessage }));
    }
  }, [getFirstName, requiredMessage]);

  const handleFirstNameChange = useCallback(() => {
    setFormErrors((state) => ({ ...state, firstName: '' }));
  }, []);

  const handlePhoneNumberBlur = useCallback(() => {
    if (!getPhoneNumber()) {
      setFormErrors((state) => ({ ...state, phoneNumber: requiredMessage }));
    }
  }, [getPhoneNumber, requiredMessage]);

  const handlePhoneNumberChange = useCallback(() => {
    setFormErrors((state) => ({ ...state, phoneNumber: '' }));
  }, []);

  const handleCompanyBlur = useCallback(() => {
    if (!getCompany()) {
      setFormErrors((state) => ({ ...state, company: requiredMessage }));
    }
  }, [getCompany, requiredMessage]);

  const handleCompanyChange = useCallback(() => {
    setFormErrors((state) => ({ ...state, company: '' }));
  }, []);

  const handleSignInError = useCallback(
    (error) => {
      if (error.message === 'Incomplete registration') {
        addToast({
          type: 'error',
          title: attentionMessage,
          description: incompleteRegistrationMessage,
          duration: 15000,
        });
      } else if (error.message === 'Invalid credentials') {
        setFormErrors((state) => ({
          ...state,
          credentials: invalidCredentialsMessage,
        }));
      } else {
        const message = Array.isArray(error.message)
          ? error.message.join('\n')
          : error.message;
        addToast({
          type: 'error',
          title: 'Error',
          description: message,
          duration: 10000,
        });
      }

      setLoading(false);
    },
    [
      addToast,
      attentionMessage,
      invalidCredentialsMessage,
      incompleteRegistrationMessage,
    ]
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!canSubmit()) return;

      setLoading(true);

      setSubmitCallback(() => {
        return () => {
          let promise: Promise<void>;

          if (isSignup) {
            promise = signUp({
              email: getEmail(),
              password: getPassword(),
              firstName: getFirstName(),
              lastName: getLastName(),
              phoneNumber: getPhoneNumber(),
              company: getCompany(),
              origin: queryParams.get('origin') || undefined,
            });
          } else if (isFirstLogin) {
            promise = firstSignIn({
              email: getEmail(),
              password: getPassword(),
              firstName: getFirstName(),
              lastName: getLastName(),
              roleDescription: getRoleDescription(),
            });
          } else {
            promise = signIn({ email: getEmail(), password: getPassword() });
          }

          return promise;
        };
      });
    },
    [
      isSignup,
      queryParams,
      isFirstLogin,
      canSubmit,
      signUp,
      firstSignIn,
      signIn,
      getEmail,
      getPassword,
      getFirstName,
      getLastName,
      getRoleDescription,
      getPhoneNumber,
      getCompany,
    ]
  );

  const handleForgotPassword = useCallback(() => {
    if (!getEmail() || !isEmailValid()) {
      setFormErrors((state) => ({
        ...state,
        email: requiredMessage,
        password: '',
      }));
      return;
    }

    // eslint-disable-next-line no-restricted-globals
    const sendLink = confirm(
      formatMessage(
        { id: AppMessages.pageSignInForgotPasswordConfirm },
        { email: getEmail() }
      )
    );

    if (!sendLink) return;

    setLoading(true);
    forgotPassword(getEmail())
      .then(
        (success) => {
          if (success)
            alert(
              formatMessage({
                id: AppMessages.pageSignInForgotPasswordLinkSended,
              })
            );
        },
        (error) =>
          addToast({
            type: 'error',
            title: 'Error',
            description: error.message,
          })
      )
      .finally(() => setLoading(false));
  }, [
    getEmail,
    isEmailValid,
    forgotPassword,
    formatMessage,
    addToast,
    requiredMessage,
  ]);

  useEffect(() => {
    if (checkingEmail || !loading || !submitCallback) return;

    submitCallback().then(
      () => {
        setSubmitCallback(undefined);
        setLoading(false);
      },
      (error) => {
        setSubmitCallback(undefined);
        handleSignInError(error);
      }
    );
  }, [checkingEmail, loading, submitCallback, handleSignInError]);

  useEffect(() => {
    const token = match.params.token || '';
    if (!validateToken(token)) return;
    const tokenData = getTokenData(token);
    setLoading(true);
    resetPassword(tokenData.id, token)
      .then(async (success) => {
        if (success) {
          const passwordAlreadyStored = await isPasswordStored(
            tokenData.email
          ).catch(() => undefined);
          setUserRegistered(passwordAlreadyStored !== undefined);
          setIsFirstLogin(passwordAlreadyStored === false);
        }
      })
      .finally(() => setLoading(false));
  }, [
    match.params.token,
    validateToken,
    getTokenData,
    resetPassword,
    isPasswordStored,
  ]);

  useEffect(() => {
    setIsSignup(match.path === '/try-it' && !userRegistered);
  }, [match.path, userRegistered]);

  useEffect(() => {
    const token = match.params.token || '';
    if (!isFirstLogin || !validateToken(token)) return;
    const tokenData = getTokenData(token);
    if (emailRef.current) emailRef.current.value = tokenData.email || '';
    if (passwordRef.current) {
      passwordRef.current.value = '';
      passwordRef.current.focus();
    }
    if (firstNameRef.current)
      firstNameRef.current.value = tokenData.firstName || '';
    if (lastNameRef.current)
      lastNameRef.current.value = tokenData.lastName || '';
    if (roleDescriptionRef.current)
      roleDescriptionRef.current.value = tokenData.roleDescription || '';
  }, [match.params.token, isFirstLogin, validateToken, getTokenData]);

  useEffect(() => {
    const encodedEmail = match.params.encodedEmail || '';
    if (!encodedEmail) return;
    try {
      const email = atob(encodedEmail);
      if (emailRef.current) emailRef.current.value = email;
      if (passwordRef.current) {
        passwordRef.current.value = '';
        passwordRef.current.focus();
      }
    } catch (error) {
      addToast({ type: 'error', title: 'Error', description: 'Invalid token' });
    }
  }, [match.params.encodedEmail, addToast]);

  const welcomeMessage = useMemo(
    () => (
      <MessageContainer align="center">
        {formatMessage({ id: AppMessages.pageSignInWelcome })}
      </MessageContainer>
    ),
    [formatMessage]
  );

  const emailInput = useMemo(
    () => (
      <StyledInput
        required
        name="email"
        inputRef={emailRef}
        autoFocus
        label={emailLabel}
        onChange={handleEmailChange}
        onBlur={handleEmailBlur}
        error={!!formErrors.email || !!formErrors.credentials}
        helperText={formErrors.email || formErrors.credentials}
      />
    ),
    [
      emailLabel,
      formErrors.credentials,
      formErrors.email,
      handleEmailBlur,
      handleEmailChange,
    ]
  );

  const passwordInput = useMemo(
    () => (
      <>
        <div className="inline-fields">
          <StyledInput
            required
            name="password"
            autoComplete={isFirstLogin || isSignup ? 'new-password' : undefined}
            inputRef={passwordRef}
            label={passwordLabel}
            type="password"
            onChange={handlePasswordChange}
            onBlur={handlePasswordBlur}
            error={!!formErrors.password || !!formErrors.credentials}
            helperText={formErrors.password || formErrors.credentials}
          />

          {(isFirstLogin || isSignup) && profileEditingAllowed && (
            <StyledInput
              required
              inputRef={confirmPasswordRef}
              label={confirmPasswordLabel}
              type="password"
              onChange={handleConfirmPasswordChange}
              onBlur={handleConfirmPasswordBlur}
              error={!!formErrors.confirmPassword}
              helperText={formErrors.confirmPassword}
            />
          )}
        </div>

        {(isFirstLogin || isSignup) && !profileEditingAllowed && (
          <StyledInput
            required
            inputRef={confirmPasswordRef}
            label={confirmPasswordLabel}
            type="password"
            onChange={handleConfirmPasswordChange}
            onBlur={handleConfirmPasswordBlur}
            error={!!formErrors.confirmPassword}
            helperText={formErrors.confirmPassword}
          />
        )}
      </>
    ),
    [
      confirmPasswordLabel,
      formErrors.confirmPassword,
      formErrors.credentials,
      formErrors.password,
      handleConfirmPasswordBlur,
      handleConfirmPasswordChange,
      handlePasswordBlur,
      handlePasswordChange,
      isFirstLogin,
      isSignup,
      passwordLabel,
      profileEditingAllowed,
    ]
  );

  const nameInput = useMemo(
    () =>
      (isFirstLogin || isSignup) &&
      profileEditingAllowed && (
        <div className="inline-fields">
          <StyledInput
            required
            name="firstName"
            inputRef={firstNameRef}
            label={firstNameLabel}
            onChange={handleFirstNameChange}
            onBlur={handleFirstNameBlur}
            error={!!formErrors.firstName}
            helperText={formErrors.firstName}
          />

          <StyledInput
            name="lastName"
            inputRef={lastNameRef}
            label={lastNameLabel}
          />
        </div>
      ),
    [
      firstNameLabel,
      formErrors.firstName,
      handleFirstNameBlur,
      handleFirstNameChange,
      isFirstLogin,
      isSignup,
      lastNameLabel,
      profileEditingAllowed,
    ]
  );

  const signupInputs = useMemo(
    () =>
      isSignup && (
        <>
          <StyledInput
            required
            name="phoneNumber"
            inputRef={phoneNumberRef}
            label={phoneNumberLabel}
            onChange={handlePhoneNumberChange}
            onBlur={handlePhoneNumberBlur}
            error={!!formErrors.phoneNumber}
            helperText={formErrors.phoneNumber}
          />

          <StyledInput
            required
            name="company"
            inputRef={companyRef}
            label={companyLabel}
            onChange={handleCompanyChange}
            onBlur={handleCompanyBlur}
            error={!!formErrors.company}
            helperText={formErrors.company}
          />
        </>
      ),
    [
      companyLabel,
      formErrors.company,
      formErrors.phoneNumber,
      handleCompanyBlur,
      handleCompanyChange,
      handlePhoneNumberBlur,
      handlePhoneNumberChange,
      isSignup,
      phoneNumberLabel,
    ]
  );

  const roleDescriptionInput = useMemo(
    () =>
      isFirstLogin &&
      profileEditingAllowed && (
        <StyledInput
          name="roleDescription"
          inputRef={roleDescriptionRef}
          label={roleDescriptionLabel}
        />
      ),
    [isFirstLogin, profileEditingAllowed, roleDescriptionLabel]
  );

  const keepConnectedContainer = useMemo(
    () => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '40px', height: '20px', marginRight: '10px' }}>
          <SwitchButton
            isOn={keepConnected}
            handleToggle={() => setKeepConnected((state) => !state)}
          />
        </div>

        <br />

        <MessageContainer>
          {formatMessage({ id: AppMessages.pageSignInKeepConnected })}
        </MessageContainer>
      </div>
    ),
    [formatMessage, keepConnected, setKeepConnected]
  );

  const submitButton = useMemo(
    () => <Button type="submit">{submitLabel}</Button>,
    [submitLabel]
  );

  const forgotPasswordLink = useMemo(
    () =>
      !(isFirstLogin || isSignup) && (
        <LinkButton onClick={handleForgotPassword}>
          {forgotPasswordLabel}
        </LinkButton>
      ),
    [forgotPasswordLabel, handleForgotPassword, isFirstLogin, isSignup]
  );

  const formContainer = useMemo(
    () => (
      <>
        <form onSubmit={handleSubmit}>
          {welcomeMessage}
          <br />
          <br />
          <br />
          {emailInput}
          {passwordInput}
          {nameInput}
          {signupInputs}
          {roleDescriptionInput}
          {keepConnectedContainer}
          {submitButton}
        </form>

        {forgotPasswordLink}
        {/* <GoogleSignInButton onClick={signInWithGoogle} /> */}
      </>
    ),
    [
      emailInput,
      forgotPasswordLink,
      handleSubmit,
      keepConnectedContainer,
      nameInput,
      passwordInput,
      roleDescriptionInput,
      signupInputs,
      submitButton,
      welcomeMessage,
    ]
  );

  const extensionRequired = useMemo(
    () => (
      <ExtensionRequiredContainer>
        <ExtensionRequiredTitleContainer>
          {formatMessage({
            id: AppMessages.extensionRequiredTitle,
          })}
        </ExtensionRequiredTitleContainer>
        <img alt="Extension Required" src={logoExtensionRequired} />
        <MessageContainer align="center">
          {formatMessage({
            id: AppMessages.extensionRequiredDescription,
          })}
        </MessageContainer>
        <ExtensionRequiredLinksContainer>
          <ExtensionRequiredLinks href={CHROME_EXTENSION_LINK} target="_blank">
            <ExtensionIcon />
            {formatMessage({
              id: AppMessages.extensionRequiredButtonText,
            })}
          </ExtensionRequiredLinks>
        </ExtensionRequiredLinksContainer>
      </ExtensionRequiredContainer>
    ),
    [formatMessage]
  );

  return (
    <ProseiaPageContainer loading={loading}>
      {extensionIsRequired ? extensionRequired : formContainer}
    </ProseiaPageContainer>
  );
};

export default SignIn;
