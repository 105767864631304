import React, { useMemo } from 'react';
import { PostEmbed } from 'mattermost-redux/types/posts';

import { ChatPostLinkPreviewContainer } from './styles';
import { ChatPostImage } from './ChatPostImage';

interface Props {
  embed: PostEmbed;
  onImgResize?: (width: number, height: number) => void;
}

export const ChatPostLinkPreview: React.FC<Props> = React.memo(
  ({ embed, onImgResize }) => {
    const { type, url, data } = embed;
    const dataTitle = data?.title || '';
    const dataDescription = data?.description || '';
    const dataImages = data?.images || [];

    const title = useMemo(
      () => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <h4>{dataTitle}</h4>
        </a>
      ),
      [dataTitle, url]
    );

    const description = useMemo(() => <h5>{dataDescription}</h5>, [
      dataDescription,
    ]);

    const images = useMemo(
      () =>
        dataImages &&
        dataImages.map((image: any) => (
          <ChatPostImage
            key={image.secure_url || image.url}
            src={image.secure_url || image.url}
            onResize={onImgResize}
          />
        )),
      [dataImages, onImgResize]
    );

    const content = useMemo(
      () =>
        type === 'opengraph' && (
          <>
            {title}
            {description}
            {images}
          </>
        ),
      [type, title, description, images]
    );

    return (
      <ChatPostLinkPreviewContainer>{content}</ChatPostLinkPreviewContainer>
    );
  }
);
