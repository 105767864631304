import React, { useState, useEffect } from 'react';

import { StyledTooltip } from './styles';

export type TooltipPosition =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';
export type TooltipTextAlign = 'center' | 'left' | 'right';
export type TooltipArrowPosition = 'center' | 'start' | 'end';

interface Props {
  text: string;
  position?: TooltipPosition;
  minWidth?: number;
  maxWidth?: number;
  textAlign?: TooltipTextAlign;
}

const fragment = <></>;

export const Tooltip: React.FC<Props> = React.memo(
  ({
    text,
    position = 'top',
    minWidth = 100,
    maxWidth = 350,
    textAlign = 'center',
  }) => {
    const [tooltipContent, setTooltipContent] = useState(fragment);

    useEffect(() => {
      setTooltipContent(
        <div
          style={{
            textAlign,
            minWidth,
            maxWidth,
          }}
        >
          {text.split('\n').reduce((previous, line) => {
            // eslint-disable-next-line eqeqeq
            const notFirst = previous != fragment;

            return (
              <>
                {previous}
                {notFirst && <br />}
                {line}
              </>
            );
          }, fragment)}
        </div>
      );
    }, [text, textAlign, minWidth, maxWidth]);

    return (
      <StyledTooltip
        title={tooltipContent}
        arrow
        placement={position}
        style={{ color: 'red' }}
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'none',
          }}
        />
      </StyledTooltip>
    );
  }
);
