import React, { useCallback, useMemo } from 'react';

import favicon from '../assets/favicon.ico';
import { AppMessages } from '../languages';
import { createContext, useContextSelector } from './context';
import { useLanguage } from './language';
import { usePersistentState } from './persistentState';
import { useToast } from './toast';

interface NotificationProps {
  title: string;
  body: string;
  silent?: boolean;
  onClick?: () => void;
}

interface NotificationContextData {
  isNotificationsEnable: () => boolean;
  enableNotifications: () => Promise<void>;
  disableNotifications: () => void;
  addNotification: (data: NotificationProps) => void;
}

const NotificationContext = createContext({} as NotificationContextData);

export const NotificationProvider: React.FC = ({ children }) => {
  const [enabled, setEnabled] = usePersistentState(
    'notificationsEnabled',
    false
  );
  const addToast = useToast((state) => state.addToast);
  const messages = useLanguage((state) => state.messages);

  const isNotificationsEnable = useCallback(() => {
    return enabled && Notification.permission === 'granted';
  }, [enabled]);

  const enableNotifications = useCallback(async () => {
    if (!Notification) {
      addToast({
        type: 'error',
        title: messages[AppMessages.noSupport],
        description: messages[AppMessages.notificationsNoSupport],
      });
    } else {
      switch (Notification.permission) {
        case 'denied':
          addToast({
            type: 'error',
            title: messages[AppMessages.permissionDenied],
            description: messages[AppMessages.notificationsPermissionRequired],
          });
          break;
        case 'granted':
          setEnabled(true);
          break;
        default:
          setEnabled((await Notification.requestPermission()) === 'granted');
      }
    }
  }, [addToast, setEnabled, messages]);

  const disableNotifications = useCallback(() => {
    setEnabled(false);
  }, [setEnabled]);

  const addNotification = useCallback(
    (data: NotificationProps) => {
      if (!isNotificationsEnable()) return;

      const { title, body, silent, onClick } = data;
      const notification = new Notification(title, {
        body,
        silent,
        icon: favicon,
      });

      notification.onclick = (e) => {
        e.preventDefault();
        window.focus();
        if (onClick) onClick();
        notification.close();
      };
    },
    [isNotificationsEnable]
  );

  const contextValue = useMemo<NotificationContextData>(
    () => ({
      isNotificationsEnable,
      enableNotifications,
      disableNotifications,
      addNotification,
    }),
    [
      isNotificationsEnable,
      enableNotifications,
      disableNotifications,
      addNotification,
    ]
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotification<TResult>(
  selector: (state: NotificationContextData) => TResult
): TResult {
  return useContextSelector(NotificationContext, selector);
}
