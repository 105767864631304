import { Select } from '@material-ui/core';
import { styled } from '../../themes';

export const Container = styled(Select)`
  && {
    .MuiInputBase-input {
      padding: 0px 0 7px;
      color: ${(props) => props.theme.textColor};
    }
    svg.MuiSelect-icon {
      fill: ${(props) => props.theme.textColor};
    }
  }
`;
