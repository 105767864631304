import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Container } from './styles';
import { AppMessages } from '../../languages';

export interface MultiSelectOption<T = any> {
  label: string;
  value: T;
}

interface Props {
  placeholder?: string;
  options: MultiSelectOption[];
  selectedOptions: MultiSelectOption[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<MultiSelectOption[]>>;
}

export const StyledMultiSelect: React.FC<Props> = ({
  placeholder = '',
  options,
  selectedOptions,
  setSelectedOptions,
}) => {
  const { formatMessage } = useIntl();
  const [all, setAll] = useState<MultiSelectOption>({
    label: 'All',
    value: '*',
  });
  const [selectedLabel, setSelectedLabel] = useState<string>('selected');

  const selectAll = useCallback(() => {
    setSelectedOptions([all, ...options]);
  }, [options, all, setSelectedOptions]);

  const getDropdownButtonLabel = useCallback(
    (event: { value: any[] }) => {
      if (event.value && event.value.some((o) => o.value === all.value)) {
        return `${placeholder}: ${all.label}`;
      }
      return `${placeholder}: ${event.value.length} ${selectedLabel}`;
    },
    [placeholder, all, selectedLabel]
  );

  const onChange = useCallback(
    (value: any[], event: any) => {
      if (
        event.action === 'select-option' &&
        event.option.value === all.value
      ) {
        selectAll();
      } else if (
        event.action === 'deselect-option' &&
        event.option.value === all.value
      ) {
        setSelectedOptions([]);
      } else if (event.action === 'deselect-option') {
        setSelectedOptions(value.filter((o) => o.value !== all.value));
      } else if (value.length === options.length) {
        selectAll();
      } else {
        setSelectedOptions(value);
      }
    },
    [all, options, selectAll, setSelectedOptions]
  );

  useEffect(() => {
    selectAll();
  }, [selectAll]);

  useEffect(() => {
    setAll({
      label: formatMessage({ id: AppMessages.componentStyledMultiSelectAll }),
      value: '*',
    });
    setSelectedLabel(
      formatMessage({ id: AppMessages.componentStyledMultiSelectSelected })
    );
  }, [formatMessage]);

  return (
    <Container
      options={[all, ...options]}
      placeholderButtonLabel={placeholder}
      getDropdownButtonLabel={getDropdownButtonLabel}
      value={selectedOptions}
      onChange={onChange}
      setState={setSelectedOptions}
    />
  );
};
