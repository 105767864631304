import { LanguageData, AppMessages } from './index';

const messages: LanguageData = {
  [AppMessages.pageSignInWelcome]: 'Your team together, wherever you are!',
  [AppMessages.pageSignInEmail]: 'Email',
  [AppMessages.pageSignInPassword]: 'Password',
  [AppMessages.pageSignInConfirmPassword]: 'Confirm password',
  [AppMessages.pageSignInFirstName]: 'First name',
  [AppMessages.pageSignInLastName]: 'Last name',
  [AppMessages.pageSignInRoleDescription]: 'Role',
  [AppMessages.pageSignInPhoneNumber]: 'Phone number',
  [AppMessages.pageSignInCompany]: 'Company',
  [AppMessages.pageSignInSubmit]: 'SIGN IN',
  [AppMessages.pageSignInForgotPassword]: 'Forgot my password',
  [AppMessages.pageSignInForgotPasswordConfirm]:
    'Do you want to receive a link in the email "{email}" to reset your password?',
  [AppMessages.pageSignInForgotPasswordLinkSended]:
    'A link has been sent to your email',
  [AppMessages.pageSignInValidationRequired]: 'Required',
  [AppMessages.pageSignInValidationInvalidEmail]: 'Invalid email',
  [AppMessages.pageSignInValidationWeakPassword]:
    'At least: 1 letter, 1 number, 6 digits',
  [AppMessages.pageSignInValidationDoesNotMatch]: 'Does not match',
  [AppMessages.pageSignInValidationInvalidPhoneNumber]: 'Invalid phone number',
  [AppMessages.pageSignInValidationInvalidCredentials]: 'Invalid credentials',
  [AppMessages.pageSignInValidationIncompleteRegistration]:
    'Your administrator still needs to complete your user registration before you can login. Contact him/her to request completion.',
  [AppMessages.pageSignInKeepConnected]: 'Keep connected',

  [AppMessages.pageCreateEnvironmentWelcome]:
    'Welcome to Proseia, shall we start?',
  [AppMessages.pageCreateEnvironmentOrganization]: 'Company / Organization',
  [AppMessages.pageCreateEnvironmentEmail]:
    'Enter your company name and a valid email.',
  [AppMessages.pageCreateEnvironmentSubdomain]:
    'Your domain (WEB address) in Proseia will be:',
  [AppMessages.pageCreateEnvironmentSubdomainPlaceholder]: 'yourcompany',
  [AppMessages.pageCreateEnvironmentSubmit]: 'Next',
  [AppMessages.pageCreateEnvironmentValidationInvalidSubdomain]:
    'At least 3 digits, up to 20 digits',
  [AppMessages.pageCreateEnvironmentValidationSubdomainNotAvailable]:
    'Subdomain not available',

  [AppMessages.pageSigningUpEnvironmentTitle]:
    'Fill in your details and choose a password.',
  [AppMessages.pageSigningUpEnvironmentSubmit]: 'Next',

  [AppMessages.pageSettingUpEnvironmentTitle]:
    'Wait, we are preparing your Proseia.',
  [AppMessages.pageSettingUpEnvironmentSteps]:
    'Creating your environment; Assembling the interface; Preparing the rooms; Organizing the chat; Building the feed; Almost ready;',

  [AppMessages.pageStartEnvironmentTitle]: 'All ready!',
  [AppMessages.pageStartEnvironmentDescription]:
    'Now just go ahead and start using it.',
  [AppMessages.pageStartEnvironmentSubmit]: 'Get started',

  [AppMessages.pageOfficeNotAllowedToRequestConnectionsBetweenOthers]:
    'It is not allowed to request connections between other users.',
  [AppMessages.pageOfficeNotAllowedToRequestConnectionsWithInactiveMobileUser]:
    'Sending meeting invites to inactive mobile users is not allowed.',
  [AppMessages.pageOfficeNotAllowedToMoveOthers]:
    'It is not allowed to move other users.',
  [AppMessages.pageOfficeEnableEditMode]: 'Enable office edit mode',
  [AppMessages.pageOfficeDisableEditMode]: 'Disable office edit mode',
  [AppMessages.pageOfficeAddRoom]: 'Add room',
  [AppMessages.pageOfficeRoomName]: 'Room name',
  [AppMessages.pageOfficeInviteUsers]: 'Invite users',
  [AppMessages.pageOfficeInviteUsersPrompt]: 'Enter emails separated by commas',
  [AppMessages.pageOfficeStartNewMeeting]: `Start new meeting`,
  [AppMessages.pageOfficeShareMeetingLink]: `Share meeting link`,
  [AppMessages.pageOfficeChangeMood]: 'Change mood',
  [AppMessages.pageOfficeTurnOffRoomSoundAndStartMeetingConfirmation]:
    'Do you want to turn off room sound and start a new meeting?',

  [AppMessages.pageMeetingHeaderMessage]:
    'Enter your name before entering the meeting',
  [AppMessages.pageMeetingEnterMeeting]: 'Enter meeting',

  [AppMessages.componentGoogleSignInButton]: 'Sign-in with Google',

  [AppMessages.componentAvatarOpenChatConversation]: 'Open chat conversation',
  [AppMessages.componentAvatarInviteToMeeting]: 'Invite to meeting',
  [AppMessages.componentAvatarRequestToJoinTheMeeting]:
    'Request to join the meeting',
  [AppMessages.componentAvatarJoinTheMeeting]: 'Join the meeting',

  [AppMessages.componentMeetingLinkSharingTitle]: 'Meeting title',
  [AppMessages.componentMeetingLinkSharingGenerate]: 'Generate link',
  [AppMessages.componentMeetingLinkSharingCopy]: 'Copy link',
  [AppMessages.componentMeetingLinkSharingCopied]:
    'Link successfully copied to clipboard!',

  [AppMessages.componentThemeSwitcherToDark]: 'Change to dark theme',
  [AppMessages.componentThemeSwitcherToLight]: 'Change to light theme',

  [AppMessages.componentRoomExpand]: 'Expand',
  [AppMessages.componentRoomRetract]: 'Retract',
  [AppMessages.componentRoomDelete]: 'Delete room',
  [AppMessages.componentRoomConfirmDeletion]: 'Confirm room deletion?',
  [AppMessages.componentRoomEnter]: 'Enter the room',
  [AppMessages.componentRoomTurnOnSound]: 'Turn on room sound',
  [AppMessages.componentRoomTurnOffSound]: 'Turn off room sound',

  [AppMessages.componentProfileDefineYourRole]: 'Define your role',
  [AppMessages.componentProfileAt]: 'At:',
  [AppMessages.componentProfileSignOut]: 'Sign out',
  [AppMessages.componentProfileEditingNotAllowed]:
    'Only admin users can edit profile info',

  [AppMessages.componentWelcomeTourStartTitle]: `Welcome to Proseia`,
  [AppMessages.componentWelcomeTourStartDescription]: `Let's start a tour to learn how to use it!\n(Press the arrows to navigate through this presentation)`,
  [AppMessages.componentWelcomeTourUserInfoTitle]: `User info`,
  [AppMessages.componentWelcomeTourUserInfoDescription]: `Here is your personal information.`,
  [AppMessages.componentWelcomeTourAvatarPictureTitle]: `Avatar picture`,
  [AppMessages.componentWelcomeTourAvatarPictureDescription]: `You can change your picture by clicking on the edit icon that appears when you hover over the avatar.`,
  [AppMessages.componentWelcomeTourAvatarDraggableTitle]: `The avatar is draggable`,
  [AppMessages.componentWelcomeTourAvatarDraggableDescription]: `You can also drag it into a room to enter, or drag it over another user's avatar to request a conference wherever they are.`,
  [AppMessages.componentWelcomeTourRoleTitle]: `Role`,
  [AppMessages.componentWelcomeTourRoleDescription]: `Your role is displayed here.\nYou can click to edit it.`,
  [AppMessages.componentWelcomeTourProfileTitle]: `Profile`,
  [AppMessages.componentWelcomeTourProfileDescription]: `When you click on the personal information area, the profile will be expanded.`,
  [AppMessages.componentWelcomeTourRemoteTitle]: `Remote work`,
  [AppMessages.componentWelcomeTourRemoteDescription]: `Enable or disable this option to inform other users if you are currently working in person or remotely.`,
  [AppMessages.componentWelcomeTourUserStatusTitle]: `User status`,
  [AppMessages.componentWelcomeTourUserStatusDescription]: `Inform other users of your current status by selecting one of the options.`,
  [AppMessages.componentWelcomeTourLanguagesTitle]: `Languages`,
  [AppMessages.componentWelcomeTourLanguagesDescription]: `Select your preferred language from those available.`,
  [AppMessages.componentWelcomeTourMoodsTitle]: 'Moods',
  [AppMessages.componentWelcomeTourMoodsDescription]:
    'Inform other users how you are feeling',
  [AppMessages.componentWelcomeTourNotificationsTitle]: `Notifications`,
  [AppMessages.componentWelcomeTourNotificationsDescription]: `Give permission and enable notifications to make it easier to see when someone tries to interact.`,
  [AppMessages.componentWelcomeTourThemeSwitcherTitle]: `Theme switcher`,
  [AppMessages.componentWelcomeTourThemeSwitcherDescription]: `Switch between light and dark themes by clicking this button.`,
  [AppMessages.componentWelcomeTourInviteOthersTitle]: `Invite others`,
  [AppMessages.componentWelcomeTourInviteOthersDescription]: `Send invitations to your teammates to join you at Proseia.\nThey will receive the invitation in their emails.`,
  [AppMessages.componentWelcomeTourEditModeTitle]: `Edit the virtual office`,
  [AppMessages.componentWelcomeTourEditModeDescription]: `By clicking on this button, the editing mode is enabled and you can add, rename or delete rooms as needed.`,
  [AppMessages.componentWelcomeTourRoomsTitle]: `Rooms`,
  [AppMessages.componentWelcomeTourRoomsDescription]: `Here is the virtual representation of your office.\nEach room can represent a real-world room in your office, a department, a specific team context, or any other separation that makes sense for your company.\nYou can move between rooms by dragging your avatar.`,
  [AppMessages.componentWelcomeTourDoneTitle]: `That's it!`,
  [AppMessages.componentWelcomeTourDoneDescription]: `For now, this is all we have to show you!`,
  [AppMessages.componentWelcomeTourDone]: `Done`,

  [AppMessages.componentStyledMultiSelectSelected]: 'Selected',
  [AppMessages.componentStyledMultiSelectAll]: 'All',

  [AppMessages.componentUserMoodTitle]: 'How are you feeling?',
  [AppMessages.componentUserMoodComment]:
    'If you want, tell us what makes you feel that way',
  [AppMessages.componentUserMoodConfirm]: 'Confirm',

  [AppMessages.componentUserManagementTitle]: 'User management',
  [AppMessages.componentUserManagementInviteNewUsers]: 'Invite new users',
  [AppMessages.componentUserManagementUsersCount]: 'Total',
  [AppMessages.componentUserManagementCanAccess]: 'Can access',
  [AppMessages.componentUserManagementRevokeAccess]: 'Revoke access',
  [AppMessages.componentUserManagementGrantAccess]: 'Grant access',
  [AppMessages.componentUserManagementAddAsAdministrator]:
    'Add as administrator',
  [AppMessages.componentUserManagementRemoveFromAdministrators]:
    'Remove from administrators',
  [AppMessages.componentUserManagementDeleteUser]: 'Delete user',
  [AppMessages.componentUserManagementResendInvitationEmail]:
    'Resend invitation email',
  [AppMessages.componentUserManagementAdministrator]: 'Administrator',
  [AppMessages.componentUserManagementAccessRevoked]: 'Access revoked',
  [AppMessages.componentUserManagementNoLogin]: '(No login)',
  [AppMessages.componentUserManagementUseLocation]: 'Use geolocation',
  [AppMessages.componentUserManagementUseLocationDescription]:
    'When this option is enabled, the location of users using the mobile app will be requested.',
  [AppMessages.componentUserManagementMakeLocationRequired]:
    'Make geolocation mandatory for this user to use the mobile app',
  [AppMessages.componentUserManagementMakeLocationOptional]:
    'Make geolocation optional for this user when using the mobile app',
  [AppMessages.componentUserManagementAllowProfileEditing]:
    'Allow profile editing',
  [AppMessages.componentUserManagementAllowProfileEditingDescription]:
    'When this option is disabled, non-admin users cannot edit their profile information (name, role, picture).',
  [AppMessages.componentUserManagementUseExtension]: 'Use extension',
  [AppMessages.componentUserManagementUseExtensionDescription]:
    'When this option is enabled, the user must have the extension that checks their activity installed in their browser.',
  [AppMessages.componentUserManagementMakeExtensionRequired]:
    'Make use of extension mandatory for this user.',
  [AppMessages.componentUserManagementMakeExtensionOptional]:
    'Make use of extension optional for this user.',
  [AppMessages.componentUserManagementUnnamed]: 'Undefined name',
  [AppMessages.componentUserManagementNoRole]: 'Undefined role',

  [AppMessages.userInfoRemote]: 'Remote',
  [AppMessages.userInfoStatusAvailable]: 'Available',
  [AppMessages.userInfoStatusAway]: 'Away',
  [AppMessages.userInfoStatusBusy]: 'Busy',
  [AppMessages.userInfoStatusInactive]: 'Inactive',

  [AppMessages.inputSearchPlaceholder]: 'Search',

  [AppMessages.chatDefaultChannelName]: 'Everyone',
  [AppMessages.chatChannels]: 'Channels',
  [AppMessages.chatContacts]: 'Contacts',
  [AppMessages.chatTyping]: 'typing',
  [AppMessages.chatLoading]: 'loading...',
  [AppMessages.chatNewMessages]: 'New messages',
  [AppMessages.chatLoadPrevious]: 'Load previous',
  [AppMessages.chatLoadNew]: 'Show recent',
  [AppMessages.chatSendMessage]: 'Send message',
  [AppMessages.chatExcludeFile]: 'Exclude file',
  [AppMessages.chatNewMessageFrom]: 'New message from {name}',
  [AppMessages.chatPublicChannel]: 'Public',
  [AppMessages.chatPrivateChannel]: 'Private',
  [AppMessages.chatNewChannel]: 'New channel',
  [AppMessages.chatCreateChannel]: 'Create',
  [AppMessages.chatChannelName]: 'Name',
  [AppMessages.chatJoinChannel]: 'Join',
  [AppMessages.chatLeaveChannel]: 'Leave the group',
  [AppMessages.chatLeaveChannelConfirm]:
    'Do you really want to leave this group?',
  [AppMessages.chatRemoveFromChannel]: 'Remove',
  [AppMessages.chatRemoveFromChannelConfirm]:
    'Do you really want to remove "{name}" from this group?',
  [AppMessages.chatRenameChannel]: 'Rename',
  [AppMessages.chatRenameChannelNewName]: 'New name:',
  [AppMessages.chatMakeChannelPublic]: 'Make it public',
  [AppMessages.chatMakeChannelPublicConfirm]:
    'Do you want to make this group public?',
  [AppMessages.chatMakeChannelPrivate]: 'Make it private',
  [AppMessages.chatMakeChannelPrivateConfirm]:
    'Do you want to make this group private?',
  [AppMessages.chatDeleteChannel]: 'Delete the group',
  [AppMessages.chatDeleteChannelConfirm]:
    'Do you really want to delete the group "{group}"? (This action is irreversible)',
  [AppMessages.chatChannelMembers]: 'Members',
  [AppMessages.chatChannelMember]: 'Member',
  [AppMessages.chatAddToChannel]: 'Add',
  [AppMessages.chatInputPlaceholder]: 'Message',
  [AppMessages.chatPostConfirmDeletion]: 'Confirm deletion?',
  [AppMessages.chatPostDelete]: 'Delete',
  [AppMessages.chatPostAddReaction]: 'Add reaction',
  [AppMessages.chatPostReplyTo]: 'Reply',
  [AppMessages.chatSendingAudio]: 'Sending message',

  [AppMessages.officePostsGeneral]: 'General',
  [AppMessages.officePostsNothingPosted]: 'Nothing has been posted yet',
  [AppMessages.officePostConfirmDeletion]: 'Confirm deletion?',
  [AppMessages.officePostDelete]: 'Delete',
  [AppMessages.officePostAddReaction]: 'Add reaction',
  [AppMessages.officePostPin]: 'Pin post',
  [AppMessages.officePostUnpin]: 'Unpin post',
  [AppMessages.officePostPublish]: 'Publish',
  [AppMessages.officePostInputTitle]: 'Create post',
  [AppMessages.officePostInputAddToPost]: 'Add to post',
  [AppMessages.officePostInputPlaceholder]: 'Create a new post',
  [AppMessages.officePostCommentInputPlaceholder]: 'Add a comment',
  [AppMessages.officePostCommentsCountTitle]: 'comment(s)',
  [AppMessages.officePostCommentsShowAllButton]: 'Show all comments',
  [AppMessages.officePostCommentsHideButton]: 'Hide comments',
  [AppMessages.officePostCommentsNewCount]: 'new',

  [AppMessages.attention]: 'Attention',
  [AppMessages.notAllowed]: 'Not allowed',
  [AppMessages.noSupport]: 'No support',
  [AppMessages.permissionDenied]: 'Permission denied',

  [AppMessages.notificationsNoSupport]:
    'Browser does not support notifications.',
  [AppMessages.notificationsPermissionRequired]:
    'Notifications permission must be granted in browser settings.',
  [AppMessages.notificationsEnable]: 'Enable notifications',
  [AppMessages.notificationsDisable]: 'Disable notifications',

  [AppMessages.userMediaNoSupport]:
    'Browser does not support audio/video capture.',
  [AppMessages.userMediaMicrophonePermissionRequired]:
    'Microphone permission must be granted in browser settings.',
  [AppMessages.userMediaMicrophoneEnable]: 'Enable microphone',
  [AppMessages.userMediaMicrophoneDisable]: 'Disable microphone',
  [AppMessages.userMediaCameraPermissionRequired]:
    'Camera permission must be granted in browser settings.',
  [AppMessages.userMediaCameraEnable]: 'Enable camera',
  [AppMessages.userMediaCameraDisable]: 'Disable camera',
  [AppMessages.userMediaScreenSharingEnable]: 'Start screen sharing',
  [AppMessages.userMediaScreenSharingDisable]: 'Stop screen sharing',

  [AppMessages.meetingLeave]: 'Leave meeting',
  [AppMessages.meetingShow]: 'Show meeting',
  [AppMessages.meetingHide]: 'Hide meeting',
  [AppMessages.meetingConfirmLeave]: `You will leave the current meeting. That's right?`,

  [AppMessages.inviteSended]: 'Invite sended',
  [AppMessages.inviteReceived]: 'Invite received',
  [AppMessages.inviteRejected]: 'Invite rejected',
  [AppMessages.inviteCanceled]: 'Invite canceled',
  [AppMessages.inviteWantsToConnect]: 'wants to connect with you',
  [AppMessages.inviteRejectedYourInvitation]: 'rejected your invitation',
  [AppMessages.inviteCanceledTheInvitation]: 'canceled the invitation',

  [AppMessages.periodsField]: 'Period',
  [AppMessages.periodsToday]: 'Today',
  [AppMessages.periodsYesterday]: 'Yesterday',
  [AppMessages.periodsThisWeek]: 'This Week',
  [AppMessages.periodsLastWeek]: 'Last Week',
  [AppMessages.periodsThisMonth]: 'This Month',
  [AppMessages.periodsLastMonth]: 'Last Month',

  [AppMessages.booleanStringYes]: 'Yes',
  [AppMessages.booleanStringNo]: 'No',

  [AppMessages.reportsTitle]: 'Reports',
  [AppMessages.reportsNoData]: 'No data for the period or filter',

  [AppMessages.reportsModelField]: 'Model',
  [AppMessages.reportsModelStatusPerUser]: 'Time per status/user',
  [AppMessages.reportsModelStatusPerRoomUser]: 'Time per status/room/user',
  [AppMessages.reportsModelInMeetingPerUser]: 'Meeting time per user',
  [AppMessages.reportsModelMeetingsPerDayRoomUser]:
    'Meetings per day/room/user',
  [AppMessages.reportsModelAnalyticLogs]: 'Analytic logs',

  [AppMessages.reportsFieldLogId]: 'Log ID',
  [AppMessages.reportsFieldUserFullName]: 'User',
  [AppMessages.reportsFieldUserEmail]: 'Email',
  [AppMessages.reportsFieldOfficeName]: 'Office',
  [AppMessages.reportsFieldRoomName]: 'Room',
  [AppMessages.reportsFieldIsRemote]: 'Remote',
  [AppMessages.reportsFieldStatus]: 'Status',
  [AppMessages.reportsFieldStatusMessage]: 'Status message',
  [AppMessages.reportsFieldInMeeting]: 'In meeting',
  [AppMessages.reportsFieldMeetingId]: 'Meeting ID',
  [AppMessages.reportsFieldMeetingStart]: 'Meeting start',
  [AppMessages.reportsFieldMeetingEnd]: 'Meeting end',
  [AppMessages.reportsFieldMeetingTotalTimeInSeconds]:
    'Meeting duration (seconds)',
  [AppMessages.reportsFieldMeetingTotalTimeInMinutes]:
    'Meeting duration (minutes)',
  [AppMessages.reportsFieldMeetingTotalTimeInHours]: 'Meeting duration (hours)',
  [AppMessages.reportsFieldMicrophoneEnabled]: 'Microphone enabled',
  [AppMessages.reportsFieldCameraEnabled]: 'Camera enabled',
  [AppMessages.reportsFieldScreenSharingEnabled]: 'Screen shared',
  [AppMessages.reportsFieldLogCreatedAt]: 'Log start',
  [AppMessages.reportsFieldLogFinishedAt]: 'Log end',
  [AppMessages.reportsFieldLogDate]: 'Date',
  [AppMessages.reportsFieldLogYear]: 'Year',
  [AppMessages.reportsFieldLogMonth]: 'Month',
  [AppMessages.reportsFieldLogDay]: 'Day',
  [AppMessages.reportsFieldTimeInSeconds]: 'Log duration (seconds)',
  [AppMessages.reportsFieldTimeInMinutes]: 'Log duration (minutes)',
  [AppMessages.reportsFieldTimeInHours]: 'Log duration (hours)',

  [AppMessages.limitsActiveUsersReached]: 'Active users limit reached',
  [AppMessages.limitsActiveUsers]:
    'Your current plan allows only {limit} active users',
  [AppMessages.limitsActivityReportsNotAllowed]:
    'Your current plan does not allow reports',
  [AppMessages.limitsExternalMeetingsNotAllowed]:
    'Your current plan does not allow meeting links for external users',
  [AppMessages.limitsCannotJoinMeeting]: 'Cannot join the meeting',
  [AppMessages.limitsUsersPerMeeting]:
    'Meeting exceeded the maximum number of participants',
  [AppMessages.limitsAudioHoursReached]:
    'Audio hours limit reached for your current plan',
  [AppMessages.limitsVideoHoursReached]:
    'Video hours limit reached for your current plan',
  [AppMessages.limitsScreenSharingHoursReached]:
    'Screen sharing hours limit reached for your current plan',
  [AppMessages.limitsChatFileTooLarge]: 'File too large',
  [AppMessages.limitsChatMaxFileSize]:
    'Your current plan allows you to upload files up to {limit} megabytes only',

  [AppMessages.chromeExtensionNeededMessage]:
    'We were unable to detect the Proseia extension for your browser.\nFor a better experience please install the extension.\n>> Click here to install the extension <<\nAfter finishing the installation please reload the page!',

  [AppMessages.smallScreenDetectedMessage]:
    'Proseia is optimized for use on larger screens.\nFor a better experience, access via a computer!\n\nFor mobile devices we offer the App Proseia, which allows you to send and receive messages in chat.',

  [AppMessages.copyrightText]: '{year} - All rights reserved',

  [AppMessages.privacyPolicy]: 'Privacy policy',

  [AppMessages.updateVersion]: 'New version available! Click to update.',

  [AppMessages.stepOf]: 'Step {current} of {total}',

  [AppMessages.tutorialEnterRoomTitle]: 'Welcome to Proseia',
  [AppMessages.tutorialEnterRoomDescription1]:
    'Drag your avatar to enter a room.',
  [AppMessages.tutorialEnterRoomDescription2]: 'You can also use the icon ',

  [AppMessages.tutorialAddUsersDescription1]: 'Click here to add users.',

  [AppMessages.tutorialInviteToMeetingTitle]: 'Audio/video meeting?',
  [AppMessages.tutorialInviteToMeetingDescription1]:
    'Drag your avatar to another user.',
  [AppMessages.tutorialInviteToMeetingDescription2]:
    'Users in a meeting appear together, and best of all, you can add several!',

  [AppMessages.tutorialContextMenuTitle]: 'Chat or meeting?',
  [AppMessages.tutorialContextMenuDescription1]:
    'Just click on the user and select.',

  [AppMessages.tutorialMeetingVisibilityTitle]: 'Created a meeting?',
  [AppMessages.tutorialMeetingVisibilityDescription1]:
    'During a meeting you can continue using Proseia as normal.',
  [AppMessages.tutorialMeetingVisibilityDescription2]:
    'While the meeting is active, the camera icon will blink red.',

  [AppMessages.tutorialGotIt]: 'Ok, I got it!',

  [AppMessages.incompatibleBrowserTitle]:
    'Unfortunately, your browser is not supported!',
  [AppMessages.incompatibleBrowserDescription]:
    'To access Proseia, use one of the browsers below.',

  [AppMessages.extensionRequiredTitle]:
    'Your administrator requires the installed extension to access Proseia.',
  [AppMessages.extensionRequiredDescription]:
    'To access Proseia, install the extension from the link below and reload the page.',
  [AppMessages.extensionRequiredButtonText]: 'Install',
};

export default messages;
