/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import { Picker, EmojiData } from 'emoji-mart';

import { useThemeContext } from '../../hooks/theme';

import { OfficePostEmojiPickerContainer } from './styles';

type ThemeTitle = 'light' | 'dark';

type TypeEmojiPicker = 'comment-input' | 'post' | 'comment' | 'post-creation';

interface Props {
  open: boolean;
  channelId: string;
  onClose: () => void;
  onSelect?: (emoji: EmojiData) => void;
  type: TypeEmojiPicker;
}

export const OfficePostEmojiPicker: React.FC<Props> = ({
  open,
  channelId,
  onClose,
  onSelect,
  type,
}) => {
  const themeTitle = useThemeContext((state) => state.theme.title);

  const handleClose = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const handleSelect = useCallback(
    (emoji: EmojiData) => {
      if (onSelect) onSelect(emoji);
      handleClose();
    },
    [onSelect, handleClose]
  );

  return (
    <OfficePostEmojiPickerContainer type={type}>
      {open && (
        <>
          <div
            className="outside-area"
            onClick={handleClose}
            onWheel={(e) => {
              const container = document.getElementById(channelId);
              if (!container) return;
              container.scrollTo({ top: container.scrollTop + e.deltaY });
            }}
          />
          <Picker
            autoFocus
            theme={themeTitle as ThemeTitle}
            sheetSize={64}
            onSelect={handleSelect}
            emoji=""
            title=""
          />
        </>
      )}
    </OfficePostEmojiPickerContainer>
  );
};
