import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { GoAlert } from 'react-icons/go';

import { Container } from './styles';
import { AppMessages } from '../../languages';
import { ModalContainer } from '../ModalContainer';
import { IconButton } from '../IconButton';
import { CloseBoxIcon } from '../Icons';

const TEXT_LINK_PATTERN = /(>>(.)+<<)/gi;

interface Props {
  isOpen: boolean;
  extensionLink: string;
  onCloseRequest: () => void;
}

export const ExtensionNeededDialog: React.FC<Props> = ({
  isOpen,
  extensionLink,
  onCloseRequest,
}) => {
  const { formatMessage } = useIntl();

  const formatLine = useCallback(
    (line: string) => {
      return line.split(TEXT_LINK_PATTERN).reduce((result, item) => {
        const isLink = item.match(TEXT_LINK_PATTERN);
        return (
          <>
            {result}
            {isLink ? (
              <>
                <br />
                <a
                  href={extensionLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item}
                </a>
                <br />
              </>
            ) : (
              item
            )}
          </>
        );
      }, <></>);
    },
    [extensionLink]
  );

  const message = formatMessage({
    id: AppMessages.chromeExtensionNeededMessage,
  })
    .split('\n')
    .reduce<JSX.Element | string>(
      (lineAccumulator, line) => (
        <>
          {lineAccumulator && (
            <>
              {lineAccumulator}
              <br />
            </>
          )}
          {formatLine(line)}
        </>
      ),
      ''
    );

  return (
    <ModalContainer isOpen={isOpen} onClickOutside={onCloseRequest}>
      <Container onClick={(e) => e.stopPropagation()}>
        <div className="content">
          <div className="icon-container">
            <GoAlert />
          </div>
          <div className="text-container">{message}</div>
        </div>
        <div className="close-button">
          <IconButton iconSize={16} onClick={onCloseRequest}>
            <CloseBoxIcon />
          </IconButton>
        </div>
      </Container>
    </ModalContainer>
  );
};
