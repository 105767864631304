var workerPath = 'https://archive.org/download/ffmpeg_asm/ffmpeg_asm.js';

var worker;

export function processInWebWorker() {
  if (!!worker) return;

  var blob = URL.createObjectURL(new Blob(['importScripts("' + workerPath + '");var now = Date.now;function print(text) {postMessage({"type" : "stdout","data" : text});};onmessage = function(event) {var message = event.data;if (message.type === "command") {var Module = {print: print,printErr: print,files: message.files || [],arguments: message.arguments || [],TOTAL_MEMORY: message.TOTAL_MEMORY || false};postMessage({"type" : "start","data" : Module.arguments.join(" ")});postMessage({"type" : "stdout","data" : "Received command: " +Module.arguments.join(" ") +((Module.TOTAL_MEMORY) ? ".  Processing with " + Module.TOTAL_MEMORY + " bits." : "")});var time = now();var result = ffmpeg_run(Module);var totalTime = now() - time;postMessage({"type" : "stdout","data" : "Finished processing (took " + totalTime + "ms)"});postMessage({"type" : "done","data" : result,"time" : totalTime});}};postMessage({"type" : "ready"});'], {
    type: 'application/javascript'
  }));

  worker = new Worker(blob);
  URL.revokeObjectURL(blob);
  return worker;
}

export function convertToAAC(audioBlob, callback) {
  var aab;
  var buffersReady;
  // var workerReady;
  // var posted;

  var fileReader = new FileReader();
  fileReader.onload = function() {
    aab = this.result;
    postMessage();
  };

  fileReader.readAsArrayBuffer(audioBlob);

  if (!worker) {
    worker = processInWebWorker();
  }

  worker.onmessage = function(event) {
    var message = event.data;

    if (message.type === "ready") {
      console.log('ffmpeg-asm.js file has been loaded.');

      // workerReady = true;
      if (buffersReady)
        postMessage();
    } else if (message.type === "stdout") {
      console.log(message.data);
    } else if (message.type === "start") {
      console.log('ffmpeg-asm.js file received ffmpeg command.');
    } else if (message.type === "done") {
      console.log(JSON.stringify(message));

      var result = message.data[0];
      console.log(JSON.stringify(result));

      var blob = new File([result.data], 'test.mp3', {
          type: 'audio/mp3'
      });

      console.log(JSON.stringify(blob));

      callback(blob);
    }
  };

  var postMessage = function() {
    // posted = true;

    worker.postMessage({
      type: 'command',
      arguments: '-i audio.wav -c:a aac -b:a 96k -strict experimental output.mp4'.split(' '),
      files: [
        {
          data: new Uint8Array(aab),
          name: "audio.wav"
        }
      ]
    });
  };
}
